import type { SVGProps } from "react";
const SvgRocketMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18 3.733.38.441c5.636 6.557 6.957 13.434 6.866 18.669a28.58 28.58 0 0 1-.073 1.6c2.312 1.47 3.367 4.174 2.868 6.73l-.184.938-.666-.686a5.436 5.436 0 0 0-2.95-1.553 21.61 21.61 0 0 1-.355 1.157 14.708 14.708 0 0 1-.224.616l-.014.034-.004.01-.001.002v.001l-.462-.192.46.193-.278.668-.526-.498c-2.438-2.309-4.83-2.295-6.63-1.727a8.688 8.688 0 0 0-2.217 1.088 8.44 8.44 0 0 0-.78.596l-.038.035-.008.007v.001h-.001l-.526.498-.28-.668.462-.193-.461.192-.002-.003-.004-.01-.014-.034a14.708 14.708 0 0 1-.224-.615 21.61 21.61 0 0 1-.356-1.158 5.437 5.437 0 0 0-2.95 1.552l-.665.687-.184-.939c-.499-2.554.556-5.259 2.868-6.729a28.683 28.683 0 0 1-.073-1.6c-.091-5.235 1.23-12.112 6.867-18.669L18 3.733Zm6.49 25.168c.985.188 1.895.59 2.67 1.17a5.475 5.475 0 0 0-2.094-4.481 28.362 28.362 0 0 1-.575 3.31Zm-13.556-3.31c.145 1.274.358 2.392.575 3.31a6.492 6.492 0 0 0-2.67 1.17 5.475 5.475 0 0 1 2.095-4.48Zm2.113 5.077a9.691 9.691 0 0 1 2.86-1.485c1.943-.614 4.496-.644 7.055 1.457.133-.397.306-.96.482-1.667a27.62 27.62 0 0 0 .802-6.148c.067-3.82-.645-8.545-3.36-13.364a5.68 5.68 0 0 1-5.773 0c-2.714 4.82-3.426 9.545-3.359 13.364a27.62 27.62 0 0 0 .802 6.148c.18.723.357 1.297.491 1.695Zm2.573-22.07a4.681 4.681 0 0 0 4.76 0A28.89 28.89 0 0 0 18 5.276a28.907 28.907 0 0 0-2.38 3.323ZM18 14.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm-2.75 1.75a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRocketMedium;
