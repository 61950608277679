import type { SVGProps } from "react";
const SvgMissedConnection = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className="missed-connection_svg__color-immutable"
    {...props}
  >
    <rect width={16} height={16} fill="#EB0000" rx={2} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.778 9.2c0 .45-.33.81-.75.81s-.75-.36-.75-.81V6.02c0-.45.33-.81.75-.81s.75.36.75.81V9.2Zm-.78 3.21c-.42 0-.75-.36-.75-.81 0-.45.33-.81.75-.81s.75.36.75.81c0 .45-.33.81-.75.81Zm5.912.42L8.688 2.42c-.15-.27-.39-.42-.69-.42a.72.72 0 0 0-.66.42L2.087 12.83c-.12.24-.12.54.03.78a.7.7 0 0 0 .63.39H13.25c.27 0 .51-.15.66-.39a.89.89 0 0 0 0-.78Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMissedConnection;
