import type { SVGProps } from "react";
const SvgCarSignParkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.25 6.25h7v8.5h-3V31.5h-1V14.75h-3v-8.5Zm1 1v6.5h5v-6.5h-5Zm12.25 6a3.25 3.25 0 0 0-3.25 3.25v3.25h8.035a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.465 3h2.035V16.5A3.25 3.25 0 0 0 27 13.25H16.5Zm10.7 6.5a2.502 2.502 0 0 0-2.45-2c-1.21 0-2.219.86-2.45 2h4.9Zm-13.95 7.5v-6.5h17v6.5h-17Zm-1-10.75a4.25 4.25 0 0 1 4.25-4.25H27a4.25 4.25 0 0 1 4.25 4.25v1.75H33v1h-1.75v12h-5.498v-3H17.75v3h-5.5v-12H10.5v-1h1.75V16.5Zm14.502 11.75v2h3.498v-2h-3.498Zm-13.502 0h3.5v2h-3.5v-2Zm2-4.25a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm10.5-1a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM26.75 24c0-.138.113-.25.25-.25s.25.112.25.25a.25.25 0 0 1-.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCarSignParkingMedium;
