import type { SVGProps } from "react";
const SvgWheelchairSubstituteTransportMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 3.25h-.5v29.5h29.5V3.25h-29Zm.5 28.5V4.25h27.5v27.5H4.25Zm8.405-19.591a.087.087 0 0 1-.004-.01l.004-.008a.18.18 0 0 1 .036-.05.181.181 0 0 1 .05-.036l.006-.003.003-.001.009.004a.181.181 0 0 1 .05.036.18.18 0 0 1 .036.05l.002.005.002.004a.183.183 0 0 1-.04.059.186.186 0 0 1-.059.04.186.186 0 0 1-.059-.04.183.183 0 0 1-.036-.05Zm.095-1.11a1.1 1.1 0 0 0-.766.335 1.1 1.1 0 0 0-.334.766c0 .313.151.583.334.766a1.1 1.1 0 0 0 .766.334 1.1 1.1 0 0 0 .766-.334 1.1 1.1 0 0 0 .334-.766 1.1 1.1 0 0 0-.334-.766 1.1 1.1 0 0 0-.766-.334Zm-.5 3.95v7.25h6v6.25h1v-7.25h-6V15h-1Zm-2.257 2.996C8.155 18.83 7 20.665 7 22.8c0 2.976 2.474 5.45 5.45 5.45 2.168 0 3.97-1.333 4.939-3.11l-.878-.48c-.83 1.523-2.329 2.59-4.061 2.59-2.424 0-4.45-2.026-4.45-4.45 0-1.765.945-3.23 2.407-3.895l-.414-.91Zm10.3-9.686c.37-.369.878-.559 1.442-.559h4.5c1.208 0 2 1.007 2 2v.882h.461v.25l.002 1.5v.25h-.463v3.618h-.002v.744h-2.5v-.744h-3.499V17h-2.5v-4.368h-.453v-.25l.001-1.5v-.25h.453V9.75c0-.564.19-1.072.559-1.441Zm.708.707c-.156.156-.266.398-.266.734v2.5h6.5v-2.5c0-.507-.408-1-1-1h-4.5c-.337 0-.578.11-.734.266Zm6.234 4.234h-6.5v2h6.5v-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairSubstituteTransportMedium;
