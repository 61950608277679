import type { SVGProps } from "react";
const SvgBucketFoamBroomMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.75 18.275a5 5 0 0 1 4.5 4.975v6.5h-10v-6.5a5 5 0 0 1 4.5-4.975V4.5h1v13.775ZM4.61 16.75h1.675a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.464 3h7.676l-.155.621-3 12-.095.379H7.86l-.095-.379-3-12-.155-.621Zm5.14-2c1.209 0 2.218.86 2.45 2H7.3c.232-1.14 1.241-2 2.45-2Zm-3.86 3 2.75 11h8.22l1.625-6.5H13.5v-1h5.235l.875-3.5H5.89Zm18.36 11V25.5h1v3.25h2V24h1v4.75h2v-5.5a4 4 0 1 0-8 0v5.5h2Zm-9.5-16a1 1 0 1 1 2.001.001 1 1 0 0 1-2.001-.001Zm1-2a2 2 0 1 0 .001 4.001 2 2 0 0 0-.001-4.001ZM9 10.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBucketFoamBroomMedium;
