import type { SVGProps } from "react";
const SvgTwoFingerTapSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 3a3.47 3.47 0 0 0-2.48 1.048 3.482 3.482 0 0 0-2.444 5.37l.836-.55a2.482 2.482 0 0 1 1.887-3.845l.202-.017.133-.151A2.48 2.48 0 0 1 12.5 4 2.5 2.5 0 0 1 15 6.5c0 .51-.153.982-.415 1.374l.83.556c.37-.552.585-1.216.585-1.93A3.5 3.5 0 0 0 12.5 3Zm-.502 3.501a.5.5 0 1 1 1.003 0L13 11.408l-.003.09H13v1.503h1v-1.555a.5.5 0 0 1 1 .053V13h1v-.507a.5.5 0 0 1 1.003.008v.132l-.002.514L17 16c0 1.417-.217 2.494-.43 3.21a6.913 6.913 0 0 1-.283.79H9.786l-.002-.002-.389-.66-1.2-2.037c-.889-1.507-1.815-3.08-1.97-3.344-.137-.232-.19-.406-.196-.53a.345.345 0 0 1 .117-.28.501.501 0 0 1 .71 0l2.29 2.291.146.146H10v-3.083l-.002-5a.502.502 0 0 1 1-.048L11 12.5v.507h1l.001-5.505c0-.029 0-.057-.002-.085v-.916Zm2.003 0L14 10.082a1.502 1.502 0 0 1 1.948 1.023 1.501 1.501 0 0 1 2.055 1.396v.136L18 13.15V16c0 1.52-.233 2.694-.47 3.494-.12.4-.24.707-.331.917a4.895 4.895 0 0 1-.15.31l-.01.019-.004.006v.002l-.001.001-.434-.248.433.249-.144.25H9.214l-.145-.246.431-.254-.43.254h-.001l-.002-.004-.007-.012-.029-.048-.108-.184-.39-.66-1.2-2.038c-.888-1.506-1.815-3.079-1.971-3.346-.176-.3-.318-.638-.332-.993a1.342 1.342 0 0 1 .41-1.03 1.501 1.501 0 0 1 2.123 0v.001L9 13.878v-1.377l-.002-5a1.502 1.502 0 0 1 2.053-1.396 1.501 1.501 0 0 1 2.95.396Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoFingerTapSmall;
