import type { SVGProps } from "react";
const SvgKeyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 5.75A6.209 6.209 0 0 0 11.75 12 6.209 6.209 0 0 0 18 18.25 6.209 6.209 0 0 0 24.25 12 6.209 6.209 0 0 0 18 5.75ZM10.75 12A7.209 7.209 0 0 1 18 4.75 7.209 7.209 0 0 1 25.25 12a7.215 7.215 0 0 1-3 5.894v10.513l-.146.146-3.75 3.75-.354.354-.354-.354-3.75-3.75-.146-.146V17.894a7.215 7.215 0 0 1-3-5.894Zm4 6.495v9.498l3.25 3.25 3.25-3.25V25.25h-3.26v-1h3.26v-2H18v-1h3.25v-2.755a7.29 7.29 0 0 1-3.25.755 7.29 7.29 0 0 1-3.25-.755Zm3.258-8.245c-1.017 0-1.758.74-1.758 1.716 0 .996.76 1.784 1.758 1.784.992 0 1.742-.783 1.742-1.784 0-.981-.73-1.716-1.742-1.716Zm-2.758 1.716c0-1.547 1.206-2.716 2.758-2.716 1.555 0 2.742 1.173 2.742 2.716 0 1.522-1.167 2.784-2.742 2.784-1.572 0-2.758-1.258-2.758-2.784Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKeyMedium;
