import type { SVGProps } from "react";
const SvgTravelBackpackMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.252 4.25c-.553 0-1.002.449-1.002 1.002v4.5c0 .551.447.998.998.998h4.002v-6.5H8.252Zm4.998 0v6.5h9.5v-6.5h-9.5Zm10.5 0v6.5h4a1 1 0 0 0 1-1v-4.5a1 1 0 0 0-1-1h-4ZM6.25 5.252c0-1.106.896-2.002 2.002-2.002H27.75a2 2 0 0 1 2 2v4.5a2 2 0 0 1-2 2h-1v11h.376c.683.011 1.697.223 2.554.839.881.633 1.57 1.677 1.57 3.261v4.5c0 .373-.15.73-.414.992a1.412 1.412 0 0 1-.994.408H6.158c-.372 0-.73-.146-.994-.408a1.396 1.396 0 0 1-.414-.992v-4.5c0-1.089.436-2.132 1.212-2.9a4.149 4.149 0 0 1 2.92-1.2h.368v-11H8.248A1.998 1.998 0 0 1 6.25 9.752v-4.5Zm4 6.498v3.06a10.505 10.505 0 0 0 3.431 2.504c1.205.548 2.501.86 3.819.924V16.5h1v1.738a10.438 10.438 0 0 0 3.819-.924 10.505 10.505 0 0 0 3.431-2.505V11.75h-15.5Zm15.5 4.476a11.438 11.438 0 0 1-7.25 3.013V21h-1v-1.76a11.438 11.438 0 0 1-4.233-1.016 11.496 11.496 0 0 1-3.017-1.998V31.75h15.5V16.226Zm1 15.524h3.092a.41.41 0 0 0 .29-.119.396.396 0 0 0 .118-.281v-4.5c0-1.249-.522-1.996-1.154-2.45-.654-.47-1.455-.64-1.982-.65h-.364v8Zm-17.5 0v-8h-.368c-.832 0-1.63.328-2.216.91a3.084 3.084 0 0 0-.916 2.19v4.5c0 .105.042.206.118.281a.41.41 0 0 0 .29.119H9.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTravelBackpackMedium;
