import type { SVGProps } from "react";
const SvgStationMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.439 7.487c-9.082-3.706-18.214-3.64-27.374-.002l-.315.126V31.25h8.5v-4.486h11v4.486h8.5V7.614l-.311-.127ZM5.75 30.25V8.29c8.716-3.382 17.373-3.439 26-.002V30.25h-6.5v-4.486h-13v4.486h-6.5Zm13-20a5.457 5.457 0 0 0-5.5 5.5c0 3.072 2.428 5.5 5.5 5.5s5.5-2.428 5.5-5.5-2.428-5.5-5.5-5.5Zm-6.5 5.5c0-3.624 2.876-6.5 6.5-6.5s6.5 2.876 6.5 6.5-2.876 6.5-6.5 6.5a6.457 6.457 0 0 1-6.5-6.5Zm9.742-2.54-2.85 3.6-.33.417-.395-.354-2.85-2.55.666-.746 2.455 2.196 2.52-3.183.784.62Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStationMedium;
