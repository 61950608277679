import type { SVGProps } from "react";
const SvgNarcoticMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m29.75 7.748.002-4.749h-1l-.002 4.749h-1.5v3.002h-1.5v16h3.001v5H25.5v1H33v-1h-3.25l.001-5h2.999v-16h-1.5V7.748h-1.5ZM15.84 5.436a4.05 4.05 0 1 1 5.727 5.726L16.72 16.01l-5.727-5.727 4.846-4.846Zm-5.554 5.553-4.848 4.846a4.05 4.05 0 0 0 3.428 6.876l.17-.031a4.052 4.052 0 0 0 2.13-1.117l.734-.734.811-.812.107-.106 3.195-3.195-5.728-5.727Zm11.988.88-7.883 7.883a6.5 6.5 0 1 1-6.641 6.499c0-.893.19-1.735.518-2.501a5.05 5.05 0 0 1-3.537-8.622l10.4-10.399h.001a5.05 5.05 0 1 1 7.142 7.14Zm-8.963 8.964-.702.702-.735.736a5.06 5.06 0 0 1-2.43 1.349 5.457 5.457 0 0 0-.67 2.133h10.954a5.5 5.5 0 0 0-6.417-4.92Zm6.416 5.92H8.773a5.501 5.501 0 0 0 10.954 0ZM30.25 8.748v2.002h-2V8.748h2Zm-3.5 15.002v2h5v-14h-5v2H30v1h-3.25v2H30v1h-3.25v2H30v1h-3.25v2H30v1h-3.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNarcoticMedium;
