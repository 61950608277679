import type { SVGProps } from "react";
const SvgCloudSnowMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m26.444 4.888-.525 1.038c-.974 1.928-.526 4.32.912 5.934 1.413 1.584 3.793 2.419 6.725 1.18l1.17-.495-.52 1.16c-.81 1.807-2.38 2.97-3.962 3.602-1.162.465-2.383.663-3.381.595.027.22.035.444.024.667a3.735 3.735 0 0 1-1.151 2.539c-.743.703-1.825 1.142-3.236 1.142H12c-1.375 0-2.417-.442-3.125-1.155-.701-.707-1.033-1.639-1.073-2.548-.039-.906.21-1.825.714-2.53.416-.582 1.014-1.024 1.758-1.167.007-2.803 2.942-4.36 5.134-3.134 1.329-2.368 3.803-2.954 5.8-2.23.058.021.116.044.173.067.436-1.275.948-2.199 1.559-2.874.704-.779 1.509-1.194 2.39-1.458l1.114-.333Zm3.43 11.49c-1.233.493-2.462.62-3.29.487a3.373 3.373 0 0 0-.498-.813 3.014 3.014 0 0 0-1.906-1.068c.246-1.254.065-2.374-.417-3.293a4.571 4.571 0 0 0-1.488-1.66c.42-1.278.893-2.113 1.406-2.681.283-.313.586-.553.916-.744-.548 2.073.075 4.334 1.488 5.919 1.47 1.648 3.787 2.567 6.53 1.883-.72.907-1.707 1.557-2.742 1.97Zm-9.007-5.951c-1.686-.612-3.8-.065-4.81 2.225l-.265.602-.509-.417c-1.627-1.333-4.282-.069-3.986 2.404l.066.56H10.8c-.616 0-1.116.302-1.47.798-.362.505-.56 1.198-.529 1.904.031.704.286 1.385.784 1.887.493.496 1.265.86 2.415.86h10.5c1.197 0 2.019-.367 2.548-.868a2.737 2.737 0 0 0 .84-1.863c.034-.685-.175-1.351-.571-1.828-.387-.466-.968-.772-1.747-.741l-.698.028.199-.67c.379-1.275.233-2.339-.194-3.153a3.664 3.664 0 0 0-2.01-1.728ZM23 24.75a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-5 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm9 4a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-5 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowMoonMedium;
