import type { SVGProps } from "react";
const SvgLockerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.25 6.75a3.5 3.5 0 0 1 6.373-2h7.834l.147.147 1.476 1.476.353.353-.353.354-1.523 1.524-.146.146H9.622a3.501 3.501 0 0 1-6.372-2Zm7 0c0-.347-.05-.683-.145-1h6.938l.976.977-1.023 1.023h-6.89c.094-.316.144-.652.144-1Zm-3.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-3 8h-.5v20.5h29.5v-20.5h-29Zm.5 19.5v-18.5h27.5v18.5H4.25Zm3.5-11.499a2 2 0 0 1 2-2h16.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H9.75a2 2 0 0 1-2-2v-6Zm2-1a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h16.5a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H9.75Zm5.25-1.5h6v-1h-6v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLockerMedium;
