import type { SVGProps } from "react";
const SvgWalletSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 5h18v14H3V5Zm1 1v12h16v-3h-7c-.968 0-1.731-.368-2.247-.95-.507-.57-.75-1.318-.75-2.05 0-.731.243-1.479.75-2.05C11.27 9.369 12.032 9 13 9h7V6H4Zm9 4h7v4h-7c-.696 0-1.182-.256-1.499-.613-.325-.366-.498-.868-.498-1.387 0-.518.173-1.02.498-1.387.317-.356.803-.613 1.499-.613Zm0 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalletSmall;
