import type { SVGProps } from "react";
const SvgDisplayBinaryCodeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 3H2v14h8v2H7v1h9v-1h-3v-2h8V3H2.5ZM12 17h-1v2h1v-2Zm-9-1V4h17v12H3Zm6.209-3.783A1.437 1.437 0 0 0 9 13c0 .33.09.605.209.783.121.182.234.217.291.217.057 0 .17-.035.291-.217.12-.178.209-.454.209-.783 0-.33-.09-.605-.209-.783C9.67 12.035 9.557 12 9.5 12c-.057 0-.17.035-.291.217Zm-.832-.555c.24-.36.628-.662 1.123-.662s.883.301 1.123.662c.243.364.377.839.377 1.338 0 .5-.134.974-.377 1.338-.24.36-.628.662-1.123.662s-.883-.301-1.123-.662C8.134 13.974 8 13.499 8 13c0-.5.134-.974.377-1.338ZM13 7c0-.33.09-.605.209-.783.121-.182.234-.217.291-.217.057 0 .17.035.291.217.12.178.209.454.209.783 0 .33-.09.605-.209.783-.121.182-.234.217-.291.217-.057 0-.17-.035-.291-.217A1.438 1.438 0 0 1 13 7Zm.5-2c-.495 0-.883.301-1.123.662C12.134 6.026 12 6.501 12 7c0 .5.134.974.377 1.338.24.36.628.662 1.123.662s.883-.301 1.123-.662C14.866 7.974 15 7.499 15 7c0-.5-.134-.974-.377-1.338-.24-.36-.628-.662-1.123-.662ZM6 5v4H5V5h1Zm4 4V5H9v4h1Zm7 6h1v-4h-1v4ZM6 11v4H5v-4h1Zm12-2V5h-1v4h1Zm-4 6h-1v-4h1v4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDisplayBinaryCodeSmall;
