import type { SVGProps } from "react";
const SvgFaceSmilingLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 23.5C7 13.835 14.835 6 24.5 6S42 13.835 42 23.5 34.165 41 24.5 41 7 33.165 7 23.5ZM24.5 5C14.283 5 6 13.283 6 23.5S14.283 42 24.5 42 43 33.717 43 23.5 34.717 5 24.5 5Zm-7.883 24.321a10.29 10.29 0 0 0 15.766 0l-.766-.642a9.29 9.29 0 0 1-14.234 0l-.766.642Zm1.05-12.068a1.5 1.5 0 1 1 1.667 2.495 1.5 1.5 0 0 1-1.667-2.495ZM18.5 18a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm12-1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.278 1.084a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaceSmilingLarge;
