import type { SVGProps } from "react";
const SvgEv56Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-56-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm7.723 0v2.063H28.36l-.5 2.61c.561-.592 1.278-.888 2.15-.888.858 0 1.603.23 2.238.69.942.693 1.413 1.745 1.413 3.158 0 1.386-.503 2.483-1.508 3.293-.783.634-1.72.952-2.809.952-1.227 0-2.214-.328-2.96-.984-.745-.661-1.14-1.553-1.182-2.674h2.277c.038.407.17.754.397 1.04.355.45.847.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.298.328-1.706.984l-2.07-.031 1.102-6.467h6.372Zm9.182 2.896H40.01c-.206-.894-.714-1.341-1.523-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.47.72-.81 1.11-1.016.398-.206.874-.31 1.43-.31 1.089 0 1.964.387 2.625 1.16.63.73.945 1.644.945 2.745 0 1.306-.424 2.356-1.27 3.15-.762.714-1.677 1.07-2.745 1.07-1.37 0-2.436-.515-3.198-1.546-.762-1.037-1.142-2.481-1.142-4.333 0-1.978.407-3.528 1.221-4.65.789-1.084 1.865-1.626 3.23-1.626 1.285 0 2.27.431 2.951 1.293.392.498.643 1.124.754 1.88ZM38.29 9.724c-.556 0-.998.233-1.325.698-.297.413-.445.905-.445 1.476 0 .566.151 1.06.453 1.484.328.47.777.706 1.348.706.556 0 1.003-.225 1.341-.674.318-.424.476-.923.476-1.5 0-.566-.142-1.05-.428-1.452-.35-.492-.823-.738-1.42-.738Z"
    />
  </svg>
);
export default SvgEv56Negative;
