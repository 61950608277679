import type { SVGProps } from "react";
const SvgFullscreenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 3.25h-.5V12h1V4.25H12v-1H3.75Zm20.25 1h7.75V12h1V3.25H24v1Zm7.75 27.5V24h1v8.75H24v-1h7.75ZM4.25 24v7.75H12v1H3.25V24h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFullscreenMedium;
