import type { SVGProps } from "react";
const SvgWifiDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m2.646 2.354 9.41 9.41a27.902 27.902 0 0 0-7.977 5.81l.722.692a26.9 26.9 0 0 1 8.006-5.752l3.796 3.796a21.57 21.57 0 0 0-8.176 5.396l.726.688a20.57 20.57 0 0 1 8.233-5.301l4.123 4.123a15.5 15.5 0 0 0-8.732 4.63l.726.688a14.5 14.5 0 0 1 8.892-4.432l5.978 5.978a9.43 9.43 0 0 0-4.363-1.071h-.02a9.42 9.42 0 0 0-6.864 2.969l.729.684.009-.01.01.01A8.42 8.42 0 0 1 24 28.01a8.42 8.42 0 0 1 6.126 2.653l.01-.01.01.01.417-.392 15.084 15.084.707-.708-43-43-.708.708Zm12.52 9.118a26.5 26.5 0 0 1 28.001 6.762l.726-.688a27.5 27.5 0 0 0-29.059-7.018l.332.944ZM24 16a20.4 20.4 0 0 1 14.846 6.393l.728-.686A21.402 21.402 0 0 0 24 15a21.85 21.85 0 0 0-4.196.41l.192.98c1.32-.258 2.66-.39 4.004-.39Zm1.642 5.093a15.41 15.41 0 0 1 9.591 4.744l-.727.686a14.41 14.41 0 0 0-8.968-4.436l.104-.994ZM21.5 36.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM24 33a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWifiDisabledLarge;
