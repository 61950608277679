import type { SVGProps } from "react";
const SvgLocationPinSurroundingAreaMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 12.022a8.75 8.75 0 0 1 17.5 0v.004c-.013 1.46-.473 2.756-1.167 3.973l-.005.01-.007.01-7.125 11.247-.422.666-.422-.666-7.126-11.247-.006-.01c-.711-1.185-1.16-2.457-1.22-3.967v-.02Zm1-.01c.053 1.312.44 2.418 1.075 3.476l6.699 10.576 6.696-10.57c.632-1.111 1.018-2.236 1.03-3.474a7.75 7.75 0 0 0-15.5-.008Zm7.762-4.752a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Zm-5.75 4.75a5.75 5.75 0 1 1 11.5 0 5.75 5.75 0 0 1-11.5 0Zm.884 11.005c-2.791.316-5.209.9-6.944 1.666-.866.382-1.587.822-2.1 1.317-.513.496-.852 1.085-.852 1.752 0 .847.544 1.567 1.315 2.144.781.584 1.88 1.09 3.195 1.507 2.636.836 6.252 1.349 10.24 1.349 3.988 0 7.604-.513 10.24-1.349 1.314-.417 2.413-.923 3.194-1.508.771-.576 1.315-1.296 1.315-2.143 0-.662-.334-1.247-.84-1.74-.506-.493-1.218-.93-2.073-1.311-1.712-.764-4.098-1.347-6.856-1.668l-.115.993c2.699.314 4.977.88 6.564 1.588.795.354 1.391.733 1.782 1.114.39.379.539.723.539 1.024 0 .385-.25.845-.915 1.343-.656.49-1.638.955-2.898 1.355-2.514.797-6.023 1.302-9.938 1.302-3.914 0-7.423-.505-9.937-1.302-1.26-.4-2.241-.864-2.898-1.355-.665-.498-.914-.958-.914-1.343 0-.304.151-.65.547-1.033.397-.383 1.003-.765 1.81-1.121 1.61-.712 3.919-1.277 6.652-1.587l-.113-.994Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSurroundingAreaMedium;
