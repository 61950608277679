import type { SVGProps } from "react";
const SvgHandshakeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.75 9.425v11.419l-.258.143-12.148 6.75-.01.006-.228-.433.227.433-.002.001-.004.002-.012.006a1.406 1.406 0 0 1-.02.01l-.019.009a4.912 4.912 0 0 1-.614.232 5.749 5.749 0 0 1-3.324 0 4.912 4.912 0 0 1-.652-.251l-.012-.006-.004-.002h-.002v-.001l.232-.443-.233.443-.01-.006-12.15-6.75-.257-.143V9.425l.742.413 3.864 2.145 5.646-1.776.015-.004.014-.004c1.71-.426 3.227-.054 4.545.957 1.343-.956 2.762-1.471 4.275-.983l.002.001 5.791 1.81 3.863-2.146.742-.413Zm-15.492 2.371c-1.037-.72-2.177-.95-3.47-.63L7.95 13.002l-.205.064-.188-.104-3.308-1.838v9.132l11.884 6.602.02.01c.02.009.05.024.094.042.085.036.215.086.381.137a4.743 4.743 0 0 0 2.742 0 3.935 3.935 0 0 0 .476-.18l.02-.009 7.753-4.308-5.602-5.815-6.493 3.212h-.001c-1.12.56-2.261.253-2.9-.51-.651-.775-.725-1.95.117-2.958l.007-.009.008-.008c.302-.329.6-.658.896-.985 1.245-1.373 2.44-2.691 3.606-3.68Zm11.266 10.252 3.226-1.792v-9.131l-3.308 1.837-.187.104-.205-.064-6-1.875-.004-.002c-1.239-.4-2.488.087-3.928 1.257-1.174.954-2.378 2.283-3.702 3.742-.299.33-.603.665-.915 1.005-.536.648-.437 1.278-.112 1.666.337.402.976.615 1.688.258l.002-.001 6.824-3.375.328-.163.254.264 6.04 6.27Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandshakeMedium;
