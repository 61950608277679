import type { SVGProps } from "react";
const SvgNewspaperSbbMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.25 6.25h-.5v20c0 1.926 1.574 3.5 3.5 3.5H12v-1H8.25a2.509 2.509 0 0 1-2.5-2.5v-19h21.5V22.5h1V6.25h-23Zm2.25 4h18v-1h-18v1Zm.75 2h-.5v5.5h7v-5.5h-6.5Zm.5 4.5v-3.5h5v3.5h-5Zm7.75-.5h9v-1h-9v1Zm9-3h-9v-1h9v1Zm0 7.5h-9v-1h9v1Zm-18 0H15v-1H7.5v1Zm7.5 3H7.5v-1H15v1Zm-7.5 3h6v-1h-6v1ZM25.8 24l3.6 3.375h-4.275V24h-2.25v3.375H18.6L22.2 24h-2.7L15 28.5l4.5 4.5h2.7l-3.6-3.375h4.275V33h2.25v-3.375H29.4L25.8 33h2.7l4.5-4.5-4.5-4.5h-2.7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNewspaperSbbMedium;
