import type { SVGProps } from "react";
const SvgCloudParticlesSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M6 15.5h1m-2-2h1m2 1h1m-2-2h1m2 3h1m0-2h1m-3-1h1m-2-2h1m3 0h1m1 2h1m0-3h1m1 5h1m-4 1h1m-9.004-4.42c-3.211 0-3.849 6.42 1.2 6.42h10.502c4.981 0 4.8-6.42 1.05-6.27 1.65-5.526-5.701-8.064-7.951-2.987-1.982-1.616-5.149-.065-4.8 2.836Z"
    />
  </svg>
);
export default SvgCloudParticlesSmall;
