import type { SVGProps } from "react";
const SvgReduceSizeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 2.521v-.52h-1v.52h1Zm0 2.083V3.563h-1v1.041h1Zm0 2.084V5.646h-1v1.042h1Zm0 2.083V7.73h-1v1.042h1Zm0 2.083V9.813h-1v1.041h1Zm0 2.084v-1.042h-1v1.042h1Zm0 1.562v-.52h-1V14h-.02v1H15v-.5ZM2 15h.52v-1H2v1Zm1.563 0h1.041v-1H3.562v1Zm2.083 0h1.042v-1H5.646v1Zm2.083 0h1.042v-1H7.729v1Zm2.083 0h1.042v-1H9.813v1Zm2.084 0h1.041v-1h-1.041v1ZM16 16h4v1h-2.293l3.647 3.646-.708.707L17 17.707V20h-1v-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgReduceSizeSmall;
