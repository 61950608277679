import type { SVGProps } from "react";
const SvgArrowCircleResetSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.255 9H9v1H4V5.01h1v2.491C6.534 5.391 9.19 4 12 4c4.626 0 8 3.374 8 8 0 4.626-3.374 8-8 8-3.872 0-6.878-2.369-7.745-5.88l.97-.24C5.978 16.93 8.572 19 12 19c4.074 0 7-2.926 7-7s-2.926-7-7-7C9.093 5 6.413 6.677 5.255 9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleResetSmall;
