import type { SVGProps } from "react";
const SvgScannerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 5.75a1.5 1.5 0 0 0-1.5 1.5v2h24.5v-2a1.5 1.5 0 0 0-1.5-1.5H7.25Zm1.309 9.5-2.5-5h23.882l-2.5 5H8.559Zm.051 1-2.666 8h24.112l-2.666-8H8.61Zm-2.86 12.5v-3.5h24.5v3.5a1.5 1.5 0 0 1-1.5 1.5H7.25a1.5 1.5 0 0 1-1.5-1.5Zm-1-21.5a2.5 2.5 0 0 1 2.5-2.5h21.5a2.5 2.5 0 0 1 2.5 2.5v2.618l-.053.106-2.907 5.814 2.934 8.804.026.077v4.081a2.5 2.5 0 0 1-2.5 2.5H7.25a2.5 2.5 0 0 1-2.5-2.5v-4.081l.026-.077 2.934-8.804-2.907-5.814-.053-.106V7.25Zm19.25 21h3v-1h-3v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScannerMedium;
