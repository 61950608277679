import type { SVGProps } from "react";
const SvgIr55Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-55-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M44.09 4.235v2.057h-4.644l-.496 2.603a2.807 2.807 0 0 1 2.136-.886 3.677 3.677 0 0 1 2.223.688c.936.69 1.403 1.74 1.404 3.148 0 1.382-.5 2.476-1.498 3.283a4.283 4.283 0 0 1-2.791.949c-1.22 0-2.2-.328-2.94-.985-.742-.656-1.133-1.544-1.175-2.662h2.262c.026.377.163.738.395 1.036a1.917 1.917 0 0 0 2.9.016 2.41 2.41 0 0 0 .513-1.558c.03-.58-.162-1.15-.536-1.594a1.824 1.824 0 0 0-1.427-.597 1.895 1.895 0 0 0-1.695.98l-2.057-.031 1.095-6.447h6.33Zm8.979 0v2.057h-4.643l-.497 2.603a2.807 2.807 0 0 1 2.136-.886 3.678 3.678 0 0 1 2.224.688c.935.69 1.403 1.74 1.403 3.148 0 1.382-.5 2.476-1.498 3.283a4.283 4.283 0 0 1-2.79.949c-1.22 0-2.2-.328-2.941-.985-.741-.656-1.133-1.544-1.175-2.662h2.263c.025.377.162.738.394 1.036a1.917 1.917 0 0 0 2.9.016c.348-.443.53-.994.513-1.558.03-.58-.162-1.15-.536-1.594a1.824 1.824 0 0 0-1.427-.597 1.895 1.895 0 0 0-1.695.98l-2.057-.031 1.096-6.447h6.33ZM9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
    />
  </svg>
);
export default SvgIr55Negative;
