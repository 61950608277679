import type { SVGProps } from "react";
const SvgChartColumnTrendSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 3.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 0 0-1.229 2.36l-3.356 4.698a1.502 1.502 0 0 0-1.542.452l-2.374-.949L11 8.5a1.5 1.5 0 1 0-2.917.493l-2.79 2.233a1.5 1.5 0 1 0 .625.78l2.79-2.232a1.497 1.497 0 0 0 1.92-.284l2.373.949L13 10.5a1.5 1.5 0 1 0 2.729-.86l3.356-4.698A1.501 1.501 0 0 0 21 3.5 1.5 1.5 0 0 0 19.5 2ZM4 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm4 .504h3V21h2v-5.996h3V21h2V9.004h3V22H3v-4.996h3V21h2v-7.996ZM10 21v-6.996H9V21h1Zm5 0v-4.996h-1V21h1Zm5-10.996V21h-1V10.004h1Zm-15 8V21H4v-2.996h1ZM9.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm4.5 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChartColumnTrendSmall;
