import type { SVGProps } from "react";
const SvgCloudSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.28 9.875c.493.111.897.378 1.19.742.4.498.573 1.153.53 1.79a2.729 2.729 0 0 1-.824 1.786c-.52.5-1.265.807-2.208.807h-7c-.954 0-1.693-.307-2.2-.816-.503-.503-.737-1.164-.765-1.802-.028-.636.146-1.284.507-1.785a2.065 2.065 0 0 1 1.143-.811c.093-1.847 2.01-2.88 3.523-2.166.946-1.532 2.625-1.908 3.986-1.416a3.272 3.272 0 0 1 1.795 1.542c.318.605.447 1.329.323 2.13Zm-2.457-2.73c-1.05-.38-2.364-.045-2.998 1.386l-.266.6-.507-.415c-.972-.792-2.567-.044-2.388 1.444l.067.56h-.563c-.35 0-.636.168-.846.46-.216.301-.34.723-.32 1.158.019.431.175.84.474 1.14.292.293.762.522 1.492.522h7c.717 0 1.203-.228 1.515-.528a1.73 1.73 0 0 0 .519-1.134c.028-.421-.09-.818-.312-1.094-.212-.264-.536-.443-1.003-.425l-.699.028.2-.67c.242-.808.146-1.468-.116-1.965a2.273 2.273 0 0 0-1.25-1.068Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudSmall;
