import type { SVGProps } from "react";
const SvgFilterXMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.663 6.25h24.674l-.707.825-8.88 10.36v2.188h-1v-2.559l.12-.14 8.293-9.675H7.837l8.293 9.674.12.14V27.25H18v1h-2.75V17.434L6.37 7.075l-.707-.824ZM23.25 26.957l-3.396 3.396-.708-.707 3.397-3.396-3.397-3.397.707-.707 3.397 3.396 3.396-3.396.707.707-3.396 3.396 3.396 3.397-.707.707-3.396-3.396Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFilterXMedium;
