import type { SVGProps } from "react";
const SvgSunriseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.25 10.5V6h1v4.5h-1Zm-8.946-.621 3.402 3.387.706-.709L10.01 9.17l-.706.709Zm9.446 3.371c-3.05 0-5.5 2.45-5.5 5.5 0 1.29.392 2.531 1.096 3.445l-.793.61c-.856-1.111-1.303-2.575-1.303-4.055 0-3.602 2.898-6.5 6.5-6.5 3.603 0 6.5 2.898 6.5 6.5 0 1.546-.58 2.954-1.46 4.057l-.781-.623c.756-.95 1.24-2.142 1.24-3.434 0-3.05-2.449-5.5-5.5-5.5Zm-8.25 6H6v-1h4.5v1Zm17.127-9.941-3.399 3.376.705.71 3.4-3.377-.706-.71ZM27 18.25h4.5v1H27v-1Zm-19.5 7h20.99v-1H7.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSunriseMedium;
