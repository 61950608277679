import type { SVGProps } from "react";
const SvgBookSectionMarkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.75 5.75a2.5 2.5 0 0 0-2.5 2.5v17.051a3.49 3.49 0 0 1 2.5-1.05h19V5.75h-19Zm-3.5 22V8.25a3.5 3.5 0 0 1 3.5-3.5h20v20.502h-20a2.498 2.498 0 0 0-2.5 2.498 2.502 2.502 0 0 0 2.5 2.502L30 30.25v1l-20.25.002c-1.933 0-3.5-1.57-3.5-3.502Zm23.75.5H10.5v-1H30v1ZM16.25 15a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm3.331 2.25a2.75 2.75 0 0 0-1.581-5 1.75 1.75 0 1 1 1.75-1.75h1a2.75 2.75 0 1 0-4.331 2.25 2.75 2.75 0 0 0 1.581 5 1.75 1.75 0 1 1-1.75 1.75h-1a2.75 2.75 0 1 0 4.331-2.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBookSectionMarkMedium;
