import type { SVGProps } from "react";
const SvgHandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 13V4.5c0-.274.226-.5.5-.5s.5.226.5.5V13h1V5.5c0-.274.226-.5.5-.5s.5.226.5.5V13h1V8.483A.503.503 0 0 1 16.5 8c.274 0 .5.226.5.5V16c0 1.412-.24 2.485-.475 3.196a6.405 6.405 0 0 1-.318.795l-.005.009H9.785l-.002-.003-.389-.658-1.203-2.036a840.048 840.048 0 0 1-1.968-3.342c-.136-.238-.191-.414-.196-.538a.341.341 0 0 1 .116-.28.508.508 0 0 1 .713 0l2.29 2.29.854.854V5.5c0-.274.226-.5.5-.5s.5.226.5.5V13h1Zm4-5.914A1.503 1.503 0 0 1 18 8.5V16c0 1.528-.26 2.705-.526 3.51-.132.4-.266.708-.369.92a4.538 4.538 0 0 1-.166.31l-.012.02-.004.006-.001.002v.001h-.001L16.5 20.5l.42.27-.147.23H9.214l-.145-.245.43-.255-.43.254-.002-.004-.007-.012-.029-.048-.108-.184-.39-.659-1.203-2.035a820.284 820.284 0 0 1-1.974-3.353c-.173-.302-.314-.641-.328-.994a1.338 1.338 0 0 1 .408-1.029 1.508 1.508 0 0 1 2.127 0L9 13.873V5.5a1.503 1.503 0 0 1 2.053-1.394A1.504 1.504 0 0 1 12.5 3c.69 0 1.273.47 1.447 1.106A1.503 1.503 0 0 1 16 5.5v1.586Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandSmall;
