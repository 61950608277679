import type { SVGProps } from "react";
const SvgBatteryLightningMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M23.25 10.5V5.25h-3v-1.5h-6v1.5h-3v27h12V27m3-14.25-3 4.5h4.5L19.5 27l2.25-6.75h-4.5l3-7.5h6Z"
    />
  </svg>
);
export default SvgBatteryLightningMedium;
