import type { SVGProps } from "react";
const SvgPunctualityMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.75 4.25h1.75v-1H15v1h1.75v3h.5c6.35 0 11.5 5.15 11.5 11.5 0 6.352-5.15 11.5-11.5 11.5s-11.5-5.148-11.5-11.5v-.74h-1v.74c0 6.904 5.597 12.5 12.5 12.5s12.5-5.596 12.5-12.5c0-6.735-5.329-12.227-12-12.49V4.25Zm-.843 20.04 7.5-10.5-.814-.58-7.157 10.02-4.082-4.083-.708.707 4.5 4.5.418.417.343-.48Zm-1.91-16.842-1.448.388-.26-.966 1.45-.388.259.966Zm-4.146 1.718 1.298-.75-.5-.866-1.298.75.5.866Zm-1.202.922-1.06 1.06-.708-.707 1.06-1.06.708.707Zm-2.733 3.561.75-1.299-.866-.5-.75 1.299.866.5Zm-.58 1.4-.388 1.449-.966-.26.388-1.448.966.259Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPunctualityMedium;
