import type { SVGProps } from "react";
const SvgSaMp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.081 7.478h-1l.9-4.272h.88c1.2 0 2.341.3 2.341 1.725 0 1.745-1.58 2.547-3.12 2.547ZM19.681 15h2.8l1.12-5.315h1.62c3.121 0 5.901-1.666 5.901-5.054 0-3.49-3.54-3.631-6.12-3.631h-2.36L19.68 15ZM8.581 1h-4.62L1 15h2.66L6.221 3.406h.04L7.382 15h2.68l6.322-11.594h.039L13.783 15h2.96l2.981-14h-4.66L9.562 11.09h-.04L8.582 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaMp;
