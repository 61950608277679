import type { SVGProps } from "react";
const SvgRampUserMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 11.25c0-.523.477-1 1-1s1 .477 1 1-.477 1-1 1-1-.477-1-1Zm1-2c-1.076 0-2 .924-2 2s.924 2 2 2 2-.924 2-2-.924-2-2-2Zm-1.707 6h3.516l.138.276 1.48 2.96L13.9 21.45l-.8.6-2.25-3-.027-.036-.02-.04-.553-1.106v5.264l1.447 2.894.053.106v4.5l20.35-6.36.65-.202v8.68H8.25l-.15-.977 2.65-.828v-4.577L9.441 23.75H7.25V33h-1V16.957l-2 2V22.5h-1v-3.957l.146-.147 3-3 .147-.146Zm.707 7.5v-6.5h2v6.5h-2Zm24.5 9H11.526l20.224-6.32v6.32Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRampUserMedium;
