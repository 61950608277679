import type { SVGProps } from "react";
const SvgTicketsClassMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.75 4.75h-.5v3h-9v23.5h14.5v-3h9V4.75h-14Zm4.5 3h-4v-2h12.5v21.5h-8v-6.5H27v-1h-6.25v-2H27v-1h-6.25v-9h-.5Zm-.5 20v2.5H7.25V8.75h12.5v19Zm7.212-20.353v6.104h-1.218V9.505h-1.439V8.62c.3-.008.542-.038.73-.092.188-.052.36-.14.517-.258.225-.174.376-.465.453-.873h.957Zm-8.978 8.017V16.5h-4.396c0-.331.054-.648.162-.948.106-.3.258-.56.453-.777.15-.166.325-.33.528-.487.2-.16.603-.45 1.203-.873.292-.204.498-.393.613-.569a1.06 1.06 0 0 0 .177-.609.91.91 0 0 0-.237-.65.816.816 0 0 0-.62-.247c-.63 0-.952.452-.973 1.356h-1.171v-.133c0-.741.207-1.314.62-1.72.404-.395.926-.593 1.567-.593.568 0 1.042.162 1.423.484.414.351.621.822.621 1.416 0 .516-.165.962-.496 1.335a2.27 2.27 0 0 1-.305.294c-.094.074-.343.252-.747.533-.384.267-.658.472-.825.613a3.337 3.337 0 0 0-.46.49h2.863ZM18 20.75H9v-1h9v1Zm-9 3h9v-1H9v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketsClassMedium;
