import type { SVGProps } from "react";
const SvgGithubMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M6 29.25a3.51 3.51 0 0 0 1.294-.24c.41-.162.783-.399 1.097-.697a3.2 3.2 0 0 0 .73-1.045c.17-.39.256-.81.254-1.232v-1.072a3.069 3.069 0 0 1 .253-1.232c.17-.39.418-.745.731-1.044a3.377 3.377 0 0 1 1.097-.697 3.5 3.5 0 0 1 1.294-.24h10.5c.444-.003.883.08 1.294.24.41.161.783.398 1.097.697.313.298.562.653.73 1.044.17.391.256.81.254 1.232v1.072a3.07 3.07 0 0 0 .253 1.232c.17.39.418.746.731 1.044.314.3.687.536 1.097.697.41.16.85.243 1.294.241m-18 1.5c.444.002 1.258-.075 1.669-.225.41-.15.783-.371 1.097-.65a2.99 2.99 0 0 0 .73-.975c.17-.365.256-.756.254-1.15v-6m8.25 9c-.444.002-1.258-.075-1.669-.225a3.418 3.418 0 0 1-1.097-.65 2.99 2.99 0 0 1-.73-.975 2.703 2.703 0 0 1-.254-1.15v-6m-5.625 0A6.75 6.75 0 0 1 7.875 15v-1.125a6.933 6.933 0 0 1 1.195-3.84 7.312 7.312 0 0 1 .492-6.285 7.313 7.313 0 0 1 6.16 3.375h4.556a7.313 7.313 0 0 1 6.16-3.375 7.313 7.313 0 0 1 .492 6.286 6.933 6.933 0 0 1 1.195 3.839V15a6.75 6.75 0 0 1-6.75 6.75"
    />
  </svg>
);
export default SvgGithubMedium;
