import type { SVGProps } from "react";
const SvgThumbDownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.999 5H9v.782c.041-.036.084-.071.127-.105.694-.54 1.58-.668 2.373-.668h5a1.5 1.5 0 0 1 1.395 2.052 1.5 1.5 0 0 1 .723 2.447c.238.266.382.616.382 1 0 .385-.144.735-.382 1a1.5 1.5 0 0 1-1.118 2.5h-2.766l1.17 2.97.002.006a1.5 1.5 0 0 1-2.47 1.58l-.008-.006-.007-.009-4.142-4.54H8v-.001H4.999V5ZM8 8.49v4.518H5.999V6H8v2.49Zm1 4.518h.72l.15.164 4.28 4.694a.498.498 0 0 0 .85-.357.486.486 0 0 0-.029-.17l-1.436-3.647-.27-.684H17.5a.5.5 0 1 0 0-1H16v-1h1.5a.5.5 0 1 0 0-1H16v-1h1.5a.5.5 0 1 0 0-1H16v-1h.5a.5.5 0 0 0 0-1h-5c-.716 0-1.329.123-1.758.457-.403.314-.739.89-.742 2.027v4.516Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgThumbDownSmall;
