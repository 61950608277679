import type { SVGProps } from "react";
const SvgSaVb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.612 14.86a4.46 4.46 0 0 0 4.429-3.95h3.24l3.87-4.802.327.624a4.588 4.588 0 0 0-1.831 3.668 4.601 4.601 0 0 0 9.202 0 4.6 4.6 0 0 0-4.603-4.6c-.534 0-1.043.096-1.52.264L21.063 1h-3.236v1.415h2.38l.744 1.417H14.75l-.375-.713 1.331.006V1.707l-4.932.014v1.39l2 .006.726 1.38-1.222 1.768A4.456 4.456 0 0 0 6.152 10.4c0 2.46 1.996 4.46 4.46 4.46Zm14.634-7.6a3.14 3.14 0 0 1 0 6.28 3.142 3.142 0 0 1-3.137-3.14c0-.936.417-1.767 1.068-2.343l1.586 3.014 1.252-.66-1.593-3.028c.265-.072.536-.123.824-.123Zm-12.54.94c.37.352.64.797.797 1.292h-1.67l.873-1.292Zm8.327-2.953-3.356 4.147-2.186-4.152 5.542.005Zm-6.784.674 1.88 3.57h-1.15a4.455 4.455 0 0 0-1.47-2.477l.74-1.093Zm-3.637 1.434c.288 0 .559.054.822.13l-1.74 2.513.624.911h3.286a3.04 3.04 0 0 1-2.992 2.533A3.048 3.048 0 0 1 7.567 10.4a3.048 3.048 0 0 1 3.045-3.045ZM3.305 14.35c-.35-.533-.64-1.003-.87-1.414-.232-.412-.528-1.025-.89-1.841C1.181 10.277 1 9.264 1 8.054c0-1.122.199-2.158.591-3.1.392-.943.963-2.002 1.713-3.175h1.272a32.884 32.884 0 0 0-.944 1.76 25.08 25.08 0 0 0-.781 1.84c-.3.78-.453 1.679-.453 2.695 0 1.038.166 1.981.5 2.82.331.842.59 1.45.77 1.824.181.375.484.92.908 1.633H3.305v-.002Zm31.057-9.523c-.422-.956-.979-1.972-1.668-3.048v.004h-1.269c.413.702.71 1.243.9 1.623.184.38.388.841.605 1.38.217.537.38 1.08.49 1.622a8.25 8.25 0 0 1 .164 1.633c0 1.016-.156 1.928-.463 2.738-.309.811-.553 1.397-.735 1.76-.181.361-.503.969-.96 1.814h1.268c.459-.713.787-1.246.98-1.597.193-.352.463-.934.808-1.752.345-.816.518-1.8.518-2.948 0-1.197-.214-2.274-.638-3.23Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVb;
