import type { SVGProps } from "react";
const SvgSaXx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M9.44 5.8 7.42 1H4.4l3.06 6.38L1 14.96h3.48l4.36-5.6h.04l2.14 5.6h3.06l-3.24-7.3L16.62 1h-3.3L9.48 5.8zm13.34 0L20.76 1h-3.02l3.06 6.38-6.46 7.58h3.48l4.36-5.6h.04l2.14 5.6h3.06l-3.24-7.3L29.96 1h-3.3l-3.84 4.8z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaXx;
