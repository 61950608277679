import type { SVGProps } from "react";
const SvgWheelMicrophoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a9 9 0 0 0 0 18 8.69 8.69 0 0 0 2.603-.378l.294.956A9.675 9.675 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c3.485 0 6.626 1.76 8.417 4.475l-.834.55C17.975 4.59 15.147 3 12 3Zm6 6a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0v-6a1 1 0 0 0-1-1Zm-2 1a2 2 0 1 1 4 0v6a2 2 0 1 1-4 0v-6Zm-4 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm7.5 7.976V21H16v1h4v-1h-1.5v-1.024c1.901-.188 3.5-1.447 3.5-3.476h-1c0 1.477-1.246 2.5-3 2.5s-3-1.023-3-2.5h-1c0 2.03 1.599 3.288 3.5 3.476ZM5 12a7 7 0 0 1 7-7c1.985 0 3.765.819 5.04 2.146l.72-.692A7.947 7.947 0 0 0 12 4a8 8 0 1 0 0 16c.688 0 1.312-.112 1.922-.265l-.244-.97c-.564.142-1.1.235-1.678.235a7 7 0 0 1-7-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelMicrophoneSmall;
