import type { SVGProps } from "react";
const SvgAppIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 6.36A3.36 3.36 0 0 1 6.359 3h11.282A3.36 3.36 0 0 1 21 6.36v11.28A3.36 3.36 0 0 1 17.641 21H6.359A3.36 3.36 0 0 1 3 17.64V6.36ZM6.359 2A4.36 4.36 0 0 0 2 6.36v11.28A4.36 4.36 0 0 0 6.359 22h11.282A4.36 4.36 0 0 0 22 17.64V6.36A4.36 4.36 0 0 0 17.641 2H6.359Zm9.257 9.318L13.264 9h1.748L18 12l-2.988 3h-1.748l2.352-2.302h-2.924V15h-1.383v-2.302H8.401L10.753 15H9.004L6 12l3.004-3h1.749L8.4 11.318h2.908V9h1.383v2.318h2.924Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAppIconSmall;
