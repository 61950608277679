import type { SVGProps } from "react";
const SvgLocomotiveProfileMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m26.32 3.534.409-1.056-1.057.409A5.752 5.752 0 0 0 27.75 14a5.752 5.752 0 0 0 5.363-3.672l.41-1.057-1.057.41a4.75 4.75 0 0 1-6.147-6.147ZM23 8.25c0-1.63.822-3.07 2.074-3.925a5.75 5.75 0 0 0 6.601 6.601A4.75 4.75 0 0 1 23 8.25ZM8.527 6.334 8.25 6.15l-.277.185-4.5 3-.624.416.624.416 3.126 2.084H3v1h5.099l.151.101.151-.1h4.106c2.456 0 4.566 1.427 5.582 3.5H12.25v7h8.416l.88 2.428.01.027.013.024c.119.241.188.5.188.77a1.75 1.75 0 0 1-1.75 1.75H3v1h6.792c.111.779.48 1.475 1.015 2H3v1h10.185a3.46 3.46 0 0 0 .144 0H33v-1H15.706a3.491 3.491 0 0 0 1.015-2h3.286a2.75 2.75 0 0 0 2.75-2.75c0-.433-.109-.834-.28-1.187l-3.145-8.673c-.98-2.836-3.648-4.89-6.825-4.89H9.9l3.126-2.084.624-.416-.624-.416-4.5-3Zm4.669 25.416h.122a2.502 2.502 0 0 0 2.389-2h-4.9a2.502 2.502 0 0 0 2.389 2Zm7.107-9-1.813-5h-5.24v5h7.053ZM8.25 12.15l-3.599-2.4L8.25 7.351l3.599 2.4-3.599 2.398Zm1 5.6H3v-1h7.25v7H3v-1h6.25v-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveProfileMoonMedium;
