import type { SVGProps } from "react";
const SvgShoppingBagMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3.25a4.25 4.25 0 0 0-4.25 4.25v3.185H7.784l-.033.464-1.5 21-.038.536h23.574l-.038-.536-1.5-21-.034-.464h-5.968L22.25 7.5A4.25 4.25 0 0 0 18 3.25Zm3.245 8.435-.005 4.814 1 .002.005-4.816h5.04l1.428 20H7.287l1.428-20h5.035V16.5h1v-4.815h6.495Zm.002-1L21.25 7.5a3.25 3.25 0 1 0-6.5 0v3.185h6.497Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShoppingBagMedium;
