import type { SVGProps } from "react";
const SvgTicketMoonStarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4h20v5h-.5c-1.424 0-2.5 1.076-2.5 2.5s1.076 2.5 2.5 2.5h.5v5H2V4Zm1 1v13h18v-3.034c-1.727-.232-3-1.66-3-3.466 0-1.806 1.273-3.234 3-3.467V5H3Zm10 2V6h-1v1h-1v1h1v1h1V8h1V7h-1Zm4 0V6h-1v1h1Zm0 1v1h-1V8h1Zm0 3v-1h-1v1h1Zm0 1v1h-1v-1h1Zm0 3v-1h-1v1h1Zm0 1v1h-1v-1h1Zm-3-2v-1h1v-1h-1v-1h-1v1h-1v1h1v1h1ZM8.5 8a4.5 4.5 0 0 0 0 9c1.16 0 2.204-.45 2.996-1.162l.859-.773-1.15-.097C9.408 14.815 8 13.328 8 11.5c0-1.03.454-1.95 1.172-2.594l.856-.769-1.146-.101a1.824 1.824 0 0 1-.09-.011l-.058-.008A1.804 1.804 0 0 0 8.5 8ZM5 12.5a3.502 3.502 0 0 1 2.712-3.411A4.424 4.424 0 0 0 7 11.499a4.475 4.475 0 0 0 2.916 4.194A3.41 3.41 0 0 1 8.5 16 3.5 3.5 0 0 1 5 12.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketMoonStarSmall;
