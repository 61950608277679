import type { SVGProps } from "react";
const SvgSaAr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.046 1h-3.301l-2.922 13.962h2.802l1.22-5.8h1.06c1.29 0 1.428.749 1.636 1.88.037.202.076.416.125.64l.68 3.28h2.94l-1.34-5.62a1.492 1.492 0 0 0-1.42-1.28v-.04c2.28-.04 4.08-1.32 4.08-3.721C28.606.999 25.695 1 23.136 1h-.09Zm-.681 5.961h-1.02l.78-3.76h1.16c1.14 0 2.401 0 2.401 1.46 0 1.84-1.82 2.3-3.321 2.3Zm-9.68-5.96h-3.1L1.123 14.962h3.1l1.782-3.2h5.94l.481 3.2h2.8L12.686 1Zm-1.12 8.56h-4.32l3.36-6.08h.08l.88 6.08Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaAr;
