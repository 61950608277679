import type { SVGProps } from "react";
const SvgSeatSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM6 6a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2.03 12.67-4-11 .94-.34L8.85 18H16v1H8.15l-.12-.33ZM7.71 9H7l.239.668 2.5 7 .119.332h6.546l2.44 4.768.891-.456-2.58-5.04-.14-.272h-6.452L8.42 10h.944l1.379 3.685.121.325h4.117v-1h-3.423l-1.379-3.685L10.057 9H7.71Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeatSmall;
