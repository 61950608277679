import type { SVGProps } from "react";
const SvgLipsHandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.307 6.41C8.114 6.175 8.902 6 9.5 6c1.128 0 1.814.272 2.255.597a2.4 2.4 0 0 1 .245.207 2.4 2.4 0 0 1 .245-.207C12.686 6.272 13.372 6 14.5 6c.598 0 1.385.174 2.192.41.82.24 1.707.558 2.52.874a57.994 57.994 0 0 1 2.934 1.233l.047.021.012.006.003.001h.001L22 9l.21-.454.799.37-.727.496L22 9l.282.413h-.001l-.004.003-.013.01-.053.035-.199.132a49.12 49.12 0 0 1-3.196 1.934c-1.78.984-4.007 2.052-5.816 2.37v.189a1.5 1.5 0 0 1 1.947 1.02 1.5 1.5 0 0 1 2 1A1.5 1.5 0 0 1 19 17.5V21h-1v-3.5a.5.5 0 0 0-1 0V20h-1v-3.5a.5.5 0 0 0-1 0V19h-1v-3.5a.5.5 0 0 0-1 0V18h-1v-7.5a.5.5 0 0 0-1 0V19h-1v-1.5a.5.5 0 1 0-1 0V21H8v-3.5a1.5 1.5 0 0 1 2-1.414v-2.42c-1.76-.51-3.757-1.528-5.33-2.425a50.11 50.11 0 0 1-2.893-1.788l-.044-.03-.011-.008-.003-.002h-.001L2 9l-.21-.454h.002l.003-.002.012-.006.047-.021a36.178 36.178 0 0 1 .825-.366 58.036 58.036 0 0 1 2.108-.867 32.49 32.49 0 0 1 2.52-.873ZM2 9l-.283.412-.726-.497.8-.37L2 9Zm16.852-.784c.55.213 1.07.426 1.515.614a59.7 59.7 0 0 1-1.508.27c-1.71.284-3.922.575-6.069.635a1.5 1.5 0 0 0-2.555-.042c-1.993-.115-3.967-.397-5.474-.657a59.56 59.56 0 0 1-1.128-.206c.445-.188.965-.4 1.515-.614.8-.31 1.658-.617 2.44-.846C8.383 7.138 9.054 7 9.5 7c.958 0 1.425.228 1.662.403.124.09.206.181.279.266l.012.015c.03.036.079.092.124.135l.001.001c.04.037.19.18.422.18.232 0 .382-.143.422-.18.046-.044.094-.1.125-.136l.012-.015a1.61 1.61 0 0 1 .278-.266C13.075 7.228 13.542 7 14.5 7c.445 0 1.117.138 1.912.37.782.23 1.64.536 2.44.846ZM4.55 10.014c.197.117.402.236.615.358 1.477.842 3.264 1.75 4.835 2.248v-1.94c-1.984-.123-3.926-.402-5.41-.659a5.142 5.142 0 0 1-.04-.007ZM13 12.88v-2.15c2.153-.073 4.338-.362 6.023-.642l.425-.072c-.348.205-.722.42-1.115.637-1.73.956-3.744 1.908-5.333 2.227Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLipsHandSmall;
