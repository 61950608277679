import type { SVGProps } from "react";
const SvgFaceWorkerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.254 13.25a7.692 7.692 0 0 0-.004.25 2.74 2.74 0 0 1-.628 1.75h16.756a2.738 2.738 0 0 1-.628-1.75 6.91 6.91 0 0 0-.004-.25H19.5v-1h6.15a7.752 7.752 0 0 0-15.3 0h6.15v1h-6.246Zm18.246 2a1.75 1.75 0 0 1-1.75-1.75 8.75 8.75 0 0 0-17.5 0 1.75 1.75 0 0 1-1.75 1.75v1h3.15a8.75 8.75 0 1 0 14.699 0H28.5v-1Zm-4.377 1H11.876A7.708 7.708 0 0 0 10.25 21a7.75 7.75 0 1 0 13.873-4.75Zm-9.123 3a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm7.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-6.46 4.787A5.74 5.74 0 0 0 18 26.75c1.953 0 3.673-.98 4.71-2.464l-.82-.572A4.736 4.736 0 0 1 18 25.75a4.74 4.74 0 0 1-3.89-2.037l-.82.574Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaceWorkerMedium;
