import type { SVGProps } from "react";
const SvgTrainProfileSignParkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#train-profile-sign-parking-medium_svg__a)">
      <path
        stroke="currentColor"
        d="M24.75 15V6.75H27c1.477 0 2.25.746 2.25 2.25 0 1.5-.753 2.248-2.25 2.25h-2.25m1.5 6v15m-6.734-9H11.25v-6h6.11m-8.603 12a3.001 3.001 0 0 0 6 0M3 17.25h5.25v6H3m0-10.5h8.007c2.955 0 5.44 1.911 6.354 4.557l3.156 8.702c.149.3.24.634.24.991a2.25 2.25 0 0 1-2.25 2.25H3m30 3H3m18.75-28.5h9c.83 0 1.5.671 1.5 1.5v10.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5V5.25c0-.829.67-1.5 1.5-1.5Zm-13.5 9-4.5-3 4.5-3 4.5 3-4.5 3Z"
      />
    </g>
    <defs>
      <clipPath id="train-profile-sign-parking-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTrainProfileSignParkingMedium;
