import type { SVGProps } from "react";
const SvgTorchMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.546 4.847-.796-.585v7.794l-3.842-1.28-.658-.22v5.194a5 5 0 0 0 5 5c2.793 0 4.751-2.271 4.997-4.938v-.005a4.345 4.345 0 0 0 .036-.29c.02-.195.04-.475.048-.822.014-.693-.028-1.66-.25-2.76-.447-2.203-1.625-4.95-4.535-7.088Zm3.711 10.82v.001l-.003.018-.002.02c-.203 2.275-1.83 4.044-4.002 4.044a4 4 0 0 1-4-4v-3.806l3.842 1.28.658.22v-7.15c2.088 1.841 2.984 4.032 3.35 5.84a11.6 11.6 0 0 1 .23 2.54 9.356 9.356 0 0 1-.065.936 1.987 1.987 0 0 1-.006.046l-.002.01ZM14.25 22.75h-.61l.12.598 1.5 7.5.08.402h3.82l.08-.402 1.5-7.5.12-.598h-6.61Zm1.91 7.5-1.3-6.5h4.78l-1.3 6.5h-2.18Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTorchMedium;
