import type { SVGProps } from "react";
const SvgDocumentDocMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.265 6.25h9.692l.146.146 7.5 7.5.147.147V29.75H9.25v-.5l.015-22.5v-.5Zm1 1-.015 21.5h15.5v-14h-7.5v-7.5h-7.986Zm8.985.707 5.793 5.793H19.25V7.957ZM13.605 22.5H12v3.885h1.62a2.81 2.81 0 0 0 .795-.105c.21-.077.399-.2.555-.36a2.13 2.13 0 0 0 .57-1.5 1.92 1.92 0 0 0-.6-1.5 1.336 1.336 0 0 0-.57-.315 2.298 2.298 0 0 0-.765-.105Zm-.735 3.24v-2.505l.63.03a1.17 1.17 0 0 1 .87.3c.208.263.31.595.285.93a1.5 1.5 0 0 1-.315 1.005.899.899 0 0 1-.345.24c-.164.02-.33.02-.495 0h-.63ZM18 22.35c.497-.009.977.18 1.334.525a2.1 2.1 0 0 1 .585 1.5c.02.508-.14 1.007-.45 1.41a1.83 1.83 0 0 1-1.5.675 1.86 1.86 0 0 1-1.5-.69 2.206 2.206 0 0 1-.434-1.395 2.025 2.025 0 0 1 .57-1.5A1.905 1.905 0 0 1 18 22.35Zm-.023.72a.973.973 0 0 0-.758.375 1.5 1.5 0 0 0-.3.99 1.5 1.5 0 0 0 .3 1.005c.095.11.213.196.345.255.145.072.304.108.465.105a.975.975 0 0 0 .75-.33c.224-.297.336-.664.316-1.035a1.5 1.5 0 0 0-.3-.99.945.945 0 0 0-.886-.375h.068Zm5.603 2.88a1.77 1.77 0 0 0 .42-1.065l-.975-.015a1.004 1.004 0 0 1-.285.675.794.794 0 0 1-.615.24.915.915 0 0 1-.84-.465 1.665 1.665 0 0 1-.21-.9 1.65 1.65 0 0 1 .21-.885.977.977 0 0 1 1.5-.285.84.84 0 0 1 .3.51h.855a1.334 1.334 0 0 0-.39-.87 1.71 1.71 0 0 0-1.335-.555 1.771 1.771 0 0 0-1.41.6 2.1 2.1 0 0 0-.54 1.5 2.25 2.25 0 0 0 .45 1.425 1.786 1.786 0 0 0 1.5.66 1.694 1.694 0 0 0 1.365-.57Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentDocMedium;
