import type { SVGProps } from "react";
const SvgIr65Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-65-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M42.276 7.123h2.192a3.89 3.89 0 0 0-.749-1.875c-.678-.86-1.655-1.29-2.933-1.29a3.751 3.751 0 0 0-3.208 1.622c-.81 1.118-1.214 2.663-1.214 4.635 0 1.846.378 3.284 1.135 4.315.757 1.031 1.816 1.547 3.177 1.547a3.84 3.84 0 0 0 2.728-1.068c.84-.791 1.261-1.838 1.261-3.14a4.051 4.051 0 0 0-.938-2.737 3.26 3.26 0 0 0-2.61-1.155 3.009 3.009 0 0 0-1.414.308c-.44.25-.82.596-1.108 1.013.05-.657.168-1.307.355-1.938.357-1.05.961-1.574 1.813-1.574.804 0 1.309.445 1.514 1.337Zm-3.027 3.345a1.53 1.53 0 0 1 1.317-.696 1.64 1.64 0 0 1 1.41.736c.292.425.441.932.427 1.448a2.428 2.428 0 0 1-.473 1.495 1.586 1.586 0 0 1-1.333.672 1.546 1.546 0 0 1-1.34-.704 2.489 2.489 0 0 1-.45-1.48 2.46 2.46 0 0 1 .442-1.47Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M53.069 4.235v2.057h-4.643l-.497 2.603a2.807 2.807 0 0 1 2.136-.886 3.678 3.678 0 0 1 2.224.688c.935.69 1.403 1.74 1.403 3.148 0 1.382-.5 2.476-1.498 3.283a4.283 4.283 0 0 1-2.79.949c-1.22 0-2.2-.328-2.941-.985-.741-.656-1.133-1.544-1.175-2.662h2.263c.025.377.162.738.394 1.036a1.917 1.917 0 0 0 2.9.016c.348-.443.53-.994.513-1.558.03-.58-.162-1.15-.536-1.594a1.824 1.824 0 0 0-1.427-.597 1.895 1.895 0 0 0-1.695.98l-2.057-.031 1.096-6.447h6.33ZM9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
    />
  </svg>
);
export default SvgIr65Negative;
