import type { SVGProps } from "react";
const SvgCameraSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.14 4h5.72l.115.342L15.857 7H22v13H2V6h3v1h3.14l.886-2.658L9.14 4Zm.72 1h4.28l.663 2h-5.61l.667-2ZM3 19V8h18v11H3Zm6-5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCameraSmall;
