import type { SVGProps } from "react";
const SvgRx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="rx_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.204 4.2 4.186 15.8H8.42l1.905-4.609 1.68-4.091h8.962l-1.21 2.788h-7.438a65.89 65.89 0 0 0 1.557 1.596 238.04 238.04 0 0 0 2.879 2.799c.493.472 1.03.977 1.613 1.517h10.777l1.926-1.484 2.129-1.64 1.389 3.124h6.228l-1.926-3.17-1.725-2.877L45.611 4.2h-6.43l-3.585 2.675-1.3-2.675h-6.071l3.204 5.575-8.447 5.575c-.418-.33-.915-.73-1.49-1.202a99.76 99.76 0 0 0-2.229-1.765h.874c.493 0 .892-.004 1.199-.011a8.64 8.64 0 0 0 .66-.034 1.68 1.68 0 0 0 .673-.157c.24-.232.424-.517.537-.832L26.232 4.2H9.204Z"
    />
  </svg>
);
export default SvgRx;
