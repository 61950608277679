import type { SVGProps } from "react";
const SvgMoneyExchangeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.328 3C7.147 2.99 3.01 7.03 3 12.001L2 12C2.01 6.448 6.623 1.99 12.33 2c3.53.007 6.782 1.7 8.67 4.523V4h1v5h-5V8h3.707c-1.557-3.077-4.793-4.993-8.379-5l.001-.5V3ZM21 11.998c-.02 4.983-4.126 9.021-9.29 9.002-3.516-.013-6.797-1.985-8.38-5H7v-1H2v5h1v-2.606c1.901 2.819 5.203 4.593 8.706 4.606 5.698.021 10.272-4.445 10.294-9.998l-1-.004Zm-11.86.386v.047h-.732l-.363.836h1.17c.391 2.51 1.601 3.765 3.63 3.765.86 0 1.626-.166 2.295-.497.047-.023.172-.093.377-.21v-1.34c-.73.395-1.244.646-1.545.755-.3.107-.644.16-1.032.16-.469 0-.873-.107-1.21-.322-.324-.207-.602-.545-.834-1.018a4.98 4.98 0 0 1-.41-1.293h3.2l.374-.835h-3.67v-.003a11.07 11.07 0 0 1-.007-.287c0-.125.005-.313.014-.56h4.033l.376-.835h-4.293a5.36 5.36 0 0 1 .212-.835c.401-1.217 1.123-1.825 2.167-1.825.57 0 1.127.155 1.674.466.225.13.541.33.951.598l.485-1.09c-.924-.715-1.955-1.072-3.09-1.072-1.608 0-2.716.829-3.322 2.486-.146.395-.257.82-.335 1.273h-.847l-.363.835H9.16l-.007.047c-.01.252-.014.41-.014.478v.276Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMoneyExchangeSmall;
