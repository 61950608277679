import type { SVGProps } from "react";
const SvgArrowCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 18c0-6.248 4.502-10.75 10.75-10.75 4.595 0 8.83 2.741 10.511 6.5H22.5v1h7.25V7.5h-1v4.574C26.596 8.608 22.435 6.25 18 6.25 11.2 6.25 6.25 11.2 6.25 18S11.2 29.75 18 29.75c5.696 0 10.11-3.476 11.381-8.632l-.971-.24C27.254 25.571 23.253 28.75 18 28.75c-6.248 0-10.75-4.502-10.75-10.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleMedium;
