import type { SVGProps } from "react";
const SvgBinocularsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 4h-.39l-.095.379-.944 3.777-1.795.897-.241.12-.032.269-.897 7.629c-.07.297-.106.608-.106.929 0 2.23 1.77 4 4 4a3.974 3.974 0 0 0 4-4.066V10.17a3.068 3.068 0 0 1 1-.165c.352 0 .688.058 1 .165l.001 7.74L13 18c0 2.23 1.77 4 4 4s4.001-1.77 4.001-4c0-.32-.037-.631-.106-.929l-.897-7.63-.032-.268-.241-.12-1.795-.897-.944-3.777L16.891 4h-3.89v.5L13 8h1V5h2.11l.906 3.621.056.223.205.103 1.759.88.607 5.158a3.998 3.998 0 0 0-2.643-.98c-1.201 0-2.27.512-3 1.334v-4.588A2.98 2.98 0 0 1 15.001 13h1A3.97 3.97 0 0 0 12 9.004c-2.23 0-4 1.765-4 3.996h1a2.98 2.98 0 0 1 1-2.25v4.59a3.988 3.988 0 0 0-3-1.335c-1.018 0-1.94.368-2.642.98l.607-5.159 1.759-.879.205-.103.056-.223L7.89 5H10v3.037h1V4H7.5ZM14 18h.001v-.079A2.968 2.968 0 0 1 17 15.005c1.419 0 2.582.941 2.91 2.245l.09.778A2.974 2.974 0 0 1 17 21c-1.678 0-3-1.322-3-3Zm-4 0v-.057a2.97 2.97 0 0 0-3-2.938c-1.418 0-2.581.94-2.908 2.244l-.092.78A2.973 2.973 0 0 0 7 21a2.974 2.974 0 0 0 3.001-3Zm6 .001a.988.988 0 0 1 1-.999v-1c-1.113 0-2 .884-2 1.999h1Zm-11 .001c0-1.114.884-2 1.999-2v1a.987.987 0 0 0-.999 1H5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBinocularsSmall;
