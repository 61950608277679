import type { SVGProps } from "react";
const SvgNetworkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 6c0-.999.751-1.75 1.75-1.75s1.75.751 1.75 1.75S9.999 7.75 9 7.75 7.25 6.999 7.25 6ZM9 3.25C7.449 3.25 6.25 4.449 6.25 6S7.449 8.75 9 8.75c.492 0 .949-.12 1.343-.335l3.836 5.27A5.716 5.716 0 0 0 12.25 18c0 1.435.509 2.736 1.358 3.737l-3.263 3.183a3.536 3.536 0 0 0-2.095-.67c-1.969 0-3.5 1.527-3.5 3.497s1.53 3.503 3.5 3.503 3.509-1.53 3.509-3.503c0-.809-.26-1.543-.7-2.127l3.261-3.18A5.744 5.744 0 0 0 18 23.75a5.714 5.714 0 0 0 4.994-2.87l2.9 1.343a3.67 3.67 0 0 0-.144 1.027c0 1.98 1.52 3.5 3.5 3.5s3.5-1.52 3.5-3.5-1.52-3.5-3.5-3.5c-1.255 0-2.326.61-2.941 1.563l-2.897-1.341c.22-.613.34-1.277.34-1.972a5.762 5.762 0 0 0-.83-3l3.637-2.534a2.742 2.742 0 0 0 1.94.784c1.538 0 2.766-1.204 2.766-2.75A2.74 2.74 0 0 0 28.5 7.742a2.727 2.727 0 0 0-2.75 2.758c0 .41.085.797.24 1.144l-3.66 2.549A5.731 5.731 0 0 0 18 12.256a5.778 5.778 0 0 0-3.016.836L11.118 7.78A2.756 2.756 0 0 0 11.75 6c0-1.551-1.199-2.75-2.75-2.75Zm19.5 5.492c-.988 0-1.75.766-1.75 1.758 0 .99.76 1.75 1.75 1.75.994 0 1.765-.764 1.765-1.75A1.74 1.74 0 0 0 28.5 8.742ZM18 13.256c-2.656 0-4.75 2.09-4.75 4.744A4.71 4.71 0 0 0 18 22.75 4.712 4.712 0 0 0 22.752 18c0-2.654-2.096-4.744-4.752-4.744Zm8.75 9.994c0-1.428 1.072-2.5 2.5-2.5s2.5 1.072 2.5 2.5-1.072 2.5-2.5 2.5-2.5-1.072-2.5-2.5Zm-18.5 2c-1.418 0-2.5 1.08-2.5 2.497 0 1.42 1.083 2.503 2.5 2.503 1.42 0 2.509-1.085 2.509-2.503 0-1.415-1.088-2.497-2.509-2.497Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNetworkMedium;
