import type { SVGProps } from "react";
const SvgBikeProfileSignParkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 3.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-7Zm.5-1.5c-.83 0-1.5.67-1.5 1.5v7c0 .828.67 1.5 1.5 1.5H17v10h1V12h2.5a1.5 1.5 0 0 0 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5h-6Zm2 2H16v6h1V8h1c.577-.001 1.1-.146 1.477-.522.378-.376.523-.9.523-1.478 0-.582-.15-1.106-.529-1.481C19.093 4.144 18.571 4 18 4h-1.5ZM18 7h-1V5h1c.414 0 .641.104.767.229.125.124.233.35.233.771 0 .422-.106.647-.229.77-.124.124-.35.23-.772.23ZM7 9h4.434l-.518.777-1.845 2.769.742 1.633A4.004 4.004 0 0 1 15 18c0 2.208-1.794 4-4 4s-4-1.792-4-4a4 4 0 0 1 1.903-3.405L8.18 13H3.35l-.528 1.45A3.99 3.99 0 0 1 5 18a4.007 4.007 0 0 1-2.864 3.835l-.284-.958A3.007 3.007 0 0 0 4 18c0-1.29-.824-2.389-1.98-2.81l-.469-.171.171-.47.808-2.22.12-.329h5.582l1.334-2H7V9Zm2.322 6.514a3 3 0 1 0 .91-.415l1.224 2.694-.91.413-1.224-2.691ZM4 11H2v-1h2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBikeProfileSignParkingSmall;
