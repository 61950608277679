import type { SVGProps } from "react";
const SvgBusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.019 6.519C7.533 7.005 7.25 7.789 7.25 9v7.75h21.5V9c0-1.211-.282-1.995-.769-2.481-.486-.486-1.27-.769-2.481-.769h-15c-1.211 0-1.995.283-2.481.769ZM7.25 27.25v-9.5h21.5v9.5H7.25Zm.062-21.438C8.06 5.063 9.152 4.75 10.5 4.75h15c1.348 0 2.44.313 3.188 1.062.749.749 1.062 1.84 1.062 3.188v4.75h1.5v2.5h-1.5v15h-5.498v-3H11.75v3h-5.5v-15h-1.5v-2.5h1.5V9c0-1.348.313-2.44 1.062-3.188ZM29.75 15.25h.5v-.5h-.5v.5Zm-4.498 13h3.498v2h-3.498v-2Zm-18.002 0h3.5v2h-3.5v-2Zm-1-13.5v.5h-.5v-.5h.5Zm19.25-7h-15v1h15v-1Zm-15 15a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM10.25 24c0-.138.113-.25.25-.25s.25.112.25.25a.25.25 0 0 1-.5 0Zm14 0a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBusMedium;
