import type { SVGProps } from "react";
const SvgIr47Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-47-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.251 4.2h4.07L8.286 15.8H4.2L9.251 4.2Zm16.818 8.166a.93.93 0 0 0 .849-.553L30.22 4.2H16.038l-5.035 11.6h4.052l3.812-8.69h6.014l-1.206 2.775-5.243-.019 4.231 5.934h4.613l-3.135-3.434h1.928Zm18.86-1.163v1.954h-1.456v2.642H41.22v-2.642h-4.825V10.99l4.944-6.755h2.136v6.968h1.455Zm-3.662.024V6.924l-2.974 4.303h2.974ZM53.55 4.235v1.961c-.063.069-.25.282-.56.641-.433.496-.863 1.12-1.29 1.87-.427.752-.76 1.473-.997 2.164-.5 1.44-.804 3.082-.91 4.928h-2.388c.016-.87.167-1.835.455-2.895.287-1.06.684-2.12 1.19-3.18.722-1.497 1.456-2.613 2.199-3.346h-5.458V4.235h7.76Z"
    />
  </svg>
);
export default SvgIr47Negative;
