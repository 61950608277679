import type { SVGProps } from "react";
const SvgElephantLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30.374 13.563A10.476 10.476 0 0 1 34 21.5v2c0 1.88-.494 3.645-1.36 5.172a6.997 6.997 0 0 0 8.253-4.398l1.37-3.76a7 7 0 0 0-11.89-6.951Zm.805 17.099a10.55 10.55 0 0 1-2.15 1.766 9.483 9.483 0 0 0 4.692 3.172 10.479 10.479 0 0 1-2.542-4.938Zm-3.745 2.576A10.47 10.47 0 0 1 23.5 34a10.47 10.47 0 0 1-4.022-.798c.973 2.961 2.435 5.435 4.096 7.216 1.868 2 3.944 3.082 5.836 3.082H32v-2h-.93c-.527 0-1.122-.314-1.646-.757-.545-.46-1.088-1.12-1.51-1.945-.726-1.422-1.092-3.336-.48-5.56Zm-9.501-.834a10.554 10.554 0 0 1-2.16-1.794 10.476 10.476 0 0 1-2.55 4.99 9.494 9.494 0 0 0 4.71-3.196Zm-3.573-3.733a10.452 10.452 0 0 1-1.36-5.17v-2a10.48 10.48 0 0 1 3.626-7.939 6.998 6.998 0 0 0-9.491-1.055m10.278.437a8 8 0 0 0-13.615 7.913l1.368 3.759a7.997 7.997 0 0 0 9.755 4.94l.007.009a9.475 9.475 0 0 1-3.673 6.48L9.83 37.132l1.78-.19a10.49 10.49 0 0 0 6.857-3.613c1.012 3.164 2.568 5.836 4.375 7.772 1.977 2.117 4.299 3.4 6.567 3.4h1.724a.999.999 0 0 0 1.867-.5v-3a1 1 0 0 0-1.867-.5h-.064c-.174 0-.541-.132-1-.52-.44-.371-.902-.926-1.265-1.637-.637-1.247-.972-2.975-.35-5.04a10.488 10.488 0 0 0 6.878 3.638l1.78.19-1.424-1.085a9.48 9.48 0 0 1-3.667-6.41l.057-.08a7.996 7.996 0 0 0 9.754-4.94l1.369-3.76a7.998 7.998 0 0 0-13.615-7.913A10.451 10.451 0 0 0 23.5 11c-2.27 0-4.37.72-6.087 1.944ZM14.36 28.67a6.998 6.998 0 0 1-8.254-4.397l-1.368-3.76a7 7 0 0 1 2.397-8.007M23.5 12a9.5 9.5 0 0 0-9.5 9.5v2a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5v-2a9.5 9.5 0 0 0-9.5-9.5ZM18 23a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm13.001-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgElephantLarge;
