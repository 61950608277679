import type { SVGProps } from "react";
const SvgButtonPowerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 5v5h1V5h-1Zm3.224 2.81A5.001 5.001 0 0 1 17 12c0 2.756-2.244 5-5 5s-5-2.244-5-5c0-1.748.905-3.286 2.274-4.182l-.548-.837A5.992 5.992 0 0 0 6 12c0 3.309 2.692 6 6 6s6-2.691 6-6a6.001 6.001 0 0 0-2.73-5.028l-.546.837Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgButtonPowerSmall;
