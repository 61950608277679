import type { SVGProps } from "react";
const SvgEscalatorMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.996 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm10.055.953A4.19 4.19 0 0 1 23.25 8.5h4.5A4.254 4.254 0 0 1 32 12.75 4.254 4.254 0 0 1 27.75 17h-2.572l-9.229 10.546A4.188 4.188 0 0 1 12.75 29h-6a4.255 4.255 0 0 1-4.25-4.25 4.254 4.254 0 0 1 4.25-4.25h3.247V13h4v3.87l6.052-6.914.002-.003Zm-7.054 8.06V14h-2v6.3l2-2.286ZM23.25 9.5a3.19 3.19 0 0 0-2.442 1.107l-.003.003-9.381 10.72-.15.17H6.75a3.254 3.254 0 0 0-3.25 3.25C3.5 26.54 4.96 28 6.75 28h6a3.19 3.19 0 0 0 2.442-1.106l.003-.004 9.38-10.72.15-.17h3.025A3.254 3.254 0 0 0 31 12.75a3.254 3.254 0 0 0-3.25-3.25h-4.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEscalatorMedium;
