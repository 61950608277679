import type { SVGProps } from "react";
const SvgStairsUserSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 3.5c0-.257.243-.5.5-.5s.5.243.5.5-.243.5-.5.5a.527.527 0 0 1-.5-.5ZM9.5 2C8.69 2 8 2.69 8 3.5S8.69 5 9.5 5 11 4.31 11 3.5 10.31 2 9.5 2ZM8.293 6h2.516l.138.276.98 1.96L13.4 10.2l-.8.6-1.5-2-.027-.036-.02-.04L11 8.618v2.73l.916 1.375.084.126V15h2v-3h4V9h4v1h-3v3h-4v3h-4v3H7v3H2v-1h4v-3h1.11L8 14.438V7.707l-1 1V11H6V8.293l.146-.147 2-2L8.293 6ZM9 11V7h1v4H9Zm0 1v2.562l-.015.06L8.14 18H10v-3h1v-1.849L10.232 12H9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStairsUserSmall;
