import type { SVGProps } from "react";
const SvgEc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ec_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.129 15.732a.9.9 0 0 0 .358.068h12.637l-4.078-3.507H9.184l1.792-1.776h7.931L20.7 8.741h-7.933l1.747-1.798h7.932l2.71-2.743h-12.5a1.628 1.628 0 0 0-.852.202l-.222.18-.27.225-6.81 6.857a.806.806 0 0 0-.315.629.636.636 0 0 0 .248.517l.446.382 2.554 2.046.404.337c.077.083.18.138.291.158Zm15.282.023.334.045h11.942l3.495-3.507H24.686l5.265-5.35h12.726L45.366 4.2H27.309l-7.26 7.419c-.137.125-.26.265-.369.415a.562.562 0 0 0-.057.281.647.647 0 0 0 .179.473l.338.27 2.42 2.09.313.292c.113.084.233.159.357.225l.18.09Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEc;
