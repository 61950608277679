import type { SVGProps } from "react";
const SvgPaperClipMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.75 6v19.374l-.198.15-5.25 3.975-.302.228-.302-.228-5.25-3.975-.198-.15V6.25h8.5v14.61l-.18.15-2.258 1.875-.32.266-.32-.267-2.243-1.875-.179-.15V10.5h1v9.891l1.744 1.458 1.756-1.459V7.25h-6.5v17.626L18 28.473l4.75-3.597V6h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPaperClipMedium;
