import type { SVGProps } from "react";
const SvgDisplayBinaryCodeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 4.75h-.5v20.5h12v3.5H10.5v1H24v-1h-4.75v-3.5h12V4.75H3.75Zm14.5 20.5h-2v3.5h2v-3.5Zm-14-1V5.75h26v18.5h-26Zm9.355-6.064c-.21.315-.355.778-.355 1.314s.146 1 .355 1.314c.212.317.45.436.645.436s.433-.119.645-.436c.21-.315.355-.778.355-1.314s-.146-1-.355-1.314c-.212-.317-.45-.436-.645-.436s-.433.119-.645.436Zm-.832-.554c.331-.497.844-.882 1.477-.882.633 0 1.146.385 1.477.882.333.5.523 1.162.523 1.868 0 .706-.19 1.368-.523 1.868-.331.497-.844.882-1.477.882-.633 0-1.146-.385-1.477-.882-.333-.5-.523-1.162-.523-1.868 0-.706.19-1.368.523-1.868ZM19.25 10.5c0-.536.146-1 .355-1.314.212-.317.45-.436.645-.436s.433.119.645.436c.21.315.355.778.355 1.314s-.146 1-.355 1.314c-.212.317-.45.436-.645.436s-.433-.119-.645-.436c-.21-.315-.355-.778-.355-1.314Zm1-2.75c-.633 0-1.146.385-1.477.882-.333.5-.523 1.162-.523 1.868 0 .706.19 1.368.523 1.868.331.497.844.882 1.477.882.633 0 1.146-.385 1.477-.882.333-.5.523-1.162.523-1.868 0-.706-.19-1.368-.523-1.868-.331-.497-.844-.882-1.477-.882ZM8.75 7.5v6h-1v-6h1Zm6 6v-6h-1v6h1Zm11 9h1v-6h-1v6Zm-17-6v6h-1v-6h1Zm18-3v-6h-1v6h1Zm-6 3v6h-1v-6h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDisplayBinaryCodeMedium;
