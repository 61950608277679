import type { SVGProps } from "react";
const SvgMicroscooterProfilePowerPlugMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.25 3.249H24v1h1.75v11.025a5.009 5.009 0 0 0-4.476 4.475H11.45a2.753 2.753 0 0 0-2.45-1.5A2.753 2.753 0 0 0 6.25 21 2.753 2.753 0 0 0 9 23.75a2.753 2.753 0 0 0 2.739-3h10.51v-.5c0-2.205 1.796-4 4-4h.5v-13h-.5Zm-19 17.75c0-.964.786-1.75 1.75-1.75s1.75.786 1.75 1.75c0 .965-.786 1.75-1.75 1.75S7.25 21.965 7.25 21Zm13.5 4.75v1.5l3.249-.007.002 1-3.251.006v2.001H24v1h-3.25v1.5h-2a3.502 3.502 0 0 1-3.464-3H10.5v-1h4.785a3.499 3.499 0 0 1 3.462-2.998l1.503-.002h.5Zm-1 1-1.003.002a2.498 2.498 0 1 0 .003 4.998h1v-5Zm7.25-7.5c-.964 0-1.75.786-1.75 1.75 0 .965.786 1.75 1.75 1.75s1.75-.785 1.75-1.75c0-.964-.786-1.75-1.75-1.75ZM24.25 21A2.753 2.753 0 0 1 27 18.25 2.753 2.753 0 0 1 29.75 21 2.753 2.753 0 0 1 27 23.75 2.753 2.753 0 0 1 24.25 21Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMicroscooterProfilePowerPlugMedium;
