import type { SVGProps } from "react";
const SvgRocketLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24.885 8.512-.374-.423-.375.423c-6.515 7.362-8.043 15.084-7.938 20.964.014.752.054 1.474.114 2.161-2.112 1.162-2.93 2.996-3.209 4.541a8.57 8.57 0 0 0-.044 2.698 5.399 5.399 0 0 0 .039.228l.003.014v.005l.001.001.489-.104-.489.105.184.855.644-.592c1.172-1.08 2.33-1.706 3.426-2.03.153.536.297.978.414 1.312a16.06 16.06 0 0 0 .26.689l.015.038.004.01.002.004.46-.196-.46.197.28.648.518-.477.002-.002.01-.009.045-.039a9.861 9.861 0 0 1 .91-.677 10.276 10.276 0 0 1 2.591-1.233A8.22 8.22 0 0 1 24 37.298V40h1v-2.734c1.599.06 3.377.666 5.172 2.317l.52.477.278-.648-.46-.197.46.197v-.001l.002-.003.004-.01.016-.039.058-.144c.049-.125.118-.308.201-.545.116-.33.258-.766.41-1.295 1.079.33 2.217.952 3.368 2.013l.643.592.184-.855-.489-.105.49.104v-.006l.004-.015.01-.049c.007-.042.017-.101.028-.177a8.582 8.582 0 0 0-.04-2.677c-.272-1.534-1.075-3.361-3.153-4.53.062-.697.103-1.43.117-2.194.106-5.88-1.423-13.602-7.938-20.964ZM14.087 36.356c.224-1.24.83-2.644 2.34-3.623.173 1.412.421 2.647.671 3.658-1.021.299-2.07.822-3.116 1.64a7.531 7.531 0 0 1 .105-1.675Zm20.89 1.675c-1.026-.803-2.054-1.321-3.058-1.623.25-1.004.497-2.233.67-3.638 1.474.982 2.067 2.376 2.285 3.605.112.627.124 1.206.103 1.656ZM25 36.266c1.654.054 3.474.617 5.297 2.102l.01-.029c.158-.45.37-1.113.587-1.955a30.28 30.28 0 0 0 .93-6.926c.077-4.331-.76-9.698-3.96-15.167-2.781 1.302-5.446.504-6.69-.026-3.215 5.478-4.054 10.855-3.976 15.193a30.28 30.28 0 0 0 .93 6.926c.216.842.428 1.505.586 1.955l.02.055a11.27 11.27 0 0 1 3.38-1.727A9.165 9.165 0 0 1 24 36.294V32h1v4.266Zm-3.3-22.865c1.146.464 3.353 1.053 5.64.03a32.632 32.632 0 0 0-2.83-3.826 32.653 32.653 0 0 0-2.81 3.796ZM22 22.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm2.5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRocketLarge;
