import type { SVGProps } from "react";
const SvgChargingStationSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 3A1.5 1.5 0 0 0 6 4.5V21h10V4.5A1.5 1.5 0 0 0 14.5 3h-7ZM5 4.5V21H4v1h14v-1h-1v-6c.423 0 .641.104.76.224.123.123.23.35.24.782v1.023c0 .248 0 .703.162 1.103.088.22.233.445.47.614.24.17.533.254.868.254.333 0 .626-.08.866-.248.239-.166.385-.39.475-.61.163-.403.16-.865.16-1.123L21 17v-3.05a2.5 2.5 0 0 0 2-2.449V10h-1V8h-1v2h-1V8h-1v2h-1v1.5c0 1.209.86 2.218 2 2.45v3.053c.002.29-.004.562-.086.762a.353.353 0 0 1-.12.167c-.042.029-.125.068-.294.068-.165 0-.247-.04-.288-.069a.37.37 0 0 1-.123-.173c-.082-.205-.088-.48-.089-.758v-1.011c-.013-.573-.157-1.095-.53-1.47-.375-.377-.895-.519-1.47-.519V4.5A2.5 2.5 0 0 0 14.5 2h-7A2.5 2.5 0 0 0 5 4.5Zm2.5.5H7v5h8V5H7.5ZM8 9V6h6v3H8Zm11 2.5V11h3v.5a1.5 1.5 0 0 1-3 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChargingStationSmall;
