import type { SVGProps } from "react";
const SvgEv84 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-84_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm1.962 5.371c-.54-.185-.954-.489-1.245-.912-.286-.423-.429-.936-.429-1.54 0-1.004.413-1.806 1.238-2.403.73-.53 1.597-.794 2.602-.794 1.143 0 2.077.315 2.801.944.688.593 1.032 1.325 1.032 2.198 0 .609-.146 1.13-.437 1.563-.29.434-.703.749-1.237.944.418.122.759.278 1.023.469.735.534 1.103 1.314 1.103 2.34 0 1.18-.452 2.119-1.357 2.817-.761.587-1.74.88-2.935.88-1.296 0-2.328-.322-3.095-.967-.799-.672-1.198-1.553-1.198-2.642 0-1.021.354-1.812 1.063-2.373.323-.248.68-.423 1.071-.524Zm2.135-3.848c-.508 0-.918.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.317.286.74.428 1.27.428.528 0 .946-.142 1.253-.428.307-.286.46-.674.46-1.166 0-.482-.156-.857-.468-1.127-.307-.27-.735-.405-1.285-.405Zm-.016 4.721c-.572 0-1.04.178-1.405.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.85.532 1.436.532.667 0 1.167-.196 1.5-.587.323-.376.484-.81.484-1.301 0-.561-.185-1.021-.555-1.381-.355-.35-.844-.524-1.468-.524Zm13.126.746v1.96h-1.46v2.65h-2.261v-2.65h-5v-2.174l5.119-6.776h2.142v6.99h1.46Zm-3.674.024V6.868l-3.142 4.316h3.142Z"
    />
  </svg>
);
export default SvgEv84;
