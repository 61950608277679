import type { SVGProps } from "react";
const SvgWagonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.497 10.001 19.5 10c.83 0 1.5.672 1.5 1.5h.001V16H22v1h-.999v1H20a2 2 0 0 1-4 0H8a2 2 0 1 1-4 0H3v-1H2v-1h1l-.003-4.499a1.5 1.5 0 0 1 1.5-1.5ZM17 18a1 1 0 0 0 2 0h-2ZM6 19a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1Zm13.5-8-15.003.001a.5.5 0 0 0-.5.5L4 17h1v-4h4v4h11.001v-5.5H20a.5.5 0 0 0-.5-.5ZM8 17H6v-3h2v3Zm2.5-4H10v3h9.001v-3H10.5Zm.5 2v-1h7.001v1H11Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWagonSmall;
