import type { SVGProps } from "react";
const SvgDisplaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 4h18v13h-8v2h4v1H7v-1h4v-2H3V4Zm17 12H4V5h16v11Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDisplaySmall;
