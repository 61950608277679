import type { SVGProps } from "react";
const SvgMuseumLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24.094 9.008-.089-.016-.09.016-16.504 2.998L7 12.08V17h2v15H8v3H7v4h34.001v-4h-1v-3h-1V17h2v-4.92l-.41-.075-16.497-2.998ZM38.001 32V17h-2v15h2Zm-3 0V17h-5v15h5Zm-6 0V17H27v15h2.001ZM26 32h-3.999V17H26v15Zm-4.999 0V17h-2v15h2Zm-3 0V17h-5v15h5Zm-6 0V17H10v15h2ZM8 16v-3.085l16.005-2.907 15.996 2.907V16H8Zm5.806 17H9v3H8v2h4.14l1.666-5Zm1.054 0h18.28l1.667 5H13.194l1.666-5Zm21.001 5h4.14v-2h-1v-3h-4.806l1.666 5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMuseumLarge;
