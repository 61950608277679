import type { SVGProps } from "react";
const SvgCalculatorLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.62 3C9.758 3 9 3.763 9 4.77v38.46c0 1.016.757 1.77 1.62 1.77h25.75c.862 0 1.62-.763 1.62-1.77V4.77c0-1.016-.757-1.77-1.62-1.77H10.62ZM8 4.77C8 3.277 9.142 2 10.62 2h25.75c1.477 0 2.62 1.266 2.62 2.77v38.46c0 1.493-1.142 2.77-2.62 2.77H10.62C9.143 46 8 44.734 8 43.23V4.77ZM12.5 6a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h22a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5h-22Zm-1.5.5A1.5 1.5 0 0 1 12.5 5h22A1.5 1.5 0 0 1 36 6.5v10a1.5 1.5 0 0 1-1.5 1.5h-22a1.5 1.5 0 0 1-1.5-1.5v-10Zm2 16a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM12 28.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm1 12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0-5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM12 34.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm7-12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM18 28.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm1 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM18 40.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm7-18a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM24 28.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm1 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM24 40.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm7-12a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm0-5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM30 22.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm1 12a1.5 1.5 0 0 1 3 0v6a1.5 1.5 0 0 1-3 0v-6Zm1.5-2.5a2.5 2.5 0 0 0-2.5 2.5v6a2.5 2.5 0 0 0 5 0v-6a2.5 2.5 0 0 0-2.5-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCalculatorLarge;
