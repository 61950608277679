import type { SVGProps } from "react";
const SvgAerosolCanLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M33 6.5A4.5 4.5 0 0 1 37.5 2a4.5 4.5 0 0 1 4.475 4.025 4.5 4.5 0 0 1 0 8.95A4.5 4.5 0 0 1 37.5 19a4.499 4.499 0 0 1-4.458-5.095l-16.628-2.912L16 10.92V9.055l.442-.052 16.592-1.937A4.533 4.533 0 0 1 33 6.5Zm8.672 1.69c.151-.364.251-.755.298-1.159a3.5 3.5 0 0 1 0 6.938 4.337 4.337 0 0 0-.298-1.16l-.924.382c.164.397.252.842.252 1.309 0 1.934-1.566 3.5-3.5 3.5a3.499 3.499 0 0 1-3.5-3.5c0-.301.043-.59.115-.879l.13-.52-.529-.094L17 10.08v-.135l16.688-1.948.582-.068-.159-.564A3.499 3.499 0 0 1 37.5 3C39.434 3 41 4.566 41 6.5c0 .467-.088.912-.252 1.31l.924.38ZM15 8.5v-.64l-.621.155-4 1L10 9.11V12H9v2.073A5.031 5.031 0 0 0 5 19H4v27h17V19h-1a5.03 5.03 0 0 0-4-4.927V12h-1V8.5ZM14 12h-3V9.89l3-.75V12Zm5 7H6c0-2.056 1.545-3.766 3.529-4h5.943c1.983.234 3.528 1.944 3.528 4Zm-4-5h-5v-1h5v1ZM5 42h15v3H5v-3Zm0-22v21h15V20H5Zm2 1v19h1V21H7Zm3-4c-.554 0-1 .446-1 1H8c0-1.106.894-2 2-2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAerosolCanLarge;
