import type { SVGProps } from "react";
const SvgHourglassLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 6.5a.5.5 0 0 1 .5-.5h29a.5.5 0 0 1 0 1h-29a.5.5 0 0 1-.5-.5ZM9.5 5a1.5 1.5 0 1 0 0 3h.67c.606 5.38 4.378 9.925 8.345 12.318 1.127.693 1.929 2.071 1.929 3.682 0 1.61-.802 2.989-1.929 3.681-3.967 2.394-7.74 6.94-8.346 12.319H9.5a1.5 1.5 0 0 0 0 3h29a1.5 1.5 0 0 0 0-3h-.67c-.606-5.38-4.378-9.925-8.345-12.319-1.127-.693-1.929-2.07-1.929-3.681 0-1.61.802-2.989 1.929-3.682 3.967-2.393 7.74-6.939 8.346-12.318h.669a1.5 1.5 0 0 0 0-3h-29Zm27.324 3H11.176c.604 4.949 4.11 9.203 7.857 11.463l.004.002c1.443.887 2.407 2.6 2.407 4.535s-.964 3.648-2.407 4.535l-.004.002C15.286 30.797 11.78 35.05 11.176 40h25.648c-.604-4.949-4.11-9.203-7.857-11.463l-.004-.002c-1.443-.887-2.407-2.6-2.407-4.535 0-1.936.964-3.648 2.407-4.535l.004-.002C32.714 17.203 36.22 12.95 36.824 8ZM9 41.5a.5.5 0 0 1 .5-.5h29a.5.5 0 0 1 0 1h-29a.5.5 0 0 1-.5-.5ZM16.73 15h14.54l-.93.866a15.154 15.154 0 0 1-2.42 1.861c-2.079 1.287-3.422 3.685-3.422 6.273h-1c0-2.589-1.344-4.988-3.402-6.26a15.247 15.247 0 0 1-2.437-1.874L16.73 15Zm7.269 6.126c.63-1.76 1.825-3.278 3.397-4.25l.003-.002c.419-.254.838-.548 1.252-.874H19.35c.419.33.844.628 1.268.886l.003.002c1.56.963 2.75 2.478 3.379 4.238ZM24 29.502h-.39l-.095.377c-.385 1.523-1.438 2.157-2.91 3.044-.175.105-.355.213-.54.327-.614.37-1.268.644-1.943.902-.15.058-.304.115-.458.173-.522.197-1.056.397-1.555.63-1.331.623-2.515 1.525-3.087 3.399l-.197.646h22.351l-.198-.646c-.572-1.874-1.758-2.777-3.091-3.401-.5-.234-1.034-.435-1.558-.632a69.38 69.38 0 0 1-.462-.175c-.677-.26-1.334-.536-1.952-.908l-.466-.283c-1.483-.898-2.524-1.528-2.914-3.075l-.096-.378H24Zm-2.879 4.279c1.119-.666 2.23-1.328 2.9-2.499.668 1.185 1.768 1.845 2.893 2.52.16.095.32.191.48.29l.003.001c.696.42 1.421.721 2.112.986.168.065.332.127.494.187.515.194 1 .377 1.46.592.992.465 1.805 1.053 2.313 2.142H14.224c.507-1.089 1.32-1.676 2.309-2.139.46-.215.943-.397 1.457-.59h.003l.488-.185c.687-.264 1.41-.563 2.102-.981l.003-.002c.178-.109.357-.216.535-.322ZM23.5 26v-1h1v1h-1Zm0 1v1h1v-1h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHourglassLarge;
