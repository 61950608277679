import type { SVGProps } from "react";
const SvgBriefcaseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 6c.016-.553.472-1 1.037-1h2.926c.565 0 1.02.447 1.037 1H9ZM8 6c.016-1.11.924-2 2.037-2h2.926c1.113 0 2.02.89 2.037 2h3.97A2.03 2.03 0 0 1 21 8.03v10.69a2.03 2.03 0 0 1-2.03 2.03H4.03A2.03 2.03 0 0 1 2 18.72V8.03A2.03 2.03 0 0 1 4.03 6H8ZM3 8.03C3 7.46 3.46 7 4.03 7h14.94c.57 0 1.03.46 1.03 1.03V11h-6v-.733C14 9.567 13.434 9 12.734 9h-2.468C9.566 9 9 9.567 9 10.267V11H3V8.03ZM9 12H3v6.72c0 .568.46 1.03 1.03 1.03h14.94c.57 0 1.03-.461 1.03-1.03V12h-6v.733c0 .7-.567 1.266-1.266 1.266h-2.468A1.264 1.264 0 0 1 9 12.733V12Zm4 0v.733c0 .147-.12.266-.266.266h-2.468a.266.266 0 0 1-.266-.266V12h3Zm0-1h-3v-.733c0-.149.12-.267.266-.267h2.468c.146 0 .266.118.266.267V11Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBriefcaseSmall;
