import type { SVGProps } from "react";
const SvgLighthouseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2a3 3 0 0 0-3 3H8v1h1v3H8v1h.91L7.076 21H5v1h14v-1h-2.076L15.09 10H16V9h-1V6h1V5h-1a3 3 0 0 0-3-3Zm2 3a2 2 0 1 0-4 0h4Zm-4 1v3h4V6h-4ZM8.09 21l1.834-11h4.152l1.834 11H8.09ZM18 8h2V7h-2v1Zm4 0h-1V7h1v1Zm-3.752-2.066 3.5-2-.496-.868-3.5 2 .496.868Zm3.004 6-3.5-2 .496-.868 3.5 2-.496.868ZM6 7H4v1h2V7ZM2 7h1v1H2V7Zm4.248-1.934-3.5-2-.496.868 3.5 2 .496-.868Zm-3.996 6 3.5-2 .496.868-3.5 2-.496-.868Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLighthouseSmall;
