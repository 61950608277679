import type { SVGProps } from "react";
const SvgCalendarLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 13v-3h-1v3H9v22.939h.499l30 .06.501.002V13h-7v-3h-1v3H16Zm16 3v-2H16v2h-1v-2h-5v4h29v-4h-6v2h-1Zm-22 3v15.94L39 35V19H10Zm26 4H26v-1h10v1Zm-23 6h10v-1H13v1Zm10 3H13v-1h10v1Zm3-6h10v-1H26v1Zm10 6H26v-1h10v1Zm-10-3h10v-1H26v1Zm-13-3v-1h4v1h-4Zm6 0h4v-1h-4v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCalendarLarge;
