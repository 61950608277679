import type { SVGProps } from "react";
const SvgArmchairMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.482 4.982a2.5 2.5 0 0 1 1.768-.732h13.5a2.5 2.5 0 0 1 2.5 2.5v7.04a3.5 3.5 0 0 0-2.955 3.46h-.045v2.5h-12.5v-2.5a3.5 3.5 0 0 0-3-3.467V6.75a2.5 2.5 0 0 1 .732-1.768Zm1.626 12.268h-.358A2.501 2.501 0 1 0 7 19.412l.25.145v5.238a2.5 2.5 0 0 0 2.5 2.5h16.545a2.5 2.5 0 0 0 2.5-2.5v-5.238l.25-.145a2.5 2.5 0 1 0-3.75-2.162h-.358.313v4.5a2 2 0 0 1-2 2h-10.5a2 2 0 0 1-2-2v-4.5h.358Zm.642 3.5v1a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1v-1h-12.5Zm16.5-14v7.027a3.497 3.497 0 0 1 3.045 3.47 3.5 3.5 0 0 1-1.5 2.872v4.676a3.5 3.5 0 0 1-1.545 2.903v3.155a1.882 1.882 0 0 1-1.897 1.897h-.205a1.88 1.88 0 0 1-1.898-1.897v-2.558h-12.5v2.558a1.882 1.882 0 0 1-1.897 1.897h-.205a1.88 1.88 0 0 1-1.898-1.897v-3.186a3.499 3.499 0 0 1-1.5-2.872V20.12a3.5 3.5 0 0 1 1.5-6.336V6.75a3.5 3.5 0 0 1 3.5-3.5h13.5a3.5 3.5 0 0 1 3.5 3.5Zm-1.955 21.545c.326 0 .647-.045.955-.133v2.699a.878.878 0 0 1-.89.89h-.22a.88.88 0 0 1-.89-.89v-2.566h1.045Zm-16.545 0h1v2.566a.878.878 0 0 1-.89.89h-.22a.88.88 0 0 1-.89-.89v-2.712c.322.096.658.146 1 .146Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArmchairMedium;
