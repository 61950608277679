import type { SVGProps } from "react";
const SvgRe87Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="re-87-negative_svg__color-immutable"
    {...props}
  >
    <g fill="#fff" clipPath="url(#re-87-negative_svg__a)">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.982 1.982 0 0 1-.197-.625c-.037-.253-.087-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.708-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.17-.949-.514-1.202-.342-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm3.062 5.356c-.538-.186-.952-.489-1.242-.91-.285-.422-.427-.934-.427-1.535 0-1.002.41-1.801 1.233-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.029 1.321 1.029 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.702.746-1.234.941.416.121.756.277 1.02.467.733.532 1.1 1.31 1.1 2.333 0 1.176-.451 2.112-1.353 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.194-1.547-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.925 2.925 0 0 1 1.068-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.31.28-.466.648-.466 1.107 0 .48.155.865.466 1.155.317.285.739.427 1.266.427s.944-.142 1.25-.427c.306-.285.458-.672.458-1.163 0-.48-.155-.854-.466-1.123-.306-.269-.733-.403-1.282-.403Zm-.015 4.706c-.57 0-1.037.177-1.4.53-.36.348-.538.796-.538 1.345 0 .564.181 1.023.545 1.376.37.354.847.53 1.432.53.665 0 1.163-.195 1.495-.585a1.93 1.93 0 0 0 .483-1.297c0-.56-.185-1.018-.554-1.377-.353-.348-.841-.522-1.463-.522Zm12.94-6.225v1.962a45.8 45.8 0 0 0-.561.64c-.433.496-.862 1.12-1.29 1.875a12.726 12.726 0 0 0-.996 2.16c-.501 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.195-3.18.722-1.497 1.455-2.612 2.199-3.345h-5.458V4.234h7.76Z" />
    </g>
    <defs>
      <clipPath id="re-87-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe87Negative;
