import type { SVGProps } from "react";
const SvgCarPowerPlugMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.92 5.744A2.77 2.77 0 0 0 8.744 8.92l.006.04v3.29h9.536a3.5 3.5 0 0 1 6.928 0h2.036V8.96l.007-.04a2.77 2.77 0 0 0-3.177-3.176l-.04.006H11.96l-.04-.006ZM24.2 12.25a2.5 2.5 0 0 0-4.9 0h4.9Zm-15.45 7.5v-6.5h18.5v6.5H8.75Zm3.289-15A3.77 3.77 0 0 0 7.75 9.039v1.71H6v1h1.75v12h5.5v-3h9.5v3h5.5v-12H30v-1h-1.75V9.04a3.77 3.77 0 0 0-4.288-4.29H12.039Zm-3.289 18v-2h3.5v2h-3.5Zm15 0v-2h3.5v2h-3.5ZM12 16.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM24 16.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-2 10.75v-1.5h-2a3.5 3.5 0 0 0-3.464 3H10.5v1h4.786a3.5 3.5 0 0 0 3.464 3h2v-1.5H24v-1h-3.25v-2H24v-1h-3.25Zm-3.768.232a2.5 2.5 0 0 1 1.768-.732h1v5h-1a2.5 2.5 0 0 1-1.768-4.268Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCarPowerPlugMedium;
