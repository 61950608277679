import type { SVGProps } from "react";
const SvgWagonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m6.745 15.252 22.505-.002a2 2 0 0 1 2 2h.002v7H33v1h-1.748v1.5h-1.515a2.75 2.75 0 1 1-5.475 0H11.737a2.749 2.749 0 1 1-5.475 0H4.75v-1.5H3v-1h1.749l-.004-6.998a2 2 0 0 1 2-2ZM25.271 26.75a1.526 1.526 0 0 0-.021.25 1.75 1.75 0 1 0 3.5 0c0-.084-.008-.167-.021-.25H25.27Zm-18 0h3.458a1.749 1.749 0 1 1-3.458 0Zm21.979-10.5-22.504.002a1 1 0 0 0-1 1l.004 8.498h2v-6h5.5v6h17.002v-8.5h-.002a1 1 0 0 0-1-1Zm-17 9.5h-3.5v-5h3.5v5Zm3.5-6h-.5v4h13.002v-4H15.75Zm.5 3v-2h11.002v2H16.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWagonMedium;
