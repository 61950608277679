import type { SVGProps } from "react";
const SvgIc81Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-81-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M40.586 5.754c-.507 0-.916.141-1.227.423-.31.282-.466.653-.466 1.112 0 .48.157.863.47 1.15.314.288.735.432 1.262.432s.944-.143 1.25-.428c.306-.284.459-.672.459-1.162 0-.48-.155-.854-.463-1.123-.309-.27-.737-.404-1.285-.404Zm-.016 4.707c-.57 0-1.035.175-1.397.526-.36.35-.541.8-.541 1.348 0 .565.183 1.023.55 1.377.366.353.842.53 1.427.53.664 0 1.163-.195 1.495-.586.322-.374.483-.806.483-1.297 0-.559-.185-1.018-.554-1.376-.353-.348-.841-.523-1.463-.523Zm-2.112-.87c-.538-.185-.95-.488-1.238-.91-.288-.422-.431-.933-.431-1.535 0-1.002.41-1.8 1.234-2.396.727-.528 1.592-.792 2.594-.792 1.14 0 2.07.314 2.793.942.685.59 1.028 1.32 1.028 2.191 0 .606-.145 1.126-.435 1.558-.29.433-.702.747-1.234.942.416.12.756.276 1.02.466.733.533 1.1 1.31 1.1 2.334 0 1.176-.451 2.112-1.353 2.808-.76.585-1.735.878-2.927.878-1.292 0-2.32-.322-3.085-.965-.796-.67-1.194-1.548-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.928 2.928 0 0 1 1.068-.522Zm12.949-5.356V15.8h-2.31V8.23h-2.721V6.553c.564-.016 1.024-.074 1.38-.174.356-.1.681-.264.977-.49.427-.327.714-.878.862-1.654h1.812Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIc81Negative;
