import type { SVGProps } from "react";
const SvgSaBz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.181 1c.96 0 1.75.79 1.75 1.75V12h-18.5V2.75c0-.96.79-1.75 1.75-1.75h15Zm-15.25 9.5h15.5V2.75c0-.14-.11-.25-.25-.25h-15c-.14 0-.25.11-.25.25v7.75Zm9.85 3.5v-1h8.9c0 1.1-.9 2-2 2h-18c-1.1 0-2-.9-2-2h9.1v1h4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaBz;
