import type { SVGProps } from "react";
const SvgSpeechBubbleGroupEmptyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.75 6.25h13v8.5h-7.418l-3.228 3.229-.854.853V14.75h-1.5v-8.5Zm1 1v6.5h1.5v2.668l2.521-2.522.147-.146h6.832v-6.5h-11Zm5 11h14.5v10h-3v4.082l-.854-.853-3.228-3.229H10.75v-10Zm1 1v8h6.832l.147.146 2.521 2.522V27.25h3v-8h-12.5Zm10-11.5h-.5v7h4.418l3.228 3.229.854.853V14.75h1.5v-7h-9.5Zm.5 6v-5h8v5h-1.5v2.668l-2.521-2.522-.147-.146H22.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpeechBubbleGroupEmptyMedium;
