import type { SVGProps } from "react";
const SvgDisplaySwitzerlandWarningLightMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#display-switzerland-warning-light-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.25 3.25h26.5V13.5h-1V4.25H4.25v17H19.5v1h-1.75v3.5h1.75v1H9v-1h6.25v-3.5h-12v-19Zm13 19v3.5h.5v-3.5h-.5Zm1.06-16.167.366.328 1.212 1.083 3.31 1.302.401.158-.135.409-.458 1.382.81.248.224-.428.251-.483.43.333 1.224.947.166.128.01.21.084 1.779-.91.042-.073-1.568-.627-.485-.146.28-.175.336-.362-.11-1.617-.495-.446-.137.147-.442.469-1.415-2.986-1.174-.076-.03-.06-.054-.907-.81-.624.816-.093.122-.147.04-3.43.948-.178.049-.162-.089-.416-.228-1.307.983.2.07.643.223-.453.509-2.574 2.892-.752 2.792 1.13-.291.806-.476.299-.177.249.242 1.884 1.828 2.492-.285 1.543-1.69.389-.426.324.477 1.867 2.748-.752.512-1.544-2.272-1.27 1.391-.115.126-.17.02-2.875.327-.214.024-.154-.15-1.79-1.735-.561.332-.056.033-.062.016-1.95.502-.756.195.204-.754.984-3.65.028-.104.071-.08 2.193-2.464-.363-.126-.806-.28.682-.513 2.224-1.673.235-.177.257.142.512.28 3.104-.857.83-1.085.298-.39ZM26 18v-3h-1v3h1Zm-4.94-2.123 1.5 2.625-.87.496-1.5-2.625.87-.496ZM19.5 28.75h-.39l-.095.379-.75 3-.155.621h14.78l-.155-.621-.75-3-.095-.379h-2.14V24a4.25 4.25 0 0 0-8.5 0v4.75H19.5ZM28.75 24v4.75h-6.5V24a3.25 3.25 0 0 1 6.5 0Zm-9.36 7.75.5-2h11.22l.5 2H19.39Zm9.92-12.752 1.5-2.625-.87-.496-1.5 2.625.87.496Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="display-switzerland-warning-light-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDisplaySwitzerlandWarningLightMedium;
