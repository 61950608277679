import type { SVGProps } from "react";
const SvgHeartSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.994 9.497A5.483 5.483 0 0 1 7.5 4c1.59 0 3.005.673 4.003 1.739A5.437 5.437 0 0 1 15.5 4 5.477 5.477 0 0 1 21 9.497a5.51 5.51 0 0 1-.72 2.736c-.166.29-.618.87-.795 1.085l-.015.019-.017.017-7.6 7.6-.353.353-.353-.353-7.61-7.61-.007-.008a5.558 5.558 0 0 1-.87-1.203M7.5 5a4.483 4.483 0 0 0-4.506 4.497c0 .785.198 1.52.544 2.158.2.367.434.697.71.985l7.252 7.253 7.228-7.23c.189-.23.57-.726.682-.925.376-.657.59-1.421.59-2.24A4.477 4.477 0 0 0 15.5 5a4.452 4.452 0 0 0-3.595 1.815l-.401.54-.402-.54A4.469 4.469 0 0 0 7.5 5ZM1.994 9.497c0 .955.24 1.854.666 2.636l-.666-2.636Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHeartSmall;
