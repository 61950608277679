import type { SVGProps } from "react";
const SvgSkisSkiPolesSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 2a2 2 0 0 0-2 2v18h4V4a2 2 0 0 0-2-2ZM3 15v-4a1 1 0 1 1 2 0v4H3Zm1-6c.364 0 .706.097 1 .267V4a1 1 0 1 0-2 0v5.267C3.294 9.097 3.636 9 4 9Zm-1 7v5h2v-5H3ZM9 2a2 2 0 0 0-2 2v18h4V4a2 2 0 0 0-2-2Zm1 7.267V4a1 1 0 1 0-2 0v5.267C8.294 9.097 8.636 9 9 9s.706.097 1 .267ZM8 11a1 1 0 1 1 2 0v4H8v-4Zm0 10v-5h2v5H8Zm6.47-18.173 3.03 8.227 3.03-8.227.94.346-.479 1.297.167.056.474.158-.158.474-1 3-.158.475-.474-.158-.29-.097-1.52 4.122 2.723 7.388 1.087-.362.316.948-1.057.353.368 1-.938.346-.38-1.03-.993.331-.316-.948.964-.322-2.306-6.258-2.306 6.258.964.322-.316.948-.994-.33-.379 1.029-.938-.346.368-1-1.057-.353.316-.948 1.087.362 2.722-7.388-1.518-4.122-.29.097-.475.158-.158-.475-1-3-.158-.474.474-.158.167-.056-.478-1.297.938-.346Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSkisSkiPolesSmall;
