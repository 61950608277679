import type { SVGProps } from "react";
const SvgRssSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 20c0-8.285-6.715-15-15-15V4c8.837 0 16 7.163 16 16h-1ZM4 18a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2-6a9 9 0 0 1 9 9h1c0-5.523-4.477-10-10-10v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRssSmall;
