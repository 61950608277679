import type { SVGProps } from "react";
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className="info_svg__color-immutable"
    {...props}
  >
    <rect width={16} height={16} fill="#EB0000" rx={2} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.081 2a1.48 1.48 0 1 0 0 2.96 1.48 1.48 0 0 0 0-2.96ZM6.723 12.665h-.929a.294.294 0 0 0 0 .587l.93.001h3.57a.293.293 0 1 0 0-.588H9.39V5.689H5.794a.294.294 0 1 0 0 .587h.93v6.389Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfo;
