import type { SVGProps } from "react";
const SvgFaceThinkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#face-thinking-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 5.75C11.241 5.75 5.75 11.241 5.75 18a12.24 12.24 0 0 0 7.522 11.314l-.384.922A13.238 13.238 0 0 1 4.75 18c0-7.311 5.939-13.25 13.25-13.25S31.25 10.689 31.25 18c0 .515-.031 1.012-.077 1.488l-.996-.096c.044-.454.073-.917.073-1.392 0-6.759-5.491-12.25-12.25-12.25Zm13.022 17.677a1.225 1.225 0 0 0-1.575-.572l-9 3.854-.213.092-.207-.104-5.295-2.64a1.088 1.088 0 0 0-.977 0c-.693.352-.808 1.3-.23 1.798.827.704 1.782 1.628 2.579 2.695.708.948 1.308 2.034 1.563 3.2h5.583c1.374 0 2.5-1.126 2.5-2.5v-1.81l.276-.138 4.455-2.235a1.23 1.23 0 0 0 .54-1.641Zm-1.969-1.492c1.083-.464 2.34 0 2.864 1.046l.002.003c.539 1.098.1 2.433-.99 2.978l-4.179 2.096v1.192c0 1.926-1.574 3.5-3.5 3.5h-6.434l-.061-.43c-.156-1.101-.713-2.181-1.453-3.172-.737-.988-1.633-1.857-2.426-2.532l-.002-.002c-1.13-.972-.886-2.782.43-3.45l.002-.001a2.089 2.089 0 0 1 1.872 0l5.088 2.536 8.787-3.764ZM13.25 13.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm9 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-10.652 7.99 15-3-.196-.98-15 3 .196.98Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="face-thinking-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFaceThinkingMedium;
