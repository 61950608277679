import type { SVGProps } from "react";
const SvgWalkLargeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3c-.524 0-1 .476-1 1 0 .523.476 1 1 1s1-.477 1-1c0-.524-.476-1-1-1Zm-2 1c0-1.077.924-2 2-2s2 .923 2 2c0 1.076-.924 2-2 2s-2-.924-2-2Zm0 5.15-1 1.5V14H8v-3.652l.084-.126 2-3L10.232 7h3.536l.148.222 1.898 2.847 3.323.95-.274.961-3.5-1-.177-.05-.102-.153L14 9.15v4.197l1.916 2.874.084.126V22h-1v-5.35L13.232 14H11v3.66l-.093.13-2.5 3.5-.814-.581L10 17.339V9.151ZM11 8v5h2V8h-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalkLargeSmall;
