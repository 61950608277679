import type { SVGProps } from "react";
const SvgDocumentZipSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 4H6v16h12V9.293l-.146-.146-5-5L12.707 4H6.5ZM7 19V5h1v.465h1V5h3v5h5v9h-7v-.464H9V19H7Zm9.293-10L13 5.707V9h3.293ZM8 7.322v-.929h1V6h1v.465H9v.857H8Zm0 1.857V8.25h1v-.857h1v.929H9v.857H8Zm0 1.857v-.929h1V9.25h1v.929H9v.857H8Zm0 1.857v-.929h1v-.857h1v.929H9v.857H8Zm0 1.857v-.928h1v-.857h1v.928H9v.857H8Zm0 1.857v-.928h1v-.857h1v.928H9v.857H8ZM8 18v-.464h1v-.857h1v.928H9V18H8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentZipSmall;
