import type { SVGProps } from "react";
const SvgSwisspassTemporaryMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.762 10.297c.157-.031.32-.048.488-.048h.488v-1H8.25c-.233 0-.461.023-.683.067l.195.981Zm1.95-.048h.976v-1h-.976v1Zm1.95 0h.975v-1h-.975v1Zm1.95 0h.976v-1h-.975v1Zm1.95 0h.976v-1h-.976v1Zm1.95 0h.976v-1h-.976v1Zm1.95 0h.976v-1h-.976v1Zm1.95 0h.976v-1h-.976v1Zm1.95 0h.976v-1h-.976v1Zm1.95 0h.976v-1h-.976v1Zm1.95 0h.488c.168 0 .33.017.488.048l.195-.98a3.512 3.512 0 0 0-.683-.067h-.488v1ZM6.171 11.361c.183-.273.418-.508.69-.69l-.556-.831a3.52 3.52 0 0 0-.965.965l.831.556Zm22.967-.69c.273.182.508.417.691.69l.83-.556a3.519 3.519 0 0 0-.964-.965l-.557.83ZM5.75 12.75c0-.168.016-.331.048-.489l-.981-.194c-.044.221-.067.45-.067.682v.525h1v-.524Zm24.452-.489c.032.158.048.321.048.489v.524h1v-.524c0-.234-.023-.462-.067-.683l-.98.194ZM5.75 15.375v-1.05h-1v1.05h1Zm24.5-1.05v1.05h1v-1.05h-1Zm0 2.1v1.05h1v-1.05h-1Zm-24.5 1.05v-1.05h-1v1.05h1Zm24.5 1.05v1.05h1v-1.05h-1Zm-24.5 1.05v-1.05h-1v1.05h1Zm0 2.1v-1.05h-1v1.05h1Zm24.5-1.05v1.05h1v-1.05h-1ZM5.75 23.25v-.526h-1v.526c0 .233.023.46.067.682l.98-.194a2.512 2.512 0 0 1-.047-.488Zm24.5-.526v.526c0 .167-.016.33-.048.488l.981.194c.044-.221.067-.45.067-.682v-.526h-1ZM6.862 25.328a2.52 2.52 0 0 1-.691-.69l-.83.556a3.5 3.5 0 0 0 .964.965l.557-.83Zm22.967-.69a2.522 2.522 0 0 1-.69.69l.556.831c.381-.255.71-.583.965-.965l-.831-.556ZM8.25 25.749c-.168 0-.33-.016-.488-.047l-.195.98c.222.045.45.068.683.068h.488v-1H8.25Zm19.988-.047c-.157.031-.32.047-.488.047h-.488v1h.488c.233 0 .461-.022.683-.066l-.195-.981Zm-17.55.047h-.976v1h.976v-1Zm1.95 0h-.976v1h.975v-1Zm1.95 0h-.975v1h.975v-1Zm1.95 0h-.976v1h.976v-1Zm1.95 1v-1h-.976v1h.976Zm1.95-1h-.976v1h.976v-1Zm1.95 0h-.976v1h.976v-1Zm1.95 0h-.976v1h.976v-1Zm1.95 0h-.976v1h.976v-1ZM25.25 15.252v-1.75h-1v1.75H22.5v1h1.75v1.75h1v-1.75H27v-1h-1.75ZM15 16.25H9v-1h6v1Zm-6 3h9v-1H9v1Zm7.5 3H9v-1h7.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSwisspassTemporaryMedium;
