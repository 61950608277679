import type { SVGProps } from "react";
const SvgFaceDisagreeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#face-disagree-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.75 18c0-6.765 5.485-12.25 12.25-12.25S30.25 11.235 30.25 18 24.765 30.25 18 30.25 5.75 24.765 5.75 18ZM18 4.75C10.682 4.75 4.75 10.682 4.75 18S10.682 31.25 18 31.25 31.25 25.318 31.25 18 25.318 4.75 18 4.75Zm-6.396 12.104 1.896-1.897 1.896 1.897.708-.708-1.897-1.896 1.897-1.896-.708-.708-1.896 1.897-1.896-1.897-.708.708 1.897 1.896-1.897 1.896.708.708Zm9 0 1.896-1.897 1.896 1.897.708-.708-1.897-1.896 1.897-1.896-.708-.708-1.896 1.897-1.896-1.897-.708.708 1.897 1.896-1.897 1.896.708.708ZM18.09 18.25c-3.313 0-6.069 1.972-7.284 4.803l.919.394c1.064-2.48 3.469-4.197 6.365-4.197 2.896 0 5.301 1.718 6.366 4.197l.919-.394c-1.216-2.83-3.971-4.803-7.285-4.803Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="face-disagree-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFaceDisagreeMedium;
