import type { SVGProps } from "react";
const SvgPercentTagMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.25 7.75h-.5v20.5h21.049l.141-.262 5.25-9.75.128-.238-.128-.237-5.25-9.75-.141-.263H5.25Zm.5 19.5V8.75h19.451L30.182 18l-4.98 9.25H5.75Zm6-13.75c0-.984.766-1.75 1.75-1.75s1.75.766 1.75 1.75-.766 1.75-1.75 1.75-1.75-.766-1.75-1.75Zm1.75-2.75a2.728 2.728 0 0 0-2.75 2.75 2.728 2.728 0 0 0 2.75 2.75 2.728 2.728 0 0 0 2.75-2.75 2.728 2.728 0 0 0-2.75-2.75Zm4.25 11.75c0-.985.766-1.75 1.75-1.75s1.75.765 1.75 1.75c0 .984-.766 1.75-1.75 1.75s-1.75-.766-1.75-1.75Zm1.75-2.75a2.727 2.727 0 0 0-2.75 2.75 2.728 2.728 0 0 0 2.75 2.75 2.728 2.728 0 0 0 2.75-2.75 2.727 2.727 0 0 0-2.75-2.75Zm-9.354 3.896 12-12 .708.707-12 12-.708-.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPercentTagMedium;
