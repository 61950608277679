import type { SVGProps } from "react";
const SvgMicrophoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 5.5A2.51 2.51 0 0 1 11.5 3 2.51 2.51 0 0 1 14 5.5v8a2.51 2.51 0 0 1-2.5 2.5A2.509 2.509 0 0 1 9 13.5v-8ZM11.5 2A3.51 3.51 0 0 0 8 5.5v8c0 1.926 1.574 3.5 3.5 3.5s3.5-1.574 3.5-3.5v-8A3.51 3.51 0 0 0 11.5 2ZM11 18.98V21H9v1h5v-1h-2v-2.018c2.745-.2 5-2.052 5-4.982h-1c0 2.42-1.942 4-4.5 4C8.943 18 7 16.42 7 14H6c0 2.93 2.256 4.782 5 4.982Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMicrophoneSmall;
