import type { SVGProps } from "react";
const SvgWalkieTalkieSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 6h2v15H7v-9H6V7h1V6h7V3h1v3ZM8 7v13h8V7H8Zm7 7v1H9v-1h6Zm-6 2v1h6v-1H9Zm6 2v1H9v-1h6Zm-4-8c0-.23.111-.483.314-.686.203-.203.457-.314.686-.314.23 0 .483.111.686.314.203.203.314.457.314.686 0 .23-.111.483-.314.686-.202.203-.455.314-.684.314-.23 0-.483-.112-.687-.315C11.112 10.482 11 10.23 11 10Zm1-2c-.542 0-1.038.253-1.393.607C10.253 8.962 10 9.458 10 10s.254 1.039.609 1.393c.354.354.851.607 1.393.607s1.038-.253 1.392-.608c.354-.354.606-.85.606-1.392 0-.542-.253-1.038-.607-1.393C13.038 8.253 12.542 8 12 8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalkieTalkieSmall;
