import type { SVGProps } from "react";
const SvgFlameWarningLightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.501 2.193.873.973a125.083 125.083 0 0 0 2.444 2.64l.002.001.423.449c.798.846 1.482 1.589 2.15 2.434l-.785.62c-.64-.81-1.297-1.525-2.092-2.368l-.422-.446c-.49-.518-1.031-1.09-1.643-1.757a8.956 8.956 0 0 1-.478 2.234c-.401 1.131-1.066 2.278-2.106 3.652C5.17 11.544 4 13.439 4 15.532c0 1.63.785 3.044 1.928 4.089a3.027 3.027 0 0 1-.234-1.134c0-.957.274-1.692.835-2.48.256-.359.505-.662.726-.931l.085-.105c.245-.3.448-.556.616-.834.32-.53.542-1.183.542-2.387v-1.368l.882 1.046c1.181 1.399 1.938 2.332 2.625 3.278l-.81.588a43.649 43.649 0 0 0-1.79-2.28 4.468 4.468 0 0 1-.593 1.64 8.23 8.23 0 0 1-.698.95l-.087.107c-.222.27-.449.547-.684.877-.459.644-.65 1.181-.65 1.9 0 .454.196.937.518 1.412.319.472.736.896 1.115 1.22L8 21.5l-.187.464C5.351 20.973 3 18.65 3 15.532c0-2.427 1.335-4.54 2.07-5.51 1.004-1.328 1.606-2.383 1.96-3.383.353-.996.471-1.965.471-3.139V2.193ZM16.5 12v-2h-1v2h1Zm-3.316-1.498 1 1.75-.868.496-1-1.75.868-.496ZM12 19h-.39l-.095.379-.5 2-.155.621h10.28l-.155-.621-.5-2L20.39 19H19v-3a3 3 0 0 0-6 0v3h-1Zm6-3v3h-4v-3a2 2 0 0 1 4 0Zm-5.86 5 .25-1h7.22l.25 1h-7.72Zm6.544-8.252 1-1.75-.868-.496-1 1.75.868.496Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFlameWarningLightSmall;
