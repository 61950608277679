import type { SVGProps } from "react";
const SvgCirclePlayLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 23.5C3 12.178 12.178 3 23.5 3S44 12.178 44 23.5 34.822 44 23.5 44 3 34.822 3 23.5ZM23.5 2C11.626 2 2 11.626 2 23.5S11.626 45 23.5 45 45 35.374 45 23.5 35.374 2 23.5 2Zm-7.757 8.063L15 9.65v27.733l.757-.454 22.5-13.5.743-.446-.757-.42-22.5-12.5ZM16 35.617V11.35l21 11.667-21 12.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCirclePlayLarge;
