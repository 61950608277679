import type { SVGProps } from "react";
const SvgIc40 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-40_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-40_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.824 7.003v1.954h-1.455v2.642h-2.255v-2.643h-4.825V10.99l4.944-6.755h2.136v6.969h1.455Zm-3.662.024V6.922l-2.974 4.303h2.974Zm8.464-7.27c.88 0 1.656.269 2.325.807.665.538 1.147 1.366 1.448 2.484.227.854.34 1.782.34 2.784 0 1.661-.295 3.027-.886 4.098-.348.622-.804 1.102-1.368 1.44a3.556 3.556 0 0 1-1.875.505c-.944 0-1.75-.284-2.42-.854-1.192-1.012-1.788-2.742-1.788-5.189 0-1.74.31-3.138.933-4.192a3.77 3.77 0 0 1 1.384-1.393 3.788 3.788 0 0 1 1.907-.49Zm-.087 1.954c-.76 0-1.276.438-1.55 1.313-.217.696-.325 1.672-.325 2.927 0 1.107.11 1.996.332 2.665.285.87.812 1.306 1.582 1.306.638 0 1.113-.338 1.424-1.013.311-.675.467-1.706.467-3.093 0-1.181-.11-2.112-.332-2.792-.285-.875-.818-1.313-1.598-1.313Z"
      />
    </g>
    <defs>
      <clipPath id="ic-40_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc40;
