import type { SVGProps } from "react";
const SvgCircleMinusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 17.25c0-6.351 5.15-11.5 11.5-11.5s11.5 5.149 11.5 11.5c0 6.352-5.15 11.5-11.5 11.5s-11.5-5.148-11.5-11.5Zm11.5-12.5c-6.903 0-12.5 5.597-12.5 12.5 0 6.904 5.597 12.5 12.5 12.5s12.5-5.596 12.5-12.5c0-6.903-5.597-12.5-12.5-12.5Zm-6.75 12v1h13.5v-1H12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleMinusMedium;
