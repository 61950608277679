import type { SVGProps } from "react";
const SvgColourPaletteBrushMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#colour-palette-brush-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M25.763 8.464c.566-1.598 1.718-3.276 4.115-3.998a5.73 5.73 0 0 0-.262 1.135c-.157 1.268.082 2.94 1.582 4.582A3.2 3.2 0 0 1 31.75 12c0 1.001-.46 1.881-1.176 2.485h-4.148c-.716-.604-1.176-1.484-1.176-2.485v-.02l-.002-.018v-.014l-.003-.052a9.244 9.244 0 0 1 .02-.99 9.497 9.497 0 0 1 .498-2.442ZM24.25 12.01l.001.007c.005 1.3.601 2.432 1.5 3.205V30a2.747 2.747 0 0 0 2.75 2.75A2.747 2.747 0 0 0 31.25 30V15.223c.903-.777 1.5-1.915 1.5-3.223A4.2 4.2 0 0 0 32 9.583l-.02-.027-.022-.025c-1.299-1.409-1.476-2.787-1.35-3.807a4.747 4.747 0 0 1 .502-1.598c.02-.039.037-.067.048-.085l.01-.018.003-.003.618-.956-1.124.193c-3.498.6-5.12 2.826-5.845 4.873a10.528 10.528 0 0 0-.553 2.702 10.222 10.222 0 0 0-.021 1.111l.004.068Zm6.001 3.474h-3.5v2.765h3.5v-2.765ZM26.75 30V19.25h3.5V30c0 .969-.781 1.75-1.75 1.75s-1.75-.781-1.75-1.75ZM18 7.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM15.25 9a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Zm-4.75 2.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM7.75 13.5a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Zm7 13.5a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.75-2.75a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm1.919-20.985C11.199 3.063 5.113 8.063 3.607 14.77v.002c-.41 1.851.79 3.549 2.511 4.094 2.737.875 4.632 3.195 4.632 5.884 0 .758-.152 1.485-.438 2.14l-.002.005c-.723 1.704-.083 3.762 1.644 4.564h.003a14.47 14.47 0 0 0 6.028 1.291c2.144 0 4.316-.497 6.212-1.382l-.424-.906a13.869 13.869 0 0 1-5.788 1.288c-2 0-3.908-.42-5.61-1.2-1.181-.548-1.681-1.997-1.146-3.262a6.33 6.33 0 0 0 .52-2.538c0-3.191-2.244-5.85-5.327-6.836h-.001c-1.308-.415-2.118-1.657-1.838-2.925C5.987 8.736 11.66 4.077 18.39 4.265c1.908.058 3.73.52 5.394 1.316l.432-.902a14.407 14.407 0 0 0-5.796-1.414h-.001Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="colour-palette-brush-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgColourPaletteBrushMedium;
