import type { SVGProps } from "react";
const SvgEnvelopeOpenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.328 5.622 18 5.338l-.328.284-11.25 9.75-.172.15v17.227h23.5V15.521l-.172-.15-11.25-9.75ZM7.25 16.956v14.089l7.06-7.042-7.06-7.047Zm.71 14.794h20.085L18.03 21.707 7.96 31.75Zm20.79-.71V16.957l-7.032 7.032 7.032 7.051Zm-.233-15.264L18 6.66 7.483 15.776l7.535 7.52 2.659-2.65.354-.354.353.354 2.628 2.635 7.505-7.505Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEnvelopeOpenMedium;
