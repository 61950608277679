import type { SVGProps } from "react";
const SvgIc82 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-82_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-82_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6ZM38.35 9.59c-.539-.186-.953-.489-1.243-.91-.284-.422-.427-.934-.427-1.535 0-1.002.412-1.801 1.234-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.734.532 1.1 1.31 1.1 2.333 0 1.176-.45 2.112-1.352 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.195-1.547-1.195-2.634 0-1.018.354-1.806 1.06-2.365a2.924 2.924 0 0 1 1.068-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.316.285.738.427 1.266.427.527 0 .944-.142 1.25-.427.305-.285.458-.672.458-1.163 0-.48-.155-.854-.467-1.123-.305-.269-.733-.403-1.28-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.358.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.37.354.846.53 1.432.53.664 0 1.163-.195 1.495-.585.322-.374.482-.807.482-1.297 0-.56-.184-1.018-.553-1.377-.354-.348-.841-.522-1.464-.522Zm13.226 3.283v2.056h-8.33c0-.627.1-1.226.301-1.795.206-.57.493-1.06.862-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.554-.385.942-.744 1.163-1.076.221-.332.332-.717.332-1.155 0-.506-.15-.915-.45-1.226-.296-.316-.686-.474-1.171-.474-1.192 0-1.806.857-1.843 2.57H45.61V8.34c0-1.402.393-2.489 1.178-3.259.765-.749 1.754-1.123 2.967-1.123 1.075 0 1.975.306 2.697.918.786.664 1.179 1.558 1.179 2.681 0 .981-.314 1.825-.942 2.531a4.65 4.65 0 0 1-.577.562c-.18.137-.651.472-1.416 1.005-.728.506-1.25.893-1.566 1.162a6.348 6.348 0 0 0-.87.926h5.426Z"
      />
    </g>
    <defs>
      <clipPath id="ic-82_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc82;
