import type { SVGProps } from "react";
const SvgHikingBootLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 11H9v17.016A7.597 7.597 0 0 0 8.5 28H8v7H7v5h9v-1.991c1.301.045 2.455.26 3.592.482l.18.035c1.054.206 2.11.413 3.228.463V40h18.001v-3.5H41a6.5 6.5 0 0 0-6.5-6.5h-5c-2.163 0-3.886-1.598-5.152-4H25v-1h-1.133a19.66 19.66 0 0 1-.747-2H24v-1h-1.17a25.318 25.318 0 0 1-.446-2H23v-1h-.776A21.886 21.886 0 0 1 22 16v-5H9.5Zm13.28 14a21.555 21.555 0 0 1-.706-2H20v-1h1.796a26.497 26.497 0 0 1-.427-2H19v-1h2.214A22.723 22.723 0 0 1 21 16H10v12.15a7.504 7.504 0 0 1 5.984 6.858c1.411.044 2.647.276 3.8.501l.151.03c1.233.24 2.36.461 3.565.461h16.478a5.502 5.502 0 0 0-5.478-5h-5c-2.883 0-4.933-2.246-6.272-5H21v-1h1.78Zm17.221 12H23.5c-1.305 0-2.518-.237-3.728-.474l-.18-.035C18.312 36.24 17.01 36 15.5 36H8v3h7v-2h.5c1.615 0 3 .259 4.283.51l.152.029c1.233.24 2.36.461 3.565.461h.5v1h16.001v-2ZM9 35v-5.981A6.501 6.501 0 0 1 14.981 35H9Zm1-20h11v-3H10v3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHikingBootLarge;
