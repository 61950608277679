import type { SVGProps } from "react";
const SvgSaBb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.522 6.656H5.421l.74-3.45h.981c1.06 0 2.34.06 2.34 1.404 0 1.645-1.54 2.046-2.96 2.046Zm-1.421 6.138h-.96l.84-4.051h.9c1.5 0 2.861.22 2.861 1.685 0 2.165-1.921 2.366-3.641 2.366ZM8.062 1H3.921L1 15h3.981c2.081 0 6.562-.32 6.562-4.332 0-1.505-.681-2.708-2.241-2.949v-.04c1.62-.26 2.981-1.624 2.981-3.329 0-2.548-2.041-3.35-4.221-3.35Zm10.68 5.656h-1.1l.74-3.45h.98c1.06 0 2.34.06 2.34 1.404 0 1.645-1.54 2.046-2.96 2.046Zm-1.42 6.138h-.96l.84-4.051h.9c1.5 0 2.86.22 2.86 1.685 0 2.165-1.92 2.366-3.64 2.366ZM20.282 1h-4.141L13.22 15h3.982c2.08 0 6.56-.32 6.56-4.332 0-1.505-.68-2.708-2.24-2.949v-.04c1.62-.26 2.98-1.624 2.98-3.329 0-2.548-2.04-3.35-4.22-3.35Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaBb;
