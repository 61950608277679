import type { SVGProps } from "react";
const SvgWheelchairInaccessibleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.75 8.75a.25.25 0 1 0 0 .501.25.25 0 0 0 0-.5ZM14.5 9a1.25 1.25 0 1 1 2.5.001 1.25 1.25 0 0 1-2.5 0Zm8.792 19.5 2.604 2.604.708-.707-18.75-18.75-.708.707 4.428 4.428A6.49 6.49 0 0 0 9.25 21.75c0 3.575 2.927 6.5 6.5 6.5a6.48 6.48 0 0 0 4.965-2.33l2.036 2.037v.542h.542Zm-3.289-3.289-7.719-7.72a5.494 5.494 0 0 0-2.035 4.26c0 3.022 2.479 5.5 5.5 5.5 1.71 0 3.242-.797 4.254-2.04Zm3.247-5.461h-3v1h2.5v2.5h1v-3.5h-.5Zm-7-7.75v3.75h-1V12h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairInaccessibleMedium;
