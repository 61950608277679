import type { SVGProps } from "react";
const SvgMilkBrickDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 2H9v2.348L7.884 6.023l.832.554.784-1.176 1.5 2.25V9h1V8h4v6h1V7.349l-.084-.126L15 4.349V2H9.5Zm2.268 5h3.798l-1.334-2h-3.798l1.334 2ZM14 4h-4V3h4v1Zm2 12.707V21h-4v-8.293l4 4Zm-5-5V21H8V8.707l3 3ZM7.5 22H17v-4.293l3.646 3.647.708-.708-18-18-.708.708L7 7.707V22h.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMilkBrickDisabledSmall;
