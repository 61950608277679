import type { SVGProps } from "react";
const SvgArrowCircleTrainTracksMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3.25C9.854 3.25 3.25 9.854 3.25 18h1c0-7.594 6.156-13.75 13.75-13.75S31.75 10.406 31.75 18 25.594 31.75 18 31.75c-5.547 0-10.544-3.287-12.74-8H9v-1H3.25v5.75h1v-4.555c2.305 5.19 7.73 8.805 13.75 8.805 8.146 0 14.75-6.604 14.75-14.75S26.146 3.25 18 3.25ZM13.767 7.5h1v3.243h6.5V7.5h1v3.243H24v1h-1.733v3.5H24v1h-1.733v3.5H24v1h-1.733v3.5H24v1h-1.733V28.5h-1v-3.257h-6.5V28.5h-1v-3.257H12v-1h1.767v-3.5H12v-1h1.767v-3.5H12v-1h1.767v-3.5H12v-1h1.767V7.5Zm1 16.743h6.5v-3.5h-6.5v3.5Zm6.5-8v3.5h-6.5v-3.5h6.5Zm-6.5-1h6.5v-3.5h-6.5v3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleTrainTracksMedium;
