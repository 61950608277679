import type { SVGProps } from "react";
const SvgTwoAdultsKidSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM4 5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM18.5 9a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM4.5 8h-.377l-.104.363-2 7-.182.637H4v4h3v-4h2.163l-.182-.637-2-7L6.877 8H4.5ZM6 16H5v3h1v-3Zm-2.837-1 1.714-6h1.246l1.714 6H3.163ZM12.5 5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM10.5 8H10v6h1v6h3v-6h1V8h-4.5Zm1 5H11V9h3v4h-1v6h-1v-6h-.5Zm4.5-1h5v5h-1v3h-3v-3h-1v-5Zm1 4h1v3h1v-3h1v-3h-3v3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoAdultsKidSmall;
