import type { SVGProps } from "react";
const SvgBrochureSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m2 2.806.658.22L11.5 5.973l8.842-2.947.658-.22V18.86l-.342.114-9 3-.158.053-.158-.053-9-3L2 18.86V2.806Zm1 1.388V18.14l8 2.666V6.86L3 4.194Zm9 2.666v13.946l8-2.666V4.194L12 6.86ZM9.842 9.474l-6-2 .316-.948 6 2-.316.948Zm3.316 0 6-2-.316-.948-6 2 .316.948Zm0 3 6-2-.316-.948-6 2 .316.948Zm6 1-6 2-.316-.949 6-2 .316.949Zm-6 5 6-2-.316-.949-6 2 .316.949Zm-9.316-8 6 2 .316-.949-6-2-.316.95Zm6 5-6-2 .316-.949 6 2-.316.95Zm-6 1 6 2 .316-.949-6-2-.316.949Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBrochureSmall;
