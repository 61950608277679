import type { SVGProps } from "react";
const SvgTwoHousesMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m22.854 4.146-.354-.354-.354.354L19.5 6.793l-1.146-1.147L18 5.293l-.354.353-12 12 .708.707 1.396-1.396V29.75h6.985v-7.5h6.5v7.5h7.015v-1.5h4.5V15.457l1.396 1.396.707-.707-12-12Zm8.896 10.31-9.25-9.25L20.207 7.5l10.147 10.146-.707.707-1.397-1.396V27.25h3.5V14.457Zm-4.5 1.5L18 6.707l-9.25 9.25V28.75h4.985v-7.5h8.5v7.5h5.015V15.957Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoHousesMedium;
