import type { SVGProps } from "react";
const SvgCloudFogSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.313 9.856c.493.112.897.38 1.19.746.4.5.572 1.156.529 1.795a2.744 2.744 0 0 1-.823 1.793c-.52.502-1.265.81-2.209.81H8c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.167-.764-1.808-.028-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.816c.092-1.85 2.006-2.895 3.522-2.176.946-1.538 2.625-1.917 3.988-1.422.745.27 1.403.803 1.794 1.549.318.607.447 1.335.322 2.139Zm-2.458-2.748c-1.049-.38-2.362-.046-2.997 1.394l-.266.602-.509-.417c-.967-.792-2.566-.048-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.462-.217.303-.34.728-.32 1.165.018.435.175.847.473 1.148.293.295.763.524 1.492.524h7c.716 0 1.202-.229 1.515-.53.318-.307.49-.72.52-1.141.028-.424-.09-.824-.313-1.103-.212-.265-.536-.445-1.002-.426l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073ZM17.993 17H5v-1h12.993v1ZM6 19h10.993v-1H6v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudFogSmall;
