import type { SVGProps } from "react";
const SvgDisplayLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 8.5A1.5 1.5 0 0 1 4.5 7h39A1.5 1.5 0 0 1 45 8.5V31H3V8.5ZM3 32v1.5A1.5 1.5 0 0 0 4.5 35h39a1.5 1.5 0 0 0 1.5-1.5V32H3Zm17.806 4H4.5A2.5 2.5 0 0 1 2 33.5v-25A2.5 2.5 0 0 1 4.5 6h39A2.5 2.5 0 0 1 46 8.5v25a2.5 2.5 0 0 1-2.5 2.5H27.194l1.666 5h5.64v1h-21v-1h5.64l1.666-5Zm5.334 0 1.666 5h-7.612l1.666-5h4.28ZM40 34h3v-1h-3v1Zm-1 0h-2v-1h2v1ZM27 9h-5V8h5v1Zm-7 0h1V8h-1v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDisplayLarge;
