import type { SVGProps } from "react";
const SvgArrowCircleLightningSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10c-3.823 0-7.292-2.15-9-5.319V19H2v-4h4v1H3.773c1.49 2.956 4.687 5 8.227 5a9 9 0 1 0-9-9H2Zm7.5-7h-.377l-.104.363-2 7-.182.637h4.053l-.88 4.402-.433 2.162L10.9 17.8l6-8 .6-.8h-4.191l1.638-3.276L15.31 5H9.5Zm-1.337 7 1.714-6h3.814l-1.638 3.276-.362.724H15.5l-4.077 5.437.567-2.839.12-.598H8.163Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleLightningSmall;
