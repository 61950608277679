import type { SVGProps } from "react";
const SvgFactorySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 3.5h5V11l3.2-2.4.8-.6v3l3.2-2.4.8-.6v3l3.2-2.4.8-.6v12.5h-17v-17Zm1 1v15h15V10l-3.2 2.4-.8.6v-3l-3.2 2.4-.8.6v-3l-3.2 2.4-.8.6V4.5h-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFactorySmall;
