import type { SVGProps } from "react";
const SvgDocumentSoundSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.698 3.997.147.147 5.009 5.006.146.146v10.708H6v-16h.5l5.99-.006h.208ZM7 5.003v14h10v-9h-4.999v-.498l-.009-4.507L7 5.003Zm5.994.704 3.299 3.297H13l-.006-3.297ZM13.217 11h-.207l-.147.146L11.01 13H8.997v3h2.012l1.854 1.859.147.148.21-.001L14.5 18l.497-.003V11h-1.78Zm-1.647 2.853L13.424 12h.573v5.002l-.574.003-1.852-1.858-.147-.147H9.997v-1h1.427l.146-.147Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentSoundSmall;
