import type { SVGProps } from "react";
const SvgFunicularProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.43 4.755a2 2 0 0 0-.87-.042h-.002L3.063 9.033l.174.985 24.493-4.32a1 1 0 0 1 1.17 1v2.267h-6v10h6v2.75a1 1 0 0 1-.8 1l-25.048 5.01.196.98 1.127-.225a1.705 1.705 0 0 1 .525 1.234v.001a1.75 1.75 0 0 1-1.035 1.598l-.813.162.098.49v.5c.348 0 .69-.066 1.008-.191l14.045-2.81c.33-.006.653-.072.955-.19l6.545-1.31c.33-.006.653-.072.955-.19l6.59-1.318-.196-.981-4.953.99c.196-.383.3-.81.3-1.25h-.5.5a2.704 2.704 0 0 0-.422-1.455l.321-.064h.002a2 2 0 0 0 1.6-1.995V6.709a2 2 0 0 0-1.47-1.954Zm-2.065 22.058a1.751 1.751 0 0 0 1.035-1.598 1.703 1.703 0 0 0-.525-1.235l-2.836.567a1.84 1.84 0 0 0-.14.671 1.75 1.75 0 0 0 1.706 1.747l.76-.152Zm-2.495.498a2.75 2.75 0 0 1-.97-2.096v-.006c.002-.149.015-.296.04-.442l-2.463.493a2.718 2.718 0 0 1 .122 2.706l3.271-.655Zm-5.005 1.002a1.751 1.751 0 0 0 1.035-1.598 1.703 1.703 0 0 0-.525-1.235l-2.836.567a1.84 1.84 0 0 0-.14.671 1.75 1.75 0 0 0 1.706 1.747l.76-.152Zm-2.495.498a2.75 2.75 0 0 1-.97-2.096v-.006c.002-.149.015-.296.04-.442L5.479 28.26a2.7 2.7 0 0 1 .422 1.455c0 .44-.105.867-.3 1.25l10.77-2.154Zm-10.47.904h-.5.5Zm15-3h-.5.5Zm8-8.75v-8h-5v8h5Zm-16-7.5h-.5v10h7v-10h-6.5Zm.5 9v-8h5v8h-5Zm-5.5-6.5H3.15v-1H8.9v10H3.15v-1H7.9v-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFunicularProfileMedium;
