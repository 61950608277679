import type { SVGProps } from "react";
const SvgCalendarOneDayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.75 9.265V7.5h-1v1.765h-4.5v17.454l.499.001 22.5.045.501.001V9.265h-4.5V7.5h-1v1.765h-12.5Zm12.5 2.734v-1.734h-12.5v1.734h-1v-1.734h-3.5v3.514h21.5v-3.514h-3.5v1.734h-1Zm-17 13.722V14.78h21.5v10.984l-21.5-.043Zm12.512-1.72v-7.5h-1.174c-.096.502-.282.859-.56 1.07a1.881 1.881 0 0 1-.633.32c-.23.064-.53.102-.895.112v1.088h1.764V24h1.498Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCalendarOneDayMedium;
