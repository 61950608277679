import type { SVGProps } from "react";
const SvgCoffeeMachineMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.25 5.25a1 1 0 0 1 1-1h25.5a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1H5.25a1 1 0 0 1-1-1v-4.5Zm1-2a2 2 0 0 0-2 2v4.5a2 2 0 0 0 1.5 1.937v15.626a2 2 0 0 0-1.5 1.937v1.5a2 2 0 0 0 2 2h25.5a2 2 0 0 0 2-2v-1.5a2 2 0 0 0-1.5-1.937V11.687a2 2 0 0 0 1.5-1.937v-4.5a2 2 0 0 0-2-2H5.25Zm25 24v-15.5h-8v4.5h-1.5V18h-1v-1.75h-3.5V18h-1v-1.75h-1.5v-4.5h-8v15.5h7.86a4.01 4.01 0 0 1-1.36-3.01v-4.49h11.5v1.5h1.285a1.715 1.715 0 0 1 1.715 1.715v.555a1.73 1.73 0 0 1-1.73 1.73h-1.4a4.009 4.009 0 0 1-1.23 2h7.86Zm-6.5-3h1.27a.73.73 0 0 0 .73-.73v-.555a.715.715 0 0 0-.715-.715H23.75v2Zm-9.618 2.118a3.01 3.01 0 0 0 2.128.882h3.48a3.01 3.01 0 0 0 3.01-3.01v-3.49h-9.5v3.49c0 .798.317 1.564.882 2.128Zm2.128 1.882h14.49a1 1 0 0 1 1 1v1.5a1 1 0 0 1-1 1H5.25a1 1 0 0 1-1-1v-1.5a1 1 0 0 1 1-1h11.01Zm-1.51-16.5v3.5h6.5v-3.5h-6.5ZM24 6.25h4.5v1H24v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCoffeeMachineMedium;
