import type { SVGProps } from "react";
const SvgKRSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.003 6H3V5h18.002v1Zm0 13H3v-1h18.002v1ZM6.958 11.162l3.136-3.164H7.883l-3.136 3.31v-3.31H3v8h1.746v-2.582l1.007-1.007 2.43 3.59h2.205l-3.431-4.837Zm2.39.942h1.541v-1.541h.884v1.541h1.547v.884h-1.547v1.542h-.884v-1.542H9.347v-.883Zm6.344-.532v-2.2h1.74c.511 0 .885.087 1.122.263.238.174.356.452.356.83 0 .392-.139.684-.416.877-.219.153-.597.23-1.133.23h-1.669Zm2.13-3.574h-3.876v8h1.746v-3.146h1.57c.56 0 .953.109 1.185.326.232.216.37.608.414 1.173.043.635.08 1.04.106 1.215.028.176.073.32.134.433h1.822l-.076-.093c-.105-.135-.184-.48-.235-1.034-.069-.756-.126-1.24-.17-1.45a1.736 1.736 0 0 0-.257-.608 1.35 1.35 0 0 0-.832-.591 1.87 1.87 0 0 0 .94-.744c.223-.347.336-.753.336-1.221 0-.412-.09-.782-.269-1.111a2.02 2.02 0 0 0-.777-.788c-.409-.24-.996-.361-1.762-.361Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKRSmall;
