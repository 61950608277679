import type { SVGProps } from "react";
const SvgSaLc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.122 3.133c1.5 0 2.56.37 3.201.795l.7-2.268c-1.22-.486-2.7-.66-4.1-.66-4.942 0-8.003 3.219-8.003 7.582 0 3.664 2.02 6.418 6.242 6.418 1.06 0 2.14-.098 3.16-.368l.44-2.599c-.8.465-1.76.835-2.94.835-2.46 0-3.98-2.017-3.98-4.286 0-3.18 2.18-5.449 5.28-5.449Zm-13.36-1.9H3.961L1 14.767h8.262l.48-2.132H4.261L6.762 1.233Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaLc;
