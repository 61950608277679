import type { SVGProps } from "react";
const SvgColourPaletteBrushSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.332 5.698c.34-.958 1.002-1.955 2.336-2.46a3.925 3.925 0 0 0-.09.475c-.11.886.06 2.047 1.09 3.18a1.986 1.986 0 0 1-.347 2.598h-2.642A1.986 1.986 0 0 1 17 7.999v-.038l-.001-.007a2.312 2.312 0 0 1-.006-.167 6.168 6.168 0 0 1 .34-2.09ZM15.997 7.97l.003.048a2.98 2.98 0 0 0 1 2.206V20c0 1.106.894 2 2 2 1.106 0 2-.894 2-2v-9.776c.603-.545 1-1.328 1-2.224 0-.632-.191-1.222-.53-1.707l-.02-.027-.022-.025c-.829-.899-.936-1.767-.857-2.404a3 3 0 0 1 .315-1.007 1.5 1.5 0 0 1 .029-.05l.006-.01.618-.956-1.124.193c-2.406.413-3.527 1.951-4.025 3.357a7.163 7.163 0 0 0-.393 2.606ZM20 10.49h-2V12h2v-1.51ZM18 20v-7h2v7c0 .554-.446 1-1 1s-1-.446-1-1ZM12 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM7 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5 9a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm5 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm1.284-13.99c-4.896-.137-9.021 3.253-10.042 7.8v.002c-.295 1.331.57 2.539 1.786 2.925C5.795 13.3 7 14.79 7 16.5c0 .483-.097.945-.278 1.36l-.002.005c-.513 1.209-.063 2.682 1.18 3.259h.002A9.814 9.814 0 0 0 11.99 22c1.454 0 2.926-.337 4.211-.937l-.423-.906A9.08 9.08 0 0 1 11.99 21a8.814 8.814 0 0 1-3.671-.784c-.696-.324-1.005-1.188-.68-1.958.238-.545.36-1.142.36-1.758 0-2.211-1.554-4.04-3.667-4.716h-.001c-.803-.255-1.278-1.007-1.113-1.755.92-4.093 4.633-7.142 9.037-7.02a8.797 8.797 0 0 1 3.53.862l.43-.902a9.771 9.771 0 0 0-3.93-.959h-.001Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgColourPaletteBrushSmall;
