import type { SVGProps } from "react";
const SvgRouteCircleStartSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 7a5.5 5.5 0 1 0 .002 11.002A5.5 5.5 0 0 0 9.5 7ZM3 12.5a6.5 6.5 0 0 1 12.981-.5H21v1h-5.019A6.5 6.5 0 0 1 3 12.5ZM9.5 10a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM6 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRouteCircleStartSmall;
