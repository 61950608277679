import type { SVGProps } from "react";
const SvgTrainTracksLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.05 4v4h-4v5h4v4h-4v5h4v4h-4v5h4v4h-4v5h4v4h1v-4h18v4h1v-4h4v-5h-4v-4h4v-5h-4v-4h4v-5h-4v-4h4V8h-4V4h-1v4h-18V4h-1Zm19 31v-4h-18v4h18Zm-18 1h18v3h-18v-3Zm-1 0h-3v3h3v-3Zm20 3v-3h3v3h-3Zm-23-12h3v3h-3v-3Zm4 0v3h18v-3h-18Zm22 3h-3v-3h3v3Zm-22-4h18v-4h-18v4Zm18-9v-4h-18v4h18Zm-18 1h18v3h-18v-3Zm-1 0h-3v3h3v-3Zm20 3v-3h3v3h-3Zm-23-12h3v3h-3V9Zm22 3h-18V9h18v3Zm1 0V9h3v3h-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrainTracksLarge;
