import type { SVGProps } from "react";
const SvgSaCc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 11h18V7H22v8h-1.5v-2.5h-18V15H1V7h1.5v4Zm5-4c1.1 0 2 .9 2 2v1h-6V7h4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaCc;
