import type { SVGProps } from "react";
const SvgDownloadSmallDataMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m20.647 10.146-2.897 2.896V4.5h-1v8.54l-2.9-2.906-.708.707 3.754 3.762.354.354.354-.354 3.75-3.75-.707-.707ZM5.25 9.25h-.5v17.5h25V9.25H22.5v1h6.25v15.5h-23v-15.5H12v-1H5.25Zm2.25 14.5H27v-1H7.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDownloadSmallDataMedium;
