import type { SVGProps } from "react";
const SvgConstructionMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.75 8.25c0-.523.477-1 1-1s1 .477 1 1-.477 1-1 1-1-.477-1-1Zm1-2c-1.076 0-2 .924-2 2s.924 2 2 2 2-.924 2-2-.924-2-2-2ZM10.5 10h-.36l-.114.342-1.245 3.732-2.428-2.428-.707.708 2.88 2.878.709 5.68-3.682 7.364.894.448 3.75-7.5.068-.136-.019-.15-.57-4.554 1.887 1.886 1.397 4.192-2.907 5.814.894.448 3-6 .093-.186-.066-.196-.83-2.49 5.409 5.408-.962 2.885-5.815 2.908.448.894 6-3 .185-.092.065-.197 1.435-4.303 2.815-1.408.098-.049.068-.086 2.85-3.562h2.47l2.856 4.998.868-.496-3-5.25-.144-.252h-3.53l-.15.188-2.932 3.664-2.902 1.45-.185.093-.065.197-.12.357-3.363-3.363 1.447-7.238.053-.262-.19-.19-3-3-.146-.146H10.5Zm5.293 3.5L12 17.293 9.707 15l3.793-3.793 2.293 2.293Zm-3.5-2.5-2.149 2.149L10.86 11h1.433Zm3.393 4.021L12.707 18l1.986 1.986.993-4.965Zm11.778 8.415-1.5 3.75-.057.144-.13.086-4.5 3-.554-.832 4.37-2.914 1.443-3.606.928.372Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgConstructionMedium;
