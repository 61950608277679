import type { SVGProps } from "react";
const SvgChildAdultSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 3.057c-.524 0-1 .476-1 1 0 .523.476 1 1 1s1-.477 1-1c0-.524-.476-1-1-1Zm-2 1c0-1.077.924-2 2-2s2 .924 2 2-.924 2-2 2-2-.924-2-2ZM15 8v5h2V8h-2Zm-1 .707v8.687l-2.405 3.312.81.588 2.5-3.444.095-.131V14h2.23L19 16.706V22h1v-5.593l-.081-.124L18 13.35v-4.2l1.084 1.626.062.094.097.058 2.5 1.5.514-.858-2.403-1.442-1.938-2.906L17.768 7h-3.475l-.146.146-3 3-.147.147V13H9.118l-1.894-.947L7.118 12H3.793l-.147.146-1.5 1.5-.146.147V17h1v-2.793l1-1v5.626L2.6 20.7l.8.6 1.5-2 .1-.133V17h1.65l1.88 5.17.94-.34-1.97-5.418v-3.103l1.276.638.106.053H12v-3.293l2-2ZM5.5 9c-.257 0-.5.243-.5.5s.243.5.5.5.5-.243.5-.5-.243-.5-.5-.5ZM4 9.5C4 8.69 4.69 8 5.5 8S7 8.69 7 9.5 6.31 11 5.5 11 4 10.31 4 9.5ZM5 16v-3h1.5v3H5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChildAdultSmall;
