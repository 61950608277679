import type { SVGProps } from "react";
const SvgPowerPlugMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.25 10.75h-11V4.5h-1v6.25h-3V18c0 2.873 2.327 5.75 5.75 5.75h.25v4.5h3v3.25h1v-3.25h3v-4.5h.25c3.02 0 5.75-2.416 5.75-5.75v-7.25h-3V4.5h-1v6.25Zm-8 16.5v-3.5h5v3.5h-5Zm-6-15.5V18c0 2.383 1.939 4.75 4.75 4.75h7.5c2.503 0 4.75-2.003 4.75-4.75v-6.25h-17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPowerPlugMedium;
