import type { SVGProps } from "react";
const SvgSurveillanceCamerasTrainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#surveillance-cameras-train-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33 3.25h-7.5v1h2.733l2.333 3.5H25.5A4.25 4.25 0 0 0 21.25 12v14.75h3.441l-1 2h-1.19v1h.69l-1 2h-1.19v1h12v-1h-9.692l1-2H33v-1h-8.19l1-2H33v-1H22.25v-6.5H33v-1H22.25V12a3.25 3.25 0 0 1 3.25-3.25H33v-1h-1.232l-2.334-3.5H33v-1ZM5.677 6.888l.46.131 10.5 3 .66.188-.38.57-.388.58 1.63.544.515.171-.214.5-1.125 2.625-.197.46-.46-.197-2.243-.962-.519.78-.212.317-.362-.12-3.092-1.031v4.25l6.387 1.825.66.188-.38.57-.388.58 1.63.544.515.172-.214.499-1.125 2.625-.197.46-.46-.197-2.243-.962-.519.78-.212.317-.362-.12-3.092-1.031v4.806H6v-1h3.25v-4.14l-4.908-1.636-.474-.158.158-.474 1.5-4.5.151-.454.46.131 3.113.89V14.11l-4.908-1.636-.474-.158.158-.474 1.5-4.5.151-.454ZM15 13.652l.954-1.432 1.372.457-.713 1.667L15 13.652Zm-8.677-5.54-1.19 3.572 8.163 2.72 2.408-3.611-9.38-2.68Zm10.29 16.732L15 24.151l.954-1.432 1.372.457-.713 1.666Zm-11.48-2.66 1.19-3.572 9.381 2.68-2.408 3.613-8.163-2.721ZM31.5 10.75h-3v1h3v-1ZM27 22.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="surveillance-cameras-train-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSurveillanceCamerasTrainMedium;
