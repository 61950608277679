import type { SVGProps } from "react";
const SvgChevronSmallDownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.64 14.651-3.903-3.95.71-.703 3.55 3.59L15.544 10l.71.703-3.903 3.948-.356.36-.355-.36Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallDownSmall;
