import type { SVGProps } from "react";
const SvgSpannerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.749 9.75a6.5 6.5 0 0 1 6.5-6.5c.956 0 1.855.218 2.667.583l.66.298-.512.512-3.815 3.815v2.293h2.293l3.815-3.815.511-.512.298.66c.368.812.583 1.711.583 2.667a6.5 6.5 0 0 1-6.5 6.5 6.4 6.4 0 0 1-2.344-.45l-8.103 8.104a6.42 6.42 0 0 1 .447 2.346 6.5 6.5 0 0 1-6.5 6.5 6.446 6.446 0 0 1-2.666-.585l-.659-.298.511-.511 3.814-3.813V25.25H8.456l-3.813 3.813-.512.511-.298-.658a6.446 6.446 0 0 1-.584-2.666 6.5 6.5 0 0 1 6.5-6.5c.83 0 1.62.162 2.346.447l8.103-8.103a6.4 6.4 0 0 1-.449-2.344Zm6.5-5.5a5.5 5.5 0 0 0-5.5 5.5c0 .803.182 1.56.496 2.255l.142.315-.244.244-8.579 8.579-.244.244-.316-.142a5.452 5.452 0 0 0-2.255-.494 5.5 5.5 0 0 0-5.5 5.5c0 .541.083 1.063.234 1.559l3.412-3.413.147-.146h3.707v3.707l-.146.146-3.413 3.413c.495.15 1.017.234 1.559.234a5.5 5.5 0 0 0 5.5-5.5c0-.803-.18-1.563-.494-2.256l-.143-.315.245-.244 8.579-8.579.244-.244.314.142a5.47 5.47 0 0 0 2.255.496 5.5 5.5 0 0 0 5.5-5.5 5.37 5.37 0 0 0-.232-1.56l-3.414 3.413-.147.147H24.25V8.043l.146-.146 3.414-3.413a5.371 5.371 0 0 0-1.56-.233Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpannerMedium;
