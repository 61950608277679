import type { SVGProps } from "react";
const SvgTrafficJamMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#traffic-jam-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.56 3.25c-.66 0-1.298.336-1.663.9L3.591 7.594A1.986 1.986 0 0 0 3.25 8.7v4.55h3.063a2 2 0 0 0 3.874 0H12v-1h-1.813a2 2 0 0 0-3.874 0H4.25v-3.5h17.5c.549 0 1 .451 1 1v.75h1v-.75c0-1.101-.899-2-2-2h-1.293L16.54 3.831a1.985 1.985 0 0 0-1.419-.581H7.56Zm11.483 4.5L15.83 4.539a.985.985 0 0 0-.711-.289h-1.87v3.5h5.793Zm-6.793-3.5H7.56a.993.993 0 0 0-.824.445l-.005.008L4.69 7.75h7.559v-3.5Zm-5 8.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm7.632.4a1.993 1.993 0 0 1 1.663-.9h7.575c.533 0 1.036.2 1.418.581l3.92 3.919h1.292c1.101 0 2 .899 2 2v3.5h-3.063a2 2 0 0 1-3.874 0H19.5v-1h6.313a2 2 0 0 1 3.874 0h2.063v-2.5c0-.549-.451-1-1-1H13.235v1.75h-1v-1.8c0-.383.119-.78.341-1.105l2.306-3.445Zm9.95.389 3.21 3.211H22.25v-3.5h1.87c.277 0 .524.1.711.289Zm-8.287-.289h4.705v3.5h-7.575l2.04-3.047.006-.008a.992.992 0 0 1 .824-.445Zm11.205 7.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-20.19.5c-.66 0-1.298.337-1.663.9l-2.306 3.445A1.986 1.986 0 0 0 3.25 26.7v4.55h3.063a2 2 0 0 0 3.874 0h6.626a2 2 0 0 0 3.874 0h3.063v-3.5c0-1.101-.899-2-2-2h-1.293l-3.918-3.919a1.985 1.985 0 0 0-1.419-.581H7.56Zm11.483 4.5-3.212-3.211a.984.984 0 0 0-.711-.289h-1.87v3.5h5.793Zm-6.793-3.5H7.56a.992.992 0 0 0-.824.445l-.005.008-2.04 3.047h7.559v-3.5Zm-8 4.5v3.5h2.063a2 2 0 0 1 3.874 0h6.626a2 2 0 0 1 3.874 0h2.063v-2.5c0-.549-.451-1-1-1H4.25Zm3 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm10.5 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="traffic-jam-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTrafficJamMedium;
