import type { SVGProps } from "react";
const SvgKeyboardSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5 6H10v4H3v9h18v-9H11V7h4V6h-4.5ZM4 11v7h16v-7H4Zm1 2h1v-1H5v1Zm0 1v1h2v-1H5Zm3-1H7v-1h1v1Zm0 2h1v-1H8v1Zm0 2H7v-1h1v1Zm-3 0h1v-1H5v1Zm12 0h-1v-1h1v1Zm1-1v1h1v-1h-1Zm-7-2v1h-1v-1h1Zm1 1h1v-1h-1v1Zm3 0h-1v-1h1v1Zm1 0h1v-1h-1v1Zm-6-2H9v-1h1v1Zm1 0h1v-1h-1v1Zm3 0h-1v-1h1v1Zm1 0h1v-1h-1v1Zm-6 4v-1h6v1H9Zm8-4h1v2h1v-3h-2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKeyboardSmall;
