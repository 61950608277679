import type { SVGProps } from "react";
const SvgMicroscooterProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.25 7.749H24v1h1.75v11.025a5.009 5.009 0 0 0-4.476 4.475H11.45a2.753 2.753 0 0 0-2.45-1.5 2.753 2.753 0 0 0-2.75 2.75A2.753 2.753 0 0 0 9 28.249a2.753 2.753 0 0 0 2.739-3h10.51v-.5c0-2.205 1.796-4 4-4h.5v-13h-.5ZM9 23.749c-.964 0-1.75.786-1.75 1.75s.786 1.75 1.75 1.75 1.75-.786 1.75-1.75-.786-1.75-1.75-1.75Zm18 0c-.964 0-1.75.786-1.75 1.75s.786 1.75 1.75 1.75 1.75-.786 1.75-1.75-.786-1.75-1.75-1.75Zm-2.75 1.75a2.753 2.753 0 0 1 2.75-2.75 2.753 2.753 0 0 1 2.75 2.75 2.753 2.753 0 0 1-2.75 2.75 2.753 2.753 0 0 1-2.75-2.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMicroscooterProfileMedium;
