import type { SVGProps } from "react";
const SvgSaTn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.501 11.63 22.782 1h2.641l-3.02 14H18.8L15.88 4.27h-.04L13.62 15h-2.68l3-14h3.541l2.981 10.63h.04ZM12.262 1H1.48L1 3.206h3.94L2.4 14.999h2.921l2.54-11.793h3.941L12.262 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaTn;
