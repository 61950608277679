import type { SVGProps } from "react";
const SvgPauseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 5H7v14h4V5H7.5ZM8 18V6h2v12H8Zm5.501-13h-.5v14h4V5h-3.5Zm.5 13V6h2v12h-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPauseSmall;
