import type { SVGProps } from "react";
const SvgGearsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.25 4.5v3.261c1.636.072 3.175.496 4.544 1.198l1.713-2.892.86.51-1.705 2.88a10.962 10.962 0 0 1 2.806 2.559l2.774-1.572.493.87-2.69 1.525a10.972 10.972 0 0 1 1.694 5.411H33v1h-3.261a10.997 10.997 0 0 1-1.093 4.332l2.607 1.664-.538.843-2.547-1.626a10.964 10.964 0 0 1-3.66 3.677l1.432 2.673-.881.472-1.427-2.663a10.996 10.996 0 0 1-4.382 1.117V33h-1v-3.26a10.992 10.992 0 0 1-4.488-1.17l-1.585 2.8-.87-.492 1.584-2.799a10.962 10.962 0 0 1-3.843-4.114l-2.757 1.56-.493-.87 2.818-1.595a11.018 11.018 0 0 1-.855-3.809H4.5v-1h3.261a11.01 11.01 0 0 1 1.129-4.407L5.994 12.13l.51-.86 2.87 1.699a10.964 10.964 0 0 1 3.364-3.448L11.198 6.8l.87-.493L13.6 9.014a10.99 10.99 0 0 1 4.65-1.253v-3.26h1Zm-4.994 23.198a10.025 10.025 0 0 0 4.494 1.052 9.958 9.958 0 0 0 9.988-9.5h-5.012c-.244 2.558-2.344 4.5-4.976 4.5a5.084 5.084 0 0 1-2.024-.413l-2.47 4.361Zm1.597-4.849-2.468 4.358A9.962 9.962 0 0 1 8.75 18.75a9.961 9.961 0 0 1 4.481-8.358l2.473 4.368c-1.195.906-1.954 2.345-1.954 3.99 0 1.713.823 3.202 2.103 4.099Zm.706-8.61-2.466-4.354A10.02 10.02 0 0 1 18.75 8.75a9.957 9.957 0 0 1 9.988 9.5h-5.012c-.244-2.558-2.344-4.5-4.976-4.5a5.07 5.07 0 0 0-2.191.49ZM14.75 18.75c0-2.248 1.752-4 4-4s4 1.752 4 4c0 2.249-1.752 4-4 4s-4-1.751-4-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGearsMedium;
