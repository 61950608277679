import type { SVGProps } from "react";
const SvgArrowTurnRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.25 21.25c-4.377 0-7.222-1.308-8.982-3.342-1.772-2.047-2.518-4.91-2.518-8.158h-1c0 3.38.774 6.516 2.762 8.813 2 2.309 5.155 3.687 9.738 3.687h6.268l-3.849 3.857.708.707 4.713-4.724.353-.353-.354-.353-4.713-4.712-.707.707 3.872 3.871z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowTurnRightMedium;
