import type { SVGProps } from "react";
const SvgWalkLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.5 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.389 1.421a2.5 2.5 0 1 1 2.778 4.157 2.5 2.5 0 0 1-2.778-4.157ZM20.53 14.03h-.175l-.136.109-5.53 4.4-.117.092-.046.14-2 6-.001.003a1.69 1.69 0 0 0 1.548 2.216h.01a1.69 1.69 0 0 0 1.633-1.177v-.002l1.713-5.293 2.53-1.997v7.949l-.633 7.867-5.87 5.87-.354.354.355.354 1.6 1.59.354.35.352-.35 6.59-6.57.122-.123.021-.17.946-7.642h.308L28 33.667V43h3V32.349l-.084-.126L27 26.349V18.64l3.66 3.377.093.085.122.031 6.18 1.6a1.57 1.57 0 0 0 1.82-.866l.003-.007.003-.007a1.558 1.558 0 0 0-.957-2.09l-.01-.003-.01-.003-5.76-1.606-5.27-4.985-.145-.137h-6.2Zm-5.103 5.199 5.277-4.199h5.627l5.215 4.933.09.085.12.034 5.868 1.636a.561.561 0 0 1 .338.74.57.57 0 0 1-.657.308l-6.058-1.569-4.408-4.064-.84-.774V26.65l.085.126L30 32.651V42h-1v-8.667l-.1-.133-4.5-6-.15-.2h-1.692l-.054.439-.98 7.909-6.115 6.097-.89-.886 5.644-5.645.13-.13.015-.184.65-8.07.002-.02V16.46l-.81.639-3.46 2.73-.12.094-.046.144-1.76 5.44v.003a.69.69 0 0 1-.662.481.69.69 0 0 1-.627-.903v-.001l1.952-5.857Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalkLarge;
