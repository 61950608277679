import type { SVGProps } from "react";
const SvgCloudCo2Small = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M5.996 11.08c-3.211 0-3.849 6.42 1.2 6.42h10.502c4.981 0 4.8-6.42 1.05-6.27 1.65-5.526-5.701-8.064-7.951-2.987-1.982-1.616-5.149-.065-4.8 2.836Z"
    />
    <path
      fill="currentColor"
      d="M9.517 14.27h.978v.127c-.002.435-.06.765-.173.99a1.096 1.096 0 0 1-.471.495c-.202.105-.471.158-.809.158-.422 0-.743-.099-.964-.296a1.236 1.236 0 0 1-.378-.651c-.066-.27-.1-.702-.1-1.294 0-.489.022-.858.067-1.108.043-.25.122-.454.235-.612.243-.336.637-.504 1.184-.504.475 0 .823.116 1.046.349.13.135.219.285.262.45.044.165.066.424.066.777h-.973v-.093c0-.233-.017-.407-.052-.522-.053-.18-.171-.27-.355-.27-.223 0-.353.14-.392.417a7.743 7.743 0 0 0-.053 1.051c0 .53.016.92.05 1.172.04.3.173.448.398.448.168 0 .282-.076.343-.228.06-.152.09-.438.09-.855Zm2.856-2.698c.64 0 1.067.215 1.277.645.084.17.143.37.174.602a7.6 7.6 0 0 1 .049 1c0 .66-.057 1.148-.17 1.462-.183.508-.628.762-1.333.762-.7 0-1.143-.26-1.33-.782-.111-.309-.167-.806-.167-1.491 0-.415.019-.74.056-.98.037-.24.101-.444.193-.614.213-.403.63-.604 1.251-.604Zm-.003.698c-.193 0-.32.098-.378.295-.055.182-.082.585-.082 1.21 0 .575.023.972.067 1.193.053.261.185.392.396.392.188 0 .312-.095.372-.289.06-.191.09-.589.09-1.19 0-.67-.026-1.105-.081-1.304-.057-.205-.185-.307-.384-.307Zm1.954.753c0-.442.067-.762.2-.961.214-.325.598-.487 1.15-.487.442 0 .775.102 1 .305.215.197.322.509.322.934 0 .317-.063.587-.19.812-.088.156-.26.356-.516.6-.291.278-.48.468-.567.572a1.45 1.45 0 0 0-.221.369h1.512v.78h-2.71a2.318 2.318 0 0 1 .22-1.038c.087-.185.18-.335.275-.448.096-.113.36-.39.794-.83.168-.17.278-.307.33-.413a.931.931 0 0 0 .08-.412c0-.196-.028-.338-.085-.428-.057-.09-.146-.135-.27-.135-.146 0-.247.056-.3.166-.054.11-.08.315-.08.614h-.944Z"
    />
  </svg>
);
export default SvgCloudCo2Small;
