import type { SVGProps } from "react";
const SvgUndergroundVehiculeProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.01 3.01h13.754a1.5 1.5 0 0 1 1.425 1.065l2.72 9 .003.01c.063.229.096.464.098.701V18.5a1.5 1.5 0 0 1-1.5 1.5H2.01v-1h16.5a.5.5 0 0 0 .5-.5v-4.708a1.68 1.68 0 0 0-.06-.433l-2.718-8.994a.5.5 0 0 0-.474-.355H2.01v-1ZM22 22H2.02v-1H22v1ZM10.5 5H10v8h7.673l-.182-.637-2-7L15.387 5H10.5Zm.5 7V6h3.633l1.714 6H11ZM2 5h7v13H2V5Zm4 12h2V6H6v11ZM3 6v11h2V6H3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUndergroundVehiculeProfileSmall;
