type Mode = "tiny" | "small" | "medium" | "big";

export declare interface Props {
  className?: string;
  fullbox?: boolean;
  mode?: Mode;
}

const rectangleClassName = {
  tiny: "mr-[0.2em] h-[0.33em] w-[0.53em]",
  small: "mr-[0.2em] h-[0.6em] w-[1em]",
  medium: "mr-[0.3em] h-[1.2em] w-[2em]",
  big: "mr-[0.46em] h-[1.8em] w-[3em]",
};

function Rectangle({ className, mode }: { className: string; mode: Mode }) {
  return <div className={`${className} bg-red ${rectangleClassName[mode]}`} />;
}

const loadingClassName = {
  tiny: "h-[1.66em] pt-[0.66em]",
  small: "h-[3em] pt-[1.2em]",
  medium: "h-[6em] pt-[2.4em]",
  big: "h-[9em] pt-[3.6em]",
};

const perspectiveClassName = {
  tiny: "h-[0.33em] w-[3.46em] [perspective:6em]",
  small: "h-[0.6em] w-[5.8em] [perspective:8em]",
  medium: "h-[1.2em] w-[11.2em] [perspective:12em]",
  big: "h-[1.8em] w-[16.66em] [perspective:16.6em]",
};

const containerClassName = {
  tiny: "animate-[loadingContainerTiny_.23s_linear_infinite]",
  small: "animate-[loadingContainerSmall_.23s_linear_infinite]",
  medium: "animate-[loadingContainerMedium_.23s_linear_infinite]",
  big: "animate-[loadingContainerBig_.23s_linear_infinite]",
};

export default function Loading({
  className = "",
  fullbox,
  mode = "medium",
  ...props
}: Props) {
  return (
    <div
      className={
        fullbox
          ? `absolute z-50 flex h-full w-full items-center justify-center bg-white bg-opacity-50 ${className}`
          : className
      }
      {...props}
    >
      <div className={`flex ${loadingClassName[mode]}`}>
        <div
          className={`mx-auto my-0 origin-center translate-x-[-3em] ${perspectiveClassName[mode]}`}
        >
          <div className="origin-right [transform:rotateY(50deg)_translateZ(1em)]">
            <div className={`flex ${containerClassName[mode]}`}>
              <Rectangle
                className="animate-[loadingRectangle1_.23s_linear_infinite]"
                mode={mode}
              />
              <Rectangle
                className="animate-[loadingRectangle2_.23s_linear_infinite]"
                mode={mode}
              />
              <Rectangle
                className="animate-[loadingRectangle3_.23s_linear_infinite]"
                mode={mode}
              />
              <Rectangle
                className="animate-[loadingRectangle4_.23s_linear_infinite]"
                mode={mode}
              />
              <Rectangle
                className="animate-[loadingRectangle5_.23s_linear_infinite]"
                mode={mode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
