import type { SVGProps } from "react";
const SvgTicketDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.146 31.854 29.04 29.75H3.25v-22h3.791L4.146 4.854l.707-.707 27 27-.707.707Zm-5.98-5.98 2.875 2.876H4.25v-20h3.791l16.75 16.75h-.541v.375h.917ZM32.25 7.75H13.5v1h18.25v5.023c-2.558.244-4.5 2.346-4.5 4.977 0 2.631 1.942 4.732 4.5 4.976V27h1v-4.25h-.5c-2.248 0-4-1.752-4-4 0-2.247 1.752-4 4-4h.5v-7h-.5Zm-8 1.8v-.524h1v.524h-1Zm0 2.094v-1.047h1v1.047h-1Zm0 2.095v-1.047h1v1.047h-1Zm0 2.095v-1.047h1v1.047h-1Zm0 2.095v-1.047h1v1.047h-1Zm0 1.571v-.524h1v.524h-1Zm0 7.125v.75h1v-.75h-1Zm0 1.5v.375h1v-.375h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketDisabledMedium;
