import type { SVGProps } from "react";
const SvgTwoSpeechBubblesLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 9H6v19h6v5.207l.854-.853L17.207 28H18v8h11.793l4.353 4.354.854.853V36h6V17H31V9H6.5ZM31 18v4h5v1h-5v3h5v1h-5v1H19v7h11.207l.147.146L34 38.793V35h6V18h-9ZM7 27V10h23v17H16.793l-.147.146L13 30.793V27H7Zm4-12h14v-1H11v1Zm12 8H11v-1h12v1Zm3-4H11v-1h15v1Zm7 11H23v1h10v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoSpeechBubblesLarge;
