import type { SVGProps } from "react";
const SvgMusicNotesMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.75 3.15v20.1a5 5 0 1 1-1-3V8.85l-15.5 2.817V27.75a5 5 0 1 1-1-3V6.332l.41-.075 16.5-3 .59-.107Zm-16.5 7.5 15.5-2.817V4.349l-15.5 2.818v3.484Zm-5 13.1a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm12.5-.5a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMusicNotesMedium;
