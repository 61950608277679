import type { SVGProps } from "react";
const SvgTelephoneGsmMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.75 4.75h-.5v1.5h-9v25h17.5v-25h-4.5v-1.5h-3.5Zm2.5 1.5v-.5h-2v.5h2Zm-11 24v-23h15.5v23h-15.5Zm13.75-8h-3v-1h3v1Zm-7.5 0h3v-1h-3v1Zm-1.5 0h-3v-1h3v1Zm-3 3h3v-1h-3v1Zm3 3h-3v-1h3v1Zm1.5 0h3v-1h-3v1Zm3-3h-3v-1h3v1Zm1.5 0h3v-1h-3v1Zm3 3h-3v-1h3v1Zm-11.25-19h-.5v10h11.5v-10h-11Zm.5 9v-8h9.5v8h-9.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTelephoneGsmMedium;
