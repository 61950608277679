import type { SVGProps } from "react";
const SvgRailwaySwitchAppSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.999 5.01v2h-1v1h1v2h-1v1h1v2h-1v1h1v2h-1v1h1v2h1v-1.892l9.418-14.334-.836-.549-1.166 1.775h-.417v.634L9.444 7.01h-.445v-2h1v-1h-1v-2h-1v2h-3v1h3v2h-3v1h3v1.2l-.523.794H5v1h1.82l-1.314 2H5v.771l-1 1.522V2.01H3v2H2v1zm11.524 3H12v-1h3.227l1.405-2H14v-1h3.335l1.256-1.788.818.575-.852 1.213H19v1h-1.146l-1.405 2h.55v1zm-2.803 3.989-.71 1.01H8v1h2.309l-1.406 2H6v1h2v2h1v-1.397l.423-.603H11v-1h-.874l1.405-2H13v-1h-.767l.77-1.008zm3.641-2a.355.355 0 0 0-.354.355v10.291c0 .196.159.355.354.355h5.291a.355.355 0 0 0 .355-.355v-10.29a.355.355 0 0 0-.355-.355H19.5v1h-3v-1zm-1.354.355c0-.748.606-1.354 1.354-1.354h5.291c.748 0 1.355.606 1.355 1.354v10.291c0 .748-.607 1.355-1.355 1.355h-5.29a1.355 1.355 0 0 1-1.355-1.355v-10.29Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRailwaySwitchAppSmall;
