import type { SVGProps } from "react";
const SvgBatteryLevelEmptySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 4h4v1h2v15H8V5h2V4Zm1 1v1H9v13h6V6h-2V5h-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBatteryLevelEmptySmall;
