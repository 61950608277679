import type { SVGProps } from "react";
const SvgMarketShoppingBagLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 8.992h-.25l-.15.2-3 4-.6.8H4v1.5a2.5 2.5 0 0 0 2.494 2.496c.174 0 .343-.017.506-.05v13.07H5v8h19v-8h-2V17.942a2.5 2.5 0 0 0 3-2.45v-1.5h2.001l-.6-.8-3-4-.15-.2H6.5Zm-1.5 5h19v1.5a1.5 1.5 0 0 1-3 0l-1-.001a1.5 1.5 0 0 1-3 0h-1a1.5 1.5 0 0 1-3 0h-1a1.5 1.5 0 0 1-3 0H8a1.5 1.5 0 0 1-3 0v-1.499Zm20-1h.001l-2.25-3H6.75l-2.25 3H25Zm-4.5 3.998c.142.19.31.359.5.501v13.5H8V17.489a2.51 2.51 0 0 0 .499-.499 2.496 2.496 0 0 0 2.002 1.003c.818 0 1.545-.394 2.001-1.003a2.497 2.497 0 0 0 3.999-.002 2.497 2.497 0 0 0 3.998.002Zm15-1.998a2.5 2.5 0 0 0-2.5 2.5v3.5h-4.971l-.028.47-1 17-.031.53h17.06l-.03-.53-1-17-.029-.47h-4.974l.003-3.5a2.5 2.5 0 0 0-2.5-2.5Zm1.496 7-.002 3h1l.002-3h4.032l.942 16H28.03l.941-16H33v3h1v-3h2.996Zm.001-1 .003-3.5a1.5 1.5 0 0 0-3 0v3.5h2.997ZM6 32.008v6h17v-6H6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMarketShoppingBagLarge;
