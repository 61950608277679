import type { SVGProps } from "react";
const SvgLockClosedSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4a2 2 0 0 0-2 2v3h4V6a2 2 0 0 0-2-2Zm3 5V6a3 3 0 0 0-6 0v3H6v11h12V9h-3Zm-2.5 4v4h-1v-4h1ZM7 19v-9h10v9H7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLockClosedSmall;
