import type { SVGProps } from "react";
const SvgEv38Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-38-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.674c.026-.953.262-1.751.706-2.397.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.145 1.084-.436 1.507-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.786 0 1.147-.423 2.086-1.269 2.816-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.57-.195-1.023-.587-1.356-.323-.275-.82-.413-1.492-.413-.19 0-.378.01-.563.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.047-.291-.27-.674-.405-1.15-.405-.546 0-.966.17-1.262.508-.296.338-.455.833-.476 1.484h-2.198Zm10.571 1.697c-.54-.185-.955-.489-1.246-.912-.285-.423-.428-.936-.428-1.54 0-1.004.412-1.806 1.237-2.403.73-.53 1.598-.794 2.603-.794 1.143 0 2.076.315 2.8.944.689.593 1.032 1.325 1.032 2.198 0 .609-.145 1.13-.436 1.563-.29.434-.703.749-1.238.944.418.122.76.278 1.024.469.735.534 1.103 1.314 1.103 2.34 0 1.18-.453 2.119-1.357 2.817-.762.587-1.74.88-2.936.88-1.296 0-2.327-.322-3.094-.967-.8-.672-1.199-1.553-1.199-2.642 0-1.021.355-1.812 1.064-2.373.322-.248.68-.423 1.07-.524Zm2.134-3.848c-.508 0-.918.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.318.286.741.428 1.27.428s.947-.142 1.254-.428c.306-.286.46-.674.46-1.166 0-.482-.156-.857-.468-1.127-.307-.27-.736-.405-1.286-.405Zm-.016 4.721c-.57 0-1.039.178-1.404.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.849.532 1.436.532.667 0 1.167-.196 1.5-.587.322-.376.484-.81.484-1.301 0-.561-.185-1.021-.556-1.381-.354-.35-.843-.524-1.468-.524Z"
    />
  </svg>
);
export default SvgEv38Negative;
