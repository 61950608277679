import type { SVGProps } from "react";
const SvgArrowCircleTrainTracksSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12h1a9 9 0 1 1 9 9c-3.54 0-6.737-2.044-8.227-5H6v-1H2v4h1v-2.319C4.708 19.85 8.177 22 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2ZM9.011 5h1v1.995h4V5h1v1.995H16v1h-.989v2H16v1h-.989v2H16v1h-.989v2H16v1h-.989V19h-1v-2.005h-4V19h-1v-2.005H8v-1h1.011v-2H8v-1h1.011v-2H8v-1h1.011v-2H8v-1h1.011V5Zm1 10.995h4v-2h-4v2Zm4-5v2h-4v-2h4Zm-4-1h4v-2h-4v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleTrainTracksSmall;
