import type { SVGProps } from "react";
const SvgSaTs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.703 3.133c1.066 0 2.175.291 3.031.698l.69-2.23C21.234 1.155 19.916 1 18.64 1c-2.8 0-5.434 1.24-5.434 4.092 0 1.978 1.497 2.755 2.848 3.456 1.113.577 2.126 1.103 2.126 2.206 0 1.454-1.38 2.114-2.78 2.114-1.254 0-2.445-.447-3.574-.932l-.753 2.308c1.317.446 2.76.756 4.16.756 3.198 0 6-1.202 6-4.518 0-2.138-1.607-3.017-2.994-3.776-1.054-.577-1.982-1.084-1.982-2.022 0-1.163 1.401-1.551 2.446-1.551Zm-5.933-1.9H1.502L1 3.365h4.118L2.464 14.768h3.052L8.17 3.365h4.118l.482-2.132Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaTs;
