import type { SVGProps } from "react";
const SvgBatteryLevelHighMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.25 6.25h5.5v1.5h3v22h-11.5v-22h3v-1.5Zm1 2v.5h-3v20h9.5v-20h-3v-1.5h-3.5v1ZM21 26.75h-6v-1h6v1Zm-6-3h6v-1h-6v1Zm6-3h-6v-1h6v1Zm-6-3h6v-1h-6v1Zm6-3h-6v-1h6v1Zm-6-3h6v-1h-6v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBatteryLevelHighMedium;
