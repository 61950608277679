import type { SVGProps } from "react";
const SvgCloudStrongRainSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.25 3v3h1V3h-1Zm-.486 8.691a4.6 4.6 0 0 0-1.796-1.852c.708-.678 1.68-1.089 2.782-1.089 2.26 0 4 1.74 4 4 0 1.709-.994 3.114-2.446 3.703a3.137 3.137 0 0 0-.26-.39 2.862 2.862 0 0 0-1.864-1.079c.246-1.253.065-2.374-.416-3.293Zm2.986 6.892a3.966 3.966 0 0 0-.087-1.197c1.836-.736 3.087-2.51 3.087-4.636 0-2.813-2.188-5-5-5-1.51 0-2.85.632-3.76 1.663-1.956-.606-4.301.021-5.582 2.303-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.586-1.758 1.167-.504.705-.753 1.624-.713 2.53.04.91.371 1.84 1.073 2.548.708.713 1.75 1.155 3.124 1.155h10.5c1.331 0 2.367-.445 3.088-1.147a3.94 3.94 0 0 0 1.162-2.52Zm-10.692-5.93c1.01-2.29 3.124-2.838 4.81-2.226.848.308 1.58.908 2.01 1.728.426.814.572 1.879.193 3.153l-.199.67.698-.028c.778-.031 1.33.273 1.687.73.368.47.55 1.133.496 1.825a2.942 2.942 0 0 1-.862 1.882c-.517.503-1.295.863-2.39.863H12c-1.151 0-1.923-.363-2.415-.86-.5-.502-.754-1.183-.784-1.886-.031-.707.167-1.4.527-1.905.355-.496.856-.799 1.471-.799h.564l-.067-.559c-.296-2.473 2.359-3.737 3.987-2.404l.508.417.266-.602Zm-5.255 14.123 1.5-3 .894.448-1.5 3-.894-.448Zm9 0 1.5-3 .894.448-1.5 3-.894-.448Zm-4.5-3-3 6 .894.448 3-6-.894-.448Zm.074 6 3-6 .895.448-3 6-.894-.448Zm4.182-21.52-2.162-2.079.693-.72 2.162 2.079-.693.72Zm9.777 9.893 2.121 2.121.707-.707-2.12-2.12-.708.706ZM31.5 12.25h3v1h-3v-1Zm-.21-6.77L29.169 7.6l.707.708 2.121-2.121-.707-.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudStrongRainSunMedium;
