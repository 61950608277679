import type { SVGProps } from "react";
const SvgStreetLocationPinLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.318 3.328A4.5 4.5 0 0 1 28 6.51h-.5l.5.006a4.2 4.2 0 0 1-.568 2.055l-.008.015-.01.014-3.5 5.18-.418.618-.412-.622-3.44-5.18-.008-.01A4.18 4.18 0 0 1 19 6.53v-.02a4.5 4.5 0 0 1 1.318-3.182ZM27 6.507a3.5 3.5 0 0 0-7-.007 3.18 3.18 0 0 0 .48 1.549l3.024 4.552 3.072-4.547A3.2 3.2 0 0 0 27 6.507ZM2 17h17.771L9.034 44.82l.932.36 11-28.5.263-.68H2v1Zm24.5-1h-.729l.262.68 11 28.5.934-.36L27.229 17H46v-1H26.5ZM23 45v-5h1v5h-1Zm0-13v5h1v-5h-1Zm0-3v-5h1v5h-1Zm0-13v5h1v-5h-1Zm-1-9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM23.5 4a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStreetLocationPinLarge;
