import type { SVGProps } from "react";
const SvgArrowLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.411 18.384 5.985-5.988.708.707-5.132 5.134H28.5v1H10.968l5.136 5.16-.708.705L9.41 19.09l-.352-.353.352-.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowLeftMedium;
