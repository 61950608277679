import type { SVGProps } from "react";
const SvgShipSteeringWheelMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.75 6.261V3h1v3.261a10.958 10.958 0 0 1 6.917 2.866l2.306-2.306.707.707-2.306 2.306a10.957 10.957 0 0 1 2.865 6.916H31.5v1h-3.261a10.957 10.957 0 0 1-2.865 6.917l2.306 2.306-.707.707-2.306-2.306a10.957 10.957 0 0 1-6.917 2.865V31.5h-1v-3.261a10.957 10.957 0 0 1-6.917-2.865L7.527 27.68l-.707-.707 2.306-2.306a10.958 10.958 0 0 1-2.865-6.917H3v-1h3.261a10.957 10.957 0 0 1 2.865-6.916L6.82 7.528l.707-.707 2.306 2.306A10.958 10.958 0 0 1 16.75 6.26Zm.5.989c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10Zm-8 10a8 8 0 1 1 16 0c0 4.42-3.58 8-8 8s-8-3.58-8-8Zm1.018-.5h3.518a3.483 3.483 0 0 1 .66-1.595l-2.486-2.489a6.97 6.97 0 0 0-1.692 4.084Zm2.399-4.791 2.487 2.488a3.481 3.481 0 0 1 1.596-.661v-3.518a6.97 6.97 0 0 0-4.083 1.691Zm5.083-1.691v3.518a3.483 3.483 0 0 1 1.596.662l2.488-2.489a6.97 6.97 0 0 0-4.084-1.691Zm4.79 2.398-2.487 2.489c.343.458.577 1.002.662 1.595h3.517a6.97 6.97 0 0 0-1.691-4.084Zm1.692 5.084h-3.517a3.482 3.482 0 0 1-.662 1.596l2.488 2.488a6.97 6.97 0 0 0 1.691-4.084Zm-2.398 4.79-2.488-2.487a3.482 3.482 0 0 1-1.596.662v3.517a6.97 6.97 0 0 0 4.084-1.691Zm-5.084 1.692v-3.517a3.482 3.482 0 0 1-1.596-.662l-2.488 2.488a6.97 6.97 0 0 0 4.084 1.691Zm-4.791-2.398 2.488-2.488a3.483 3.483 0 0 1-.661-1.596h-3.518a6.97 6.97 0 0 0 1.69 4.084Zm5.291-7.084a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShipSteeringWheelMedium;
