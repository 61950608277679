import type { SVGProps } from "react";
const SvgIr7 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-7_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-7_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.293-8.132v1.962a45.8 45.8 0 0 0-.562.64c-.432.496-.862 1.12-1.29 1.875a12.805 12.805 0 0 0-.996 2.16c-.5 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.167-1.836.451-2.896.29-1.06.689-2.12 1.195-3.18.722-1.497 1.455-2.612 2.199-3.345h-5.458V4.234h7.76Z"
      />
    </g>
    <defs>
      <clipPath id="ir-7_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr7;
