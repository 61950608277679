import type { SVGProps } from "react";
const SvgSaNf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.562 11.63 12.842 1h2.641l-3.021 14H8.86L5.941 4.27h-.04L3.68 15H1L4 1h3.54l2.982 10.63h.04ZM15.559 15 18.5 1h7.702l-.48 2.206h-4.88l-.74 3.449h4.66l-.44 2.208H19.64L18.36 15h-2.8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaNf;
