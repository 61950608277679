import type { SVGProps } from "react";
const SvgDocumentImageSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 4h6.707l.147.147 5 5 .146.146V20H6V4Zm1 1v14h10v-9h-5V5H7Zm6 .707L16.293 9H13V5.707Zm-2.026 3.635-.51-1.533-.445 1.553-2 6.995-.182.638h8.303l-.155-.621-1-4.005-.274-1.099-.627.943-1.434 2.155-1.676-5.026Zm-1.811 6.653 1.374-4.805 1.489 4.465.113.34H9.163Zm5.697 0h-2.09l.146-.221 1.373-2.064.57 2.285Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentImageSmall;
