import type { SVGProps } from "react";
const SvgMedicalFacilityMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18.879 5.211-.354-.353-.353.353-12 12 .707.708 1.396-1.397v14.293h6.985v-7.5h6.5v7.5h7.015V16.522l1.397 1.397.707-.707-12-12Zm8.896 10.311-9.25-9.25-9.25 9.25v14.293h4.985v-7.5h8.5v7.5h5.015V15.522Zm-10.5-3.707h2.5v3h3v2.5h-3v3h-2.5v-3h-3v-2.5h3v-3Zm1 1v3h-3v.5h3v3h.5v-3h3v-.5h-3v-3h-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMedicalFacilityMedium;
