import type { SVGProps } from "react";
const SvgSn15 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="sn-15_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#sn-15_svg__a)">
      <path
        fill="currentColor"
        d="M1.792 0h55.416C58.197 0 59 .806 59 1.8v16.4c0 .994-.801 1.8-1.792 1.8H1.792A1.797 1.797 0 0 1 0 18.2V1.8C0 .806.803 0 1.792 0Z"
      />
      <path
        fill="#FFDE15"
        d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429Zm7.404 0v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.981-.49.427-.328.715-.879.862-1.654h1.812Zm10.821 0V6.29h-4.66l-.498 2.602c.56-.59 1.274-.886 2.144-.886.854 0 1.598.23 2.23.688.94.691 1.409 1.74 1.409 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.207-.328-2.95-.982-.744-.659-1.137-1.547-1.18-2.665h2.27c.038.406.17.751.396 1.036.354.448.844.672 1.472.672.606 0 1.086-.218 1.44-.656.342-.427.513-.947.513-1.558 0-.665-.179-1.195-.538-1.59-.358-.401-.835-.602-1.431-.602-.728 0-1.295.327-1.7.981l-2.065-.031 1.1-6.447h6.35Z"
      />
    </g>
    <defs>
      <clipPath id="sn-15_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSn15;
