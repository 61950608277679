import type { SVGProps } from "react";
const SvgHandCursorSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 5.5c0-.826.674-1.5 1.5-1.5s1.5.674 1.5 1.5v3.586a1.504 1.504 0 0 1 1.947 1.02 1.504 1.504 0 0 1 2 1A1.503 1.503 0 0 1 18 12.5V16c0 1.522-.233 2.695-.47 3.495-.12.4-.24.706-.332.916a4.748 4.748 0 0 1-.149.31l-.01.019-.004.006-.001.002v.001L16.6 20.5l.433.25-.144.25H9.215l-.145-.245.43-.255-.43.254h-.001l-.002-.004-.007-.012-.029-.048-.108-.184-.39-.659-1.202-2.035a820.284 820.284 0 0 1-1.975-3.353c-.173-.302-.313-.641-.328-.994a1.338 1.338 0 0 1 .408-1.029 1.508 1.508 0 0 1 2.128 0L9 13.873V5.5Zm6 7v.5h1v-.506a.503.503 0 0 1 .5-.494c.274 0 .5.226.5.5V16c0 1.418-.217 2.495-.43 3.21a6.886 6.886 0 0 1-.283.79H9.785l-.001-.003-.39-.658-1.202-2.036a838.244 838.244 0 0 1-1.968-3.342c-.137-.238-.192-.414-.197-.538a.341.341 0 0 1 .117-.28.508.508 0 0 1 .712 0l2.29 2.29.854.854V5.5c0-.274.226-.5.5-.5s.5.226.5.5V13h1v-2.5c0-.274.226-.5.5-.5s.5.226.5.5V13h1v-1.5c0-.274.226-.5.5-.5s.5.226.5.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandCursorSmall;
