import type { SVGProps } from "react";
const SvgVegetarianSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18.364 4.426-.066-.442-.446.016c-3.465.128-5.976 1.037-7.772 2.381-1.796 1.344-2.839 3.094-3.42 4.837-.578 1.74-.7 3.48-.652 4.832.024.678.09 1.265.166 1.713a7.176 7.176 0 0 0 .152.7 46.17 46.17 0 0 0-1.776 3.32l.9.435a45.16 45.16 0 0 1 1.728-3.231c3.314-.167 5.718-1.06 7.443-2.364 1.774-1.343 2.79-3.09 3.351-4.83 1.055-3.272.52-6.573.407-7.274a9.488 9.488 0 0 1-.015-.093ZM7.813 17.942c2.795-.239 4.786-1.043 6.206-2.117 1.577-1.193 2.49-2.751 3.002-4.34.86-2.666.58-5.375.424-6.464-3.068.18-5.236 1.017-6.764 2.161-1.602 1.198-2.541 2.762-3.07 4.352-.532 1.595-.648 3.21-.603 4.481.016.443.05.842.094 1.18a46.162 46.162 0 0 1 8.064-9.566l.67.742a45.157 45.157 0 0 0-8.023 9.571Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVegetarianSmall;
