import type { SVGProps } from "react";
const SvgPenLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m36.73 4.203.354.353 6.36 6.36.353.354-.353.354-3.182 3.18.005.006-.353.354-26.17 26.16-.098.098-.134.033-8.48 2.12-.809.202.202-.808 2.12-8.48.034-.135.098-.097 26.16-26.17.353-.354.005.005 3.181-3.182.354-.353Zm5.653 7.067-2.828 2.828-5.653-5.653 2.828-2.828 5.653 5.653ZM33.19 9.147 7.737 34.61l5.653 5.653L38.853 14.81 33.19 9.147ZM6.172 40.105l1.13-4.517 5.11 5.111-4.516 1.13-1.724-1.724Zm-.283 1.131-.291 1.167 1.166-.292-.875-.875Zm7.505-2.383 24.04-24.05-.707-.707-24.04 24.05.707.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPenLarge;
