import type { SVGProps } from "react";
const SvgHandUserSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3c-1.31 0-2.5 1.268-2.5 3s1.19 3 2.5 3 2.5-1.268 2.5-3-1.189-3-2.5-3ZM8.5 6c0-2.134 1.498-4 3.5-4 2.003 0 3.5 1.866 3.5 4 0 .927-.282 1.803-.766 2.498C17.505 9.82 19 12.819 19 16h-1c0-3.01-1.46-5.681-3.983-6.727A3.179 3.179 0 0 1 12 10a3.179 3.179 0 0 1-2.018-.727c-1.687.703-2.891 2.126-3.51 3.889l-.944-.332c.66-1.875 1.929-3.463 3.738-4.332A4.387 4.387 0 0 1 8.5 6Zm-4.075 7.319 3.578 2.5c.038-.425.201-.848.502-1.184.35-.39.862-.635 1.495-.635h2.237v.004c1.607.071 3.121.956 4.28 2.07l.002.002 5.326 5.062.907.862h-9.208l-.146-.145-.862-.855H8.611l-.141-.125-5.919-5.213a1.5 1.5 0 0 1 1.874-2.343ZM12.105 15H10c-.36 0-.597.131-.75.302a1.07 1.07 0 0 0-.256.702c-.002.268.086.518.244.695.149.168.387.301.762.301h2v1h-2c-.617 0-1.12-.224-1.47-.593L3.84 14.13l-.014-.01-.013-.01a.5.5 0 1 0-.626.78l.009.007.009.008L8.989 20h3.959l.146.145.862.855h6.292l-4.42-4.201-.003-.002c-1.084-1.043-2.415-1.77-3.72-1.797Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandUserSmall;
