import type { SVGProps } from "react";
const SvgWheelchairInaccessibleLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.389 17.096-1.298-1.298-2.076 2.204a.75.75 0 0 0-.096.9l.012.02.01.021 1.502 3.126c.386-.171.78-.322 1.18-.453l-.575-2.067a1.22 1.22 0 0 1 .254-1.328l1.087-1.125ZM2.646 2.354 15.384 15.09l-2.098 2.226a1.751 1.751 0 0 0-.237 2.079l1.496 3.114a12.49 12.49 0 0 0-5.593 6.206A12.5 12.5 0 1 0 33 33.5c0-.274-.009-.547-.027-.82l2.066 2.066L36 39.55V43h7.293l2.354 2.354.707-.707-43-43-.708.707Zm14.94 18.99c.138-.033.276-.063.414-.091v-3.351l-.98 1.015-.004.004a.219.219 0 0 0-.043.248l.017.037.011.04.585 2.098ZM19 21.09v-2.383l2.32 2.32a12.49 12.49 0 0 0-2.32.064ZM42.293 42l-2-2H39.23l-.088-.391-.218-.978-2.61-2.61.676 3.381.01.048V42h5.293ZM20.5 22c.656 0 1.311.055 1.958.165l1.38 1.38a10.506 10.506 0 0 0-2.285-.492L20.5 22Zm1.053 1.053L20.5 22A11.5 11.5 0 1 0 32 33.5l-1.053-1.053a10.496 10.496 0 0 1-3.567 8.985 10.5 10.5 0 1 1-5.827-18.38Zm8.17 8.17a9.5 9.5 0 0 1 .137 3.905l-8.237-1.884 5.632-4.49 2.468 2.47Zm0 0-2.468-2.468.975-.778a9.5 9.5 0 0 1 1.493 3.246Zm-3.18-3.18.998-.795-1.37-1.369a9.499 9.499 0 0 0-3.394-1.602l3.766 3.766ZM21 32.462l5.543-4.419-3.766-3.766A9.495 9.495 0 0 0 21 24.013v8.449Zm.401 1.757 3.666 7.611a9.5 9.5 0 0 0 4.57-5.727L21.4 34.219Zm-.9.433 3.665 7.612a9.498 9.498 0 0 1-7.332 0l3.666-7.612Zm-.902-.433-8.236 1.884a9.498 9.498 0 0 0 4.57 5.727l3.666-7.61Zm-8.593-.382c.015.435.06.866.134 1.29l8.236-1.883-6.608-5.264a9.5 9.5 0 0 0-1.762 5.857Zm2.386-6.64L20 32.464v-8.45a9.499 9.499 0 0 0-6.608 3.185Zm17.555 5.25L32 33.5c0-.656-.055-1.311-.165-1.958l-1.38-1.38a10.5 10.5 0 0 1 .492 2.285ZM20 6a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm12.871 24.317a1.5 1.5 0 0 0-.959-.317H29.5v-1h5.394a2.5 2.5 0 0 1 2.494 1.95l1.67 7.46-.976.22-1.67-7.46a1.5 1.5 0 0 0-.54-.853ZM25.341 12h-6.45a1.412 1.412 0 0 0-1.021.434l-.005.005-.7.75.73.682.698-.747a.411.411 0 0 1 .296-.124h6.451A1.66 1.66 0 0 1 27 14.66v8.84h1v-8.84A2.66 2.66 0 0 0 25.34 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairInaccessibleLarge;
