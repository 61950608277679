import type { SVGProps } from "react";
const SvgRailwaySwitchAppMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#railway-switch-app-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.498 7.515v3h-1.5v1.5h1.5v3h-1.5v1.5h1.5v2.999h-1.5v1.5h1.5v3h-1.5v1.5h1.5v3h1.5v-2.837l14.127-21.5-1.254-.824-1.749 2.662h-.625v.951l-2.331 3.549h-.668v-3h1.5v-1.5h-1.5v-3h-1.5v3h-4.5v1.5h4.5v3h-4.5v1.5h4.5v1.799l-.784 1.193H7.498v1.5h2.73l-1.97 3h-.76v1.156l-1.5 2.283V3.015h-1.5v3h-1.5v1.5zm17.287 4.5h-3.787v-1.5h4.84l2.109-3h-3.949v-1.5H26l1.884-2.682 1.228.863-1.278 1.819h.664v1.5H26.78l-2.108 3h.826v1.5zm-4.205 5.983-1.064 1.516h-4.518v1.5h3.463l-2.107 3H8.998v1.5h3v3h1.5v-2.096l.635-.904h2.365v-1.5h-1.311l2.108-3h2.203v-1.5h-1.15L19.503 18l-1.922-.003Zm5.462-3.248a.782.782 0 0 0-.782.782v15.436c0 .432.35.782.782.782h7.936c.432 0 .782-.35.782-.782V15.532a.782.782 0 0 0-.782-.782zm-1.782.782c0-.984.798-1.782 1.782-1.782h7.936c.984 0 1.782.798 1.782 1.782v15.436c0 .984-.798 1.782-1.782 1.782h-7.936a1.782 1.782 0 0 1-1.782-1.782zm3.49.718h4.5v-1h-4.5z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="railway-switch-app-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRailwaySwitchAppMedium;
