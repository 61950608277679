import type { SVGProps } from "react";
const SvgEiffelTowerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#eiffel-tower-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.25 3.25h-.45l-.048.446a109.164 109.164 0 0 1-4.36 20.903 48.338 48.338 0 0 1-3.087 7.421l-.378.73H15.5v-.5a2.5 2.5 0 0 1 5 0v.5h6.58l-.388-.734c-2.815-5.32-4.68-12.386-5.84-18.153a127.06 127.06 0 0 1-1.237-7.244 103.735 103.735 0 0 1-.362-2.87l-.004-.038-.001-.009V3.7l-.498.05.497-.05-.045-.45H17.25Zm.448 1h.604l.04.342a103.48 103.48 0 0 0 .427 3.158h-1.52c.168-1.164.318-2.33.449-3.5Zm-.6 4.5a110.118 110.118 0 0 1-1.537 8h4.885c-.208-.921-.399-1.821-.574-2.69a127.735 127.735 0 0 1-.95-5.31H17.1Zm3.58 9h-5.353a110.155 110.155 0 0 1-1.777 6.5h8.912a88.679 88.679 0 0 1-1.783-6.5Zm2.11 7.5h-9.555a49.342 49.342 0 0 1-2.67 6.5h3.973a3.5 3.5 0 0 1 6.928 0h3.967c-1.004-2.01-1.881-4.224-2.644-6.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="eiffel-tower-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEiffelTowerMedium;
