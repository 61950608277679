import type { SVGProps } from "react";
const SvgTwoHousesSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.354 2.646 15 2.292l-.354.354L13 4.293l-.646-.647L12 3.293l-.354.353-8 8 .708.707.646-.646V20h4.99v-5h4v5H19v-1h3v-8.293l.646.646.708-.707-8-8ZM21 9.706l-6-6L13.707 5l6.647 6.646-.707.707-.647-.646V18h2V9.707Zm-3 1-6-6-6 6V19h2.99v-5h6v5H18v-8.293Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoHousesSmall;
