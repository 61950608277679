import type { SVGProps } from "react";
const SvgUtilizationNone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={16}
    fill="none"
    className="utilization-none_svg__color-immutable"
    {...props}
  >
    <path
      fill="#BDBDBD"
      fillRule="evenodd"
      d="M2.5 3C3.327 3 4 2.327 4 1.5S3.327 0 2.5 0 1 .673 1 1.5 1.673 3 2.5 3Zm7.004 0c.827 0 1.5-.673 1.5-1.5S10.33 0 9.504 0s-1.5.673-1.5 1.5.673 1.5 1.5 1.5Zm7.004 0c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5-1.5.673-1.5 1.5.673 1.5 1.5 1.5ZM0 10h1v6h3v-6h1V4H0v6Zm7.004 0h1v6h3v-6h1V4h-5v6ZM14 4v6h1v6h3v-6h1V4h-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUtilizationNone;
