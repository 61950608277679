import type { SVGProps } from "react";
const SvgSwisspassLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.07 13.5a2.5 2.5 0 0 1 2.5-2.5h37a2.5 2.5 0 0 1 2.5 2.5v22a2.5 2.5 0 0 1-2.5 2.5h-37a2.5 2.5 0 0 1-2.5-2.5v-22Zm2.5-3.5a3.5 3.5 0 0 0-3.5 3.5v22a3.5 3.5 0 0 0 3.5 3.5h37a3.5 3.5 0 0 0 3.5-3.5v-22a3.5 3.5 0 0 0-3.5-3.5h-37Zm.5 5.5a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 1.5 1.5v18a1.5 1.5 0 0 1-1.013 1.42c-.177-3.978-2.168-7.937-5.624-9.597A5.335 5.335 0 0 0 20.57 22c0-2.704-1.96-5-4.5-5-2.539 0-4.5 2.296-4.5 5 0 1.26.426 2.431 1.137 3.323-3.464 1.66-5.447 5.635-5.624 9.596A1.5 1.5 0 0 1 6.07 33.5v-18Zm12.606 10.58c3.235 1.36 5.225 5.053 5.384 8.92H8.08c.16-3.852 2.142-7.56 5.384-8.92a4.17 4.17 0 0 0 2.606.92 4.17 4.17 0 0 0 2.606-.92ZM7.57 13a2.5 2.5 0 0 0-2.5 2.5v18a2.5 2.5 0 0 0 2.5 2.5h17a2.5 2.5 0 0 0 2.5-2.5v-18a2.5 2.5 0 0 0-2.5-2.5h-17Zm29.5 0h3v2h2v3h-2v2h-3v-2h-2v-3h2v-2Zm1 1v2h-2v1h2v2h1v-2h2v-1h-2v-2h-1Zm-9 10h11v1h-11v-1Zm0 3h10v1h-10v-1Zm8 3h-8v1h8v-1Zm-21-12c-1.88 0-3.5 1.734-3.5 4s1.62 4 3.5 4 3.5-1.734 3.5-4-1.62-4-3.5-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSwisspassLarge;
