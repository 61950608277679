import type { SVGProps } from "react";
const SvgDocumentImageMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 6.25h9.707l.147.147 7.5 7.5.146.146v15.708H9.25V6.25Zm1 1v21.5h15.5v-14h-7.5v-7.5h-8Zm9 .708 5.793 5.793H19.25V7.958Zm-3.026 6.134-.51-1.533-.445 1.553-3 10.493-.182.637H23.89l-.155-.62-1.5-6.008-.274-1.099-.627.943-2.433 3.658-2.677-8.024Zm-2.811 10.15 2.374-8.302 2.489 7.463.28.84h-5.143Zm9.197 0h-3.923l.48-.72 2.372-3.567 1.07 4.287Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentImageMedium;
