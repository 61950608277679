import type { SVGProps } from "react";
const SvgArrowCircleEyeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12h1a9 9 0 1 1 9 9c-3.54 0-6.737-2.044-8.227-5H6v-1H2v4h1v-2.319C4.708 19.85 8.177 22 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2Zm.022 7c1.18-.053 3.94.44 5.711 3.012-.951 1.455-3.196 3.04-5.723 2.988H12a6.982 6.982 0 0 1-5.736-3A6.982 6.982 0 0 1 12 9h.022Zm6.725 2.733C16.744 8.553 13.45 7.94 11.99 8a7.988 7.988 0 0 0-6.748 3.733L5.07 12l.17.267A7.987 7.987 0 0 0 11.995 16c3.084.061 5.764-1.952 6.766-3.757l.144-.259-.158-.25ZM11 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleEyeSmall;
