import type { SVGProps } from "react";
const SvgQuestionAnswerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 5H3v10h3v2.957l.854-.853L8.957 15H9v3h6.043l2.103 2.104.854.853V18h3V8h-5V5H3.5ZM16 9v2h3v1h-3v1h2v1h-2v1h-6v2h5.457l.147.146L17 18.543V17h3V9h-4ZM4 14V6h11v8H8.543l-.147.146L7 15.543V14H4Zm4.828-1.372h.844v-.812h-.844v.812ZM7.782 7.55c-.172.266-.267.641-.285 1.125h.671c.055-.854.43-1.281 1.125-1.281.336 0 .59.108.762.324.143.178.215.396.215.657 0 .14-.025.278-.074.414-.06.158-.186.337-.379.535a9.663 9.663 0 0 0-.58.629 1.229 1.229 0 0 0-.2.379c-.066.192-.1.488-.107.886h.664c0-.224.013-.406.04-.547a.998.998 0 0 1 .122-.353 2.05 2.05 0 0 1 .311-.358c.336-.328.548-.553.637-.675A1.58 1.58 0 0 0 11 8.324c0-.451-.146-.814-.437-1.09-.313-.299-.733-.449-1.262-.449-.328 0-.623.066-.886.199a1.596 1.596 0 0 0-.633.566Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQuestionAnswerSmall;
