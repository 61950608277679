import type { SVGProps } from "react";
const SvgEv26 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-26_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.302 9.537v2.063h-8.355c0-.63.1-1.23.301-1.801a4.438 4.438 0 0 1 .865-1.476 8.124 8.124 0 0 1 1-.928c.386-.302 1.15-.855 2.293-1.659.556-.386.944-.745 1.167-1.079a2.04 2.04 0 0 0 .333-1.158c0-.508-.151-.918-.452-1.23-.297-.317-.688-.476-1.175-.476-1.195 0-1.811.86-1.849 2.579h-2.23v-.254c0-1.407.395-2.497 1.183-3.27.767-.75 1.759-1.126 2.976-1.126 1.079 0 1.98.307 2.705.92.788.667 1.182 1.563 1.182 2.69 0 .984-.314 1.83-.944 2.54-.206.232-.4.42-.579.563-.18.137-.653.473-1.42 1.007-.73.508-1.254.897-1.571 1.167a6.37 6.37 0 0 0-.873.928h5.443Zm8.603-6.641H40.01c-.206-.894-.714-1.341-1.523-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.471.72-.81 1.11-1.016.398-.206.874-.31 1.43-.31 1.089 0 1.964.387 2.626 1.16.629.73.944 1.644.944 2.744 0 1.307-.423 2.357-1.27 3.15-.762.715-1.677 1.072-2.745 1.072-1.37 0-2.436-.516-3.198-1.547-.762-1.037-1.142-2.481-1.142-4.333 0-1.978.407-3.528 1.221-4.65.789-1.084 1.865-1.626 3.23-1.626 1.285 0 2.27.431 2.951 1.293.392.498.643 1.124.754 1.88ZM38.29 9.724c-.556 0-.997.233-1.325.698-.297.413-.445.905-.445 1.476 0 .566.151 1.06.453 1.484.328.47.777.706 1.348.706.556 0 1.003-.225 1.341-.674.318-.424.477-.924.477-1.5 0-.566-.143-1.05-.429-1.452-.35-.492-.823-.738-1.42-.738Z"
    />
  </svg>
);
export default SvgEv26;
