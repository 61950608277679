import type { SVGProps } from "react";
const SvgPunctualitySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3h1V2h-3v1h1v2h.5A7.5 7.5 0 1 1 4 12.5v-.493H3v.494a8.5 8.5 0 1 0 9-8.486V3Zm-.593 13.29 5-7-.814-.58-4.657 6.52-2.582-2.583-.708.707 3 3 .418.417.343-.48ZM10.042 5.127l-.966.26-.26-.967.967-.259.259.966ZM7.317 6.255l.866-.5-.5-.866-.866.5.5.866Zm-.767.588-.707.707-.707-.707.707-.707.707.707Zm-1.795 2.34.5-.866-.866-.5-.5.866.866.5Zm-.37.893-.259.966-.966-.26.26-.965.965.259Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPunctualitySmall;
