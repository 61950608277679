import type { SVGProps } from "react";
const SvgSmartphoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 3.999a1.5 1.5 0 0 0-1.5 1.5v14c0 .83.672 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5v-14A1.5 1.5 0 0 0 15.5 4h-7Zm-.5 1.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v14c0 .277-.223.5-.5.5h-7a.5.5 0 0 1-.5-.5v-14Zm2 .5h4V5h-4v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneSmall;
