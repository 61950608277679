import type { SVGProps } from "react";
const SvgPauseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.25 7.75h-.5v20.5h5.5V7.75h-5Zm.5 19.5V8.75h3.5v18.5h-3.5Zm8.502-19.5h-.5v20.5h5.5V7.75h-5Zm.5 19.5V8.75h3.5v18.5h-3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPauseMedium;
