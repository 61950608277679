import type { SVGProps } from "react";
const SvgArrowsUpDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m25.25 24.659 4.91-4.981.713.702-5.763 5.845-.356.36-.356-.36-5.78-5.84.712-.703 4.92 4.972V7.5h1v17.159ZM11.606 8.273l-.356-.36-.356.36-5.764 5.845.712.702 4.908-4.976V27h1V9.841l4.923 4.977.71-.704-5.777-5.84Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowsUpDownMedium;
