import type { SVGProps } from "react";
const SvgCityMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.75 3.75v-.61l-.598.119-7.5 1.5-.402.08V33h1v-6.25h6.5V33h1v-3.25h11V33h1v-3.25h8V33h1V12.25h-9v-6h-12v-2.5Zm12 9.5v15.5h8v-15.5h-8Zm-13-6.5v19h-6.5V5.658l6.5-1.3v2.391Zm12 22h-11V7.25h11v21.5ZM9 7.75H6v1h3v-1Zm11.75 5.75v-3h-1v3h1Zm6 3v3h-1v-3h1Zm0 6v3h-1v-3h1Zm3 3v-3h-1v3h1Zm0-9v3h-1v-3h1Zm-9 9v-3h-1v3h1Zm0-9v3h-1v-3h1Zm-3 6v3h-1v-3h1Zm0-3v-3h-1v3h1Zm0-9v3h-1v-3h1Zm-3 15v-3h-1v3h1Zm0-9v3h-1v-3h1Zm0-3v-3h-1v3h1ZM6 12.25h3v1H6v-1Zm0 4.5h3v1H6v-1Zm3 4.5H6v1h3v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCityMedium;
