import type { SVGProps } from "react";
const SvgTicketParkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4h20v5h-.5c-1.424 0-2.5 1.076-2.5 2.5s1.076 2.5 2.5 2.5h.5v5H2V4Zm1 1v2h5.278l.148.224 1.843 2.784H12A2 2 0 0 1 14 12v3h-2.268a1.999 1.999 0 0 1-3.465 0H4.733A2 2 0 0 1 3 16v2h13v-.465h1V18h4v-3.034c-1.727-.232-3-1.66-3-3.466 0-1.806 1.273-3.234 3-3.467V5h-4v.464h-1V5H3Zm0 7V8h4.74l1.843 2.784.148.224H12a1 1 0 0 1 1 1V14h-1a2 2 0 1 0-4 0H5a2 2 0 0 0-2-2Zm1 2a1 1 0 0 1-1 1v-2a1 1 0 0 1 1 1Zm5 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM6.735 9.995H6v-1h1.273l.148.224.996 1.505-.834.552-.848-1.28ZM16 6.393v.928h1v-.928h-1Zm0 1.857v.928h1V8.25h-1Zm0 1.857v.929h1v-.93h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketParkingSmall;
