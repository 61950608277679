import type { SVGProps } from "react";
const SvgDoorArrowRightLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#door-arrow-right-left-medium_svg__a)">
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        d="m12 18-3.75-3.75m0 0L12 10.5m-3.75 3.75h15m-7.5 2.25v12.75h10.5V6.75h-10.5V12M31.5 29.25h-27m18.75-15L19.5 10.5m3.75 3.75L19.5 18m4.5.75h-1.5"
      />
    </g>
    <defs>
      <clipPath id="door-arrow-right-left-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDoorArrowRightLeftMedium;
