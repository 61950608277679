import type { SVGProps } from "react";
const SvgCloudSnowLightningMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.18 14.984c.79.118 1.419.516 1.855 1.082.538.698.759 1.62.682 2.52a3.995 3.995 0 0 1-1.164 2.515c-.716.701-1.74 1.149-3.053 1.149v-1c1.072 0 1.839-.36 2.353-.863.52-.51.81-1.196.868-1.886.059-.694-.117-1.356-.479-1.825-.35-.454-.895-.758-1.672-.727l-.698.028.199-.67c.379-1.274.233-2.338-.193-3.153a3.664 3.664 0 0 0-2.01-1.728c-1.686-.612-3.8-.064-4.81 2.226l-.266.602-.509-.418c-1.626-1.333-4.282-.069-3.986 2.404l.066.56H10.8c-.616 0-1.116.302-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.887.493.496 1.265.86 2.415.86v1c-1.375 0-2.417-.443-3.125-1.156-.701-.707-1.033-1.638-1.073-2.548-.039-.905.21-1.825.714-2.53.416-.581 1.014-1.023 1.758-1.167.007-2.802 2.943-4.359 5.134-3.134 1.329-2.367 3.803-2.954 5.8-2.23a4.67 4.67 0 0 1 2.555 2.205c.482.92.663 2.04.417 3.294Zm-7.137 4.766h3.664l-.853.854-2.897 2.896h3.451l-1.088.887-4.995 4.073-3.17 2.584 2.454-3.271L15.5 25.25h-3.957l.853-.854 4.5-4.5.147-.146Zm.27 4.75-.413.55-.038.051.737-.601h-.287Zm-2.52-.25h-.836l3.5-3.5h.836l-2.897 2.896-.603.604Zm9.707.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-3 4a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0ZM8 26.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0ZM8.25 25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowLightningMedium;
