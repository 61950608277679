import type { SVGProps } from "react";
const SvgWifiSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.856 8.368C4.406 5.684 8.002 4 12 4c3.997 0 7.593 1.684 10.142 4.368l-.725.689C19.047 6.562 15.71 5 12 5S4.952 6.562 2.58 9.057l-.725-.69ZM12 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-11a10.96 10.96 0 0 0-7.97 3.432l.726.688A9.96 9.96 0 0 1 12 8a9.96 9.96 0 0 1 7.244 3.12l.726-.688A10.96 10.96 0 0 0 12 7Zm-5.791 5.5a7.97 7.97 0 0 1 5.796-2.496 7.97 7.97 0 0 1 5.792 2.49l-.725.69a6.969 6.969 0 0 0-5.067-2.18 6.97 6.97 0 0 0-5.071 2.185l-.725-.689Zm5.79.5c-1.43 0-2.71.607-3.618 1.563l.725.69A3.977 3.977 0 0 1 12 14c1.139 0 2.163.483 2.893 1.252l.725-.688A4.977 4.977 0 0 0 12 13Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWifiSmall;
