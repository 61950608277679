import type { SVGProps } from "react";
const SvgSmartwatchMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.25 3.25h11.5v6h1a2 2 0 0 1 2 2v5.5h1.5v2.5h-1.5v5.5a2 2 0 0 1-2 2h-1v6h-11.5v-6h-1a2 2 0 0 1-2-2v-13.5a2 2 0 0 1 2-2h1v-6Zm14.5 15v-.5h.5v.5h-.5Zm-13.5 8.5v5h9.5v-5h-9.5Zm9.5-22.5v5h-9.5v-5h9.5Zm-11.5 6a1 1 0 0 0-1 1v13.5a1 1 0 0 0 1 1h13.5a1 1 0 0 0 1-1v-13.5a1 1 0 0 0-1-1h-13.5Zm6.5 3.25v4.747l4.807-.027.006 1-5.31.03-.503.003V13.5h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSmartwatchMedium;
