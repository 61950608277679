import type { SVGProps } from "react";
const SvgMagnifyingGlassMinusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.25 5.75c-5.524 0-10 4.478-10 10 0 5.524 4.476 10 10 10s10-4.476 10-10c0-5.522-4.476-10-10-10Zm-11 10c0-6.074 4.924-11 11-11s11 4.926 11 11c0 2.803-1.048 5.36-2.773 7.303l9.35 8.068-.654.758-9.395-8.108a10.962 10.962 0 0 1-7.528 2.979c-6.076 0-11-4.924-11-11Zm17.75.5H7.5v-1H21v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassMinusMedium;
