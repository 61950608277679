import type { SVGProps } from "react";
const SvgSaRu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.78 2.918c-1.06 0-2.12.36-3.08.8l-.22-2.22c1.28-.38 2.62-.66 3.96-.66 2.3 0 4.56 1.02 4.56 3.58 0 1.39-.897 2.366-1.771 3.317-.887.965-1.75 1.904-1.629 3.223h-2.46c0-1.9.74-2.94 1.46-3.76.113-.125.225-.244.335-.361.613-.652 1.145-1.217 1.145-2.099 0-1.32-1.14-1.82-2.3-1.82Zm2 12.12h-2.8v-2.8h2.8v2.8ZM6.946 2.25c0 .69-.555 1.25-1.239 1.25s-1.239-.56-1.239-1.25S5.023 1 5.708 1c.683 0 1.238.56 1.238 1.25Zm3.25 11.13L9.334 11H5.707c-.822 0-1.486-.67-1.486-1.5V5.75c0-.83.664-1.5 1.486-1.5.823 0 1.487.67 1.487 1.5V8.5H9.72c.416 0 .793.26.931.66l1.289 3.58a.935.935 0 0 1-.873 1.26.94.94 0 0 1-.872-.62Zm-.99 0-.615-1.7c-.525 1.07-1.615 1.82-2.884 1.82-1.774 0-3.22-1.46-3.22-3.25 0-.78.287-1.49.743-2.05V6.23A4.735 4.735 0 0 0 1 10.25C1 12.87 3.11 15 5.707 15c1.397 0 2.636-.63 3.498-1.61v-.01Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaRu;
