import type { SVGProps } from "react";
const SvgHostelSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 2h16v2h2v1h-1v15h1v1H2v-1h1V5H2V4h2V2Zm0 18V5h16v15h-5.005L15 17.5l.001-.5H9v3H4Zm9.999-2-.004 2H10v-2h3.999ZM19 3v1H5V3h14Zm-6 6v2h1V6h-1v2h-2V6h-1v5h1V9h2Zm-6.5 4.6L7 12l.5 1.6H9l-1.2.8.5 1.6-1.3-.9-1.3.9.5-1.5-1.2-.9h1.5ZM12 12l-.5 1.6H10l1.2.9-.5 1.5 1.3-.9 1.3.9-.5-1.6 1.2-.9h-1.5L12 12Zm4.5 1.6L17 12l.5 1.5H19l-1.2.9.5 1.6-1.3-.9-1.3.9.5-1.5-1.2-.9h1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHostelSmall;
