import type { SVGProps } from "react";
const SvgArrowsLongRightLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.844 7.138-3.142-3.141-.707.707 2.288 2.287H4v1h14.285l-2.29 2.296.708.707 3.142-3.15.353-.353-.354-.353Zm-15.688 9 3.142-3.141.707.707-2.288 2.287H20v1H5.715l2.29 2.296-.707.707-3.142-3.15-.353-.353.353-.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLongRightLeftSmall;
