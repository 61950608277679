import type { SVGProps } from "react";
const SvgSaVx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.801 1H1l1.84 14h3.341l7.801-14h-3.04L5.141 11.89h-.04L3.801 1Zm16 4.814L23.64 1h3.3l-5.78 6.679L24.402 15h-3.06L19.2 9.383h-.04L14.8 15h-3.48l6.46-7.603L14.72 1h3.02l2.02 4.814h.04Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVx;
