import type { SVGProps } from "react";
const SvgFullscreenSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 2H2v6h1V3h5V2H2.5ZM16 3h5v5h1V2h-6v1Zm5 18v-5h1v6h-6v-1h5ZM3 16v5h5v1H2v-6h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFullscreenSmall;
