import type { SVGProps } from "react";
const SvgFolderMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.941 9.25h6.802v.5l.007 16.5v.5H6.25v-14.5h15.191l1.362-2.724.138-.276Zm.618 1-1.362 2.724-.138.276H7.25v12.5h21.5l-.007-15.5H23.56Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFolderMedium;
