import type { SVGProps } from "react";
const SvgHierarchyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.75 6.25h10v8.5h-4.5v3.5h7.5v4.5h4.5v8.5h-10v-8.5h4.5v-3.5h-14v3.5h4.5v8.5h-10v-8.5h4.5v-4.5h7.5v-3.5h-4.5v-8.5Zm1 1v6.5h8v-6.5h-8Zm-7.5 16.5v6.5h8v-6.5h-8Zm15 0v6.5h8v-6.5h-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHierarchyMedium;
