import type { SVGProps } from "react";
const SvgGpsDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m35.808 36.516 9.839 9.838.707-.707-43-43-.708.707 9.839 9.838A16.438 16.438 0 0 0 7.995 24H3v1h4.995a16.447 16.447 0 0 0 4.838 11.167A16.446 16.446 0 0 0 24 41.005V46h1v-4.995a16.439 16.439 0 0 0 10.808-4.49Zm-.707-.708-4.246-4.246a9.482 9.482 0 0 1-13.073-.345 9.482 9.482 0 0 1-.345-13.072l-4.245-4.246a15.466 15.466 0 0 0 .348 21.561 15.466 15.466 0 0 0 21.56.348ZM18.146 18.853a8.482 8.482 0 0 0 .343 11.657 8.482 8.482 0 0 0 11.658.344L18.146 18.853ZM24 8.008a16.406 16.406 0 0 0-8.91 2.956l.57.82a15.402 15.402 0 0 1 8.84-2.783A15.467 15.467 0 0 1 40 24.5c0 3.295-1.039 6.331-2.785 8.839l.82.571A16.401 16.401 0 0 0 40.993 25h5.006v-1h-5.006A16.466 16.466 0 0 0 25 8.008V3h-1v5.008Zm-3.752 8.018A9.428 9.428 0 0 1 24.5 15c5.26 0 9.5 4.24 9.5 9.5 0 1.54-.384 2.976-1.026 4.252l-.893-.45a8.42 8.42 0 0 0 .92-3.802c0-4.709-3.793-8.5-8.5-8.5a8.428 8.428 0 0 0-3.804.919l-.45-.893Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGpsDisabledLarge;
