import type { SVGProps } from "react";
const SvgAdultKidsLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 12.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 8 12.5ZM9.5 10a2.5 2.5 0 1 0 .002 5.002A2.5 2.5 0 0 0 9.5 10Zm17.001 15h-.448l-.049.445-1 9 .994.11.173-1.554H27v6.998h1v-6.998h3.001v6.998h1v-6.998h.83l.172 1.554.994-.11-1-9-.05-.445H26.5Zm6.22 7.001L32.053 26h-5.106l-.666 6.001h6.438ZM7 28.001v11.998h1V28.001h3v11.998h1V28.001h1V29.5h1V17H5v12.5h1v-1.499h1Zm6-1V18H6v9.001h7Zm16.501-8a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3Zm-2.5 1.5a2.5 2.5 0 1 1 5.001 0 2.5 2.5 0 0 1-5.001 0ZM36.054 25h6.896l.05.445 1 9-.994.11-.173-1.554h-.83v6.998h-1v-6.998h-3v6.998h-1v-6.998h-.83L36 34.555l-.994-.11 1-9 .05-.445Zm6 1 .667 6.001h-6.438L36.949 26h5.105Zm-4.052-5.5a1.5 1.5 0 1 1 3.001.002 1.5 1.5 0 0 1-3.001-.002Zm1.5-2.5a2.5 2.5 0 1 0 .001 5.002A2.5 2.5 0 0 0 39.502 18Zm-23.001 7h-.448l-.049.445-1 9 .994.11.173-1.554H17v6.998h1v-6.998h3v6.998h1v-6.998h.83l.173 1.554.994-.11-1.001-9-.05-.445H16.5Zm6.219 7.001L22.052 26h-5.104l-.666 6.001h6.438Zm-3.22-13a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3ZM17 20.5a2.5 2.5 0 1 1 5.002 0 2.5 2.5 0 0 1-5.002 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAdultKidsLarge;
