import type { SVGProps } from "react";
const SvgFolderOpenArrowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m20.75 10.793 3.646-3.647.708.708-4.5 4.5-.354.353-.354-.353-4.5-4.5.708-.708 3.646 3.647V3h1v7.793ZM4.736 12.25H14.75v3h12v3h4.392l-.217.657-3.975 12-.114.343H4.75v-.5l-.014-18v-.5Zm21.014 6v-2h-12v-3H5.737l.01 14.613 3.307-9.28.118-.333H25.75Zm-19.791 12h20.155l3.644-11H9.878l-3.92 11Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFolderOpenArrowMedium;
