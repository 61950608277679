import type { SVGProps } from "react";
const SvgUserTieMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 8.75c-2.14 0-4 2.052-4 4.75s1.86 4.75 4 4.75c2.14 0 4-2.052 4-4.75s-1.86-4.75-4-4.75Zm-5 4.75c0-3.1 2.169-5.75 5-5.75s5 2.65 5 5.75c0 1.46-.48 2.82-1.285 3.85 4.25 1.939 6.535 7.126 6.535 11.9v.5H7.75v-.5c0-4.746 2.281-9.95 6.535-11.9C13.481 16.32 13 14.96 13 13.5Zm5.875 5.663a4.443 4.443 0 0 1-1.753 0L18 20.218l.875-1.055Zm-3.904-1.021c-3.795 1.57-6.055 6.13-6.212 10.608h8.784l-1.397-1.396-.2-.2.069-.275 1.438-5.754-2.482-2.983Zm3.486 10.608h8.784c-.157-4.505-2.422-9.052-6.217-10.609l-2.477 2.984 1.438 5.754.069.275-.2.2-1.397 1.396Zm.49-1.903L18 23.062l-.946 3.785.946.946.946-.946Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserTieMedium;
