import type { SVGProps } from "react";
const SvgTrashMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.294 10.764c1.15-1.8 3.15-3.014 5.456-3.014 2.305 0 4.306 1.213 5.456 3.014H30v1h-1.75V29.75h-19V11.764H7.5v-1h5.794Zm1.233 0h8.446A5.443 5.443 0 0 0 18.75 8.75a5.44 5.44 0 0 0-4.223 2.014Zm-4.277 1V28.75h17V11.764h-17Zm3.5 3.986v9h1v-9h-1Zm4.5 9v-9h1v9h-1Zm4.5-9v9h1v-9h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrashMedium;
