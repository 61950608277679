import type { SVGProps } from "react";
const SvgFreightWagonWeightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#freight-wagon-weight-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.25 6a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm3.871 1.75a2.75 2.75 0 1 0-4.243 0H9.34l-.08.402-3 15-.12.598h23.72l-.12-.598-3-15-.08-.402h-6.54ZM18 8.75h-7.84l-2.8 14h21.28l-2.8-14H18ZM4.25 30v-1.75h2v1.5h1.5V30a2.747 2.747 0 0 0 2.75 2.75A2.747 2.747 0 0 0 13.25 30v-.25h9.5V30a2.747 2.747 0 0 0 2.75 2.75A2.747 2.747 0 0 0 28.25 30v-.25h1.5v-1.5h2V30h1v-4.5h-1v1.75h-2v-1.5H6.25v1.5h-2V25.5h-1V30h1Zm8-.25h-3.5V30c0 .969.781 1.75 1.75 1.75s1.75-.781 1.75-1.75v-.25Zm11.5 0h3.5V30c0 .969-.781 1.75-1.75 1.75s-1.75-.781-1.75-1.75v-.25Zm-16.5-3v2h21.5v-2H7.25ZM17.7 12.165l-.015.015h-1.95l-2.805 2.955V12.18h-1.56v7.155h1.56v-2.31l.9-.9 2.175 3.21h1.965L14.895 15l2.79-2.82h.03l-.015-.015Zm6.855 7.155v-3.855H21.54v1.14h1.59c-.015.48-.18.87-.495 1.17-.315.3-.72.45-1.215.45-.675 0-1.2-.255-1.545-.765-.27-.42-.42-.96-.42-1.695 0-.735.15-1.305.45-1.74.345-.495.84-.735 1.47-.735.42 0 .765.12 1.035.33.27.21.45.51.525.9h1.515a2.734 2.734 0 0 0-.765-1.62c-.585-.615-1.35-.915-2.295-.915-1.02 0-1.845.345-2.49 1.02-.69.705-1.035 1.635-1.035 2.76 0 .99.27 1.83.795 2.505.645.81 1.5 1.215 2.595 1.215.9 0 1.62-.33 2.145-.99l.15.825h1.005Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="freight-wagon-weight-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFreightWagonWeightMedium;
