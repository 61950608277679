import type { SVGProps } from "react";
const SvgLocationPinPulseSurroundingAreaSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 8.015a6 6 0 1 1 12 0v.004c-.009 1.009-.327 1.901-.8 2.729l-.005.01-.007.01-4.75 7.498-.422.667-.422-.667-4.75-7.499-.007-.01c-.486-.81-.796-1.683-.837-2.723v-.02ZM7.557 10c.042.08.087.159.135.237l4.324 6.827 4.321-6.822c.048-.084.094-.168.137-.252H14.19l-.138-.275-.487-.969-1.098 2.93-.564 1.503-.39-1.558-1.11-4.442-.936 2.497-.122.324H7.557Zm-.405-1h1.501l1.378-3.675.564-1.504.39 1.558 1.11 4.442.936-2.496.402-1.071.513 1.022.862 1.714h2.058c.084-.317.13-.641.134-.977a5 5 0 0 0-10-.008c.015.352.066.681.152.995Zm-3.084 7.301c1.177-.52 2.807-.911 4.678-1.123l.112.993c-1.812.206-3.333.58-4.386 1.045-.528.233-.913.478-1.158.715-.244.236-.314.427-.314.569 0 .18.117.443.543.762.417.312 1.052.614 1.883.878C7.08 20.665 9.403 21 12 21c2.598 0 4.92-.335 6.575-.86.83-.264 1.465-.566 1.882-.878.426-.319.543-.582.543-.762 0-.141-.069-.33-.309-.564-.24-.235-.62-.477-1.14-.71-1.037-.462-2.537-.836-4.327-1.045l.116-.993c1.848.215 3.457.607 4.619 1.125.58.259 1.073.56 1.43.907.357.348.611.779.611 1.28 0 .642-.412 1.165-.944 1.563-.541.405-1.293.75-2.179 1.03C17.1 21.657 14.671 22 12 22c-2.67 0-5.1-.343-6.877-.907-.885-.28-1.638-.625-2.179-1.03C2.412 19.665 2 19.142 2 18.5c0-.505.258-.939.62-1.288.36-.349.86-.651 1.448-.91Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinPulseSurroundingAreaSmall;
