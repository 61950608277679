import type { SVGProps } from "react";
const SvgHandFingersSnapMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.75 10.5V3h-1v7.5h1Zm10.285-1.666a2.002 2.002 0 0 0-2.829-.002l-8.955 8.731.003-3.31a2.002 2.002 0 0 0-2.002-2.003c-.555 0-1.005.21-1.343.577-.32.348-.514.81-.64 1.294l-3 10.491-.079.274.195.207 7.433 7.878.205.217.289-.077-.13-.483.13.483h.002l.003-.002.009-.002.028-.008.101-.032c.086-.028.21-.071.365-.132a11.58 11.58 0 0 0 1.295-.609c1.082-.586 2.553-1.57 4.147-3.164a3344.586 3344.586 0 0 1 3.571-3.565l.11-.11.024-.024.006-.005.001-.001.002-.002a2.002 2.002 0 0 0-.912-3.354 2.005 2.005 0 0 0-.148-2.665 1.998 1.998 0 0 0-.907-.52c.62-.786.57-1.93-.155-2.657a1.998 1.998 0 0 0-.896-.518l4.077-4.076a2.002 2.002 0 0 0 0-2.831Zm-10.579 23.17a7.3 7.3 0 0 1-.13.049l-7.018-7.44 2.923-10.223.004-.011c.106-.41.246-.696.41-.875a.765.765 0 0 1 .607-.254c.553 0 1.002.448 1.002 1.003l-.003 4.497-.001 1.186.85-.828 9.807-9.563.005-.005a1.002 1.002 0 0 1 1.416 1.418L22.1 16.185a2.016 2.016 0 0 0-.078.074l-2.656 2.66.707.706 2.712-2.71a1.004 1.004 0 0 1 1.363.05c.39.392.39 1.026 0 1.418l-.962.958a1.82 1.82 0 0 0-.102.095l.003.003-1.595 1.59.706.708 1.665-1.66a1.004 1.004 0 0 1 1.347.066c.39.391.39 1.026 0 1.417l-1.594 1.592.706.708.53-.53h.002a1.002 1.002 0 0 1 1.417 1.417h-.001l-.006.006-.024.024-.111.11-.547.546c-.54.537-1.468 1.464-3.026 3.022-1.521 1.522-2.913 2.448-3.916 2.992-.502.272-.907.449-1.183.557Zm6.96-27.227-3 4.5-.832-.554 3-4.5.832.554Zm-10.5 3.946-3-4.5-.832.554 3 4.5.832-.554Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandFingersSnapMedium;
