import type { SVGProps } from "react";
const SvgSignXSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.5 1.877.448.9 8.7 17.5.36.723H1.992l.36-.723 8.7-17.5.447-.9ZM3.607 20h15.786L11.5 4.123 3.607 20Zm7.186-4.5-2.146-2.146.707-.708 2.146 2.147 2.147-2.147.707.708-2.147 2.146 2.147 2.146-.707.708-2.147-2.147-2.146 2.147-.707-.708 2.146-2.146Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSignXSmall;
