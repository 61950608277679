import type { SVGProps } from "react";
const SvgCloudSnowflakeSunSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.925 6.536c.436.292.808.69 1.065 1.18.318.609.448 1.337.322 2.143a2.04 2.04 0 0 1 1.034.603c.088.095.167.197.236.305C18.43 10.376 19 9.527 19 8.5 19 7.085 17.915 6 16.5 6c-.609 0-1.153.2-1.575.536Zm3.022 5.164c.019.101.032.204.04.307.086 1.114-.468 2.412-1.812 2.852l-.311-.95c.801-.263 1.186-1.05 1.126-1.825-.03-.381-.167-.715-.377-.942-.2-.216-.488-.359-.893-.343l-.698.028.2-.67c.24-.811.144-1.476-.118-1.977a2.277 2.277 0 0 0-1.25-1.073c-1.048-.38-2.362-.045-2.997 1.395l-.266.602-.508-.417c-.967-.793-2.566-.048-2.386 1.453l.067.56h-.563c-.59 0-1.079.533-1.159 1.337-.076.769.267 1.548 1.121 1.838l-.321.947c-1.397-.474-1.905-1.769-1.795-2.883.092-.93.65-1.918 1.639-2.175.093-1.85 2.007-2.893 3.522-2.174.887-1.444 2.421-1.866 3.733-1.504A3.484 3.484 0 0 1 16.5 5C18.467 5 20 6.533 20 8.5c0 1.443-.827 2.659-2.053 3.2Zm-9.276.679L10.293 14H8.014v1h2.292l-1.62 1.62.707.708L11 15.721V18h1v-2.293l1.621 1.621.707-.707L12.708 15h2.306v-1h-2.293l1.622-1.622-.707-.707L12 13.306V11h-1v2.293L9.378 11.67l-.707.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowflakeSunSmall;
