import type { SVGProps } from "react";
const SvgCircleInformationSmallSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 12c0-3.912 3.088-7 7-7 3.91 0 7 3.088 7 7s-3.09 7-7 7c-3.912 0-7-3.088-7-7Zm7-8c-4.464 0-8 3.536-8 8s3.536 8 8 8c4.463 0 8-3.536 8-8s-3.537-8-8-8Zm.98 5V7h-1v2h1Zm0 5.99h2v1h-5v-1h2v-4H10v-1h2.98v5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleInformationSmallSmall;
