import type { SVGProps } from "react";
const SvgTwoAdultsKidLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.497 11a1.5 1.5 0 1 0 .001 3.001 1.5 1.5 0 0 0 0-3.001Zm-2.5 1.5a2.5 2.5 0 1 1 5.002.002 2.5 2.5 0 0 1-5.002-.002Zm0 25.5v1.999h1V38h3v1.999h1V38h2.053l-.055-.55-1-9.999-.045-.45h-6.905l-.045.45-1 10-.055.549h2.052Zm-.924-21h6.849l.07.418 2 12-.987.164L16.074 18H10.92L8.99 29.582l-.987-.164 2-12 .07-.418Zm-.023 20 .9-8.999h5.095l.9 8.999H10.05Zm15.448-26a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm0 27.499V28.001h-1V29.5h-1V17h9v12.5h-1v-1.499h-1v11.998h-1V28.001h-3v11.998h-1Zm6-21.999v9.001h-7V18h7ZM35 20.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM34 33.002V40h1v-6.998h3V40h1v-6.998h.83l.173 1.554.994-.11-1.001-9-.05-.445h-6.894l-.049.445-1 9 .994.11.173-1.554H34Zm5.719-1L39.051 26h-5.104l-.667 6.001h6.438Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoAdultsKidLarge;
