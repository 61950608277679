import type { SVGProps } from "react";
const SvgTaxiSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5 4.5H10V6H8c-.921 0-1.7.214-2.243.757C5.214 7.3 5 8.079 5 9v1H4v1h1v8h4v-2h6l.001 1.5v.5H19v-8h1v-1h-1V9c0-.921-.214-1.7-.757-2.243C17.7 6.214 16.921 6 16 6h-2V4.5h-3.5ZM13 6v-.5h-2V6h2ZM6.464 7.464C6.184 7.744 6 8.215 6 9v2h6.05a2.501 2.501 0 0 1 4.9 0H18V9c0-.785-.183-1.256-.464-1.536C17.256 7.184 16.785 7 16 7H8c-.785 0-1.256.183-1.536.464ZM18 12H6v4h12v-4ZM6 18v-1h2v1H6Zm10 0v-1h2v1h-2Zm-.085-7a1.5 1.5 0 0 0-2.83 0h2.83ZM7 14a1 1 0 1 1 2.002 0A1 1 0 0 1 7 14Zm9-1a1 1 0 1 0 .002 2A1 1 0 0 0 16 13Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTaxiSmall;
