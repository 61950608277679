import type { SVGProps } from "react";
const SvgGlassCocktailLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 2a6.5 6.5 0 1 0 0 13c.96 0 1.865-.215 2.68-.588L23 28.628V44h-8v1h17v-1h-8V28.628L34.938 8.741 35.346 8H28.82l2.618-4.759-.876-.482L27.679 8h-8.698A6.5 6.5 0 0 0 12.5 2Zm5.478 6a5.5 5.5 0 1 0-5.478 6 5.43 5.43 0 0 0 2.197-.467l-.487-.887A4.363 4.363 0 0 1 12.5 13a4.5 4.5 0 1 1 4.473-5h1.005Zm-2.013 0a3.503 3.503 0 0 0-1.825-2.593L13.21 8h2.755Zm-2.767-2.93A3.494 3.494 0 0 0 9.74 6.347l2.49 1.423.968-2.7ZM9.243 7.215A3.491 3.491 0 0 0 9 8.5c0 .902.34 1.723.9 2.344l1.835-2.206-2.492-1.423Zm1.426 4.269A3.49 3.49 0 0 0 12.5 12c.43 0 .84-.085 1.225-.236l-1.31-2.38-1.746 2.1ZM27.171 11h5.383l1.1-2h-5.383l-1.1 2Zm-.042-2-1.1 2H14.446l-1.1-2h13.783ZM23.5 27.462 14.996 12h17.008L23.5 27.462ZM20.5 14a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm7 2a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-3 6a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGlassCocktailLarge;
