import type { SVGProps } from "react";
const SvgHandBriefcaseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a2 2 0 0 0-2 2H7a2 2 0 0 0-2 2v6h1v-3h5v.734c0 .7.567 1.266 1.266 1.266h2.468c.7 0 1.266-.567 1.266-1.266V10h5v5a1 1 0 0 1-1 1h-.998v1H20a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-3a2 2 0 0 0-2-2h-3Zm4 2a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1h5ZM7 6a1 1 0 0 0-1 1v2h5v-.734c0-.7.567-1.266 1.266-1.266h2.468c.7 0 1.266.567 1.266 1.266V9h5V7a1 1 0 0 0-1-1H7Zm5.266 2a.266.266 0 0 0-.266.266V9h3v-.734A.266.266 0 0 0 14.734 8h-2.468ZM15 10.734V10h-3v.734c0 .147.12.266.266.266h2.468c.147 0 .266-.12.266-.266Zm-6.993 5.088-3.582-2.503a1.5 1.5 0 0 0-1.874 2.343l5.919 5.213.141.125h3.925l.862.855.146.145h9.208l-.907-.862-5.328-5.063c-1.19-1.138-2.748-2.02-4.39-2.075H10c-.636 0-1.15.243-1.499.637-.299.336-.459.76-.494 1.185ZM3.84 14.13l4.723 3.3c.347.354.838.57 1.437.57h2v-1h-2c-.364 0-.6-.132-.751-.3A1.054 1.054 0 0 1 9 16c0-.268.09-.52.249-.699.15-.17.387-.301.751-.301h2.1c1.313.047 2.646.765 3.726 1.798h.002L20.248 21h-6.292l-.862-.855-.146-.145H8.99l-5.784-5.095-.009-.008-.009-.007a.5.5 0 0 1 .626-.78l.013.01.014.01Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandBriefcaseSmall;
