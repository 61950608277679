import type { SVGProps } from "react";
const SvgSaXr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.181 6.977h-1.02l.78-3.771h1.16c1.14 0 2.401 0 2.401 1.465 0 1.845-1.82 2.306-3.32 2.306ZM21.861 1h-3.3l-2.92 14h2.8l1.22-5.816h1.06c1.29 0 1.428.75 1.636 1.886.037.202.076.417.124.641l.68 3.289h2.941l-1.34-5.636c-.18-.763-.66-1.243-1.42-1.283v-.04c2.28-.04 4.08-1.325 4.08-3.731C27.422.999 24.51 1 21.952 1h-.09ZM9.442 5.814 7.421 1H4.4l3.061 6.398L1 15h3.481l4.361-5.617h.04L11.022 15h3.06l-3.24-7.321L16.623 1h-3.301l-3.84 4.814h-.04Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaXr;
