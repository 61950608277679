import type { SVGProps } from "react";
const SvgTreeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.184 3.933c-4.752 0-8.658 3.627-8.658 8.166 0 .395.032.782.09 1.159C10.898 13.812 8 16.816 8 20.533c0 4.115 3.54 7.4 7.842 7.4H23v7.184l-1.512-1.44a3 3 0 0 0-.367-3.798c-.342-.343-.85-.538-1.337-.658a8.986 8.986 0 0 0-1.568-.22 16.153 16.153 0 0 0-1.872-.003l-.031.002-.009.001h-.003l.035.499-.036-.499-.453.032-.011.455.5.012-.5-.012v.012l-.001.03a14.756 14.756 0 0 0 .086 1.791c.052.488.138 1.023.28 1.508.136.472.344.958.678 1.292a3 3 0 0 0 3.92.28L23 36.499V43h-9v1h19v-1h-9V27.933h9.789c3.4 0 6.211-2.598 6.211-5.867 0-1.83-.89-3.448-2.262-4.517.4-.709.63-1.519.63-2.383 0-2.845-2.445-5.1-5.394-5.1-.483 0-.946.065-1.385.18-.894-3.631-4.32-6.313-8.405-6.313Zm-2.436 29.04-1.403-1.335-.69.724 1.403 1.336a2 2 0 0 1-2.472-.284c-.154-.154-.303-.445-.426-.865a7.993 7.993 0 0 1-.245-1.335 14.61 14.61 0 0 1-.077-1.197v-.038l.064-.002c.33-.007.778-.007 1.253.022.477.03.967.088 1.388.192.436.108.726.25.871.395a2 2 0 0 1 .334 2.387ZM24 26.933h9.789c2.908 0 5.211-2.208 5.211-4.867 0-1.63-.866-3.08-2.21-3.968l-.428-.283.293-.42c.45-.646.713-1.41.713-2.229 0-2.236-1.938-4.1-4.394-4.1-.558 0-1.091.108-1.591.29l-.563.204-.1-.59c-.58-3.398-3.7-6.037-7.536-6.037-4.259 0-7.658 3.237-7.658 7.166 0 .507.061 1.002.17 1.484l.126.561-.573.047C11.722 14.48 9 17.218 9 20.533c0 3.505 3.034 6.4 6.842 6.4H23v-5.707l-5.293-3.82.586-.811L23 19.993V11h1v12.774l6.634-7.115.732.682-7.366 7.9v1.692Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTreeLarge;
