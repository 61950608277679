import type { SVGProps } from "react";
const SvgBuildingTreeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 4H6v1h1v5h1V5h12v16H8v-3H7v3H6v-4.051a2.506 2.506 0 0 0 1.347-.081 2.5 2.5 0 0 0 .142-4.671c.006-.063.01-.127.01-.19V12a2 2 0 0 0-4 0v.006c.002.064.005.128.012.19a2.497 2.497 0 0 0-1.534 2.126A2.5 2.5 0 0 0 5 16.949V21H2v1h19V5h1V4ZM10.5 6H10v4h8V6h-7.5Zm.5 3V7h6v2h-6Zm-1 2h8v4h-8v-4Zm1 1v2h6v-2h-6Zm-.5 4H10v4h8v-4h-7.5Zm6.5 3h-6v-2h6v2ZM6.43 12.367l.47.173-.093.491a1.5 1.5 0 1 1-1.05 2.76l-.257-.154-.258.154a1.5 1.5 0 1 1-1.05-2.76l.582-.11-.205-.554a1 1 0 1 1 1.862 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBuildingTreeSmall;
