import type { SVGProps } from "react";
const SvgExt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ext_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M3.669 15.8 6.104 4.132h8.651l-.405 1.95H8.102l-.557 2.65h6.04l-.405 1.95H7.139l-.733 3.168h6.79l-.414 1.95H3.669Zm13.009 0h-3.104l5.15-5.905-2.898-5.763h2.508l1.138 2.189c.037.08.297.618.78 1.616.032.053.066.124.103.214a81.91 81.91 0 0 1 1.632-1.941l1.838-2.078h3.017l-5.34 6.145 2.873 5.523h-2.707l-.875-1.759c-.456-.928-.759-1.594-.907-1.998-.207.314-.74.96-1.6 1.942L16.678 15.8Zm14.204 0h-2.404l2.037-9.718h-3.422l.406-1.95h9.193l-.406 1.95H32.91L30.88 15.8Z"
    />
  </svg>
);
export default SvgExt;
