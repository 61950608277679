import type { SVGProps } from "react";
const SvgUserHeadsetSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.843 8H17a.99.99 0 0 1 .749.307c.188.207.251.465.251.693v2c0 .271-.09.536-.294.731-.2.192-.459.269-.706.269h-1.964a4.062 4.062 0 0 1-.34.562c3 1.661 4.304 4.943 4.304 7.938v.5H5v-.5c0-3.384 1.6-6.45 4.301-7.94a4.072 4.072 0 0 1-.337-.56H7c-.27 0-.515-.121-.686-.286A.993.993 0 0 1 6 11V9.006a1 1 0 0 1 .307-.712A.997.997 0 0 1 7 8h.075c1.388-5.377 8.61-5.218 9.768 0Zm-1.031 0C14.75 4.137 9.38 3.934 8.114 8h.85c.59-1.18 1.692-2 3.036-2s2.447.82 3.036 2h.776ZM12 7c-1.328 0-2.5 1.259-2.5 3s1.172 3 2.5 3c.725 0 1.404-.376 1.87-1H12v-1h2.362a3.57 3.57 0 0 0 .138-1c0-1.741-1.172-3-2.5-3Zm0 7c.75 0 1.425-.256 1.978-.687 2.597 1.313 3.868 4.018 4.008 6.687H6.012c.154-2.976 1.67-5.52 4.008-6.688A3.184 3.184 0 0 0 12 14Zm-5-3.014v-1.97A.058.058 0 0 1 7.016 9H8v2h-.985l-.002-.001A.065.065 0 0 1 7 10.986ZM16 11h1V9h-1v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserHeadsetSmall;
