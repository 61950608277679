import type { SVGProps } from "react";
const SvgTaxiProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.75 6.25h8.5v3h1.336a1.5 1.5 0 0 1 1.06.44l5.561 5.56h4.043a1.5 1.5 0 0 1 1.5 1.5v7h-4.536a3.502 3.502 0 0 1-3.464 3 3.502 3.502 0 0 1-3.465-3h-8.07a3.502 3.502 0 0 1-3.465 3 3.502 3.502 0 0 1-3.464-3H3.25v-7.624a1.5 1.5 0 0 1 .371-.988l4.704-5.376a1.5 1.5 0 0 1 1.129-.512h1.296v-3Zm1 3h6.5v-2h-6.5v2Zm-5.465 13.5a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.464 3h8.071a3.502 3.502 0 0 1 3.465-3 3.502 3.502 0 0 1 3.465 3h3.535v-6a.5.5 0 0 0-.5-.5h-27v6.5h2.035Zm-1.433-7.5h8.898v-5H9.454a.5.5 0 0 0-.377.17l-4.225 4.83Zm9.898-5v5h11.043l-4.854-4.854a.5.5 0 0 0-.353-.146H14.75Zm-5 10.5a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Zm15 0a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTaxiProfileMedium;
