import type { SVGProps } from "react";
const SvgHamburgerMenuMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 7.75h24v1H6v-1Zm0 21h24v1H6v-1Zm24-10.5H6v1h24v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHamburgerMenuMedium;
