import type { SVGProps } from "react";
const SvgTicketDayLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.08 8.12h42v8h-.5a6.5 6.5 0 1 0 0 13h.5v9h-42v-30Zm1 1v28h40v-7.017a7.5 7.5 0 0 1 0-14.966V9.12h-40Zm21 6v-3h-1v3h-11v-3h-1v3h-4v18.95l.499.001 20 .05.501.001v-19h-4Zm-16 1v2h19v-2h-19Zm0 16.951v-13.95h19v13.998l-19-.048Zm10.53-2.95v-8h-.74a1.93 1.93 0 0 1-.55 1.05 2.82 2.82 0 0 1-1.71.43v.72h2v5.8h1Zm12.47-20v2h1v-2h-1Zm0 5v-2h1v2h-1Zm0 1v2h1v-2h-1Zm0 5v-2h1v2h-1Zm0 1v2h1v-2h-1Zm0 5v-2h1v2h-1Zm0 1v2h1v-2h-1Zm0 5v-2h1v2h-1Zm0 1v2h1v-2h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketDayLarge;
