import type { SVGProps } from "react";
const SvgCloudSunshineMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.25 3v3h1V3h-1Zm-.487 8.691a4.599 4.599 0 0 0-1.796-1.852c.71-.678 1.682-1.089 2.783-1.089 2.26 0 4 1.74 4 4 0 1.709-.994 3.114-2.446 3.703a3.128 3.128 0 0 0-.26-.39 2.861 2.861 0 0 0-1.864-1.079c.246-1.253.065-2.374-.417-3.293Zm2.987 6.892a3.966 3.966 0 0 0-.087-1.197c1.836-.735 3.087-2.51 3.087-4.636 0-2.813-2.187-5-5-5-1.51 0-2.85.632-3.76 1.663-1.956-.606-4.301.02-5.582 2.303-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.586-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.84 1.073 2.548.708.713 1.75 1.155 3.125 1.155h10.5c1.331 0 2.367-.445 3.088-1.147a3.941 3.941 0 0 0 1.162-2.52Zm-5.883-8.156c-1.686-.612-3.8-.065-4.81 2.225l-.265.602-.509-.417c-1.627-1.333-4.282-.069-3.986 2.404l.066.56H10.8c-.616 0-1.116.302-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.886.493.497 1.265.86 2.415.86h10.5c1.096 0 1.874-.36 2.39-.863a2.941 2.941 0 0 0 .863-1.882c.054-.692-.128-1.355-.496-1.825-.357-.457-.91-.761-1.687-.73l-.698.028.199-.67c.379-1.274.233-2.339-.194-3.153a3.663 3.663 0 0 0-2.01-1.728Zm-1.309-2.17-2.161-2.08.693-.72 2.162 2.079-.694.72Zm9.778 9.892 2.12 2.121.708-.707-2.121-2.12-.707.706ZM31.5 12.25h3v1h-3v-1Zm-.21-6.77L29.169 7.6l.707.708 2.121-2.121-.707-.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudSunshineMedium;
