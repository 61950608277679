import type { SVGProps } from "react";
const SvgCombinedMobilityMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3.249h6.184l-.518.777-2.846 4.27 1.29 2.84-.91.414-1.272-2.801h-7.603l-.542 1.22a5.754 5.754 0 0 1 2.967 5.03A5.755 5.755 0 0 1 9 20.749a5.755 5.755 0 0 1-5.75-5.75 5.756 5.756 0 0 1 7.62-5.438l.673-1.515.132-.297h8.307l2.334-3.5H18v-1ZM8.543 14.796l1.919-4.317A4.756 4.756 0 0 0 4.25 15c0 2.62 2.131 4.75 4.75 4.75s4.75-2.13 4.75-4.75c0-1.754-.956-3.29-2.375-4.112l-1.918 4.315-.914-.406Zm-2.897 14.85 22.5-22.5.708.707-22.5 22.5-.708-.708ZM25.5 13.748H27c1.348 0 2.44.313 3.188 1.063.749.748 1.062 1.84 1.062 3.187v1.752H33v1h-1.75v11.998h-5.498v-3H16.25v3h-5.5v-4.25h1v.25h18.5V22.25H18v-1h3.285a3.502 3.502 0 0 1 3.465-3.002 3.502 3.502 0 0 1 3.465 3.002h2.035v-3.252c0-1.21-.282-1.994-.769-2.48-.486-.487-1.27-.77-2.481-.77h-1.5v-1Zm1.252 16h3.498v2h-3.498v-2Zm-15.002 0h3.5v2h-3.5v-2Zm13-10.5a2.5 2.5 0 0 1 2.45 2.002h-4.9a2.502 2.502 0 0 1 2.45-2.002Zm1 6.25a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-12-1a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0ZM9.313 5.749H13.5v-1H9.313v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCombinedMobilityMedium;
