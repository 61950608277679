import type { SVGProps } from "react";
const SvgUtilizationHighNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={16}
    fill="none"
    className="utilization-high-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#FF3838"
      fillRule="evenodd"
      d="M2.5 3C3.327 3 4 2.327 4 1.5S3.327 0 2.5 0 1 .673 1 1.5 1.673 3 2.5 3m7.004 0c.827 0 1.5-.673 1.5-1.5S10.33 0 9.504 0s-1.5.673-1.5 1.5.673 1.5 1.5 1.5m7.004 0c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5-1.5.673-1.5 1.5.673 1.5 1.5 1.5M0 10h1v6h3v-6h1V4H0zm7.004 0h1v6h3v-6h1V4h-5zM14 4v6h1v6h3v-6h1V4z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUtilizationHighNegative;
