import type { SVGProps } from "react";
const SvgTwoUsersSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.207 4.65c-.344.328-.594.79-.677 1.394 1.713.292 2.944 1.98 2.97 3.896 1.12-.274 2-1.449 2-2.94 0-1.707-1.153-3-2.5-3-.742 0-1.362.239-1.793.65Zm-.69-.725c-.552.527-.901 1.248-.993 2.11C8.764 6.305 7.5 8.039 7.5 10c0 .924.281 1.799.763 2.493C5.5 13.913 4 17.31 4 20.499v.5h14v-.5c0-.833-.104-1.679-.306-2.5H21v-.5c0-3.193-1.556-6.521-4.309-7.935A4.406 4.406 0 0 0 17.5 7c0-2.093-1.447-4-3.5-4-.957 0-1.838.31-2.482.925Zm4.445 6.399a3.18 3.18 0 0 1-1.568.652 4.284 4.284 0 0 1-.673 1.54c1.74.895 2.988 2.569 3.672 4.484h2.593c-.158-2.88-1.672-5.63-4.024-6.676ZM11 7c-1.311 0-2.5 1.268-2.5 3s1.189 3 2.5 3 2.5-1.268 2.5-3-1.189-3-2.5-3Zm-2.03 6.262C6.631 14.314 5.165 17.11 5.012 20h11.974c-.155-2.886-1.638-5.667-3.98-6.717-.56.45-1.248.718-2.007.718a3.18 3.18 0 0 1-2.03-.738Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoUsersSmall;
