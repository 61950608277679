import type { SVGProps } from "react";
const SvgLowVisionSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.534 16.48 3.223 3.224.707-.708-15-15-.707.708 2.815 2.815a17.21 17.21 0 0 0-3.37 3.541l-.2.282.193.287c.392.582 1.485 1.995 3.031 3.27 1.54 1.268 3.59 2.451 5.884 2.451h.015a8.06 8.06 0 0 0 3.41-.87Zm-.75-.749-7.5-7.5a16.21 16.21 0 0 0-1.381 1.205l6.854 6.863a7.062 7.062 0 0 0 2.027-.568ZM12.11 5.35c2.294 0 4.345 1.183 5.884 2.452 1.546 1.274 2.64 2.688 3.031 3.269l.194.288-.202.282a17.64 17.64 0 0 1-2.923 3.18l-.648-.762a16.64 16.64 0 0 0 2.548-2.714 16.354 16.354 0 0 0-2.636-2.772c-1.462-1.205-3.289-2.221-5.243-2.223a6.5 6.5 0 0 0-2.279.464l-.372-.928A7.5 7.5 0 0 1 12.1 5.35h.01Zm-7.884 6.004c.309-.415.637-.815.983-1.197l6.134 6.142c-1.665-.22-3.21-1.125-4.48-2.172a16.351 16.351 0 0 1-2.637-2.773Zm8.946-3.868a3.96 3.96 0 0 0-2.052.001l.26.966a2.96 2.96 0 0 1 3.627 3.627l.966.26a3.96 3.96 0 0 0-2.8-4.854Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLowVisionSmall;
