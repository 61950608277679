import type { SVGProps } from "react";
const SvgGeneralDisplaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 5V4H4v1H2v15h20V5h-1.999V4h-1v1H5Zm12 5V8.082c-.587.203-1 .754-1 1.418 0 .84.661 1.5 1.5 1.5.84 0 1.5-.66 1.5-1.5 0-.664-.412-1.216-1-1.418V10h-1Zm-2-.5C15 8.107 16.109 7 17.5 7 18.892 7 20 8.108 20 9.5S18.892 12 17.5 12A2.486 2.486 0 0 1 15 9.5ZM3 19V6h18v13H3ZM14 8H4V7h10v1ZM4 10h10V9H4v1Zm10 2H4v-1h10v1ZM4 14h10v-1H4v1Zm10 2H4v-1h10v1ZM4 18h10v-1H4v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGeneralDisplaySmall;
