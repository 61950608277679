import type { SVGProps } from "react";
const SvgTaxiProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 4h6v2h.586a1.5 1.5 0 0 1 1.06.44L18.207 10H20.5a1.5 1.5 0 0 1 1.5 1.5V16h-3.05a2.502 2.502 0 0 1-4.9 0h-5.1a2.502 2.502 0 0 1-4.9 0H2v-5.124a1.5 1.5 0 0 1 .371-.988l2.954-3.376A1.5 1.5 0 0 1 6.454 6H7V4Zm1 2h4V5H8v1Zm-3.95 9a2.502 2.502 0 0 1 4.9 0h5.1a2.502 2.502 0 0 1 4.9 0H21v-3.5a.5.5 0 0 0-.5-.5H3v4h1.05Zm-.448-5H9V7H6.454a.5.5 0 0 0-.377.17L3.602 10ZM10 7v3h6.793l-2.854-2.854A.5.5 0 0 0 13.586 7H10Zm-3.5 7a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3.002Zm10 0a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTaxiProfileSmall;
