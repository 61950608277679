import type { SVGProps } from "react";
const SvgArrowRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m26.589 18.384-5.985-5.988-.708.707 5.132 5.134H7.5v1h17.532l-5.136 5.16.708.705 5.985-6.012.352-.353-.352-.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRightMedium;
