import type { SVGProps } from "react";
const SvgCircleCrossMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 4.25C10.406 4.25 4.25 10.405 4.25 18c0 7.594 6.156 13.75 13.75 13.75S31.75 25.594 31.75 18c0-7.595-6.156-13.75-13.75-13.75ZM3.25 18C3.25 9.853 9.853 3.25 18 3.25S32.75 9.853 32.75 18c0 8.146-6.603 14.75-14.75 14.75S3.25 26.145 3.25 18Zm14.75.707-5.646 5.646-.708-.707L17.293 18l-5.647-5.647.708-.707L18 17.292l5.646-5.646.708.707L18.707 18l5.647 5.646-.708.707L18 18.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleCrossMedium;
