import type { SVGProps } from "react";
const SvgFiveCirclesInterconnectedMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.75 8.25a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 0 0-1.723 3.016l-7.761 7.76a2 2 0 1 0 0 3.446l7.76 7.762a2 2 0 1 0 3.446 0l7.762-7.761a2 2 0 1 0 0-3.446l-7.76-7.761A2 2 0 0 0 18.75 6.25Zm8.563 13h-5.098a3.502 3.502 0 0 1-2.966 2.965v5.098c.184.047.358.12.517.214l7.76-7.761a1.988 1.988 0 0 1-.213-.516Zm0-1h-5.098a3.503 3.503 0 0 0-2.965-2.965v-5.098c.183-.047.357-.12.516-.214l7.76 7.761a1.99 1.99 0 0 0-.213.516Zm-12.028 1a3.502 3.502 0 0 0 2.964 2.965v5.098a1.98 1.98 0 0 0-.515.214l-7.76-7.761c.093-.16.166-.333.213-.517h5.098Zm-5.098-1h5.099a3.503 3.503 0 0 1 2.964-2.965v-5.098a1.99 1.99 0 0 1-.516-.214l-7.761 7.76c.094.16.167.333.214.516Zm8.563-2a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm10.5 1.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-11.5 11.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-9.5-11.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFiveCirclesInterconnectedMedium;
