import type { SVGProps } from "react";
const SvgSaTg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.381 3.133c1.46 0 2.801.291 3.881.97l.7-2.347c-1.46-.543-3.1-.756-4.66-.756-5.042 0-8.383 3.044-8.383 7.99 0 4.187 2.74 6.01 6.841 6.01 1.36 0 2.921-.271 3.741-.601l1.601-7.388h-5.66l-.46 2.133h2.96l-.782 3.587c-.319.136-.74.136-1.379.136-2.38 0-3.94-1.765-3.94-3.955 0-3.2 2.14-5.78 5.54-5.78Zm-8.12-1.9H1.479L1 3.365h3.94L2.4 14.767h2.92L7.861 3.366h3.94l.46-2.132Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaTg;
