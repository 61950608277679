import type { SVGProps } from "react";
const SvgSaPl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.401 7.478h-1l.9-4.272h.88c1.2 0 2.341.3 2.341 1.725 0 1.745-1.581 2.547-3.121 2.547ZM1 15h2.8l1.121-5.315h1.62c3.121 0 5.901-1.665 5.901-5.054C12.442 1.14 8.902 1 6.321 1h-2.36L1 15ZM17.922 1H15.12l-2.96 14h8.262l.48-2.206H15.42L17.92 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaPl;
