import type { SVGProps } from "react";
const SvgHierarchyLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5 3H15v15h9v5H12v6H3v15h19V29h-9v-5h23v5h-9v15h19V29h-9v-6H25v-5h9V3H15.5Zm.5 14V4h17v13H16ZM4 43V30h17v13H4Zm24-13v13h17V30H28Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHierarchyLarge;
