import type { SVGProps } from "react";
const SvgSunshineMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.268 10.504V5.98h1v4.523h-1Zm-9.033-.677 3.416 3.416.707-.707L9.942 9.12l-.707.707Zm9.515 3.423c-3.03 0-5.458 2.46-5.458 5.498a5.492 5.492 0 0 0 5.492 5.493c3.03 0 5.466-2.456 5.466-5.491 0-3.034-2.467-5.5-5.5-5.5Zm-6.458 5.498c0-3.582 2.867-6.498 6.458-6.498 3.585 0 6.5 2.913 6.5 6.5 0 3.582-2.878 6.49-6.466 6.49a6.492 6.492 0 0 1-6.492-6.492Zm-1.787.502H6v-1h4.505v1Zm17.13-9.994-3.397 3.357.703.71 3.396-3.356-.703-.711Zm-.644 8.994H31.5v1h-4.509v-1Zm1.368 9.285-3.4-3.386-.706.709 3.4 3.386.706-.71ZM18.25 31.494v-4.505h1v4.505h-1Zm-5.618-7.246L9.24 27.64l.707.707 3.393-3.392-.707-.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSunshineMedium;
