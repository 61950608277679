import type { SVGProps } from "react";
const SvgBusSurroundingAreaMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.519 6.519C9.033 7.005 8.75 7.789 8.75 9v6.25h18.5V9c0-1.211-.282-1.995-.769-2.481-.486-.486-1.27-.769-2.481-.769H12c-1.211 0-1.995.283-2.481.769ZM8.75 24.25v-8h18.5v8H8.75Zm.062-18.438C9.56 5.063 10.652 4.75 12 4.75h12c1.348 0 2.44.313 3.188 1.062.749.749 1.062 1.84 1.062 3.188v3.25h1.5v2.5h-1.5v13.5h-5.498v-3H13.25v3h-5.5v-13.5h-1.5v-2.5h1.5V9c0-1.348.313-2.44 1.062-3.188ZM28.25 13.75h.5v-.5h-.5v.5Zm-4.498 11.5h3.498v2h-3.498v-2Zm-15.002 0h3.5v2h-3.5v-2Zm-1-12v.5h-.5v-.5h.5ZM24 7.75H12v1h12v-1ZM5.778 24.883c-.74.365-1.359.78-1.798 1.244-.442.466-.73 1.012-.73 1.622 0 .848.544 1.567 1.316 2.144.78.584 1.88 1.09 3.195 1.508 2.635.836 6.251 1.348 10.239 1.348 3.989 0 7.604-.512 10.24-1.348 1.315-.417 2.414-.924 3.194-1.508.772-.577 1.316-1.297 1.316-2.144 0-.61-.288-1.156-.73-1.622-.44-.464-1.058-.878-1.799-1.243l-.442.897c.68.335 1.186.686 1.516 1.034.328.345.455.657.455.934 0 .386-.25.846-.915 1.344-.656.49-1.638.955-2.897 1.355-2.514.797-6.023 1.3-9.938 1.3-3.914 0-7.423-.503-9.937-1.3-1.26-.4-2.242-.864-2.898-1.355-.666-.498-.915-.958-.915-1.344 0-.276.127-.588.455-.934.33-.348.836-.7 1.517-1.036l-.444-.896ZM12 19.749a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM11.75 21c0-.137.113-.25.25-.25s.25.113.25.25a.25.25 0 0 1-.5 0Zm11 0a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBusSurroundingAreaMedium;
