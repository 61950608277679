import type { SVGProps } from "react";
const SvgEv53 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-53_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm7.723 0v2.063H28.36l-.5 2.61c.561-.592 1.278-.888 2.15-.888.858 0 1.603.23 2.238.69.942.693 1.413 1.745 1.413 3.158 0 1.386-.503 2.483-1.508 3.293-.783.634-1.72.952-2.809.952-1.227 0-2.213-.328-2.96-.984-.745-.661-1.14-1.553-1.182-2.674h2.278c.037.407.169.754.396 1.04.355.45.847.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.298.328-1.706.984l-2.07-.031 1.102-6.467h6.372Zm1.272 3.674c.026-.953.261-1.751.706-2.397.72-1.037 1.8-1.555 3.245-1.555 1.11 0 2.007.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.146 1.084-.437 1.507-.285.418-.674.7-1.166.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.786 0 1.147-.423 2.086-1.27 2.816-.782.677-1.771 1.016-2.967 1.016-1.296 0-2.325-.373-3.086-1.119-.757-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.169 1.266.507 1.658.339.386.818.579 1.437.579.618 0 1.095-.183 1.428-.548.307-.344.46-.754.46-1.23 0-.57-.196-1.023-.587-1.356-.323-.275-.82-.413-1.492-.413-.19 0-.378.01-.563.032V8.772c.137.01.27.016.396.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.436-1.047-.291-.27-.675-.405-1.15-.405-.546 0-.966.17-1.262.508-.297.338-.455.833-.477 1.484h-2.197Z"
    />
  </svg>
);
export default SvgEv53;
