import type { SVGProps } from "react";
const SvgCigaretteSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 4.469c0-1.378 1.122-2.5 2.5-2.5S22 3.09 22 4.469c0 .753-.35 1.468-.94 1.94l-.284.228.128.34c.063.166.096.343.096.523 0 .826-.674 1.5-1.5 1.5a1.503 1.503 0 0 1-1.404-2.024l.128-.34-.283-.226A2.497 2.497 0 0 1 17 4.469Zm2.5-3.5c-1.93 0-3.5 1.57-3.5 3.5a3.49 3.49 0 0 0 1.059 2.494A2.503 2.503 0 0 0 19.5 10a2.503 2.503 0 0 0 2.441-3.037A3.49 3.49 0 0 0 23 4.47c0-1.93-1.57-3.5-3.5-3.5ZM2 11h20.25v4H2v-4Zm1 1v2h4v-2H3Zm6 2H8v-2h1v2Zm1 0h8.75v-2H10v2Zm11.25 0h-1.5v-2h1.5v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCigaretteSmall;
