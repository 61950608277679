import type { SVGProps } from "react";
const SvgCircleQuestionMarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4c-4.437 0-8 3.563-8 8 0 4.436 3.563 8 8 8 4.437 0 8-3.564 8-8 0-4.437-3.563-8-8-8Zm-9 8c0-4.989 4.01-9 9-9s9 4.011 9 9c0 4.988-4.01 9-9 9s-9-4.012-9-9Zm8.124 5.991h1.73v-1.666h-1.73v1.666ZM8.978 7.58c-.353.545-.548 1.314-.585 2.306h1.378c.111-1.751.88-2.626 2.306-2.626.688 0 1.208.222 1.561.665.294.363.44.811.44 1.345 0 .288-.051.571-.152.849-.122.326-.381.691-.777 1.097-.613.63-1.01 1.06-1.189 1.289-.178.23-.316.488-.412.777-.133.395-.205 1.001-.216 1.818h1.361c0-.46.027-.833.08-1.121.048-.289.132-.53.252-.725.121-.195.332-.439.637-.733.689-.673 1.124-1.134 1.305-1.385.406-.55.609-1.207.609-1.97 0-.924-.3-1.668-.897-2.234-.641-.614-1.503-.921-2.586-.921-.673 0-1.279.136-1.818.408A3.282 3.282 0 0 0 8.978 7.58Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleQuestionMarkSmall;
