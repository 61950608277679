import type { SVGProps } from "react";
const SvgLocomotiveHighSpeedSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.607 4.99H4v1h.607c1.136 0 2.247.255 3.24.78l1.436.762c-.743 1.264-.362 3.031 1.064 3.677l.032.014 2.517 1.105a8.035 8.035 0 0 0 3.228.677h3.486l.381.202a1.898 1.898 0 0 1 1.006 1.784H7v1h13.687c-.37.6-1.034 1-1.79 1H2v1h1.05c.075.37.231.709.45 1H2v1h20v-1h-8.486c.218-.291.375-.63.45-1h4.933A3.105 3.105 0 0 0 22 14.884a2.898 2.898 0 0 0-1.54-2.56L8.314 5.886c-1.148-.61-2.422-.896-3.707-.896Zm6.153 5.308c-.821-.372-1.11-1.473-.592-2.297l7.555 4.004h-1.599a7.035 7.035 0 0 1-2.826-.593l-2.518-1.105-.02-.009Zm-.661 7.693a1.5 1.5 0 0 0 2.83 0h-2.83Zm-1.036 0H7.95c-.075.37-.231.709-.45 1h2.013a2.488 2.488 0 0 1-.45-1Zm-4.978 0a1.5 1.5 0 0 0 2.83 0h-2.83ZM3 5.99H2v-1h1v1Zm0 9H2v1h1v-1Zm1 0h2v1H4v-1Zm1-3H3v1h2v-1Zm1-1a1 1 0 0 0-1-1v-1a2 2 0 1 1 0 4v-1a1 1 0 0 0 1-1Zm-4-1h2v-1H2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveHighSpeedSmall;
