import type { SVGProps } from "react";
const SvgSa2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.667 1H1v14h11.667V1ZM11.37 13.727H2.296V2.273h9.074v11.454Zm-6.358-3.346-.771.74.015 1.334h5.17v-1.486H6.5l.101-.135c.484-.516.954-1.11 1.415-1.781.458-.671.767-1.2.932-1.584.165-.383.272-.692.317-.93.047-.238.069-.479.069-.722 0-.726-.227-1.286-.68-1.681-.454-.393-1.095-.59-1.928-.59-.485 0-.861.034-1.128.105-.266.07-.47.131-.6.182-.134.051-.29.122-.463.211l-.13.088.19 1.645.093-.095c.544-.506 1.152-.757 1.821-.757.794 0 1.188.387 1.188 1.16 0 .512-.192 1.074-.577 1.68-.386.61-1.09 1.481-2.108 2.616Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSa2;
