import type { SVGProps } from "react";
const SvgTrainProfileSignParkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M16.5 10V4.5H18c.985 0 1.5.497 1.5 1.5 0 1-.502 1.498-1.5 1.5h-1.5m1 4v10m-4.49-6H7.5v-4h4.074m-5.736 8a2 2 0 0 0 4 0M2 11.5h3.5v4H2m0-7h5.338c1.97 0 3.627 1.274 4.236 3.038l2.104 5.8c.099.2.16.424.16.662a1.5 1.5 0 0 1-1.5 1.5H2m20 2H2m12.5-19h6a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Zm-9 6-3-2 3-2 3 2-3 2Z"
    />
  </svg>
);
export default SvgTrainProfileSignParkingSmall;
