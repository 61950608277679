import type { SVGProps } from "react";
const SvgDownloadLargeDataSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12 8.293 1.646-1.647.708.707-2.5 2.5-.354.354-.354-.354-2.503-2.508.708-.707L11 8.291V3h1v5.293ZM3.5 6H3v12h17V6h-5v1h4v10H4V7h4V6H3.5ZM5 12h13v-1H5v1Zm13 1.994H5v-1h13v1ZM5 16.001h13v-1H5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDownloadLargeDataSmall;
