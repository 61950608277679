import type { SVGProps } from "react";
const SvgCarProfileSignParkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 3.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-7Zm.5-1.5c-.83 0-1.5.67-1.5 1.5v7c0 .828.67 1.5 1.5 1.5H17v10h1V12h2.5a1.5 1.5 0 0 0 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5h-6ZM16 4h2c.57 0 1.094.144 1.471.519.379.375.529.899.529 1.481 0 .578-.145 1.102-.523 1.478-.376.376-.9.52-1.476.522H17v2h-1V4Zm1 3h1c.422 0 .647-.106.771-.23.123-.123.229-.348.229-.77 0-.421-.107-.647-.233-.771C18.641 5.104 18.414 5 18 5h-1v2ZM8.5 18a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3.002Zm-2.45 1a2.502 2.502 0 0 1 4.9 0H13v-3.5a.5.5 0 0 0-.5-.5H2v-1h6.792L5.94 11.145A.5.5 0 0 0 5.586 11H2v-1h3.586a1.5 1.5 0 0 1 1.06.44L10.207 14H12.5a1.5 1.5 0 0 1 1.5 1.5V20h-3.05a2.502 2.502 0 0 1-4.9 0H2v-1h4.05Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCarProfileSignParkingSmall;
