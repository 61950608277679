import type { SVGProps } from "react";
const SvgConstructionSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 5.5c0-.257.243-.5.5-.5s.5.243.5.5-.243.5-.5.5a.527.527 0 0 1-.5-.5Zm.5-1.5c-.81 0-1.5.69-1.5 1.5S11.69 7 12.5 7 14 6.31 14 5.5 13.31 4 12.5 4ZM7 6.5h-.36l-.114.342-.745 2.232-1.427-1.428-.708.708 1.88 1.878.459 3.68-2.432 4.864.894.448 2.5-5 .068-.136-.019-.15-.32-2.554.887.886.897 2.692-1.907 3.814.894.448 2-4 .093-.186-.066-.196-.33-.99 3.034 3.033-.587 1.76-3.815 1.908.448.894 4-2 .185-.092.065-.197.935-2.803 1.815-.908.098-.049.068-.086L17.24 13h1.47l1.856 3.248.868-.496-2-3.5L19.29 12h-2.53l-.15.188-1.932 2.414-1.902.95-.185.093-.06.18-1.988-1.99.947-4.737.053-.262-.19-.19-2-2-.146-.146H7ZM10.293 9 8 11.293 6.707 10 9 7.707 10.293 9Zm-2.5-1.5-.649.649.216-.649h.433Zm2.393 3.021L8.707 12l.986.986.493-2.465Zm8.278 5.165-1 2.5-.057.144-.13.086-3 2-.554-.832 2.87-1.914.943-2.356.928.372Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgConstructionSmall;
