import type { SVGProps } from "react";
const SvgDoorbellHandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 2H2v9h6.298l.429.428a1.504 1.504 0 0 0-.658 2.095 1.504 1.504 0 0 0-.705 2.124 1.501 1.501 0 0 0-.466 2.44l.002.002v.001l.004.004.016.016.074.073.364.362 2.016 2.014.01.01a5.282 5.282 0 0 0 2.783 1.38c.4.067.745.08.991.075a4.197 4.197 0 0 0 .378-.025l.025-.003h.008l.003-.001h.001L13.5 21.5l.074.494.178-.026.12-.134 4.5-5 .177-.197-.064-.258-1.346-5.38H22V2H2.5Zm14.36 8H21V3H3v7h4.298l-1.01-1.01A2.5 2.5 0 1 1 9 6.5c0 .286-.052.555-.14.803L11.627 10h2.457a1.5 1.5 0 0 1 1.415-1c.417 0 .762.16 1.018.438.15.164.261.357.344.562Zm-2.862 1h-1.346l1.347 1.313L13.998 11ZM8 6.53V6.5a1.5 1.5 0 1 0-2.247 1.3A1.503 1.503 0 0 1 8 6.53ZM10.012 6h9V5h-9v1Zm0 2h9V7h-9v1Zm7.438 8.363-4.19 4.657a4.416 4.416 0 0 1-.927-.057 4.283 4.283 0 0 1-2.257-1.116A2270.434 2270.434 0 0 0 7.7 17.474l-.074-.074-.017-.016-.003-.004h-.001a.501.501 0 0 1 .71-.71v.002l.354.353.707-.708-1.063-1.061a.503.503 0 0 1 .668-.746l1.105 1.1.705-.71-1.063-1.059.002-.002a1.553 1.553 0 0 0-.072-.067l-.637-.634a.503.503 0 0 1 .678-.739L11.5 14.2l.707-.707-1.77-1.772h-.001a1.429 1.429 0 0 0-.055-.053L6.899 8.187a.501.501 0 0 1 .708-.71l.005.006 6.538 6.375.85.829v-1.188l-.002-2.997a.5.5 0 0 1 .5-.502c.137 0 .215.042.282.115.083.09.168.252.235.51l1.435 5.738Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDoorbellHandSmall;
