import type { SVGProps } from "react";
const SvgWheelThermometerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3a9 9 0 0 0 0 18c.326 0 .635-.014.947-.047l.106.994A9.889 9.889 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c1.103 0 2.174.2 3.157.525l-.314.95A9.086 9.086 0 0 0 12 3Zm3 2.5a2.5 2.5 0 1 1 5 0v9.38a4 4 0 1 1-5 0V5.5ZM17.5 4A1.5 1.5 0 0 0 16 5.5v9.891l-.214.15a3 3 0 1 0 3.428 0L19 15.39V5.5A1.5 1.5 0 0 0 17.5 4ZM11 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm4.5 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm.5-1.937a2 2 0 1 0 1 0V7h-1v9.063ZM5 12c0-3.865 3.135-7 7-7 .657 0 1.282.07 1.867.232l.266-.964A8 8 0 0 0 12 4c-4.417 0-8 3.583-8 8s3.583 8 8 8l.15.002c.112.003.231.006.379-.003l-.058-.998a2.269 2.269 0 0 1-.245.002C12.17 19.002 12.105 19 12 19c-3.865 0-7-3.135-7-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelThermometerSmall;
