import type { SVGProps } from "react";
const SvgClipboardCrossSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 2H9v1H3v19h18V3h-6V2H9.5Zm.5 1.5V3h4v1h-4v-.5ZM9 4H4v17h16V4h-5v1H9V4Zm3 9.707-4.646 4.647-.708-.708L11.293 13 6.646 8.354l.708-.708L12 12.293l4.646-4.647.708.708L12.707 13l4.647 4.646-.708.708L12 13.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClipboardCrossSmall;
