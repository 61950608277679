import type { SVGProps } from "react";
const SvgFreightWagonLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 14.99H6V27H4v-1H3v3h1v-1h2v1.99h4.034a3.5 3.5 0 0 0 6.931 0h14.07a3.5 3.5 0 0 0 6.93 0H42V28h2v1h1v-3h-1v1h-2V14.99H6.5Zm9.451 15H11.05a2.5 2.5 0 0 0 4.902 0Zm16.781 1.278a2.5 2.5 0 0 1-.684-1.278h4.904a2.5 2.5 0 0 1-4.22 1.278ZM7 25.99v-10h34v10H7Zm0 1v2h34v-2H7ZM11 25v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Zm3 0v-8h-1v8h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonLarge;
