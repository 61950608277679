import type { SVGProps } from "react";
const SvgSkisSkiPolesLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 4.5A3.499 3.499 0 0 0 4.5 8v35.5h7V8c0-1.934-1.566-3.5-3.5-3.5Zm-2.5 26V22c0-1.382 1.118-2.5 2.5-2.5s2.5 1.118 2.5 2.5v8.5h-5Zm2.5-12c.98 0 1.865.402 2.5 1.05V8c0-1.382-1.118-2.5-2.5-2.5A2.499 2.499 0 0 0 5.5 8v11.55A3.49 3.49 0 0 1 8 18.5Zm-2.5 13v11h5v-11h-5ZM18 4.5A3.499 3.499 0 0 0 14.5 8v35.5h7V8c0-1.934-1.566-3.5-3.5-3.5Zm2.5 15.05V8c0-1.382-1.118-2.5-2.5-2.5A2.499 2.499 0 0 0 15.5 8v11.55A3.49 3.49 0 0 1 18 18.5c.98 0 1.865.402 2.5 1.05Zm-5 2.45c0-1.382 1.118-2.5 2.5-2.5s2.5 1.118 2.5 2.5v8.5h-5V22Zm0 20.5v-11h5v11h-5ZM28.47 5.827 35 23.554l6.53-17.727.94.346-1.135 3.079.823.274.474.158-.158.475-2 6-.158.474-.475-.158-1.04-.347L35.533 25l5.675 15.404 2.634-.878.316.948-2.604.869.915 2.484-.938.346-.926-2.514-2.447.815-.316-.948 2.417-.806L35 26.446 29.741 40.72l2.417.806-.316.948-2.447-.815-.926 2.514-.938-.346.915-2.484-2.604-.869.316-.948 2.634.878L34.467 25 31.2 16.128l-1.04.347-.475.158-.158-.474-2-6-.158-.475.474-.158.823-.274-1.134-3.08.938-.345Zm10.677 9.362.536.179 1.684-5.052-.378-.126-1.842 5Zm-8.83.179.536-.179-1.842-4.999-.378.127 1.684 5.051Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSkisSkiPolesLarge;
