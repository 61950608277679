import type { SVGProps } from "react";
const SvgSunriseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 7V4h1v3h-1Zm-5.915-.296 2.268 2.258.706-.709L6.79 5.995l-.706.709ZM12.5 9A3.49 3.49 0 0 0 9 12.5c0 .828.252 1.619.696 2.195l-.792.61C8.308 14.531 8 13.518 8 12.5A4.49 4.49 0 0 1 12.5 8a4.49 4.49 0 0 1 4.5 4.5c0 1.073-.403 2.048-1.01 2.809l-.781-.624c.484-.607.79-1.366.79-2.185A3.49 3.49 0 0 0 12.5 9ZM7 13H4v-1h3v1Zm11.3-6.912-2.265 2.25.705.71 2.266-2.25-.705-.71ZM18 12h3v1h-3v-1ZM5 17h13.993v-1H5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSunriseSmall;
