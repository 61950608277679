import type { SVGProps } from "react";
const SvgMountainSunLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.174 7.21a3.332 3.332 0 0 0-4.02 1.41 2.498 2.498 0 0 0-2.983.739h-.001a2.5 2.5 0 0 0-.528 1.43c-.476.128-.86.428-1.133.808-.36.501-.535 1.15-.507 1.785.028.638.263 1.299.764 1.802.508.51 1.247.816 2.2.816h.883l-4.795 8.395-1.178-.982a8 8 0 0 0-6.164-9.226 8.001 8.001 0 0 0-4.647 15.258l-5 8.808-.424.747h38.755l-.47-.762L29.218 16h3.745c.943 0 1.686-.307 2.207-.807a2.73 2.73 0 0 0 .823-1.787c.043-.636-.13-1.29-.53-1.788a2.1 2.1 0 0 0-1.168-.738 3.332 3.332 0 0 0-2.12-3.67ZM28 16h.044l4.67 7.578-1.278 1.279-2.659-1.773-.277-.185-.277.185-2.696 1.797-1.784-1.427L28.001 16Zm.832-6.494a2.33 2.33 0 0 1 4.361 1.64l-.262.703.75-.03c.467-.018.791.161 1.003.425.221.276.34.673.31 1.094a1.73 1.73 0 0 1-.517 1.134c-.312.3-.798.528-1.514.528h-6.997c-.73 0-1.2-.229-1.492-.522-.298-.3-.454-.709-.473-1.14-.019-.435.104-.857.32-1.158.21-.292.496-.46.845-.46h.588l-.095-.581a1.496 1.496 0 0 1 2.397-1.417l.536.42.24-.636Zm-14.334 5.658a7.001 7.001 0 0 1 5.473 7.495l-3.65-3.043-.46-.383-.296.52-5.001 8.812a7 7 0 0 1 3.934-13.401Zm7.053 10.113-1.985 3.475.868.496 2.807-4.915 1.947 1.558.285.228.304-.203 2.723-1.815 2.723 1.815.34.227.29-.29 1.401-1.4L41.604 38h-7.586l-3.656-3.844-.148-.156h-6.057l-4.87-3.41-.45-.314-.271.476L14.424 38H6.359l9.78-17.233 5.412 4.51ZM29.785 35l2.853 3H15.575l3.587-6.276 4.551 3.186.13.09h5.942Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMountainSunLarge;
