import type { SVGProps } from "react";
const SvgTicketMachineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 2H6v16h1v4h10v-4h1V2H6.5ZM16 18H8v3h8v-3Zm-9-1h10V3H7v14Zm9-12H8V4h8v1Zm-1 2v3h1V7h-1ZM8 7h6v5H8V7Zm1 1v3h4V8H9Zm-.5 5H8v2h6v-2H8.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketMachineSmall;
