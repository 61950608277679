import type { SVGProps } from "react";
const SvgIr76 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-76_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-76_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.293-8.132v1.962a45.8 45.8 0 0 0-.562.64c-.432.496-.862 1.12-1.29 1.875a12.805 12.805 0 0 0-.996 2.16c-.5 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.167-1.836.451-2.896.29-1.06.689-2.12 1.195-3.18.722-1.497 1.455-2.612 2.199-3.345h-5.458V4.234h7.76Zm9.105 2.887h-2.2c-.205-.891-.712-1.337-1.518-1.337-.855 0-1.461.525-1.82 1.574-.147.428-.266 1.074-.356 1.938.348-.469.718-.806 1.108-1.012.395-.206.87-.309 1.423-.309 1.087 0 1.96.385 2.619 1.155.627.728.941 1.64.941 2.737 0 1.303-.422 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.429-.514-3.188-1.542-.76-1.034-1.14-2.473-1.14-4.319 0-1.972.407-3.517 1.219-4.635.786-1.081 1.859-1.622 3.22-1.622 1.28 0 2.262.43 2.942 1.29.39.495.64 1.12.751 1.874Zm-3.916 2.65c-.554 0-.994.232-1.321.696-.295.411-.443.902-.443 1.471 0 .565.15 1.058.45 1.48.328.469.776.704 1.345.704.554 0 1-.224 1.337-.673.317-.422.475-.92.475-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Z"
      />
    </g>
    <defs>
      <clipPath id="ir-76_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr76;
