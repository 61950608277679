import type { SVGProps } from "react";
const SvgTargetMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.957 11.75H30v-1h-4.25l-.5-.5V6h-1v5.043l-2 2V9h-1v5.043l-.427.426A8.717 8.717 0 0 0 15 12.25a8.75 8.75 0 1 0 6.53 2.926l.427-.426H27v-1h-4.043l2-2ZM15 13.25c1.96 0 3.75.728 5.115 1.928l-2.484 2.484a4.25 4.25 0 1 0 .707.707l2.484-2.484A7.751 7.751 0 1 1 15 13.25Zm0 4.5c.717 0 1.38.232 1.918.625l-1.461 1.461a1.25 1.25 0 1 0 .707.707l1.46-1.46A3.25 3.25 0 1 1 15 17.75ZM14.75 21a.25.25 0 0 1 .073-.177l.354.354A.25.25 0 0 1 14.75 21Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTargetMedium;
