import type { SVGProps } from "react";
const SvgControlsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.75 7.25c-1.471 0-2.5 1.029-2.5 2.5 0 1.472 1.029 2.5 2.5 2.5s2.5-1.028 2.5-2.5c0-1.471-1.029-2.5-2.5-2.5Zm-9.75 3h6.281c.222 1.763 1.616 3 3.469 3 1.853 0 3.247-1.237 3.469-3H33v-1H16.219c-.222-1.762-1.616-3-3.469-3-1.853 0-3.247 1.238-3.469 3H3v1Zm20.25 6c-1.42 0-2.5 1.08-2.5 2.5s1.08 2.5 2.5 2.5 2.5-1.08 2.5-2.5-1.08-2.5-2.5-2.5Zm-3.467 2c.234-1.725 1.665-3 3.467-3s3.233 1.275 3.467 3H33v1h-6.284c-.233 1.724-1.664 3-3.466 3-1.802 0-3.233-1.276-3.466-3H3v-1h16.783Zm-9.533 9.5c0-1.445 1.054-2.5 2.5-2.5s2.5 1.055 2.5 2.5-1.054 2.5-2.5 2.5-2.5-1.055-2.5-2.5Zm2.5-3.5c-1.828 0-3.24 1.257-3.468 3H3v1h6.282c.228 1.743 1.64 3 3.468 3s3.24-1.257 3.468-3H33v-1H16.218c-.228-1.743-1.64-3-3.468-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgControlsMedium;
