import type { SVGProps } from "react";
const SvgQrcodeDisabledTwoTicketsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m21.354 20.646-18-18L3 3l-.354.354.354.353V6h1V4.707l3 3V10h1V8.707l1.29 1.291h-.292v1H7v1h2v-1h1v-.29l1 1V17h5.293l1 1-2.292.001v1h-1v2h1v-1h1v-1.003h1v2h2v-1h-1v-1.29l2 2v.29h.29l.355.356.708-.707ZM15.293 16l-1.003-1.003H13v-1.29l-1-1V16h3.293ZM3 7.996h2.001v-1H3v1ZM10 5H9V4h1v1Zm1.001 0h1V3h-1v2Zm4-.002h-2v-1h2v-1h2v2h-1v-1h-1v1ZM3 17h1v-2H3v2Zm1-6.002H3v-1h1V9h1.001v1H4v.998Zm-1 2h1V12h1.001v-1H4v.996H3v1.001ZM4 14h1.001v-1H4v1Zm17.001-1h-1v.998h-2v1h2V14h1v-1Zm-1-2h1v1h-1v.998h-2v-1h2V11ZM15 9h1v.998h1v-1h-1V7h-1v2Zm6.001 8h-1v-2h1v2Zm-7.999 4.001h-1.001v-1h1.001v1Zm-6.003 0h1V19H7v2.002Zm2-4.003h1l.002 1h1v1H9V18H8v-1h1v-.002Zm3.002 2h2.001v-1h-2.001v1Zm-5.002-4h1V14H10v-1H8v.998H7v1Zm1 2H7v-1h1V15h2v1H8v.998Zm12.002-8h1v-2h-2v1h1v1Zm-9 12.003H8.998v-1H11v1Zm7-10V9l-1-.003v-1h2v2h1v1l-2 .002Zm-3.005.995H16V13l1 1v-3.003h-3.003l1 1ZM13 9.998h-.002L12 9v-.002h1V8h1v2h-1v-.002ZM11 8v-.001h2v-1h-3l1 1ZM7 3.998V4L6 3v-.002h3v1H7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeDisabledTwoTicketsSmall;
