import type { SVGProps } from "react";
const SvgBusStopMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.748 3.25h22v4h-1.496v25.752h-1V7.25H13.25v25.752h-1V7.25h-1.502v-4Zm1 1v2h20v-2h-20Zm-8.498 6.5h7v8.5h-3V33h-1V19.25h-3v-8.5Zm1 1v6.5h5v-6.5h-5Zm19 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-1 4h-.5v8.5h1.5v7.5h4v-7.5h1.5v-8.5h-6.5Zm.5 7.5v-6.5h5v6.5h-1.5v7.5h-2v-7.5h-1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBusStopMedium;
