import type { SVGProps } from "react";
const SvgCertificateRibbonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.25 12a7.75 7.75 0 1 1 15.5 0 7.75 7.75 0 0 1-15.5 0ZM18 3.25a8.75 8.75 0 0 0-5.75 15.346v14.111l.854-.854 2.646-2.646 2.646 2.646.854.854v-5.5l1-1 2.646 2.646.854.854V18.595A8.75 8.75 0 0 0 18 3.25Zm4.75 16.1a8.698 8.698 0 0 1-3.5 1.312v5.13l.646-.646.354-.354.354.354 2.146 2.146V19.35Zm-9.5 10.943V19.35a8.709 8.709 0 0 0 5 1.397v9.546l-2.146-2.147-.354-.353-.354.353-2.146 2.147Zm0-18.293a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0ZM18 6.25a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCertificateRibbonMedium;
