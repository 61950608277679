import type { SVGProps } from "react";
const SvgQrcodeDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m20.398 21.005.249.249.248-.25H21V20.9l.354-.353-17.9-17.9-.35.348H3v.106l-.252.253.252.252v1.397h1v-.397l1 1V6h.395L6 6.606v1.397h1V9H5V7H4V6H3v2h1v1h1v1h2v-.999h1v-.395l1 1.001V15h5.393l1.002 1.002-2.395.002v1h-1v2h1v-1h1v-1.002h1v2h2v-1h-1v-1.395l2 2v.395h.395l1 1H19v1h1v.003h.398ZM13.393 14l-1-1H11v-1.393l-1-1V14h3.393ZM7.394 8l-.395-.395V8h.395Zm-.395-4.415v-.591H10v1H7.41L7 3.586Zm7.001 7V10h-.586l-1-1H15v2.586l-1-1ZM9 5.001v.002h-.583l.998.998H11v-1h1v.002h1v2h1v-1l3-.002v-1h1v-1h-1v-.998h-1v1.998h-1v-.998h-1v1h-1v-2h-1v.998h-1v1H9Zm11 2.002h1V6.002h-1v1.001Zm-2 5v.998h-1.585L16 12.586v-.585h2v-.998h1v1h-1Zm1 2v1h1v1.583l-1-1v-.582h-.582L18 14.586v-1.582h3v2h-1v-1h-1Zm-2-6.002h1v1h-1v.002h-1v-2h1v.998Zm0 13.004h1v-2h-1v2Zm3-16.002h1v-2h-1v-.002h-2v1h2v1.002Zm1 12.583-.584-.584H21v.584Zm-1-5.582h1v-2.001h-1v-1l1-.002v-1h-2v2.002h1v2.001Zm-1-5.003h-2v-1h2v1Zm-8 14.001v-1.001h2v1.001h-2Zm1-12.999v-2h-1v.998h-.585l1.002 1.002H12ZM9 19.002h1v-.997h1v2h-1v-.003H9v-1Zm6 .003h-1v2h1v-.003h1v-1h-1v-.997Zm-9-3.001v-1l2-.002v1h1v1H7v-.998H6Zm4-.002v1h2v-1h-2Zm8-5.001h-2v-1h2v1Zm-15 7h1v-1H3v1Zm3-5H5v-1h1v-.997h2v1H6v.997Zm-2-1h1v-1H4v-2H3v2h1v1Zm0 0H3v2.001h1v1l-1 .002v1h2v-2.002H4V12Zm11-3.999h-1V7.001h1v1.001ZM7 18.003H5v-1h2v1Zm0 1v-.999h2v1H8v1.998H7v-.997H6v-1h1v-.001Zm-3 1.002h2v1H4v-.003H3v-2h1v1.003Zm4-6.001H6v-1h2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeDisabledSmall;
