import type { SVGProps } from "react";
const SvgCloudDenseFogSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.503 10.602a2.103 2.103 0 0 0-1.19-.746c.124-.804-.005-1.532-.323-2.14a3.276 3.276 0 0 0-1.794-1.548c-1.363-.495-3.042-.116-3.988 1.422-1.516-.719-3.43.326-3.522 2.176-.481.129-.87.432-1.144.815-.36.504-.535 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.944 0 1.689-.31 2.21-.811a2.744 2.744 0 0 0 .822-1.793c.043-.639-.13-1.295-.529-1.795Zm-6.645-2.1c.635-1.44 1.948-1.774 2.997-1.394.53.192.983.566 1.25 1.073.261.5.357 1.165.116 1.977l-.2.67.7-.028c.465-.019.789.161 1 .426.223.279.342.679.313 1.103-.028.42-.2.834-.519 1.14-.313.302-.799.531-1.515.531H8c-.73 0-1.2-.23-1.492-.524-.298-.3-.455-.713-.474-1.148-.019-.437.104-.862.321-1.165.21-.294.497-.463.845-.463h.563l-.066-.56c-.18-1.5 1.419-2.245 2.386-1.453l.51.417.265-.602ZM5 17h12.993v-1H5v1Zm12.993 4H5v-1h12.993v1ZM6 19h10.993v-1H6v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudDenseFogSmall;
