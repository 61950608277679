import type { SVGProps } from "react";
const SvgEv10Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-10-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm5.914 0v11.6h-2.317V8.177h-2.73V6.495c.566-.016 1.026-.075 1.38-.175.36-.1.689-.265.985-.492.428-.328.716-.88.865-1.658h1.817Zm7.064-.278c.883 0 1.66.27 2.332.81.667.539 1.15 1.37 1.452 2.49.228.858.342 1.789.342 2.794 0 1.666-.297 3.036-.889 4.11-.35.624-.807 1.105-1.373 1.444a3.567 3.567 0 0 1-1.88.508c-.947 0-1.756-.286-2.428-.857-1.196-1.016-1.793-2.75-1.793-5.205 0-1.746.312-3.148.936-4.206a3.782 3.782 0 0 1 1.388-1.396 3.8 3.8 0 0 1 1.913-.492Zm-.088 1.96c-.761 0-1.28.439-1.555 1.317-.217.698-.325 1.677-.325 2.936 0 1.11.111 2.002.333 2.674.286.873.815 1.309 1.587 1.309.64 0 1.116-.339 1.428-1.016.312-.677.468-1.711.468-3.102 0-1.185-.11-2.119-.333-2.801-.285-.878-.82-1.317-1.603-1.317Z"
    />
  </svg>
);
export default SvgEv10Negative;
