import type { SVGProps } from "react";
const SvgPreviousSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.001 5.5v-.921l-.773.502-5 3.25-5 3.25-.644.419.644.42 5 3.25 5 3.25.773.501V5.5Zm-5.228 3.67 4.228-2.749V17.58l-4.228-2.748L10.418 12l4.356-2.83ZM4.5 5H4v14h4V5H4.5ZM5 18V6h2v12H5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPreviousSmall;
