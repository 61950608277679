import type { SVGProps } from "react";
const SvgIr81Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-81-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-81-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915ZM38.35 9.59c-.539-.186-.953-.489-1.243-.91-.284-.422-.427-.934-.427-1.535 0-1.002.412-1.801 1.234-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.734.532 1.1 1.31 1.1 2.333 0 1.176-.45 2.112-1.352 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.194-1.547-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.924 2.924 0 0 1 1.067-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.317.285.738.427 1.266.427.527 0 .944-.142 1.25-.427.305-.285.458-.672.458-1.163 0-.48-.155-.854-.466-1.123-.306-.269-.733-.403-1.282-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.358.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.37.354.846.53 1.432.53.664 0 1.163-.195 1.495-.585.322-.374.482-.807.482-1.297 0-.56-.184-1.018-.553-1.377-.354-.348-.841-.522-1.464-.522Zm10.845-6.225v11.565h-2.31v-7.57h-2.721V6.552c.565-.016 1.023-.074 1.377-.174.358-.1.685-.264.98-.49.428-.328.715-.879.863-1.654h1.811Z"
      />
    </g>
    <defs>
      <clipPath id="ir-81-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr81Negative;
