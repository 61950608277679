import type { SVGProps } from "react";
const SvgEyeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m21.271 9.2.54 2.933c.63-.093 1.273-.143 1.93-.143.738 0 1.464.065 2.177.184l.592-2.923.98.198-.592 2.925c1.328.32 2.602.822 3.808 1.443l1.425-2.625.879.476-1.426 2.628a25.712 25.712 0 0 1 3.31 2.293l1.922-2.3.767.642-1.922 2.3.023.018a35.99 35.99 0 0 1 2.85 2.768l2.231-2.009.67.744-2.232 2.008a36.49 36.49 0 0 1 2.7 3.45l.196.286-.201.283c-.91 1.283-3.16 4.072-6.312 6.546-3.148 2.47-7.248 4.665-11.846 4.665-4.603 0-8.638-2.253-11.69-4.75-3.057-2.502-5.18-5.292-5.975-6.47l-.193-.288.2-.282.09-.125a50.934 50.934 0 0 1 2.465-3.24L6.345 18.88l.65-.76 2.29 1.955a34.429 34.429 0 0 1 2.8-2.861l-1.972-2.266.754-.656 1.972 2.264a23.613 23.613 0 0 1 3.281-2.339l-1.439-2.627.878-.48 1.437 2.625a15.939 15.939 0 0 1 3.833-1.419l-.541-2.935.983-.182Zm-8.588 21.266c-2.735-2.24-4.702-4.722-5.583-5.972.976-1.372 2.955-4.104 5.687-6.566 2.944-2.654 6.698-4.938 10.953-4.938 4.257 0 8.19 2.333 11.29 5.016 2.855 2.471 4.943 5.189 5.848 6.477-.98 1.327-3.068 3.826-5.909 6.055-3.062 2.402-6.947 4.452-11.229 4.452-4.276 0-8.091-2.097-11.057-4.524Zm4.307-6.476a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-.264 5.009a2.54 2.54 0 0 1 .23-.009h.004a3 3 0 1 1-3 3v-.003c0-.093.004-.186.013-.278l.04.023c.298.17.635.258.977.258h.003a2.01 2.01 0 0 0 1.997-1.997v-.001a1.97 1.97 0 0 0-.256-.98L23.726 21Zm-3.58 1.834c.036-.112.084-.219.145-.32l.43-.716.428.716a.98.98 0 0 0 1.545.181 1.01 1.01 0 0 0 .296-.707.97.97 0 0 0-.475-.837l-.904-.536.986-.363.19-.07.007-.003.007-.002a3.589 3.589 0 0 1 1.16-.186 4 4 0 1 1-4.001 4.002l.185-1.159Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEyeLarge;
