import type { SVGProps } from "react";
const SvgIc85Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-85-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-85-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6ZM38.35 9.59c-.539-.186-.953-.489-1.243-.91-.284-.422-.427-.934-.427-1.535 0-1.002.412-1.801 1.234-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.734.532 1.1 1.31 1.1 2.333 0 1.176-.45 2.112-1.352 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.194-1.547-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.925 2.925 0 0 1 1.067-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.317.285.738.427 1.266.427.527 0 .944-.142 1.25-.427.305-.285.458-.672.458-1.163 0-.48-.155-.854-.466-1.123-.306-.269-.733-.403-1.282-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.358.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.37.354.846.53 1.432.53.664 0 1.163-.195 1.495-.585.322-.374.482-.807.482-1.297 0-.56-.184-1.018-.553-1.377-.354-.348-.841-.522-1.464-.522ZM53.11 4.234V6.29h-4.66l-.498 2.602c.56-.59 1.274-.886 2.144-.886.854 0 1.598.23 2.23.688.94.691 1.409 1.74 1.409 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.208-.328-2.951-.982-.744-.659-1.137-1.547-1.179-2.665h2.27c.037.406.17.751.396 1.036.353.448.844.672 1.471.672.607 0 1.087-.218 1.44-.656.343-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.358-.401-.836-.602-1.431-.602-.728 0-1.295.327-1.701.981l-2.065-.031 1.1-6.447h6.352Z"
      />
    </g>
    <defs>
      <clipPath id="ic-85-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc85Negative;
