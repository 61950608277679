import type { SVGProps } from "react";
const SvgLocationPinCameraSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 9.006A7.003 7.003 0 0 1 12 2c3.866 0 7 3.137 7 7.006v.005c-.01 1.173-.38 2.213-.934 3.183l-.004.008-.005.008-5.614 9.053-.425.686-.425-.686L5.98 12.21l-.004-.007c-.567-.946-.928-1.965-.976-3.177v-.02Zm1-.01c.041 1.014.34 1.868.832 2.69l5.186 8.365 5.184-8.36c.492-.864.789-1.734.798-2.687A6.003 6.003 0 0 0 12 3a6.003 6.003 0 0 0-6 5.996Zm6 .416a.032.032 0 1 0 0-.064.032.032 0 0 0 0 .064Zm-.968-.032a.968.968 0 1 1 1.936 0 .968.968 0 0 1-1.936 0ZM11 6h-.309l-.138.276L10.19 7H8v5h8V7h-2.191l-.362-.724L13.31 6H11Zm-.053 1.724L11.31 7h1.382l.362.724.138.276H15v3H9V8h1.809l.138-.276Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinCameraSmall;
