import type { SVGProps } from "react";
const SvgFolderInfoMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.25 9.25h-.309l-.138.276-1.362 2.724H6.25v14.5h23.5v-.5l-.008-16.5v-.5H23.25Zm-1.053 3.724 1.362-2.724h5.184l.007 15.5H7.25v-12.5h14.809l.138-.276ZM16.5 19.25h1.75v3.5H16.5v1H21v-1h-1.75v-4.5H16.5v1Zm1.75-2.75V15h1v1.5h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFolderInfoMedium;
