import type { SVGProps } from "react";
const SvgLocationPinSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.984 3.014a6 6 0 0 0-6 6v.02c.042 1.04.351 1.913.838 2.724l.006.01 4.75 7.498.422.667.423-.667 4.75-7.498.006-.01.006-.01c.472-.828.79-1.72.8-2.73v-.004a6 6 0 0 0-6-6Zm-4.308 8.224c-.41-.685-.657-1.393-.692-2.233a5 5 0 0 1 10 .007c-.007.788-.252 1.509-.662 2.23L12 18.065l-4.324-6.826Zm1.316-2.231a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSmall;
