import type { SVGProps } from "react";
const SvgSaMo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.728 13.709H2.273V2.296h21.455v11.413ZM25 15H1V1h24v14Zm-7.5-7.5h-9v-1h9v1Zm0 2.5h-9V9h9v1ZM13 12.5l5.786-.692V4.885L13 3.5 7.214 4.885v.043h-.246l-.35.008c-1.568.056-2.475.609-2.475 2.1 0 1.851 1.413 4.035 3.071 4.035v.737L13 12.5Zm-5.786-2.429V5.928l-.459.004-.1.003c-1.1.04-1.512.29-1.512 1.101 0 1.314.998 2.907 1.957 3.028l.114.007Zm11.572-5.143h.246l.35.008c1.568.056 2.475.609 2.475 2.1 0 1.792-1.323 3.895-2.911 4.029l-.16.006v-1c.992 0 2.071-1.668 2.071-3.035 0-.766-.366-1.032-1.334-1.092l-.278-.012-.224-.003h-.235v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaMo;
