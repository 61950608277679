import type { SVGProps } from "react";
const SvgEuropeFlagMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 4.25C10.406 4.25 4.25 10.406 4.25 18S10.406 31.75 18 31.75 31.75 25.594 31.75 18 25.594 4.25 18 4.25ZM3.25 18C3.25 9.854 9.854 3.25 18 3.25S32.75 9.854 32.75 18 26.146 32.75 18 32.75 3.25 26.146 3.25 18Zm12.73-8.565 1.24.413-.815 1.224.832.555.763-1.145.762 1.145.833-.555-.816-1.224 1.24-.413-.316-.949-1.203.401V7.684h-1v1.203l-1.204-.4-.316.948Zm7.24 2.88-1.24-.414.316-.949 1.204.401V10.15h1v1.203l1.203-.401.316.949-1.24.413.816 1.224-.832.555L24 12.948l-.763 1.145-.832-.555.816-1.224Zm1.01 5.539 1.24.413-.815 1.224.832.555.763-1.145.763 1.145.832-.555-.816-1.224 1.24-.413-.316-.949-1.203.401v-1.203h-1v1.203l-1.204-.4-.316.948Zm-1.01 6.366-1.24-.413.316-.949 1.204.401v-1.203h1v1.203l1.203-.4.316.948-1.24.413.816 1.224-.832.555L24 24.855l-.763 1.144-.832-.555.816-1.224Zm-7.24 2.053 1.24.413-.815 1.224.832.555L18 27.32l.762 1.145.833-.555-.816-1.224 1.24-.413-.316-.949-1.203.401v-1.203h-1v1.203l-1.204-.4-.316.948Zm-4.76-2.053-1.24-.413.316-.949 1.204.401v-1.203h1v1.203l1.203-.4.316.948-1.24.413.816 1.224-.833.555L12 24.855l-.763 1.144-.832-.555.816-1.224Zm-3.49-6.366 1.24.413-.815 1.224.832.555.763-1.145.762 1.145.833-.555-.816-1.224 1.24-.413-.316-.949-1.203.401v-1.203h-1v1.203l-1.204-.4-.316.948Zm3.49-5.54-1.24-.413.316-.949 1.204.401V10.15h1v1.203l1.203-.401.316.949-1.24.413.816 1.224-.833.555L12 12.948l-.763 1.145-.832-.555.816-1.224Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEuropeFlagMedium;
