import type { SVGProps } from "react";
const SvgWalkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.25 7.25c-.523 0-1 .476-1 1s.476 1 1 1 1-.476 1-1-.477-1-1-1Zm-2 1c0-1.076.924-2 2-2s2 .924 2 2c0 1.077-.925 2-2 2s-2-.923-2-2Zm1 5v6.5h2v-6.5h-2Zm-1 .434v10.914l-2.916 4.374.832.555 3-4.5.084-.126V20.75h2.293l2.707 2.707V30h1v-6.958l-.146-.146-2.854-2.854v-6.085l2.146 2.146.147.146H25.5v-1h-3.543l-2.853-2.853-.147-.147H15.6l-.126.085-4.5 3-.223.148V21h1v-4.983l3.5-2.333Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalkMedium;
