import type { SVGProps } from "react";
const SvgWifiDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.096 8.803-3.2-3.2.708-.707 22.5 22.5-.707.708-6.719-6.719a6.108 6.108 0 0 0-2.677-.634 6.207 6.207 0 0 0-4.521 1.956l-.726-.688a7.207 7.207 0 0 1 5.247-2.268c.39 0 .77.033 1.139.095l-3.35-3.349a10.733 10.733 0 0 0-5.57 3.114l-.725-.688a11.735 11.735 0 0 1 5.466-3.255l-2.752-2.752a15.26 15.26 0 0 0-5.257 3.593l-.726-.688a16.234 16.234 0 0 1 5.221-3.667L8.835 9.542a19.824 19.824 0 0 0-5.142 3.87l-.725-.688a20.774 20.774 0 0 1 5.128-3.921ZM18 25.25a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5ZM15.25 27a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM10.845 7.55c2.23-.825 4.632-1.3 7.155-1.3 5.924 0 11.253 2.496 15.033 6.473l-.725.69C28.708 9.622 23.636 7.25 18 7.25c-2.396 0-4.68.451-6.809 1.238l-.346-.938ZM18 10.75c-1.2 0-2.366.137-3.487.383l.215.977A15.256 15.256 0 0 1 18 11.75c4.352 0 8.268 1.831 11.048 4.759l.725-.689c-2.96-3.117-7.134-5.07-11.773-5.07Zm.761 5.546c2.76.193 5.231 1.42 7.027 3.308l.725-.69a11.707 11.707 0 0 0-7.682-3.616l-.07.998Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWifiDisabledMedium;
