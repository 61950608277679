import type { SVGProps } from "react";
const SvgSaVi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.801 1H1l1.84 14h3.341l7.801-14h-3.04L5.141 11.89h-.04L3.801 1Zm14.6 0h-2.922l-2.94 14h2.92L18.4 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVi;
