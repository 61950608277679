import type { SVGProps } from "react";
const SvgPaperAeroplaneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m29.836 5.885-.088.913-2.25 23.25-.084.862-.705-.504-7.091-5.065-2.68 4.882L16 31.932V22.89L4.379 19.985l-1.288-.322 1.18-.608 24.75-12.75.815-.42ZM17 23.471l1.798 1.285L17 28.032v-4.561Zm.223-1.07 11.37-14.044-2.007 20.732-9.363-6.688Zm-.911-.464-10.403-2.6L27.366 8.282 16.312 21.937Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPaperAeroplaneMedium;
