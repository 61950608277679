import type { SVGProps } from "react";
const SvgSaBi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.002 1h-2.92L13.14 15h2.92l2.942-14ZM6.522 6.656H5.421l.74-3.45h.981c1.06 0 2.34.06 2.34 1.404 0 1.645-1.54 2.046-2.96 2.046Zm-1.421 6.138h-.96l.84-4.05h.9c1.5 0 2.861.219 2.861 1.684 0 2.165-1.921 2.366-3.641 2.366ZM8.062 1H3.921L1 15h3.981c2.081 0 6.562-.32 6.562-4.332 0-1.505-.681-2.708-2.241-2.949v-.04c1.62-.26 2.981-1.624 2.981-3.329 0-2.548-2.041-3.35-4.221-3.35Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaBi;
