import type { SVGProps } from "react";
const SvgPictureLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 4h40v40H4V4Zm1 1v31h1.226l6.852-10.768.304-.477.436.359 8.172 6.73 9.656-9.697.427-.43.34.502L41.766 36H43V5H5Zm0 38v-6h38v6H5Zm29.588-15.795-2.661-3.923-3.711 3.727 1.284 1.284 1.646-1.647.354-.353.354.353 1.646 1.647 1.088-1.088Zm.572.842-1.306 1.307-.354.353-.354-.353-1.646-1.647-1.646 1.647-.354.353-.354-.353-1.636-1.636-5.132 5.154L19.264 36h21.293l-5.397-7.953Zm-21.542-1.802 7.664 6.311L17.852 36H7.411l6.207-9.755ZM13 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm2.5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM11 15H8v-1h3v1ZM9.54 9.174l2.276 2.277.707-.708-2.277-2.277-.707.708ZM15 10V7h1v3h-1Zm5.806-1.443-2.264 2.238.703.71 2.264-2.237-.703-.711ZM20 14h3v1h-3v-1Zm1.524 5.743-2.266-2.257-.706.709 2.266 2.256.706-.708ZM15 22v-3h1v3h-1Zm-3.196-4.448-2.262 2.261.707.707 2.262-2.261-.707-.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictureLarge;
