import type { SVGProps } from "react";
const SvgFogMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.5 7.25H24v-1h-7.5v1Zm-4.5 3h13.5v-1H12v1Zm13.5 3h-15v-1h15v1Zm-16.5 3h4.5v-1H9v1Zm19.5 0H15v-1h13.5v1Zm-18 3.015 18-.015v-1l-18 .015v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFogMedium;
