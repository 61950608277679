import type { SVGProps } from "react";
const SvgThreeGearsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.194 4.505A5.466 5.466 0 0 1 12 4.022V2h1v2.022c.64.058 1.248.225 1.806.483l1.01-1.75.867.5-1.011 1.751c.512.362.96.81 1.322 1.322l1.751-1.011.5.866-1.75 1.01A5.46 5.46 0 0 1 17.977 9H20v1h-2.023a5.465 5.465 0 0 1-.483 1.806l1.751 1.011-.5.866-1.751-1.011a5.55 5.55 0 0 1-1.322 1.322l1.01 1.751-.865.5-1.01-1.75a5.467 5.467 0 0 1-1.807.483V17h-1v-2.022a5.464 5.464 0 0 1-1.806-.483l-1.011 1.75-.866-.5 1.011-1.751a5.531 5.531 0 0 1-1.322-1.322l-1.751 1.011-.5-.866 1.75-1.01A5.466 5.466 0 0 1 7.022 10H5V9h2.022c.058-.64.225-1.248.483-1.806l-1.75-1.011.5-.866 1.751 1.011c.362-.512.81-.96 1.322-1.322L8.317 3.255l.866-.5 1.01 1.75ZM12.5 5a4.5 4.5 0 0 1 4.472 4H14.95a2.5 2.5 0 0 0-3.242-1.872l-1.012-1.752A4.485 4.485 0 0 1 12.5 5Zm-1.658 2.629L9.831 5.877A4.494 4.494 0 0 0 8 9.5c0 1.486.72 2.804 1.83 3.623l1.012-1.752A2.494 2.494 0 0 1 10 9.5c0-.745.325-1.413.842-1.871Zm.866 4.243-1.012 1.752A4.5 4.5 0 0 0 16.972 10H14.95a2.5 2.5 0 0 1-3.242 1.872Zm-9.758 4.18.05-.005V14h1v2.022a5.48 5.48 0 0 1 1.809.48l1.008-1.747.866.5L5.675 17c.514.362.963.81 1.325 1.325l1.745-1.008.5.866-1.746 1.008A5.49 5.49 0 0 1 7.978 21H10v1H7.953l-.005.05L7.45 22H4.945a3.305 3.305 0 0 1-.028.121l-.97-.242a1.478 1.478 0 0 0-1.826-1.826l-.242-.97A2.474 2.474 0 0 1 4.952 21h2.02A4.479 4.479 0 0 0 2.5 17c-.084 0-.143.008-.226.02-.057.008-.127.018-.225.027l-.099-.995Zm16.233-1.297L19.19 16.5a5.492 5.492 0 0 1 1.81-.478V14h1v2.022l.042.004a.495.495 0 0 1 .053.008l.015.003-.012-.002a.513.513 0 0 0-.051-.008l-.094.996A4.634 4.634 0 0 0 21.5 17c-.644 0-1.254.133-1.806.372l1.012 1.753a2.508 2.508 0 0 1 1.415-.042l-.242.97a1.478 1.478 0 0 0-1.826 1.826l-.97.242a2.488 2.488 0 0 1 .755-2.5l-1.011-1.75A4.483 4.483 0 0 0 17 21.5c0 .084.008.143.02.226.008.057.018.127.027.225l-.995.098a1.59 1.59 0 0 0-.006-.049H14v-1h2.022a5.49 5.49 0 0 1 .48-1.809l-1.747-1.008.5-.866L17 18.325c.362-.514.81-.963 1.324-1.325l-1.007-1.745.866-.5ZM11 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgThreeGearsSmall;
