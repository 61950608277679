import type { SVGProps } from "react";
const SvgArrowStartLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.396 12.396-5.985 5.988-.352.353.352.353 5.985 6.012.708-.705-5.136-5.16H25.5v-1H10.972l5.132-5.134zM30 13.5V24h-1V13.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowStartLeftMedium;
