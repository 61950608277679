import type { SVGProps } from "react";
const SvgSmartwatchSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2h8v4h.5A1.5 1.5 0 0 1 18 7.5V11h1v2h-1v3.5a1.5 1.5 0 0 1-1.5 1.5H16v4H8v-4h-.5A1.5 1.5 0 0 1 6 16.5v-9A1.5 1.5 0 0 1 7.5 6H8V2Zm1 16v3h6v-3H9Zm6-15v3H9V3h6ZM7.5 7a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-9ZM12 9v2.997l3.037-.017.006 1-3.54.02-.503.003V9h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSmartwatchSmall;
