import type { SVGProps } from "react";
const SvgChartLineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 5v15h16v-1H5.707l2.563-2.563 2.888-.963.213-.07.089-.207L12.83 12h2.06l.095-.379L15.89 8h1.817l.147-.146 1.5-1.5-.708-.708L17.293 7H15.11l-.095.379L14.11 11h-1.94l-.13.303-1.411 3.294-2.787.929-.112.037-.084.083L5 18.293V5H4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChartLineSmall;
