import type { SVGProps } from "react";
const SvgSaGl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 1H1v14h14V1Zm-1.273 12.727H2.273v-.008l1.231-.317 9.881-9.88.334-1.25h.008v11.455ZM13.71 2.273l-1.224.332-9.881 9.881-.332 1.224V2.273H13.71Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaGl;
