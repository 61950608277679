import type { SVGProps } from "react";
const SvgGpsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.256 7.762V4.5h-1v3.261A10.977 10.977 0 0 0 7.76 18.251H4.5v1h3.261A10.978 10.978 0 0 0 18.256 29.74V33h1v-3.26a10.978 10.978 0 0 0 10.483-10.496H33v-1h-3.26A10.976 10.976 0 0 0 19.256 7.762ZM8.75 18.75c0-5.539 4.46-10 10-10s10 4.461 10 10c0 5.538-4.46 10-10 10a9.98 9.98 0 0 1-10-10Zm4.5 0a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Zm5.5-6.5a6.5 6.5 0 1 0 0 13.001 6.5 6.5 0 0 0 0-13Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGpsMedium;
