import type { SVGProps } from "react";
const SvgKeyboardMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.75 9.249h-.5v6H4.75v13h26.5v-13h-15v-5h6.25v-1h-6.75Zm-10 7v11h24.5v-11H5.75Zm1.75 3H9v-1H7.5v1Zm0 2v1h3v-1h-3Zm4.5-2h-1.5v-1H12v1Zm0 3h1.5v-1H12v1Zm0 3h-1.5v-1H12v1Zm-4.5 0H9v-1H7.5v1Zm18 0H24v-1h1.5v1Zm1.5-1v1h1.5v-1H27Zm-10.5-3v1H15v-1h1.5Zm1.5 1h1.5v-1H18v1Zm4.5 0H21v-1h1.5v1Zm1.5 0h1.5v-1H24v1Zm-9-3h-1.5v-1H15v1Zm1.5 0H18v-1h-1.5v1Zm4.5 0h-1.5v-1H21v1Zm1.5 0H24v-1h-1.5v1Zm-9 6v-1h9v1h-9Zm12-6h1.75v3.25h1v-4.25H25.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKeyboardMedium;
