import type { SVGProps } from "react";
const SvgArrowChangeHorizontalMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24.354 4.147 5.25 5.25.353.353-.353.354-5.25 5.25-.707-.707 4.396-4.397H11.25a4 4 0 1 0 0 8h13.502a5 5 0 0 1 0 10H7.958l4.396 4.396-.707.707-5.25-5.25-.354-.354.354-.353 5.25-5.25.707.707-4.398 4.397h16.796a4 4 0 0 0 0-8H11.25a5 5 0 0 1 0-10h16.793l-4.396-4.396.707-.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowChangeHorizontalMedium;
