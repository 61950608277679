import type { SVGProps } from "react";
const SvgBriefcaseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.25 9.043a1.8 1.8 0 0 1 1.805-1.793h4.39a1.8 1.8 0 0 1 1.805 1.793v.206h-8v-.206Zm-1 .206v-.206a2.8 2.8 0 0 1 2.805-2.793h4.39a2.8 2.8 0 0 1 2.805 2.793v.206h6.206a2.794 2.794 0 0 1 2.793 2.795v16.037a2.793 2.793 0 0 1-2.793 2.793H6.044a2.795 2.795 0 0 1-2.795-2.793V12.044A2.795 2.795 0 0 1 6.044 9.25h6.206Zm-8.001 2.795c0-.991.804-1.795 1.795-1.795h22.412c.99 0 1.793.803 1.793 1.795v4.706h-9.5V15.4a1.65 1.65 0 0 0-1.648-1.65h-3.702a1.65 1.65 0 0 0-1.65 1.65v1.35h-9.5v-4.706Zm9.5 5.706h-9.5v10.33c0 .99.803 1.794 1.795 1.794h22.412c.99 0 1.793-.803 1.793-1.793V17.75h-9.5v1.35a1.65 1.65 0 0 1-1.648 1.65h-3.702a1.65 1.65 0 0 1-1.65-1.65v-1.35Zm6 0v1.35a.65.65 0 0 1-.648.65h-3.702a.65.65 0 0 1-.65-.65v-1.35h5Zm0-1h-5V15.4c0-.36.292-.65.65-.65H19.1a.65.65 0 0 1 .649.65v1.35Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBriefcaseMedium;
