import type { SVGProps } from "react";
const SvgControlsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 5C7.611 5 7 5.611 7 6.5 7 7.39 7.611 8 8.5 8S10 7.39 10 6.5C10 5.611 9.389 5 8.5 5ZM2 7h4.044C6.26 8.18 7.231 9 8.5 9s2.241-.82 2.456-2H22V6H10.956C10.74 4.82 9.769 4 8.5 4s-2.241.82-2.456 2H2v1Zm13.5 4c-.855 0-1.5.645-1.5 1.5 0 .854.645 1.5 1.5 1.5s1.5-.646 1.5-1.5c0-.855-.645-1.5-1.5-1.5Zm-2.453 1c.225-1.158 1.218-2 2.453-2 1.235 0 2.228.842 2.453 2H22v1h-4.047c-.225 1.157-1.218 2-2.453 2-1.235 0-2.228-.843-2.453-2H2v-1h11.047ZM7 18.5c0-.871.628-1.5 1.5-1.5s1.5.629 1.5 1.5c0 .872-.628 1.5-1.5 1.5S7 19.372 7 18.5ZM8.5 16c-1.252 0-2.235.832-2.454 2H2v1h4.046c.22 1.168 1.202 2 2.454 2 1.252 0 2.235-.832 2.454-2H22v-1H10.954c-.22-1.168-1.202-2-2.454-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgControlsSmall;
