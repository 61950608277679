import type { SVGProps } from "react";
const SvgCertificateRibbonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 8a5 5 0 1 1 9.999 0A5 5 0 0 1 7 8Zm5-6a6 6 0 0 0-4 10.472v9.735l.854-.854 1.646-1.646 1.646 1.646.854.854v-4l.5-.5 1.646 1.646.854.854v-7.735A6 6 0 0 0 12 2Zm3 11.197c-.607.352-1.282.6-2 .72v2.876l.146-.147.354-.353.354.353L15 17.793v-4.596Zm-6 6.596v-6.595c.882.51 1.907.802 3 .802v5.793l-1.146-1.147-.354-.353-.354.353L9 19.793ZM9 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCertificateRibbonSmall;
