import type { SVGProps } from "react";
const SvgArrowRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.844 12.138-3.99-3.992-.708.707 3.137 3.138H5v1h11.287l-3.141 3.156.708.706 3.99-4.009.352-.353-.352-.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRightSmall;
