import type { SVGProps } from "react";
const SvgBinocularsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.25 6.25h-.39l-.095.379-1.444 5.777-2.795 1.397-.241.12-.032.269L4.905 25.65A5.888 5.888 0 0 0 4.75 27a5.71 5.71 0 0 0 5.75 5.75 5.711 5.711 0 0 0 5.75-5.846V15.079a4.84 4.84 0 0 1 1.75-.323c.62 0 1.21.114 1.75.323l.002 11.788A5.71 5.71 0 0 0 25.5 32.75 5.711 5.711 0 0 0 31.252 27a5.87 5.87 0 0 0-.156-1.35l-1.348-11.458-.032-.268-.24-.121-2.795-1.397-1.444-5.777-.095-.379h-5.39v.5L19.75 12h1l.001-4.75h3.61l1.405 5.621.056.223.206.103 2.759 1.38 1.009 8.578a5.733 5.733 0 0 0-4.296-1.898 5.718 5.718 0 0 0-4.749 2.476v-8.121a4.709 4.709 0 0 1 2 3.888h1c0-3.208-2.545-5.744-5.751-5.744-3.206 0-5.75 2.536-5.75 5.744h1a4.71 4.71 0 0 1 2-3.888v8.123a5.72 5.72 0 0 0-4.75-2.477 5.73 5.73 0 0 0-4.294 1.896l1.009-8.578 2.759-1.379.205-.103.056-.223L11.64 7.25h3.61v4.806h1V6.25h-5ZM20.75 27h.002v-.122c.062-2.595 2.133-4.62 4.748-4.62 2.25 0 4.098 1.499 4.61 3.57l.141 1.2A4.711 4.711 0 0 1 25.5 31.75 4.71 4.71 0 0 1 20.75 27Zm-5.5 0v-.088c-.045-2.612-2.123-4.654-4.75-4.654-2.25 0-4.097 1.498-4.609 3.57l-.14 1.2A4.71 4.71 0 0 0 10.5 31.75 4.712 4.712 0 0 0 15.252 27h-.001Zm8.5.002c0-.98.77-1.749 1.75-1.749v-1a2.731 2.731 0 0 0-2.75 2.748h1Zm-16 .001a2.73 2.73 0 0 1 2.748-2.75v1a1.73 1.73 0 0 0-1.748 1.75h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBinocularsMedium;
