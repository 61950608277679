import type { SVGProps } from "react";
const SvgChevronRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.293 12 7.646 4.353l.708-.707 8 8 .353.354-.353.353-8 8-.708-.707L15.293 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronRightSmall;
