import type { SVGProps } from "react";
const SvgHandGraduationCapMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#hand-graduation-cap-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.202 3.293 18 3.203l-.202.09-12.75 5.625-1.112.49 1.135.434L9.25 11.44v4.31c0 .613.344 1.134.822 1.544.48.412 1.144.761 1.922 1.044 1.56.569 3.683.912 6.006.912 2.323 0 4.446-.343 6.006-.912.778-.283 1.443-.632 1.922-1.044.477-.41.822-.93.822-1.544v-4.31l3.5-1.338v6.28l-1.447 2.894.894.448 1.053-2.106 1.053 2.106.894-.448-1.447-2.894V9.72l.813-.31-1.111-.491-12.75-5.625Zm7.548 8.53-7.572 2.894-.178.068-.179-.068-7.571-2.895v3.928c0 .211.117.479.474.786.355.305.898.603 1.612.863 1.425.519 3.427.851 5.664.851 2.237 0 4.239-.332 5.664-.851.714-.26 1.257-.558 1.612-.863.357-.307.474-.575.474-.786v-3.928ZM18 13.714 6.563 9.342 18 4.297l11.436 5.045L18 13.715Zm-5.765 10.483L6.48 20.18a2.003 2.003 0 0 0-2.803.31c-.7.86-.559 2.114.29 2.811l8.888 7.824.141.125h5.893l1.37 1.355.145.145h12.583l-.847-.853-7.545-7.59-.01-.008c-1.713-1.64-3.916-2.933-6.242-3.043v-.006h-3.359c-.88 0-1.579.338-2.054.87a2.858 2.858 0 0 0-.696 2.078Zm5.91-1.948c2.044.043 4.086 1.182 5.745 2.767l6.693 6.733h-9.767l-1.37-1.355-.145-.145h-5.927L4.62 22.545l-.007-.007-.007-.006a1.003 1.003 0 0 1-.153-1.411c.354-.435.984-.5 1.411-.153l.015.011.015.01 7.039 4.916c.475.52 1.172.845 2.052.845h3v-1h-3c-.628 0-1.055-.227-1.328-.535-.282-.318-.431-.755-.428-1.21.004-.457.16-.898.448-1.219.279-.312.703-.536 1.308-.536h3.16Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="hand-graduation-cap-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandGraduationCapMedium;
