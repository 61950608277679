import type { SVGProps } from "react";
const SvgIc8Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-8-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M38.333 9.59a2.371 2.371 0 0 1-1.234-.91 2.666 2.666 0 0 1-.43-1.534A2.816 2.816 0 0 1 37.9 4.75a4.28 4.28 0 0 1 2.586-.79c1.136 0 2.063.313 2.783.94a2.783 2.783 0 0 1 1.025 2.192 2.74 2.74 0 0 1-.434 1.558c-.297.44-.729.77-1.23.941a3.33 3.33 0 0 1 1.018.467c.73.533 1.095 1.31 1.096 2.334a3.369 3.369 0 0 1-1.349 2.808c-.756.585-1.729.878-2.917.878-1.287 0-2.312-.322-3.074-.965a3.28 3.28 0 0 1-1.19-2.634c0-1.018.352-1.806 1.056-2.366a2.91 2.91 0 0 1 1.064-.522Zm2.105.87a1.91 1.91 0 0 0-1.391.527 1.8 1.8 0 0 0-.54 1.348 1.83 1.83 0 0 0 .547 1.377c.385.36.898.551 1.423.53.663 0 1.16-.196 1.49-.586.315-.358.486-.82.482-1.297a1.84 1.84 0 0 0-.553-1.376 1.983 1.983 0 0 0-1.458-.523Zm-1.206-4.283a1.745 1.745 0 0 1 1.222-.423 1.88 1.88 0 0 1 1.28.403c.316.286.485.699.462 1.124.022.435-.145.86-.457 1.162a1.75 1.75 0 0 1-1.246.428 1.786 1.786 0 0 1-1.257-.431 1.488 1.488 0 0 1-.47-1.151 1.43 1.43 0 0 1 .466-1.112Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
    />
  </svg>
);
export default SvgIc8Negative;
