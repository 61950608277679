import type { SVGProps } from "react";
const SvgPiggyBankSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 4.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 6 4.5ZM7.5 2a2.5 2.5 0 1 0 .001 5.001A2.5 2.5 0 0 0 7.5 2ZM4.028 13.424A1.502 1.502 0 0 1 3 12H2c0 1.215.868 2.228 2.017 2.453.154 1.978 1.3 3.705 2.983 4.882V22h3v-1.311c.79.201 1.63.311 2.5.311a9.63 9.63 0 0 0 1.5-.122V22h3v-2.08c1.355-.698 2.477-1.7 3.182-2.92H22v-5h-1.36c-.338-.937-.908-1.78-1.64-2.498V6h-.5a2.494 2.494 0 0 0-2.487 2.358l.998.056c.035-.615.437-1.13.989-1.328v2.848l.16.149c.791.732 1.36 1.604 1.637 2.556L19.9 13H21v3h-1.42l-.14.271c-.617 1.201-1.73 2.214-3.154 2.887l-.286.135V21h-1v-1.338l-.603.127A9.18 9.18 0 0 1 12.5 20a9.094 9.094 0 0 1-2.844-.452L9 19.332V21H8v-2.201l-.227-.149C6.056 17.53 5 15.848 5 14c0-2.163 1.452-4.107 3.713-5.17l-.426-.905c-2.332 1.097-4.023 3.106-4.259 5.5ZM12.5 7a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 12.5 7ZM10 8.5a2.5 2.5 0 1 1 4.36 1.67c.446.083.88.195 1.296.332l-.312.95A9.094 9.094 0 0 0 12.5 11a9.094 9.094 0 0 0-2.844.452l-.312-.95c.417-.137.85-.249 1.296-.332A2.49 2.49 0 0 1 10 8.5Zm8 4.5h-1v-1h1v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPiggyBankSmall;
