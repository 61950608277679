import type { SVGProps } from "react";
const SvgIceNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ice-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.855 6.511a4.401 4.401 0 0 0-1.11-.498 5.199 5.199 0 0 0-1.473-.183c-1.302 0-2.367.427-3.195 1.28-.828.852-1.242 1.954-1.242 3.305 0 1.108.31 2.01.927 2.708a3.058 3.058 0 0 0 2.4 1.047c.381.002.76-.048 1.127-.15a6.19 6.19 0 0 0 1.275-.564l-.365 2.226c-.154.033-.37.077-.645.133-.276.055-.55.1-.82.132-.36.038-.722.055-1.084.05-1.634 0-2.914-.482-3.841-1.445-.928-.964-1.391-2.304-1.391-4.02 0-1.983.607-3.567 1.82-4.752 1.215-1.185 2.849-1.777 4.902-1.777a9.38 9.38 0 0 1 1.697.124c.436.083.734.15.894.2.16.05.394.13.703.24l-.579 1.944ZM6.62 4.202h2.417L6.603 15.798H4.186L6.62 4.202Zm21.474 0h-6.805l-2.434 11.596h6.888l.397-1.828h-4.57l.68-3.256h4.055l.365-1.827h-4.04l.596-2.857h4.52l.348-1.828Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIceNegative;
