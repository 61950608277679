import type { SVGProps } from "react";
const SvgSmartphoneMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.75 6.248a2 2 0 0 0-2 2V29.25a2 2 0 0 0 2 2h10.5c1.106 0 2-.895 2-2v-21a2 2 0 0 0-2-2l-10.5-.002Zm-1 2a1 1 0 0 1 1-1l10.5.002a1 1 0 0 1 1 1v21a1 1 0 0 1-1 1h-10.5a1 1 0 0 1-1-1V8.248ZM15 8.75h6v-1h-6v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneMedium;
