import type { SVGProps } from "react";
const SvgEv35Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-35-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.674c.026-.953.262-1.751.706-2.397.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.145 1.084-.436 1.507-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.786 0 1.147-.423 2.086-1.269 2.816-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.57-.195-1.023-.587-1.356-.323-.275-.82-.413-1.492-.413-.19 0-.378.01-.563.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.047-.291-.27-.674-.405-1.15-.405-.546 0-.966.17-1.262.508-.296.338-.455.833-.476 1.484h-2.198ZM41.835 4.17v2.063h-4.673l-.5 2.61c.56-.592 1.278-.888 2.15-.888.857 0 1.603.23 2.238.69.941.693 1.412 1.745 1.412 3.158 0 1.386-.502 2.483-1.507 3.293-.783.634-1.72.952-2.81.952-1.226 0-2.213-.328-2.959-.984-.746-.661-1.14-1.553-1.182-2.674h2.277c.037.407.17.754.397 1.04.354.45.846.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.299.328-1.706.984l-2.071-.031 1.103-6.467h6.371Z"
    />
  </svg>
);
export default SvgEv35Negative;
