import type { SVGProps } from "react";
const SvgPe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="pe_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.002 9.378h.745c1.275 0 2.127-.08 2.554-.24a2.09 2.09 0 0 0 1.009-.769c.246-.352.368-.739.368-1.16 0-.284-.063-.516-.188-.697a1.066 1.066 0 0 0-.528-.397c-.227-.083-.733-.124-1.518-.124h-1.73l-.712 3.387Zm-1.338 6.423H3.246L5.705 4.06h4.772c.849 0 1.52.1 2.014.3.494.2.885.532 1.173.993.289.462.433 1.013.433 1.654 0 .593-.115 1.17-.345 1.73-.23.56-.511 1.009-.844 1.345a3.616 3.616 0 0 1-1.077.765c-.385.173-.903.305-1.554.396-.38.054-1.089.08-2.13.08H6.601L5.664 15.8Zm7.938-.001 2.45-11.739h8.705l-.408 1.962h-6.286l-.56 2.667h6.077l-.408 1.962h-6.078l-.737 3.187h6.83l-.416 1.962h-9.169Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPe;
