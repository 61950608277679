import type { SVGProps } from "react";
const SvgNewspaperSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 4H3v13.502A2.499 2.499 0 0 0 5.499 20h13.003A2.499 2.499 0 0 0 21 17.502V7h-2V4H3.5ZM19 17V8h1v9.502c0 .827-.671 1.498-1.498 1.498H5.499A1.499 1.499 0 0 1 4 17.502V5h14v12h1ZM5.5 8H5v4h5V8H5.5Zm.5 3V9h3v2H6Zm11 0h-6v-1h6v1Zm-6-2h6V8h-6v1Zm6-2H5V6h12v1Zm-6 7h6v-1h-6v1Zm6 2h-6v-1h6v1ZM5 14h5v-1H5v1Zm5 2H5v-1h5v1Zm-5 1v1h12v-1H5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNewspaperSmall;
