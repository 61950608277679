import type { SVGProps } from "react";
const SvgLargeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.75 18c0-6.759 5.491-12.25 12.25-12.25S30.25 11.241 30.25 18c0 .428-.029.86-.072 1.284l.994.102c.047-.447.078-.914.078-1.386 0-7.311-5.939-13.25-13.25-13.25S4.75 10.689 4.75 18 10.689 31.25 18 31.25c2.119 0 4.13-.499 5.909-1.388l-.448-.894c-1.641.82-3.5 1.282-5.461 1.282-6.759 0-12.25-5.491-12.25-12.25Zm20.916-1.027-.416.277-.416-.277.416-.624.416.624Zm-.416.277a119.43 119.43 0 0 0-.416-.277v.001l-.003.003-.007.01-.027.041a24.799 24.799 0 0 0-.445.715 28.755 28.755 0 0 0-1.05 1.908c-.751 1.504-1.552 3.472-1.552 5.099 0 1.926 1.574 3.5 3.5 3.5s3.5-1.574 3.5-3.5c0-1.627-.8-3.595-1.553-5.099a28.755 28.755 0 0 0-1.494-2.623l-.027-.04-.007-.011-.002-.003v-.001l-.417.277Zm0 .94-.04.067a27.631 27.631 0 0 0-1.013 1.842c-.748 1.496-1.447 3.278-1.447 4.651 0 1.374 1.126 2.5 2.5 2.5s2.5-1.126 2.5-2.5c0-1.373-.7-3.155-1.447-4.651a27.842 27.842 0 0 0-1.013-1.842l-.04-.066Zm-15.444 4.863c1.215-2.83 3.97-4.803 7.284-4.803 1.66 0 3.2.495 4.467 1.362l-.564.826a6.879 6.879 0 0 0-3.903-1.188c-2.896 0-5.301 1.718-6.365 4.197l-.92-.394ZM13.5 13.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm10 0a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLargeMedium;
