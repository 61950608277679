import type { SVGProps } from "react";
const SvgSaVk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.84 15h3.348l7.82-14H10.96L5.146 11.891h-.04L3.802 1H.995L2.84 15ZM15.852 1l-3.007 14h2.807l1.524-7.12 3.89 7.12h3.348l-4.512-7.622L26.98 1h-3.91l-5.554 5.496h-.04L18.66 1h-2.807Z"
    />
  </svg>
);
export default SvgSaVk;
