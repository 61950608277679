import type { SVGProps } from "react";
const SvgSuitcaseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.25 6.263h-.5v4.5h-9V31.25h26.5V10.763h-9v-4.5h-8Zm7 4.5v-3.5h-6.5v3.5h6.5ZM5.75 30.25V11.763h24.5V30.25H5.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSuitcaseMedium;
