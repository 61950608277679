import type { SVGProps } from "react";
const SvgLaptopSmartphoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 4h14v7h-1V5H7v8h6.992v1H6.667L4 16h10v1H3v1h11v1H2v-2.75l.2-.15L6 13.25V4Zm10.5 9a.5.5 0 0 0-.5.5v7c0 .277.223.5.5.5h4c.277 0 .5-.223.5-.5v-7a.5.5 0 0 0-.5-.5H20v1h-3v-1h-.5Zm-1.5.5c0-.828.67-1.5 1.5-1.5h4c.83 0 1.5.672 1.5 1.5v7c0 .83-.67 1.5-1.5 1.5h-4a1.5 1.5 0 0 1-1.5-1.5v-7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLaptopSmartphoneSmall;
