import type { SVGProps } from "react";
const SvgCloudVocSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M5.996 11.08c-3.211 0-3.849 6.42 1.2 6.42h10.502c4.981 0 4.8-6.42 1.05-6.27 1.65-5.526-5.701-8.064-7.951-2.987-1.982-1.616-5.149-.065-4.8 2.836Z"
    />
    <path
      fill="currentColor"
      d="M7.701 11.663h1.081l.536 3.211.528-3.21H10.9l-.96 4.282H8.652L7.7 11.663Zm4.899-.091c.64 0 1.066.215 1.277.645.084.17.143.37.174.602a7.6 7.6 0 0 1 .049 1c0 .66-.057 1.148-.17 1.462-.184.508-.628.762-1.333.762-.7 0-1.143-.26-1.33-.782-.112-.309-.167-.806-.167-1.491 0-.415.018-.74.055-.98.037-.24.102-.444.194-.614.213-.403.63-.604 1.25-.604Zm-.003.698c-.194 0-.32.098-.378.295-.055.182-.082.585-.082 1.21 0 .575.022.972.067 1.193.053.261.185.392.396.392.187 0 .311-.095.372-.289.06-.191.09-.589.09-1.19 0-.67-.027-1.105-.082-1.304-.056-.205-.184-.307-.383-.307Zm3.858 2h.979v.127c-.002.435-.06.765-.173.99a1.096 1.096 0 0 1-.472.495c-.201.105-.47.158-.809.158-.421 0-.743-.099-.963-.296a1.236 1.236 0 0 1-.378-.651c-.067-.27-.1-.702-.1-1.294 0-.489.021-.858.066-1.108.043-.25.122-.454.236-.612.242-.336.636-.504 1.183-.504.475 0 .824.116 1.046.349.131.135.22.285.262.45.045.165.066.424.066.777h-.972v-.093a1.92 1.92 0 0 0-.053-.522c-.053-.18-.17-.27-.354-.27-.223 0-.354.14-.393.417a7.738 7.738 0 0 0-.053 1.051c0 .53.017.92.05 1.172.041.3.174.448.399.448.168 0 .282-.076.342-.228.06-.152.091-.438.091-.855Z"
    />
  </svg>
);
export default SvgCloudVocSmall;
