import type { SVGProps } from "react";
const SvgGpsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.004 5.017V3h-1v2.016A7.484 7.484 0 0 0 5.016 12H3v1h2.016a7.485 7.485 0 0 0 6.988 6.984V22h1v-2.016a7.485 7.485 0 0 0 6.98-6.988h2.015v-1h-2.016a7.484 7.484 0 0 0-6.98-6.98ZM6 12.5C6 8.9 8.9 6 12.5 6S19 8.9 19 12.5 16.1 19 12.5 19 6 16.1 6 12.5Zm3 0a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM12.5 8a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGpsSmall;
