import type { SVGProps } from "react";
const SvgShirtShoeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.67 2 .252.168 6 4 .367.244-.197.395-2 4-.206.412-.427-.171-1.814-.726v.762h-1V8.845l.685.274 2.073.83L21 6.754 15.61 3.16a10.56 10.56 0 0 1-.916.39c-.689.259-1.63.533-2.55.533-.919 0-1.86-.274-2.548-.532a11.226 11.226 0 0 1-.916-.39L3.29 6.754l1.596 3.193L6.96 9.12l.65-.26.035.7.476 9.525h1.025v1H7.169l-.024-.476-.465-9.3-1.85.74-.427.17-.206-.41-2-4L2 6.411l.367-.244 6-4L8.618 2l.265.144.003.002.013.007a4.748 4.748 0 0 0 .272.135c.189.089.457.208.775.327.646.242 1.455.469 2.199.469.744 0 1.553-.227 2.198-.469a10.202 10.202 0 0 0 1.047-.462l.013-.007.002-.002.266-.144Zm-4.913 10.084h5.278l.095.38.001.003.005.018.02.074a15.688 15.688 0 0 0 .437 1.314c.323.846.824 1.947 1.533 2.985.537.787 1.174 1.17 1.844 1.573.157.094.316.19.476.292.41.261.824.571 1.141 1.03.322.465.516 1.043.557 1.804l.028.527h-4.551c-.316 0-.823 0-1.432-.377-.601-.371-1.254-1.078-1.98-2.38l-.003-.004a4.654 4.654 0 0 0-1.061-1.334v4.095h-2.388l-.097-.376.485-.124-.485.123v-.001l-.001-.003-.003-.012-.01-.043a16.346 16.346 0 0 1-.155-.748 22.215 22.215 0 0 1-.277-2.086c-.147-1.72-.149-4.031.446-6.355l.097-.375Zm.788 9h.6V17.37a3.804 3.804 0 0 0-1.012-.27 21.469 21.469 0 0 0 .412 3.983Zm-.396-4.989c.289.028.674.094 1.105.246.916.321 2.02 1.021 2.828 2.5.686 1.228 1.236 1.77 1.633 2.016.366.227.639.227.913.227h3.451a2.046 2.046 0 0 0-.314-.763c-.204-.295-.486-.519-.857-.756a14.384 14.384 0 0 0-.4-.243c-.682-.407-1.536-.916-2.208-1.9-.767-1.124-1.3-2.3-1.642-3.192a16.631 16.631 0 0 1-.392-1.146h-3.728a16.774 16.774 0 0 0-.389 3.011Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShirtShoeSmall;
