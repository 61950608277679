import type { SVGProps } from "react";
const SvgDoorSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M21 19.5H3m13-7h-1m2.5 7h-7v-15h7v15Z"
    />
  </svg>
);
export default SvgDoorSmall;
