import type { SVGProps } from "react";
const SvgSmartphoneShakingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.569 3.69.86-1.433-.858-.515-1.5 2.5-.257.43.429.256 2.5 1.5.514-.857-1.496-.898A24.152 24.152 0 0 1 11.5 4c1.96 0 3.907.228 5.737.674l-1.494.897.514.857 2.5-1.5.429-.257-.257-.429-1.5-2.5-.858.515.862 1.436A25.38 25.38 0 0 0 11.5 3c-2.032 0-4.03.229-5.931.69ZM7 8.499a1.5 1.5 0 0 1 1.5-1.5h6c.83 0 1.5.673 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5v-12Zm1.5-.5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-6Zm4.5 0v1h-3v-1h3Zm-7.172-.357-2.86 1.047.172.47-.172-.47a1.5 1.5 0 0 0-.896 1.923l.001.003 3.458 9.31.938-.348-3.457-9.307v-.002a.5.5 0 0 1 .299-.64l2.86-1.048-.343-.938ZM19.97 8.688l-2.86-1.047-.343.938 2.86 1.048a.5.5 0 0 1 .3.64v.002l-3.457 9.307.938.348 3.457-9.31.001-.003a1.5 1.5 0 0 0-.896-1.923l-.171.47.17-.47Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSmartphoneShakingSmall;
