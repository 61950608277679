import type { SVGProps } from "react";
const SvgUserGroupRowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 6c.44 0 .84.171 1.175.475l.016.015c.039.035.076.073.113.112.419.447.696 1.115.696 1.9a1.406 1.406 0 0 1-.015.206l-.007.064-.006.055v.001C13.839 10.142 12.98 11 12 11a1.68 1.68 0 0 1-1.006-.338 2.18 2.18 0 0 0 .006-.163c0-.83-.24-1.595-.658-2.2a3.372 3.372 0 0 0-.283-.356C10.263 6.76 11.078 5.999 12 5.999Zm0 6c.67 0 1.259-.235 1.732-.625 1.975 1.262 2.943 3.176 3.22 5.624h-3.38c-.524-1.787-1.528-3.289-3.176-4.383a3.74 3.74 0 0 0 .412-.9c.358.18.76.284 1.192.284Zm2.808-2.284c-.1.322-.238.625-.411.899 1.649 1.094 2.651 2.597 3.176 4.384h3.38c-.278-2.447-1.243-4.358-3.217-5.62a2.705 2.705 0 0 1-2.928.337Zm3.595-1.095c.382-.6.597-1.343.597-2.121 0-1.85-1.213-3.502-3-3.502-1.377 0-2.408.991-2.808 2.284A2.638 2.638 0 0 0 12 4.999c-1.377 0-2.408.99-2.807 2.283A2.64 2.64 0 0 0 8 6.998c-1.786 0-3 1.652-3 3.502 0 .771.211 1.508.587 2.105C3.309 14.117 2.049 16.506 2 19.492L1.992 20h12.045l-.038-.536A12.73 12.73 0 0 0 13.812 18h4.225l-.038-.535A12.76 12.76 0 0 0 17.812 16h4.225l-.038-.536c-.205-2.876-1.237-5.275-3.596-6.842ZM14.06 5.945C14.65 6.59 15 7.5 15 8.5c0 .054-.003.109-.006.162C15.29 8.88 15.632 9 16 9c1.063 0 2-1.015 2-2.5 0-1.486-.937-2.502-2-2.502-.922 0-1.737.762-1.941 1.946Zm-4.9 2.514A1.726 1.726 0 0 0 8 8c-1.061 0-2 1.015-2 2.501 0 1.485.938 2.5 2 2.5.965 0 1.811-.83 1.966-2.106l.007-.068.011-.103.006-.055a1.42 1.42 0 0 0 .01-.167c0-.655-.192-1.229-.5-1.66a2.26 2.26 0 0 0-.308-.352l-.031-.03ZM3.023 19c.171-2.478 1.3-4.4 3.227-5.632A2.705 2.705 0 0 0 8 14c.67 0 1.258-.234 1.731-.623 1.975 1.262 2.945 3.176 3.222 5.623h-9.93Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserGroupRowSmall;
