import type { SVGProps } from "react";
const SvgListPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 6h15V5H4v1Zm0 4h15V9H4v1Zm8 8H4v-1h8v1Zm-8-4h8v-1H4v1Zm12 2h-2v-1h2v-2h1v2h2v1h-2v2h-1v-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgListPlusSmall;
