import type { SVGProps } from "react";
const SvgIc86Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-86-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-86-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6ZM38.35 9.59c-.539-.186-.953-.489-1.243-.91-.284-.422-.427-.934-.427-1.535 0-1.002.412-1.801 1.234-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.734.532 1.1 1.31 1.1 2.333 0 1.176-.45 2.112-1.352 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.194-1.547-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.925 2.925 0 0 1 1.067-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.317.285.738.427 1.266.427.527 0 .944-.142 1.25-.427.305-.285.458-.672.458-1.163 0-.48-.155-.854-.466-1.123-.306-.269-.733-.403-1.282-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.358.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.37.354.846.53 1.432.53.664 0 1.163-.195 1.495-.585.322-.374.482-.807.482-1.297 0-.56-.184-1.018-.553-1.377-.354-.348-.841-.522-1.464-.522ZM53.49 7.121h-2.2c-.205-.891-.712-1.337-1.518-1.337-.855 0-1.461.525-1.82 1.574-.147.428-.266 1.074-.356 1.938.348-.469.718-.806 1.108-1.012.395-.206.87-.309 1.423-.309 1.087 0 1.96.385 2.619 1.155.627.728.941 1.64.941 2.737 0 1.303-.422 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.429-.514-3.188-1.542-.76-1.034-1.14-2.473-1.14-4.319 0-1.972.407-3.517 1.219-4.635.786-1.081 1.859-1.622 3.22-1.622 1.28 0 2.262.43 2.942 1.29.39.495.64 1.12.751 1.874Zm-3.916 2.65c-.554 0-.994.232-1.321.696-.295.411-.443.902-.443 1.471 0 .565.15 1.058.45 1.48.328.469.776.704 1.346.704.553 0 .999-.224 1.336-.673.317-.422.475-.92.475-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Z"
      />
    </g>
    <defs>
      <clipPath id="ic-86-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc86Negative;
