import type { SVGProps } from "react";
const SvgCarProfileSignParkingLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.5 7A1.5 1.5 0 0 0 34 8.5v11a1.5 1.5 0 0 0 1.5 1.5h8a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 43.5 7h-8ZM33 8.5A2.5 2.5 0 0 1 35.5 6h8A2.5 2.5 0 0 1 46 8.5v11a2.5 2.5 0 0 1-2.5 2.5H40v20h-1V22h-3.5a2.5 2.5 0 0 1-2.5-2.5v-11Zm4.5.5H37v10h1v-4h2.002A3 3 0 0 0 40 9.002h-2.5Zm2.502 5H38v-3.998h2A2 2 0 0 1 40.002 14ZM6 19H2v-1h12.686a1.5 1.5 0 0 1 .857.269l11.15 7.756 7.218 1.203A2.5 2.5 0 0 1 36 29.694V38h-7.028a4.5 4.5 0 0 1-8.945 0H4.972a4.503 4.503 0 0 1-2.784 3.667l-.376-.926A3.498 3.498 0 0 0 4 37.5c0-1.466-.904-2.72-2.188-3.24l.376-.926a4.5 4.5 0 0 1 2.784 3.665h15.055a4.5 4.5 0 0 1 8.945.001H35v-6h-1.513l-.013-.001c-.79-.041-1.2-.736-1.436-1.31l-.002-.004-.753-1.881L26.456 27H2v-1h4v-7Zm26.437 8.996.526 1.315c.107.259.214.44.319.553.091.098.165.13.234.135H35v-.305a1.5 1.5 0 0 0-1.254-1.48l-1.31-.218ZM14.972 19.09 24.905 26H7v-7h7.686a.5.5 0 0 1 .286.09ZM24.5 34a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM23 37.5a1.5 1.5 0 1 1 3.002.001A1.5 1.5 0 0 1 23 37.5Zm1.5-2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM12 29H9v-1h3v1Zm-5.994-1.001-.006 6L7 34l.006-6-1-.001Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCarProfileSignParkingLarge;
