import type { SVGProps } from "react";
const SvgGiftLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.915 5.136a4.137 4.137 0 0 0-3.119.417l-.005.003c-.523.31-1.065.916-1.564 1.579-.51.68-1.019 1.479-1.467 2.237A46.74 46.74 0 0 0 24.322 12h-.644a48.373 48.373 0 0 0-1.438-2.628 25.091 25.091 0 0 0-1.467-2.237c-.499-.663-1.04-1.27-1.564-1.579l-.005-.003a4.137 4.137 0 0 0-6.078 4.596c.144.573.513 1.137.923 1.606.073.083.149.165.226.245H7v7h1v24h32V19h1v-7h-7.275c.077-.08.153-.162.226-.245.41-.469.779-1.034.923-1.606a4.137 4.137 0 0 0-2.959-5.013ZM32.176 12a5.31 5.31 0 0 0 1.023-.903c.36-.412.616-.837.706-1.193l.485.122-.485-.122a3.137 3.137 0 0 0-4.607-3.486c-.34.202-.781.666-1.272 1.318a24.24 24.24 0 0 0-1.405 2.145 45.75 45.75 0 0 0-1.1 1.977l-.074.142h6.729ZM18.702 6.418a3.137 3.137 0 0 0-4.607 3.486c.09.356.346.78.707 1.193.318.363.688.683 1.022.903h6.73l-.075-.142a45.858 45.858 0 0 0-1.1-1.977 24.118 24.118 0 0 0-1.405-2.145c-.49-.652-.932-1.116-1.272-1.318ZM39 19h-6.038l2.464 3.514.492.702-.853.083-2.874.278-1.296 2.648-.372.76-.486-.693L28 23.387V42h11V19Zm-.32-1h-6.419l-3.506-5H40v5h-1.32Zm-16.74-5h5.595l6.58 9.386-2.297.223-.278.027-.123.25-1.046 2.139L21.939 13Zm-2.196 0H8v5h8.238l3.505-5Zm-4.206 6H9v23h11.001l-.002-17.9-1.537 2.192-.49.7-.37-.77-1.275-2.655-2.89-.268-.859-.08.495-.705L15.537 19ZM21 22.673 21.001 42H27V21.96l-2.75-3.923L21 22.673Zm2.64-5.506-2.798-3.99-6.455 9.206 2.315.214.282.026.122.255 1.028 2.14 5.506-7.852Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGiftLarge;
