import type { SVGProps } from "react";
const SvgIr39Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-39-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-39-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm10.493-4.47c.027-.949.261-1.745.704-2.389.718-1.033 1.796-1.55 3.236-1.55 1.107 0 2 .282 2.681.846.733.612 1.1 1.403 1.1 2.373 0 .58-.145 1.082-.435 1.503a2.1 2.1 0 0 1-1.163.847c.622.142 1.113.459 1.471.949.364.49.546 1.084.546 1.78 0 1.144-.422 2.08-1.266 2.808-.78.675-1.766 1.012-2.958 1.012-1.292 0-2.318-.371-3.077-1.115-.754-.744-1.131-1.748-1.131-3.014v-.15h2.222c0 .712.17 1.263.507 1.653.337.385.814.578 1.431.578.617 0 1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.195-1.02-.585-1.353-.322-.274-.818-.411-1.487-.411-.19 0-.378.01-.562.032V8.822c.137.01.269.016.395.016 1.33 0 1.994-.493 1.994-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.673-.403-1.147-.403-.543 0-.963.169-1.258.506-.295.338-.453.83-.474 1.48h-2.192Zm8.971 5h2.183c.179.896.698 1.344 1.558 1.344.485 0 .878-.163 1.179-.49.305-.332.54-.847.704-1.543a10.3 10.3 0 0 0 .229-1.51c-.353.495-.717.849-1.092 1.06-.369.205-.82.308-1.352.308-.85 0-1.588-.248-2.215-.744a3.542 3.542 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.445-2.357 1.337-3.164.764-.696 1.682-1.044 2.752-1.044.66 0 1.268.148 1.828.443a3.961 3.961 0 0 1 1.407 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.295 2.99-.886 4.129-.77 1.482-1.935 2.222-3.496 2.222-1.039 0-1.899-.287-2.579-.862-.675-.575-1.065-1.347-1.17-2.317ZM49.4 5.91c-.496 0-.915.203-1.258.609-.343.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.337.544.812.815 1.423.815a1.58 1.58 0 0 0 1.33-.664c.31-.417.466-.912.466-1.487 0-.607-.16-1.12-.482-1.543A1.606 1.606 0 0 0 49.4 5.91Z"
      />
    </g>
    <defs>
      <clipPath id="ir-39-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr39Negative;
