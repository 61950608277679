import type { SVGProps } from "react";
const SvgIr66Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-66-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.554L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.02 4.216 5.935h4.598l-3.124-3.434h1.92ZM40.572 9.78c-.553 0-.992.231-1.318.696-.295.412-.442.903-.442 1.473 0 .565.15 1.059.45 1.48.325.47.773.705 1.341.705.553 0 .997-.224 1.334-.673.316-.422.474-.92.474-1.496 0-.565-.143-1.048-.427-1.45-.347-.49-.818-.736-1.412-.736Zm3.906-2.654h-2.194c-.205-.892-.71-1.338-1.515-1.338-.852 0-1.458.525-1.815 1.576-.148.427-.266 1.074-.356 1.94.348-.47.717-.808 1.11-1.014.391-.206.864-.309 1.416-.309 1.084 0 1.955.386 2.612 1.156.627.73.94 1.643.94 2.74 0 1.305-.421 2.353-1.263 3.144-.758.713-1.668 1.07-2.73 1.07-1.364 0-2.424-.516-3.182-1.549-.757-1.032-1.136-2.472-1.136-4.32 0-1.974.405-3.52 1.215-4.64.784-1.082 1.855-1.624 3.213-1.624 1.278 0 2.257.43 2.936 1.291.389.496.639 1.122.75 1.877Zm5.083 2.654c-.552 0-.992.231-1.318.696-.295.412-.442.903-.442 1.473 0 .565.15 1.059.45 1.48.326.47.774.705 1.342.705.552 0 .997-.224 1.334-.673.315-.422.473-.92.473-1.496 0-.565-.142-1.048-.426-1.45-.347-.49-.818-.736-1.413-.736Zm3.907-2.654h-2.194c-.205-.892-.71-1.338-1.516-1.338-.852 0-1.457.525-1.815 1.576-.147.427-.265 1.074-.355 1.94.347-.47.717-.808 1.109-1.014.392-.206.864-.309 1.417-.309 1.083 0 1.954.386 2.612 1.156.626.73.94 1.643.94 2.74 0 1.305-.422 2.353-1.263 3.144-.758.713-1.669 1.07-2.731 1.07-1.363 0-2.423-.516-3.181-1.549-.758-1.032-1.136-2.472-1.136-4.32 0-1.974.405-3.52 1.215-4.64.784-1.082 1.855-1.624 3.212-1.624 1.279 0 2.258.43 2.936 1.291.39.496.64 1.122.75 1.877Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIr66Negative;
