import type { SVGProps } from "react";
const SvgChevronRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.293 18 11.647 6.353l.707-.707 12 12 .353.354-.353.353-12 12-.707-.707L23.293 18Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronRightMedium;
