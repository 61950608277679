import type { SVGProps } from "react";
const SvgSaJe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.941 10.347 9.901 1H7.1l-1.96 9.268c-.26 1.262-.74 2.327-2.22 2.327-.5 0-.941-.1-1.281-.218L1 14.724A9.366 9.366 0 0 0 3.339 15c3.162 0 4.062-1.893 4.602-4.653ZM21.242 1H13.02l-2.94 13.763h8.322l.48-2.168H13.36l.82-3.865h4.902l.44-2.17H14.64l.721-3.391h5.461L21.242 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaJe;
