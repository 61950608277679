import type { SVGProps } from "react";
const SvgIc15Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-15-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-15-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm26.341.034v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.98-.49.428-.328.716-.879.863-1.654h1.812Zm10.821 0V6.29h-4.66l-.498 2.602c.56-.59 1.274-.886 2.144-.886.854 0 1.598.23 2.23.688.94.691 1.409 1.74 1.409 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.208-.328-2.951-.982-.744-.659-1.137-1.547-1.179-2.665h2.27c.037.406.17.751.396 1.036.353.448.844.672 1.471.672.607 0 1.087-.218 1.44-.656.343-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.358-.401-.836-.602-1.431-.602-.728 0-1.295.327-1.701.981l-2.065-.031 1.1-6.447h6.352Z"
      />
    </g>
    <defs>
      <clipPath id="ic-15-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc15Negative;
