import type { SVGProps } from "react";
const SvgUserGroupRoundTableSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 8c0-1.215.77-2 1.5-2s1.5.785 1.5 2c0 1.215-.77 2-1.5 2S10 9.215 10 8Zm1.5-3C10.022 5 9 6.453 9 8c0 .69.203 1.36.558 1.892-.342.343-.637.77-.874 1.257a3.97 3.97 0 0 0-.239-.262C8.799 10.356 9 9.687 9 9c0-1.547-1.022-3-2.5-3S4 7.453 4 9c0 .687.202 1.356.555 1.887-.89.888-1.457 2.347-1.539 3.963l-.118.09c-.513.403-.898.927-.898 1.56s.385 1.157.898 1.56c.518.409 1.236.755 2.08 1.036C6.67 19.66 8.976 20 11.5 20s4.83-.34 6.522-.904c.844-.281 1.562-.627 2.08-1.035.513-.404.898-.928.898-1.561 0-.633-.385-1.157-.898-1.56a3.989 3.989 0 0 0-.117-.09c-.08-1.616-.646-3.077-1.538-3.966.352-.53.553-1.198.553-1.884 0-1.547-1.022-3-2.5-3S14 7.453 14 9c0 .686.201 1.354.554 1.885-.083.082-.163.17-.24.262a4.643 4.643 0 0 0-.872-1.256A3.433 3.433 0 0 0 14 8c0-1.547-1.022-3-2.5-3Zm3.235 7.287c.08.3.141.612.184.932 1.164.156 2.218.39 3.103.685.32.107.622.223.902.348-.168-1.15-.614-2.084-1.172-2.648A2.17 2.17 0 0 1 16.5 12a2.17 2.17 0 0 1-1.252-.396 3.41 3.41 0 0 0-.513.684ZM4.078 14.252c.28-.125.58-.24.9-.347.885-.295 1.939-.529 3.104-.685.042-.32.103-.63.183-.93a3.438 3.438 0 0 0-.515-.683A2.17 2.17 0 0 1 6.5 12a2.17 2.17 0 0 1-1.25-.394c-.557.563-1.004 1.497-1.172 2.645Zm5.032-1.146A26.81 26.81 0 0 1 11.5 13c.825 0 1.626.036 2.39.105-.183-1.084-.614-1.958-1.145-2.496A2.17 2.17 0 0 1 11.5 11a2.17 2.17 0 0 1-1.245-.39c-.53.537-.961 1.411-1.145 2.495ZM3 16.5c0-.195.118-.461.517-.775.392-.31.992-.61 1.777-.872C6.859 14.331 9.054 14 11.5 14c2.447 0 4.64.331 6.206.853.785.262 1.384.562 1.777.872.399.314.517.58.517.775 0 .195-.118.461-.517.775-.393.31-.992.61-1.777.872-1.565.522-3.76.853-6.206.853-2.447 0-4.64-.331-6.206-.853-.785-.262-1.385-.562-1.777-.872-.399-.314-.517-.58-.517-.775ZM15 9c0-1.215.77-2 1.5-2s1.5.785 1.5 2c0 1.215-.77 2-1.5 2S15 10.215 15 9ZM6.5 7C5.77 7 5 7.785 5 9c0 1.215.77 2 1.5 2S8 10.215 8 9c0-1.215-.77-2-1.5-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserGroupRoundTableSmall;
