import type { SVGProps } from "react";
const SvgParkAndRailSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.003 6H3V5h18.002v1Zm0 13H3v-1h18.002v1ZM4.735 11.741V9.475h1.467c.479 0 .824.073 1.035.22.292.196.438.495.438.897 0 .398-.127.698-.377.903-.205.164-.54.246-1.003.246h-1.56Zm1.483-3.744H2.99v8.001h1.745v-2.883H6.53c.46 0 .848-.045 1.161-.134.314-.089.593-.236.837-.44.577-.479.865-1.136.865-1.97 0-.943-.349-1.647-1.046-2.114a2.378 2.378 0 0 0-.844-.358c-.313-.068-.74-.102-1.285-.102Zm3.195 4.107h1.542v-1.541h.884v1.541h1.548v.884h-1.548v1.542h-.884v-1.542H9.413v-.883Zm6.354-.532v-2.2h1.74c.51 0 .884.087 1.121.263.238.174.357.452.357.83 0 .392-.14.684-.416.877-.22.153-.598.23-1.133.23h-1.67Zm2.128-3.574h-3.874v8h1.745v-3.146h1.57c.56 0 .954.109 1.186.326.232.216.37.608.414 1.173.043.635.079 1.04.105 1.215.029.176.073.32.134.433h1.822l-.075-.093c-.105-.135-.184-.48-.236-1.034-.069-.756-.125-1.24-.17-1.45a1.736 1.736 0 0 0-.256-.608 1.35 1.35 0 0 0-.833-.591c.401-.15.715-.397.94-.744.223-.347.336-.753.336-1.221 0-.412-.09-.782-.268-1.111a2.02 2.02 0 0 0-.777-.788c-.41-.24-.997-.361-1.763-.361Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgParkAndRailSmall;
