import type { SVGProps } from "react";
const SvgShoppingBagCouponMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.25 8.25a4 4 0 1 1 8 0v2.5h-8v-2.5Zm-1 3.5V15h1v-3.25h8V15h1v-3.25h3.537l1.423 18.5H7.29l1.423-18.5h3.537Zm0-1v-2.5a5 5 0 1 1 10 0v2.5h4.463l.035.462 1.5 19.5.042.538H6.21l.041-.538 1.5-19.5.036-.462h4.463Zm4.5 14.75V18h1v7.5h-1Zm.5-10.25a6.14 6.14 0 0 0-.51.02l.077.996a5.624 5.624 0 0 1 .865 0l.078-.997a6.621 6.621 0 0 0-.51-.02Zm2.488.493a6.454 6.454 0 0 0-.97-.315l-.233.972c.282.068.556.157.82.267l.383-.924Zm-4.006-.315c-.334.08-.658.186-.97.315l.383.924a5.45 5.45 0 0 1 .82-.267l-.233-.972Zm5.74 1.379a6.517 6.517 0 0 0-.825-.6l-.523.852c.246.151.479.321.698.508l.65-.76Zm-7.62-.6a6.57 6.57 0 0 0-.824.6l.65.76a5.53 5.53 0 0 1 .698-.508l-.523-.852Zm8.94 2.146a6.518 6.518 0 0 0-.6-.825l-.76.65c.187.218.357.452.508.698l.852-.523Zm-10.485-.825c-.22.258-.421.534-.6.825l.852.523c.152-.246.322-.48.509-.698l-.76-.65Zm11.265 2.704a6.454 6.454 0 0 0-.316-.97l-.923.382c.11.264.199.538.266.82l.973-.232Zm-12.329-.97a6.465 6.465 0 0 0-.315.97l.973.233a5.45 5.45 0 0 1 .266-.82l-.924-.384Zm-.473 1.978a6.621 6.621 0 0 0 0 1.02l.997-.078a5.622 5.622 0 0 1 0-.865l-.997-.077Zm12.98.51c0-.172-.007-.342-.02-.51l-.997.077a5.624 5.624 0 0 1 0 .865l.997.078c.013-.169.02-.339.02-.51Zm-.494 2.488a6.43 6.43 0 0 0 .316-.97l-.973-.234a5.45 5.45 0 0 1-.266.82l.923.384Zm-12.328-.97c.08.333.186.658.315.97l.924-.383a5.45 5.45 0 0 1-.266-.82l-.973.232Zm.78 1.878c.178.291.379.567.6.825l.76-.65a5.525 5.525 0 0 1-.509-.698l-.852.523Zm10.484.825c.221-.258.422-.534.6-.825l-.852-.523c-.15.246-.32.48-.508.698l.76.65Zm-9.164.721c.258.22.534.421.825.6l.523-.852a5.526 5.526 0 0 1-.698-.508l-.65.76Zm7.619.6c.29-.179.566-.38.825-.6l-.65-.76a5.526 5.526 0 0 1-.698.508l.523.852Zm-5.885.464c.312.13.636.235.97.315l.233-.972a5.45 5.45 0 0 1-.82-.267l-.383.924Zm4.005.315c.335-.08.66-.186.971-.315l-.383-.924a5.45 5.45 0 0 1-.82.267l.232.972Zm-2.027.159a6.57 6.57 0 0 0 1.02 0l-.078-.997a5.574 5.574 0 0 1-.865 0l-.077.997Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShoppingBagCouponMedium;
