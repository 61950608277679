import type { SVGProps } from "react";
const SvgCircleSignalNoiseRatioSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.397 10.725H8.151c-.024-.292-.132-.515-.325-.67-.192-.154-.463-.231-.81-.231-.308 0-.548.06-.72.182a.59.59 0 0 0-.255.51c0 .197.082.339.246.426.158.088.56.21 1.205.369.68.17 1.152.34 1.414.513.484.317.725.758.725 1.324 0 .62-.243 1.109-.73 1.466-.428.315-1.008.472-1.74.472-.815 0-1.448-.186-1.898-.558-.451-.37-.685-.9-.701-1.586h1.303c.019.333.105.581.258.742.24.257.603.385 1.086.385.328 0 .588-.064.78-.192a.693.693 0 0 0 .323-.603c0-.253-.152-.44-.456-.557-.166-.066-.56-.184-1.18-.357a6.936 6.936 0 0 1-.91-.303 1.949 1.949 0 0 1-.524-.32c-.306-.273-.459-.65-.459-1.13 0-.577.238-1.033.717-1.37.407-.286.936-.43 1.586-.43.571 0 1.05.113 1.435.34.554.326.846.852.876 1.578Zm3.91-1.775-2.775 7.533h-1.09l2.787-7.532h1.078Zm5.743 0v5.992h-1.316l-2.508-4.082.016 4.082h-1.258V8.951h1.332l2.492 4.073-.016-4.073h1.258Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M12 21.5a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19Z"
    />
  </svg>
);
export default SvgCircleSignalNoiseRatioSmall;
