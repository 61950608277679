import type { SVGProps } from "react";
const SvgDocumentVideoMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 6.25h9.707l.147.147 7.5 7.5.146.146v15.708H9.25V6.25Zm1 1v21.5h15.5v-14h-7.5v-7.5h-8Zm9 .708 5.793 5.793H19.25V7.958Zm-4.735 8.869-.765-.479v9.305l.765-.479 6-3.75.678-.424-.678-.424-6-3.75ZM19.307 21l-4.557 2.848v-5.695L19.307 21Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentVideoMedium;
