import type { SVGProps } from "react";
const SvgPiggyBankMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.75 6.75a2.501 2.501 0 0 1 5 0 2.501 2.501 0 0 1-5 0Zm2.5-3.5a3.501 3.501 0 0 0 0 7 3.501 3.501 0 0 0 0-7ZM6.267 20.453A2.502 2.502 0 0 1 4.25 18h-1a3.502 3.502 0 0 0 3.013 3.466c.163 3.001 1.898 5.635 4.487 7.405v3.879h4v-2.042c1.256.35 2.6.542 4 .542.864 0 1.696-.087 2.5-.227v1.727h4v-3.022c2.105-1.055 3.83-2.598 4.877-4.478h2.623v-7h-1.968c-.495-1.463-1.378-2.781-2.532-3.892V9.25h-.5a3.49 3.49 0 0 0-3.481 3.3l.998.057A2.495 2.495 0 0 1 27.25 10.3v4.493l.16.148c1.215 1.125 2.097 2.47 2.525 3.948l.105.361h1.71v5h-2.228l-.14.271c-.954 1.86-2.67 3.413-4.846 4.442l-.286.135v2.652h-2v-1.949l-.603.127c-.937.197-1.9.322-2.897.322-1.539 0-3.003-.25-4.344-.69l-.656-.216v2.406h-2v-3.417l-.227-.148C8.893 26.47 7.25 23.875 7.25 21c0-3.365 2.257-6.356 5.713-7.982l-.426-.905c-3.534 1.663-6.039 4.73-6.27 8.34ZM18.75 10.25a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Zm-3.5 2.5a3.501 3.501 0 0 1 7 0 3.49 3.49 0 0 1-1.238 2.67c.83.126 1.63.32 2.394.57l-.312.95a13.895 13.895 0 0 0-4.344-.69c-1.539 0-3.003.25-4.344.69l-.312-.95a14.742 14.742 0 0 1 2.394-.57 3.492 3.492 0 0 1-1.238-2.67ZM27 19.25h-1.5v-1H27v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPiggyBankMedium;
