import type { SVGProps } from "react";
const SvgDocumentPdfSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.01 4h6.697l.146.147 5 5 .147.146V20H6v-.5l.009-15V4Zm.999 1L7 19h10v-9h-5V5H7.01ZM13 5.708 16.293 9H13V5.708ZM8.651 16.404v-.848h.55c.18 0 .308.027.387.082.11.073.164.185.164.335 0 .15-.047.263-.142.34-.076.06-.2.091-.375.091h-.584Zm.556-1.402h-1.21V18h.654v-1.08h.672c.173 0 .318-.018.435-.052a.824.824 0 0 0 .314-.165c.216-.177.324-.424.324-.737 0-.352-.13-.616-.39-.792a.943.943 0 0 0-.318-.134 2.42 2.42 0 0 0-.48-.038Zm2.203.553v1.892h.504c.155 0 .278-.017.37-.046a.573.573 0 0 0 .239-.159c.159-.17.238-.422.238-.756 0-.306-.073-.537-.22-.695-.147-.157-.364-.236-.652-.236h-.48Zm-.654-.553h1.212c.225 0 .418.026.579.077.16.053.302.134.425.247.3.274.449.652.449 1.137 0 .515-.141.912-.424 1.192a1.069 1.069 0 0 1-.42.265 1.883 1.883 0 0 1-.595.08h-1.226v-2.998Zm5.226.554v-.554h-2.11V18h.654v-1.234h1.268v-.515h-1.268v-.695h1.456Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentPdfSmall;
