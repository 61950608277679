import type { SVGProps } from "react";
const SvgGpsDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.004 5.016a7.472 7.472 0 0 0-3.628 1.216l.55.836A6.478 6.478 0 0 1 12.5 6c3.6 0 6.5 2.9 6.5 6.5 0 1.323-.394 2.55-1.068 3.575l.835.549a7.472 7.472 0 0 0 1.216-3.628H22v-1h-2.016a7.484 7.484 0 0 0-6.98-6.98V3h-1v2.016ZM3 12h2.017A7.469 7.469 0 0 1 6.85 7.558L4.721 5.43l.708-.707 14.85 14.849-.707.707-2.13-2.129a7.473 7.473 0 0 1-4.438 1.835V22h-1v-2.016A7.484 7.484 0 0 1 5.017 13H3v-1Zm13.734 5.44-1.425-1.424A4.5 4.5 0 0 1 8.984 9.69L7.56 8.267A6.477 6.477 0 0 0 6 12.5c0 3.601 2.9 6.5 6.5 6.5a6.48 6.48 0 0 0 4.234-1.56Zm-7.037-7.036a3.5 3.5 0 0 0 4.9 4.9l-4.9-4.9ZM12.5 9c-.51 0-.992.115-1.435.315l-.412-.911A4.468 4.468 0 0 1 12.5 8a4.5 4.5 0 0 1 4.5 4.5c0 .664-.15 1.285-.404 1.847l-.911-.412c.2-.443.315-.926.315-1.434A3.5 3.5 0 0 0 12.5 9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGpsDisabledSmall;
