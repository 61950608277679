import type { SVGProps } from "react";
const SvgTicketRvMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 3.25h20.5v29.5H7.75V3.25Zm1 1v27.5h18.5V4.25H8.75ZM24 23.75H12v-1h12v1Zm-12 3h12v-1H12v1Zm6.053-9.206c-.034-.037-.147-.216-.219-.992-.065-.71-.108-1.093-.137-1.243a1.538 1.538 0 0 0-.238-.541 1.345 1.345 0 0 0-.404-.401c.206-.13.384-.299.523-.498.24-.36.362-.784.35-1.216a2.236 2.236 0 0 0-.246-1.049 1.969 1.969 0 0 0-.716-.757 2.892 2.892 0 0 0-1.512-.344H12v7.518h1.384v-3.065h1.636c.348-.032.699.03 1.014.181.25.162.4.604.434 1.278.027.569.051.89.074 1.04.023.167.083.327.176.469l.068.097h1.681l-.414-.477Zm-1.928-3.977c-.129.073-.27.12-.418.14a5.21 5.21 0 0 1-.789.045h-1.534v-1.986h1.786c.245-.007.49.016.73.067a.88.88 0 0 1 .38.199.92.92 0 0 1 .26.703.872.872 0 0 1-.415.832Zm5.291 2.567 1.887-5.631h1.473l-2.689 7.517h-1.38l-.054-.149-2.631-7.368h1.516l1.878 5.63Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketRvMedium;
