import type { SVGProps } from "react";
const SvgFaceNeutralMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.75 18c0-6.844 5.407-12.25 12.25-12.25S30.25 11.156 30.25 18c0 6.843-5.407 12.25-12.25 12.25S5.75 24.843 5.75 18ZM18 4.75C10.605 4.75 4.75 10.604 4.75 18c0 7.395 5.855 13.25 13.25 13.25S31.25 25.395 31.25 18c0-7.396-5.855-13.25-13.25-13.25Zm7.5 17.5v-1h-15v1h15ZM12.25 13.5a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm9-1a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaceNeutralMedium;
