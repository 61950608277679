import type { SVGProps } from "react";
const SvgCloudRainSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.25 3v3h1V3h-1Zm-.487 8.691a4.599 4.599 0 0 0-1.796-1.852c.71-.678 1.682-1.089 2.783-1.089 2.26 0 4 1.74 4 4 0 1.699-.982 3.098-2.42 3.692a3.193 3.193 0 0 0-.266-.384 2.933 2.933 0 0 0-1.884-1.074c.246-1.254.065-2.374-.417-3.293Zm3.052 6.885a3.92 3.92 0 0 0-.109-1.207c1.812-.746 3.044-2.51 3.044-4.619 0-2.813-2.187-5-5-5-1.51 0-2.85.632-3.76 1.663-1.956-.606-4.301.02-5.582 2.303-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.586-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.84 1.073 2.548.708.713 1.75 1.155 3.125 1.155h10.5c1.369 0 2.427-.442 3.158-1.144a3.84 3.84 0 0 0 1.157-2.53Zm-5.948-8.15c-1.686-.61-3.8-.064-4.81 2.226l-.265.602-.509-.417c-1.627-1.333-4.282-.069-3.986 2.404l.066.56H10.8c-.616 0-1.116.302-1.47.798-.362.505-.56 1.198-.529 1.905.031.703.286 1.384.784 1.886.493.497 1.265.86 2.415.86h10.5c1.144 0 1.943-.363 2.465-.866a2.84 2.84 0 0 0 .852-1.872c.044-.689-.15-1.353-.532-1.827-.37-.46-.937-.766-1.715-.735l-.698.028.199-.67c.379-1.274.233-2.339-.194-3.153a3.663 3.663 0 0 0-2.01-1.728Zm-1.309-2.17-2.161-2.079.693-.72 2.162 2.079-.694.72Zm9.778 9.893 2.12 2.121.708-.707-2.121-2.12-.707.706ZM31.5 12.25h3v1h-3v-1Zm-.21-6.77L29.169 7.6l.707.708 2.121-2.121-.707-.708ZM12.303 26.775l1.5-3 .894.448-1.5 3-.894-.448Zm10.5-3-1.5 3 .894.448 1.5-3-.894-.448Zm-9 6 3-6 .894.448-3 6-.894-.448Zm6.075-6-3 6 .894.448 3-6-.894-.448Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSunMedium;
