import type { SVGProps } from "react";
const SvgSaY = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.28 9.204 1 1h2.961l2.08 5.878L10.582 1h3.261L7.081 9.204 5.861 15H3.06l1.22-5.796Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaY;
