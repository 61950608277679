import type { SVGProps } from "react";
const SvgFreightWagonGlobeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.764 13.75a8.45 8.45 0 0 1 .984-3.5h2.988a16.32 16.32 0 0 0-.479 3.5H8.764Zm3.493 1H8.764a8.45 8.45 0 0 0 .984 3.5h2.989a16.32 16.32 0 0 1-.48-3.5Zm1.513 3.5a15.24 15.24 0 0 1-.512-3.5h3.492v3.5h-2.98Zm3.98 0v-3.5h3.492a15.24 15.24 0 0 1-.512 3.5h-2.98Zm-3.67 1h2.67v3.485a8.513 8.513 0 0 1-.838-.09c-.75-.951-1.375-2.101-1.831-3.395Zm3.67 0v3.485c.283-.016.563-.046.838-.09.75-.951 1.375-2.101 1.831-3.395H17.75Zm0-9v3.5h3.492a15.24 15.24 0 0 0-.512-3.5h-2.98Zm-1 3.5v-3.5h-2.98a15.24 15.24 0 0 0-.512 3.5h3.492Zm3.67-4.5h-2.67V5.764c.283.017.563.047.838.09.75.952 1.375 2.102 1.832 3.396Zm-3.67 0V5.764a8.53 8.53 0 0 0-.838.09c-.75.952-1.376 2.102-1.832 3.396h2.67Zm-3.725 10h-2.65a8.52 8.52 0 0 0 4.051 3.02 13.54 13.54 0 0 1-1.401-3.02Zm1.4-13.02a8.521 8.521 0 0 0-4.05 3.02h2.65c.358-1.1.832-2.116 1.4-3.02Zm7.818 7.52a16.32 16.32 0 0 0-.48-3.5h2.989a8.45 8.45 0 0 1 .983 3.5h-3.492Zm0 1a16.32 16.32 0 0 1-.48 3.5h2.989a8.45 8.45 0 0 0 .983-3.5h-3.492Zm-.768 4.5a13.54 13.54 0 0 1-1.401 3.02 8.52 8.52 0 0 0 4.05-3.02h-2.649Zm-1.4-13.02c.568.904 1.042 1.92 1.4 3.02h2.65a8.521 8.521 0 0 0-4.05-3.02ZM17.25 4.75a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM23.75 30v-.25h3.5V30a1.75 1.75 0 1 1-3.5 0Zm-1 0v-.25h-9.5V30a2.75 2.75 0 1 1-5.5 0v-.25h-1.5v-1.5h-2V30h-1v-4.5h1v1.75h2v-1.5h23.5v1.5h2V25.5h1V30h-1v-1.75h-2v1.5h-1.5V30a2.75 2.75 0 1 1-5.5 0Zm-14-.25V30a1.75 1.75 0 1 0 3.5 0v-.25h-3.5Zm-1.5-1h21.5v-2H7.25v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonGlobeMedium;
