import type { SVGProps } from "react";
const SvgIc4Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-4-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M44.735 13.158v-1.954h-1.45V4.235h-2.13l-4.927 6.756v2.167h4.81V15.8h2.246v-2.642h1.45Zm-3.65-6.233v4.303H38.12l2.965-4.303Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
    />
  </svg>
);
export default SvgIc4Negative;
