import type { SVGProps } from "react";
const SvgSaKw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.891 8.001H28.11V3.546H2.891v4.455ZM1 11.182h29V1H1v10.182ZM21.173 15h5.044a1.9 1.9 0 0 0 1.892-1.91h-8.827c0 1.055.848 1.91 1.891 1.91ZM2.891 13.09h8.827c0 1.055-.848 1.91-1.893 1.91H4.781a1.9 1.9 0 0 1-1.89-1.91Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaKw;
