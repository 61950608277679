import type { SVGProps } from "react";
const SvgBackpackMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.506 3.25a2.75 2.75 0 0 0-2.75 2.748l-.003.359A7.253 7.253 0 0 0 7.75 13.5v19.25h20.5V13.5a7.253 7.253 0 0 0-5.996-7.142l-.004-.361A2.75 2.75 0 0 0 19.5 3.25h-2.994Zm4.747 3.004-.003-.248V6a1.75 1.75 0 0 0-1.75-1.75h-2.994A1.75 1.75 0 0 0 14.756 6v.004l-.002.25c.082-.003.164-.004.246-.004h6c.085 0 .169.001.253.004ZM8.75 13.5A6.25 6.25 0 0 1 15 7.25h6a6.25 6.25 0 0 1 6.25 6.25v18.25H8.75V13.5Zm4.756 7.25a1.75 1.75 0 0 0-1.75 1.75h-.006v6.25h12.5V22.5a1.75 1.75 0 0 0-1.75-1.75h-8.994ZM25.25 22.5v7.25h-14.5V22.5h.006a2.75 2.75 0 0 1 2.75-2.75H22.5a2.75 2.75 0 0 1 2.75 2.75Zm-5.5 1.25H13.5v-1h9v1h-1.75v1.75h-1v-1.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBackpackMedium;
