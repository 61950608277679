import type { SVGProps } from "react";
const SvgSaMi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.5 13.601v-4.5c-.928-.121-1.793-.464-2.344-1.01-.548-.546-.891-1.11-.985-1.676C1.056 5.848 1 5.424 1 5.122V1h8.4v4.122c0 .261-.057.686-.153 1.272-.113.588-.452 1.152-1.003 1.698-.55.545-1.413.888-2.344 1.01V13.6l3.499.001V15H1v-1.399h3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaMi;
