import type { SVGProps } from "react";
const SvgClipboardQuestionMarkMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.25 3.25h-.5v1.5h-9v28h26.5v-28h-9v-1.5h-8Zm.5 2.5v.5h6.5v-2h-6.5v1.5Zm7.5 0v1.5h-8.5v-1.5h-8v26h24.5v-26h-8Zm-5.293 19.424h1.9v-1.828h-1.9v1.828ZM14.603 13.75c-.386.597-.6 1.441-.64 2.532h1.51c.123-1.923.967-2.883 2.532-2.883.756 0 1.327.243 1.714.729.321.399.483.891.483 1.476 0 .316-.055.627-.168.932-.133.358-.418.759-.852 1.204-.673.691-1.108 1.164-1.305 1.416a2.83 2.83 0 0 0-.453.852c-.145.433-.225 1.098-.237 1.995h1.494c0-.504.03-.913.089-1.23.052-.316.144-.582.276-.796.132-.213.364-.482.699-.804.756-.739 1.233-1.245 1.432-1.52.446-.605.668-1.325.668-2.163 0-1.014-.327-1.832-.984-2.453-.704-.673-1.649-1.009-2.838-1.009-.74 0-1.404.149-1.995.447a3.62 3.62 0 0 0-1.425 1.275Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClipboardQuestionMarkMedium;
