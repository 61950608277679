import type { SVGProps } from "react";
const SvgSpeakerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 6.066V5l-.82.682L8 9.994H4v4h4l5.18 4.321L14 19V6.066ZM8.5 10.88 13 7.133v9.73l-4.499-3.754-.139-.116H5v-1.999h3.361l.14-.115Zm10.035 6.597-2.265-1.401-.526.85 2.266 1.401.525-.85ZM15.744 7.075l2.266-1.401.525.85-2.265 1.401-.526-.85Zm3.74 1.548-3.598.892.24.97 3.598-.891-.24-.97Zm-.002 6.755-3.596-.893.241-.97 3.596.893-.24.97ZM20 11.5h-3.993v1H20v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpeakerSmall;
