import type { SVGProps } from "react";
const SvgEv18Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-18-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm5.914 0v11.6h-2.317V8.177h-2.73V6.495c.566-.016 1.026-.075 1.38-.175.36-.1.689-.265.985-.492.428-.328.716-.88.865-1.658h1.817Zm4.85 5.371c-.54-.185-.955-.489-1.246-.912-.285-.423-.428-.936-.428-1.54 0-1.004.412-1.806 1.237-2.404.73-.529 1.598-.793 2.603-.793 1.143 0 2.076.315 2.8.944.689.593 1.032 1.325 1.032 2.198 0 .608-.145 1.13-.436 1.563-.29.434-.703.749-1.238.944.418.122.76.278 1.024.468.735.535 1.103 1.315 1.103 2.341 0 1.18-.453 2.119-1.357 2.817-.762.587-1.74.88-2.936.88-1.296 0-2.327-.322-3.094-.967-.8-.672-1.199-1.553-1.199-2.643 0-1.02.355-1.811 1.064-2.372.322-.249.68-.423 1.07-.524Zm2.134-3.848c-.508 0-.918.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.318.286.741.428 1.27.428s.947-.142 1.254-.428c.306-.286.46-.675.46-1.167 0-.48-.156-.856-.468-1.126-.307-.27-.736-.405-1.286-.405Zm-.016 4.721c-.57 0-1.039.177-1.404.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.849.532 1.436.532.667 0 1.167-.196 1.5-.587.322-.376.484-.81.484-1.301 0-.561-.185-1.021-.556-1.381-.354-.35-.843-.524-1.468-.524Z"
    />
  </svg>
);
export default SvgEv18Negative;
