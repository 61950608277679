import type { SVGProps } from "react";
const SvgFaceTearSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 12c0-4.414 3.586-8 8-8s8 3.586 8 8c0 .278-.019.56-.047.839l.994.102c.032-.302.053-.62.053-.941 0-4.966-4.034-9-9-9s-9 4.034-9 9 4.034 9 9 9a8.959 8.959 0 0 0 4.014-.943l-.448-.894A7.96 7.96 0 0 1 12 20c-4.414 0-8-3.586-8-8Zm13.916-.777-.416.277-.416-.277.416-.624.416.624Zm-.416.277a119.43 119.43 0 0 0-.416-.277l-.002.003-.005.007-.018.028a13.062 13.062 0 0 0-.3.482c-.192.32-.45.77-.706 1.283C15.55 14.03 15 15.373 15 16.5c0 1.376 1.124 2.5 2.5 2.5s2.5-1.124 2.5-2.5c0-1.127-.55-2.47-1.053-3.474a19.284 19.284 0 0 0-1.006-1.765l-.018-.028-.005-.007-.001-.002v-.001l-.417.277Zm0 .954c-.164.28-.358.63-.553 1.02C16.45 14.47 16 15.627 16 16.5c0 .824.676 1.5 1.5 1.5s1.5-.676 1.5-1.5c0-.873-.45-2.03-.947-3.026-.195-.39-.39-.74-.553-1.02ZM7.05 15.303C7.887 13.357 9.782 12 12.06 12c1.14 0 2.2.34 3.072.937l-.564.826A4.42 4.42 0 0 0 12.06 13a4.414 4.414 0 0 0-4.09 2.697l-.92-.394ZM8 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm7-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaceTearSmall;
