import type { SVGProps } from "react";
const SvgCircleInformationLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.5 4C13.178 4 4 13.178 4 24.5S13.178 45 24.5 45 45 35.822 45 24.5 35.822 4 24.5 4ZM3 24.5C3 12.626 12.626 3 24.5 3S46 12.626 46 24.5 36.374 46 24.5 46 3 36.374 3 24.5ZM24.5 11a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM21 13.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM19.5 19a1.5 1.5 0 0 0 0 3H22v14h-2.5a1.5 1.5 0 1 0 0 3h11a1.5 1.5 0 1 0 0-3H28V19h-8.5Zm-.5 1.5a.5.5 0 0 1 .5-.5H27v17h3.5a.499.499 0 1 1 0 1h-11a.5.5 0 1 1 0-1H23V21h-3.5a.5.5 0 0 1-.5-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleInformationLarge;
