import type { SVGProps } from "react";
const SvgArrowStartRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.854 8.146 3.99 3.992.352.353-.352.354-3.99 4.007-.708-.705 3.141-3.156H7v-1h9.283l-3.137-3.138.708-.707ZM4 9v7h1V9H4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowStartRightSmall;
