import type { SVGProps } from "react";
const SvgWheelchairInaccessibleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 6a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm5.792 13 1.854 1.854.708-.707-12.5-12.5-.708.707 2.826 2.826A4.49 4.49 0 0 0 6 14.5c0 2.476 2.026 4.5 4.5 4.5a4.48 4.48 0 0 0 3.318-1.476L15 18.707V19h.292Zm-2.183-2.183-4.929-4.93A3.492 3.492 0 0 0 7 14.502c0 1.922 1.577 3.5 3.5 3.5a3.484 3.484 0 0 0 2.61-1.184ZM15.5 13h-2v1H15v1.5h1V13h-.5ZM11 8v2.5h-1V8h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairInaccessibleSmall;
