import type { SVGProps } from "react";
const SvgHandHeartSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.996 3a2.984 2.984 0 0 0-2.638 4.413c.127.235.284.457.477.655l.005.005 3.803 3.782.353.35.352-.35 3.798-3.777.017-.016.015-.018c.089-.107.328-.411.422-.575v-.001c.25-.438.394-.944.394-1.485 0-.36-.062-.705-.18-1.027A2.987 2.987 0 0 0 10.993 3c-.773 0-1.47.29-1.998.764a2.98 2.98 0 0 0-2-.764ZM4.358 7.414l.439-.24-.44.239Zm.636-1.43C4.994 4.884 5.88 4 6.996 4c.66 0 1.236.314 1.6.801l.401.536.4-.536A1.98 1.98 0 0 1 10.995 4c.872 0 1.602.54 1.879 1.298V5.3c.078.211.12.44.12.683 0 .362-.095.698-.261.988a5.1 5.1 0 0 1-.31.415l-3.427 3.41-3.448-3.429a1.941 1.941 0 0 1-.312-.432v-.001a1.973 1.973 0 0 1-.242-.951Zm3.009 9.835-3.578-2.5a1.5 1.5 0 0 0-1.873 2.344l5.917 5.213.142.125h3.925l.862.855.146.145h8.857l-.846-.852-5.028-5.064-.008-.008c-1.188-1.144-2.75-2.045-4.4-2.076H10c-.633 0-1.145.244-1.495.635a2.05 2.05 0 0 0-.502 1.183ZM3.84 14.13l4.69 3.276c.35.37.852.594 1.47.594h2v-1h-2c-.374 0-.613-.133-.763-.301a1.033 1.033 0 0 1-.243-.695c.002-.268.094-.522.256-.702.153-.171.39-.302.75-.302h2.104c1.305.026 2.634.751 3.718 1.792L19.999 21h-6.043l-.861-.855-.147-.145h-3.96l-5.783-5.095-.009-.008-.009-.007a.5.5 0 0 1 .627-.78l.013.01.013.01Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandHeartSmall;
