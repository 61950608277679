import type { SVGProps } from "react";
const SvgCircleExclamationPointMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.25 18c0-7.594 6.156-13.75 13.75-13.75S31.75 10.406 31.75 18 25.594 31.75 18 31.75 4.25 25.594 4.25 18ZM18 3.25C9.854 3.25 3.25 9.854 3.25 18S9.854 32.75 18 32.75 32.75 26.146 32.75 18 26.146 3.25 18 3.25ZM18.5 9v10.5h-1V9h1Zm-1 16.5v-3h1v3h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleExclamationPointMedium;
