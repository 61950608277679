import type { SVGProps } from "react";
const SvgStopMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 7.75h20.5v20.5H7.75V7.75Zm1 1v18.5h18.5V8.75H8.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStopMedium;
