import type { SVGProps } from "react";
const SvgTrainProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m10 2.899.277.185 3 2 .624.416-.624.416-1.625 1.083H14a4.981 4.981 0 0 1 4.707 3.371l2.094 5.773c.121.253.199.543.199.856a2 2 0 0 1-2 2h-2.05c-.075.37-.232.71-.45 1H22v1H2v-1h1.5a2.489 2.489 0 0 1-.45-1H2v-1h17a1 1 0 0 0 1-1 .986.986 0 0 0-.108-.439l-.012-.025-.01-.026-.547-1.51H13V10h4.444A3.97 3.97 0 0 0 14 8H2V7h6.348L6.723 5.915 6.099 5.5l.624-.416 3-2L10 2.899ZM17.872 11H14v3h4.96l-1.088-3Zm-5.822 8c.075.369.232.709.45 1h-5c.218-.291.375-.631.45-1h4.1Zm-7.965 0h2.83a1.502 1.502 0 0 1-2.83 0Zm9 0a1.502 1.502 0 0 0 2.83 0h-2.83ZM10 6.898l2.099-1.4L10 4.102 7.901 5.5 10 6.899Zm-3 3.1h5v5H7v-5Zm1 1v3h3v-3H8Zm-6 0h3v3H2v1h4v-5H2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrainProfileSmall;
