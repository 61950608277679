import type { SVGProps } from "react";
const SvgVaeNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="vae-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.023 4.2h2.623l-3.838 11.6H7.942L4.186 4.2h2.623l2.574 8.156h.033L12.023 4.2Zm4.094 11.6h-2.575L17.93 4.2h2.607l4.323 11.6h-2.64l-.873-2.583h-4.324l-.906 2.583Zm3.076-8.74h.032l1.458 4.256h-2.996l1.506-4.257Zm15.331 8.74h-8.776V4.2h8.647v2.144H28.29V8.83h5.603v1.982H28.29v2.843h6.233V15.8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVaeNegative;
