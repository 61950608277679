import type { SVGProps } from "react";
const SvgHouseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.354 3.646 12 3.293l-.354.353-8 8 .708.707.646-.646V20h4.99v-5h4v5H19v-8.293l.646.646.708-.707-8-8ZM18 10.706l-6-6-6 6V19h2.99v-5h6v5H18v-8.293Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHouseSmall;
