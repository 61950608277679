import type { SVGProps } from "react";
const SvgBatteryLevelPercentageMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M15 32.25H5.25v-27h3v-1.5h6v1.5h3V16.5M7.5 29.25H15m-7.5-3H15m-7.5-3H15m-7.5-3H15m13.5-2.156L18 32.906m1.5-14.156a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm7.5 9a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
    />
  </svg>
);
export default SvgBatteryLevelPercentageMedium;
