import type { SVGProps } from "react";
const SvgScooterProfilePowerPlugMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.106 13.76a6.47 6.47 0 0 1 3.696 1.406l-.624.782a5.473 5.473 0 0 0-3.428-1.198 5.499 5.499 0 0 0-5.5 5.499v.5h-9.035a3.502 3.502 0 0 1-3.465 3 3.502 3.502 0 0 1-3.465-3H3.264l-.014-.486c-.08-2.802 1.148-5.148 2.653-6.795a10.383 10.383 0 0 1 2.373-1.945c.779-.455 1.557-.742 2.201-.772H15.1l-.411.741-.003.005-.01.02a7.32 7.32 0 0 0-.205.417 13.53 13.53 0 0 0-.497 1.245c-.325.942-.646 2.194-.71 3.571h4.494l7.113-9.077-1.222-3.423H21v-1h3.352l.119.332 3.635 10.178Zm-2.838-4.973-6.874 8.772-.15.191H12.25v-.5c0-1.719.391-3.276.777-4.397.15-.436.301-.809.43-1.103h-2.945c-.416.023-1.027.224-1.73.636a9.384 9.384 0 0 0-2.14 1.756C5.355 15.55 4.323 17.48 4.25 19.75h17.018a6.5 6.5 0 0 1 5.785-5.962l-1.786-5ZM7.3 20.75c.232 1.14 1.241 2 2.45 2s2.218-.86 2.45-2H7.3Zm20.45-3a2.501 2.501 0 0 0 0 5 2.501 2.501 0 0 0 0-5Zm-3.5 2.5a3.501 3.501 0 0 1 7 0 3.501 3.501 0 0 1-7 0Zm-3.5 7v-1.5h-.5l-1.503.002a3.5 3.5 0 0 0-3.462 2.998H10.5v1h4.786a3.502 3.502 0 0 0 3.464 3h2v-1.5H24v-1h-3.25v-2l3.251-.007-.002-1-3.249.006Zm-2.003-.498 1.003-.001v5h-1a2.498 2.498 0 1 1-.003-4.998Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScooterProfilePowerPlugMedium;
