import type { SVGProps } from "react";
const SvgArrowsCircleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.01 12a7 7 0 0 1 7.013-6.986c1.33.002 2.793.542 4.084 1.419 1.038.704 1.934 1.605 2.54 2.567H15v1h4.989l.001-.499.01-4.49-1-.002-.006 2.75a10.362 10.362 0 0 0-2.325-2.154c-1.407-.955-3.062-1.588-4.644-1.591a8 8 0 0 0-8.015 7.985l1 .002Zm.25 3.008L9 15v-1l-4.49.01h-.5l-.001.499-.01 4.49L5 19l.006-2.501C6.566 18.626 9.27 20 12 20a8 8 0 0 0 8-8h-1a7 7 0 0 1-7 7c-2.816 0-5.564-1.652-6.74-3.992Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowsCircleSmall;
