import type { SVGProps } from "react";
const SvgLipsHandMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.031 9.856c1.208-.353 2.36-.606 3.219-.606 1.65 0 2.623.397 3.235.847.225.166.392.332.515.468a3.45 3.45 0 0 1 .515-.468c.611-.45 1.585-.847 3.235-.847.858 0 2.01.253 3.218.606 1.22.357 2.544.831 3.761 1.303a86.862 86.862 0 0 1 4.386 1.844l.07.032.018.008.005.002h.001L33 13.5l.21-.453.799.369-.727.497-.281-.411.281.412h-.002l-.005.004-.02.014-.078.053-.296.197a73.356 73.356 0 0 1-4.777 2.891c-2.743 1.516-6.155 3.142-8.854 3.56v.886a2 2 0 0 1 2.988 1.508 2 2 0 0 1 3 1.5 2 2 0 0 1 3.012 1.725V31.5h-1v-5.25a1 1 0 0 0-2 0V30h-1v-5.25a1 1 0 0 0-2 0v3.75h-1v-5.25a1 1 0 0 0-2-.002V27h-1V15.75a1 1 0 1 0-2 0V28.5h-1v-2.25a1 1 0 1 0-2 0v5.25h-1v-5.25a2 2 0 0 1 3-1.732v-4.21c-2.651-.736-5.71-2.288-8.121-3.664a74.794 74.794 0 0 1-4.076-2.507 39.938 39.938 0 0 1-.248-.165l-.065-.044-.017-.012-.004-.002-.001-.001L3 13.5l-.21-.454h.002l.005-.003.018-.008.07-.032.265-.12a86.832 86.832 0 0 1 4.12-1.724c1.218-.472 2.541-.946 3.761-1.303ZM3 13.5l-.283.412-.726-.497.8-.37L3 13.5Zm25.368-1.409c1.158.449 2.228.898 3.025 1.243a88.81 88.81 0 0 1-3.063.563c-2.612.434-6.006.878-9.29.96a2 2 0 0 0-3.55-.059c-3.054-.168-6.088-.6-8.391-.998a87.9 87.9 0 0 1-2.492-.467 85.527 85.527 0 0 1 3.025-1.242c1.204-.466 2.498-.93 3.68-1.275 1.196-.35 2.232-.566 2.938-.566 1.48 0 2.234.352 2.642.652.209.154.346.307.459.44l.02.022c.047.056.11.128.167.184.053.05.215.202.462.202.246 0 .41-.152.461-.202.059-.056.12-.128.168-.184l.02-.023a2.61 2.61 0 0 1 .459-.438c.407-.3 1.162-.653 2.642-.653.706 0 1.742.216 2.938.566 1.182.346 2.476.809 3.68 1.275ZM5.532 14.533c.611.377 1.32.802 2.092 1.242 2.318 1.322 5.163 2.764 7.626 3.493v-3.482c-3.044-.178-6.043-.607-8.321-1a85.802 85.802 0 0 1-1.397-.253ZM19.25 19.62v-3.768c3.291-.094 6.658-.538 9.244-.967a92.32 92.32 0 0 0 1.972-.35 71.03 71.03 0 0 1-2.846 1.663c-2.698 1.491-5.895 2.999-8.37 3.422Zm-1.477-8.796c-.011.01-.01.007.002-.002l-.002.002Zm.452-.002a.1.1 0 0 1 .002.002l-.002-.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLipsHandMedium;
