import type { SVGProps } from "react";
const SvgDocumentTextMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.252 6.245h9.707l.147.146 7.5 7.5.146.146v15.708h-17.5v-23.5Zm1 1v21.5h15.5v-14h-7.5v-7.5h-8Zm9 .707 5.793 5.793h-5.793V7.952ZM13.5 19.25h9v-1h-9v1Zm9 3h-9.004v-1H22.5v1Zm-9 3h9v-1h-9v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentTextMedium;
