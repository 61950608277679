import type { SVGProps } from "react";
const SvgTrainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3.25h12v1h-2.732l-2.333 3.5H24A4.25 4.25 0 0 1 28.25 12v14.75h-3.441l1 2H27v1h-.691l1 2H28.5v1h-21v-1h1.191l1-2H9v-1h1.191l1-2H7.75V12A4.25 4.25 0 0 1 12 7.75h5.065l-2.333-3.5H12v-1Zm6 4.099 2.066-3.099h-4.132L18 7.349Zm-3 4.401v-1h6v1h-6Zm-5.191 20 1-2h14.382l1 2H9.809Zm14.882-3-1-2H12.309l-1 2h13.382ZM8.75 19.25v6.5h18.5v-6.5H8.75Zm18.5-1V12A3.25 3.25 0 0 0 24 8.75H12A3.25 3.25 0 0 0 8.75 12v6.25h18.5Zm-6 4.25a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-9-1a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25c0-.138.113-.25.25-.25s.25.112.25.25a.25.25 0 0 1-.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrainMedium;
