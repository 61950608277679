import type { SVGProps } from "react";
const SvgDocumentLockSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.5 4H6v16h6v-1H7V5h5v5h5v1h1V9.293l-.146-.147-5-5L12.707 4H6.5Zm9.793 5L13 5.707V9h3.293ZM14 13H9v-1h5v1Zm-5.002 2h3v-1h-3v1ZM12 17H9v-1h3v1Zm4-3.5a.5.5 0 1 1 1 0V15h-1v-1.5ZM15 15v-1.5a1.5 1.5 0 1 1 3 0V15h2v6h-7v-6h2Zm2 1.969V19h-1v-2.031h1ZM14 20v-4h5v4h-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentLockSmall;
