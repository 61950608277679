import type { SVGProps } from "react";
const SvgDoubleDeckWagonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.496 6.998h15.003c.83 0 1.5.671 1.5 1.5v7.5h1v1h-1v1h-1a2 2 0 0 1-4 0h-8a2 2 0 0 1-4 0H2.998v-1h-1v-1h1l-.002-7.5a1.5 1.5 0 0 1 1.5-1.5Zm13.503 12a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1Zm-12 0a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1Zm13.5-11H4.496a.5.5 0 0 0-.5.5l.002 8.5h1v-4h4v4H20v-8.5h-.001a.5.5 0 0 0-.5-.5Zm-11.5 9v-3h-2v3h2Zm-2.5-8h-.5v3H19v-3H5.499Zm.5 2v-1H18v1H5.999Zm4 2H19v3H9.999v-3Zm1 1v1H18v-1h-7.001Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDoubleDeckWagonSmall;
