import type { SVGProps } from "react";
const SvgLinkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.538 5.222-.733-.704a3.473 3.473 0 0 0-4.917.004l.354.353-.354-.353-.004.004-.013.013-.05.05-.184.184-.623.624-1.6 1.6.707.707 1.6-1.6.624-.624.184-.185.05-.05.012-.012.003-.003.001-.001a2.474 2.474 0 0 1 3.507 0l.007.007.733.703c.97.973.971 2.539.002 3.507v.001l-4.249 4.267a2.508 2.508 0 0 1-3.535 0l-.707-.707-.707.707.707.707a3.507 3.507 0 0 0 4.95 0v-.001l4.248-4.266a3.478 3.478 0 0 0-.006-4.925l-.007-.007Zm-6.624 4.656a3.51 3.51 0 0 0-4.95 0l-4.242 4.243a3.509 3.509 0 0 0 0 4.95l.707.707a3.51 3.51 0 0 0 4.949 0l2.48-2.48-.707-.707-2.48 2.48a2.51 2.51 0 0 1-3.535 0l-.707-.707a2.509 2.509 0 0 1 0-3.536l4.242-4.243a2.51 2.51 0 0 1 3.536 0l.707.708.707-.707-.707-.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkSmall;
