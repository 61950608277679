import type { SVGProps } from "react";
const SvgSaRc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.381 3.133c1.5 0 2.56.37 3.201.795l.7-2.268c-1.22-.486-2.7-.66-4.1-.66-4.941 0-8.002 3.219-8.002 7.582 0 3.664 2.02 6.418 6.242 6.418 1.058 0 2.14-.098 3.16-.368l.44-2.599c-.8.465-1.76.835-2.94.835-2.462 0-3.982-2.017-3.982-4.286 0-3.18 2.18-5.449 5.281-5.449ZM6.541 7.011h-1.02l.78-3.645h1.16c1.14 0 2.401 0 2.401 1.416 0 1.783-1.82 2.229-3.321 2.229Zm.68-5.778H3.92L1 14.767h2.8l1.221-5.623h1.06c1.29 0 1.428.727 1.635 1.824.037.195.077.403.125.62l.68 3.179h2.941l-1.34-5.448c-.18-.738-.661-1.202-1.42-1.24v-.04c2.28-.038 4.08-1.28 4.08-3.606 0-3.2-2.912-3.2-5.47-3.2H7.22Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaRc;
