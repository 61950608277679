import type { SVGProps } from "react";
const SvgBriefcaseLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.023 10c.253-2.8 2.612-5 5.477-5h6c2.865 0 5.224 2.2 5.477 5h-2.012A3.496 3.496 0 0 0 26.5 7h-6a3.496 3.496 0 0 0-3.465 3h-2.012ZM26.5 8c1.247 0 2.276.906 2.468 2.098A1.504 1.504 0 0 0 28 11.5v.5h-9v-.5c0-.639-.403-1.187-.968-1.402A2.496 2.496 0 0 1 20.5 8h6Zm-12.487 2.081A6.506 6.506 0 0 1 20.5 4h6c3.445 0 6.27 2.69 6.487 6.081A1.504 1.504 0 0 1 34 11.5v.5h7.97A2.03 2.03 0 0 1 44 14.03v26.94A2.03 2.03 0 0 1 41.97 43H5.03A2.03 2.03 0 0 1 3 40.97V14.03A2.03 2.03 0 0 1 5.03 12H13v-.5c0-.656.425-1.216 1.013-1.419ZM14 11.5c0-.274.226-.5.5-.5h3c.274 0 .5.226.5.5v.5h-4v-.5Zm19 .5h-4v-.5c0-.274.226-.5.5-.5h3c.274 0 .5.226.5.5v.5ZM5.03 13A1.03 1.03 0 0 0 4 14.03V25h14v-1.5a1.5 1.5 0 0 1 1.5-1.5h8a1.5 1.5 0 0 1 1.5 1.5V25h14V14.03A1.03 1.03 0 0 0 41.97 13H5.03Zm23.885 13a1.508 1.508 0 0 1-.915.915v3.875c0 .401-.232.715-.504.909A1.728 1.728 0 0 1 26.5 32h-6c-.368 0-.721-.106-.996-.301-.272-.194-.504-.508-.504-.909v-3.875a1.504 1.504 0 0 1-.915-.915H4v14.97c0 .569.461 1.03 1.03 1.03h36.94A1.03 1.03 0 0 0 43 40.97V26H28.915ZM20 27h7v3.793a.069.069 0 0 1-.008.016.267.267 0 0 1-.075.075.734.734 0 0 1-.417.116h-6a.734.734 0 0 1-.417-.116.267.267 0 0 1-.075-.075.069.069 0 0 1-.007-.016L20 30.79V27Zm-1-3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2Zm5 6.5v-2h-1v2h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBriefcaseLarge;
