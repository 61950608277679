import type { SVGProps } from "react";
const SvgVeganMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.38 3.25h-.376l-.104.362a16.73 16.73 0 0 0-.65 4.633 17.585 17.585 0 0 0 8.492 15.433l.517-.856A16.586 16.586 0 0 1 4.25 8.261v-.012a15.73 15.73 0 0 1 .512-3.999h.488c5.003 0 9.488 1.573 12.364 5.068l.772-.636C15.263 4.887 10.448 3.25 5.25 3.25h-.87ZM31.8 6.676l-.066-.442-.447.016c-5.157.19-8.87 1.543-11.516 3.522-2.645 1.98-4.184 4.558-5.042 7.134-.857 2.573-1.038 5.151-.967 7.16.036 1.007.134 1.876.246 2.538.055.33.115.613.172.837a4.6 4.6 0 0 0 .081.286 68.983 68.983 0 0 0-2.71 5.055l.9.436c.822-1.7 1.713-3.36 2.67-4.98 5.002-.231 8.598-1.565 11.16-3.503 2.613-1.977 4.11-4.551 4.94-7.123 1.563-4.847.771-9.744.602-10.795l-.023-.14ZM15.747 27.2c4.488-.306 7.673-1.555 9.93-3.263 2.416-1.827 3.811-4.212 4.592-6.632 1.373-4.255.835-8.573.612-10.036-4.763.243-8.133 1.525-10.51 3.304-2.452 1.834-3.886 4.225-4.693 6.65-.809 2.428-.984 4.88-.916 6.808.034.963.128 1.789.232 2.407l.01.062a68.992 68.992 0 0 1 12.411-14.871l.67.742a67.987 67.987 0 0 0-12.338 14.83ZM8.516 8.576a25.594 25.594 0 0 1 6.12 5.355l-.77.638a24.594 24.594 0 0 0-5.88-5.145l.53-.848Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVeganMedium;
