import type { SVGProps } from "react";
const SvgPenMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m25.853 6.71-.354-.352-.353.353-3.435 3.435-.353.354.353.353 3.435 3.435.355.355.353-.355 3.435-3.449.353-.354-.354-.353-3.435-3.421Zm-3.081 3.79 2.729-2.729 2.727 2.717-2.729 2.739-2.727-2.727Zm-2.918 2.212-.354-.354-.354.354-11.25 11.25-.146.146v4.142h4.142l.147-.146 11.25-11.252.353-.353-.354-.354-3.434-3.433ZM8.75 24.522l10.75-10.75 2.728 2.727-10.75 10.751H8.75v-2.728Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPenMedium;
