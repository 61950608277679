import type { SVGProps } from "react";
const SvgSoccerBallSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 12c0-1.645.441-3.186 1.212-4.513l.862 2.935L3 12.014V12Zm3.056-1.784L4.94 6.418a9.036 9.036 0 0 1 3.123-2.514l3.437 2.36v2.481l-3.087 2.24-2.357-.769Zm2.047 1.72-2.358-.77-2.664 2.047a8.956 8.956 0 0 0 1.8 4.294l2.942.085 1.463-2.02-1.183-3.636Zm1.992 4.224-1.458 2.014.88 2.479A9.008 9.008 0 0 0 12 21c.862 0 1.695-.121 2.484-.347l.88-2.48-1.46-2.013h-3.809Zm4.62-.587 1.462 2.02 2.942-.086a8.956 8.956 0 0 0 1.799-4.285l-2.67-2.053-2.351.767-1.183 3.637Zm.872-4.588 2.347-.765 1.124-3.805a9.036 9.036 0 0 0-3.121-2.51L12.5 6.262v2.482l3.087 2.24Zm-9.776 7.55a9.017 9.017 0 0 0 2.485 1.67l-.573-1.615-1.912-.056Zm10.466.055 1.912-.056a9.016 9.016 0 0 1-2.485 1.671l.573-1.615ZM21 12.024V12a8.95 8.95 0 0 0-1.215-4.518l-.868 2.94L21 12.025Zm-9-6.63L9.173 3.453A8.991 8.991 0 0 1 12 3c.988 0 1.938.16 2.827.453L12 5.393ZM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm-1.797 13.16-1.115-3.429L12 9.618l2.912 2.113-1.115 3.429h-3.594Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSoccerBallSmall;
