import type { SVGProps } from "react";
const SvgCupHotMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.75 4.5v6h1v-6h-1Zm-8.5 10.75h-.5V21c0 4.498 3.128 8.267 7.326 9.25H6v1h22.5v-1h-9.076a9.52 9.52 0 0 0 6.922-6.5H28.5a2.75 2.75 0 0 0 0-5.5h-1.75v-3H8.25Zm20.25 7.5h-1.91c.105-.567.16-1.152.16-1.75v-1.75h1.75a1.75 1.75 0 0 1 0 3.5ZM8.75 21v-4.75h17V21c0 4.693-3.807 8.5-8.5 8.5a8.501 8.501 0 0 1-8.5-8.5Zm12.5-9V6h1v6h-1Zm-9-6v6h1V6h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCupHotMedium;
