import type { SVGProps } from "react";
const SvgSaGp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.462 3.133c1.46 0 2.801.291 3.881.97l.7-2.347C12.582 1.213 10.943 1 9.382 1 4.341 1 1 4.044 1 8.99 1 13.176 3.74 15 7.842 15c1.359 0 2.92-.271 3.74-.601l1.601-7.388H7.522l-.46 2.133h2.96l-.781 3.587c-.319.136-.74.136-1.379.136-2.381 0-3.941-1.765-3.941-3.955 0-3.2 2.14-5.78 5.541-5.78Zm10.319 4.362h-1l.9-4.13h.88c1.2 0 2.34.291 2.34 1.668 0 1.687-1.58 2.462-3.12 2.462Zm-5.401 7.272h2.8L18.3 9.63h1.62c3.121 0 5.901-1.61 5.901-4.886 0-3.375-3.54-3.51-6.12-3.51h-2.36l-2.962 13.534Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaGp;
