import type { SVGProps } from "react";
const SvgCameraLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.5 7h-.234l-.15.18-4.85 5.82H11v-3H2v32h44V13H34.734l-4.85-5.82-.15-.18H19.5Zm13.932 6-4.166-5h-9.532l-4.167 5h17.865ZM10 13v-2H3v2h7Zm-7 1v27h42V14H3Zm21.5 3C18.701 17 14 21.701 14 27.5S18.701 38 24.5 38 35 33.299 35 27.5 30.299 17 24.5 17ZM13 27.5C13 21.149 18.149 16 24.5 16S36 21.149 36 27.5 30.851 39 24.5 39 13 33.851 13 27.5Zm4 0a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Zm7.5-8.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm5.5 8.5a5.5 5.5 0 0 0-5.5-5.5v-1a6.5 6.5 0 0 1 6.5 6.5h-1ZM35 15h8v4h-8v-4Zm1 3h6v-2h-6v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCameraLarge;
