import type { SVGProps } from "react";
const SvgHouseLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38 2c-1.495 0-2.87.628-3.55 1.638a3.405 3.405 0 0 0-.95-.138c-1.547 0-3 1.026-3 2.5 0 .788.432 1.45 1.046 1.893a1.426 1.426 0 0 0-.046.357c0 1.047.983 1.75 2 1.75s2-.703 2-1.75c0-.123-.015-.242-.043-.357a2.77 2.77 0 0 0 .406-.36A4.949 4.949 0 0 0 38 8c.785 0 1.516-.177 2.133-.47.559.607 1.425.97 2.367.97 1.547 0 3-1.026 3-2.5s-1.453-2.5-3-2.5c-.333 0-.653.046-.953.134C40.866 2.626 39.493 2 38 2Zm-2.863 2.453C35.48 3.68 36.567 3 38 3c1.432 0 2.519.68 2.863 1.453l.206.462.459-.21c.283-.13.612-.205.972-.205 1.213 0 2 .774 2 1.5s-.787 1.5-2 1.5c-.837 0-1.501-.384-1.805-.874l-.261-.42-.423.256A3.905 3.905 0 0 1 38 7c-.784 0-1.481-.208-2.006-.534l-.42-.262-.266.418c-.16.25-.4.47-.702.62l-.5.25.304.47c.062.095.09.191.09.288 0 .333-.357.75-1 .75s-1-.417-1-.75c0-.083.03-.185.101-.306l.268-.457-.47-.242c-.585-.3-.899-.773-.899-1.245 0-.726.787-1.5 2-1.5.352 0 .685.082.983.21l.453.194.2-.45ZM24 8.827l.334.301 6.666 6V12h5v7.627l8.334 7.501.969.872H41v18H7V28H2.697l.969-.872 20-18 .334-.3ZM8 45V28h32v17H29V33H19v12H8Zm34.697-18-7.532-6.778-.165-.15V13h-3v4.373l-.834-.751L24 10.172 5.303 27h37.394ZM28 45V34h-8v11h8Zm4.5-12H32v5h5v-5h-4.5Zm.5 4v-3h3v3h-3Zm-22-4h5v5h-5v-5Zm1 1v3h3v-3h-3Zm14 6h-2v-1h2v-1h1v3h-1v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHouseLarge;
