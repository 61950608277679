import type { SVGProps } from "react";
const SvgUserHelmetMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 4.75c-3.385 0-6.125 2.756-6.125 6.151v1.349h-1.398v1H13v.25c0 1.46.48 2.82 1.285 3.85-4.254 1.95-6.535 7.153-6.535 11.9v.5h20.5v-.5c0-4.773-2.284-9.961-6.535-11.9C22.519 16.32 23 14.96 23 13.5v-.25h2.477v-1h-1.352v-1.349c0-3.395-2.74-6.151-6.125-6.151Zm5.125 7.5v-1.349A5.148 5.148 0 0 0 21.5 7.139V10.5h-1V6.404A5.082 5.082 0 0 0 18 5.75c-.907 0-1.76.237-2.5.653V10.5h-1V7.138a5.148 5.148 0 0 0-1.625 3.763v1.349h10.25ZM14 13.5v-.25h8v.25c0 2.698-1.86 4.75-4 4.75-2.14 0-4-2.052-4-4.75Zm6.979 4.622c-.824.705-1.848 1.128-2.979 1.128s-2.156-.423-2.98-1.129c-3.825 1.552-6.103 6.132-6.261 10.629H27.24c-.157-4.522-2.44-9.088-6.262-10.628Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserHelmetMedium;
