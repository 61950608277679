import type { SVGProps } from "react";
const SvgSurveillanceCamerasTrainSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 2h-5v1h1.733l1.333 2H17a3 3 0 0 0-3 3v10h2.191l-.5 1h-.69v1h.19l-.5 1h-.69v1h8v-1h-6.192l.5-1H22v-1h-5.19l.5-1H22v-1h-7v-4h7v-1h-7V8a2 2 0 0 1 2-2h5V5h-.732l-1.334-2H22V2ZM3.677 4.388l.46.131 7 2 .66.188-.38.57-.138.206.88.293.515.171-.214.5-.75 1.75-.197.46-.46-.197-1.368-.587-.269.404-.212.318-.362-.12L7 9.86v2.477l4.137 1.182.66.188-.38.57-.138.206.88.293.515.171-.214.5-.75 1.75-.197.46-.46-.197-1.368-.587-.269.404-.212.318-.362-.12L7 16.86V20H4v-1h2v-2.473l-3.158-1.053-.474-.158.158-.474 1-3 .151-.454.46.131L6 12.051V9.527L2.842 8.474l-.474-.158.158-.474 1-3 .151-.454Zm6.573 4.64.454-.683.622.207-.338.791-.738-.316ZM4.323 5.611l-.69 2.072 5.163 1.72 1.408-2.111-5.88-1.68Zm6.665 10.731-.738-.316.454-.682.622.207-.338.791Zm-7.355-1.66.69-2.07 5.881 1.68-1.408 2.112-5.163-1.721ZM21 7h-2v1h2V7Zm-3 8Zm-1 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSurveillanceCamerasTrainSmall;
