import type { SVGProps } from "react";
const SvgContactLineArrowTrainLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 3h44V2H2v1Zm25.777 7.084-7.5-5-.554.832 6.876 4.584-6.75 4.5H17v1h12.568a9.795 9.795 0 0 1 9.043 6.007H28.008v9.986h14.275l1.382 3.768.01.026.012.025c.197.395.314.825.314 1.279C44 38.693 42.686 40 41.054 40H2v1h3.022a5.496 5.496 0 0 0 2.315 4H2v1h44v-1H33.663a5.496 5.496 0 0 0 2.315-4h5.076C43.228 41 45 39.255 45 37.09c0-.618-.157-1.19-.407-1.7L39.77 22.235A10.797 10.797 0 0 0 29.568 15H21.65l6.126-4.084.624-.416-.624-.416Zm1.216 12.909h9.99l2.938 8.014H28.993v-8.014ZM13.663 45h13.674a5.496 5.496 0 0 1-2.315-4h-9.044a5.496 5.496 0 0 1-2.315 4Zm-7.636-4a4.5 4.5 0 0 0 8.945 0H6.027ZM30.5 45a4.5 4.5 0 0 1-4.473-4h8.945a4.5 4.5 0 0 1-4.472 4ZM10 8.207l5.146 5.147.708-.708-6-6-.354-.353-.354.353-6 6 .708.708L9 8.207V22h1V8.207Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgContactLineArrowTrainLarge;
