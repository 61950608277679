import type { SVGProps } from "react";
const SvgChevronSmallEndLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24.66 11.788-5.924 5.856-.36.356.36.355 5.922 5.856.703-.71L19.798 18l5.566-5.5zM13.5 13.5v9h-1v-9z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallEndLeftMedium;
