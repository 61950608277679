import type { SVGProps } from "react";
const SvgLocationPinSurroundingAreaPowerPlugSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2.015a6 6 0 0 0-6 6v.02c.041 1.038.35 1.913.837 2.723l.007.01 4.75 7.498.422.667.422-.667 4.75-7.498.007-.01.005-.01c.473-.828.791-1.72.8-2.729v-.004a6 6 0 0 0-6-6Zm-4.308 8.223c-.41-.684-.657-1.393-.692-2.233a5 5 0 0 1 10 .007c-.007.789-.253 1.51-.663 2.231l-4.321 6.821-4.324-6.826Zm1.053 4.94c-1.87.212-3.5.603-4.677 1.123-.588.26-1.088.562-1.449.911-.362.35-.62.783-.62 1.288 0 .642.413 1.165.945 1.563.541.404 1.293.749 2.18 1.03C6.9 21.657 9.328 22 12 22c2.671 0 5.1-.343 6.877-.907.886-.281 1.638-.626 2.179-1.03.532-.398.944-.921.944-1.563 0-.501-.254-.932-.611-1.28-.357-.347-.85-.648-1.43-.906-1.162-.519-2.771-.91-4.62-1.126l-.115.993c1.79.209 3.29.583 4.327 1.046.52.232.899.475 1.14.71.24.233.309.422.309.563 0 .18-.117.443-.543.762-.417.311-1.052.614-1.883.878-1.655.525-3.977.86-6.574.86s-4.919-.335-6.575-.86c-.83-.264-1.465-.567-1.882-.878C3.117 18.943 3 18.68 3 18.5c0-.142.07-.333.314-.569.245-.237.63-.482 1.158-.715 1.052-.465 2.574-.84 4.386-1.044l-.113-.994ZM14 6V5h-.5l-1.002.001v.5-.5c-1.21 0-2.217.859-2.448 1.999H8v1h2.05c.232 1.14 1.241 2 2.45 2H14V9h1.5V8H14V7h1.5V6H14Zm-1.502.001H13V9h-.5a1.5 1.5 0 0 1-.002-2.999Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSurroundingAreaPowerPlugSmall;
