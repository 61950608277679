import type { SVGProps } from "react";
const SvgCloudDenseFogMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.06 16.06a2.915 2.915 0 0 0-1.88-1.076c.246-1.253.065-2.373-.417-3.293a4.663 4.663 0 0 0-2.555-2.204c-1.997-.725-4.471-.138-5.8 2.23-2.192-1.226-5.127.33-5.134 3.133-.744.144-1.342.586-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.841 1.073 2.548.708.713 1.75 1.156 3.125 1.156h10.5c1.36 0 2.411-.443 3.14-1.146a3.865 3.865 0 0 0 1.158-2.527c.062-.904-.184-1.824-.739-2.518Zm-10.002-3.407c1.01-2.29 3.123-2.838 4.81-2.226.848.308 1.58.908 2.01 1.728.426.814.572 1.879.193 3.153l-.2.67.7-.028c.777-.03 1.34.274 1.707.734.378.473.57 1.137.523 1.826a2.866 2.866 0 0 1-.855 1.875c-.52.502-1.314.866-2.446.866H12c-1.15 0-1.922-.364-2.415-.86-.498-.503-.753-1.184-.784-1.887-.03-.707.167-1.4.528-1.905.355-.495.855-.798 1.471-.798h.563l-.066-.56c-.296-2.473 2.358-3.737 3.986-2.403l.51.416.265-.601ZM7.5 25.25h19.49v-1H7.5v1Zm19.49 6H7.5v-1h19.49v1ZM9 28.25h16.49v-1H9v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudDenseFogMedium;
