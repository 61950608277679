import type { SVGProps } from "react";
const SvgGexNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="gex-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.856 9.605h5.173l-1.041 4.977a9.018 9.018 0 0 1-2.244 1.005 9.108 9.108 0 0 1-2.708.411c-1.43 0-2.526-.324-3.289-.973-1.04-.886-1.561-2.17-1.561-3.853a8.093 8.093 0 0 1 .67-3.244 6.597 6.597 0 0 1 2.287-2.896 5.96 5.96 0 0 1 3.486-1.03c1.335 0 2.406.312 3.213.935.807.622 1.342 1.53 1.605 2.722l-2.216.253a2.798 2.798 0 0 0-.942-1.48 2.612 2.612 0 0 0-1.637-.498 3.805 3.805 0 0 0-3.62 2.46 6.843 6.843 0 0 0-.544 2.746c0 .976.221 1.697.663 2.164.442.467 1.088.7 1.94.7a5.993 5.993 0 0 0 1.57-.217 7.146 7.146 0 0 0 1.387-.518l.355-1.725h-2.95l.403-1.939Zm5.602 6.196 2.413-11.6h8.572l-.402 1.938h-6.19l-.552 2.635h5.985l-.402 1.939h-5.986l-.725 3.149h6.727l-.41 1.939h-9.03Zm9.096 0h3.076l1.593-1.805c.56-.616 1.09-1.26 1.585-1.93.147.4.446 1.063.898 1.986l.868 1.749h2.681l-2.847-5.492L37.7 4.201h-2.99l-1.82 2.065a81.597 81.597 0 0 0-1.616 1.93 1.64 1.64 0 0 0-.103-.213l-.773-1.606L29.27 4.2h-2.484l2.87 5.728-5.102 5.872Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGexNegative;
