import type { SVGProps } from "react";
const SvgLockerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4.5A2.5 2.5 0 0 1 6.5 3h5.207l.147.147.984.984.353.353-.353.354-1.015 1.016-.147.146H6.5A2.5 2.5 0 0 1 2 4.5Zm5 0c0-.171-.017-.338-.05-.5h4.343l.484.484-.515.516H6.95c.033-.161.05-.329.05-.5ZM4.5 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-2 5H2v14h20V8H2.5ZM3 21V9h18v12H3Zm2-7.5A1.5 1.5 0 0 1 6.5 12H10v-1h4v1h3.5a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 5 17.5v-4Zm1.5-.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-11Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLockerSmall;
