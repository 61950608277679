import type { SVGProps } from "react";
const SvgParkAndRailLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 10h38V9H5v1Zm38 26.02H5v1h38v-1ZM12.46 15H6v16h3.47v-5.77h3.62a9.047 9.047 0 0 0 2.32-.26 4.569 4.569 0 0 0 1.67-.89 4.839 4.839 0 0 0 1.73-3.94 4.74 4.74 0 0 0-2.09-4.22 5 5 0 0 0-1.69-.72 13.208 13.208 0 0 0-2.57-.2Zm-2.99 7.54V18h2.94a3.791 3.791 0 0 1 2.06.44 2 2 0 0 1 .87 1.8 2.18 2.18 0 0 1-.75 1.8 3.22 3.22 0 0 1-2 .5H9.47Zm9.36.67h3.08v-3.08h1.77v3.08h3.1V25h-3.1v3.08h-1.77V25h-3.08v-1.79ZM35.75 15H28v16h3.53v-6.29h3.1a3.45 3.45 0 0 1 2.37.65c.57.65.879 1.486.87 2.35.046.646.1 1.177.142 1.586.04.389.068.668.068.834.043.302.134.596.27.87H42l-.19-.19a4.77 4.77 0 0 1-.47-2.06c-.14-1.52-.25-2.48-.34-2.9a3.239 3.239 0 0 0-.48-1.18 2.7 2.7 0 0 0-1.66-1.18A3.74 3.74 0 0 0 40.73 22a4.38 4.38 0 0 0 .64-2.48 4.53 4.53 0 0 0-.54-2.22 4.079 4.079 0 0 0-1.56-1.58 6.999 6.999 0 0 0-3.52-.72Zm-4.26 7.15v-4.4H35a3.83 3.83 0 0 1 2.29.52 2 2 0 0 1 .71 1.67 2 2 0 0 1-.9 1.75 4.21 4.21 0 0 1-2.27.46h-3.34Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgParkAndRailLarge;
