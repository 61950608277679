import type { SVGProps } from "react";
const SvgScreenInsideTrainMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.25 5.75h2.293l5.707 5.708V31.5h1V11.043l-.146-.146-6-6-.147-.147H4.5v1h4.75v5h-3v13h19v-13h-3v-5Zm-1 0h-11v5h11v-5Zm-14 17v-11h17v11h-17Zm15.25-8H9v-1h13.5v1Zm-13.5 3h13.5v-1H9v1Zm13.5 3.001H9v-1h13.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScreenInsideTrainMedium;
