import type { SVGProps } from "react";
const SvgPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 12V4h-1v8H3v1h8v8h1v-8h8v-1h-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPlusSmall;
