import type { SVGProps } from "react";
const SvgArchiveBoxLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.89 5.188 29.5 4.7l-.39.488L26.86 8h-2.32l-.65-.812-.39-.488-.39.488-.65.812h-8.205l-.15.193-7 9-.105.135V42h28.75l.15-.2 6-8 .1-.133V8h-9.86l-2.25-2.812ZM14.745 9h6.916l-6.4 8H8.522l6.223-8ZM8 41V18h27v23H8Zm33-7.667L36 40V17.651l5-7.5v23.182ZM32.94 9h7.626L37.1 14.2 32.94 9Zm-7.6 0 .36.45.36-.45h-.72Zm11.178 6.072L35.232 17H31.74l-5.4-6.75L29.5 6.3l7.018 8.772ZM23.5 8.3l6.96 8.7H16.54l6.96-8.7ZM17 24.32a.32.32 0 0 1 .32-.32h8.36a.32.32 0 0 1 .32.32v1.36a.32.32 0 0 1-.32.32h-8.36a.32.32 0 0 1-.32-.32v-1.36Zm.32-1.32A1.32 1.32 0 0 0 16 24.32v1.36c0 .73.591 1.32 1.32 1.32h8.36A1.32 1.32 0 0 0 27 25.68v-1.36A1.32 1.32 0 0 0 25.68 23h-8.36Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArchiveBoxLarge;
