import type { SVGProps } from "react";
const SvgDogMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.75 8.25V7.043l-.854.853-5.882 5.883L9.712 15.25h-.025a10.877 10.877 0 0 1-.828-.045 10.079 10.079 0 0 1-1.963-.371c-.712-.217-1.381-.536-1.866-.992-.471-.443-.78-1.025-.78-1.828h-1c0 1.103.44 1.941 1.095 2.556.64.602 1.47.98 2.26 1.22.792.242 1.578.356 2.161.41.182.018.345.029.484.036V30h1V16.184l10.07-1.439.166-.024.118-.117 5.146-5.147v2l.146.147 4.147 4.146-2.293 2.293-1.146-1.147-.354-.353-.354.353-1.5 1.5-.146.147V30h1V18.957l1-1 1.146 1.147.354.353.354-.353 3-3 .353-.354-.353-.354-4.354-4.353V8.25Zm-11 13h-.007c-1.066.014-1.793.71-2.27 1.564-.475.85-.76 1.946-.935 2.995a22.908 22.908 0 0 0-.276 2.89 25.44 25.44 0 0 0-.014 1.217l.002.072v.026l.5-.014.5-.016v-.02l-.002-.065a24.378 24.378 0 0 1 .013-1.164 21.92 21.92 0 0 1 .263-2.761c.169-1.008.43-1.971.822-2.672.39-.697.85-1.043 1.408-1.052h5.496V30h1v-8.75h-6.5Zm9-8.25a1.25 1.25 0 1 0 0 2.501 1.25 1.25 0 0 0 0-2.5Zm-.25 1.25a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDogMedium;
