import type { SVGProps } from "react";
const SvgSurveillanceCamerasTrainLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M46 2H34.5v1h4.727l3.857 6H35c-3.34 0-6 2.864-6 6.333V38h4.191l-1.5 3H30v1h1.191l-1.5 3h-1.796v1H46v-1H30.81l1.5-3H46v-1H32.81l1.5-3H46v-1H30V26h16v-1H30v-9.667C30 12.36 32.266 10 35 10h11V9h-1.727l-3.857-6H46V2ZM5.455 6.888l.46.131 15.945 4.552.659.188-.38.57-.776 1.162 2.795.931.516.172-.214.5-1.709 3.983-.197.459-.459-.197-3.604-1.543-.908 1.36-.212.318-.362-.12-5.037-1.678v7.003l9.888 2.823.659.188-.38.57-.776 1.162 2.795.931.516.172-.214.5-1.709 3.982-.197.46-.459-.197-3.604-1.544-.908 1.361-.212.318-.362-.12-5.037-1.678V41H5v-1h5.972v-6.726l-7.63-2.541-.475-.158.159-.475 2.278-6.828.15-.453.461.13 5.057 1.445v-7.05l-7.63-2.542-.475-.158.159-.475 2.278-6.827.15-.454Zm13.6 10.062 1.732-2.596 2.54.845-1.298 3.024-2.974-1.273ZM6.101 8.112l-1.968 5.9 12.83 4.272 3.963-5.94L6.1 8.112Zm15.928 26.042-2.974-1.274 1.732-2.596 2.54.846-1.298 3.024ZM4.133 29.942l1.968-5.9 14.825 4.233-3.963 5.94-12.83-4.273ZM46 13h-6v1h6v-1Zm-9 17a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSurveillanceCamerasTrainLarge;
