import type { SVGProps } from "react";
const SvgArrowCircleTwoUsersSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10c-3.823 0-7.292-2.15-9-5.319V19H2v-4h4v1H3.773c1.49 2.956 4.687 5 8.227 5a9 9 0 1 0-9-9H2Zm11.5-7c-1.16 0-2.045.908-2.366 2.03C9.855 7.247 9 8.584 9 10c0 .75.24 1.477.655 2.028C8.043 12.985 7 15.128 7 17.5v.5h9v-.5a7.94 7.94 0 0 0-.142-1.5H18v-.5c0-2.372-1.043-4.515-2.655-5.472A3.406 3.406 0 0 0 16 8c0-1.547-1.02-3-2.5-3Zm2.092 10h1.39c-.144-2.078-1.179-3.694-2.441-4.265a2.16 2.16 0 0 1-.677.235c-.11.39-.288.75-.519 1.058 1.013.601 1.802 1.671 2.247 2.972Zm-3.052-2.265c-.31.169-.66.265-1.04.265s-.73-.096-1.04-.265c-1.263.571-2.298 2.187-2.443 4.265h6.966c-.145-2.078-1.18-3.694-2.443-4.265Zm1.458-2.854c-.043-1.26-.757-2.433-1.837-2.779C12.425 6.405 12.969 6 13.5 6c.73 0 1.5.786 1.5 2 0 .936-.459 1.618-1.003 1.88ZM11.5 8c-.73 0-1.5.786-1.5 2s.77 2 1.5 2 1.5-.786 1.5-2-.77-2-1.5-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleTwoUsersSmall;
