import type { SVGProps } from "react";
const SvgRe76Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="re-76-negative_svg__color-immutable"
    {...props}
  >
    <g fill="#fff" clipPath="url(#re-76-negative_svg__a)">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.982 1.982 0 0 1-.197-.625c-.037-.253-.087-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.708-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.17-.949-.514-1.202-.342-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm9.097 0v1.962c-.063.068-.25.282-.562.64-.432.496-.862 1.12-1.289 1.875-.427.749-.76 1.469-.997 2.16-.5 1.44-.804 3.082-.91 4.928h-2.388c.016-.87.166-1.836.45-2.896.29-1.06.689-2.12 1.195-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Zm9.105 2.887h-2.2c-.205-.891-.711-1.337-1.518-1.337-.855 0-1.461.525-1.82 1.574-.147.428-.266 1.074-.355 1.938.347-.469.717-.806 1.107-1.012.395-.206.87-.309 1.424-.309 1.086 0 1.959.385 2.618 1.155.628.728.941 1.64.941 2.737 0 1.303-.422 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.429-.514-3.188-1.542-.76-1.034-1.14-2.473-1.14-4.319 0-1.972.407-3.517 1.22-4.635.785-1.081 1.858-1.622 3.218-1.622 1.282 0 2.263.43 2.943 1.29.39.495.64 1.12.752 1.874Zm-3.916 2.65c-.554 0-.994.232-1.321.696-.295.411-.443.902-.443 1.471 0 .565.15 1.058.45 1.48.328.469.776.704 1.346.704.553 0 .999-.224 1.336-.673.317-.422.475-.92.475-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Z" />
    </g>
    <defs>
      <clipPath id="re-76-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe76Negative;
