import type { SVGProps } from "react";
const SvgMountainSunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5 9.251a5.75 5.75 0 0 0-.828 11.44l-5.606 9.811-.428.748h28.715l-.417-.744-10.5-18.75-.407-.728-.45.703-3.943 6.16-.754-.88a5.71 5.71 0 0 0 .368-2.01 5.75 5.75 0 0 0-5.75-5.75Zm4.615 6.865a4.75 4.75 0 1 0-4.9 3.627l2.85-4.99.353-.617.462.539 1.235 1.44ZM8.726 30.25l7.347-11.48-2.49-2.906L5.361 30.25h3.364Zm21.921 0H9.914l6.49-10.14 1.242 1.244.255.254.323-.16 2.677-1.34 1.245 1.245.289.29.34-.226 2.136-1.41 5.736 10.243Zm-9.676-17.277-4.016 6.275 1.144 1.144 2.677-1.34.323-.16.255.254 1.21 1.212 1.857-1.226-3.45-6.16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMountainSunMedium;
