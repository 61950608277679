import type { SVGProps } from "react";
const SvgTrainSignalSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.55 2h-.207l-.146.146-1.05 1.05L8 3.343v15.314l.147.147 1.05 1.05.146.146H11v2h2v-2h1.657l.147-.146 1.05-1.05.146-.147V3.343l-.146-.147-1.05-1.05L14.657 2H9.55ZM9 3.757 9.757 3h4.486l.757.757v14.486l-.757.757H9.757L9 18.243V3.757ZM12 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2 3a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 6a1 1 0 1 0 .001 2.001A1 1 0 0 0 12 15Zm-2 1a2 2 0 1 1 4.001.001A2 2 0 0 1 10 16Zm7-4h2v-1h-2v1ZM7 12H5v-1h2v1Zm-4 0h1v-1H3v1Zm3.354 2.354-2 2-.708-.708 2-2 .708.708Zm14 1.292-2-2-.708.708 2 2 .708-.708Zm-16-9 2 2-.708.708-2-2 .708-.708ZM21 12h-1v-1h1v1Zm-2.646-2.646 2-2-.708-.708-2 2 .708.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrainSignalSmall;
