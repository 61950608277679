import type { SVGProps } from "react";
const SvgLocationPinSurroundingAreaSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 8.015a6 6 0 1 1 12 0v.004c-.009 1.01-.328 1.901-.8 2.73l-.005.01-.007.01-4.75 7.497-.422.667-.422-.667-4.75-7.498-.007-.01c-.486-.81-.796-1.685-.837-2.724v-.02Zm1-.01c.035.84.282 1.55.692 2.233l4.324 6.826 4.321-6.82c.41-.723.656-1.443.663-2.232a5 5 0 0 0-10-.007Zm5.008-2.998a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-4 3a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm.738 7.171c-1.871.212-3.501.603-4.678 1.123-.588.26-1.088.562-1.449.911-.362.35-.62.783-.62 1.288 0 .642.413 1.165.945 1.563.541.404 1.293.749 2.18 1.03C6.9 21.657 9.328 22 12 22c2.671 0 5.1-.343 6.877-.907.886-.281 1.638-.626 2.179-1.03.532-.398.944-.921.944-1.563 0-.501-.254-.932-.611-1.28-.357-.347-.85-.648-1.43-.906-1.162-.519-2.771-.91-4.62-1.126l-.115.993c1.79.209 3.29.583 4.327 1.046.52.232.899.475 1.14.71.24.233.309.422.309.563 0 .18-.117.443-.543.762-.417.311-1.052.614-1.883.878-1.655.525-3.977.86-6.574.86s-4.919-.335-6.575-.86c-.83-.264-1.465-.567-1.882-.878C3.117 18.943 3 18.68 3 18.5c0-.142.07-.333.314-.569.245-.237.63-.482 1.158-.715 1.052-.465 2.574-.84 4.386-1.044l-.113-.994Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinSurroundingAreaSmall;
