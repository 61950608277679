import type { SVGProps } from "react";
const SvgMuseumSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.1 2.01-.1-.02-.1.02-9.5 1.93-.4.081V7h2v10H3v2H2v3h20v-3h-1v-2h-1V7h2V4.021l-.4-.081-9.5-1.93ZM19 7v10h-2V7h2Zm-3 0v10h-2V7h2Zm-3 0v10h-2V7h2ZM8 17h2V7H8v10ZM7 7H5v10h2V7ZM3 6V4.839l9-1.829 9 1.829V6H3Zm0 15v-1h1v-2h16v2h1v1H3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMuseumSmall;
