import type { SVGProps } from "react";
const SvgIr67 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-67_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.554L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.02 4.216 5.935h4.598l-3.124-3.434h1.92Zm14.685-2.593c-.554 0-.995.232-1.321.696-.296.41-.443.901-.443 1.47 0 .565.15 1.058.45 1.48.327.47.776.704 1.345.704.554 0 1-.224 1.337-.672.316-.422.475-.92.475-1.495 0-.564-.143-1.047-.427-1.448-.348-.49-.82-.735-1.416-.735Zm3.915-2.65h-2.199c-.205-.891-.712-1.337-1.519-1.337-.854 0-1.46.525-1.819 1.574-.148.427-.266 1.073-.356 1.938.348-.47.719-.807 1.111-1.013.393-.205.867-.308 1.42-.308 1.087 0 1.96.385 2.618 1.155.628.728.942 1.64.942 2.737 0 1.302-.422 2.35-1.266 3.14-.76.712-1.671 1.068-2.737 1.068-1.365 0-2.428-.516-3.187-1.547-.76-1.03-1.14-2.469-1.14-4.314 0-1.973.406-3.518 1.219-4.636.785-1.08 1.859-1.621 3.219-1.621 1.282 0 2.262.43 2.943 1.289.39.496.64 1.12.751 1.875Zm8.923-2.888v1.962c-.064.069-.251.282-.562.641-.433.496-.862 1.119-1.29 1.87a12.865 12.865 0 0 0-.996 2.164c-.501 1.44-.804 3.082-.91 4.928h-2.388c.015-.87.167-1.835.454-2.895.288-1.06.685-2.12 1.19-3.18.723-1.498 1.456-2.613 2.2-3.346h-5.458V4.235h7.76Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIr67;
