import type { SVGProps } from "react";
const SvgScooterProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.858 12.014a4.48 4.48 0 0 1 2.447.966l-.624.782A3.5 3.5 0 0 0 15 16.499v.5H8.95a2.502 2.502 0 0 1-4.9 0H2.014L2 16.514c-.055-1.922.788-3.526 1.813-4.648a7.089 7.089 0 0 1 1.62-1.328c.53-.31 1.077-.516 1.544-.537h3.372l-.411.741-.001.002-.007.012a6.142 6.142 0 0 0-.132.27 8.87 8.87 0 0 0-.325.814c-.2.578-.395 1.33-.455 2.16h2.739l4.635-5.915L15.648 6H14V5h2.352l.119.332 2.387 6.682ZM16.79 9.2l-4.396 5.609-.15.191H8v-.5c0-1.17.266-2.226.527-2.985.066-.19.131-.362.193-.514H7.013c-.24.014-.62.135-1.075.401a6.09 6.09 0 0 0-1.387 1.14c-.805.88-1.451 2.067-1.542 3.458h11.019a4.502 4.502 0 0 1 3.781-3.946L16.79 9.199ZM5.085 17a1.502 1.502 0 0 0 2.83 0h-2.83ZM18.5 15a1.5 1.5 0 1 0 .001 3.002A1.5 1.5 0 0 0 18.5 15ZM16 16.5a2.5 2.5 0 1 1 5.002.002A2.5 2.5 0 0 1 16 16.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScooterProfileSmall;
