import type { SVGProps } from "react";
const SvgDocumentLockMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.75 6.25h-.5v23.5H18v-1h-7.75V7.25h8v7.5h7.5v1.75h1v-2.457l-.146-.147-7.5-7.5-.147-.146H9.75Zm15.293 7.5L19.25 7.957v5.793h5.793ZM21 19.25h-7.5v-1H21v1Zm-7.504 3h4.5v-1h-4.5v1Zm4.504 3h-4.5v-1H18v1Zm5.75-5a1 1 0 1 1 2 0v2.5h-2v-2.5Zm-1 2.5v-2.5a2 2 0 1 1 4 0v2.5h3v8.5h-10v-8.5h3Zm2.5 2.703V28.5h-1v-3.047h1Zm-4.5 4.797v-6.5h8v6.5h-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentLockMedium;
