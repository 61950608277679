import type { SVGProps } from "react";
const SvgUserHeadsetDisplaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.842 6H14c.283 0 .553.093.749.307.188.206.251.465.251.693v2a.99.99 0 0 1-.294.731c-.2.192-.459.269-.706.269h-1.964c-.1.199-.213.387-.34.563a7.9 7.9 0 0 1 1.466 1.068l-.675.738a6.82 6.82 0 0 0-1.51-1.056A3.183 3.183 0 0 1 9 12c-.75 0-1.427-.257-1.98-.689C4.682 12.48 3.166 15.024 3.013 18H11v1H2v-.5c0-3.384 1.6-6.45 4.302-7.94a4.068 4.068 0 0 1-.338-.56H4c-.27 0-.514-.12-.686-.285A.993.993 0 0 1 3 9V7.006c0-.293.135-.543.307-.712C3.477 6.127 3.723 6 4 6h.075C5.463.623 12.685.782 13.842 6Zm-1.03 0C11.75 2.137 6.38 1.934 5.114 6h.85C6.554 4.82 7.657 4 9 4s2.447.82 3.036 2h.776ZM6.5 8c0-1.742 1.172-3 2.5-3s2.5 1.259 2.5 3c0 .356-.049.691-.138 1H9v1h1.87c-.466.624-1.145 1-1.87 1-1.328 0-2.5-1.259-2.5-3ZM4 8.987v-1.97A.06.06 0 0 1 4.017 7H5v2h-.985l-.002-.001A.064.064 0 0 1 4 8.987ZM13 9h1V7h-1v2Zm-1 4h10v7h-4v1h2v1h-6v-1h2v-1h-4v-7Zm1 1v5h8v-5h-8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserHeadsetDisplaySmall;
