import type { SVGProps } from "react";
const SvgIc51 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-51_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M44.056 4.235v2.057h-4.643l-.496 2.602a2.808 2.808 0 0 1 2.136-.886 3.677 3.677 0 0 1 2.223.689c.935.69 1.403 1.74 1.403 3.148 0 1.382-.499 2.476-1.498 3.282a4.283 4.283 0 0 1-2.79.95c-1.22 0-2.2-.329-2.94-.985-.742-.657-1.133-1.544-1.175-2.662h2.262c.026.377.163.738.394 1.036a1.917 1.917 0 0 0 2.901.016 2.41 2.41 0 0 0 .513-1.558 2.3 2.3 0 0 0-.536-1.594 1.824 1.824 0 0 0-1.427-.597 1.895 1.895 0 0 0-1.695.98l-2.058-.031 1.096-6.447h6.33Zm7.183 0V15.8h-2.302V8.23h-2.712V6.553a5.795 5.795 0 0 0 1.375-.174c.353-.1.684-.266.974-.49.426-.327.712-.879.86-1.654h1.805ZM9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
    />
  </svg>
);
export default SvgIc51;
