import type { SVGProps } from "react";
const SvgWineCheeseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 3.25h4v7.5H12a2.75 2.75 0 0 1 2.75 2.75V18h-1v-.25h-8v6.5H12v1H5.75v6.5H12v1H4.75V13.5a2.75 2.75 0 0 1 2.75-2.75h.25v-7.5Zm6 10.25v3.25h-8V13.5c0-.966.783-1.75 1.75-1.75h1.25v-7.5h2v7.5H12c.967 0 1.75.784 1.75 1.75Zm4 12a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.75-2.75a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm6.75 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-10-8h-.5v13h19v-4.203l-.248-.144a2.491 2.491 0 0 1-1.252-2.153c0-.918.503-1.716 1.252-2.153l.248-.144V19.75h-5.5V21a1.75 1.75 0 1 1-3.5 0v-1.25h-9.5Zm.5 12v-11h8V21a2.75 2.75 0 1 0 5.5 0v-.25h3.5v2.642c-.9.63-1.5 1.668-1.5 2.858 0 1.19.6 2.228 1.5 2.858v2.642h-17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWineCheeseMedium;
