import type { SVGProps } from "react";
const SvgIr17Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-17-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M42.292 4.235V15.8H39.99V8.23h-2.712V6.553a5.796 5.796 0 0 0 1.376-.174c.353-.1.683-.266.974-.49.425-.327.712-.878.859-1.654h1.805Zm11.068 0v1.962c-.062.069-.249.282-.56.64a11.58 11.58 0 0 0-1.284 1.872 12.88 12.88 0 0 0-.993 2.163 18.184 18.184 0 0 0-.907 4.928h-2.38c.032-.98.184-1.952.452-2.895a18.94 18.94 0 0 1 1.187-3.18c.72-1.498 1.45-2.613 2.191-3.346h-5.439V4.235h7.734ZM9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
    />
  </svg>
);
export default SvgIr17Negative;
