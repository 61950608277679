import type { SVGProps } from "react";
const SvgPunctualityLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28 3h-3v4.007a19.4 19.4 0 0 1 10.138 3.148l1.508-1.509.354-.353.353.353 4 4 .354.354-.354.354-1.335 1.335A19.415 19.415 0 0 1 44 26.5C44 37.27 35.27 46 24.5 46S5 37.27 5 26.5C5 15.897 13.461 7.272 24 7.007V3h-3V2h7v1Zm11.387 10.905a19.612 19.612 0 0 0-3.412-3.173L37 9.707 40.293 13l-.906.905ZM6 26.5C6 16.282 14.282 8 24.5 8 34.717 8 43 16.282 43 26.5 43 36.717 34.717 45 24.5 45 14.282 45 6 36.717 6 26.5ZM24.5 11H23v-1h1.5C33.613 10 41 17.387 41 26.5 41 35.612 33.613 43 24.5 43 15.388 43 8 35.612 8 26.5V26h1v.5C9 35.06 15.94 42 24.5 42 33.06 42 40 35.06 40 26.5 40 17.94 33.06 11 24.5 11Zm-4.134 24.341 14-15-.731-.682-13.62 14.592-5.635-6.576-.76.65 6 7 .364.425.381-.409ZM19.04 11.916l1.932-.518-.26-.966-1.931.518.259.966Zm-1.857.91-1.732 1-.5-.865 1.732-1 .5.866ZM12.48 16.6l1.415-1.414-.707-.707-1.415 1.414.707.707Zm-1.152 1.717-1 1.732-.866-.5 1-1.732.866.5Zm-2.187 5.62.518-1.932-.966-.259-.518 1.932.966.26Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPunctualityLarge;
