import type { SVGProps } from "react";
const SvgIr14Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-14-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-14-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm16.197-8.132v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.98-.49.428-.328.716-.879.863-1.654h1.812Zm11.501 6.969v1.954h-1.456v2.642H50.08v-2.643h-4.825V10.99l4.944-6.755h2.136v6.969h1.455Zm-3.663.024V6.922l-2.974 4.303h2.974Z"
      />
    </g>
    <defs>
      <clipPath id="ir-14-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr14Negative;
