import type { SVGProps } from "react";
const SvgTicketsClassLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 6h-.207l-.147.146-2.5 2.5-.146.147v1.209L11 10h-.207l-.147.146-2.5 2.5-.146.147V42h20v-4h12V6H23Zm4.5 4.004-6.5-.002v-.795L23.207 7H39v30H28v-3h3v-1h-3v-5h8v-1h-8v-3h8v-1h-8V10.004h-.5ZM24 28H12v-1h12v1Zm0 4H12v-1h12v1Zm-12 6h7v-1h-7v1Zm-.793-27L9 13.207V41h18V11.004L11.207 11Zm24.821-1v8.566h-1.113v-6.21h-2.162v-.774c.808 0 1.418-.152 1.828-.457.277-.207.494-.582.65-1.125h.797ZM24.006 21.316v.95h-5.572c.039-.844.258-1.547.656-2.11.18-.258.4-.496.662-.715.262-.218.668-.503 1.219-.855.805-.512 1.318-.902 1.541-1.172.328-.387.492-.824.492-1.312a1.76 1.76 0 0 0-.275-.985c-.301-.469-.756-.703-1.366-.703-.527 0-.93.186-1.21.557-.279.37-.432.924-.46 1.658h-1.025a6.96 6.96 0 0 1-.006-.223c0-.422.07-.822.211-1.2.14-.38.338-.7.592-.962.485-.496 1.135-.744 1.951-.744.754 0 1.37.205 1.846.615.258.223.458.506.6.85.143.344.214.71.214 1.101 0 .75-.253 1.4-.761 1.952-.336.363-.934.808-1.793 1.335-.672.415-1.134.752-1.386 1.011-.252.26-.425.577-.519.952h4.39Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketsClassLarge;
