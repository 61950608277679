import type { SVGProps } from "react";
const SvgSuitcaseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 4.009H9v3H3v13.99h18V7.01h-6v-3H9.5Zm4.5 3v-2h-4v2h4ZM4 19.999V8.01h16V20H4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSuitcaseSmall;
