import type { SVGProps } from "react";
const SvgEnvelopeOpenLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.321 2.617 24 2.347l-.321.27-18.5 15.5-.179.15V46h38V18.267l-.179-.15-18.5-15.5ZM6 19.707v24.586L18.293 32 6 19.707ZM6.707 45h34.586L24 27.707 6.707 45ZM42 44.293V19.707L29.707 32 42 44.293Zm-.24-25.76L24 3.653 6.24 18.532 19 31.293l4.646-4.647.354-.353.354.353L29 31.293l12.76-12.76Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEnvelopeOpenLarge;
