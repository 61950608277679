import type { SVGProps } from "react";
const SvgNetworkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 4c0-.574.426-1 1-1 .574 0 1 .426 1 1 0 .574-.426 1-1 1-.574 0-1-.426-1-1Zm1-2c-1.126 0-2 .874-2 2s.874 2 2 2c.303 0 .589-.063.843-.178L9.23 9.1A3.979 3.979 0 0 0 8 12c0 .94.315 1.8.847 2.478l-1.97 1.92A2.533 2.533 0 0 0 5.5 16 2.468 2.468 0 0 0 3 18.498 2.47 2.47 0 0 0 5.5 21a2.475 2.475 0 0 0 2.506-2.502 2.51 2.51 0 0 0-.415-1.398l1.967-1.919A4 4 0 0 0 12 16c1.442 0 2.692-.74 3.395-1.866l1.671.774A2.638 2.638 0 0 0 17 15.5c0 1.412 1.088 2.5 2.5 2.5s2.5-1.088 2.5-2.5-1.088-2.5-2.5-2.5c-.842 0-1.569.387-2.018.998l-1.668-.772c.121-.385.187-.797.187-1.226 0-.712-.18-1.377-.499-1.951l2.19-1.525A2 2 0 0 0 19 9c1.116 0 2.01-.876 2.01-2A1.993 1.993 0 0 0 19 4.995c-1.123 0-2 .887-2 2.005 0 .248.043.484.122.702l-2.211 1.54A3.99 3.99 0 0 0 12 8.004a4.02 4.02 0 0 0-1.964.504L7.62 5.19C7.86 4.86 8 4.45 8 4c0-1.126-.874-2-2-2Zm13 3.995c-.566 0-1 .435-1 1.005 0 .568.432 1 1 1a.99.99 0 0 0 1.01-1A.993.993 0 0 0 19 5.995Zm-7 3.009A2.97 2.97 0 0 0 9 12c0 1.678 1.322 3 3 3a2.974 2.974 0 0 0 3.001-3A2.971 2.971 0 0 0 12 9.004Zm6 6.496c0-.86.64-1.5 1.5-1.5s1.5.64 1.5 1.5-.64 1.5-1.5 1.5-1.5-.64-1.5-1.5ZM5.5 17c-.854 0-1.5.646-1.5 1.498A1.47 1.47 0 0 0 5.5 20c.855 0 1.506-.65 1.506-1.502 0-.85-.65-1.498-1.506-1.498Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNetworkSmall;
