import type { SVGProps } from "react";
const SvgDoubleChevronSmallLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.16 11.789-5.924 5.855-.36.356.36.355 5.922 5.856.703-.71-5.563-5.5 5.566-5.501-.703-.711Zm6.001 0-5.925 5.855-.36.356.36.355 5.922 5.856.704-.71-5.563-5.5 5.565-5.501-.703-.711Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDoubleChevronSmallLeftMedium;
