import type { SVGProps } from "react";
const SvgSledgeSnowshoeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 10.374a8.504 8.504 0 0 0 0 16.252v-4.384a4.495 4.495 0 0 1-2-3.742c0-1.56.794-2.935 2-3.742v-4.384Zm1 3.882c.47-.166.974-.256 1.5-.256s1.031.09 1.5.256v-4.195c-.485-.04-.985-.061-1.5-.061-.512 0-1.013.045-1.5.132v4.124Zm4 .502A4.498 4.498 0 0 1 19.973 18H26v-4.83c-2.418-1.382-4.938-2.568-8-2.998v4.586ZM19.973 19A4.498 4.498 0 0 1 18 22.242v4.586c3.063-.43 5.583-1.616 8.001-2.998V19h-6.029ZM17 22.744c-.469.166-.974.256-1.5.256s-1.03-.09-1.5-.256v4.124c.487.087.988.132 1.5.132.515 0 1.015-.021 1.5-.061v-4.195Zm10.001.502V19h8.102c-2.515.87-4.703 2.194-6.86 3.498l-.122.074c-.373.226-.746.451-1.12.674ZM41 19v-1c-5.04 0-8.596-2.15-12.304-4.393l-.057-.035C24.92 11.324 21.05 9 15.5 9A9.5 9.5 0 0 0 6 18.5a9.5 9.5 0 0 0 9.5 9.5c5.55 0 9.421-2.324 13.139-4.572l.057-.035C32.404 21.15 35.96 19 41 19Zm-13.999-1h8.102c-2.515-.87-4.703-2.194-6.86-3.498l-.122-.074c-.373-.226-.746-.451-1.12-.674V18ZM32.5 39c2.773 0 4.914-.453 6.364-1.597C40.343 36.236 41 34.435 41 32h-1c0 2.259-.603 3.708-1.756 4.618C37.064 37.55 35.205 38 32.5 38h-1.499v-4.999H34v-1H6v1h3V38H6v1h26.5Zm-2.499-5.999h-6V38h6v-4.999Zm-7 0V38h-6v-4.999h6Zm-7 0H10V38h6v-4.999ZM12 18.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSledgeSnowshoeLarge;
