import type { SVGProps } from "react";
const SvgServiceBellSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.98 5h-2V4h2v1ZM6.763 8.732C7.941 7.113 9.707 6 12 6s4.057 1.113 5.233 2.732c1.168 1.607 1.762 3.714 1.767 5.766V15H5v-.5c0-2.054.594-4.161 1.763-5.768ZM6.013 14h11.973c-.092-1.71-.628-3.395-1.562-4.68C15.408 7.92 13.927 7 12 7c-1.928 0-3.41.921-4.428 2.32-.935 1.285-1.471 2.97-1.56 4.68Zm-.653 2h-.362l-.113.343-.66 2-.217.657h15.987l-.221-.66-.67-2-.114-.34H5.36Zm.031 2 .33-1H18.27l.335 1H5.391Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgServiceBellSmall;
