import type { SVGProps } from "react";
const SvgLockClosedLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.5 3A6.5 6.5 0 0 0 17 9.5V18H8v27h31V18h-9V9.5A6.5 6.5 0 0 0 23.5 3ZM29 18V9.5a5.5 5.5 0 0 0-11 0V18h1V9.5a4.5 4.5 0 1 1 9 0V18h1Zm-9-8.5V18h7V9.5a3.5 3.5 0 1 0-7 0ZM9 44V19h29v25H9Zm16.116-19.613A3.5 3.5 0 1 0 22 30.654V37.5a1.5 1.5 0 1 0 3 0v-6.846a3.5 3.5 0 0 0 .116-6.267Zm-1.784.61a2.5 2.5 0 0 1 1.001 4.852l-.333.117V37.5a.5.5 0 0 1-1 0v-7.534l-.333-.117a2.5 2.5 0 0 1 .665-4.852Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLockClosedLarge;
