import type { SVGProps } from "react";
const SvgCupHotSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 3v4h1V3h-1Zm-5.5 7H5v4c0 2.702 1.65 5.02 3.996 6H4v1h15v-1h-4.996a6.52 6.52 0 0 0 3.682-4H19a2 2 0 0 0 0-4h-1v-2H5.5ZM19 15h-1.076c.05-.326.076-.66.076-1v-1h1a1 1 0 0 1 0 2ZM6 14v-3h11v3a5.5 5.5 0 0 1-11 0Zm8-6V4h1v4h-1ZM8 4v4h1V4H8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCupHotSmall;
