import type { SVGProps } from "react";
const SvgTagSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 6h-.501v.5l.007 4.3v.207l.146.147 9.494 9.499.354.353.353-.353 4.3-4.3.354-.353-.354-.354-9.512-9.5L9.995 6H5.5Zm.505 4.593L6 7h3.58l9.012 9L15 19.592l-8.995-9ZM8 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTagSmall;
