import type { SVGProps } from "react";
const SvgTicketPercentMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.25 6.25h29.5v7h-.5c-2.274 0-4 1.726-4 4 0 2.273 1.726 4 4 4h.5v7H3.25v-22Zm1 1v20h27.5v-5.023c-2.578-.24-4.5-2.32-4.5-4.977 0-2.658 1.922-4.738 4.5-4.977V7.25H4.25Zm6.246 2.996a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm-2.75 1.75a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0ZM14.75 22.5a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm1.75-2.75a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm4.104-8.897-13.5 13.5-.708-.707 13.5-13.5.708.707ZM24.25 7.5v.487h1V7.5h-1Zm0 1.462v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95V27h1v-.488h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketPercentMedium;
