import type { SVGProps } from "react";
const SvgPowerPlugSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 7H9V3H8v4H6v5c0 1.998 1.616 4 4 4v3h2v2h1v-2h2v-3c2.1 0 4-1.68 4-4V7h-2V3h-1v4Zm-5 11v-2h3v2h-3ZM7 8v4c0 1.507 1.228 3 3 3h5c1.582 0 3-1.266 3-3V8H7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPowerPlugSmall;
