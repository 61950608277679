import type { SVGProps } from "react";
const SvgBluetoothDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24 24.707-9.146 9.147-.707-.708 9.646-9.646L2.646 2.354l.708-.708 43 43-.707.708L34.5 34.207l-9.646 9.647-.854.853v-20Zm9.793 8.793L25 42.293V24.707l8.793 8.793Zm-8.94-30.354L24 2.293V20h1V4.707l8.793 8.793-7.146 7.146.707.708 7.5-7.5.353-.354-.353-.354-10-10Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBluetoothDisabledLarge;
