import type { SVGProps } from "react";
const SvgSaKb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.621 6.496 6.801 1H4L1 15h2.801l1.52-7.12L9.201 15h3.341L8.041 7.378 15.103 1h-3.901L5.661 6.496h-.04Zm14.04.16h-1.1l.74-3.45h.98c1.06 0 2.341.06 2.341 1.404 0 1.645-1.54 2.046-2.96 2.046Zm-1.42 6.138h-.96l.84-4.05h.9c1.5 0 2.86.219 2.86 1.684 0 2.165-1.92 2.366-3.64 2.366ZM21.202 1H17.06l-2.92 14h3.981c2.08 0 6.561-.32 6.561-4.332 0-1.505-.68-2.708-2.24-2.949v-.04c1.62-.26 2.98-1.624 2.98-3.329 0-2.548-2.04-3.35-4.22-3.35Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaKb;
