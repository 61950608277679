import type { SVGProps } from "react";
const SvgSaVn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m6.47 4.52-4-2.31.75-1.3L25.54 13.8l-.75 1.3-.95-.55c-.65.29-1.35.46-2.09.46-2.69 0-4.88-2.04-5.18-4.65l-1.48-.86-.7 1h-2.97c-.36 2.54-2.53 4.5-5.17 4.5C3.36 15 1 12.65 1 9.75S3.36 4.5 6.25 4.5c.041 0 .08.005.117.01.035.005.07.01.103.01ZM2.5 9.75c0 2.07 1.68 3.75 3.75 3.75h.01c1.81 0 3.32-1.29 3.67-3H4.66L8 6.45A3.7 3.7 0 0 0 6.25 6C4.18 6 2.5 7.68 2.5 9.75Zm6.65-2.34L7.84 9h2.08c-.12-.6-.4-1.13-.77-1.59ZM11.42 9h1.35l-.62-1.2-1.53-.88c.4.62.69 1.31.8 2.08Zm6.98 2.4c.61 1.24 1.88 2.1 3.35 2.1v-.01a.95.95 0 0 0 .127-.01c.038-.005.074-.01.113-.01L18.4 11.4ZM19.55 5c.67-.31 1.41-.5 2.2-.5h-.01c2.89 0 5.25 2.35 5.25 5.25 0 .95-.28 1.83-.72 2.6l-1.29-.75c.32-.55.51-1.17.51-1.85 0-2.07-1.68-3.75-3.75-3.75-.48 0-.94.1-1.37.27l2 3.07-.61.4-1.47-.85-1.18-1.81c-.23.24-.42.51-.59.79l-1.3-.75c.29-.48.65-.92 1.07-1.29l-.32-.49-1.1 1.58-1.3-.75.99-1.42h-3.44L9.49 2.66l-.08-.16h-.19L8 1.79V1h4v1.5h-.9l.39.75h5.76V1H21v1.5h-2.25v1.28l.8 1.22Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVn;
