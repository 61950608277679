import type { SVGProps } from "react";
const SvgSn10 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="sn-10_svg__color-immutable"
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.792 0h55.416C58.197 0 59 .806 59 1.8v16.4c0 .994-.801 1.8-1.792 1.8H1.792A1.797 1.797 0 0 1 0 18.2V1.8C0 .806.803 0 1.792 0Z"
    />
    <path
      fill="#FFDE15"
      d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429Zm7.404 0v11.565h-2.31v-7.57h-2.721V6.552c.564-.016 1.023-.074 1.376-.174.359-.1.686-.264.981-.49.427-.328.715-.879.862-1.654h1.812Zm7.285-.277c.88 0 1.656.269 2.326.807.664.538 1.147 1.366 1.447 2.484.227.854.34 1.782.34 2.784 0 1.661-.295 3.027-.886 4.098-.348.622-.804 1.102-1.368 1.44a3.556 3.556 0 0 1-1.875.505c-.944 0-1.75-.284-2.42-.854-1.192-1.012-1.788-2.742-1.788-5.189 0-1.74.311-3.138.933-4.192a3.771 3.771 0 0 1 1.385-1.393 3.788 3.788 0 0 1 1.906-.49Zm-.087 1.954c-.76 0-1.276.438-1.55 1.313-.216.696-.325 1.672-.325 2.927 0 1.107.111 1.996.333 2.665.284.87.812 1.306 1.582 1.306.638 0 1.112-.338 1.423-1.013.312-.675.467-1.706.467-3.093 0-1.181-.11-2.112-.332-2.792-.285-.875-.818-1.313-1.598-1.313Z"
    />
  </svg>
);
export default SvgSn10;
