import type { SVGProps } from "react";
const SvgTimetableMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 5.75h10.5v-1H3v1Zm13.25 17.5c0-3.86 3.14-7 7-7s7 3.14 7 7c0 3.858-3.14 7-7 7s-7-3.142-7-7Zm7-8c-4.412 0-8 3.589-8 8 0 4.41 3.588 8 8 8s8-3.59 8-8c0-4.411-3.588-8-8-8Zm-.75-9.5h-6v-1h6v1Zm-6 4.5h6v-1h-6v1Zm16.5 0h-7.5v-1H33v1Zm-7.5-4.5H33v-1h-7.5v1Zm-12 4.5H3v-1h10.5v1ZM3 14.75h10.5v-1H3v1Zm7.5 4.5H3v-1h7.5v1ZM3 23.75h7.5v-1H3v1Zm7.5 4.5H3v-1h7.5v1ZM22.75 18v5.75h5.75v-1h-4.75V18h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTimetableMedium;
