import type { SVGProps } from "react";
const SvgControlsXMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#controls-x-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.75 7.25a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-9.75 3h6.285a3.5 3.5 0 0 0 6.93 0H33v-1H16.215a3.5 3.5 0 0 0-6.93 0H3v1Zm16.784 8c.233-1.724 1.665-3 3.466-3 1.8 0 3.233 1.276 3.466 3H33v1h-7.25v-.5c0-1.419-1.081-2.5-2.5-2.5s-2.5 1.081-2.5 2.5v.5H3v-1h16.784Zm-9.534 9.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm2.5-3.5a3.5 3.5 0 0 0-3.465 3H3v1h6.285a3.5 3.5 0 0 0 6.93 0h3.315v-1h-3.315a3.501 3.501 0 0 0-3.465-3Zm15 4.207-4.896 4.897-.708-.708 4.897-4.896-4.897-4.896.708-.708 4.896 4.897 4.896-4.897.708.708-4.897 4.896 4.897 4.896-.708.708-4.896-4.897Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="controls-x-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgControlsXMedium;
