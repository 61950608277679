import type { SVGProps } from "react";
const SvgSaPa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9.2 1 8.198 14H1L9.2 1Zm5.346 12.6-2.067-3.5h-6.56l-2.067 3.5h10.694Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaPa;
