import type { SVGProps } from "react";
const SvgChevronSmallEndRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.627 11.644 7.677 7.74l-.702.712L10.564 12l-3.59 3.548.703.712 3.95-3.904.36-.356zM16 9v6h-1V9z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallEndRightSmall;
