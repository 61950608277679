import type { SVGProps } from "react";
const SvgCloudRainSnowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.312 9.856c.492.112.894.382 1.182.75.393.502.555 1.16.506 1.796a2.794 2.794 0 0 1-.825 1.786C16.66 14.69 15.927 15 15 15H8c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.167-.764-1.808-.028-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.816c.092-1.85 2.006-2.895 3.522-2.176.946-1.538 2.625-1.917 3.988-1.422.745.27 1.403.803 1.794 1.549.318.607.447 1.335.322 2.14Zm-2.457-2.748c-1.049-.38-2.362-.046-2.997 1.394l-.266.602-.509-.417c-.967-.792-2.566-.048-2.386 1.454l.066.56H7.2c-.348 0-.634.168-.845.462-.217.303-.34.728-.32 1.165.018.435.175.847.473 1.148.293.295.763.524 1.492.524h7c.691 0 1.167-.226 1.477-.528.317-.309.493-.725.526-1.148.033-.427-.08-.826-.296-1.102-.205-.261-.522-.44-.987-.422l-.698.028.199-.67c.241-.812.145-1.476-.117-1.977a2.276 2.276 0 0 0-1.25-1.073ZM16 17a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm1 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-7.947-4.224-1 2 .894.448 1-2-.894-.448Zm3 2 1-2 .894.448-1 2-.894-.448Zm-1-2-2 4 .894.448 2-4-.894-.448Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSnowSmall;
