import type { SVGProps } from "react";
const SvgChartColumnMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27.75 6.25h-.5v22h5.5v-22h-5Zm.5 21v-20h3.5v20h-3.5Zm-15.5-16.5h-.5v17.5h5.5v-17.5h-5Zm.5 16.5v-15.5h3.5v15.5h-3.5Zm-8.5-4.5h5.5v5.5h-5.5v-5.5Zm1 1v3.5h3.5v-3.5h-3.5ZM33 31.25H4.5v-1H33v1Zm-13.25-17.5h5.5v14.5h-5.5v-14.5Zm1 1v12.5h3.5v-12.5h-3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChartColumnMedium;
