import type { SVGProps } from "react";
const SvgRouteCircleEndSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.5 7a5.5 5.5 0 1 0 .002 11.002A5.5 5.5 0 0 0 14.5 7ZM3 13h5.019a6.5 6.5 0 1 0 0-1H3v1Zm11.5 3a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRouteCircleEndSmall;
