import type { SVGProps } from "react";
const SvgIcn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="icn_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.842 6.502Zm0 0 .58-1.947c-.32-.11-.572-.191-.754-.24a7.777 7.777 0 0 0-.522-.126 10.45 10.45 0 0 0-1.028-.141c-.41-.039-.78-.058-1.111-.058a7.372 7.372 0 0 0-2.694.474 5.905 5.905 0 0 0-3.44 3.41 7.15 7.15 0 0 0-.481 2.67c0 1.73.492 3.1 1.475 4.11.907.886 2.2 1.33 3.88 1.33.391-.002.781-.03 1.169-.083a16.35 16.35 0 0 0 1.268-.216l.365-2.23c-.38.227-.782.411-1.202.55a3.944 3.944 0 0 1-1.152.166 3.483 3.483 0 0 1-1.385-.266 2.933 2.933 0 0 1-1.06-.766 3.524 3.524 0 0 1-.68-1.197 4.73 4.73 0 0 1-.241-1.547 5.01 5.01 0 0 1 .332-1.855 4.275 4.275 0 0 1 .928-1.448 4.148 4.148 0 0 1 1.418-.94 4.79 4.79 0 0 1 1.815-.332 4.91 4.91 0 0 1 1.26.15 5.7 5.7 0 0 1 1.26.532ZM6.606 4.189l-2.42 11.612h2.42L9.044 4.19H6.606Zm12.17 11.612L21.263 4.19h2.934l2.47 8.817h.034l1.89-8.817h2.172l-2.487 11.612h-2.984l-2.421-8.9h-.017l-1.857 8.9h-2.221Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcn;
