import type { SVGProps } from "react";
const SvgChevronSmallUpSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.996 9.013.356.36 3.904 3.95-.711.703-3.549-3.59-3.548 3.589-.711-.703 3.904-3.949.355-.36Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallUpSmall;
