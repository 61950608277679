import type { SVGProps } from "react";
const SvgSpeechBubbleExclamationPointMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.25 9.25h25v17.5H14.832l-3.228 3.229-.854.853V26.75h-4.5V9.25Zm1 1v15.5h4.5v2.668l2.521-2.522.147-.146H30.25v-15.5h-23Z"
      clipRule="evenodd"
    />
    <path stroke="currentColor" d="M18.5 12v7.5m0 2.25V24" />
  </svg>
);
export default SvgSpeechBubbleExclamationPointMedium;
