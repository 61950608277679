import type { SVGProps } from "react";
const SvgTwoSpeechBubblesSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 5H3v10h3v2.957l.854-.853L8.957 15H9v3h6.043l2.103 2.104.854.853V18h3V8h-5V5H3.5ZM16 13v-1h3v-1h-3V9h4v8h-3v1.543l-1.396-1.397-.147-.146H10v-2h6v-1h2v-1h-2ZM4 14V6h11v8H8.543l-.147.146L7 15.543V14H4Zm2-5h7V8H6v1Zm6 2H6v-1h6v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoSpeechBubblesSmall;
