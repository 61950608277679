import type { SVGProps } from "react";
const SvgArrowsLongUpDownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16.858 19.84 3.14-3.143-.707-.707-2.287 2.288V3.995h-1v14.286l-2.296-2.291-.706.708 3.149 3.142.353.353.354-.354Zm-9-15.689 3.14 3.142-.707.707-2.287-2.288v14.283h-1V5.71L4.708 8l-.706-.707L7.15 4.15l.353-.353.354.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLongUpDownSmall;
