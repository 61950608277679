import type { SVGProps } from "react";
const SvgCurriculumVitaeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m28.875 5.088.219.45L30.782 9H37v12.749l5.245 10.753.22.45-.45.219L37 35.616V40h-8.988l-7.569 3.691-.45.22-.218-.45L18.087 40H12V27.518L6.624 16.497l-.22-.45.45-.219L12 13.32V9h8.855l7.57-3.692.45-.22ZM29.669 9l-1.255-2.575L23.136 9h6.533ZM13 27.404V10h23v29H13V27.404Zm-1-12.972-4.257 2.076L12 25.238V14.431ZM19.2 40l1.255 2.573L25.731 40H19.2ZM37 34.503l4.128-2.012L37 24.03v10.473ZM20 14c-.797 0-1.427.637-1.427 1.5 0 .864.63 1.5 1.428 1.5.802 0 1.428-.63 1.428-1.489 0-.867-.633-1.51-1.428-1.51Zm-2.427 1.5c0-1.376 1.039-2.5 2.428-2.5 1.392 0 2.428 1.138 2.428 2.511 0 .496-.134.956-.37 1.343.78.558 1.453 1.402 1.955 2.399A9.535 9.535 0 0 1 25 23.5v.5H15v-.5c0-1.56.376-3.036.988-4.25.503-.997 1.178-1.84 1.96-2.398a2.581 2.581 0 0 1-.375-1.353Zm1.072 2.088c-.661.434-1.285 1.165-1.764 2.114a8.463 8.463 0 0 0-.866 3.3h7.97a8.475 8.475 0 0 0-.864-3.3c-.478-.949-1.1-1.68-1.76-2.114A2.4 2.4 0 0 1 20 18a2.39 2.39 0 0 1-1.356-.412ZM34 16h-7v-1h7v1Zm0 4h-7v-1h7v1Zm0 4h-7v-1h7v1Zm0 4H15v-1h19v1Zm0 4H15v-1h19v1Zm-15 4h-2v-1h2v1Zm3 0h-2v-1h2v1Zm3 0h-2v-1h2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCurriculumVitaeLarge;
