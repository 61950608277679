import type { SVGProps } from "react";
const SvgRoofBedSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.444 2.113 12.22 2l-.224.112-10 5 .448.894L12.22 3.12l9.776 4.888.448-.894-10-5ZM2.22 21.56v-11h1v6h6v-4h10.5a2.5 2.5 0 0 1 2.5 2.5v6.5h-1v-4h-18v4h-1Zm19-5h-11v-3h9.5a1.5 1.5 0 0 1 1.5 1.5v1.5Zm-16-3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRoofBedSmall;
