import type { SVGProps } from "react";
const SvgEv96 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-96_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.145 8.688h2.19c.18.9.701 1.349 1.563 1.349.487 0 .881-.164 1.183-.492.306-.333.542-.849.706-1.547.106-.444.182-.95.23-1.516-.354.498-.72.852-1.095 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.553 3.553 0 0 1-1.031-1.357 4.459 4.459 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.768-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.296 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.586-.865-.678-.577-1.069-1.351-1.175-2.325Zm3.857-7.006c-.498 0-.918.204-1.262.61-.344.413-.516.937-.516 1.572 0 .513.125.968.373 1.365.339.545.815.817 1.428.817.556 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.16-1.124-.484-1.547a1.61 1.61 0 0 0-1.34-.66Zm12.903 1.214H40.01c-.206-.894-.714-1.341-1.523-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.47.72-.81 1.11-1.016.398-.206.874-.31 1.43-.31 1.089 0 1.964.387 2.626 1.16.629.73.944 1.644.944 2.745 0 1.306-.423 2.356-1.27 3.15-.762.714-1.677 1.07-2.745 1.07-1.37 0-2.436-.515-3.198-1.546-.762-1.037-1.142-2.481-1.142-4.333 0-1.978.407-3.528 1.221-4.65.789-1.084 1.865-1.626 3.23-1.626 1.285 0 2.27.431 2.951 1.293.392.498.643 1.124.754 1.88ZM38.29 9.724c-.556 0-.997.233-1.325.698-.297.413-.445.905-.445 1.476 0 .566.151 1.06.453 1.484.328.47.777.706 1.348.706.556 0 1.003-.225 1.341-.674.318-.424.477-.923.477-1.5 0-.566-.143-1.05-.429-1.452-.35-.492-.823-.738-1.42-.738Z"
    />
  </svg>
);
export default SvgEv96;
