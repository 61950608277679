import type { SVGProps } from "react";
const SvgIr68 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-68_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-68_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.38-5.245h-2.2c-.205-.891-.711-1.337-1.518-1.337-.854 0-1.46.525-1.82 1.574-.147.428-.266 1.074-.355 1.938.348-.469.717-.806 1.107-1.012.396-.206.87-.309 1.424-.309 1.086 0 1.959.385 2.618 1.155.628.728.941 1.64.941 2.737 0 1.303-.421 2.35-1.265 3.14-.76.712-1.672 1.068-2.737 1.068-1.366 0-2.428-.514-3.188-1.542-.76-1.034-1.139-2.473-1.139-4.319 0-1.972.406-3.517 1.218-4.635.786-1.081 1.86-1.622 3.22-1.622 1.281 0 2.262.43 2.942 1.29.39.495.641 1.12.752 1.874Zm-3.916 2.65c-.553 0-.994.232-1.32.696-.296.411-.444.902-.444 1.471 0 .565.15 1.058.451 1.48.327.469.775.704 1.345.704.554 0 1-.224 1.337-.673.316-.422.474-.92.474-1.495 0-.564-.142-1.047-.427-1.447-.348-.49-.82-.736-1.416-.736Zm6.811-.181c-.538-.186-.952-.489-1.242-.91-.285-.422-.427-.934-.427-1.535 0-1.002.411-1.801 1.234-2.397.727-.527 1.592-.791 2.594-.791 1.14 0 2.07.314 2.793.941.685.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.416.121.757.277 1.02.467.733.532 1.1 1.31 1.1 2.333 0 1.176-.451 2.112-1.353 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.194-1.547-1.194-2.634 0-1.018.353-1.806 1.06-2.365a2.925 2.925 0 0 1 1.068-.522Zm2.128-3.837c-.507 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.316.285.738.427 1.265.427.528 0 .944-.142 1.25-.427.306-.285.459-.672.459-1.163 0-.48-.156-.854-.467-1.123-.306-.269-.733-.403-1.281-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.359.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.369.354.846.53 1.431.53.665 0 1.163-.195 1.495-.585a1.93 1.93 0 0 0 .483-1.297c0-.56-.185-1.018-.554-1.377-.353-.348-.84-.522-1.463-.522Z"
      />
    </g>
    <defs>
      <clipPath id="ir-68_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr68;
