import type { SVGProps } from "react";
const SvgClipboardUsersMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="#010101"
      fillRule="evenodd"
      d="M14.25 3.25h-.5v1.5H3.25v26.5h29.5V4.75h-9v-1.5h-9.5Zm9.5 3.5v.5h-10v-1.5h-9.5v24.5h27.5V5.75h-8v1Zm-1-.5h-8v-2h8v2Zm-6.25 5.5H6v-1h10.5v1ZM6 16.25h7.5v-1H6v1Zm4.5 4.5H6v-1h4.5v1Zm7.225-3.77c0 .805.238 1.569.647 2.183-2.43 1.287-3.737 4.283-3.737 7.087v.5H27.11v-.5c0-.713-.086-1.436-.254-2.14h2.894v-.5c0-2.816-1.366-5.754-3.786-7.029.434-.63.681-1.42.681-2.241 0-1.87-1.286-3.59-3.14-3.59-.856 0-1.647.275-2.227.828-.488.465-.798 1.097-.886 1.852-1.558.264-2.667 1.811-2.667 3.55Zm7.518.362c-.386.294-.84.493-1.344.562a3.853 3.853 0 0 1-.55 1.271c1.514.8 2.603 2.264 3.208 3.935h2.177c-.157-2.492-1.473-4.854-3.49-5.768Zm.402-3.002c0 1.268-.72 2.254-1.642 2.52-.049-1.667-1.114-3.133-2.604-3.42.079-.494.287-.87.568-1.138.365-.347.894-.552 1.538-.552 1.146 0 2.14 1.1 2.14 2.59Zm-3 5.602a2.846 2.846 0 0 1-1.78.628 2.848 2.848 0 0 1-1.792-.638c-2.006.923-3.273 3.319-3.423 5.818h10.445c-.154-2.498-1.446-4.889-3.45-5.808Zm-1.78-5.552c-1.109 0-2.14 1.08-2.14 2.59 0 1.51 1.031 2.59 2.14 2.59 1.109 0 2.14-1.08 2.14-2.59 0-1.51-1.031-2.59-2.14-2.59Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClipboardUsersMedium;
