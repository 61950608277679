import type { SVGProps } from "react";
const SvgBluetoothSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11 1.878.835.75 5 4.5.412.372-.413.372L12.247 12l4.587 4.129.413.371-.413.372-5 4.5-.834.75v-8.999l-4.166 3.749-.668-.743L10.753 12 6.166 7.872l.668-.743L11 10.877V1.878Zm1 11.245 3.753 3.377L12 19.878v-6.755Zm0-2.245V4.122L15.753 7.5 12 10.877Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBluetoothSmall;
