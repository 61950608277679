import type { SVGProps } from "react";
const SvgPlatformDisplaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 9H4V8h1v1h14V8h1v1h2v7H2V9h.5Zm.5 1v5h18v-5H3Zm1 2h2v-1H4v1Zm16 0H7v-1h13v1ZM7 14h13v-1H7v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPlatformDisplaySmall;
