import type { SVGProps } from "react";
const SvgCloudLittleSnowMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.953 2.988-.525 1.038c-.614 1.217-.335 2.739.584 3.77.894 1.002 2.404 1.543 4.293.744l1.172-.495-.52 1.16c-.563 1.255-1.65 2.056-2.732 2.488-.75.3-1.543.44-2.217.414a2.847 2.847 0 0 1-.833 2.081C16.66 14.69 15.927 15 15 15H8c-.954 0-1.693-.308-2.201-.82-.502-.505-.736-1.167-.764-1.808-.027-.637.147-1.287.507-1.79a2.066 2.066 0 0 1 1.144-.815c.092-1.851 2.007-2.896 3.523-2.177.935-1.522 2.59-1.908 3.944-1.437.284-.783.617-1.37 1.016-1.812.495-.547 1.06-.837 1.67-1.02l1.114-.333Zm1.9 7.776c-.787.315-1.558.395-2.072.32a2.3 2.3 0 0 0-.287-.478 2.066 2.066 0 0 0-1.181-.75c.125-.804-.005-1.531-.323-2.14a3.205 3.205 0 0 0-.943-1.093c.266-.771.557-1.272.864-1.611a2.44 2.44 0 0 1 .238-.23c-.205 1.318.235 2.69 1.117 3.68.929 1.041 2.343 1.658 4.011 1.407-.41.391-.905.688-1.423.896Zm-5.998-3.656c-1.048-.38-2.362-.046-2.997 1.394l-.266.602-.508-.417c-.968-.792-2.567-.048-2.387 1.454l.067.56H7.2c-.348 0-.634.168-.845.462-.216.303-.34.728-.32 1.166.018.434.175.846.474 1.147C6.8 13.771 7.27 14 8 14h7c.692 0 1.167-.226 1.478-.528.316-.308.492-.725.525-1.148.033-.427-.08-.826-.296-1.101-.205-.262-.522-.442-.987-.423l-.698.028.2-.67c.24-.812.145-1.476-.118-1.977a2.276 2.276 0 0 0-1.25-1.073ZM10 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-1 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudLittleSnowMoonSmall;
