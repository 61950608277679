import type { SVGProps } from "react";
const SvgIc47Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-47-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-47-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.824 7.003v1.954h-1.455v2.642h-2.255v-2.643h-4.825V10.99l4.944-6.755h2.136v6.969h1.455Zm-3.662.024V6.922l-2.974 4.303h2.974Zm12.292-6.993v1.962a45.8 45.8 0 0 0-.561.64c-.433.496-.863 1.12-1.29 1.875a12.726 12.726 0 0 0-.996 2.16c-.501 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.194-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Z"
      />
    </g>
    <defs>
      <clipPath id="ic-47-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc47Negative;
