import type { SVGProps } from "react";
const SvgIc29 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-29_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-29_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.722 9.542v2.056h-8.33c0-.627.1-1.226.3-1.795.206-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.94-.744 1.162-1.076.222-.332.333-.717.333-1.155 0-.506-.15-.915-.451-1.226-.296-.316-.686-.474-1.171-.474-1.192 0-1.806.857-1.843 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.764-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.697.918.786.664 1.18 1.558 1.18 2.681 0 .981-.315 1.825-.942 2.531a4.65 4.65 0 0 1-.578.562c-.179.137-.65.472-1.416 1.005-.727.506-1.25.893-1.566 1.162a6.353 6.353 0 0 0-.87.926h5.426Zm.885-.846h2.184c.179.896.698 1.344 1.558 1.344.485 0 .878-.163 1.178-.49.306-.332.541-.847.704-1.543.106-.443.182-.946.23-1.51-.353.495-.717.849-1.092 1.06-.369.205-.82.308-1.352.308-.85 0-1.588-.248-2.215-.744a3.543 3.543 0 0 1-1.03-1.351 4.446 4.446 0 0 1-.363-1.804c0-1.302.445-2.357 1.337-3.164.764-.696 1.682-1.044 2.752-1.044.66 0 1.268.148 1.828.443a3.962 3.962 0 0 1 1.407 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.295 2.99-.886 4.129-.77 1.482-1.935 2.222-3.496 2.222-1.039 0-1.899-.287-2.579-.862-.675-.575-1.065-1.347-1.17-2.317ZM49.4 5.91c-.496 0-.915.203-1.258.609-.343.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.337.544.812.815 1.423.815a1.58 1.58 0 0 0 1.33-.664c.31-.417.466-.912.466-1.487 0-.607-.16-1.12-.482-1.543A1.606 1.606 0 0 0 49.4 5.91Z"
      />
    </g>
    <defs>
      <clipPath id="ic-29_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc29;
