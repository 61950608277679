import type { SVGProps } from "react";
const SvgSaRa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 1h13.905v14H1V1Zm1.264 12.727H13.64V2.273H2.264v11.454Zm5.345-8.82a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72Zm2.516 5.155.598 1.637a.644.644 0 1 0 1.21-.44l-.894-2.462a.682.682 0 0 0-.646-.453H8.64v-1.89c0-.571-.46-1.032-1.031-1.032s-1.032.46-1.032 1.031v2.578c0 .57.46 1.031 1.032 1.031h2.516Zm-.516.468.426 1.169v.007a3.235 3.235 0 0 1-2.426 1.107 3.269 3.269 0 0 1-3.266-3.266A3.25 3.25 0 0 1 5.89 6.783v1.354a2.216 2.216 0 0 0-.516 1.41A2.239 2.239 0 0 0 7.61 11.78c.88 0 1.636-.515 2-1.251Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaRa;
