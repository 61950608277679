import type { SVGProps } from "react";
const SvgBulbOnPercentageSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M7.901 16c-1.4-.8-2.4-2.3-2.4-4 0-2.5 2-4.5 4.5-4.5a4.48 4.48 0 0 1 4.385 3.474m-.44-5.923-.701 1.287m3.6 1.613-1.283.708M3.044 16.037l1.31-.78M2 12h1.5m-.39-4.01 1.281.824m1.657-3.76.709 1.289M10 4v1.5m11 6.594-7 7.875M12.5 16v2.1l-2 .7v.7h-1v-.7l-2-.7V16h5Zm3-3.469a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm4 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
);
export default SvgBulbOnPercentageSmall;
