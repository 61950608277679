import type { SVGProps } from "react";
const SvgMetadataMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 4.75h-.5v26.5h29.5V4.75h-29Zm.5 25.5V5.75h27.5v24.5H4.25Zm4.396-17.104 3-3 .708.708L9.707 13.5l2.647 2.646-.708.708-3-3-.353-.354.353-.354Zm15.708 6 3 3 .353.354-.353.354-3 3-.708-.708 2.647-2.646-2.647-2.646.708-.708ZM27 11.75H15v-1h12v1ZM9 24.25v1h12v-1H9Zm13.5-8H15v-1h7.5v1Zm1.5 0h3v-1h-3v1Zm-12 4.5H9v-1h3v1Zm1.5 0H21v-1h-7.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMetadataMedium;
