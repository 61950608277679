import type { SVGProps } from "react";
const SvgPizzaSliceMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.664 4.67c.397-1.114 1.65-1.735 2.77-1.26 8.327 3.532 14.929 10.373 18.181 18.916.431 1.133-.207 2.38-1.327 2.752l-3.968 1.315a2.136 2.136 0 0 1-2.092-.43l-20.046 6.71c-1.172.392-2.249-.75-1.846-1.897l.002-.006 7.32-19.96a2.19 2.19 0 0 1-.41-2.172l1.416-3.969Zm-.168 6.757-1.201 3.276a3.283 3.283 0 0 1 1.484 2.753c0 1.805-1.448 3.278-3.246 3.278-.149 0-.295-.01-.439-.03L4.28 31.112a.473.473 0 0 0 .585.614l2.905-.973a2.965 2.965 0 0 1-.055-.587c.01-1.61 1.31-2.92 2.917-2.91a2.896 2.896 0 0 1 2.6 1.669l11.384-3.81-.011-.027-.007-.018c-2.423-6.11-7.154-11.016-13.101-13.642Zm.778 17.818a1.895 1.895 0 0 0-1.65-.99c-1.043-.006-1.903.846-1.91 1.916 0 .088.005.175.016.26l3.544-1.186ZM8.45 19.733l1.487-4.054c.513.417.842 1.057.842 1.777 0 1.264-1.011 2.278-2.246 2.278l-.083-.001ZM14.044 4.33c-.552-.234-1.22.063-1.438.674l-1.416 3.97c-.214.6.083 1.262.69 1.53 6.197 2.73 11.124 7.838 13.645 14.197.25.628.893.937 1.48.743l3.967-1.315c.602-.2.928-.869.707-1.447-3.155-8.29-9.56-14.926-17.636-18.351ZM16.5 20.75a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm-2.75 1.75a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPizzaSliceMedium;
