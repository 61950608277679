import type { SVGProps } from "react";
const SvgTicketDayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 6.25h-.5v22h29.5v-7h-.5c-2.274 0-4-1.727-4-4 0-2.274 1.726-4 4-4h.5v-7h-29Zm.5 21v-20h27.5v5.023c-2.578.239-4.5 2.32-4.5 4.977 0 2.657 1.922 4.737 4.5 4.977v5.023H4.25Zm2.5-16.5h-.5v14.463h.499l15 .037h.501v-14.5h-3V9h-1v1.75h-8V9h-1v1.75h-2.5Zm.5 13.465v-9.466h14v9.5l-14-.034Zm14-10.466h-14v-2h14v2Zm-7.5 5.5H12v-1h1.75V16.5h1v6h-1v-3.25ZM24.25 7.5v.488h1V7.5h-1Zm0 1.463v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95V27h1v-.488h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketDayMedium;
