import type { SVGProps } from "react";
const SvgWalkFastMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.237 8.25c0-.523.477-1 1-1s1 .477 1 1-.477 1-1 1-1-.477-1-1Zm1-2c-1.076 0-2 .924-2 2s.924 2 2 2 2-.924 2-2-.924-2-2-2Zm-12.737 3H18v1h-7.5v-1Zm11.25 3h-.15l-.127.084-4.5 3-.152.102-.051.177-1.5 5.25.962.274 1.449-5.073 3.344-2.229-1.264 6.317-.01.049-.11.549h.11v3.793l-4.353 4.353.707.707 4.5-4.5.146-.146V20.75h2.292l2.66 2.66-1.441 6.481.976.218 1.502-6.75.06-.269-.195-.194-2.812-2.81 1.265-6.321 2.34 2.339.072.072.095.038 3.75 1.5.371-.928-3.654-1.462-2.928-2.928-.146-.146H21.75Zm-.889 7.5 1.3-6.5h1.98l-1.3 6.5h-1.98Zm-13.361-6v1h6v-1h-6Zm-3 6H12v1H4.5v-1Zm10.5 4.5H7.5v1H15v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalkFastMedium;
