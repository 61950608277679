import type { SVGProps } from "react";
const SvgDocumentStandardMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.75 6.25h-.5v23.5h17.5V14.043l-.146-.147-7.5-7.5-.147-.146H9.75Zm.5 22.5V7.25h8v7.5h7.5v14h-15.5Zm14.793-15L19.25 7.957v5.793h5.793Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentStandardMedium;
