import type { SVGProps } from "react";
const SvgToiletMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.75 6v24h1V6h-1Zm-9.5 2.25a1 1 0 1 1 1.998 0 1 1 0 0 1-1.999 0Zm1-2a2 2 0 1 0-.002 4 2 2 0 0 0 .001-4Zm-3 6h-.5v8.5h1.5v9h4v-9h1.5v-8.5h-6.5Zm.5 7.5v-6.5h5v6.5h-1.5v9h-2v-9h-1.5Zm19.5 9v-5h2v5h-2Zm-1 .5v-5.5h-3.163l.182-.638 3-10.5.104-.362h3.754l.104.362 3 10.5.182.638H28.25v6h-4v-.5Zm1-21a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-1.123 7-2.714 9.5h7.674l-2.714-9.5h-2.246Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgToiletMedium;
