import type { SVGProps } from "react";
const SvgDatabaseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.75 9c0-.273.189-.636.781-1.05.579-.406 1.45-.791 2.575-1.123C11.348 6.166 14.486 5.75 18 5.75c3.514 0 6.651.416 8.894 1.077 1.125.332 1.996.717 2.575 1.122.592.415.781.778.781 1.051 0 .273-.189.636-.781 1.05-.579.406-1.45.791-2.575 1.123-2.242.661-5.38 1.077-8.894 1.077-3.514 0-6.652-.416-8.894-1.077-1.125-.332-1.996-.717-2.575-1.122C5.94 9.636 5.75 9.273 5.75 9Zm-1 0c0-.763.518-1.387 1.208-1.87.703-.492 1.689-.915 2.865-1.262C11.181 5.173 14.419 4.75 18 4.75c3.581 0 6.819.423 9.177 1.118 1.176.347 2.162.77 2.865 1.262.69.483 1.208 1.107 1.208 1.87v18c0 .866-.557 1.545-1.317 2.056-.764.514-1.822.923-3.04 1.242-2.447.64-5.68.954-8.893.954-3.213 0-6.446-.315-8.892-.954-1.22-.32-2.277-.728-3.041-1.242-.76-.51-1.317-1.19-1.317-2.056V9Zm1 13.845V27c0 .385.24.8.875 1.227.63.424 1.565.797 2.736 1.103 2.335.611 5.477.922 8.639.922 3.162 0 6.304-.311 8.64-.922 1.17-.306 2.105-.68 2.735-1.103.635-.427.875-.842.875-1.227v-4.155c-.1.081-.206.159-.316.233-.764.516-1.822.926-3.041 1.247-2.447.642-5.68.958-8.893.958-3.213 0-6.447-.316-8.893-.959-1.219-.32-2.277-.73-3.041-1.246a4.535 4.535 0 0 1-.316-.233Zm24.5-1.829c0 .388-.241.805-.875 1.234-.63.425-1.565.8-2.736 1.107-2.335.614-5.477.926-8.639.926-3.162 0-6.303-.313-8.639-.926-1.17-.307-2.105-.682-2.736-1.107-.634-.429-.875-.846-.875-1.234V16.83c.1.081.206.159.316.233.764.516 1.822.926 3.041 1.246 2.447.643 5.68.959 8.893.959 3.213 0 6.447-.316 8.893-.959 1.219-.32 2.277-.73 3.041-1.246.11-.074.216-.152.316-.233v4.187Zm0-10.3V15c0 .388-.241.805-.875 1.233-.63.426-1.565.8-2.736 1.108-2.335.613-5.477.925-8.639.925-3.162 0-6.303-.311-8.639-.925-1.17-.307-2.105-.682-2.736-1.108-.634-.428-.875-.845-.875-1.233v-4.284c.067.053.137.104.208.154.703.492 1.689.915 2.865 1.262 2.358.695 5.596 1.118 9.177 1.118 3.581 0 6.819-.423 9.177-1.118 1.176-.347 2.162-.77 2.865-1.262.071-.05.14-.101.208-.154Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDatabaseMedium;
