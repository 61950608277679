import type { SVGProps } from "react";
const SvgFlashlightOffMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.75 9.25h13v4.346l-.036.09-1.464 3.66V32.75h-9.995V17.36l-1.47-3.674-.035-.09V9.25Zm2.505 8.5h7.995v14h-7.995v-14ZM22.512 14l-1.1 2.75h-8.323l-1.1-2.75h10.523Zm.238-1h-11v-2.75h11V13Zm-7 6.75h-.5v10h4v-10h-3.5Zm.5 9v-8h2v8h-2Zm.5-1.75v-1.5h1V27h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFlashlightOffMedium;
