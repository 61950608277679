import type { SVGProps } from "react";
const SvgWheelchairSubstituteTransportSmall = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 2H2v20h20V2H2.5ZM3 21V3h18v18H3ZM8.6 8.1a.154.154 0 0 1-.013.065.047.047 0 0 1-.008.014l-.014.008A.154.154 0 0 1 8.5 8.2a.154.154 0 0 1-.065-.013.047.047 0 0 1-.014-.008l-.008-.014A.154.154 0 0 1 8.4 8.1c0-.033.008-.055.013-.065a.047.047 0 0 1 .008-.014l.014-.009A.154.154 0 0 1 8.5 8a.17.17 0 0 1 .065.012.06.06 0 0 1 .014.01s.004.004.008.013c.005.01.013.032.013.065Zm-.1-.9a.9.9 0 0 0-.629.271.9.9 0 0 0-.271.629.9.9 0 0 0 .271.629A.9.9 0 0 0 8.5 9a.9.9 0 0 0 .629-.271A.9.9 0 0 0 9.4 8.1a.9.9 0 0 0-.271-.629A.9.9 0 0 0 8.5 7.2ZM8 10v5h4v4h1v-5H9v-4H8Zm-1.407 1.845C5.305 12.43 4.5 13.715 4.5 15.2c0 2.076 1.724 3.8 3.8 3.8 1.518 0 2.77-.933 3.439-2.16l-.878-.48C10.331 17.333 9.382 18 8.3 18c-1.524 0-2.8-1.276-2.8-2.8 0-1.115.595-2.03 1.507-2.445l-.414-.91Zm6.818-6.424c.282-.28.665-.421 1.079-.421h3c.908 0 1.5.757 1.5 1.5v.505h.224v.25l.001 1v.25h-.225V11h-.001v.496h-2V11h-2v.5h-2V8.505h-.218v-1.5h.219V6.5c0-.414.14-.797.421-1.079Zm.707.708c-.068.068-.128.185-.128.371V8h4V6.5c0-.257-.208-.5-.5-.5h-3c-.187 0-.303.06-.372.129ZM17.99 9h-4v1h4V9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairSubstituteTransportSmall;
