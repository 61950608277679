import type { SVGProps } from "react";
const SvgChartPieMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18.155 7.266.095-.005v11.778l.25.144 9.95 5.745a11.492 11.492 0 0 1-9.7 5.322c-6.35 0-11.5-5.15-11.5-11.5 0-6.15 4.829-11.174 10.901-11.484h.004ZM30.25 18.75a11.45 11.45 0 0 1-1.298 5.313L19.75 18.75l9.202-5.313a11.45 11.45 0 0 1 1.298 5.313Zm-1.8-6.178a11.493 11.493 0 0 0-9.2-5.311v10.623l9.2-5.312ZM18.1 6.267c.218-.012.434-.017.651-.017 6.903 0 12.5 5.597 12.5 12.5s-5.597 12.5-12.5 12.5-12.5-5.597-12.5-12.5c0-6.685 5.248-12.144 11.849-12.483Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChartPieMedium;
