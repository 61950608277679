import type { SVGProps } from "react";
const SvgContactMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.25 6.25h-.5v6H6v1h1.75v5H6v1h1.75v5H6v1h1.75v4.5h20.5V6.25h-20Zm.5 19v3.5h18.5V7.25H8.75v5h1.75v1H8.75v5h1.75v1H8.75v5h1.75v1H8.75ZM18 11.75c-.875 0-1.75.951-1.75 2.5 0 .747.217 1.388.547 1.83.328.44.752.67 1.203.67.877 0 1.75-.939 1.75-2.485 0-.747-.217-1.393-.548-1.84-.329-.443-.753-.675-1.202-.675Zm-2.75 2.5c0-1.81 1.072-3.5 2.75-3.5.828 0 1.528.436 2.005 1.08.475.641.745 1.503.745 2.435a4.36 4.36 0 0 1-.353 1.74c2.021 1.282 3.354 4.08 3.354 7.246v.5h-11.5v-.5c0-3.168 1.331-5.964 3.354-7.247a4.35 4.35 0 0 1-.355-1.754Zm.885 2.602c-1.552.991-2.741 3.186-2.872 5.899h9.476c-.131-2.709-1.32-4.904-2.869-5.896-.469.549-1.107.895-1.87.895-.749 0-1.395-.354-1.865-.898Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgContactMedium;
