import type { SVGProps } from "react";
const SvgChevronSmallLeftCircleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.25 18c0-5.937 4.813-10.75 10.75-10.75 5.938 0 10.75 4.813 10.75 10.75 0 5.938-4.812 10.75-10.75 10.75-5.937 0-10.75-4.812-10.75-10.75ZM18 6.25C11.51 6.25 6.25 11.51 6.25 18S11.51 29.75 18 29.75 29.75 24.49 29.75 18 24.49 6.25 18 6.25Zm2.16 5.538-5.924 5.856-.36.356.36.355 5.922 5.856.703-.71L15.298 18l5.566-5.5-.703-.712Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallLeftCircleMedium;
