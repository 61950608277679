import type { SVGProps } from "react";
const SvgSaRy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m14.58 1 3.28 8.204L16.64 15h2.802l1.22-5.796L27.424 1h-3.261l-4.541 5.877L17.542 1H14.58ZM6.542 6.977h-1.02l.78-3.771h1.16c1.14 0 2.401 0 2.401 1.465 0 1.845-1.82 2.306-3.321 2.306ZM7.221 1H3.92L1 15h2.8l1.221-5.816h1.06c1.29 0 1.428.75 1.635 1.886.037.202.077.417.125.641L8.521 15h2.941l-1.34-5.636c-.18-.763-.661-1.243-1.42-1.283v-.04c2.28-.04 4.08-1.325 4.08-3.731C12.782 1 9.87 1 7.312 1H7.22Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaRy;
