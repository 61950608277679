import type { SVGProps } from "react";
const SvgDiamondSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.232 2h11.536l.148.223 4 6 .198.296-.216.284-9.5 12.5-.398.523-.398-.524-9.5-12.5-.216-.283.198-.296 4-6L6.232 2ZM3.434 8l2.89-4.335L7.615 8h-4.18Zm7.306 10.515L3.508 9h4.403l2.829 9.515ZM20.492 9l-7.232 9.515L16.09 9h4.402Zm-2.817-5.335L20.565 8h-4.178l1.288-4.335ZM12.898 3h3.932l-1.275 4.288L12.898 3Zm-1.796 0L8.445 7.289 7.17 3h3.932Zm-1.92 5L12 3.45 14.82 8H9.18ZM12 19.245 15.046 9H8.954L12 19.245Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDiamondSmall;
