import type { SVGProps } from "react";
const SvgSaVl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.801 1H1l1.84 14h3.341l7.801-14h-3.04L5.141 11.89h-.04L3.801 1Zm14.62 0h-2.8l-2.962 14h8.262l.48-2.206h-5.48L18.42 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVl;
