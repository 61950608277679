import type { SVGProps } from "react";
const SvgDoubleDeckWagonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m6.744 10.747 22.504-.001a2 2 0 0 1 2 2h.002v11.5h1.748v1H31.25v1.501h-1.515a2.75 2.75 0 1 1-5.475 0H11.735a2.75 2.75 0 1 1-5.475 0H4.747v-1.5h-1.75v-1h1.748l-.002-11.5a2 2 0 0 1 2-2Zm18.525 16h3.458a1.75 1.75 0 1 1-3.48.248c0-.082.008-.165.022-.248Zm-18 0h3.458a1.75 1.75 0 1 1-3.48.248c0-.082.008-.165.022-.248Zm21.98-15.001-22.505.001a1 1 0 0 0-1 1l.003 13h2.001v-6h5.5v6H30.25V12.746h-.002a1 1 0 0 0-1-1Zm-17 14.001h-3.5v-5h3.5v5Zm-4-12.001h-.5v4H28.25v-4H8.248Zm.5 3v-2H27.25v2H8.748Zm6.5 3.001H28.25v4H15.248v-4Zm1 1v2H27.25v-2H16.248Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDoubleDeckWagonMedium;
