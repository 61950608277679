import type { SVGProps } from "react";
const SvgHandHeartLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.944 8h-.031a6.26 6.26 0 0 0-4.413 2.18A6.259 6.259 0 0 0 15.088 8h-.031a6.1 6.1 0 0 0-5.328 9.005c.256.489.579.94.959 1.34l.008.008 8.45 8.5.353.355.355-.354 8.5-8.5.01-.01.01-.012a5.99 5.99 0 0 0 .901-1.344A6.1 6.1 0 0 0 23.944 8ZM12.553 9.677A5.1 5.1 0 0 1 15.048 9a5.26 5.26 0 0 1 4.04 2.282l.412.604.413-.604A5.26 5.26 0 0 1 23.953 9a5.1 5.1 0 0 1 4.437 7.523l-.005.009-.004.009c-.197.404-.448.78-.745 1.116l-8.135 8.135-8.092-8.14a4.986 4.986 0 0 1-.796-1.114l-.003-.006a5.1 5.1 0 0 1 1.943-6.855Zm4.155 22.711L8.04 26.532a2.5 2.5 0 0 0-3.115 3.908l12.25 10.44.14.12h7.978l1.854 1.854.146.146H43.98l-1.177-.898-10.652-8.124c-.11-.09-.22-.18-.331-.273-1.983-1.638-4.411-3.645-8.092-3.705H19.5c-.878 0-1.584.29-2.071.768a2.416 2.416 0 0 0-.721 1.62ZM7.47 27.354l9.665 6.53c.085.123.183.24.294.348.488.478 1.193.768 2.071.768h4v-1h-4c-.651 0-1.094-.21-1.371-.482a1.42 1.42 0 0 1-.424-1.018c0-.373.143-.742.424-1.018.277-.272.72-.482 1.371-.482h4.216c3.319.055 5.496 1.851 7.504 3.507l.003.002c.1.083.2.166.3.247l.007.006.007.006L41.02 42H27.707l-1.853-1.853-.147-.147h-8.023L5.574 29.68a1.5 1.5 0 0 1 1.876-2.34l.01.007.01.007Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandHeartLarge;
