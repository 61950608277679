import type { SVGProps } from "react";
const SvgLighthouseMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3.25a4.25 4.25 0 0 0-4.25 4.25v.25H12v1h1.75v5H12v1h1.66l-2.834 17H7.5v1h21v-1h-3.326l-2.834-17H24v-1h-1.75v-5H24v-1h-1.75V7.5A4.25 4.25 0 0 0 18 3.25Zm3.25 4.5V7.5a3.25 3.25 0 1 0-6.5 0v.25h6.5Zm-6.5 1v5h6.5v-5h-6.5Zm-2.91 23 2.834-17h6.652l2.834 17H11.84Zm15.16-20h3v-1h-3v1Zm6 0h-1.5v-1H33v1Zm-5.752-3.066 5.25-3-.496-.869-5.25 3 .496.869Zm4.754 9-5.25-3 .496-.869 5.25 3-.496.869ZM9 10.749H6v1h3v-1Zm-6 0h1.5v1H3v-1Zm6.248-2.934-5.25-3-.496.869 5.25 3 .496-.869Zm-5.746 9 5.25-3 .496.869-5.25 3-.496-.869Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLighthouseMedium;
