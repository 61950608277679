import type { SVGProps } from "react";
const SvgCircleTickMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.25 18c0-7.595 6.156-13.75 13.75-13.75S31.75 10.405 31.75 18c0 7.594-6.156 13.75-13.75 13.75S4.25 25.594 4.25 18ZM18 3.25C9.853 3.25 3.25 9.853 3.25 18c0 8.146 6.603 14.75 14.75 14.75S32.75 26.145 32.75 18c0-8.147-6.603-14.75-14.75-14.75Zm-1.854 19.555 7.5-9.75-.792-.61-7.19 9.346-3.352-2.681-.624.78 3.75 3 .398.319.31-.404Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleTickMedium;
