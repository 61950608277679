import type { SVGProps } from "react";
const SvgBusProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.646 6.25H3v1h20.646a2.5 2.5 0 0 1 2.236 1.382l4.21 8.42a1.5 1.5 0 0 1 .158.67v7.528a.5.5 0 0 1-.5.5H16.243a4.25 4.25 0 1 0-8.486 0H3v1h4.937a4.252 4.252 0 0 0 8.126 0H29.75a1.5 1.5 0 0 0 1.5-1.5v-7.528a2.5 2.5 0 0 0-.264-1.118l-4.21-8.42a3.5 3.5 0 0 0-3.13-1.934ZM3 10.25h4.75v6.5H3v1h5.75v-8.5H3v1Zm7.75-1h8.5v8.5h-8.5v-8.5Zm1 1v6.5h6.5v-6.5h-6.5Zm-3 15.25a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0Zm12.5-16.25h2.309l.138.276 4.5 9 .053.106v2.118h-7V9.25Zm1 1v9.5h5v-.882l-4.309-8.618h-.691Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBusProfileMedium;
