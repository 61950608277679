import type { SVGProps } from "react";
const SvgSwitzerlandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.269 5.248.4.353 1.328 1.173 3.634 1.41.445.173-.152.453-.499 1.487.888.268.244-.462.276-.524.47.36 1.343 1.025.185.142.01.232.159 3.269.026.537-.538-.013-.968-.025.166 1.431.084.726-.707-.184-3.262-.852-1.397 2.962-.362.766-.496-.686-1.795-2.483-1.404 1.52-.127.137-.185.02-3.157.355-.232.026-.17-.162-1.964-1.88-.62.36-.06.035-.068.017-2.14.544-.832.212.226-.828 1.08-3.954.031-.115.08-.088 2.396-2.657-.387-.133-.897-.309.761-.566 2.44-1.813.256-.19.28.152.564.305 3.41-.93.91-1.175.327-.42ZM6.44 9.558l.206.07.715.246-.506.562-2.824 3.13-.822 3.012 1.24-.316.885-.514.325-.19.272.26L8 17.799l2.74-.307 1.692-1.832.415-.45.358.496 1.656 2.292 1.213-2.572.177-.376.402.105 2.957.772-.148-1.277-.066-.572.575.015 1.005.025-.12-2.499-.69-.526-.16.304-.191.365-.395-.12-1.776-.536-.494-.149.164-.488.512-1.524-3.272-1.27-.084-.032-.066-.06-.997-.879-.686.886-.103.132-.161.044-3.764 1.026-.193.053-.176-.095-.46-.249L6.44 9.557Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSwitzerlandSmall;
