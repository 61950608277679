import type { SVGProps } from "react";
const SvgWashingMachineMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.25 3.25h23.5v29.5H6.25V3.25Zm1 1v6.5h21.5v-6.5H7.25Zm21.5 7.5H7.25v20h21.5v-20ZM11.751 22.386l-.001.114a6.25 6.25 0 0 0 12.393 1.16c-.523-.166-.848-.527-1.097-.804l-.02-.021c-.34-.378-.545-.585-.98-.585-.436 0-.64.207-.981.585l-.02.021c-.334.371-.804.894-1.704.894-.898 0-1.369-.523-1.702-.894l-.02-.022c-.34-.378-.544-.584-.978-.584-.431 0-.635.206-.975.584l-.02.022c-.332.37-.801.894-1.698.894-.899 0-1.369-.523-1.702-.894l-.019-.022c-.179-.198-.32-.35-.476-.448Zm12.498.23c-.158-.1-.3-.251-.48-.45l-.02-.022c-.333-.371-.804-.894-1.703-.894-.9 0-1.37.523-1.705.894l-.019.021c-.34.378-.546.585-.98.585-.435 0-.639-.206-.98-.584l-.018-.022c-.334-.37-.804-.894-1.703-.894-.897 0-1.367.523-1.7.894l-.019.022c-.34.378-.543.584-.974.584-.434 0-.637-.206-.977-.584l-.02-.022c-.248-.276-.573-.637-1.094-.803a6.25 6.25 0 0 1 12.392 1.275ZM10.5 7.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm13.25-.25H27v-1h-4.5v1Zm-3 0H15v-1h4.5v1Zm-1.5 8a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWashingMachineMedium;
