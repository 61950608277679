import type { SVGProps } from "react";
const SvgMagnifyingGlassMinusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 4a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM2 10.5a7.5 7.5 0 1 1 13.22 4.852l6.107 5.27-.654.756-6.15-5.307A7.5 7.5 0 0 1 2 10.5Zm12 .5H5v-1h9v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMagnifyingGlassMinusSmall;
