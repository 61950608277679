import type { SVGProps } from "react";
const SvgEn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="en_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.287 15.8h5.288l3.428-3.508H9.138l1.77-1.774h7.933l1.815-1.778h-7.933l1.748-1.799h7.935l2.71-2.741H12.588a1.412 1.412 0 0 0-.807.2l-.246.182-.27.223-6.834 6.858c-.15.176-.237.398-.246.63a.693.693 0 0 0 .203.517l.426.382L7.39 15.24l.381.335.316.158c.034.002.067.01.099.024a.88.88 0 0 1 .075.032l.026.011Zm24.828-3.125V4.2h-5.847L15.726 15.8h5.357l8.469-8.499V15.8h5.849L46.962 4.2h-5.4l-8.448 8.475Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEn;
