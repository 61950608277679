import type { SVGProps } from "react";
const SvgBulbOnLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 8v3h1V8h-1Zm.5 8a8.463 8.463 0 0 0-8.5 8.5c0 3.154 1.831 5.963 4.435 7.5H22v-3.76l-2.312-1.85.624-.78 2.5 2 .188.15V32h1v-4.24l.188-.15 2.5-2 .624.78L25 28.24V32h2.58c2.57-1.34 4.42-4.326 4.42-7.5 0-4.724-3.776-8.5-8.5-8.5ZM14 24.5c0 3.154 1.623 5.967 4 7.744v6.811l.335.117L22 40.455V42h3v-1.545l3.665-1.283.335-.117V32.3c2.393-1.692 4-4.668 4-7.801 0-5.276-4.224-9.5-9.5-9.5a9.463 9.463 0 0 0-9.5 9.5Zm5 8.5v1h2v1h-2v1h2v1h-2v1.345l3.665 1.283.335.117V41h1v-1.255l.335-.117L28 38.345V37h-2v-1h2v-1h-2v-1h2v-1h-9Zm17.999-8 3 .008.002-1-3-.008L37 25ZM10 25H7v-1h3v1Zm28.04-8.31-2.602 1.495-.498-.868 2.602-1.494.498.868ZM9.46 33.185l2.598-1.5-.5-.866-2.599 1.5.5.866Zm22.726-22.716-1.506 2.594-.864-.502 1.506-2.594.864.502Zm2.757 21.215 2.594 1.506.502-.864-2.594-1.506-.502.864Zm-23.383-13.5-2.598-1.5.5-.866 2.598 1.5-.5.866Zm3.26-7.722 1.5 2.598.866-.5-1.5-2.598-.866.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBulbOnLarge;
