import type { SVGProps } from "react";
const SvgTrainStationSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 3h6v1h-1.23L8.96 6h2.682c.7 0 1.318.147 1.756.567.443.423.601 1.028.601 1.711V18H13v1h2.001v-3H17.5v-2.5H17V10h1.467a1 1 0 1 1 .063 0H20v3.5h-.5V16H21v1h-4.999v3H3v-1h1v-1H3V8.278c0-.683.158-1.288.601-1.712C4.04 6.147 4.658 6 5.358 6H8.04L6.73 4H5.5V3Zm3 1.877L7.925 4h1.15l-.575.877ZM4.293 7.29c-.165.158-.293.442-.293.989V12h9V8.278c0-.547-.128-.831-.293-.99-.17-.161-.48-.288-1.065-.288H5.359c-.585 0-.895.127-1.065.29ZM12.999 13H4v4h9v-4ZM12 19v-1H5v1h7ZM10 8H7v1h3V8Zm1 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-6 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm13.5-2.5H18V11h1v1.5h-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrainStationSmall;
