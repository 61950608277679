import type { SVGProps } from "react";
const SvgBalloonsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 9c0-1.694.529-3.21 1.36-4.291C13.193 3.628 14.308 3 15.5 3c1.194 0 2.308.628 3.14 1.709C19.47 5.79 20 7.306 20 9c0 1.694-.529 3.21-1.36 4.291C17.807 14.372 16.692 15 15.5 15c-1.194 0-2.308-.628-3.14-1.709C11.53 12.21 11 10.694 11 9Zm4.5-7c-1.568 0-2.954.827-3.932 2.099a6.851 6.851 0 0 0-.482.717A4.69 4.69 0 0 0 8.507 4H8.5c-1.567 0-2.953.827-3.931 2.099C3.59 7.369 3 9.104 3 11s.59 3.63 1.569 4.901c.873 1.135 2.07 1.916 3.431 2.07V22h1v-4.05a5.4 5.4 0 0 0 3.936-2.752 4.644 4.644 0 0 0 2.064.774V22h1v-6.028c1.362-.155 2.56-.936 3.432-2.071C20.41 12.631 21 10.896 21 9s-.59-3.63-1.568-4.901C18.454 2.827 17.068 2 15.5 2Zm-3.355 12.552A4.401 4.401 0 0 1 8.485 17c-1.187-.005-2.295-.632-3.124-1.709C4.53 14.21 4 12.694 4 11c0-1.694.53-3.21 1.361-4.291C6.192 5.629 7.305 5 8.497 5a3.69 3.69 0 0 1 2.14.723A8.558 8.558 0 0 0 10 9c0 1.896.59 3.63 1.568 4.901.18.233.372.45.577.651Zm-.06-6.628c.285-1.85 1.31-3.466 2.79-3.848l.25.968c-.92.238-1.795 1.363-2.05 3.032l-.99-.152ZM7.88 6.094c-1.483.372-2.51 1.979-2.793 3.83l.988.152c.256-1.669 1.13-2.782 2.047-3.01l-.242-.971Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBalloonsSmall;
