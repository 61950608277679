import type { SVGProps } from "react";
const SvgPinSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 5h-.677l.2.647 1.972 6.41-.253 3.406-.04.537H11.5v3h1v-3h2.787l-.038-.536-.243-3.406 1.972-6.411.2-.647H7.5Zm2.478 6.853L8.178 6h7.645l-1.8 5.853-.028.09.007.093.211 2.964H9.78l.22-2.963.007-.094-.028-.09Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPinSmall;
