import type { SVGProps } from "react";
const SvgDogLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M37 9.5V8.293l-.853.853-7.883 7.883-13.797 1.97a13.993 13.993 0 0 1-4.416-.759c-1.51-.578-2.737-1.49-3.002-3.063a8.175 8.175 0 0 1-.033-.237L6.52 15l-.497.052a9.22 9.22 0 0 0 1.307 3.743 8.971 8.971 0 0 0 2.24 2.598l.008.005.007.005A8.26 8.26 0 0 0 14 22.987V39h7.015L21 38.485v-.028l-.002-.032v-.056a32.89 32.89 0 0 1 .018-1.561c.036-1.015.129-2.36.353-3.703.226-1.35.58-2.657 1.116-3.617.534-.956 1.191-1.475 2.019-1.488H30v11h5V23.707l1.5-1.5.647.647.07.07a1.82 1.82 0 0 0 2.565 0h.002l.07-.07 2-2 .008-.01a1.95 1.95 0 0 0 0-2.689l-.008-.009L37 13.293V9.5ZM22.32 28.012c-.742.082-1.341.592-1.835 1.476-.537.96-.89 2.266-1.116 3.617a29.421 29.421 0 0 0-.353 3.703c-.018.49-.022.9-.022 1.192h1c0-.303.004-.725.022-1.228.038-1.04.133-2.432.367-3.832.233-1.392.61-2.831 1.229-3.94.202-.362.437-.699.708-.988ZM17.994 38c0-.303.004-.725.022-1.228.038-1.04.133-2.432.367-3.832.233-1.392.61-2.831 1.229-3.94.621-1.111 1.545-1.981 2.881-2H33v11h1V23.707l-5.677-5.677-13.752 1.965-.037.005h-.037a14.991 14.991 0 0 1-4.78-.817l.163-.473-.178.467c-.47-.18-.94-.398-1.38-.669a7.97 7.97 0 0 0 1.86 2.094A7.26 7.26 0 0 0 14.498 22l.502-.002V38h2.994Zm11.213-20.5 5.293 5.293.779-.779-5.28-5.306-.792.792Zm6.94 3.646-.161.161-5.28-5.306L36 10.707v3l.147.147 4.995 4.995a.95.95 0 0 1 0 1.302l-1.995 1.995-.07.07a.82.82 0 0 1-1.154 0l-.07-.07-1-1-.353-.353-.353.353ZM32 38V28h-1v10h1Zm4.5-22a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.353 1.146a.5.5 0 1 1 .707.708.5.5 0 0 1-.707-.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDogLarge;
