import type { SVGProps } from "react";
const SvgTelephoneGsmSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 3H12v1H6v17h12V4h-3V3h-2.5ZM7 20V5h10v15H7Zm7-5v-1h2v1h-2Zm-3 0h2v-1h-2v1Zm-1 0H8v-1h2v1Zm-2 1v1h2v-1H8Zm2 2v1H8v-1h2Zm1 0v1h2v-1h-2Zm0-1v-1h2v1h-2Zm3-1v1h2v-1h-2Zm0 3v-1h2v1h-2ZM8.5 6H8v7h8V6H8.5Zm.5 6V7h6v5H9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTelephoneGsmSmall;
