import type { SVGProps } from "react";
const SvgChildAdultLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30.5 6C28.598 6 27 7.598 27 9.5s1.598 3.5 3.5 3.5S34 11.402 34 9.5 32.402 6 30.5 6ZM28 9.5C28 8.15 29.15 7 30.5 7S33 8.15 33 9.5 31.85 12 30.5 12 28 10.85 28 9.5ZM10.5 16C9.14 16 8 17.14 8 18.5S9.14 21 10.5 21s2.5-1.14 2.5-2.5-1.14-2.5-2.5-2.5ZM9 18.5c0-.808.692-1.5 1.5-1.5s1.5.692 1.5 1.5-.692 1.5-1.5 1.5S9 19.308 9 18.5ZM7.293 22h5.335l.112.061L18.128 25h1.85l1.427-6.158.042-.182.151-.11.073-.052 5.518-4.39.136-.108H33.7l.145.137 5.27 4.98 5.762 1.614h.002a1.556 1.556 0 0 1 1.037 2.028v.01l-.044.099a1.556 1.556 0 0 1-1.813.866l-6.167-1.6-.121-.032-.092-.084L34 18.638v7.71l3.916 5.875.084.126V43h-3v-9.333L30.75 28h-.307l-.921 7.637-.021.172-.123.123-6.596 6.575-.354.352-.353-.352-1.594-1.595-.354-.354.355-.353 5.881-5.861L27 26.48v-7.954l-2.53 1.978L23 26.566A1.5 1.5 0 0 1 21.5 28h-4.118l-.106-.053L13 25.81v4.61l3.974 11.923.22.658h-3.049l-.117-.335L10.645 33h-.192l-.455 4.55-.015.15-.096.117L6.32 42.17l-.35.427-.39-.39-1.595-1.595-.324-.324.295-.351 3.062-3.64.482-4.782v-5.308l-1.5 1.5V30.5a1.5 1.5 0 0 1-3 0v-4.207l.146-.147 4-4L7.293 22Zm15.002 3.227L23.54 20.1l.04-.169.138-.107 3.474-2.717.808-.632V26.52l-.002.02-.653 8.068-.015.184-.13.13-5.658 5.638.887.886 6.12-6.1.955-7.906.053-.44h1.693l.15.2 4.5 6 .1.133V42h1v-9.349l-3.916-5.874-.084-.126v-10.29l.838.77 4.425 4.066 6.047 1.569a.556.556 0 0 0 .294-1.072l-5.878-1.647-.12-.034-.089-.084L33.301 15h-5.626l-5.337 4.244L21.005 25h.495c.292 0 .564.083.795.227ZM7.707 23 4 26.707V30.5a.5.5 0 0 0 1 0v-3.207l.146-.147 2.5-2.5.854-.853v7.772l-.002.025-.5 4.96-.016.154-.1.118-2.865 3.407.88.88 3.12-3.81.486-4.849.045-.45h1.807l.117.335L14.855 42h.951l-3.78-11.342-.026-.077v-6.39l.724.362L17.618 27H21.5a.5.5 0 1 0 0-1h-3.627l-.112-.061L12.373 23H7.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChildAdultLarge;
