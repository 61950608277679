import type { SVGProps } from "react";
const SvgPizzaSliceLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.95 4.543c.458-1.271 1.877-1.87 3.11-1.364 12.228 5.017 21.948 14.914 26.774 27.279.484 1.24-.127 2.655-1.402 3.098L40.134 34.7a2.335 2.335 0 0 1-2.336-.477L5.706 44.893l-.157-.474.157.474c-1.63.541-3.155-1.036-2.584-2.649l11.281-31.858a2.35 2.35 0 0 1-.64-2.547l1.187-3.296Zm.336 6.345-5.15 14.542 2.218 2.216-.707.708-1.88-1.88-2.319 6.549A4 4 0 1 1 4.85 40.36l-.786 2.218-.47-.167.47.167c-.296.838.497 1.641 1.327 1.366L14 41.08V38h1v2.749l9.85-3.275A4.007 4.007 0 0 1 28 30.996a4.007 4.007 0 0 1 3.998 4.101l5.21-1.732c-3.982-10.14-11.928-18.277-21.923-22.477Zm15.676 24.554c.025-.143.038-.286.038-.431a3.007 3.007 0 0 0-3-3.015 3.007 3.007 0 0 0-2.12 5.136l5.082-1.69Zm-25.766 3.94a3 3 0 1 0 1.905-5.38l-1.905 5.38ZM17.68 4.105a1.345 1.345 0 0 0-1.79.777l-1.186 3.296c-.243.676.082 1.421.727 1.688C25.791 14.14 34.029 22.526 38.142 33a1.32 1.32 0 0 0 1.665.754l3.298-1.144c.718-.25 1.085-1.057.799-1.79C39.176 18.711 29.654 9.017 17.68 4.105ZM16 19.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm2.5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM15 30.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm2.5-3.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM30 29h-4v-1h4v1Zm-6.416-5.723 2 3 .832-.554-2-3-.832.554Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPizzaSliceLarge;
