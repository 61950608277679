import type { SVGProps } from "react";
const SvgBrowserSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 5h20v16H2V5Zm1 1v4h18V6H3Zm0 14v-9h18v9H3Zm8-13a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM7 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM5 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBrowserSmall;
