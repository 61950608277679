import type { SVGProps } from "react";
const SvgTelephoneReceiverMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.794 4.261c1.074-.621 2.42-.082 3.042.993l2.636 4.563c.622 1.077.411 2.512-.663 3.131l-2.961 1.711-.002.001c-.05.03-.131.145-.067.323a28.666 28.666 0 0 0 2.157 4.629M11.794 4.261Zm0 0L9.23 5.735h-.002a1.558 1.558 0 0 0-.387.332c-1.565 1.399-2.066 3.945-1.754 6.852.317 2.955 1.485 6.423 3.443 9.815 1.958 3.39 4.377 6.136 6.777 7.887 2.362 1.724 4.82 2.564 6.813 1.907.147-.03.318-.08.487-.18l2.55-1.472.004-.002c1.051-.617 1.283-2.047.658-3.13l-2.634-4.563c-.622-1.079-1.97-1.612-3.042-.993l-2.961 1.708h-.001c-.054.032-.192.044-.316-.102a28.675 28.675 0 0 1-2.93-4.182M13.97 5.754c-.413-.715-1.19-.908-1.676-.627h-.001L9.731 6.602a.63.63 0 0 0-.154.144l-.022.027-.027.023C8.318 7.86 7.779 9.99 8.082 12.814c.3 2.791 1.413 6.126 3.315 9.421 1.901 3.293 4.233 5.925 6.5 7.58 2.294 1.674 4.408 2.272 5.936 1.756l.032-.011.034-.007a.616.616 0 0 0 .202-.066l.005-.003 2.55-1.471c.481-.284.708-1.059.298-1.768L24.32 23.68c-.413-.716-1.19-.908-1.676-.627l-2.96 1.708h-.001c-.54.313-1.197.13-1.58-.32v-.001a29.674 29.674 0 0 1-3.033-4.329 29.667 29.667 0 0 1-2.232-4.79m1.132-9.568 2.636 4.563c.412.714.191 1.484-.297 1.765l-2.96 1.71v.001c-.54.31-.711.972-.511 1.528"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTelephoneReceiverMedium;
