import type { SVGProps } from "react";
const SvgPercentMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.996 13.246a1.749 1.749 0 1 0 0 3.5 1.75 1.75 0 1 0 0-3.5Zm-2.75 1.75a2.749 2.749 0 1 1 5.5 0 2.75 2.75 0 1 1-5.5 0ZM21 22.25a1.75 1.75 0 1 0 .002 3.501A1.75 1.75 0 0 0 21 22.25ZM18.25 24a2.75 2.75 0 1 1 5.502.001A2.75 2.75 0 0 1 18.25 24Zm-6.646 2.603 13.5-13.5-.707-.707-13.5 13.5.707.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPercentMedium;
