import type { SVGProps } from "react";
const SvgTwoSpeechBubblesRainbowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#two-speech-bubbles-rainbow-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.75 7.75h-.5v14.5h6v4.19l.874-.983 2.85-3.207h.776v4.5h8.918l3.228 3.229.854.853V26.75h4.5v-14.5h-7.5v-4.5h-20Zm20 5.5v3.5h4.75v1h-4.75v2H27v1h-3.25v1.5h-9v3.5h8.332l.147.146 2.521 2.522V25.75h4.5v-12.5h-6.5Zm-19.5 8V8.75h18.5v12.5H12.525l-.149.168-2.126 2.392v-2.56h-6Zm5-1.75a4.251 4.251 0 0 1 4.25-4.25 4.251 4.251 0 0 1 4.25 4.25h-1a3.251 3.251 0 0 0-3.25-3.25 3.251 3.251 0 0 0-3.25 3.25h-1Zm4.25-7.25a7.241 7.241 0 0 0-7.25 7.25h1a6.24 6.24 0 0 1 6.25-6.25 6.24 6.24 0 0 1 6.25 6.25h1a7.241 7.241 0 0 0-7.25-7.25Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="two-speech-bubbles-rainbow-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTwoSpeechBubblesRainbowMedium;
