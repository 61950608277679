import type { SVGProps } from "react";
const SvgSuitcaseLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.44 8.44A1.5 1.5 0 0 1 19.5 8h9A1.5 1.5 0 0 1 30 9.5V13h-1v-2.5A1.5 1.5 0 0 0 27.5 9h-7a1.5 1.5 0 0 0-1.5 1.5V13h-1V9.5c0-.398.158-.78.44-1.06ZM17 13V9.5A2.5 2.5 0 0 1 19.5 7h9A2.5 2.5 0 0 1 31 9.5V13h11.5a2.5 2.5 0 0 1 2.5 2.5v22a2.5 2.5 0 0 1-2.5 2.5h-37A2.5 2.5 0 0 1 3 37.5v-22A2.5 2.5 0 0 1 5.5 13H17Zm3.5-3a.5.5 0 0 0-.5.5V13h8v-2.5a.5.5 0 0 0-.5-.5h-7Zm-15 4A1.5 1.5 0 0 0 4 15.5v22A1.5 1.5 0 0 0 5.5 39H9V14H5.5ZM12 39h-2V14h2v25Zm24 0h2V14h-2v25Zm-1 0V14H13v25h22Zm7.5 0H39V14h3.5a1.5 1.5 0 0 1 1.5 1.5v22a1.5 1.5 0 0 1-1.5 1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSuitcaseLarge;
