import type { SVGProps } from "react";
const SvgSaWl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.53 11h-18V7h-1.5v8h1.5v-2.5h18V15h1.5V7h-1.5v4Zm-11-2c0-1.1-.9-2-2-2h-4v3h6V9Zm3-2h7v3h-9V9c0-1.1.9-2 2-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaWl;
