import type { SVGProps } from "react";
const SvgCloudMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.428 4.026.651-1.289-1.308.61c-.49.228-1.037.509-1.538 1.025-.415.427-.78.993-1.077 1.782-1.355-.473-3.011-.088-3.948 1.436-1.516-.719-3.43.325-3.522 2.176-.481.128-.87.432-1.144.815-.36.503-.535 1.154-.507 1.79.028.641.262 1.304.764 1.81.508.511 1.247.82 2.201.82h7c.932 0 1.669-.311 2.185-.812a2.778 2.778 0 0 0 .831-2.082c.673.025 1.463-.116 2.209-.414 1.082-.433 2.168-1.233 2.731-2.488l.52-1.16-1.17.495c-1.89.798-3.4.258-4.294-.745-.92-1.03-1.199-2.553-.584-3.77Zm.359 7.058c.513.074 1.282-.006 2.067-.32a4.455 4.455 0 0 0 1.423-.896c-1.668.251-3.082-.365-4.012-1.407-.856-.96-1.295-2.282-1.132-3.565a2.963 2.963 0 0 0-.183.173c-.32.33-.634.805-.901 1.555.383.282.709.65.94 1.093.32.607.448 1.335.323 2.14.493.111.895.38 1.185.748.116.148.213.31.29.48Zm-6.93-2.582c.636-1.44 1.95-1.775 2.998-1.394.53.192.983.565 1.249 1.073.262.5.358 1.165.117 1.977l-.2.67.7-.028c.464-.019.783.16.99.423.218.277.333.676.301 1.102a1.779 1.779 0 0 1-.523 1.146c-.311.302-.79.53-1.489.53H8c-.73 0-1.2-.23-1.492-.525-.298-.3-.455-.713-.474-1.148-.019-.437.104-.862.321-1.165.21-.294.497-.463.845-.463h.563l-.066-.56c-.18-1.501 1.419-2.245 2.386-1.453l.51.417.265-.602Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudMoonSmall;
