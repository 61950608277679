import type { SVGProps } from "react";
const SvgChildAdultMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 4.335c-.924 0-1.75.826-1.75 1.75s.826 1.75 1.75 1.75 1.75-.826 1.75-1.75-.826-1.75-1.75-1.75Zm-2.75 1.75c0-1.476 1.274-2.75 2.75-2.75s2.75 1.274 2.75 2.75-1.274 2.75-2.75 2.75-2.75-1.274-2.75-2.75Zm1 5.665v8h3.5v-8h-3.5Zm-1 .707v13.715l-3.655 5.034.81.588 3.75-5.165.095-.132V20.75h3.73l2.77 4.234V33h1v-8.315l-.081-.124-2.919-4.46v-7.2l2.084 3.126.062.094.097.058 3.75 2.25.514-.858-3.653-2.192-2.938-4.406-.148-.223h-4.975l-.146.146-4.5 4.5-.147.147v4.207h-3.132l-2.894-1.447-.106-.053H5.793l-.147.146-2.25 2.25-.146.147V25.5h1v-4.293l1.957-1.957h.043v9.083L4.1 31.2l.8.6 2.25-3 .1-.133V25.25h2.9l2.88 7.92.94-.34L11 24.661v-5.103l2.276 1.138.106.053h3.368V21h1v-5.043l3.5-3.5Zm-13 .793c-.524 0-1 .476-1 1s.476 1 1 1 1-.476 1-1-.476-1-1-1Zm-2 1c0-1.076.924-2 2-2s2 .924 2 2-.924 2-2 2-2-.924-2-2Zm1 10v-5H10v5H7.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChildAdultMedium;
