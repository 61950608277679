import type { SVGProps } from "react";
const SvgDisplayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.75 6.25h26.5v19h-12v3.5h6.25v1h-15v-1h6.25v-3.5h-12v-19Zm13.5 22.5h-.5v-3.5h.5v3.5Zm12-4.5H5.75v-17h24.5v17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDisplayMedium;
