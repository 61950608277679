import type { SVGProps } from "react";
const SvgTicketMoonStarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.25 6.25h29.5v7h-.5c-2.274 0-4 1.726-4 4 0 2.273 1.726 4 4 4h.5v7H3.25v-22Zm1 1v20h27.5v-5.023c-2.578-.24-4.5-2.32-4.5-4.977 0-2.658 1.922-4.738 4.5-4.977V7.25H4.25Zm15 3.5V9h-1v1.75H16.5v1h1.75v1.75h1v-1.75H21v-1h-1.75Zm6-.25V9h-1v1.5h1Zm0 1.5v1.5h-1V12h1Zm0 4.5V15h-1v1.5h1Zm0 1.5v1.5h-1V18h1Zm0 4.5V21h-1v1.5h1Zm0 1.5v1.5h-1V24h1Zm-4.5-3v-1.75h1.75v-1h-1.75V16.5h-1v1.75H18v1h1.75V21h1Zm-8-8.75a6.5 6.5 0 0 0 0 13c1.673 0 3.18-.648 4.327-1.68l.859-.772-1.15-.098c-2.82-.24-5.036-2.575-5.036-5.45 0-1.621.715-3.066 1.841-4.078l.856-.768-1.146-.101a3.281 3.281 0 0 1-.152-.019l-.08-.01a2.378 2.378 0 0 0-.319-.024Zm-5.5 6.5a5.501 5.501 0 0 1 4.873-5.465 6.407 6.407 0 0 0-1.373 3.965c0 2.99 2.023 5.48 4.766 6.235a5.382 5.382 0 0 1-2.766.765 5.5 5.5 0 0 1-5.5-5.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketMoonStarMedium;
