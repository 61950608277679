import type { SVGProps } from "react";
const SvgShoppingBagFastSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.101 6.24-.173.984 2.955.521.174-.984a1.5 1.5 0 1 0-2.956-.52Zm3.767 1.679.173-.985a2.5 2.5 0 1 0-4.924-.868l-.174.985-2.461-.434-.45-.08-.12.441-2.895 10.66-.143.527.54.096 11.817 2.084.54.095.045-.546.925-11.007.038-.455-.45-.08-2.461-.433Zm-1.159.811-.347 1.97.984.174.348-1.97 2.012.354-.84 10.007-10.74-1.894 2.632-9.69 2.012.355-.347 1.97.985.173.347-1.97 2.954.521ZM2 10h6v1H2v-1Zm2 2h4v1H4v-1Zm3 2H5v1h2v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShoppingBagFastSmall;
