import type { SVGProps } from "react";
const SvgStrollerMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.256 6.25h.499c6.072 0 10.995 4.923 10.995 10.995v.5h-.012v4.005c0 1.95-1.34 3.765-3.074 4.566A2.754 2.754 0 0 1 24 29.75a2.748 2.748 0 0 1-2.739-3H16.24a2.748 2.748 0 0 1-2.739 3 2.75 2.75 0 0 1-2.665-3.433c-1.737-.8-3.087-2.615-3.087-4.567v-8.5H3v-1h5.748v4.5h9.495l.012-10v-.5Zm10.482 10.495a9.996 9.996 0 0 0-9.484-9.483l-.01 9.483h9.494Zm0 1.005H8.748v4c0 1.514 1.081 3.006 2.508 3.66a2.749 2.749 0 0 1 4.694.34h5.6a2.749 2.749 0 0 1 4.694-.34c1.42-.654 2.494-2.145 2.494-3.66v-4ZM13.5 25.25a1.75 1.75 0 1 0 .002 3.502 1.75 1.75 0 0 0-.002-3.502Zm10.5 0a1.75 1.75 0 1 0-.001 3.499A1.75 1.75 0 0 0 24 25.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStrollerMedium;
