import type { SVGProps } from "react";
const SvgHandMotionSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M9 2.5A6.5 6.5 0 0 0 2.5 9M9 4.5C6.607 4.5 4.5 6.607 4.5 9M15 21.5a6.5 6.5 0 0 0 6.5-6.5M15 19.5c2.393 0 4.5-2.107 4.5-4.5M7.012 8.33a.991.991 0 0 1 1.95.254l-.017 3.181 6.8-6.798a1.005 1.005 0 1 1 1.42 1.423l-5.308 5.294 6.024-6.008a.994.994 0 0 1 1.404 1.407l-6.015 6.015 5.303-5.303a1 1 0 0 1 1.415 1.414l-5.303 5.303 3.19-3.18a.996.996 0 0 1 1.408 1.41s-4.246 4.243-5.66 5.658c-1.414 1.414-3.534 2.123-3.534 2.123l-4.95-4.95L7.011 8.33Z"
    />
  </svg>
);
export default SvgHandMotionSmall;
