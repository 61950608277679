import type { SVGProps } from "react";
const SvgChartLineMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.25 7.5v22.25H30v-1H7.957l4.313-4.313 4.388-1.463.213-.07.089-.207 2.12-4.947h3.06l.095-.379 1.405-5.621h2.817l.147-.146 2.25-2.25-.708-.708-2.103 2.104H22.86l-.095.379-1.405 5.621h-2.94l-.13.303-2.161 5.044-4.287 1.429-.112.037-.084.083-4.396 4.397V7.5h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChartLineMedium;
