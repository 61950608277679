import type { SVGProps } from "react";
const SvgMusicNotesLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 28V6.475c.385.523.866.972 1.42 1.322 3.434 2.193 4.547 4.692 4.554 7.212.007 2.578-1.142 5.252-2.42 7.764l.892.454c1.279-2.515 2.536-5.38 2.528-8.22-.008-2.9-1.33-5.699-5.018-8.054l-.002-.001a4.223 4.223 0 0 1-1.524-1.683c-.152-.304-.265-.851-.338-1.38a13.308 13.308 0 0 1-.088-.849L14 2.988v-.016h-.004L13 3v22a5 5 0 1 0 1 3Zm-5-4a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm9 16a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-5a4.99 4.99 0 0 1 4 2V18.08l.413-.072 17-3 .587-.104V36a5 5 0 1 1-1-3V20.095l-16 2.823V40a5 5 0 1 1-5-5Zm5-13.096 16-2.823v-2.985l-16 2.823v2.985ZM35 36a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMusicNotesLarge;
