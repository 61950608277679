import type { SVGProps } from "react";
const SvgFourSquaresMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.75 7.75h-.5v8.5h8.5v-8.5h-8Zm.5 7.5v-6.5h6.5v6.5h-6.5Zm13-7.5h-.5v8.5h8.5v-8.5h-8Zm.5 7.5v-6.5h6.5v6.5h-6.5Zm-14.5 6h8.5v8.5h-8.5v-8.5Zm1 1v6.5h6.5v-6.5h-6.5Zm13-1h-.5v8.5h8.5v-8.5h-8Zm.5 7.5v-6.5h6.5v6.5h-6.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFourSquaresMedium;
