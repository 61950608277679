import type { SVGProps } from "react";
const SvgFactoryLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.498 3h.002a4.5 4.5 0 0 1 3.857 2.19 3.5 3.5 0 1 1-1.181 5.928 4.5 4.5 0 0 1-5.204.127c.102.762-.094 1.406-.513 1.884-.499.568-1.25.826-1.96.826s-1.46-.258-1.959-.826c-.46-.526-.651-1.252-.472-2.116-1.4-1.27-1.28-3.113-.444-4.393.81-1.238 2.375-2.08 4.036-1.442A4.5 4.5 0 0 1 11.498 3Zm1.888 1.555A3.5 3.5 0 0 0 11.5 4a3.5 3.5 0 0 0-3.167 2.039l-.224.488-.47-.259c-1.254-.687-2.503-.136-3.18.9-.674 1.032-.677 2.392.472 3.264l.277.21-.1.333c-.225.741-.067 1.21.183 1.494.268.306.718.486 1.208.486s.94-.18 1.208-.486c.25-.284.408-.753.183-1.494l-.098-.326.266-.21c.115-.091.218-.195.309-.31l.336-.424.396.369a3.5 3.5 0 0 0 4.77 0l.383-.357.338.4a2.5 2.5 0 1 0 .767-3.842l-.465.238-.218-.474a3.5 3.5 0 0 0-1.29-1.484ZM2.5 16H2v29h44V21.566l-.777.518L34 29.566v-8l-.777.518L22 29.566v-8l-.777.518L10 29.566V16H2.5ZM18 44h27V23.434l-11.223 7.482-.777.518v-8l-11.223 7.482-.777.518v-8L9.777 30.916 9 31.434V17H3v27h6v-8h9v8Zm-8 0h3v-7h-3v7Zm4 0h3v-7h-3v7Zm8.5-8H22v6h5v-6h-4.5Zm.5 5v-4h3v4h-3Zm9-5h5v6h-5v-6Zm1 1v4h3v-4h-3Zm-5.623-26.928a2.5 2.5 0 0 1 2.815 1.219l.31.565.47-.441a1.5 1.5 0 0 1 1.958-.083l.497.394.267-.576a1.99 1.99 0 0 1 3.612 0l.272.588.5-.412a1.43 1.43 0 0 1 .919-.326h.002a1.5 1.5 0 0 1 1.413 2h-14.86a2.275 2.275 0 0 1-.052-.497V12.5a2.5 2.5 0 0 1 1.877-2.427Zm2.013-.79a3.5 3.5 0 0 0-4.89 3.217c-.002.402.07.802.213 1.178l.122.323h16.184l.144-.25a2.5 2.5 0 0 0-3.234-3.508 2.99 2.99 0 0 0-4.862.006 2.498 2.498 0 0 0-2.31.09 3.5 3.5 0 0 0-1.367-1.057Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFactoryLarge;
