import type { SVGProps } from "react";
const SvgSaYm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m1 1 3.28 8.204L3.06 15h2.801l1.22-5.796L13.843 1h-3.261L6.041 6.877 3.961 1H1Zm19.401 0h-4.62l-2.962 14h2.66L18.04 3.407h.04L19.201 15h2.68l6.322-11.593h.04L25.602 15h2.96l2.98-14h-4.66L21.38 11.09h-.04L20.401 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaYm;
