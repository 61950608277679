import type { SVGProps } from "react";
const SvgHandGraduationCapSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.202 2.043 12 1.953l-.202.09-8.5 3.75-1.112.49 1.136.434L6 7.741V10.5c0 .476.268.865.606 1.156.34.292.804.534 1.333.726C9.002 12.77 10.437 13 12 13c1.563 0 2.998-.23 4.061-.618.53-.192.993-.434 1.333-.726.339-.291.606-.68.606-1.156V7.741l2-.764v3.905l-.947 1.895.894.447.553-1.106.553 1.106.894-.447L21 10.882V6.594l.814-.31-1.112-.491-8.5-3.75ZM17 8.123l-4.82 1.845-.179.068-.178-.068L7 8.124V10.5c0 .074.04.21.258.397.216.186.558.376 1.023.546.928.338 2.242.557 3.72.557 1.476 0 2.79-.22 3.718-.557.466-.17.807-.36 1.023-.546.218-.187.258-.323.258-.397V8.124Zm-5 .842L4.814 6.217 12 3.047l7.186 3.17L12 8.965Zm-4.005 6.851L4.42 13.32a1.502 1.502 0 0 0-1.884 2.341l5.924 5.214.14.125h3.924l.863.855.147.145h8.857l-.847-.852-5.03-5.06-.01-.01c-1.16-1.109-2.668-2-4.276-2.074V14H9.99c-.632 0-1.144.244-1.493.636-.3.334-.463.757-.502 1.18ZM12.094 15h.001c1.304.028 2.626.758 3.715 1.797L19.988 21h-6.042l-.864-.855-.146-.145H8.979L3.19 14.905l-.008-.007-.007-.006a.502.502 0 1 1 .629-.784l.014.011.015.01 4.678 3.268c.35.375.856.603 1.478.603h2v-1h-2c-.374 0-.612-.133-.76-.3a1.034 1.034 0 0 1-.244-.696 1.07 1.07 0 0 1 .256-.702c.153-.171.39-.302.748-.302h2.105Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandGraduationCapSmall;
