import type { SVGProps } from "react";
const SvgDiscFloppySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 2H3v20h15.768l.148-.223 2-3 .084-.126V2H3.5ZM4 21V3h2v9h12V3h2v15.349L18.232 21H17v-5H7v5H4Zm3-10V3h10v8H7Zm1 10h8v-4H8v4ZM8 6h8V5H8v1Zm8 3H8V8h8v1Zm-2 9v2h1v-2h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDiscFloppySmall;
