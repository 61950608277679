import type { SVGProps } from "react";
const SvgCrossSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.707 12 5.647-5.647-.707-.707L12 11.293 6.354 5.646l-.708.707L11.293 12l-5.647 5.646.708.707L12 12.707l5.646 5.646.708-.707L12.707 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCrossSmall;
