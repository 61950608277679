import type { SVGProps } from "react";
const SvgSwitzerlandRouteLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m27.069 10.8-.4-.353-.326.422-1.942 2.506-7.24 1.973-1.316-.712-.28-.151-.256.19-4.925 3.657-.763.566.899.308 1.512.518L6.68 25.66l-.08.089-.031.114-2.18 7.978-.225.828.831-.211 4.317-1.097.069-.018.06-.035 1.58-.923 4.243 4.062.17.162.232-.026 6.37-.717.184-.02.126-.137 3.257-3.523 3.988 5.513.497.687.36-.767 2.997-6.36 6.99 1.827.707.185-.084-.726-.4-3.47 2.538.064.538.014-.026-.538-.32-6.596-.01-.232-.185-.141-2.71-2.07-.47-.358-.275.523-.688 1.303-2.695-.813 1.174-3.498.152-.452-.445-.173-7.416-2.879-2.75-2.429Zm-1.98 3.32 1.718-2.217 2.418 2.134.067.059.083.032 7.054 2.738-1.186 3.535-.164.489.494.149 3.583 1.08.394.12.192-.365.605-1.145L42.4 22.3l.282 5.826-2.575-.066-.575-.014.066.571.382 3.317-6.685-1.748-.402-.105-.177.376-2.812 5.97-3.85-5.322-.357-.495-.415.449-3.545 3.834-5.951.67-4.346-4.161-.272-.26-.326.19-1.845 1.077-3.417.868 1.921-7.035 5.78-6.41.507-.561-.717-.246-1.33-.456 3.907-2.9 1.212.655.177.095.193-.052 7.595-2.07.161-.044.102-.133Zm3.41 2.89a2 2 0 0 0-1.715 3.028l-7.28 7.732a2 2 0 1 0 .712.703l7.28-7.733a2 2 0 1 0 1.003-3.73Zm-1 2a1 1 0 1 1 2.002 0 1 1 0 0 1-2.001 0Zm-10 10.49a1 1 0 1 1 2.002 0 1 1 0 0 1-2.001 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSwitzerlandRouteLarge;
