import type { SVGProps } from "react";
const SvgGlobeLocomotiveMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.248 15.75c0-3.873 1.914-7.298 4.848-9.382l1.27.794.122.076h2.529l.097-.375.63-2.439c.65-.114 1.32-.174 2.004-.174 1.983 0 3.85.502 5.478 1.386l-1.334.97-.078.056-.052.083-1.02 1.629-3.162-.562-.265-.047-.185.195-2.45 2.58-3.397 1.433-.141.059-.084.128-.593.911-.36.553.629.197 3.473 1.09.12.038.123-.024 5.115-.97.603.305.861 2.408.942-.336-.929-2.597-.067-.188-.178-.09-.933-.471-.152-.077-.167.032-5.158.978-2.724-.854.15-.23 3.351-1.414.097-.04.072-.076 2.336-2.462 3.23.574.332.06.179-.286 1.148-1.833 1.596-1.16a11.511 11.511 0 0 1 4.817 7.034l.977-.217C26.7 7.427 21.712 3.25 15.748 3.25c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5c.267 0 .533-.008.796-.025l-.063-.998a11.502 11.502 0 0 1-11.238-6.79l1.817-2.43 2.203-.535 3.64 1.255.997 1.66.207.344.381-.129 2.398-.806.959.313.31-.95-1.116-.365-.158-.052-.157.053-2.174.731-.884-1.47-.093-.156-.172-.06-3.95-1.362-.14-.047-.142.034-2.52.613-.174.043-.108.144-1.549 2.071a11.49 11.49 0 0 1-.57-3.586Zm6.527-9.512-.747-.467a11.43 11.43 0 0 1 2.618-1.098l-.404 1.565h-1.467ZM30 15.25h-7.5v1h3.25v2h-3.192a2.808 2.808 0 0 0-2.808 2.808v8.692h2.012l-1.226 3.064.928.372 1.375-3.436h6.823l1.374 3.436.928-.372-1.226-3.064h2.012v-8.692a2.808 2.808 0 0 0-2.808-2.808H26.75v-2H30v-1Zm-9.25 13.5v-5h11v5h-11Zm11-7.692v1.692h-11v-1.692c0-.999.81-1.808 1.808-1.808h7.384c.999 0 1.808.81 1.808 1.808Zm-8 5.192a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0ZM24 25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm4.5 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGlobeLocomotiveMedium;
