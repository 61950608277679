import type { SVGProps } from "react";
const SvgBluetoothDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.584 4.129-.834-.751V13.5h1V5.623l6.228 5.605-3.29 2.632.624.78 3.75-3 .46-.367-.438-.394-7.5-6.75ZM5.646 7.854 16.13 18.336l-6.713 6.042.668.743 6.666-5.998v13.49l.832-.74 6.398-5.686 4.166 4.167.708-.707-22.5-22.5-.708.707Zm17.625 17.624-5.521-5.52v10.428l5.521-4.907Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBluetoothDisabledMedium;
