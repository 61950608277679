import type { SVGProps } from "react";
const SvgEarthSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.598 3.109A9.067 9.067 0 0 1 12 3a8.96 8.96 0 0 1 4.218 1.048l-.571.373a3.76 3.76 0 0 0-.841.805l-.56.77-.003.004H11.7a.97.97 0 0 0-.711.304L9.203 8.09l-.01.008-.01.007-1.898 1.07c-.79.444-.624 1.581.21 1.833l.004.002 1.74.51.01.002.01.003a.99.99 0 0 0 .469.013l3.743-.755a.295.295 0 0 1 .053 0l.378.093.01.002c.034.008.098.08.098.132v2.29c0 .281.119.536.296.713l.008.007 4.276 4.11a9.039 9.039 0 0 1-2.25 1.756l-.315-2.285-.001-.01-.002-.01a.97.97 0 0 0-.288-.554l-1.87-1.87-.75-.75a.998.998 0 0 0-1.158-.184h-.002l-.944.478v-.691c0-.556-.444-1-1-1H4.8c-.364 0-.699.2-.876.515l-.004.007-.552 1.023A9.002 9.002 0 0 1 3 12a8.988 8.988 0 0 1 3.746-7.308l1.012.624a.5.5 0 0 0 .262.074h1.6a.5.5 0 0 0 .484-.375l.494-1.906Zm8.633 14.25A8.96 8.96 0 0 0 21 12a8.99 8.99 0 0 0-3.839-7.374l-.968.632a2.804 2.804 0 0 0-.579.556l-.557.766a.993.993 0 0 1-.817.42h-2.533l-.004.003-1.81 1.81-.013.014-.015.012-.325-.38.325.38v.001l-.002.001-.004.004-.012.01-.04.032c-.02.015-.075.058-.138.092l-1.893 1.066-.002.001v.001h.001l.009.004 1.725.506.013.002 3.77-.76.013-.002.014-.002c.12-.017.235-.016.353-.001l.028.003.029.007.405.099c.503.115.876.601.876 1.107v2.292l.003.003 4.218 4.055Zm-4.195.392.358 2.587A8.974 8.974 0 0 1 12 21a9.001 9.001 0 0 1-8.164-5.207l.96-1.779.004-.004h5.21v.69c0 .724.768 1.243 1.447.895l.947-.478a.03.03 0 0 1 .004-.002l.748.748 1.878 1.878.002.01Zm-5.534-14.4a8.944 8.944 0 0 0-1.814.747l.474.292h1.071l.27-1.039ZM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEarthSmall;
