import type { SVGProps } from "react";
const SvgWalkieTalkieMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.25 9.25h3v22h-14.5v-13.5h-1.5v-7h1.5v-1.5h10.5V4.5h1v4.75Zm-11.5 2.5v5h-.5v-5h.5Zm1-1.5v20h12.5v-20h-12.5Zm10.75 11v1h-9v-1h9Zm-9 3v1h9v-1h-9Zm9 3v1h-9v-1h9ZM16.25 15c0-.422.203-.863.545-1.205.342-.342.783-.545 1.205-.545.422 0 .863.203 1.205.545.342.342.545.783.545 1.205 0 .422-.202.863-.544 1.206-.341.341-.781.544-1.203.544-.422 0-.864-.203-1.207-.545-.343-.343-.546-.783-.546-1.205ZM18 12.25c-.735 0-1.419.344-1.912.838-.494.493-.838 1.178-.838 1.912 0 .735.345 1.42.84 1.913.494.493 1.179.837 1.913.837.735 0 1.418-.344 1.911-.838.493-.494.836-1.178.836-1.912 0-.735-.344-1.419-.838-1.912-.493-.494-1.177-.838-1.912-.838Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWalkieTalkieMedium;
