import type { SVGProps } from "react";
const SvgArrowDownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.75 25.042 5.146-5.146.708.707-6 6-.354.354-.354-.354-6-6 .708-.707 5.146 5.146V7.5h1v17.542Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowDownMedium;
