import type { SVGProps } from "react";
const SvgClipboardQuestionMarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 2H9v1H3v19h18V3h-6V2H9.5Zm.5 1.5V3h4v1h-4v-.5ZM9 4H4v17h16V4h-5v1H9V4Zm2.305 12.783h1.266v-1.219h-1.266v1.219Zm-1.57-7.617c-.257.398-.4.961-.427 1.688h1.007c.082-1.282.645-1.922 1.688-1.922.504 0 .885.162 1.143.486.214.266.322.594.322.984 0 .211-.037.418-.112.621-.089.239-.279.506-.568.803-.449.461-.739.776-.87.944a1.887 1.887 0 0 0-.302.568c-.097.289-.15.732-.158 1.33h.996c0-.336.02-.609.06-.82.034-.211.095-.388.183-.531.088-.142.243-.321.466-.536.504-.492.822-.83.955-1.013.297-.403.445-.883.445-1.442 0-.676-.218-1.221-.656-1.635-.469-.449-1.099-.673-1.892-.673-.493 0-.936.099-1.33.298-.395.2-.71.483-.95.85Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClipboardQuestionMarkSmall;
