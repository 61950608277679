import type { SVGProps } from "react";
const SvgParkAndRailMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.504 8.75H4.502v-1h27.002v1Zm0 19.5H4.502v-1h27.002v1Zm-24.4-10.638v-3.4h2.2c.717 0 1.234.11 1.55.33.439.295.658.743.658 1.346 0 .597-.19 1.047-.566 1.355-.307.246-.808.369-1.503.369h-2.34Zm2.224-5.616H4.484v12.001h2.62v-4.324h2.692c.69 0 1.27-.068 1.741-.201a3.358 3.358 0 0 0 1.256-.662c.865-.717 1.297-1.702 1.297-2.955 0-1.413-.523-2.469-1.569-3.17a3.567 3.567 0 0 0-1.267-.536c-.468-.102-1.11-.153-1.926-.153Zm4.79 6.16h2.314v-2.312h1.326v2.313h2.322v1.324h-2.322v2.313h-1.326v-2.313h-2.313v-1.324Zm9.532-.799v-3.3h2.61c.766 0 1.327.13 1.683.395.357.26.534.678.534 1.246 0 .587-.209 1.025-.624 1.314-.329.23-.896.345-1.7.345H23.65Zm3.193-5.36h-5.812v12h2.619v-4.72h2.355c.838 0 1.43.164 1.777.49.348.323.555.911.621 1.759.065.952.119 1.561.16 1.822.041.264.109.48.2.65h2.733l-.114-.14c-.157-.202-.276-.72-.352-1.55-.104-1.135-.19-1.86-.255-2.176a2.605 2.605 0 0 0-.386-.912 2.024 2.024 0 0 0-1.248-.886 2.787 2.787 0 0 0 1.409-1.116c.336-.52.505-1.13.505-1.832a3.43 3.43 0 0 0-.403-1.666 3.031 3.031 0 0 0-1.166-1.182c-.613-.36-1.494-.542-2.643-.542Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgParkAndRailMedium;
