import type { SVGProps } from "react";
const SvgIc57Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-57-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-57-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.144.034V6.29h-4.659l-.498 2.602c.559-.59 1.273-.886 2.143-.886.855 0 1.598.23 2.231.688.939.691 1.408 1.74 1.408 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.207-.328-2.95-.982-.744-.659-1.137-1.547-1.18-2.665h2.271c.037.406.169.751.395 1.036.354.448.844.672 1.472.672.606 0 1.086-.218 1.44-.656.342-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.359-.401-.836-.602-1.432-.602-.728 0-1.295.327-1.7.981l-2.065-.031 1.1-6.447h6.351Zm9.31 0v1.962a45.8 45.8 0 0 0-.561.64c-.433.496-.863 1.12-1.29 1.875a12.726 12.726 0 0 0-.996 2.16c-.501 1.44-.804 3.082-.91 4.928h-2.389c.016-.87.166-1.836.451-2.896.29-1.06.688-2.12 1.194-3.18.723-1.497 1.456-2.612 2.2-3.345h-5.459V4.234h7.76Z"
      />
    </g>
    <defs>
      <clipPath id="ic-57-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc57Negative;
