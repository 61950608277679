import type { SVGProps } from "react";
const SvgArrowCompassFilledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M0 0h36v36H0z" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.25 29.162v-10.5H6.75L28.41 7.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCompassFilledMedium;
