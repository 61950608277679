import type { SVGProps } from "react";
const SvgFlashlightOffSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 6h9v3.096l-.036.09-.964 2.41V22H8.003V11.605l-.967-2.42L7 9.097V6Zm2.003 6H14v9H9.003v-9Zm5.758-2.5-.6 1.5H8.84l-.6-1.5h6.522Zm.239-1H8V7h7v1.5ZM10.5 13H10v7h3v-7h-2.5Zm.5 6v-1h1v1h-1Zm1-2h-1v-3h1v3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFlashlightOffSmall;
