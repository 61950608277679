import type { SVGProps } from "react";
const SvgCloudRainSnowMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m25.92 5.926.524-1.038-1.114.333c-.881.264-1.686.68-2.39 1.458-.611.675-1.123 1.599-1.56 2.874a4.954 4.954 0 0 0-.172-.067c-1.997-.724-4.471-.138-5.8 2.23-2.192-1.225-5.127.332-5.134 3.134-.744.143-1.342.585-1.758 1.167-.504.705-.753 1.624-.714 2.53.04.91.372 1.84 1.073 2.548.708.713 1.75 1.155 3.125 1.155h10.5c1.411 0 2.493-.439 3.236-1.142a3.735 3.735 0 0 0 1.15-2.54 3.902 3.902 0 0 0-.023-.666c.998.068 2.22-.13 3.381-.595 1.583-.632 3.152-1.795 3.962-3.602l.52-1.16-1.17.495c-2.932 1.239-5.311.404-6.724-1.18-1.439-1.614-1.887-4.006-.913-5.934Zm.663 10.939c.83.134 2.058.006 3.29-.486 1.035-.414 2.022-1.064 2.742-1.97-2.743.683-5.06-.236-6.53-1.884-1.413-1.585-2.036-3.846-1.488-5.919-.33.19-.633.43-.916.744-.513.568-.985 1.403-1.406 2.68.61.415 1.13.975 1.488 1.66.482.92.663 2.04.417 3.294a3.014 3.014 0 0 1 1.906 1.068c.204.245.37.52.497.813Zm-10.525-4.213c1.01-2.29 3.123-2.837 4.81-2.225.848.307 1.58.908 2.01 1.728.426.814.572 1.878.193 3.153l-.2.67.7-.028c.778-.031 1.359.275 1.746.74.396.478.605 1.143.57 1.83a2.736 2.736 0 0 1-.839 1.862c-.529.5-1.351.868-2.548.868H12c-1.15 0-1.922-.364-2.415-.86-.498-.502-.753-1.183-.784-1.887-.03-.706.167-1.4.528-1.904.355-.496.855-.799 1.471-.799h.563l-.066-.56c-.296-2.472 2.358-3.736 3.986-2.403l.51.417.265-.602ZM9.303 23.776l-1.5 3 .894.447 1.5-3-.894-.447Zm4.5 3 1.5-3 .894.447-1.5 3-.894-.447Zm-4.5 3 3-6 .894.447-3 6-.894-.447ZM19.25 25.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-2 4.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSnowMoonMedium;
