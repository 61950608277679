import type { SVGProps } from "react";
const SvgTabletSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 4.5a.5.5 0 0 1 .5-.5H10v1h4V4h3.5a.5.5 0 0 1 .5.5v16a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-16ZM6.5 3A1.5 1.5 0 0 0 5 4.5v16A1.5 1.5 0 0 0 6.5 22h11a1.5 1.5 0 0 0 1.5-1.5v-16A1.5 1.5 0 0 0 17.5 3h-11ZM14 20h-4v-1h4v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTabletSmall;
