import type { SVGProps } from "react";
const SvgSaBv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.522 6.656H5.421l.74-3.45h.981c1.06 0 2.341.06 2.341 1.404 0 1.646-1.541 2.046-2.961 2.046Zm-1.421 6.138h-.96l.841-4.052h.899c1.501 0 2.861.22 2.861 1.686 0 2.165-1.921 2.366-3.641 2.366ZM8.062 1H3.921L1 15h3.982c2.08 0 6.56-.321 6.56-4.333 0-1.504-.68-2.707-2.24-2.947v-.04c1.62-.261 2.98-1.626 2.98-3.33 0-2.548-2.04-3.35-4.22-3.35Zm9.68 0H14.94l1.84 14h3.34l7.802-14h-3.04l-5.801 10.89h-.04L17.742 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaBv;
