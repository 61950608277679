import type { SVGProps } from "react";
const SvgTrainProfileSignalMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#train-profile-signal-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m15 4.649.277.185 4.5 3 .624.416-.624.416-3.126 2.084H21c3.03 0 5.596 1.868 6.678 4.5h.004l.031.086c.04.1.077.2.112.303l3.146 8.674c.17.353.279.753.279 1.187a2.75 2.75 0 0 1-2.75 2.75h-3.285a3.491 3.491 0 0 1-1.015 2H33v1H3v-1h2.8a3.491 3.491 0 0 1-1.015-2H3v-1h25.5a1.75 1.75 0 0 0 1.75-1.75c0-.271-.069-.53-.188-.77l-.012-.025-.01-.026-.88-2.43h-9.41v-7h6.832c-1.017-2.07-3.125-3.5-5.582-3.5h-5.849l-.151.102-.151-.101H3v-1h10.349l-3.126-2.084-.624-.416.624-.416 4.5-3L15 4.649Zm11.983 11.6H20.75v5h8.047l-1.814-5Zm-8.698 12c.112.78.48 1.476 1.015 2h-8.6a3.491 3.491 0 0 0 1.015-2h6.57Zm-12.485 0h4.9a2.5 2.5 0 0 1-4.9 0Zm13.5 0a2.5 2.5 0 0 0 4.9 0h-4.9Zm-7.899-20L15 10.65l3.599-2.4L15 5.852 11.401 8.25ZM7.11 17.598c-1.813 1.877-1.813 4.928 0 6.805l-.72.695c-2.187-2.265-2.187-5.93 0-8.195l.72.695Zm2.25 4.93a2.219 2.219 0 0 1 0-3.055l-.72-.695a3.219 3.219 0 0 0 0 4.445l.72-.695Zm5.25-5.625c2.187 2.265 2.187 5.93 0 8.195l-.72-.695c1.813-1.877 1.813-4.928 0-6.805l.72-.695Zm-2.25 6.32a3.219 3.219 0 0 0 0-4.445l-.72.695a2.219 2.219 0 0 1 0 3.055l.72.695Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="train-profile-signal-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTrainProfileSignalMedium;
