import type { SVGProps } from "react";
const SvgRobotMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.75 5.25a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 0 0-.5 3.937V9.25h-9v3h-3v7h3v4.5h19v-4.5h3v-7h-3v-3h-9V7.187a2 2 0 0 0-.5-3.937Zm9.5 10v5h2v-5h-2Zm-19 0v5h-2v-5h2Zm1-3v12.5h17v-12.5h-17Zm-5.5 18.513c0-1.693 1.45-3.013 3.172-3.013h21.656c1.723 0 3.172 1.32 3.172 3.013V33h-1v-4.237c0-1.083-.943-2.013-2.172-2.013H7.922c-1.229 0-2.172.93-2.172 2.013V33h-1v-4.237Zm9.065-10.516c.862 1.518 2.752 2.503 4.935 2.503s4.073-.985 4.935-2.503l-.87-.494c-.645 1.136-2.154 1.997-4.065 1.997-1.911 0-3.42-.861-4.065-1.997l-.87.494Zm1.935-3.197a.05.05 0 1 0 0-.1.05.05 0 0 0 0 .1ZM14.8 15a.95.95 0 1 1 1.9 0 .95.95 0 0 1-1.9 0Zm7 0a.05.05 0 1 1-.1 0 .05.05 0 0 1 .1 0Zm-.05-.95a.95.95 0 1 0 0 1.9.95.95 0 0 0 0-1.9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRobotMedium;
