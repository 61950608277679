import type { SVGProps } from "react";
const SvgArrowLongRightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.844 12.138-3.142-3.141-.707.707 2.288 2.287H4v1h14.285l-2.29 2.296.708.707 3.142-3.15.352-.353-.353-.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongRightSmall;
