import type { SVGProps } from "react";
const SvgGlassCocktailMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.112 10.39 1.2-2H9.829l1.333 2h7.95Zm1.166 0h4.35l1.333-2h-4.483l-1.2 2Zm3.683 1H11.829l6.066 9.099 6.066-9.099Zm-15.066-4h12.017l2.554-4.257.858.514-2.246 3.743h5.751l-.518.777-8.916 13.374v9.849h6.25v1h-13.5v-1h6.25v-9.849L8.479 8.167 7.96 7.39h.934Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGlassCocktailMedium;
