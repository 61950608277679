import type { SVGProps } from "react";
const SvgWavesLadderMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.75 7.5a2.75 2.75 0 1 1 5.5 0h-1a1.75 1.75 0 1 0-3.5 0v3.25h6.5V7.5a2.75 2.75 0 1 1 5.5 0h-1a1.75 1.75 0 1 0-3.5 0v15.237c.827-.09 1.285-.615 1.863-1.307l.027-.033c.612-.734 1.373-1.647 2.86-1.647v1c-.995 0-1.484.56-2.12 1.32l-.027.033c-.612.734-1.374 1.647-2.86 1.647-1.487 0-2.248-.913-2.86-1.647l-.028-.033c-.635-.76-1.125-1.32-2.12-1.32-.992 0-1.48.559-2.115 1.32l-.027.033c-.611.733-1.372 1.647-2.857 1.647-1.484 0-2.245-.914-2.855-1.647l-.028-.033c-.634-.762-1.122-1.32-2.114-1.32-.992 0-1.48.558-2.115 1.32l-.027.033c-.61.733-1.371 1.647-2.855 1.647s-2.243-.914-2.853-1.647l-.028-.033c-.633-.762-1.12-1.32-2.111-1.32v-1c1.483 0 2.243.914 2.852 1.647l.028.033c.634.762 1.121 1.32 2.112 1.32.991 0 1.48-.558 2.114-1.32l.027-.033c.61-.733 1.371-1.647 2.856-1.647 1.484 0 2.245.914 2.855 1.647l.028.033c.634.762 1.123 1.32 2.114 1.32.803 0 1.277-.366 1.764-.909V7.5Zm7.5 15.122V16.25h-6.5v4.453c.525-.514 1.206-.953 2.236-.953 1.486 0 2.248.913 2.86 1.647l.027.033c.454.544.834.985 1.378 1.192Zm0-7.372v-3.5h-6.5v3.5h6.5ZM3 26.75c.99 0 1.478.559 2.111 1.32l.028.034c.61.733 1.37 1.647 2.853 1.647 1.484 0 2.244-.914 2.855-1.647l.027-.033c.634-.762 1.123-1.32 2.115-1.32.992 0 1.48.558 2.114 1.32l.028.033c.61.733 1.371 1.647 2.855 1.647 1.485 0 2.246-.914 2.857-1.647l.027-.033c.635-.762 1.123-1.32 2.116-1.32.994 0 1.484.559 2.12 1.32l.027.033c.612.733 1.373 1.647 2.86 1.647 1.486 0 2.248-.914 2.86-1.647l.027-.033c.636-.761 1.125-1.32 2.12-1.32v-1c-1.486 0-2.248.913-2.86 1.646l-.027.033c-.636.762-1.125 1.32-2.12 1.32-.995 0-1.485-.558-2.12-1.32l-.027-.033c-.612-.733-1.374-1.646-2.86-1.646-1.485 0-2.246.913-2.856 1.646l-.028.034c-.634.761-1.123 1.32-2.116 1.32-.991 0-1.48-.559-2.114-1.32l-.028-.034c-.61-.733-1.37-1.646-2.855-1.646-1.485 0-2.245.913-2.856 1.647l-.027.033c-.634.761-1.123 1.32-2.114 1.32-.99 0-1.478-.558-2.112-1.32l-.028-.033C5.242 26.665 4.483 25.75 3 25.75v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWavesLadderMedium;
