import type { SVGProps } from "react";
const SvgArrowCircleResetMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.488 13.75H13.5v1H6.25V7.515h1v4.556C9.404 8.603 13.566 6.25 18 6.25c6.801 0 11.75 4.949 11.75 11.75S24.801 29.75 18 29.75c-5.697 0-10.103-3.479-11.375-8.63l.97-.24c1.158 4.69 5.152 7.87 10.405 7.87 6.249 0 10.75-4.501 10.75-10.75S24.249 7.25 18 7.25c-4.596 0-8.834 2.737-10.512 6.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleResetMedium;
