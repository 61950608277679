import type { SVGProps } from "react";
const SvgSaRb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.741 6.656h-1.1l.74-3.45h.98c1.06 0 2.341.06 2.341 1.404 0 1.646-1.54 2.046-2.96 2.046Zm-1.42 6.138h-.96l.84-4.052h.9c1.5 0 2.86.22 2.86 1.686 0 2.165-1.92 2.366-3.64 2.366ZM20.28 1h-4.14l-2.922 14H17.2c2.08 0 6.56-.321 6.56-4.333 0-1.504-.68-2.707-2.24-2.947v-.04c1.62-.261 2.98-1.626 2.98-3.33C24.5 1.802 22.46 1 20.28 1ZM6.541 6.977h-1.02l.78-3.77h1.16c1.14 0 2.401 0 2.401 1.464 0 1.845-1.82 2.306-3.321 2.306ZM7.221 1H3.92L1 15h2.8l1.221-5.816h1.06c1.29 0 1.428.751 1.635 1.886.037.202.077.417.125.641L8.521 15h2.941l-1.34-5.636c-.18-.763-.661-1.243-1.42-1.283v-.04c2.28-.04 4.08-1.325 4.08-3.731C12.782 1 9.87 1 7.312 1H7.22Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaRb;
