import type { SVGProps } from "react";
const SvgHandSafetyShieldLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m20 5.989.103.022 9.5 2 .397.083V8.5c0 8.15-3.746 15.223-9.691 19.893l-.309.243-.309-.243C13.746 23.723 10 16.651 10 8.5v-.406l.397-.083 9.5-2L20 5.989Zm-8.997 2.916c.12 7.517 3.559 14.045 8.997 18.455 5.438-4.41 8.877-10.938 8.997-18.455L20 7.011l-8.997 1.894Zm8.867 9.931 5-5.5-.74-.672-4.647 5.112-2.63-2.63-.707.708 3 3 .371.37.353-.388Zm-3.163 13.552-8.661-5.851a2.492 2.492 0 0 0-3.497.401 2.5 2.5 0 0 0 .382 3.507l12.245 10.436.14.119h7.977l1.853 1.854.147.146H43.98l-1.178-.898-10.653-8.114-.314-.26C29.84 32.08 27.41 30.07 23.73 30H19.5c-.878 0-1.584.29-2.071.768a2.416 2.416 0 0 0-.721 1.62ZM7.47 27.354l9.665 6.53a2.4 2.4 0 0 0 .294.348c.487.478 1.193.768 2.071.768h4v-1h-4c-.652 0-1.094-.21-1.371-.482a1.42 1.42 0 0 1-.424-1.018c0-.373.142-.742.424-1.018.277-.272.72-.482 1.371-.482h4.215c3.32.065 5.502 1.865 7.524 3.532l.283.234.008.006.007.006L41.018 42h-13.31l-1.854-1.853-.147-.147h-8.023L5.574 29.68l-.005-.005-.006-.005a1.5 1.5 0 0 1-.233-2.107l.002-.002a1.492 1.492 0 0 1 2.105-.231l.016.013.017.011ZM20.098 8.01 20 7.99l-.098.02-7.5 1.5-.445.089.046.451a25.801 25.801 0 0 0 7.649 15.809l.348.338.349-.339c4.181-4.069 7.026-9.677 7.648-15.808l.046-.451-.445-.09-7.5-1.5ZM20 24.796A24.82 24.82 0 0 1 13.047 10.4L20 9.01l6.953 1.39c-.668 5.543-3.226 10.618-6.953 14.396Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandSafetyShieldLarge;
