import type { SVGProps } from "react";
const SvgFaceKingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m6 1.617.757.454 2.165 1.3 1.224-1.225.354-.353.354.353L12 3.293l1.146-1.147.354-.353.354.353 1.223 1.224 2.166-1.299.757-.454V8.3a7.648 7.648 0 0 1 2 5.717 8 8 0 0 1-16 0A7.65 7.65 0 0 1 6 8.3V1.617ZM6.716 9a6.65 6.65 0 0 0-1.717 4.964V14a7 7 0 1 0 14 0v-.018l.002-.018A6.65 6.65 0 0 0 17.284 9H6.716ZM7 3.383V8h10V3.383L15.257 4.43l-.335.2-.276-.275L13.5 3.207l-1.146 1.147-.354.353-.354-.353L10.5 3.207 9.353 4.354l-.276.276-.334-.201L7 3.383Zm7.73 9.347a.18.18 0 1 0 0 .36.18.18 0 0 0 0-.36Zm-1.18.18a1.18 1.18 0 1 1 2.36 0 1.18 1.18 0 0 1-2.36 0Zm-4.46 0a.18.18 0 1 1 .36 0 .18.18 0 0 1-.36 0Zm.18-1.18a1.18 1.18 0 1 0 0 2.36 1.18 1.18 0 0 0 0-2.36Zm.374 6.678a5 5 0 0 1-1.8-1.63l.831-.556a4 4 0 0 0 6.65 0l.83.556a5 5 0 0 1-6.51 1.63Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaceKingSmall;
