import type { SVGProps } from "react";
const SvgStarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18 3.67.475 1.422 2.886 8.658h9.346l-1.15.895-6.467 5.03 2.883 8.36.48 1.391-1.228-.81L18 23.85l-7.224 4.767-1.228.81.48-1.39 2.883-8.36-6.468-5.031-1.15-.895h9.347l2.886-8.658L18 3.67Zm0 3.161-2.525 7.577-.114.342H8.208l5.6 4.356.281.22-.116.337-2.52 7.308 6.272-4.138.275-.182.276.182 6.272 4.139-2.52-7.309-.117-.338.282-.22 5.6-4.355H20.64l-.114-.342L18 6.831Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStarMedium;
