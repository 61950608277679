import type { SVGProps } from "react";
const SvgRe29 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="re-29_svg__color-immutable"
    {...props}
  >
    <g fill="#EB0000" clipPath="url(#re-29_svg__a)">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.979 1.979 0 0 1-.197-.625c-.037-.253-.088-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.709-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.171-.949-.514-1.202-.343-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm9.382 9.508v2.056h-8.33c0-.627.1-1.226.3-1.795.207-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.941-.744 1.163-1.076.221-.332.332-.717.332-1.155 0-.506-.15-.915-.451-1.226-.295-.316-.686-.474-1.17-.474-1.193 0-1.807.857-1.844 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.765-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.698.918.785.664 1.178 1.558 1.178 2.681 0 .981-.313 1.825-.941 2.531a4.72 4.72 0 0 1-.577.562c-.18.137-.652.472-1.416 1.005-.728.506-1.25.893-1.567 1.162a6.348 6.348 0 0 0-.87.926h5.427Zm.886-.846h2.183c.18.896.699 1.344 1.558 1.344.485 0 .878-.163 1.179-.49.306-.332.54-.847.704-1.543.105-.443.182-.946.23-1.51-.354.495-.718.849-1.093 1.06-.369.205-.82.308-1.352.308-.85 0-1.587-.248-2.215-.744a3.543 3.543 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.446-2.357 1.337-3.164.764-.696 1.682-1.044 2.752-1.044.66 0 1.269.148 1.828.443a3.961 3.961 0 0 1 1.408 1.258c.67.965 1.004 2.32 1.004 4.066 0 1.613-.295 2.99-.886 4.129-.77 1.482-1.935 2.222-3.496 2.222-1.039 0-1.898-.287-2.579-.862-.675-.575-1.065-1.347-1.17-2.317Zm3.844-6.985c-.496 0-.915.203-1.258.609-.343.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.337.544.812.815 1.424.815a1.58 1.58 0 0 0 1.329-.664c.31-.417.466-.912.466-1.487 0-.607-.16-1.12-.482-1.543a1.606 1.606 0 0 0-1.337-.656Z" />
    </g>
    <defs>
      <clipPath id="re-29_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe29;
