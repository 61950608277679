import type { SVGProps } from "react";
const SvgCloudSnowflakeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.18 14.986a2.821 2.821 0 0 1 1.656.877c.54.583.834 1.368.896 2.167.123 1.586-.667 3.405-2.547 4.02l-.311-.95c1.337-.437 1.957-1.747 1.86-2.993-.047-.617-.271-1.175-.632-1.564-.35-.378-.852-.62-1.531-.594l-.698.028.199-.67c.378-1.274.232-2.338-.194-3.153a3.665 3.665 0 0 0-2.011-1.728c-1.686-.612-3.8-.064-4.81 2.226l-.266.602-.508-.418c-1.627-1.333-4.282-.068-3.986 2.404l.067.56h-.563c-1.064 0-1.86.956-1.986 2.231-.123 1.24.433 2.538 1.85 3.019l-.322.947c-1.96-.666-2.68-2.48-2.523-4.065.138-1.39.99-2.807 2.454-3.084.007-2.802 2.943-4.358 5.134-3.133 1.328-2.367 3.802-2.954 5.8-2.23a4.67 4.67 0 0 1 2.555 2.205c.482.92.663 2.041.417 3.296ZM13.184 18.39l2.859 2.859h-4.022v1h4.042l-2.858 2.858.707.707 2.838-2.838V27h1v-4.043l2.859 2.859.707-.707-2.859-2.86h4.064v-1h-4.044l2.86-2.859-.707-.707-2.88 2.88V16.5h-1v4.043l-2.859-2.86-.707.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudSnowflakeMedium;
