import type { SVGProps } from "react";
const SvgDocumentDocSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.01 4h6.697l.146.146 5 5 .147.147V20H6v-.5l.01-15V4Zm1 1L7 19h10v-9h-5V5H7.01Zm5.99.707L16.293 9H13V5.707ZM9.07 15H8v2.59h1.08c.179.002.357-.022.53-.07a1 1 0 0 0 .37-.24 1.42 1.42 0 0 0 .38-1 1.28 1.28 0 0 0-.4-1 .89.89 0 0 0-.38-.21 1.528 1.528 0 0 0-.51-.07Zm-.49 2.16v-1.67l.42.02a.78.78 0 0 1 .58.2.89.89 0 0 1 .19.62 1 1 0 0 1-.21.67.601.601 0 0 1-.23.16 1.34 1.34 0 0 1-.33 0h-.42ZM12 14.9c.331-.006.651.12.89.35a1.4 1.4 0 0 1 .39 1 1.45 1.45 0 0 1-.3.94 1.219 1.219 0 0 1-1 .45 1.24 1.24 0 0 1-1-.46 1.47 1.47 0 0 1-.29-.93 1.35 1.35 0 0 1 .38-1 1.273 1.273 0 0 1 .93-.35Zm-.016.48a.65.65 0 0 0-.504.25 1 1 0 0 0-.2.66 1 1 0 0 0 .2.67.66.66 0 0 0 .23.17.67.67 0 0 0 .31.07.649.649 0 0 0 .5-.22 1.05 1.05 0 0 0 .21-.69 1 1 0 0 0-.2-.66.63.63 0 0 0-.59-.25h.044Zm3.736 1.92c.17-.2.268-.449.28-.71l-.65-.01a.67.67 0 0 1-.19.45.53.53 0 0 1-.41.16.609.609 0 0 1-.56-.31 1.11 1.11 0 0 1-.14-.6 1.1 1.1 0 0 1 .14-.59.65.65 0 0 1 1-.19.56.56 0 0 1 .2.34h.57a.89.89 0 0 0-.26-.58 1.14 1.14 0 0 0-.89-.37 1.181 1.181 0 0 0-.94.4 1.4 1.4 0 0 0-.36 1 1.5 1.5 0 0 0 .3.95 1.189 1.189 0 0 0 1 .44 1.13 1.13 0 0 0 .91-.38Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentDocSmall;
