import type { SVGProps } from "react";
const SvgSaSf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.301 3.133c1.021 0 2.081.291 2.901.698l.66-2.23C10.722 1.155 9.461 1 8.241 1 5.561 1 3.04 2.24 3.04 5.092c0 1.978 1.433 2.755 2.726 3.456 1.065.577 2.035 1.103 2.035 2.205 0 1.454-1.32 2.114-2.661 2.114-1.2 0-2.34-.445-3.42-.93L1 14.244c1.26.446 2.64.756 3.98.756 3.061 0 5.742-1.202 5.742-4.518 0-2.138-1.537-3.017-2.865-3.775C6.85 6.13 5.961 5.622 5.961 4.684c0-1.163 1.34-1.55 2.34-1.55Zm14.881-1.9h-7.7L12.54 14.767h2.8l1.281-5.933h4.681l.44-2.133h-4.66l.74-3.336h4.881l.48-2.132Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaSf;
