import type { SVGProps } from "react";
const SvgDiscFloppyMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.25 3.25h-.5v29.5h23.268l.148-.223 3-4.5.084-.126V3.25h-26Zm.5 28.5V4.25h3.5v13.5h17.5V4.25h3.5v23.349l-2.768 4.151H25.25v-7.5h-14.5v7.5h-5Zm4.5-15V4.25h15.5v12.5h-15.5Zm1.5 15h12.5v-6.5h-12.5v6.5Zm.25-23h12v-1H12v1Zm12 4.5H12v-1h12v1ZM21.25 27v3h1v-3h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDiscFloppyMedium;
