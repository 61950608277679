import type { SVGProps } from "react";
const SvgChevronSmallLeftCircleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 12a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm1.323 3.74-3.95 3.904-.36.356.36.355 3.948 3.904.703-.71L10.436 12l3.59-3.549-.703-.71Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallLeftCircleSmall;
