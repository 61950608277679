import type { SVGProps } from "react";
const SvgFreightWagonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 8h18v6.006h1.001v1H21V16h-2v.003a1 1 0 0 1-2 0V16H7v.003a1 1 0 0 1-2 0V16H3v-.994H1.998v-1H3V8Zm1 1v6h4V9H4Zm5 0v6h6V9H9Zm11 6h-4V9h4v6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonSmall;
