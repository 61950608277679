import type { SVGProps } from "react";
const SvgSignParkingLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 4.5A2.5 2.5 0 0 1 16.5 2h14A2.5 2.5 0 0 1 33 4.5v18a2.5 2.5 0 0 1-2.5 2.5H24v21h-1V25h-6.5a2.5 2.5 0 0 1-2.5-2.5v-18ZM16.5 3A1.5 1.5 0 0 0 15 4.5v18a1.5 1.5 0 0 0 1.5 1.5h14a1.5 1.5 0 0 0 1.5-1.5v-18A1.5 1.5 0 0 0 30.5 3h-14Zm2.926 10.42V7.276H23.7c.732 0 1.287.036 1.667.107.379.073.726.207 1.04.406.862.533 1.292 1.384 1.292 2.553 0 1.148-.444 2-1.333 2.553a3.124 3.124 0 0 1-1.04.406c-.386.078-.904.117-1.553.117h-4.348Zm4.573-7.373h-6v14.991h1.427V14.65h4.747c1.415 0 2.513-.277 3.292-.83 1.107-.78 1.66-1.963 1.66-3.549 0-1.558-.57-2.717-1.712-3.476a3.848 3.848 0 0 0-1.374-.584c-.506-.11-1.185-.164-2.04-.164Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSignParkingLarge;
