import type { SVGProps } from "react";
const SvgSaPg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 3a7.06 7.06 0 0 1 3.91 1l.7-2.42A13.19 13.19 0 0 0 21.95.8c-5.04 0-8.38 3.14-8.38 8.24 0 4.32 2.74 6.2 6.84 6.2a11.29 11.29 0 0 0 3.74-.62L25.75 7h-5.66l-.5 2.16h3l-.78 3.7a3.67 3.67 0 0 1-1.38.14 3.88 3.88 0 0 1-3.97-4c0-3.34 2.14-6 5.54-6ZM1.39 15h2.8l1.12-5.38h1.62c3.12 0 5.9-1.52 5.9-5S9.29 1 6.71 1H4.39l-3 14Zm5.4-7.5h-1l.9-4.26h.88c1.2 0 2.34.3 2.34 1.72 0 1.74-1.58 2.54-3.12 2.54Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaPg;
