import type { SVGProps } from "react";
const SvgDocumentCheckSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.496 4h-.5v.5L6 19.5v.5h12v-.5l-.002-10.006v-.207l-.147-.147-4.998-4.994L12.707 4H6.496ZM7 19 6.996 5H12v5h.5l4.498-.005L17 19H7Zm9.29-10.005L13 5.707v3.292l3.29-.004ZM8.001 14h3v-1H8v1Zm3 3H8v-1h3v1Zm2.353-2.646 3-3-.707-.708L13 13.293l-.646-.647-.707.708 1 1 .353.353.354-.353Zm3 0-3 3-.354.353-.353-.353-1-1 .707-.708.646.647 2.647-2.647.707.708Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentCheckSmall;
