import type { SVGProps } from "react";
const SvgContextMenuSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.5 5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM12 20.5a1.5 1.5 0 1 0 .001-2.999A1.5 1.5 0 0 0 12 20.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M12 7a2 2 0 0 0 2-2h-1a1 1 0 0 1-1 1v1Zm-2-2a2 2 0 0 0 2 2V6a1 1 0 0 1-1-1h-1Zm2-2a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1V3Zm2 2a2 2 0 0 0-2-2v1a1 1 0 0 1 1 1h1Zm-2 9a2 2 0 0 0 2-2h-1a1 1 0 0 1-1 1v1Zm-2-2a2 2 0 0 0 2 2v-1a1 1 0 0 1-1-1h-1Zm2-2a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1v-1Zm2 2a2 2 0 0 0-2-2v1a1 1 0 0 1 1 1h1Zm-1 7a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2h-1Zm-1-1a1 1 0 0 1 1 1h1a2 2 0 0 0-2-2v1Zm-1 1a1 1 0 0 1 1-1v-1a2 2 0 0 0-2 2h1Zm1 1a1 1 0 0 1-1-1h-1a2 2 0 0 0 2 2v-1Z"
    />
  </svg>
);
export default SvgContextMenuSmall;
