import type { SVGProps } from "react";
const SvgBellMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.25 4.75h5.5v1.853c1.943.54 3.318 1.647 4.256 2.949 1.312 1.822 1.744 3.993 1.744 5.448v9c0 .545.168 1.374.656 2.052.47.652 1.257 1.198 2.594 1.198v1h-7.78c-.12.946-.58 1.679-1.275 2.181-.792.573-1.844.819-2.945.819-1.1 0-2.153-.246-2.945-.819-.695-.502-1.155-1.235-1.274-2.181H6v-1c.545 0 1.374-.168 2.052-.656.652-.47 1.198-1.257 1.198-2.594v-9c0-1.455.432-3.626 1.744-5.448.938-1.302 2.313-2.409 4.256-2.949V4.75Zm1 1.635A10.958 10.958 0 0 1 18 6.25c.623 0 1.206.047 1.75.135V5.75h-3.5v.635ZM14.791 28.25a2.07 2.07 0 0 0 .85 1.371c.566.41 1.388.629 2.359.629.97 0 1.793-.22 2.359-.629a2.07 2.07 0 0 0 .85-1.371H14.79ZM10.25 15c0-1.295.393-3.249 1.556-4.864C12.95 8.546 14.863 7.25 18 7.25s5.05 1.296 6.194 2.886C25.357 11.75 25.75 13.705 25.75 15v9c0 .705.207 1.751.844 2.636.158.219.34.426.55.614H8.838c.81-.667 1.412-1.712 1.412-3.25v-9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBellMedium;
