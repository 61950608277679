import type { SVGProps } from "react";
const SvgWheelMicrophoneLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 24C3 12.402 12.402 3 24 3c7.335 0 13.934 3.707 17.688 9.394l.835-.551C38.584 5.876 31.673 2 24 2 11.85 2 2 11.85 2 24s9.85 22 22 22c2.25 0 4.318-.296 6.37-.927l-.293-.956c-1.95.6-3.92.883-6.077.883-11.598 0-21-9.402-21-21Zm18 0a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm13.5 17a3.5 3.5 0 0 1-3.5-3.5v-14a3.5 3.5 0 1 1 7 0v14a3.5 3.5 0 0 1-3.5 3.5ZM42 19.5v14a4.5 4.5 0 1 1-9 0v-14a4.5 4.5 0 1 1 9 0ZM37 45v-3.012c-4.416-.212-8-3.238-8-7.988h1c0 4.277 3.306 7 7.5 7s7.5-2.723 7.5-7h1c0 4.75-3.584 7.776-8 7.988V45h4v1h-9v-1h4ZM24 7C14.611 7 7 14.611 7 24s7.611 17 17 17c1.747 0 3.34-.23 4.919-.715l.294.956C27.533 41.758 25.84 42 24 42c-9.941 0-18-8.059-18-18S14.059 6 24 6c5.493 0 10.508 2.428 13.835 6.305l-.76.651C33.937 9.297 29.197 7 24 7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelMicrophoneLarge;
