import type { SVGProps } from "react";
const SvgBulbOffPercentageSignalSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M5.901 17c-1.4-.8-2.4-2.3-2.4-4 0-2.5 2-4.5 4.5-4.5a4.479 4.479 0 0 1 4.384 3.474M19 13.094l-7 7.875M13.5 2a8.5 8.5 0 0 0 8.5 8.5M15.5 2A6.5 6.5 0 0 0 22 8.5M17.5 2A4.5 4.5 0 0 0 22 6.5M19.5 2A2.5 2.5 0 0 0 22 4.5M10.5 17v2.1l-2 .7v.7h-1v-.7l-2-.7V17h5Zm3-3.469a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm4 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
    />
  </svg>
);
export default SvgBulbOffPercentageSignalSmall;
