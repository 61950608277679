import type { SVGProps } from "react";
const SvgLocomotiveLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 6h20V5H5v1Zm13 28V17h-1v17h1Zm-3-23V9H2v1h12v29H2v1h1.028a4.5 4.5 0 0 0 1.631 2.99H2v1h5.2a4.538 4.538 0 0 0 .6 0h20.4a4.539 4.539 0 0 0 .6 0H46v-1H31.34A4.505 4.505 0 0 0 32.973 40h.534a13.59 13.59 0 0 0 5.872-1.225l.01-.005a6.5 6.5 0 0 0 3.251-8.113c-1.088-2.977-2.775-7.296-4.562-11.139-.893-1.921-1.815-3.733-2.703-5.202-.868-1.435-1.74-2.608-2.56-3.206-.784-.7-1.97-1.155-3.235-1.463-1.292-.314-2.737-.488-4.079-.583a45.327 45.327 0 0 0-4.918-.068l-.08.003L20.48 9h-.007l.026.5-.027-.5-.473.026V11h-5Zm16.964 29h-6.928a3.5 3.5 0 0 0 3.2 2.99h.529a3.5 3.5 0 0 0 3.2-2.99Zm-6.305 2.99A4.505 4.505 0 0 1 24.028 40H11.972a4.5 4.5 0 0 1-1.631 2.99h15.318ZM5.025 41.975A3.5 3.5 0 0 1 4.035 40h6.93a3.5 3.5 0 0 1-3.2 2.99h-.53a3.5 3.5 0 0 1-2.21-1.015ZM20 12h-5v27h5V12Zm10.162 6.005a.03.03 0 0 0-.017-.005H25v11h9.61a.09.09 0 0 0 .076-.04.09.09 0 0 0 .007-.087l-.005-.01-4.51-10.83-.005-.014a.03.03 0 0 0-.01-.014ZM30.138 17a1.03 1.03 0 0 1 .966.653l4.506 10.82A1.09 1.09 0 0 1 34.596 30H24V17h6.138ZM21 39V9.98a44.34 44.34 0 0 1 4.43.08c1.313.094 2.696.263 3.913.559 1.23.299 2.226.712 2.821 1.252l.021.019.023.016c.645.463 1.437 1.483 2.31 2.928.862 1.425 1.767 3.2 2.652 5.106 1.771 3.808 3.448 8.099 4.53 11.062l.001.003a5.5 5.5 0 0 1-2.744 6.863A12.588 12.588 0 0 1 33.519 39H21ZM2 17.05h9.05A.95.95 0 0 1 12 18v11.05a.95.95 0 0 1-.95.95H2v-1h9V18.05H2v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocomotiveLarge;
