import { Menu } from "@headlessui/react";

import { ChevronRightSmall } from "./icons";
import type { UsermenuProps } from "./Usermenu";

function Logo() {
  return (
    <svg
      className="w-16"
      viewBox="0 0 60 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="text-red" d="M0 0h60v21H0V0z" fill="currentColor" />
      <path
        className="text-white"
        d="M35.186 17.02h3.75l-5.047-5.163h6.265v5.163h2.96v-5.163h6.267l-5.05 5.163h3.752l6.427-6.708-6.426-6.73h-3.752l5.05 5.185h-6.266V3.583h-2.96v5.184h-6.267l5.047-5.184h-3.75l-6.43 6.73 6.43 6.707"
        fill="currentColor"
      />
    </svg>
  );
}

export declare interface HeaderProps {
  children?: React.ReactNode;
  env?: "dev" | "edu" | "int" | "test";
  title?: string;
  subtitle?: string;
  usermenu?: React.ReactElement<UsermenuProps>;
}

const envColorMapping = {
  dev: "bg-peach",
  edu: "bg-green",
  int: "bg-granite",
  test: "bg-night",
};

function Header({ children, env, subtitle, title, usermenu }: HeaderProps) {
  return (
    <div className="border-b border-silver">
      {env && (
        <div
          className={`absolute -left-6 top-3 -rotate-45 px-8 text-xs text-white ${envColorMapping[env]}`}
        >
          {env}
        </div>
      )}
      <div className="container mx-auto flex h-14 items-center justify-between px-4">
        <div className="flex">
          {title && (
            <div className="mr-8">
              <div>{title}</div>
              {subtitle && <div className="text-sm text-metal">{subtitle}</div>}
            </div>
          )}
          <nav className="flex items-center space-x-6">{children}</nav>
        </div>
        <div className="flex w-80 justify-between">
          {usermenu}
          <Logo />
        </div>
      </div>
    </div>
  );
}

declare interface HeaderMenuProps {
  children:
    | React.ReactElement<HeaderMenuItemProps>
    | React.ReactElement<HeaderMenuItemProps>[];
  button: React.ReactNode;
}

function HeaderMenu({ children, button, ...props }: HeaderMenuProps) {
  return (
    <Menu className="relative" as="div" {...props}>
      {({ open }) => (
        <>
          <div className="z-10 rounded-sm border border-white">
            <Menu.Button className="group flex w-full space-x-2 border-b border-white p-2">
              <div>{button}</div>
              <ChevronRightSmall className="rotate-90 transition-transform duration-300 group-hover:text-red125" />
            </Menu.Button>
          </div>
          {open ? (
            <div className="absolute left-0 top-0 z-30 rounded-sm bg-white">
              <Menu.Button className="group flex w-full justify-between space-x-2 border border-iron border-b-cloud p-2">
                <div>{button}</div>
                <ChevronRightSmall className="-rotate-90 transition-transform duration-300 group-hover:text-red125" />
              </Menu.Button>
              <div className="flex w-full flex-col-reverse">
                <div className="-mt-1 h-2 rounded-b-sm bg-black opacity-20" />
                <Menu.Items className="z-10 rounded-b-sm border border-t-0 border-iron bg-white py-1">
                  {children}
                </Menu.Items>
              </div>
            </div>
          ) : null}
        </>
      )}
    </Menu>
  );
}

export type HeaderMenuItemProps = {
  active?: boolean;
  children: React.ReactNode;
} & (React.HTMLProps<HTMLButtonElement> | React.HTMLProps<HTMLAnchorElement>);

function HeaderMenuItem({
  active,
  children,
  ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...props
}: HeaderMenuItemProps) {
  const as = props.href ? "a" : "button";

  return (
    <Menu.Item
      aria-checked={active || false}
      className={`flex w-full whitespace-nowrap border-l px-2 py-1 hover:border-red125 hover:bg-milk hover:text-red125 ${
        active ? "border-iron bg-cloud" : "border-white"
      }`}
      {...props}
      as={as}
    >
      {children}
    </Menu.Item>
  );
}

Header.Menu = HeaderMenu;
Header.MenuItem = HeaderMenuItem;

export default Header;
