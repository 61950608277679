import type { SVGProps } from "react";
const SvgWashingMachineSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 2h16v20H4V2Zm1 1v4h14V3H5Zm14 5H5v13h14V8ZM8.012 15.318a4 4 0 0 0 7.891.56c-.31-.137-.513-.362-.659-.524l-.017-.02c-.228-.252-.32-.334-.53-.334-.21 0-.302.082-.53.335l-.017.018c-.224.25-.582.647-1.256.647s-1.031-.398-1.255-.647l-.016-.018c-.228-.254-.32-.335-.529-.335-.207 0-.298.08-.526.334l-.017.019c-.222.248-.58.647-1.253.647-.673 0-1.03-.398-1.254-.647l-.017-.019-.015-.016Zm7.976-.633-.018-.02-.017-.018c-.224-.25-.582-.647-1.256-.647s-1.032.398-1.256.646l-.017.02c-.228.252-.32.334-.53.334-.209 0-.3-.081-.528-.335l-.017-.018c-.223-.249-.58-.647-1.255-.647-.673 0-1.03.399-1.253.647l-.017.019c-.227.253-.318.334-.526.334-.208 0-.3-.081-.527-.334l-.017-.02c-.145-.161-.348-.387-.657-.524a4.002 4.002 0 0 1 7.89.563ZM6 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm9 0h3V4h-3v1Zm-2 0h-3V4h3v1Zm-1 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWashingMachineSmall;
