import type { SVGProps } from "react";
const SvgIc38 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-38_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-38_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm20.637 3.696c.027-.949.261-1.745.704-2.389.718-1.033 1.796-1.55 3.236-1.55 1.107 0 2 .282 2.681.846.733.612 1.1 1.403 1.1 2.373 0 .58-.145 1.082-.435 1.503a2.1 2.1 0 0 1-1.163.847c.622.142 1.113.459 1.471.949.364.49.546 1.084.546 1.78 0 1.144-.422 2.08-1.266 2.808-.78.675-1.766 1.012-2.958 1.012-1.292 0-2.318-.371-3.077-1.115-.754-.744-1.131-1.748-1.131-3.014v-.15h2.223c0 .712.168 1.263.506 1.653.337.385.815.578 1.431.578.617 0 1.092-.182 1.424-.546a1.78 1.78 0 0 0 .46-1.226c0-.57-.196-1.02-.586-1.353-.322-.274-.818-.411-1.487-.411-.19 0-.377.01-.562.032V8.822c.137.01.269.016.395.016 1.33 0 1.994-.493 1.994-1.48 0-.432-.145-.78-.435-1.044-.29-.269-.673-.403-1.147-.403-.543 0-.963.169-1.258.506-.295.338-.453.83-.474 1.48h-2.192ZM47.367 9.59c-.538-.186-.952-.489-1.242-.91-.285-.422-.427-.934-.427-1.535 0-1.002.411-1.801 1.234-2.397.728-.527 1.593-.791 2.594-.791 1.14 0 2.07.314 2.793.941.685.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.733.532 1.1 1.31 1.1 2.333 0 1.176-.451 2.112-1.353 2.808-.76.586-1.735.878-2.926.878-1.292 0-2.32-.321-3.085-.965-.797-.67-1.195-1.547-1.195-2.634 0-1.018.353-1.806 1.06-2.365a2.925 2.925 0 0 1 1.068-.522Zm2.128-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.316.285.738.427 1.265.427.528 0 .944-.142 1.25-.427.306-.285.459-.672.459-1.163 0-.48-.156-.854-.467-1.123-.306-.269-.733-.403-1.281-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.359.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.369.354.846.53 1.431.53.665 0 1.163-.195 1.495-.585a1.93 1.93 0 0 0 .483-1.297c0-.56-.184-1.018-.554-1.377-.353-.348-.84-.522-1.463-.522Z"
      />
    </g>
    <defs>
      <clipPath id="ic-38_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc38;
