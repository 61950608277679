import type { SVGProps } from "react";
const SvgTicketJourneySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.993 3.007a5 5 0 0 0-5 5v.019c.015.388.075.75.174 1.094l.961-.278a3.532 3.532 0 0 1-.135-.844 4 4 0 0 1 8 .007c-.007.624-.2 1.198-.53 1.779l-3.458 5.458-.603-.952-.845.536 1.025 1.618.423.666.422-.666 3.887-6.135.006-.01.006-.01c.391-.687.659-1.432.667-2.277v-.005a5 5 0 0 0-5-5Zm.006 3.994a1 1 0 1 0-.001 1.998 1 1 0 0 0 .001-1.998Zm-2 1a2 2 0 1 1 3.999-.002 2 2 0 0 1-3.999.002Zm-9 2.999H4v.993H2.993v-2h1v-.001H5V11Zm-1.007 9.994h-1v-2H4v.999h1V21H3.992v-.007Zm3-2.001v-1H5v-1h-.008V16h1v1.99h5V16h1v.992h-1v1h-2v1h2V20h1v1H13v-.007h1v-2h-1.007v.999H12v-1h-1.007v-1h2v-1h1v-1H12v-1h1.993v-1H13v-1h-1.007V12H12v-1h.993v.993H14v-2h-1v-.001h-1.008v1h-1v1h-1v1H9v-1H7.992v-1h-2v1h-1v1h-1v1h-1v1.009H4v-1.008h1.992v1h-1v1h-2v1h1V18h1v.992h2Zm0 0H8v1h1.993v1h-2V20H7v1H5.992v-1.008h1v-1ZM6 12.992h1.992v-1H6v1Zm4.992 2h1v-1.998h-1v2Zm-3-4h2v-1h-2v1Zm0 5.009H9v-1.008H7.992V16Zm2 .99h-3v-3h3v3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketJourneySmall;
