import type { SVGProps } from "react";
const SvgTrainTracksHorizontalSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 7v2h2V7h1v2h2V7h1v2h2V7h1v2h2V7h1v2h2V7h1v2h1v1h-1v5h1v1h-1v2h-1v-2h-2v2h-1v-2h-2v2h-1v-2h-2v2h-1v-2H8v2H7v-2H5v2H4v-2H3v-1h1v-5H3V9h1V7h1Zm14 8v-5h-2v5h2Zm-5-5h2v5h-2v-5Zm-1 5v-5h-2v5h2Zm-5-5h2v5H8v-5Zm-1 5v-5H5v5h2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrainTracksHorizontalSmall;
