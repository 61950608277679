import type { SVGProps } from "react";
const SvgSaWv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 15c.81 0 1.47-.66 1.47-1.47 0-.81-.66-1.47-1.47-1.47-.81 0-1.47.66-1.47 1.47 0 .81.66 1.47 1.47 1.47Zm.01-6.58c1.39 0 2.71.52 3.73 1.47l-1.39 1.4c-.64-.58-1.46-.9-2.34-.9-.88 0-1.7.32-2.34.9l-1.39-1.4a5.447 5.447 0 0 1 3.73-1.47Zm7.53-2.28-.16.14-.01.01c-1.89-2.1-4.62-3.31-7.41-3.31a9.95 9.95 0 0 0-7.34 3.24l-1.39-1.4C3.48 2.39 6.64 1 9.98 1s6.55 1.42 8.8 3.89l-1.24 1.25Zm-1.355 1.145.005.005v-.01l-.005.005ZM10.03 4.69c2.348 0 4.526.918 6.155 2.595L14.8 8.68a6.584 6.584 0 0 0-4.77-2.02c-1.82 0-3.58.77-4.83 2.1l-1.39-1.4a8.585 8.585 0 0 1 6.22-2.67Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaWv;
