import type { SVGProps } from "react";
const SvgOfficeChairMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.75 6.25h-.5v13h4.5v2h-6v4h6v5H12v1h12v-1h-4.75v-5h6v-4h-6v-2h4.5v-13h-11Zm5.5 13h-.5v2h.5v-2Zm1-1h3.5v-11h-9.5v11h6Zm-1 7v5h-.5v-5h.5Zm-1.5-1h7.5v-2h-12.5v2h5Zm10-7.75V21h-1v-4.5h1ZM10.25 21v-4.5h-1V21h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOfficeChairMedium;
