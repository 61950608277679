import type { SVGProps } from "react";
const SvgNextMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.022 7.83-.772-.5v21.34l.772-.502 7.5-4.875 7.5-4.875.645-.419-.645-.42-7.5-4.874-7.5-4.875ZM7.25 18V9.171l6.727 4.373L20.834 18l-6.855 4.456-6.728 4.373V18ZM24.752 7.75h-.5v20.5h5.5V7.75h-5Zm4 19.5h-3.5V8.75h3.5v18.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNextMedium;
