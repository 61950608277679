import type { SVGProps } from "react";
const SvgDownloadSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12 14.076 3.05-3.074.71.705-3.905 3.935-.354.357-.355-.357-3.908-3.935.709-.705L11 14.076V7h1v7.077ZM4 17.51V15h1v2.01h13v-2h1v3H4v-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDownloadSmall;
