import type { SVGProps } from "react";
const SvgTargetSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 4v3h3v1h-3.293l-1 1H18v1h-3.293l-.126.126a6 6 0 1 1-.707-.707L14 9.293V6h1v2.293l1-1V4h1Zm-7 5c1.2 0 2.302.423 3.164 1.129l-1.426 1.426a3 3 0 1 0 .707.707l1.426-1.426A5 5 0 1 1 10 9Zm0 3c.371 0 .718.1 1.016.277l-.757.757a1 1 0 1 0 .707.707l.757-.757A2 2 0 1 1 10 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTargetSmall;
