import type { SVGProps } from "react";
const SvgFlameWarningLightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#flame-warning-light-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m11.502 3.943.872.973c1.434 1.6 2.625 2.859 3.672 3.965l.637.674c1.197 1.27 2.215 2.376 3.21 3.635l-.785.62c-.966-1.223-1.959-2.303-3.153-3.569l-.63-.667-.003-.003a213.877 213.877 0 0 1-2.853-3.06c-.076 1.345-.292 2.587-.746 3.865-.59 1.664-1.571 3.36-3.122 5.41-1.053 1.392-2.851 4.289-2.851 7.512 0 2.969 1.674 5.454 3.928 7.097-.507-.787-.888-1.7-.888-2.664 0-1.376.392-2.43 1.207-3.574.379-.532.746-.98 1.078-1.385l.129-.157c.368-.45.682-.846.944-1.28.509-.842.849-1.868.849-3.71v-1.368l.882 1.045c1.77 2.098 2.902 3.492 3.926 4.904l-.81.588c-.837-1.155-1.75-2.302-3.06-3.87-.13 1.257-.459 2.147-.931 2.928-.303.5-.658.946-1.026 1.396l-.13.16a23.88 23.88 0 0 0-1.037 1.329c-.714 1.002-1.02 1.857-1.02 2.994 0 .75.32 1.525.818 2.26.495.73 1.138 1.383 1.717 1.88L12 32.25l-.187.464C8.178 31.25 4.75 27.84 4.75 23.298c0-3.557 1.962-6.673 3.053-8.115 1.516-2.003 2.434-3.608 2.978-5.14.542-1.53.721-3.014.721-4.793V3.943ZM24.5 18v-3h-1v3h1Zm-4.94-2.123 1.5 2.625-.87.496-1.5-2.625.87-.496ZM18 28.75h-.39l-.095.379-.75 3-.155.621h14.78l-.155-.621-.75-3-.095-.379h-2.14V24a4.25 4.25 0 0 0-8.5 0v4.75H18ZM27.25 24v4.75h-6.5V24a3.25 3.25 0 0 1 6.5 0Zm-9.36 7.75.5-2h11.22l.5 2H17.89Zm9.92-12.752 1.5-2.625-.87-.496-1.5 2.625.87.496Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="flame-warning-light-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFlameWarningLightMedium;
