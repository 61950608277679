import type { SVGProps } from "react";
const SvgSa1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.667 1H1v14h11.667V1ZM11.37 13.727H2.296V2.273h9.074v11.454Zm-4.152-8.1v6.828h1.56v-8.91H7.4l-2.511 2.25.881 1.337 1.448-1.504Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSa1;
