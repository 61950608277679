import type { SVGProps } from "react";
const SvgUserLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.567 12.836C18.648 9.97 21.151 8 24 8c2.849 0 5.352 1.97 6.433 4.836a12.16 12.16 0 0 1-3.4-1.968 1.44 1.44 0 0 0-1.618-.185 3.32 3.32 0 0 1-2.83 0 1.44 1.44 0 0 0-1.618.185 12.16 12.16 0 0 1-3.4 1.968Zm-.351 1.18A9.12 9.12 0 0 0 17 16c0 4.479 3.191 8 7 8s7-3.521 7-8c0-.687-.075-1.351-.216-1.984a13.159 13.159 0 0 1-4.392-2.38l-.006-.005-.006-.006a.44.44 0 0 0-.501-.056l-.012.007-.012.006a4.321 4.321 0 0 1-3.71 0l-.012-.006-.012-.007a.44.44 0 0 0-.5.056l-.007.006-.007.005a13.16 13.16 0 0 1-4.391 2.38ZM24 7c-4.475 0-8 4.09-8 9 0 2.454.88 4.703 2.32 6.338C12.264 25.293 8 32.794 8 41.5v.5h32v-.5c0-8.705-4.246-16.21-10.319-19.162C31.12 20.702 32 18.453 32 16c0-4.91-3.525-9-8-9ZM9.005 41c.162-8.357 4.387-15.325 10.064-17.91C20.421 24.284 22.127 25 24 25c1.873 0 3.58-.716 4.931-1.91 5.695 2.584 9.902 9.552 10.064 17.91H9.005ZM26.5 15a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-3.4 4.3a3 3 0 0 0 4.8 0l-.8-.6a2 2 0 0 1-3.2 0l-.8.6ZM22 31h-3v-1h-1v1h-1v1h5v-1Zm-1-15.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserLarge;
