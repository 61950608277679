import type { SVGProps } from "react";
const SvgMicroscooterProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.5 5H16v1h1v7.035A3.508 3.508 0 0 0 14.035 16H7.732A2 2 0 0 0 4 17c0 1.104.897 2 2 2s2-.896 2-2h7v-.5c0-1.377 1.122-2.5 2.5-2.5h.5V5h-.5ZM6 16c-.55 0-1 .449-1 1 0 .55.45 1 1 1s1-.45 1-1c0-.551-.45-1-1-1Zm12 0c-.55 0-1 .449-1 1 0 .55.45 1 1 1s1-.45 1-1c0-.551-.45-1-1-1Zm-2 1c0-1.104.897-2 2-2s2 .896 2 2c0 1.103-.897 2-2 2s-2-.897-2-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMicroscooterProfileSmall;
