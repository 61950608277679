import type { SVGProps } from "react";
const SvgIr19 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-19_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.251 4.2h4.07L8.286 15.8H4.2L9.251 4.2Zm16.818 8.166a.93.93 0 0 0 .849-.553L30.22 4.2H16.038l-5.035 11.6h4.052l3.812-8.69h6.014l-1.205 2.775-5.244-.019 4.231 5.934h4.613l-3.134-3.434h1.927Zm16.376-8.132V15.8h-2.31V8.23h-2.719V6.552c.564-.016 1.024-.074 1.38-.174.356-.1.681-.264.977-.49.427-.327.714-.878.862-1.654h1.811Zm3.259 8.662h2.183c.18.896.699 1.345 1.559 1.345.485 0 .879-.165 1.182-.495.303-.33.537-.842.7-1.538.105-.443.182-.947.23-1.511-.354.496-.716.848-1.088 1.056-.372.208-.824.312-1.357.312-.849 0-1.587-.247-2.214-.743a3.509 3.509 0 0 1-1.025-1.353 4.404 4.404 0 0 1-.367-1.803c0-1.303.445-2.357 1.336-3.164.765-.696 1.682-1.044 2.753-1.044.659 0 1.268.147 1.827.443a3.961 3.961 0 0 1 1.408 1.257c.67.965 1.005 2.32 1.005 4.066 0 1.614-.296 2.99-.886 4.129-.77 1.482-1.936 2.223-3.497 2.223-1.038 0-1.897-.288-2.574-.863-.678-.574-1.07-1.347-1.175-2.317Zm3.844-6.985c-.495 0-.915.203-1.257.61-.343.41-.514.933-.514 1.566 0 .511.123.965.371 1.36.338.543.812.815 1.424.815.554 0 .997-.222 1.329-.665.311-.416.467-.912.467-1.487 0-.606-.161-1.12-.483-1.542a1.606 1.606 0 0 0-1.337-.657Z"
    />
  </svg>
);
export default SvgIr19;
