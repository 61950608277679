import type { SVGProps } from "react";
const SvgNj = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="nj_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.966 15.78h-2.23L7.594 7.964 5.96 15.78H3.73L6.158 4.148h2.238l3.15 7.784 1.626-7.784h2.222L12.966 15.78Zm9.469-11.632h2.349l-1.468 7.046c-.392 1.867-.905 3.131-1.54 3.793-.634.66-1.61.991-2.927.991-1.117 0-1.952-.256-2.508-.77-.555-.512-.833-1.21-.833-2.094 0-.185.013-.391.04-.619l2.214-.238a4.423 4.423 0 0 0-.048.58c0 .37.106.653.317.848.212.196.537.294.976.294.614 0 1.045-.18 1.293-.54.186-.275.41-1.05.675-2.325l1.46-6.966Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNj;
