import type { SVGProps } from "react";
const SvgLongDistanceCoachProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4.02h14.851a1.5 1.5 0 0 1 1.365.885v.001l.042.094H20.5A1.5 1.5 0 0 1 22 6.5V9h-1V6.5a.5.5 0 0 0-.5-.5h-1.795l3.082 6.906.002.005c.137.318.21.66.211 1.007V16.5a1.5 1.5 0 0 1-1.5 1.5h-4.541a3 3 0 0 1-5.918 0H2v-1h8.041a3 3 0 0 1 5.918 0H20.5a.5.5 0 0 0 .5-.5v-2.579a1.56 1.56 0 0 0-.128-.61v-.002l-3.568-7.994a.5.5 0 0 0-.455-.295H2v-1ZM2 7h2v3H2v1h3V6H2v1Zm11.5-1h-.663l.182.637 2 7 .104.363h5.145l-.31-.703-3.1-7L16.724 6H13.5Zm5.232 7h-2.855l-1.714-6h1.912l2.657 6ZM13 15.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM6.5 6H6v5h7.314l-.184-.638-1.15-4L11.877 6H6.5Zm.5 4V7h4.123l.863 3H7Zm6 3H2v-1h11v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLongDistanceCoachProfileSmall;
