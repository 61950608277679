import type { SVGProps } from "react";
const SvgDocumentPptSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.01 4h6.697l.146.147 5 5 .147.146V20H6v-.5l.009-15V4Zm.999 1L7 19h10v-9h-5V5H7.01ZM13 5.708 16.293 9H13V5.708ZM8.668 16.405v-.849h.561c.183 0 .315.027.396.082.111.073.167.186.167.336 0 .15-.048.262-.145.338-.078.061-.206.093-.382.093h-.597Zm.567-1.403H8V18h.668v-1.08h.687c.175 0 .323-.017.443-.05a.876.876 0 0 0 .321-.166c.22-.179.331-.425.331-.738 0-.353-.134-.617-.4-.792a.952.952 0 0 0-.324-.135 2.606 2.606 0 0 0-.49-.037Zm2.249.554v.85h.597c.176 0 .304-.033.382-.094.097-.076.145-.189.145-.338 0-.15-.056-.263-.167-.336-.081-.055-.213-.082-.396-.082h-.561Zm-.668-.554h1.235c.208 0 .371.013.491.037a.951.951 0 0 1 .324.135c.266.175.4.44.4.792 0 .313-.111.56-.331.738a.876.876 0 0 1-.321.165c-.12.034-.268.05-.443.05h-.687V18h-.668v-2.998Zm5.184.555v-.554h-2.523v.554h.925V18h.668v-2.444H16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentPptSmall;
