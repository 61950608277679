import type { SVGProps } from "react";
const SvgMinusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.5 18.25H30v1H4.5v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMinusMedium;
