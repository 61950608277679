import type { SVGProps } from "react";
const SvgBicycleTandemMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#bicycle-tandem-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M27.75 9.25H24v1h2.816l-2.334 3.5H20.75v-3H16.5v1h3.25v2h-6.5v-3H9v1h3.25v2.382l-.827 1.654A5.719 5.719 0 0 0 9 15.25 5.756 5.756 0 0 0 3.25 21 5.756 5.756 0 0 0 9 26.75a5.756 5.756 0 0 0 5.745-6h6.51a5.756 5.756 0 0 0 5.745 6A5.756 5.756 0 0 0 32.75 21a5.756 5.756 0 0 0-6.885-5.638l-.538-1.076 2.839-4.259.518-.777h-.934Zm-8.309 5.5-2.5 5h-2.328a5.764 5.764 0 0 0-2.322-3.463l.768-1.537h6.382ZM22.25 21c0-.084.002-.167.006-.25h6.303l-.362-.724-1.865-3.73A4.756 4.756 0 0 1 31.75 21 4.756 4.756 0 0 1 27 25.751 4.756 4.756 0 0 1 22.25 21Zm4.691-1.25-1.6-3.2a4.772 4.772 0 0 0-2.924 3.2h4.524Zm-5.554 0a5.772 5.772 0 0 1 3.504-4.1l-.45-.9h-3.882l-2.5 5h3.328Zm-10.411-3.07A4.72 4.72 0 0 0 9 16.25 4.756 4.756 0 0 0 4.25 21 4.756 4.756 0 0 0 9 25.75a4.756 4.756 0 0 0 4.743-5H8.941l.362-.724 1.673-3.345Zm2.607 3.07H10.56l1.279-2.557a4.766 4.766 0 0 1 1.745 2.557Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="bicycle-tandem-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBicycleTandemMedium;
