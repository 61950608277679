import type { SVGProps } from "react";
const SvgSaNj = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.541 1h-3.54L1 14.763h2.681l2.22-10.549h.04l2.92 10.55h3.602L15.483 1h-2.64l-2.28 10.451h-.041L7.541 1Zm13.66 9.347L23.161 1h-2.8l-1.96 9.268c-.26 1.262-.74 2.327-2.22 2.327-.5 0-.942-.1-1.282-.218l-.639 2.347A9.366 9.366 0 0 0 16.6 15c3.161 0 4.061-1.893 4.601-4.653Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaNj;
