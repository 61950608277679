import type { SVGProps } from "react";
const SvgKRMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.504 8.75H4.502v-1h27.002v1Zm0 19.5H4.502v-1h27.002v1ZM10.437 16.743l4.704-4.746h-3.317L7.12 16.963v-4.967H4.501v12.002h2.62v-3.875l1.51-1.51 3.645 5.385h3.307l-5.146-7.256Zm3.584 1.415h2.313v-2.313h1.326v2.313h2.32v1.324h-2.32v2.313h-1.326v-2.313H14.02v-1.324Zm9.517-.8v-3.3h2.61c.767 0 1.328.13 1.683.395.357.26.534.678.534 1.246 0 .587-.208 1.025-.624 1.314-.328.23-.895.345-1.7.345h-2.503Zm3.194-5.36h-5.813v12h2.62v-4.72h2.354c.839 0 1.43.164 1.778.49.348.323.555.911.62 1.759.065.952.12 1.561.16 1.822.042.264.11.48.2.65h2.734l-.114-.14c-.158-.202-.276-.72-.353-1.55-.103-1.135-.189-1.86-.255-2.176a2.605 2.605 0 0 0-.385-.912 2.024 2.024 0 0 0-1.248-.886 2.787 2.787 0 0 0 1.408-1.116c.336-.52.506-1.13.506-1.832a3.42 3.42 0 0 0-.404-1.666 3.031 3.031 0 0 0-1.165-1.182c-.614-.36-1.494-.542-2.643-.542Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgKRMedium;
