import type { SVGProps } from "react";
const SvgMusicRockHandGestureLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 10a2 2 0 0 0-2 2l-.002 7.5c0 1.772.725 3.463 2.001 4.683V26H7v12.004L7.503 38l10-.076.496-.004V26h-1v-1.817a6.48 6.48 0 0 0 2-4.683V12a2 2 0 1 0-4 0v3.268a1.995 1.995 0 0 0-2.5.41 1.996 1.996 0 0 0-2.5-.41V12a2 2 0 0 0-2-2Zm7.999 16v-2.26l.167-.15A5.48 5.48 0 0 0 18 19.5V12a1 1 0 1 0-2 0v7h-1v-2a1 1 0 0 0-2 0v2h-1v-2a1 1 0 0 0-2 0v2H9v-7a1 1 0 0 0-2 0v7.5c0 1.564.666 3.052 1.833 4.09l.167.15V26h7Zm-8 10.996V29h9v7.928l-7 .053V31h-1v5.989l-1 .007Zm9-8.996h-9v-1h9v1Zm8.002 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0-1a3.99 3.99 0 0 1 3 1.354V15.043l.39-.088 13-2.943.611-.138V29.31h-.012a4 4 0 1 1-.988-2.955V16.126l-12.001 2.717V33a4 4 0 1 1-4-4Zm4-11.182L41.002 15.1v-1.975l-12.001 2.716v1.976ZM35.002 29a3 3 0 1 1 5.999-.001 3 3 0 0 1-5.999.001Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMusicRockHandGestureLarge;
