import type { SVGProps } from "react";
const SvgMachineLearningSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 3.311a6.568 6.568 0 0 1 2-.311 6.5 6.5 0 0 1 6.48 7.003A6.469 6.469 0 0 1 21 13.5v.5h-3v1.085a1.5 1.5 0 1 1-1 0V14h-2v5.085a1.5 1.5 0 1 1-1 0V14h-2v1.085a1.5 1.5 0 1 1-1 0V14H9v5.085a1.5 1.5 0 1 1-1 0V14H6v1.085a1.5 1.5 0 1 1-1 0V14H2v-.5c0-1.288.374-2.488 1.02-3.497A6.5 6.5 0 0 1 9.5 3c.694 0 1.367.11 2 .311Zm0 1.06a5.517 5.517 0 0 1 2.127 1.463l.745-.668a6.47 6.47 0 0 0-1.354-1.145 5.501 5.501 0 0 1 5.933 4.742 6.49 6.49 0 0 0-2.826-1.558l-.25.968A5.504 5.504 0 0 1 19.978 13H15c0-.349.12-.588.283-.742.167-.158.414-.258.717-.258v-1c-.525 0-1.028.176-1.404.532-.38.36-.596.87-.596 1.468h-2v-2.55A2.5 2.5 0 0 0 14 8h-1a1.5 1.5 0 0 1-3 0H9a2.5 2.5 0 0 0 2 2.45V13H9c0-.598-.216-1.109-.596-1.468C8.028 11.176 7.525 11 7 11v1c.303 0 .55.1.717.258.163.154.283.393.283.742H3.022a5.504 5.504 0 0 1 4.103-4.827l-.25-.968A6.49 6.49 0 0 0 4.05 8.763a5.501 5.501 0 0 1 5.933-4.742 6.47 6.47 0 0 0-1.354 1.145l.744.668A5.517 5.517 0 0 1 11.5 4.37ZM8 20.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm9.5-4.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-6 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm2.5 4.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM5.5 16a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMachineLearningSmall;
