import type { SVGProps } from "react";
const SvgDocumentSbbMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.263 6.25h9.694l.146.147 7.5 7.5.147.146v15.708H9.25v-.5l.013-22.5v-.5Zm1 1-.013 21.5h15.5v-14h-7.5v-7.5h-7.987Zm8.987.708 5.793 5.793H19.25V7.958ZM21.6 23.25 19.2 21H21l3 3-3 3h-1.8l2.401-2.25H18.75V27h-1.5v-2.25H14.4L16.8 27H15l-3-3 3-3h1.8l-2.4 2.25h2.85V21h1.5v2.25h2.85Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentSbbMedium;
