import type { SVGProps } from "react";
const SvgBucketFoamBroomLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M36 3.5a.5.5 0 0 1 1 0v26.518a7.016 7.016 0 0 0-1 0V3.5Zm2 0v26.663c2.835.627 5 2.985 5 5.837v10h-.5v-.5.5h-12c.004-.002.35-.146.499-.49l-1-.01V36c0-2.852 2.165-5.21 5-5.837V3.41h.003a1.5 1.5 0 0 1 2.993-.015H38V3.5ZM9.5 14a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM7 15.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm9 3.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-6.5 8a4.5 4.5 0 0 0-4.473 4h8.946a4.5 4.5 0 0 0-4.473-4Zm0-1a5.5 5.5 0 0 0-5.478 5h-3.19l.188.641 5 17 .106.359h12.748l.106-.359 5-17 .188-.641h-9.19a5.5 5.5 0 0 0-5.478-5Zm-7.332 6 4.706 16h11.252l2.059-7H13v-1h9.479l.588-2H14v-1h9.361l1.47-5H4.169ZM15 11c0-.548.45-1 1.014-1 .553 0 .986.44.986 1s-.433 1-.986 1A1.007 1.007 0 0 1 15 11Zm1.014-2A2.007 2.007 0 0 0 14 11c0 1.109.906 2 2.014 2 1.12 0 1.986-.903 1.986-2s-.865-2-1.986-2ZM11.5 5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM36.5 31c-3.066 0-5.5 2.295-5.5 5v9h2v-8h1v8h2v-5h1v5h2v-7h1v7h2v-9c0-2.705-2.434-5-5.5-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBucketFoamBroomLarge;
