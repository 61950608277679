import type { SVGProps } from "react";
const SvgCutlerySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 3v4.5c0 .413.12 1.022.494 1.542.32.445.813.802 1.506.918V21h1V9.96a2.263 2.263 0 0 0 1.506-.918C7.88 8.522 8 7.913 8 7.5V3H7v4.5c0 .253-.08.644-.306.958C6.487 8.746 6.137 9 5.5 9c-.637 0-.987-.254-1.194-.542A1.736 1.736 0 0 1 4 7.5V3H3Zm2 0v5h1V3H5Zm5.5 0H10v18h1v-8h2V6c0-1.002-.116-1.795-.553-2.32-.465-.558-1.17-.68-1.947-.68Zm1.5 9h-1V4.027c.373.047.562.153.678.293.188.225.322.682.322 1.68v6Zm4.502-7.344c-.3.45-.502 1.1-.502 1.844 0 .743.202 1.394.502 1.844.302.453.665.656.998.656.333 0 .696-.203.998-.656.3-.45.502-1.1.502-1.844 0-.743-.202-1.394-.502-1.844C18.196 4.203 17.833 4 17.5 4c-.333 0-.696.203-.998.656Zm-.832-.555C16.09 3.47 16.729 3 17.5 3c.771 0 1.409.469 1.83 1.101.424.636.67 1.486.67 2.399s-.246 1.763-.67 2.399c-.326.49-.782.881-1.33 1.033V21h-1V9.932c-.548-.152-1.004-.543-1.33-1.033C15.246 8.263 15 7.413 15 6.5s.246-1.763.67-2.399Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCutlerySmall;
