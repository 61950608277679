import type { SVGProps } from "react";
const SvgMovingBusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 7a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v4H7V7Zm0 5v4h10v-4H7Zm2-8a3 3 0 0 0-3 3v2H5v2h1v8h4v-2h4.001v2H18v-8h1V9h-1V7a3 3 0 0 0-3-3H9Zm6.001 14v-1H17v1h-1.999ZM7 17v1h2v-1H7Zm2-4a1 1 0 1 0 .002 2A1 1 0 0 0 9 13Zm5 1a1 1 0 1 1 2.002.001A1 1 0 0 1 14 14Zm5 1h1v-2h-1v2Zm3 1h-1v-4h1v4ZM4 13v2h1v-2H4Zm-2 3v-4h1v4H2ZM15 6H9v1h6V6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMovingBusSmall;
