import type { SVGProps } from "react";
const SvgIncreaseSizeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 4h2.293l-3.647 3.646.708.707L20 4.706V7h1V3h-4v1ZM2 10h.52V9H2v1Zm1.563 0h1.041V9H3.562v1Zm2.083 0h1.042V9H5.646v1Zm2.083 0h1.042V9H7.729v1Zm2.083 0h1.042V9H9.813v1Zm2.084 0h1.041V9h-1.041v1Zm2.083 0H14v.021h1v-1.02h-1.02v1ZM14 11.063v1.041h1v-1.041h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.084v1.041h1v-1.041h-1Zm0 2.083v1.042h1v-1.042h-1Zm0 2.083V22h1v-.52h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIncreaseSizeSmall;
