import type { SVGProps } from "react";
const SvgSaP = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.401 7.478h1c1.54 0 3.121-.802 3.121-2.547 0-1.424-1.141-1.725-2.341-1.725h-.88l-.9 4.272ZM3.8 15H1L3.961 1h2.36c2.581 0 6.121.14 6.121 3.63 0 3.39-2.78 5.055-5.901 5.055h-1.62L3.8 15Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaP;
