import type { SVGProps } from "react";
const SvgSaGr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.462 3.133c1.46 0 2.801.291 3.881.97l.7-2.347C12.582 1.213 10.943 1 9.382 1 4.341 1 1 4.044 1 8.99 1 13.176 3.74 15 7.842 15c1.359 0 2.92-.271 3.74-.601l1.601-7.388H7.522l-.46 2.133h2.96l-.781 3.587c-.319.136-.74.136-1.379.136-2.381 0-3.941-1.765-3.941-3.955 0-3.2 2.14-5.78 5.541-5.78ZM19.921 7.01H18.9l.78-3.646h1.16c1.14 0 2.4 0 2.4 1.416 0 1.784-1.82 2.23-3.32 2.23Zm.68-5.778H17.3l-2.92 13.534h2.8l1.22-5.623h1.06c1.29 0 1.428.727 1.636 1.824.037.196.076.403.125.62l.68 3.18h2.94l-1.34-5.45c-.18-.737-.66-1.201-1.42-1.24v-.039c2.28-.038 4.08-1.28 4.08-3.607 0-3.2-2.911-3.2-5.47-3.2h-.09Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaGr;
