import type { SVGProps } from "react";
const SvgClipboardCrossMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.25 3.25h-.5v1.5h-9v28h26.5v-28h-9v-1.5h-8Zm.5 2.5v.5h6.5v-2h-6.5v1.5Zm7.5 0v1.5h-8.5v-1.5h-8v26h24.5v-26h-8ZM18 20.207l-7.146 7.147-.708-.708 7.147-7.146-7.147-7.146.708-.708L18 18.793l7.146-7.147.708.708-7.147 7.146 7.147 7.146-.708.708L18 20.207Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClipboardCrossMedium;
