import type { SVGProps } from "react";
const SvgFunicularProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.997 3.01a1.5 1.5 0 0 0-.652-.033h-.002l-16.33 2.88.174.986 16.328-2.88a.5.5 0 0 1 .585.5V5.81h-4v7h4v1.67a.5.5 0 0 1-.4.5L2.002 18.32l.196.98.662-.132a.969.969 0 0 1 .24.641 1 1 0 0 1-.575.906l-.523.105.098.49v.5a2 2 0 0 0 .72-.134l9.334-1.867c.23-.006.454-.052.665-.133l4.335-.867c.23-.006.454-.052.665-.133l4.38-.876-.197-.98-2.997.6c.062-.196.095-.4.095-.61h-.5.5c0-.295-.065-.586-.192-.852a1.5 1.5 0 0 0 1.192-1.492V4.473a1.5 1.5 0 0 0-1.103-1.464Zm-1.472 14.705a1 1 0 0 0 .575-.905v-.001a.97.97 0 0 0-.24-.64l-1.71.341c-.031.098-.048.2-.05.303a1 1 0 0 0 .956.996l.469-.094Zm-1.95.39a2 2 0 0 1-.475-1.295v-.006l.003-.084-1.195.239a1.97 1.97 0 0 1 .097 1.46l1.57-.314Zm-3.05.61a.998.998 0 0 0 .575-.905v-.001a.97.97 0 0 0-.24-.64l-1.71.341c-.031.098-.048.2-.05.303a1 1 0 0 0 .956.996l.469-.094Zm-1.95.39a2 2 0 0 1-.475-1.295v-.006l.003-.084-6.195 1.239a1.97 1.97 0 0 1 .097 1.46l6.57-1.314ZM4.1 19.81h-.5.5Zm10-2h-.5.5Zm5-6v-5h-3v5h3Zm-10.5-5h-.5v7h5v-7H8.6Zm.5 6v-5h3v5h-3Zm-4-4h-3v-1h4v7h-4v-1h3v-5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFunicularProfileSmall;
