import type { SVGProps } from "react";
const SvgLayersSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.282 3.087 12 2.894l-.282.193-9.5 6.5-.603.413.603.413 2.35 1.608-2.345 1.563-.616.41.61.419 9.5 6.5.283.193.282-.193 9.5-6.5.611-.418-.616-.411-2.345-1.563 2.35-1.608.604-.413-.604-.413-9.5-6.5Zm6.26 9.542-6.26 4.284-.282.193-.282-.193-6.26-4.284-2.065 1.376L12 19.895l8.607-5.89-2.064-1.376ZM12 15.894 3.385 10 12 4.106 20.615 10 12 15.894Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLayersSmall;
