import type { SVGProps } from "react";
const SvgMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.077 4.909 9.491 6.52c-.723 1.988-.055 4.474 1.54 6.245 1.576 1.752 3.999 2.737 6.73 1.811l1.997-.677-1.48 1.502c-1.86 1.884-3.865 2.642-5.732 2.572-1.857-.07-3.516-.957-4.694-2.249-1.178-1.29-1.897-3.01-1.85-4.78.048-1.784.876-3.579 2.714-4.991l1.361-1.045Zm6.017 11.011c-2.349.14-4.395-.916-5.807-2.485-1.391-1.546-2.204-3.633-2.074-5.622-.818.994-1.182 2.09-1.21 3.159-.04 1.483.565 2.958 1.587 4.08 1.022 1.12 2.438 1.864 3.994 1.922 1.1.042 2.3-.259 3.51-1.054Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMoonSmall;
