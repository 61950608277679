import type { SVGProps } from "react";
const SvgEv99 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-99_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.145 8.688h2.19c.18.9.701 1.349 1.563 1.349.487 0 .881-.164 1.183-.492.306-.333.542-.849.706-1.547.106-.444.182-.95.23-1.516-.354.498-.72.852-1.095 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.553 3.553 0 0 1-1.031-1.357 4.459 4.459 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.768-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.296 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.586-.865-.678-.577-1.069-1.351-1.175-2.325Zm3.857-7.006c-.498 0-.918.204-1.262.61-.344.413-.516.937-.516 1.572 0 .513.125.968.373 1.365.339.545.815.817 1.428.817.556 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.16-1.124-.484-1.547a1.61 1.61 0 0 0-1.34-.66Zm4.945 7.006h2.19c.18.9.7 1.349 1.563 1.349.487 0 .88-.164 1.182-.492.307-.333.542-.849.706-1.547.106-.444.183-.95.23-1.516-.354.498-.719.852-1.094 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.552 3.552 0 0 1-1.031-1.357 4.457 4.457 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.767-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.297 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.587-.865-.677-.577-1.068-1.351-1.174-2.325Zm3.856-7.006c-.497 0-.918.204-1.261.61-.344.413-.516.937-.516 1.572 0 .513.124.968.373 1.365.338.545.814.817 1.428.817.555 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.161-1.124-.484-1.547a1.611 1.611 0 0 0-1.34-.66Z"
    />
  </svg>
);
export default SvgEv99;
