import type { SVGProps } from "react";
const SvgIr94Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-94-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-94-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm10.446.53h2.183c.18.896.699 1.344 1.558 1.344.486 0 .878-.163 1.179-.49.306-.332.54-.847.704-1.543.105-.443.182-.946.23-1.51-.354.495-.718.849-1.092 1.06-.37.205-.82.308-1.353.308-.849 0-1.587-.248-2.215-.744a3.543 3.543 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.446-2.357 1.337-3.164.765-.696 1.682-1.044 2.753-1.044.659 0 1.268.148 1.827.443a3.961 3.961 0 0 1 1.408 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.296 2.99-.886 4.129-.77 1.482-1.936 2.222-3.497 2.222-1.039 0-1.898-.287-2.578-.862-.675-.575-1.066-1.347-1.171-2.317Zm3.844-6.985c-.495 0-.915.203-1.258.609-.342.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.338.544.812.815 1.424.815.554 0 .997-.221 1.329-.664.311-.417.467-.912.467-1.487 0-.607-.161-1.12-.483-1.543a1.606 1.606 0 0 0-1.337-.656Zm13.408 5.292v1.954h-1.456v2.642H50.08v-2.643h-4.825V10.99l4.944-6.755h2.136v6.969h1.455Zm-3.662.024V6.922l-2.975 4.303h2.975Z"
      />
    </g>
    <defs>
      <clipPath id="ir-94-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr94Negative;
