import type { SVGProps } from "react";
const SvgSaB = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.05 9.102v4.5h-3.5V15h8.399v-1.399L11.45 13.6V9.102c.931-.122 1.793-.465 2.344-1.01.551-.546.89-1.11 1.003-1.698.096-.586.153-1.01.153-1.272V1h-8.4v4.122c0 .302.056.726.17 1.293.095.566.438 1.13.986 1.676.55.546 1.416.889 2.344 1.01Zm-7.615 3.833c.23.41.52.881.87 1.414v.002h1.271a23.746 23.746 0 0 1-.908-1.633 29.356 29.356 0 0 1-.77-1.824c-.334-.839-.5-1.782-.5-2.82 0-1.016.152-1.915.453-2.695.303-.78.563-1.394.78-1.84.218-.449.534-1.036.945-1.76H3.304c-.75 1.173-1.32 2.232-1.713 3.175C1.199 5.896 1 6.932 1 8.054c0 1.21.182 2.223.544 3.04.363.816.66 1.43.89 1.84Zm17.651-8.11c-.422-.955-.98-1.97-1.668-3.047v.004h-1.27c.413.702.71 1.243.9 1.623.185.38.389.841.606 1.38.216.537.38 1.08.49 1.623a8.25 8.25 0 0 1 .164 1.632c0 1.016-.156 1.929-.463 2.738-.31.811-.553 1.397-.735 1.76a74 74 0 0 1-.961 1.814h1.269a30.96 30.96 0 0 0 .98-1.597c.193-.352.463-.934.808-1.752.344-.816.518-1.799.518-2.948 0-1.197-.214-2.274-.638-3.23Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaB;
