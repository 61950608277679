import type { SVGProps } from "react";
const SvgMountainRockTransmitterSignalLarge = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.809 4.107 2 3.472V46h33.097l-.72-.828-9.627-11.07 13.027-8.686-.554-.832-12.945 8.63-2.3-7.363-.068-.214-.205-.093-9.758-4.39-1.954-11.24-.033-.188-.151-.119-7-5.5ZM3 45V5.53l6.04 4.745 1.967 11.311.046.262.242.108 9.795 4.408 2.433 7.786.031.1.069.078L32.903 45H3Zm9.434-41.248 4 7-.868.496-4-7 .868-.496Zm8 7-4-7-.868.496 4 7 .868-.496Zm-3.193 6.315c.268.476.8.931 1.735 1.185l.009.002.009.003c1.08.337 1.906.29 2.468.066.555-.22.88-.619.993-1.073.22-.892-.366-2.325-2.56-3.127-1.397-.436-2.473.363-2.797 1.394a1.943 1.943 0 0 0 .143 1.55Zm-1.097-1.85c.47-1.496 2.07-2.674 4.062-2.044l.01.003.01.004c2.468.897 3.592 2.726 3.2 4.31-.195.787-.758 1.43-1.595 1.763-.827.328-1.893.344-3.126-.039-1.136-.31-1.912-.906-2.335-1.655a2.943 2.943 0 0 1-.226-2.342ZM37.5 21c-1.75 0-3.3.86-4.386 2.183l.772.635C34.814 22.688 36.094 22 37.5 22c1.406 0 2.686.688 3.614 1.818l.772-.635C40.8 21.86 39.25 21 37.5 21Zm-6.386.683c1.59-1.936 3.847-3.183 6.386-3.183 2.54 0 4.796 1.247 6.386 3.183l-.772.635c-1.432-1.743-3.42-2.818-5.614-2.818-2.195 0-4.182 1.075-5.614 2.818l-.772-.635ZM37.5 16c-3.328 0-6.292 1.634-8.386 4.183l.772.635C31.821 18.462 34.516 17 37.5 17s5.679 1.462 7.614 3.818l.772-.635C43.792 17.633 40.828 16 37.5 16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMountainRockTransmitterSignalLarge;
