import type { SVGProps } from "react";
const SvgWeightWheelLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.5 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm4 3a3.99 3.99 0 0 1-1.354 3H36.88l.102.366 5 18 .176.634H4.842l.176-.634 5-18L10.12 9h10.734A4 4 0 1 1 27.5 6Zm-16.62 4h25.24l4.722 17H6.158l4.722-17Zm22.404 8.2v5.646h-.9l-.211-.811a9.095 9.095 0 0 0-.153-.527c-.854 1.058-2 1.587-3.438 1.587-1.712 0-3.035-.617-3.972-1.85-.755-1-1.132-2.214-1.132-3.643 0-1.672.484-3.055 1.455-4.146.925-1.034 2.175-1.55 3.744-1.55 1.453 0 2.596.428 3.43 1.286a3.66 3.66 0 0 1 1.06 2.246h-1.39a2.49 2.49 0 0 0-1.004-1.693c-.569-.427-1.267-.64-2.096-.64-1.21 0-2.157.434-2.845 1.302-.624.79-.936 1.838-.936 3.144 0 1.2.285 2.182.856 2.948.688.931 1.66 1.396 2.918 1.396.97 0 1.77-.307 2.398-.92.615-.605.932-1.483.952-2.634h-3.423v-1.14h4.687Zm-9.872-5.046-4.52 4.293 4.688 6.4h-1.792l-3.89-5.434-1.77 1.681v3.752h-1.412V13.154h1.412v5.302l5.426-5.302h1.858ZM5.002 29.04C5.79 38.536 13.764 46 23.5 46S41.21 38.536 42 29.04l-.997-.082c-.746 8.979-8.289 16.04-17.502 16.04S6.744 37.939 6 28.96l-.997.082ZM23.5 42.001c-7.619 0-13.879-5.692-14.498-12.958l.997-.085C10.572 35.687 16.38 41 23.5 41c7.12 0 12.928-5.313 13.502-12.043l.996.085C37.379 36.31 31.12 42 23.5 42Zm-3.165-12.722C21.026 30.309 22.18 31 23.5 31s2.474-.69 3.166-1.721l-.831-.558C25.312 29.5 24.459 30 23.5 30c-.959 0-1.812-.5-2.334-1.279l-.831.558Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWeightWheelLarge;
