import type { SVGProps } from "react";
const SvgHighlighterSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.551 3.844.302.302 3 3 .302.302-.25.346-8 11-.345.473-.414-.413-.316-.317-.102.017-.846.145c-.545.092-1.165.196-1.59.262a144.122 144.122 0 0 0-.762 1.153l-.08.12-.02.033-.006.008-.001.002v.001l-.42-.274.42.274-.338.514-.435-.434-.211-.212-1.16.773-.127.084H2.56l.52-.782 1.774-2.66-.23-.23-.437-.437.518-.336.272.419-.272-.42.003-.002.01-.006.036-.023.132-.086a240.924 240.924 0 0 1 1.155-.742 184.354 184.354 0 0 1 .402-2.408l.02-.12-.317-.316-.413-.414.473-.345 11-8 .345-.25ZM7.331 14.037l-.05.293c-.113.668-.237 1.413-.287 1.744l-.034.229-.196.122c-.198.122-.575.364-.906.577l-.09.059 2.155 2.155.035-.052c.206-.313.457-.692.628-.944l.123-.183.218-.032a103.612 103.612 0 0 0 2.037-.334L7.33 14.038Zm-1.75 4.25-1.14 1.71h1.406l.866-.577-1.133-1.133Zm1.686-5.727 5.173 5.173L19.844 7.55l-2.396-2.395-10.18 7.404Zm4.086 1.793 6-6-.707-.707-6 6 .707.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHighlighterSmall;
