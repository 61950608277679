import type { SVGProps } from "react";
const SvgCopySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#1D1D1B"
      fillRule="evenodd"
      d="M9.485 3.96h-.5V7H5v13h10v-3.04h3.985v-13h-9.5Zm5.515 12h2.985v-11h-8V7H15v8.96ZM6 19V8h8v11H6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCopySmall;
