import type { SVGProps } from "react";
const SvgSuitcaseDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.75 4.764h8.5v4.5h9V30h-1V10.263H14.248l-3.75-.013.004-1 3.248.011V4.764Zm7.5 1v3.5h-6.5v-3.5h6.5Zm-16.16 3.5h-.34V29.75h20.84l3.079 3.078.707-.707L3.868 6.63l-.706.707 1.927 1.927Zm1 1h-.34V28.75h18.838L6.09 10.264Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSuitcaseDisabledMedium;
