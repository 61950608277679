import type { SVGProps } from "react";
const SvgTunnelFreightWagonShiftedLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 22.75C4 11.479 12.73 3 23.5 3S43 11.479 43 22.75V46h1V22.75C44 10.927 34.822 2 23.5 2S3 10.927 3 22.75V46h1V22.75ZM33.617 45H17.383l2.4-4h11.434l2.4 4Zm1.166 0H38v1H13v-1h3.217l2.4-4H14v-5H9V15h23v20h5v6h-4.617l2.4 4ZM15 36h21v4H15v-4Zm-5-20v19h6V16h-6Zm7 0h7v19h-7V16Zm14 0h-6v19h6V16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTunnelFreightWagonShiftedLarge;
