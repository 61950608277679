import type { SVGProps } from "react";
const SvgUserPlusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 9c0-1.732 1.189-3 2.5-3s2.5 1.268 2.5 3-1.189 3-2.5 3-2.5-1.268-2.5-3ZM11 5C8.997 5 7.5 6.866 7.5 9c0 .93.284 1.808.77 2.503C5.473 12.903 4 16.367 4 19.5v.5h10v-1H5.013c.153-2.864 1.606-5.71 3.97-6.726A3.179 3.179 0 0 0 11 13c.764 0 1.455-.271 2.017-.726 1.586.671 2.862 2.142 3.514 3.9l.938-.348c-.687-1.85-2.011-3.476-3.737-4.325a4.386 4.386 0 0 0 .768-2.5c0-2.135-1.497-4-3.5-4Zm7 12v2h-2v1h2v2h1v-2h2v-1h-2v-2h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserPlusSmall;
