import type { SVGProps } from "react";
const SvgConstruction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className="construction_svg__color-immutable"
    {...props}
  >
    <rect width={16} height={16} fill="#EB0000" rx={2} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.664 4.104c0 .609-.48 1.102-1.07 1.102-.591 0-1.07-.493-1.07-1.102 0-.61.479-1.104 1.07-1.104.59 0 1.07.495 1.07 1.104Zm-1.749 8.775s.263-.188.42-.295c.155-.109.598-.298.598-.298l.21-.305-3.139-2.64.761.928-1.898 3.618a.623.623 0 0 1-.55.338.6.6 0 0 1-.3-.078.658.658 0 0 1-.252-.877l1.493-2.843-1.6-1.949-.123 2.327-.804 3.077a.63.63 0 0 1-.77.455.648.648 0 0 1-.44-.792l.76-2.898-.057-2.985.768-.856-1.22-1.026a.33.33 0 0 1-.047-.454.309.309 0 0 1 .443-.046l.628.528.425-2.243h3.234l1.538 2.289-1.522 3.045 3.153 2.652a3.78 3.78 0 0 1 .196-.098l.01-.005c.23-.107.46-.213.565-.347.102-.134.364-.16.364-.16l.417.081.338.485.341.43.625-.027s.262.161.34.216c.06.04.043.226.032.346-.003.035-.006.065-.006.084 0 .082.235.485.235.485l.312.349.468-.027.29.297.364.377h-8.06l.81-.62h.338l.182-.215.13-.323ZM5.583 8.152l-.648-.544 1.208-.543-.56 1.087ZM3.978 4.75l.78.107-1.014 1.077.234-1.184Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgConstruction;
