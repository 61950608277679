import type { SVGProps } from "react";
const SvgSpeechBubbleMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.75 9.25h-.5v17.5h4.5v4.082l.854-.853 3.228-3.229H29.75V9.25h-23Zm.5 16.5v-15.5h21.5v15.5H14.418l-.147.146-2.521 2.522V25.75h-4.5Zm3.25-11.031h15.032v-1H10.5v1Zm15 3.125H10.469v-1H25.5v1Zm-15.031 3h11.968v-1H10.468v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpeechBubbleMedium;
