import type { SVGProps } from "react";
const SvgTravelBackpackSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.501 3A.501.501 0 0 0 5 3.501v3c0 .276.223.499.499.499H8V3H5.501ZM9 3v4h6V3H9Zm7 0v4h2.5a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5H16ZM4 3.501C4 2.672 4.672 2 5.501 2H18.5A1.5 1.5 0 0 1 20 3.5v3A1.5 1.5 0 0 1 18.5 8H18v7h.087a3.29 3.29 0 0 1 1.797.59c.63.453 1.116 1.198 1.116 2.31v3a1.1 1.1 0 0 1-.325.78l-.352-.356.352.355a1.11 1.11 0 0 1-.78.321H4.105c-.291 0-.572-.115-.78-.32A1.097 1.097 0 0 1 3 20.9v-3c0-.77.309-1.509.857-2.052A2.932 2.932 0 0 1 5.921 15H6V8h-.501A1.499 1.499 0 0 1 4 6.501v-3ZM7 8v1.808a6.836 6.836 0 0 0 2.19 1.583 6.792 6.792 0 0 0 2.31.59V11h1v.982a6.791 6.791 0 0 0 2.31-.591A6.836 6.836 0 0 0 17 9.808V8H7Zm10 3.185a7.827 7.827 0 0 1-4.5 1.799V14h-1v-1.016A7.79 7.79 0 0 1 7 11.185V21h10v-9.815ZM18 21h1.895a.115.115 0 0 0 .076-.03.102.102 0 0 0 .029-.07v-3c0-.777-.32-1.225-.7-1.497-.402-.29-.9-.397-1.226-.403H18v5ZM6 21v-5h-.079c-.51 0-1 .201-1.36.558A1.89 1.89 0 0 0 4 17.9v3c0 .025.01.05.03.07a.11.11 0 0 0 .075.03H6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTravelBackpackSmall;
