import type { SVGProps } from "react";
const SvgDeskMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.25 15.249h-.5v16.25h1v-12.25h24.5v12.25h1v-16.25h-26Zm25 3v-2H5.75v2h24.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDeskMedium;
