import type { SVGProps } from "react";
const SvgEyeDisabledLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m15.85 15.557 2.631 2.631a8 8 0 1 0 11.31 11.31l2.755 2.755a17.291 17.291 0 0 1-8.813 2.737c-4.274-.003-8.086-2.099-11.05-4.525-2.736-2.239-4.704-4.723-5.583-5.972 1.313-1.862 4.44-6.137 8.75-8.936Zm-.722-.722L2.646 2.354l.708-.708 43 43-.707.707-12.379-12.378a18.29 18.29 0 0 1-9.512 3.015h-.016c-4.603 0-8.638-2.253-11.69-4.75-3.057-2.502-5.18-5.292-5.974-6.47l-.193-.286.198-.281.008-.011c.491-.7 1.358-1.936 2.544-3.36L6.345 18.88l.65-.76 2.288 1.953a34.512 34.512 0 0 1 2.803-2.859l-1.973-2.266.754-.656 1.972 2.264a25.058 25.058 0 0 1 2.289-1.721Zm2.602 6.025a7.006 7.006 0 0 1 1.459-1.964l3.636 3.636a.991.991 0 0 1-.828.458h-.001a.971.971 0 0 1-.838-.468l-.536-.887-.36.97a3.99 3.99 0 0 0 6.701 4.065l2.121 2.121A7 7 0 0 1 17.73 20.86Zm5.458 2.73c.132-.1.25-.215.353-.342l2.714 2.714a2.99 2.99 0 0 1-5.233-2.225l.005.003c.298.166.635.253.976.25l-.003-.5.004.5a1.99 1.99 0 0 0 1.184-.4Zm17.578-5.582-2.227 2.005a35.977 35.977 0 0 0-2.846-2.764l-.027-.024 1.918-2.294-.768-.642-1.918 2.295a25.7 25.7 0 0 0-3.312-2.292l1.424-2.624-.88-.476-1.422 2.621a17.071 17.071 0 0 0-3.81-1.441l.592-2.923-.98-.198-.592 2.921a13.155 13.155 0 0 0-2.168-.182h-.006c-.648.008-1.292.059-1.93.152L21.272 9.2l-.983.182.541 2.94a14.495 14.495 0 0 0-3.3 1.15l.44.898a13.5 13.5 0 0 1 5.783-1.379c4.256.001 8.188 2.334 11.286 5.016 2.853 2.47 4.941 5.184 5.847 6.474a32.501 32.501 0 0 1-6.191 6.284l.61.792a33.504 33.504 0 0 0 6.599-6.771l.207-.284-.197-.292a36.498 36.498 0 0 0-2.706-3.454l2.227-2.003-.67-.744Zm-19.568-.419a7.001 7.001 0 0 1 9.193 9.212l.918.398A8 8 0 0 0 20.802 16.67l.396.918Zm3.11 3.438a2.98 2.98 0 0 1 2.645 2.646l.995-.106a3.98 3.98 0 0 0-3.534-3.534l-.107.994Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEyeDisabledLarge;
