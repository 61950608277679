import type { SVGProps } from "react";
const SvgHandLocomotiveProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 4.25H3v-1h21v1Zm4.45 3.811c-.62-.983-1.122-1.525-1.973-1.728-.389-.093-.827-.11-1.33-.108-.219.001-.458.005-.718.01l-.133.003c-.313.006-.66.011-1.046.012v.5-.5h-3.5v9h.5v1h-.5v.5h-2V6.25H3v1h13.75v11H9v1h8.75v-1.5h2v1.5h8.75v-.5.5c1.928 0 3.283-.618 3.885-1.75.594-1.117.338-2.522-.458-3.831l-.002-.004-3.472-5.6-.002-.004Zm-5.2-.811c.396 0 .75-.007 1.064-.012l.135-.003c.26-.005.49-.009.7-.01.498-.002.832.018 1.096.08.453.109.773.359 1.359 1.288v.001l3.47 5.596c.703 1.159.788 2.165.428 2.84-.353.663-1.248 1.22-3.002 1.22h-7.75v-11h2.5Zm-.5 2h2.945l.14.268 2.358 4.5.383.732H22.75v-5.5Zm1 1v3.5h3.174l-1.834-3.5h-1.34ZM6.488 20.18l5.77 4.03a3.058 3.058 0 0 1-.008-.21c0-.669.222-1.354.689-1.879.474-.534 1.175-.87 2.061-.87h3.181c2.382.08 4.663 1.36 6.422 3.041v.001l7.991 7.595.908.862H20.419l-.146-.145-1.366-1.355h-5.896l-.141-.125-8.881-7.823a2 2 0 0 1 2.499-3.123Zm11.667 2.07H15c-.614 0-1.038.226-1.314.535-.284.32-.436.76-.436 1.215 0 .456.152.896.436 1.215.276.31.7.535 1.314.535h3v1h-3c-.845 0-1.521-.307-1.994-.798L5.903 20.99l-.014-.01-.013-.01a1 1 0 1 0-1.252 1.56l.009.007.009.008 8.747 7.705h5.93l.146.145 1.366 1.355h10.167l-7.085-6.733v-.001c-1.65-1.576-3.706-2.694-5.758-2.766Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandLocomotiveProfileMedium;
