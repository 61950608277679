import type { SVGProps } from "react";
const SvgIr36 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-36_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M36.608 7.898a4.344 4.344 0 0 1 .702-2.39c.715-1.033 1.79-1.55 3.224-1.55 1.104 0 1.995.283 2.673.847a2.953 2.953 0 0 1 1.096 2.373 2.593 2.593 0 0 1-.43 1.499 2.107 2.107 0 0 1-1.163.85c.59.123 1.114.461 1.47.95.37.518.559 1.143.54 1.78a3.545 3.545 0 0 1-1.261 2.807c-.778.675-1.76 1.013-2.948 1.013-1.288 0-2.309-.372-3.063-1.116-.754-.743-1.131-1.748-1.131-3.013v-.15h2.215c0 .711.168 1.261.504 1.649.364.4.889.613 1.427.581.615 0 1.088-.182 1.42-.546.301-.335.465-.774.457-1.226a1.686 1.686 0 0 0-.584-1.352c-.32-.275-.814-.412-1.482-.412-.187 0-.374.01-.56.032v-1.7c.137.01.268.015.395.015 1.324 0 1.986-.493 1.986-1.48a1.359 1.359 0 0 0-.433-1.047 1.62 1.62 0 0 0-1.143-.4 1.574 1.574 0 0 0-1.254.506c-.294.338-.452.831-.473 1.48h-2.184Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M51.256 7.123h2.191a3.89 3.89 0 0 0-.749-1.875c-.678-.86-1.655-1.29-2.932-1.29a3.751 3.751 0 0 0-3.209 1.622c-.81 1.118-1.214 2.663-1.214 4.635 0 1.846.378 3.284 1.135 4.315.757 1.031 1.816 1.547 3.177 1.547a3.84 3.84 0 0 0 2.728-1.068c.841-.791 1.261-1.838 1.261-3.14a4.05 4.05 0 0 0-.938-2.737 3.26 3.26 0 0 0-2.61-1.155 3.009 3.009 0 0 0-1.414.308c-.44.25-.82.596-1.108 1.013.05-.657.169-1.307.355-1.938.357-1.05.962-1.574 1.813-1.574.804 0 1.309.445 1.514 1.337Zm-3.028 3.345a1.53 1.53 0 0 1 1.317-.696 1.64 1.64 0 0 1 1.411.736c.291.425.44.932.426 1.448a2.428 2.428 0 0 1-.473 1.495 1.586 1.586 0 0 1-1.332.672 1.546 1.546 0 0 1-1.34-.704 2.488 2.488 0 0 1-.45-1.48 2.46 2.46 0 0 1 .441-1.47Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M9.22 4.2h4.056L8.258 15.8H4.185L9.22 4.2Zm16.76 8.166a.925.925 0 0 0 .847-.553L30.119 4.2H15.984l-5.018 11.6h4.038l3.8-8.69h5.993l-1.201 2.775-5.226-.019 4.216 5.934h4.598l-3.124-3.434h1.92Z"
    />
  </svg>
);
export default SvgIr36;
