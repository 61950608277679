import type { SVGProps } from "react";
const SvgTwoAdultsKidMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.25 7.25a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm21.5 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-19-2h-.377l-.104.363-3 10.5-.182.637h10.326l-.182-.637-3-10.5-.104-.363H6.75Zm-2.337 10.5 2.714-9.5h2.246l2.714 9.5H4.413ZM18.75 7.25a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-1 4h-.5v8.5h1.5v9h4v-9h1.5v-8.5h-6.5Zm.5 7.5v-6.5h5v6.5h-1.5v9h-2v-9h-1.5Zm8-1.5h7v7h-1.5v4.5h-4v-4.5h-1.5v-7Zm1 1v5h1.5v4.5h2v-4.5h1.5v-5h-5ZM6.25 24v5.75h4V24h-1v4.75h-2V24h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoAdultsKidMedium;
