import type { SVGProps } from "react";
const SvgSaDz = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.413 12.33v.115c0 .704-.265 1.306-.797 1.806-.534.5-1.174.749-1.926.749-.74 0-1.37-.248-1.896-.74-.525-.493-.788-1.087-.788-1.776v-.154h-1.964v.115c0 .704-.265 1.306-.802 1.806S15.057 15 14.3 15c-.744 0-1.382-.248-1.91-.74-.53-.492-.793-1.085-.793-1.774v-.156H9.633v.115c0 .704-.265 1.306-.796 1.806-.532.5-1.175.749-1.926.749a2.705 2.705 0 0 1-1.886-.73c-.532-.486-.797-1.081-.797-1.784l-.02-.156H1V9.013h5.055V5.422H1.017V3.026H9.66v3.42h10.39V1h2.598v5.448h4.097l1.964 5.882h-4.296Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaDz;
