import type { SVGProps } from "react";
const SvgRe23Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="re-23-negative_svg__color-immutable"
    {...props}
  >
    <g fill="#fff" clipPath="url(#re-23-negative_svg__a)">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.982 1.982 0 0 1-.197-.625c-.037-.253-.087-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.708-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.17-.949-.514-1.202-.342-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm9.382 9.508v2.056h-8.33c0-.627.1-1.226.3-1.795.207-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.941-.744 1.163-1.076.221-.332.332-.717.332-1.155 0-.506-.15-.915-.451-1.226-.295-.316-.686-.474-1.17-.474-1.192 0-1.807.857-1.844 2.57h-2.223V8.34c0-1.402.393-2.489 1.18-3.259.764-.749 1.752-1.123 2.965-1.123 1.076 0 1.975.306 2.698.918.785.664 1.178 1.558 1.178 2.681 0 .981-.313 1.825-.941 2.531a4.72 4.72 0 0 1-.577.562c-.18.137-.652.472-1.416 1.005-.728.506-1.25.893-1.567 1.162a6.342 6.342 0 0 0-.87.926h5.427Zm.933-5.846c.026-.949.261-1.745.704-2.389.717-1.033 1.796-1.55 3.235-1.55 1.108 0 2.002.282 2.682.846.733.612 1.1 1.403 1.1 2.373 0 .58-.146 1.082-.436 1.503a2.1 2.1 0 0 1-1.162.847c.622.142 1.112.459 1.47.949.365.49.547 1.084.547 1.78 0 1.144-.422 2.08-1.266 2.808-.78.675-1.767 1.012-2.958 1.012-1.292 0-2.318-.371-3.077-1.115-.755-.744-1.132-1.748-1.132-3.014v-.15h2.223c0 .712.169 1.263.506 1.653.338.385.815.578 1.432.578s1.092-.182 1.424-.546a1.78 1.78 0 0 0 .459-1.226c0-.57-.195-1.02-.586-1.353-.321-.274-.817-.411-1.487-.411-.19 0-.377.01-.561.032V8.822c.137.01.269.016.395.016 1.33 0 1.994-.493 1.994-1.48 0-.432-.145-.78-.436-1.044-.29-.269-.672-.403-1.146-.403-.544 0-.963.169-1.258.506-.295.338-.454.83-.475 1.48h-2.19Z" />
    </g>
    <defs>
      <clipPath id="re-23-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe23Negative;
