import type { SVGProps } from "react";
const SvgSwitzerlandLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M27 11.5 25 14l-7.5 2.5-1.5-1-4.5 3.5 2 .5-6 6.5-3 2.5-1 5.5L8 33l.5-2 3-.5 1 1.5v1.5l3 4 4-1.5 4 .5L26 30l5.5 7.5 3-8 2 2L39 31l2.5 1.5L41 28h3.5l-.5-6-3 2-1.5-2-3.5-1 1.5-4-3.5-3.5-4 .5-3-2.5Z"
    />
  </svg>
);
export default SvgSwitzerlandLarge;
