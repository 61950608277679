import type { SVGProps } from "react";
const SvgTaxiProfileLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 9h9v3h1.686a1.5 1.5 0 0 1 .857.269l11.15 7.756 7.218 1.203A2.5 2.5 0 0 1 46 23.694V32h-7.028a4.5 4.5 0 0 1-8.945 0H14.973a4.5 4.5 0 0 1-8.944 0H2V21.121c0-.506.153-1 .44-1.416l4.85-7.055A1.5 1.5 0 0 1 8.526 12H14V9Zm1 3h7v-2h-7v2ZM6.027 31a4.5 4.5 0 0 1 8.945 0h15.056a4.5 4.5 0 0 1 8.945 0H45v-6h-1.513l-.013-.001c-.79-.041-1.2-.736-1.436-1.31l-.002-.004-.753-1.881L36.456 21H3.005a1.61 1.61 0 0 0-.005.121V22h.5A1.5 1.5 0 0 1 5 23.5v1A1.5 1.5 0 0 1 3.5 26H3v5h3.027ZM3 25h.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H3v2Zm.45-5H16v-7H8.526a.5.5 0 0 0-.412.217L3.451 20ZM17 13v7h17.905l-9.933-6.91a.5.5 0 0 0-.286-.09H17Zm25.437 8.996.526 1.315c.107.259.214.44.319.553.091.098.165.13.234.135H45v-.305a1.5 1.5 0 0 0-1.253-1.48l-1.31-.218ZM10.5 28a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm0 2a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3ZM8 31.5a2.5 2.5 0 1 1 5.002.001A2.5 2.5 0 0 1 8 31.499ZM34.5 28a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm0 2a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3ZM32 31.5a2.5 2.5 0 1 1 5.002.001A2.5 2.5 0 0 1 32 31.5Zm-10.37-7.555.52 1.506h-1.043l.522-1.506Zm1.937 3.043-1.487-4.006h-.885l-1.492 4.006h.882l.318-.896h1.455l.312.896h.897Zm-3.882-3.26v-.738h-3.23v.738h1.186v3.26h.855v-3.26h1.189Zm8.061-.738-1.259 1.906 1.374 2.092h-1.028l-.858-1.389-.874 1.389h-.977l1.382-2.092-1.276-1.906h1.003l.777 1.283.788-1.283h.948Zm1.72 3.998h-.855V22.99h.855v3.998Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTaxiProfileLarge;
