import type { SVGProps } from "react";
const SvgSwitzerlandRouteSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.876 5.353-.4-.353-.326.421-.91 1.175-3.41.93-.564-.305-.28-.152-.256.19-2.44 1.813-.762.566.898.309.387.133-2.396 2.657-.08.088-.031.115-1.08 3.954-.226.828.831-.212 2.14-.544.068-.017.06-.035.62-.36 1.965 1.88.17.162.232-.026 3.157-.354.185-.021.126-.137 1.405-1.52 1.795 2.483.496.686.361-.766 1.397-2.963 3.263.853.707.184-.084-.726-.166-1.43.967.024.539.013-.026-.537-.158-3.27-.012-.232-.184-.14L20.51 9.69l-.47-.359-.276.524-.244.462-.888-.268.5-1.487.151-.453-.445-.172-3.634-1.411-1.329-1.173ZM6.854 9.38l-.206-.07 1.423-1.058.46.249.176.095.193-.053 3.764-1.026.161-.044.102-.132.687-.886.996.88.067.059.083.032 3.272 1.27-.51 1.524-.165.489.494.148 1.776.536.394.12.192-.365.16-.304.689.526.12 2.5-1.004-.026-.576-.015.067.572.147 1.277-2.956-.772-.402-.105-.177.376-1.213 2.572-1.657-2.292-.357-.495-.415.449-1.693 1.832-2.74.307-2.067-1.98-.272-.26-.325.19-.885.514-1.24.316.822-3.012 2.823-3.13.507-.562-.715-.246Zm2.356 4.37c.09 0 .176.012.26.034l3.776-3.777a1.002 1.002 0 0 1 .964-1.267 1 1 0 1 1-.251 1.968l-3.783 3.783a1 1 0 1 1-.966-.741Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSwitzerlandRouteSmall;
