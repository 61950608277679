import type { SVGProps } from "react";
const SvgPlatformMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#platform-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33 4.25h-1.75v23H33v1H16.25V33h-1v-4.75H13.5v-1h16.75v-23h-5v2h1.5v5.5h-11.5v-5.5h1.5v-2H13.5v-1H33v1Zm-15.25 0v2h6.5v-2h-6.5ZM7.25 24a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-4.5-3h7.75v-5.5c0-.898-.333-1.492-.86-1.879-.551-.403-1.37-.621-2.39-.621H3v-1h1.076l.584-3.5H3v-1h4.5v1H5.674l-.584 3.5H7.5c1.14 0 2.196.24 2.98.815.808.592 1.27 1.498 1.27 2.685v14H9.008l.857 2H12v1h-1.706l.666 1.553-.92.394-.834-1.947H3v-1h5.777l-.857-2H3v-1h7.75v-6.5H3v-1Zm13.25-9v-3.5h9.5v3.5h-9.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="platform-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPlatformMedium;
