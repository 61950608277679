import type { SVGProps } from "react";
const SvgSaOb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.861 12.867c-2.021 0-2.941-1.57-2.941-3.336 0-2.733 1.681-6.398 4.941-6.398 2.02 0 2.981 1.512 2.981 3.316 0 2.695-1.78 6.418-4.981 6.418Zm5.561-.136a8.87 8.87 0 0 0 2.341-5.992C14.763 3.133 12.722 1 8.941 1 6.661 1 4.72 1.834 3.2 3.52A8.867 8.867 0 0 0 1 9.377C1 12.595 3.06 15 6.501 15c2.34 0 4.461-.698 5.921-2.27Zm8.7-6.03H20.02l.74-3.336h.98c1.06 0 2.342.058 2.342 1.357 0 1.591-1.541 1.979-2.961 1.979ZM19.7 12.634h-.96l.84-3.916h.9c1.5 0 2.86.212 2.86 1.628 0 2.094-1.92 2.288-3.64 2.288Zm2.96-11.401h-4.14L15.6 14.767h3.982c2.08 0 6.56-.31 6.56-4.188 0-1.455-.68-2.618-2.24-2.85v-.04c1.62-.252 2.98-1.57 2.98-3.218 0-2.463-2.04-3.238-4.22-3.238Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaOb;
