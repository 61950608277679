import type { SVGProps } from "react";
const SvgBrochureLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={38}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.658.22 0 0v31.054l.342.114 18 6 .158.053.158-.053 18-6 .342-.114V0l-.658.22L18.5 6.166.658.219ZM1 30.332V1.387l17 5.667V36L1 30.333ZM19 36V7.054l17-5.667v28.946L19 36ZM3.342 8.168l12 4 .316-.949-12-4-.316.949Zm18.316 4 12-4-.316-.949-12 4 .316.95Zm-6.316 6-12-4 .316-.949 12 4-.316.95Zm-12 2 12 4 .316-.949-12-4-.316.95Zm12 10-12-4 .316-.949 12 4-.316.95ZM34 13.694V13l-.658.22-12 4-.342.113v13.054l.658-.219 12-4 .342-.114v-12.36ZM22 29V18.054l11-3.667v10.946L22 29Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBrochureLarge;
