import type { SVGProps } from "react";
const SvgQrcodeDisabledTwoTicketsMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m31.854 31.146-27-27L4.5 4.5l-.354.354.354.353V9H6V6.707l4.5 4.5V15H12v-2.294l2.29 2.291h-.791v1.499h-3v1.5h3v-1.499H15v-.79l1.5 1.5V25.5h8.293l1.5 1.5-3.791.002v1.5h-1.5v3h1.5v-1.5h1.5v-1.505h1.5v3h3v-1.5h-1.5v-2.288l3 3v.787h.787l.357.358.708-.707ZM23.293 24l-1.505-1.504H19.5v-2.289l-1.5-1.5V24h5.293ZM4.5 11.994h3.002v-1.5H4.5v1.5ZM15 7.5h-1.5V6H15v1.5Zm1.502 0h1.5v-3h-1.5v3Zm6-.003h-3V5.994h3V4.496h3v3h-1.5v-1.5h-1.5v1.5ZM4.5 25.5H6v-3H4.5v3ZM6 16.497H4.5v-1.5H6V13.5h1.502V15H6v1.497Zm-1.5 2.999H6V18h1.502v-1.5H6v1.494H4.5v1.502ZM6 21h1.502v-1.5H6V21Zm25.502-1.5h-1.5v1.497h-3v1.5h3V21h1.5v-1.5Zm-1.5-3h1.5V18h-1.5v1.497h-3v-1.5h3V16.5Zm-7.502-3H24v1.497h1.5v-1.5H24V10.5h-1.5v3Zm9.002 12h-1.5v-3h1.5v3Zm-11.999 6.002h-1.502v-1.5h1.502v1.5Zm-9.004 0h1.5v-3.003h-1.5v3.003Zm3-6.005H15l.002 1.5h1.5v1.5H13.5V27H12v-1.5h1.5v-.003Zm4.502 3h3.002v-1.5h-3.002v1.5Zm-7.502-6h1.5V21H15v-1.5h-3v1.497h-1.5v1.5Zm1.5 3h-1.5v-1.5h1.5V22.5H15V24h-3v1.497Zm18.002-12h1.5v-3h-3v1.5h1.5v1.5Zm-13.5 18.005h-3.002v-1.5H16.5v1.5ZM27 16.5v-3l-1.5-.003v-1.5h3v3H30v1.5l-3 .003Zm-4.507 1.494H24V19.5l1.5 1.5v-4.504h-4.504l1.498 1.498ZM19.5 14.997h-.003L18 13.5v-.003h1.5V12H21v3h-1.5v-.003Zm-3-2.998v-.001h3v-1.5H15v.001l1.5 1.5Zm-6-6.003V6L9 4.5v-.003h4.5v1.5h-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeDisabledTwoTicketsMedium;
