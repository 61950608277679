import type { SVGProps } from "react";
const SvgTwoFingerTapMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.75 4.75a4.96 4.96 0 0 0-3.601 1.558c-2.473.299-4.399 2.382-4.399 4.942 0 1.015.308 1.954.823 2.74l.836-.55a3.973 3.973 0 0 1-.659-2.19c0-2.096 1.618-3.798 3.679-3.965l.201-.017.134-.151A3.969 3.969 0 0 1 18.75 5.75c2.21 0 4 1.79 4 4a3.94 3.94 0 0 1-.665 2.2l.83.557a4.937 4.937 0 0 0 .835-2.757c0-2.763-2.238-5-5-5Zm-1.002 5.002a1.001 1.001 0 1 1 2.003 0l-.001 7.367a2.029 2.029 0 0 0-.004.129h.004V19.5h1v-2.344a1.001 1.001 0 0 1 2 .09V19.5h1v-.762a1.001 1.001 0 0 1 2.005.013v.044l-.001.156-.002.772c0 .762-.002 2.074-.002 4.278 0 2.15-.33 3.79-.654 4.884a10.588 10.588 0 0 1-.504 1.366H14.536l-.075-.127-.583-.99-1.802-3.056-2.955-5.017c-.214-.365-.317-.666-.327-.909a.765.765 0 0 1 .249-.608 1.002 1.002 0 0 1 1.417 0l3.435 3.436.146.147h.709V18.75l-.003-7.499a1.003 1.003 0 0 1 2.001-.084l.001 7.582h.001v.761h1l.002-8.259c0-.04-.002-.08-.004-.119v-1.38Zm3.003 0-.001 5.76a2.002 2.002 0 0 1 2.988 1.514 2.001 2.001 0 0 1 3.017 1.726v.047l-.001.155-.002.77c0 .761-.002 2.073-.002 4.277 0 2.254-.345 3.99-.695 5.17a11.78 11.78 0 0 1-.486 1.345 6.953 6.953 0 0 1-.214.446l-.015.026-.004.008-.002.002V31l-.434-.248.433.25-.144.25H13.964l-.145-.246.431-.254-.43.254-.002-.002-.002-.005-.011-.018-.043-.073-.162-.275-.584-.99-1.801-3.056a7183.506 7183.506 0 0 1-2.957-5.019c-.254-.433-.444-.9-.463-1.373a1.76 1.76 0 0 1 .541-1.357 2.002 2.002 0 0 1 2.831 0l2.583 2.584v-2.67l-.003-7.499a2.002 2.002 0 0 1 3.014-1.728 2.001 2.001 0 0 1 3.99.228Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoFingerTapMedium;
