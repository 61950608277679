import type { SVGProps } from "react";
const SvgArrowsLeftRightMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.331 15.25 4.398-4.397-.708-.707-5.25 5.25-.353.353.353.354 5.25 5.25.708-.707-4.396-4.396 17.164.002v-1L5.331 15.25Zm25.336 4.5-4.396-4.396.708-.707 5.25 5.25.353.353-.353.354-5.25 5.25-.707-.707 4.397-4.397-17.169-.002v-1l17.167.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowsLeftRightMedium;
