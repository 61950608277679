import type { SVGProps } from "react";
const SvgTravelBackpackLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31 1.998h4V3h6.5A1.5 1.5 0 0 1 43 4.5v9a1.5 1.5 0 0 1-1.5 1.5H37v16h.798A6.202 6.202 0 0 1 44 37.202v6.873A1.926 1.926 0 0 1 42.075 46H5.925A1.926 1.926 0 0 1 4 44.075v-6.873A6.202 6.202 0 0 1 10.202 31H11V15H6.5A1.5 1.5 0 0 1 5 13.5v-9A1.5 1.5 0 0 1 6.5 3H13V1.998h4V3h14V1.998ZM36 15h-1v.998h-4V15H17v.998h-4V15h-1v3.663a16.166 16.166 0 0 0 10 5.212V23h.5v-2h3v2h.5v.877a16.175 16.175 0 0 0 10-5.201V15Zm-11.5 8h-1v-1h1v1ZM36 20.117a17.166 17.166 0 0 1-10 4.767V27h-.5v.5a1.5 1.5 0 0 1-3 0V27H22v-2.117a17.175 17.175 0 0 1-10-4.777V45h6v-5.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5V45h6V20.117ZM23.5 27v.5a.5.5 0 0 0 1 0V27h-1ZM35 14h6.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H35v10ZM31 4v10H17V4h14ZM13 4H6.5a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H13V4Zm19-1.002v12h2v-12h-2ZM29 45H19v-4h10v4Zm0-5H19v-.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v.5Zm8 5h5.075a.926.926 0 0 0 .925-.925v-6.873a5.2 5.2 0 0 0-.494-2.213H37V45Zm0-11.011h4.889A5.202 5.202 0 0 0 37.798 32H37v1.989ZM11 32h-.798c-1.38 0-2.703.548-3.678 1.524a5.213 5.213 0 0 0-.413.465H11V32Zm-6 5.202c0-.772.172-1.527.494-2.213H11V45H5.926A.926.926 0 0 1 5 44.075v-6.873ZM23 26v-2h2v2h-2Zm-9-11.002v-12h2v12h-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTravelBackpackLarge;
