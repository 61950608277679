import type { SVGProps } from "react";
const SvgCircleTriangleSquareLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 3A9.5 9.5 0 0 0 3 12.5a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5A9.5 9.5 0 0 0 12.5 3ZM2 12.5C2 6.7 6.7 2 12.5 2S23 6.7 23 12.5 18.3 23 12.5 23 2 18.3 2 12.5Zm24 .5h20v20H26V13Zm1 19h18V14H27v18Zm-10.07-6.255-.43-.73-.43.73-11.5 19.5-.445.754H5L28 46h.875l-.444-.754-11.5-19.501Zm-.43 1.238L27.125 45H5.875L16.5 26.982Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleTriangleSquareLarge;
