import type { SVGProps } from "react";
const SvgNewspaperMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.25 6.25h-.5v20.003a3.498 3.498 0 0 0 3.498 3.497h19.505a3.498 3.498 0 0 0 3.497-3.497V10.75h-3v-4.5h-23Zm23 19.25V11.75h2v14.503a2.498 2.498 0 0 1-2.497 2.497H8.248a2.498 2.498 0 0 1-2.498-2.497V7.25h21.5V25.5h1Zm-20-13.25h-.5v5.5h7v-5.5h-6.5Zm.5 4.5v-3.5h5v3.5h-5Zm16.75-.5h-9v-1h9v1Zm-9-3h9v-1h-9v1Zm9-3h-18v-1h18v1Zm-9 10.5h9v-1h-9v1Zm9 3h-9v-1h9v1Zm-18-3H15v-1H7.5v1Zm7.5 3H7.5v-1H15v1Zm-7.5 2v1h18v-1h-18Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNewspaperMedium;
