import type { SVGProps } from "react";
const SvgEv41Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-41-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.405 6.99v1.96h-1.46v2.65h-2.261v-2.65h-4.999v-2.174l5.118-6.776h2.142v6.99h1.46Zm-3.674.024V6.867L26.9 11.184h3.142Zm9.984-7.014v11.6H37.71V8.177h-2.73V6.495c.566-.016 1.026-.075 1.38-.175.36-.1.688-.265.984-.492.429-.328.717-.88.865-1.658h1.817Z"
    />
  </svg>
);
export default SvgEv41Negative;
