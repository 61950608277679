import type { SVGProps } from "react";
const SvgCircleQuestionMarkLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 24C3 12.402 12.402 3 24 3s21 9.402 21 21-9.402 21-21 21S3 35.598 3 24ZM24 2C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2Zm-6.13 12.33a9.609 9.609 0 0 0-1.1 4.77l1.34.02a8.73 8.73 0 0 1 .8-3.9 5.48 5.48 0 0 1 5.27-3 5.67 5.67 0 0 1 4.16 1.5 4.75 4.75 0 0 1 1.47 3.49 5.55 5.55 0 0 1-1.34 3.53 25.64 25.64 0 0 1-2.34 2.39A9.45 9.45 0 0 0 23.82 26a11 11 0 0 0-.46 3.81h1.37a12.427 12.427 0 0 1 .27-3c.124-.457.33-.888.61-1.27.444-.549.929-1.064 1.45-1.54a30.403 30.403 0 0 0 2.72-2.75 6.52 6.52 0 0 0 1.45-4.12 5.76 5.76 0 0 0-1.74-4.2A7.09 7.09 0 0 0 24.22 11a7.86 7.86 0 0 0-3.75.88 6.4 6.4 0 0 0-2.6 2.45ZM24.77 37v-3.4H23.3V37h1.47Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCircleQuestionMarkLarge;
