import type { SVGProps } from "react";
const SvgPlatformDisplayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.75 13.75h2.5V12h1v1.75h21.502V12h1v1.75h2.998v10H3.25v-10h.5Zm.5 1v8h27.5v-8H4.25Zm1.75 3h3v-1H6v1Zm24 0H10.5v-1H30v1Zm-19.5 3.001H30v-1H10.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPlatformDisplayMedium;
