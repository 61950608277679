import type { SVGProps } from "react";
const SvgCloudLittleSnowMoonMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m26.444 4.888-.525 1.038c-.974 1.928-.526 4.32.912 5.934 1.413 1.584 3.793 2.419 6.724 1.18l1.172-.495-.52 1.16c-.811 1.807-2.38 2.97-3.963 3.602-1.208.483-2.48.678-3.499.585.022.229.023.46.005.69a3.941 3.941 0 0 1-1.162 2.521c-.72.702-1.757 1.147-3.088 1.147H12c-1.374 0-2.416-.442-3.124-1.155-.702-.707-1.034-1.639-1.073-2.548-.04-.906.209-1.825.713-2.53.416-.582 1.014-1.024 1.758-1.167.007-2.803 2.942-4.36 5.134-3.134 1.329-2.368 3.803-2.954 5.8-2.23.058.021.116.043.173.067.436-1.275.948-2.199 1.558-2.874.705-.779 1.51-1.194 2.39-1.458l1.115-.333Zm3.43 11.49c-1.278.51-2.55.63-3.379.471a3.234 3.234 0 0 0-.45-.786 2.862 2.862 0 0 0-1.865-1.079c.246-1.254.065-2.374-.416-3.293a4.572 4.572 0 0 0-1.49-1.66c.421-1.278.893-2.113 1.407-2.681.283-.313.586-.553.916-.744-.549 2.073.074 4.334 1.488 5.919 1.47 1.648 3.787 2.567 6.53 1.883-.72.907-1.707 1.557-2.742 1.97Zm-9.007-5.951c-1.685-.612-3.799-.065-4.81 2.225l-.265.602-.508-.417c-1.628-1.333-4.283-.069-3.987 2.404l.067.56H10.8c-.615 0-1.116.302-1.47.798-.361.505-.559 1.198-.528 1.904.03.704.285 1.385.784 1.887.492.496 1.264.86 2.414.86h10.5c1.096 0 1.874-.36 2.39-.863.524-.51.81-1.193.863-1.882.055-.692-.128-1.355-.496-1.826-.357-.456-.91-.76-1.687-.73l-.698.029.2-.67c.378-1.275.232-2.339-.194-3.153a3.664 3.664 0 0 0-2.01-1.728ZM14.75 25.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm6 1a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-1.25.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-2 4.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudLittleSnowMoonMedium;
