import type { SVGProps } from "react";
const SvgEv5Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-5-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm7.723 0v2.063H28.36l-.5 2.61c.561-.592 1.278-.889 2.15-.889.858 0 1.603.23 2.238.69.942.694 1.413 1.746 1.413 3.159 0 1.386-.503 2.483-1.508 3.293-.783.634-1.72.952-2.809.952-1.227 0-2.214-.328-2.96-.984-.745-.661-1.14-1.553-1.182-2.674h2.277c.038.407.17.754.397 1.04.355.449.847.674 1.476.674.608 0 1.09-.22 1.444-.659.344-.428.516-.95.516-1.563 0-.666-.18-1.198-.54-1.595-.36-.402-.838-.603-1.436-.603-.73 0-1.298.328-1.706.984l-2.07-.032 1.102-6.466h6.372Z"
    />
  </svg>
);
export default SvgEv5Negative;
