import type { SVGProps } from "react";
const SvgSoccerBallLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.814 7.134A17.517 17.517 0 0 1 24.5 6.5c1.623 0 3.195.221 4.686.635L24.5 10.539l-4.686-3.405Zm-1.186.375a17.54 17.54 0 0 0-7.999 5.82l2.055 6.323 4.744 1.868L24 16.745v-5.333l-5.372-3.903Zm-8.722 6.83a17.405 17.405 0 0 0-2.89 8.9l4.677-3.4-1.787-5.5ZM6.02 24.85l.005.346.013-.01C6.65 34.85 14.681 42.5 24.5 42.5c9.816 0 17.847-7.646 18.462-17.307l.014.01.004-.33c.013-.29.02-.58.02-.873 0-10.217-8.283-18.5-18.5-18.5S6 13.783 6 24c0 .285.006.569.02.85Zm35.965-1.604a17.405 17.405 0 0 0-2.896-8.916l-1.791 5.51 4.687 3.406Zm-3.62-9.926a17.54 17.54 0 0 0-7.992-5.81L25 11.411v5.333l6.572 4.775 4.734-1.867 2.058-6.333Zm3.63 11.17-5.357-3.893-4.734 1.868-2.518 7.75 2.918 3.69h6.627a17.412 17.412 0 0 0 3.063-9.416Zm-3.806 10.414h-5.764l-1.782 5.488a17.542 17.542 0 0 0 7.546-5.488Zm-8.726 5.883 2.04-6.283-2.917-3.689h-8.17l-2.912 3.684 2.044 6.29c1.57.463 3.233.711 4.953.711 1.724 0 3.389-.25 4.962-.713Zm-11.095-.392L16.582 34.9h-5.774a17.542 17.542 0 0 0 7.56 5.496Zm-8.3-6.496a17.41 17.41 0 0 1-3.06-9.417l5.346-3.885 4.744 1.868 2.519 7.75-2.913 3.684h-6.636Zm8.02-11.622 2.45 7.538h7.926l2.45-7.538-6.413-4.659-6.412 4.659Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSoccerBallLarge;
