import type { SVGProps } from "react";
const SvgMeetingPointMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.75 6v7.25H4.5v-1h5.543L4.52 6.73l.708-.707 5.521 5.521V6h1Zm5.5 8.75c-2.235 0-4 1.766-4 4 0 2.235 1.765 4 4 4 2.235 0 4-1.765 4-4 0-2.234-1.765-4-4-4Zm-5 4c0-2.787 2.213-5 5-5s5 2.213 5 5c0 2.788-2.213 5-5 5s-5-2.212-5-5Zm10.5-6V6h1v5.543l5.521-5.521.708.707-5.522 5.522H30v1h-7.25v-.5Zm0 11.5H30v1h-5.543l5.522 5.521-.708.707-5.521-5.521V31.5h-1v-7.25Zm-18.25 0h7.25v7.25h-1v-5.543l-5.521 5.521-.708-.707 5.522-5.521H4.5v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMeetingPointMedium;
