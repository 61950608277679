import type { SVGProps } from "react";
const SvgBanknotesDollarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 9.25h25v13h-3v4.5H3.25v-14.5h4.5v-3Zm24 12h-2v-9h-21v-2h23v11Zm-27.5-8v12.5h24.5v-12.5H4.25Zm13.58 7.736c0 .549-.341.867-1.024.95V20.03c.344.106.552.183.626.228.265.156.399.399.399.729Zm-2.539-3.243c0-.509.31-.79.927-.845v1.69c-.346-.085-.586-.19-.723-.314-.136-.125-.204-.302-.204-.531Zm1.515.98v-1.811c.554.072.837.402.85.991h1.315c-.012-.622-.204-1.107-.573-1.455-.374-.348-.903-.538-1.592-.574v-.875h-.588v.875c-.445.03-.804.103-1.072.223a1.996 1.996 0 0 0-.87.711 1.85 1.85 0 0 0-.305 1.046c0 .354.072.654.22.901.145.248.374.462.688.644.246.14.693.303 1.34.484v2.054c-.698-.075-1.046-.497-1.039-1.268h-1.378c.006.783.279 1.39.814 1.821.386.308.92.48 1.602.52V24h.588v-.986c.43-.021.786-.09 1.07-.206.282-.115.541-.297.774-.546.367-.388.55-.868.55-1.441 0-.564-.178-1.01-.535-1.335-.288-.263-.779-.485-1.475-.666a9.03 9.03 0 0 1-.384-.098Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBanknotesDollarMedium;
