import type { SVGProps } from "react";
const SvgWheelchairSectorLocationSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.99 2.98a4.008 4.008 0 0 0-4.01 4.01v.017a3.7 3.7 0 0 0 .563 1.832l.005.009 3.03 4.78.422.666.423-.666 3.03-4.78.007-.012.007-.012c.307-.548.522-1.14.533-1.825V6.99a4.008 4.008 0 0 0-4.01-4.01Zm-2.594 5.337a2.7 2.7 0 0 1-.416-1.335 3.008 3.008 0 0 1 3.01-3.002A3.008 3.008 0 0 1 20 6.986c-.008.468-.151.892-.4 1.338L17 12.426l-2.604-4.109ZM15.99 6.98c0-.554.446-1 1-1 .553 0 1 .446 1 1s-.447 1-1 1c-.554 0-1-.446-1-1Zm1-2c-1.107 0-2 .894-2 2 0 1.106.893 2 2 2 1.106 0 2-.894 2-2 0-1.106-.894-2-2-2Zm-9.471.04c-.557 0-1 .444-1 1s.443 1 1 1c.556 0 1-.444 1-1s-.444-1-1-1Zm-.5 3v6h5v5h1v-6h-5v-5h-1Zm-1.527 2.484A4.52 4.52 0 0 0 3.02 14.52c0 2.476 2.023 4.5 4.5 4.5a4.527 4.527 0 0 0 4.071-2.586l-.903-.428A3.527 3.527 0 0 1 7.52 18.02a3.512 3.512 0 0 1-3.5-3.5 3.52 3.52 0 0 1 1.926-3.124l-.453-.892Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelchairSectorLocationSmall;
