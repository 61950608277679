import type { SVGProps } from "react";
const SvgTramMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.75 6.25h-.5v1.5H12A4.25 4.25 0 0 0 7.75 12v16.25h3.316l-2.482 3.722.832.555 2.852-4.278h11.464l2.852 4.278.832-.555-2.482-3.723h3.316V12A4.25 4.25 0 0 0 24 7.75h-3.25v-1.5h-5Zm4 1.5v-.5h-3.5v.5h3.5Zm1.25 3h-6v1h6v-1Zm6.25 9V12A3.25 3.25 0 0 0 24 8.75H12A3.25 3.25 0 0 0 8.75 12v7.75h18.5Zm-18.5 1h18.5v6.5H8.75v-6.5ZM21.25 24a1.251 1.251 0 0 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm1.25-.25a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5Zm-9-1a1.251 1.251 0 0 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM13.25 24c0-.138.113-.25.25-.25s.25.112.25.25a.25.25 0 0 1-.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTramMedium;
