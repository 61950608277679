import type { SVGProps } from "react";
const SvgUserGroupRowMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 8.749c.73 0 1.388.287 1.931.779l.025.023c.063.057.123.117.182.18.675.721 1.111 1.787 1.111 3.021 0 .076-.004.15-.011.227l-.013.122-.009.077-.009.088v.001c-.208 2.056-1.57 3.485-3.207 3.485-.66 0-1.26-.235-1.77-.642a3.72 3.72 0 0 0 .02-.36c0-1.2-.348-2.3-.948-3.166a4.695 4.695 0 0 0-.48-.586c.285-1.929 1.604-3.25 3.178-3.25Zm4.23 4.36c.51.408 1.11.643 1.77.643 1.776 0 3.25-1.682 3.25-4 0-2.32-1.474-4.003-3.25-4.003-1.573 0-2.893 1.321-3.178 3.25l.044.046c.865.921 1.384 2.24 1.384 3.707 0 .105-.007.211-.016.311l-.004.046ZM18 17.752c1.002 0 1.88-.376 2.575-.997 3.223 1.987 4.745 5.071 5.131 8.995h-5.535c-.78-2.788-2.33-5.102-4.926-6.752.37-.521.646-1.132.811-1.792a3.736 3.736 0 0 0 1.944.546Zm4.056-3.547c-.165.66-.44 1.27-.809 1.79 2.597 1.65 4.145 3.966 4.924 6.755h5.535c-.386-3.924-1.902-7.002-5.126-8.99a3.834 3.834 0 0 1-2.58.992 3.733 3.733 0 0 1-1.944-.547Zm5.2-1.201c.631-.886.994-2.031.994-3.252 0-2.684-1.75-5.003-4.25-5.003-2.028 0-3.554 1.541-4.056 3.546A3.734 3.734 0 0 0 18 7.75c-2.029 0-3.554 1.54-4.056 3.544A3.737 3.737 0 0 0 12 10.747c-2.498 0-4.25 2.32-4.25 5.003 0 1.21.357 2.347.978 3.23-3.476 2.21-5.404 5.77-5.478 10.261l-.008.509h17.545l-.038-.536c-.06-.852-.17-1.675-.335-2.464h6.373l-.038-.536c-.06-.852-.17-1.675-.335-2.464h6.373l-.038-.536c-.31-4.345-1.886-7.916-5.493-10.21Zm-13.349-.5c-.538-.478-1.187-.757-1.907-.757-1.774 0-3.25 1.683-3.25 4.003 0 2.319 1.475 4 3.25 4 1.61 0 2.954-1.381 3.196-3.381l.01-.104.016-.143.01-.09c.01-.1.018-.188.018-.281 0-1.03-.304-1.943-.797-2.635a3.64 3.64 0 0 0-.497-.567l-.049-.045ZM4.267 28.75c.202-3.99 2.004-7.078 5.132-9.01.7.63 1.589 1.01 2.6 1.01 1.001 0 1.88-.374 2.573-.994 3.224 1.987 4.748 5.07 5.134 8.994H4.267Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserGroupRowMedium;
