import type { SVGProps } from "react";
const SvgFigmaSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 2a3.5 3.5 0 0 0-1.734 6.541 3.466 3.466 0 0 0-1.688 2.995 3.467 3.467 0 0 0 1.752 2.993 3.762 3.762 0 0 0-1.545 1.786A3.75 3.75 0 1 0 12.5 17.75v-3.8a3.5 3.5 0 1 0 4.53-5.3A3.5 3.5 0 0 0 15.5 2h-7Zm4.05 7a3.7 3.7 0 0 0-.05.05V9h.05Zm-.05-1h3a2.5 2.5 0 0 0 0-5h-3v5Zm-1-5h-3a2.5 2.5 0 0 0 0 5h3V3ZM15 9a2.5 2.5 0 1 1-2.5 2.502v-.004A2.5 2.5 0 0 1 15 9Zm-3.5.033V14H8.594a2.535 2.535 0 0 1-1.776-.725 2.467 2.467 0 0 1-.74-1.745 2.466 2.466 0 0 1 .72-1.752 2.534 2.534 0 0 1 1.766-.745H11.5Zm0 5.967H8.75a2.75 2.75 0 1 0 2.75 2.75V15Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFigmaSmall;
