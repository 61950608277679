import type { SVGProps } from "react";
const SvgCertificateRibbonLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.5 4a9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 0-19ZM13 13.5C13 7.7 17.7 3 23.5 3 29.298 3 34 7.7 34 13.5c0 3.345-1.564 6.324-4 8.247v19.21l-.895-1.15-3.152-4.053-.953.953v8.25l-.895-1.15L21 39.814l-3.105 3.993-.895 1.15v-23.21a10.481 10.481 0 0 1-4-8.247Zm12 10.394a10.433 10.433 0 0 0 4-1.448v15.597l-2.605-3.35-.348-.447-.4.4-.647.647v-11.4ZM23.5 24c.168 0 .334-.004.5-.012v18.055l-2.605-3.35-.395-.507-.395.507L18 42.043V22.446c1.6.985 3.483 1.554 5.5 1.554ZM16 13.5a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0ZM23.5 5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCertificateRibbonLarge;
