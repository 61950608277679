import type { SVGProps } from "react";
const SvgLocationPinAMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 6.253a8.75 8.75 0 0 0-8.75 8.75v.02c.06 1.51.509 2.781 1.219 3.965l.006.01 7.125 11.25.422.667.423-.667L25.571 19l.007-.01.005-.01c.694-1.217 1.152-2.513 1.167-3.97v-.006A8.75 8.75 0 0 0 18 6.253ZM11.323 18.47c-.634-1.06-1.02-2.164-1.073-3.475a7.75 7.75 0 0 1 15.5.007c-.013 1.237-.398 2.362-1.03 3.472l-6.697 10.572-6.7-10.576Zm6.651-8.118 1.79 4.825h-3.587l1.797-4.825Zm4.902 9.157-4.092-10.51H17.23l-4.11 10.51h1.461l1.196-3.214h4.395l1.167 3.214h1.537Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinAMedium;
