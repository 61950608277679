import type { SVGProps } from "react";
const SvgThermometerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M11.5 16.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm0 0V8m3.5 2.5h2m-2-2h2m-2-2h2m-2 6h2m-3.5 2.633V3.5h-4v11.633A3.492 3.492 0 0 0 8 18a3.5 3.5 0 0 0 7 0 3.491 3.491 0 0 0-1.5-2.867Z"
    />
  </svg>
);
export default SvgThermometerSmall;
