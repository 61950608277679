import type { SVGProps } from "react";
const SvgHeartMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.241 14.246a7.975 7.975 0 0 1 8.01-7.996 7.938 7.938 0 0 1 6.003 2.731 7.912 7.912 0 0 1 5.996-2.73 7.966 7.966 0 0 1 8 7.995c0 1.45-.38 2.809-1.048 3.98-.235.414-.9 1.269-1.168 1.593l-.015.018-.017.017-11.398 11.4-.354.353-.353-.353L5.483 19.839l-.007-.008a8.086 8.086 0 0 1-1.267-1.751 8.006 8.006 0 0 1-.968-3.834Zm8.01-6.996a6.975 6.975 0 0 0-7.01 6.996c0 1.22.307 2.363.847 3.356a7.09 7.09 0 0 0 1.106 1.533L17.25 30.193l11.028-11.03c.28-.34.873-1.11 1.055-1.432v-.001a7.006 7.006 0 0 0 .917-3.484 7.02 7.02 0 0 0-.421-2.41v-.001A6.966 6.966 0 0 0 23.25 7.25a6.928 6.928 0 0 0-5.594 2.825l-.4.54-.402-.54A6.953 6.953 0 0 0 11.25 7.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHeartMedium;
