import type { SVGProps } from "react";
const SvgTicketIpvMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.25 3.25h-.5v29.5h22V3.25H8.25Zm.5 28.5V4.25h20v27.5h-20Zm3.25-8h13.5v-1H12v1Zm13.5 3H12v-1h13.5v1Zm-13.321-8.732v-7.496H10.5v7.496h1.679Zm1.479-7.496h3.105c.523 0 .935.031 1.234.095.3.063.571.175.814.336.669.437 1.004 1.097 1.004 1.979 0 .783-.277 1.398-.832 1.845a2.198 2.198 0 0 1-.804.413c-.302.084-.674.126-1.116.126h-1.726v2.702h-1.68v-7.496Zm1.679 1.384v2.123h1.5c.446 0 .767-.077.963-.23.242-.192.363-.474.363-.847 0-.376-.14-.656-.421-.84-.204-.138-.535-.206-.995-.206h-1.41Zm9.105 6.112L27 10.522h-1.743L23.5 16.136l-1.73-5.614h-1.76l2.52 7.496h1.911Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketIpvMedium;
