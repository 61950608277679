import type { SVGProps } from "react";
const SvgSaWs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 5V1h1v4c0 1.11-.74 2.05-1.75 2.37V15h-1.5V7.37A2.488 2.488 0 0 1 1 5V1h1v4h1V1h1v4h1Zm3 5V4c0-1.66 1.34-3 3-3v14H9.5v-5H8Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaWs;
