import type { SVGProps } from "react";
const SvgStairsUserMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.25 5.25c0-.523.477-1 1-1s1 .477 1 1-.477 1-1 1-1-.477-1-1Zm1-2c-1.076 0-2 .924-2 2s.924 2 2 2 2-.924 2-2-.924-2-2-2Zm-1.707 6h3.516l.138.276 1.48 2.96L19.9 15.45l-.8.6-2.25-3-.027-.036-.02-.04-.553-1.106v5.23l1.416 2.125.084.126v3.401h3.5v-4.5h6v-4.5H33v1h-4.75v4.5h-6v4.5h-6v4.5h-6v4.5H3v-1h6.25v-4.5h1.61l1.39-5.562V10.957l-2 2V16.5h-1v-3.957l.146-.147 3-3 .147-.146Zm.707 7.5v-6.5h2v6.5h-2Zm0 1v4.062l-.015.06-1.345 5.378h3.36v-4.5h1.5v-3.099l-1.268-1.901H13.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStairsUserMedium;
