import type { SVGProps } from "react";
const SvgHandSbbMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.829 10.056 15.71 6h3.06L24 11.25l-5.23 5.25h-3.06l4.118-4.03H14.71v4.03h-2.42v-4.03H7.2l4.116 4.03h-3.06L3 11.25 8.256 6h3.06L7.2 10.056h5.09V6h2.42v4.056h5.119ZM12.246 24.2l-5.758-4.02a2 2 0 0 0-2.499 3.123l8.88 7.823.141.125h5.898l1.365 1.355.147.145H33l-.846-.852-7.542-7.596-.008-.008c-1.756-1.69-4.041-2.999-6.431-3.044H15c-.881 0-1.582.337-2.058.87a2.85 2.85 0 0 0-.697 2.081Zm-6.343-3.21 7.056 4.929c.475.512 1.169.83 2.042.83h3v-1h-3c-.63 0-1.057-.226-1.332-.535-.282-.317-.432-.755-.428-1.21a1.85 1.85 0 0 1 .447-1.218c.28-.312.705-.536 1.313-.536h3.158c2.044.04 4.097 1.172 5.749 2.76l6.692 6.74h-9.77l-1.365-1.355-.146-.145h-5.932l-8.746-7.705-.008-.008-.01-.007a1 1 0 0 1 1.253-1.56l.013.01.013.01Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandSbbMedium;
