import type { SVGProps } from "react";
const SvgPlatformLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.006 4c-1.098 0-2 .902-2 2v2h7v1h-2v5h11V9h-2V8h7v27h-21v3h2v5h1v-5h23v-3h-2V8h2V4h-24Zm21 31h-1V8h1v27Zm-22 1h24v1h-24v-1Zm12-28v1h-5V8h5Zm-12-2c0-.546.454-1 1-1h23v2h-24V6ZM4 8h6.006v1h-2.64L5.7 14h5.296c1.58 0 2.854.388 3.732 1.241.88.856 1.278 2.099 1.278 3.626V38H11.85l1.09 2h2.067v1h-1.521l.96 1.76-.878.48L12.346 41H4v-1h7.8l-1.09-2H4.006v-1h11V27h-11v-1h11v-7.133c0-1.363-.353-2.304-.975-2.909-.625-.607-1.606-.958-3.035-.958H4.313l.219-.658L6.312 9H4V8Zm7.006 22a2 2 0 1 0 .002 4.001A2 2 0 0 0 11.006 30Zm-1 2a1 1 0 1 1 2.001.001 1 1 0 0 1-2-.001Zm-2-15H4.002v1h4.004v-1Zm16-7v3h9v-3h-9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPlatformLarge;
