import type { SVGProps } from "react";
const SvgAlarmClockSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m3.854 6.853 3-3-.708-.707-3 3 .708.707ZM5 12a7 7 0 1 1 14 0 7 7 0 0 1-14 0Zm7-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm8.146 2.853-3-3 .708-.707 3 3-.707.707ZM11 7v6h6v-1h-5V7h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAlarmClockSmall;
