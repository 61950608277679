import type { SVGProps } from "react";
const SvgUserPlusMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 13.5c0-2.698 1.86-4.75 4-4.75 2.14 0 4 2.052 4 4.75 0 2.699-1.86 4.75-4 4.75-2.14 0-4-2.051-4-4.75Zm4-5.75c-2.831 0-5 2.65-5 5.75 0 1.46.48 2.82 1.285 3.85C8.53 19.3 6.25 24.503 6.25 29.25v.5H21v-1H7.259c.158-4.496 2.436-9.077 6.26-10.628.824.705 1.85 1.128 2.981 1.128 1.131 0 2.156-.422 2.98-1.128 2.52 1.01 4.534 3.31 5.551 6.052l.938-.348c-1.048-2.824-3.099-5.277-5.753-6.477.804-1.03 1.284-2.39 1.284-3.849 0-3.1-2.169-5.75-5-5.75ZM27.25 25.5v3.25H24v1h3.25V33h1v-3.25h3.25v-1h-3.25V25.5h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserPlusMedium;
