import type { SVGProps } from "react";
const SvgBalloonsLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.501 6h-.013a9.906 9.906 0 0 0-7.751 4.07A9.12 9.12 0 0 0 17.503 9H17.5C11.62 9 7 14.686 7 21.5c0 6.62 4.36 12.175 10 12.486v8.036h1v-8.046a9.9 9.9 0 0 0 7.264-4.045A9.115 9.115 0 0 0 29 30.983v11.04h1V30.985c5.64-.312 10-5.867 10-12.486 0-6.814-4.62-12.5-10.5-12.5Zm-4.097 22.863-.034-.021-.004-.002C22.218 26.99 20 23.083 20 18.5v-.004a12.952 12.952 0 0 1 2.299-7.482l.238-.345A8.906 8.906 0 0 1 29.507 7c5.162.005 9.494 5.063 9.494 11.5 0 6.44-4.335 11.5-9.499 11.5a8.114 8.114 0 0 1-4.043-1.104l-.055-.032ZM17.5 10c-5.166.001-9.5 5.06-9.5 11.5 0 6.439 4.332 11.496 9.494 11.5a8.9 8.9 0 0 0 6.907-3.584c-3.24-2.147-5.4-6.242-5.4-10.914a13.95 13.95 0 0 1 2.172-7.6A8.12 8.12 0 0 0 17.499 10Zm-.544 2.002c-3.233.295-6.108 3.175-6.943 6.888l.976.22c.763-3.394 3.354-5.865 6.058-6.112l-.091-.996Zm12-3c-3.232.295-6.108 3.173-6.942 6.889l.976.219c.762-3.395 3.354-5.865 6.057-6.112l-.09-.996Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBalloonsLarge;
