import type { SVGProps } from "react";
const SvgStarFilledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="M10 9.5H4.5L9 13l-2 5.8 5-3.3 5 3.3-2-5.8 4.5-3.5H14l-2-6z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgStarFilledSmall;
