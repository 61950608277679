import type { SVGProps } from "react";
const SvgCirclePlaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM3 12a9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9 9 9 0 0 1-9-9Zm5.741-6.438L8 5.155v13.228l.757-.454 10-6 .746-.448-.762-.419-10-5.5ZM9 16.617V6.846l8.497 4.673L9 16.617Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCirclePlaySmall;
