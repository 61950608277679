import type { SVGProps } from "react";
const SvgAppleBagSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.232 3h8.692l.07.417 2 12 .052.32-.269.179-3 2-.126.084H13v-1h5.035l.831-11.649-.95 1.426-.148.223h-6.871l-.912 3.622-.97-.244L9.865 7H8.566l.518-.778 2-3L11.232 3Zm9.722 12.263-1.883 1.255.628-8.784 1.255 7.529ZM18.566 4h-6.798l-1.334 2h6.798l1.334-2ZM5.8 12.098A2.799 2.799 0 0 0 5.006 12c-.443-.01-1.234-.024-1.897.481C2.419 13.006 2 13.975 2 15.596c0 1.498.249 2.809.73 3.765C3.21 20.32 3.974 21 5 21h.01a3.495 3.495 0 0 0 1.376-.288l.165-.07c.205-.089.306-.132.449-.142.143.01.244.053.449.142l.165.07c.325.137.736.276 1.377.288H9c1.025 0 1.79-.68 2.27-1.639.481-.956.73-2.267.73-3.765 0-1.621-.418-2.59-1.109-3.115-.663-.505-1.454-.491-1.897-.481-.326 0-.58.035-.794.098-.217.064-.373.15-.49.217l-.001.001a1.42 1.42 0 0 1-.256.126c-.154-.881-.66-1.472-1.153-1.842a3.725 3.725 0 0 0-1.141-.575l-.025-.007-.008-.002h-.004L5 10.5l-.122.485.008.002.047.015a2.723 2.723 0 0 1 .767.399c.292.218.574.53.712.982a4.195 4.195 0 0 1-.12-.067l-.003-.001a2.193 2.193 0 0 0-.49-.217ZM3 15.596c0-1.475.38-2.065.714-2.319.358-.272.814-.288 1.275-.277H5c.258 0 .414.027.518.058.102.03.174.068.278.127l.02.012c.104.058.247.14.435.2A2.4 2.4 0 0 0 7 13.5a2.4 2.4 0 0 0 .749-.102c.188-.061.331-.143.434-.201l.021-.012c.104-.06.176-.098.278-.127.104-.03.26-.058.518-.058h.011c.461-.01.917.005 1.275.277.334.254.714.844.714 2.319 0 1.405-.236 2.547-.623 3.316-.384.766-.868 1.086-1.373 1.088a2.498 2.498 0 0 1-1.003-.21l-.11-.048c-.222-.098-.514-.227-.87-.242H6.98c-.357.015-.65.144-.871.242l-.11.048a2.5 2.5 0 0 1-1.003.21c-.505-.002-.989-.322-1.373-1.088-.387-.77-.623-1.91-.623-3.316Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAppleBagSmall;
