import type { SVGProps } from "react";
const SvgVeganSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.92 2h-.377l-.104.362A11.32 11.32 0 0 0 2 5.495a11.89 11.89 0 0 0 5.742 10.433l.517-.856A10.89 10.89 0 0 1 3 5.511v-.012A10.32 10.32 0 0 1 3.304 3H3.5c3.303 0 6.238 1.038 8.115 3.318l.772-.636C10.262 3.102 6.997 2 3.5 2h-.58Zm18.445 2.426-.066-.442-.447.016c-3.465.128-5.975 1.037-7.771 2.381-1.796 1.344-2.84 3.094-3.42 4.837-.58 1.74-.7 3.48-.653 4.832.024.678.09 1.265.166 1.713a7.181 7.181 0 0 0 .152.7 46.17 46.17 0 0 0-1.776 3.32l.9.435a45.168 45.168 0 0 1 1.729-3.231c3.314-.167 5.717-1.06 7.442-2.364 1.775-1.343 2.79-3.09 3.351-4.83 1.055-3.272.521-6.573.407-7.274a9.488 9.488 0 0 1-.014-.093ZM10.813 17.942c2.794-.239 4.785-1.043 6.205-2.117 1.577-1.193 2.49-2.751 3.002-4.34.86-2.666.58-5.375.425-6.464-3.07.18-5.237 1.017-6.765 2.161-1.602 1.198-2.54 2.762-3.07 4.352-.531 1.595-.647 3.21-.603 4.481.016.443.051.842.094 1.18a46.165 46.165 0 0 1 8.064-9.566l.67.742a45.156 45.156 0 0 0-8.022 9.571ZM5.765 5.576a17.23 17.23 0 0 1 4.12 3.605l-.77.638a16.23 16.23 0 0 0-3.88-3.395l.53-.848Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgVeganSmall;
