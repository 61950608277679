import type { SVGProps } from "react";
const SvgEv13 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-13_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm5.914 0v11.6h-2.317V8.177h-2.73V6.495c.566-.016 1.026-.075 1.38-.175.36-.1.689-.265.985-.492.428-.328.717-.88.865-1.658h1.817Zm3.081 3.673c.026-.952.261-1.75.706-2.396.72-1.037 1.8-1.555 3.245-1.555 1.11 0 2.007.283 2.69.849.735.614 1.103 1.407 1.103 2.38 0 .582-.146 1.085-.437 1.508-.285.418-.674.7-1.166.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.785 0 1.148-.423 2.087-1.27 2.817-.782.677-1.771 1.016-2.967 1.016-1.296 0-2.325-.373-3.086-1.119-.757-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.169 1.266.507 1.658.339.386.818.579 1.437.579.618 0 1.095-.183 1.428-.548.307-.344.46-.754.46-1.23 0-.571-.196-1.023-.587-1.357-.323-.274-.82-.412-1.492-.412-.19 0-.378.01-.563.032V8.772c.137.01.27.016.396.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.436-1.048-.291-.27-.675-.404-1.15-.404-.546 0-.966.17-1.262.508-.297.338-.455.833-.477 1.483h-2.197Z"
    />
  </svg>
);
export default SvgEv13;
