import type { SVGProps } from "react";
const SvgSaAw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 15 9.462 1h3.1l2.541 14h-2.801l-.479-3.21H5.881L4.101 15H1Zm9.562-11.513h-.08L7.121 9.584h4.321l-.88-6.097Zm18.76 8.243L34.263 1h2.901l-6.662 14h-3.5l-.14-11.192h-.04L21.901 15h-3.463L17.56 1h2.801l.42 10.73h.04L25.461 1h3.761l.06 10.73h.04Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaAw;
