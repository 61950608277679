import type { SVGProps } from "react";
const SvgSaWt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 1h18.9v14H1V1Zm1.264 12.727h16.38V2.273H2.264v11.454Zm5.201-9.68a.86.86 0 1 1-1.719 0 .86.86 0 0 1 1.719 0Zm2.255 7.652-.598-1.636H6.606A1.03 1.03 0 0 1 5.574 9.03V6.453a1.03 1.03 0 1 1 2.062 0v1.89H9.39c.289 0 .55.18.646.454l.894 2.462a.644.644 0 1 1-1.21.44Zm-.688 0-.426-1.169a2.237 2.237 0 0 1-2 1.251A2.239 2.239 0 0 1 4.37 9.547c0-.536.2-1.025.516-1.41V6.783A3.249 3.249 0 0 0 3.34 9.547a3.269 3.269 0 0 0 3.266 3.265c.969 0 1.828-.433 2.427-1.106v-.007ZM12.84 3.19c-.414 0-.797.14-1.079.421-.28.282-.421.665-.421 1.079v.255h-.538v.5l-.001 1v.5h.539V9.69h2v-.5h2v.496h2V6.945h.545v-2h-.545V4.69c0-.743-.592-1.5-1.5-1.5h-3Zm3.5 5h-4v-1h4v1Zm0-2h-4v-1.5c0-.186.06-.303.129-.371.068-.07.185-.129.371-.129h3c.292 0 .5.243.5.5v1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaWt;
