import type { SVGProps } from "react";
const SvgGondolaProfileSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m9 6.422 13.144-3.943-.288-.958-20 6 .288.958L8 6.722V10H5.5A1.5 1.5 0 0 0 4 11.5v6.838c0 .16.026.321.077.474l.72 2.162A1.5 1.5 0 0 0 6.222 22h4.558a1.5 1.5 0 0 0 1.423-1.026l.721-2.162a1.5 1.5 0 0 0 .077-.474V11.5a1.5 1.5 0 0 0-1.5-1.5H9V6.422ZM6 12h5v5H6v-5Zm1 1v3h3v-3H7Zm-2-1.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v6.838a.5.5 0 0 1-.026.158l-.72 2.162a.5.5 0 0 1-.475.342H6.221a.5.5 0 0 1-.475-.342l-.72-2.162A.5.5 0 0 1 5 18.338V11.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGondolaProfileSmall;
