import type { SVGProps } from "react";
const SvgIc3Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-3-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M36.575 7.898a4.345 4.345 0 0 1 .702-2.389c.715-1.034 1.79-1.55 3.224-1.55 1.104 0 1.995.282 2.673.846a2.953 2.953 0 0 1 1.096 2.373 2.595 2.595 0 0 1-.43 1.499 2.108 2.108 0 0 1-1.163.85c.59.123 1.114.462 1.47.95.37.518.559 1.143.54 1.78a3.545 3.545 0 0 1-1.261 2.808c-.778.675-1.76 1.012-2.948 1.012-1.288 0-2.31-.372-3.063-1.115-.754-.744-1.131-1.749-1.131-3.014v-.15h2.215c0 .711.168 1.261.504 1.649.364.4.889.613 1.427.581.615 0 1.088-.182 1.42-.546.301-.335.465-.774.457-1.226a1.687 1.687 0 0 0-.584-1.352c-.32-.274-.814-.412-1.482-.412-.187 0-.374.01-.56.032v-1.7c.137.01.269.015.395.015 1.324 0 1.986-.493 1.986-1.48a1.359 1.359 0 0 0-.433-1.047 1.621 1.621 0 0 0-1.144-.4 1.575 1.575 0 0 0-1.253.507c-.294.337-.452.83-.473 1.479h-2.184ZM9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
    />
  </svg>
);
export default SvgIc3Negative;
