import type { SVGProps } from "react";
const SvgSaTd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 3.207 1.481 1h10.782l-.46 2.207H7.862L5.321 15h-2.92l2.54-11.793H1Zm14.642 9.586H14.34l2-9.587h.96c2.52 0 4.222 1.184 4.222 3.37 0 3.85-1.84 6.217-5.881 6.217ZM18.202 1H14l-2.94 14h4.702c7.04 0 8.682-4.775 8.682-8.023C24.444 2.123 21.123 1 18.202 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaTd;
