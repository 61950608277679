import type { SVGProps } from "react";
const SvgIc7 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-7_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M44.348 4.235v1.962c-.063.069-.25.282-.56.64a11.49 11.49 0 0 0-1.284 1.872 12.9 12.9 0 0 0-.994 2.163 18.19 18.19 0 0 0-.906 4.928h-2.381c.032-.98.185-1.952.453-2.895a18.812 18.812 0 0 1 1.187-3.18c.72-1.498 1.45-2.613 2.191-3.346h-5.44V4.235h7.734ZM9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Z"
    />
  </svg>
);
export default SvgIc7;
