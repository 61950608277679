import type { SVGProps } from "react";
const SvgCarProfilePowerPlugSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.077 5.17A.502.502 0 0 1 6.454 5l-.377.17Zm0 0L3.602 8H9V5H6.454M10 5v3h6.793L13.94 5.147A.5.5 0 0 0 13.584 5H10Zm8 4H3v4h1.05a2.502 2.502 0 0 1 4.9 0h5.1a2.502 2.502 0 0 1 4.9 0H21l-.002-3.498c0-.215-.082-.316-.17-.38a.694.694 0 0 0-.344-.12L18 9Zm.95 5a2.502 2.502 0 0 1-4.9 0h-5.1a2.502 2.502 0 0 1-4.9 0H2V8.875a1.5 1.5 0 0 1 .372-.989l2.953-3.375A1.502 1.502 0 0 1 6.454 4h7.131a1.5 1.5 0 0 1 1.062.44L18.207 8l2.29.002h.019c.243.01.595.089.9.312.33.24.582.632.582 1.187L22 13.5v.5h-3.05ZM6.5 12a1.5 1.5 0 1 0 .002 3 1.5 1.5 0 0 0-.002-3.002Zm10 0a1.5 1.5 0 1 0 .001 3 1.5 1.5 0 0 0-.001-3.002ZM14 16.998v1l1.999-.005.002 1L14 19v1h2v1h-2v1h-1.5a2.502 2.502 0 0 1-2.45-2H7v-1h3.05a2.5 2.5 0 0 1 2.447-1.998L13.499 17H14Zm-1 4V18h-.502a1.499 1.499 0 1 0 .002 3h.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCarProfilePowerPlugSmall;
