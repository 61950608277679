import type { SVGProps } from "react";
const SvgTicketMachineTicketSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 2h12v16h-1v4H4v-4H3V2Zm2 16h8v3H5v-3Zm-.5-1H4V3h10v14H4.5ZM5 5h8V4H5v1Zm7 5V7h1v3h-1ZM5.5 7H5v5h6V7H5.5Zm.5 4V8h4v3H6Zm10-2h6v9h-6V9Zm5 1h-4v7h4v-7Zm-3 6h2v-1h-2v1Zm2-2h-2v-1h2v1ZM5.5 13H5v2h6v-2H5.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketMachineTicketSmall;
