import type { SVGProps } from "react";
const SvgHandMotionMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#hand-motion-medium_svg__a)">
      <path
        stroke="currentColor"
        strokeMiterlimit={10}
        d="M13.5 3.75a9.75 9.75 0 0 0-9.75 9.75m9.75-6.75c-3.59 0-6.75 3.16-6.75 6.75M22.5 32.25a9.75 9.75 0 0 0 9.75-9.75m-9.75 6.75c3.59 0 6.75-3.16 6.75-6.75M10.518 12.496a1.487 1.487 0 0 1 2.925.38l-.025 4.772L23.617 7.45a1.508 1.508 0 1 1 2.13 2.134l-7.962 7.94 9.036-9.01a1.49 1.49 0 0 1 2.106 2.11l-9.022 9.021 7.955-7.954a1.5 1.5 0 1 1 2.122 2.122l-7.955 7.954 4.786-4.77a1.495 1.495 0 0 1 2.112 2.115l-8.491 8.486c-2.121 2.122-5.3 3.185-5.3 3.185l-7.427-7.426 2.81-10.862Z"
      />
    </g>
    <defs>
      <clipPath id="hand-motion-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHandMotionMedium;
