import type { SVGProps } from "react";
const SvgDownloadSmallDataSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.646 6.646 12 8.293V3h-1v5.29L9.351 6.64l-.708.706 2.503 2.508.354.354.354-.354 2.5-2.5-.707-.707ZM3.5 6H3v12h17V6h-5v1h4v10H4V7h4V6H3.5ZM5 16h13v-1H5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDownloadSmallDataSmall;
