import type { SVGProps } from "react";
const SvgCheckpointsSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.994 5.5a2.506 2.506 0 1 1 5.012 0 2.506 2.506 0 0 1-5.012 0ZM5.5 2.006a3.494 3.494 0 1 0 3.46 3.988H19.5c.832 0 1.506.674 1.506 1.506v2c0 .832-.675 1.506-1.506 1.506h-4.54a3.495 3.495 0 0 0-6.92 0H4.5A2.494 2.494 0 0 0 2.006 13.5v3A2.494 2.494 0 0 0 4.5 18.994h10.54a3.495 3.495 0 1 0 0-.988H4.5A1.506 1.506 0 0 1 2.994 16.5v-3c0-.832.674-1.506 1.506-1.506h3.54a3.495 3.495 0 0 0 6.92 0h4.54A2.494 2.494 0 0 0 21.994 9.5v-2A2.494 2.494 0 0 0 19.5 5.006H8.96a3.495 3.495 0 0 0-3.46-3Zm1.85 2.843-.7-.698L5 5.8l-.65-.65-.7.698 1 1L5 7.2l.35-.35 2-2ZM18.5 15.994a2.506 2.506 0 1 0 0 5.012 2.506 2.506 0 0 0 0-5.012Zm1.85 1.855-.7-.698L18 18.8l-.65-.65-.7.698 1 1 .35.35.35-.35 2-2ZM11.5 8.994a2.506 2.506 0 1 0 0 5.011 2.506 2.506 0 0 0 0-5.011Zm1.85 1.855-.7-.699L11 11.8l-.65-.65-.7.699 1 1 .35.35.35-.35 2-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheckpointsSmall;
