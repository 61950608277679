import type { SVGProps } from "react";
const SvgArmchairProfileUserLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5 6.5a3.5 3.5 0 0 0-3.262 4.772l.442.758.098.17A3.5 3.5 0 1 0 15.5 6.5Zm0-1a4.5 4.5 0 0 0-4.496 4.306 4.379 4.379 0 0 0-.18-.124c-.741-.485-1.862-.896-3.395-.677-3.058.437-4.36 2.741-4.876 3.771-.342.683-.258 1.713.314 2.454.616.8 1.723 1.193 3.27.75.335-.095.588-.065.804.031.228.102.457.3.695.607.389.504.723 1.189 1.104 1.97.1.205.204.418.313.636.491.983 1.734 3.84 2.862 6.473a918.627 918.627 0 0 1 1.95 4.585l.13.305.005.014V42h23v-9.502l4.428 7.68a1.93 1.93 0 0 0 2.636.706l.057-.033.003-.003a1.93 1.93 0 0 0 .646-2.6l-5.315-9.183C39.786 28.513 40 27.83 40 27c0-1.358-.573-2.323-1.293-2.962a4.45 4.45 0 0 0-2.125-1.031l-.04-.007H32v-1c0-.763-.329-1.288-.77-1.606A2.17 2.17 0 0 0 30 20h-8.217l-2.854-4.757-.858.514 3 5 .146.243H30c.17 0 .436.055.645.206.184.132.355.357.355.794v1h-9.5a5.952 5.952 0 0 1-2.191-.46c-.806-.344-1.51-.891-1.845-1.726-.415-1.04-1.812-3.575-3.079-5.81-.15-.265-.298-.527-.444-.781A4.5 4.5 0 1 0 15.5 5.5Zm-4.228 6.1.065.11c.156.381.363.735.612 1.055.436.752.991 1.716 1.566 2.731 1.283 2.265 2.637 4.73 3.02 5.69.467 1.165 1.43 1.868 2.381 2.274.95.405 1.944.54 2.584.54h14.957a3.468 3.468 0 0 1 1.586.787c.53.47.957 1.17.957 2.213 0 1.042-.427 1.744-.957 2.213a3.468 3.468 0 0 1-1.586.787H14.832l-.045-.107a1046.63 1046.63 0 0 0-1.952-4.59c-1.122-2.618-2.38-5.51-2.888-6.527-.094-.188-.188-.38-.282-.574-.385-.792-.78-1.602-1.238-2.195-.293-.38-.643-.714-1.078-.909-.449-.2-.946-.233-1.486-.079-1.252.358-1.896.002-2.204-.4-.354-.458-.37-1.078-.212-1.395.486-.97 1.583-2.866 4.124-3.229 1.267-.181 2.146.158 2.706.524.283.185.489.38.622.527a2.39 2.39 0 0 1 .175.216l.002.002.006.012.04.067.15.257ZM15 41V31h21v10H15Zm27.294-1.322-5.099-8.843a4.493 4.493 0 0 0 1.585-.94l5.124 8.853a.93.93 0 0 1-.34 1.27h-.002a.93.93 0 0 1-1.268-.34Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArmchairProfileUserLarge;
