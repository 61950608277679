import type { SVGProps } from "react";
const SvgShoppingCartMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 8.75h3.25v15H27v-1H10.25v-2h16.36l.114-.342 3-9 .22-.658H10.25v-3H6v1Zm4.25 3v8h15.64l2.666-8H10.25Zm4 15a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm10 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShoppingCartMedium;
