import type { SVGProps } from "react";
const SvgTicketPercentSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4h20v5h-.5c-1.424 0-2.5 1.076-2.5 2.5s1.076 2.5 2.5 2.5h.5v5H2V4Zm1 1v13h13v-.465h1V18h4v-3.034c-1.727-.232-3-1.66-3-3.466 0-1.806 1.273-3.234 3-3.467V5h-4v.464h-1V5H3Zm3.997 1.997a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM10 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm2.854-5.647-9 9-.708-.707 9-9 .708.707ZM16 6.393v.928h1v-.928h-1Zm0 1.857v.928h1V8.25h-1Zm0 1.857v.929h1v-.93h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Zm0 1.857v.929h1v-.929h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketPercentSmall;
