import type { SVGProps } from "react";
const SvgLongDistanceCoachProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 6.28h22.276a2 2 0 0 1 1.82 1.18v.002l.13.288h3.524a2 2 0 0 1 2 2v3.75h-1V9.75a1 1 0 0 0-1-1h-3.078l4.78 10.712.002.004c.193.446.294.926.296 1.412v3.872a2 2 0 0 1-2 2h-7.03a4.25 4.25 0 0 1-8.44 0H3v-1h12.28a4.25 4.25 0 0 1 8.44 0h7.03a1 1 0 0 0 1-1v-3.869a2.59 2.59 0 0 0-.213-1.014v-.003L26.183 7.87a1 1 0 0 0-.91-.59H3v-1Zm0 3.97h3.25v5H3v1h4.25v-7H3v1Zm17.25-1h-.663l.182.638 3 10.5.104.362h7.145l-.31-.702-4.65-10.5-.133-.298H20.25Zm3.377 10.5-2.714-9.5h3.362l4.207 9.5h-4.855ZM19.5 23a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5ZM9.75 9.25h-.5v7h10.389l-.183-.638-1.726-6-.104-.362H9.75Zm.5 6v-5h6.623l1.438 5H10.25Zm9.25 4H3v-1h16.5v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLongDistanceCoachProfileMedium;
