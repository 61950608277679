import type { SVGProps } from "react";
const SvgHandSbbSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.22 6.704 10.473 4h2.04L16 7.5 12.514 11h-2.04l2.745-2.686H9.807V11H8.193V8.314H4.8L7.544 11h-2.04L2 7.5 5.504 4h2.04L4.8 6.704h3.393V4h1.614v2.704h3.412Zm-5.217 9.114-3.578-2.5a1.5 1.5 0 0 0-1.873 2.344l5.917 5.213.142.125h3.925l.862.855.146.145h8.857l-.846-.852-5.028-5.064-.008-.008c-1.188-1.144-2.75-2.045-4.4-2.076H10c-.633 0-1.145.244-1.495.635a2.05 2.05 0 0 0-.502 1.183ZM3.84 14.13l4.69 3.276c.35.37.852.594 1.47.594h2v-1h-2c-.374 0-.613-.134-.763-.301a1.033 1.033 0 0 1-.243-.695c.002-.268.094-.522.256-.702.153-.171.39-.302.75-.302h2.104c1.305.026 2.634.751 3.718 1.792L19.999 21h-6.043l-.861-.855-.147-.145h-3.96l-5.783-5.095-.009-.008-.009-.007a.5.5 0 0 1 .627-.78l.013.01.013.01Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandSbbSmall;
