import type { SVGProps } from "react";
const SvgEv98Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-98-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.145 8.688h2.19c.18.9.701 1.349 1.563 1.349.487 0 .881-.164 1.183-.492.306-.333.542-.849.706-1.547.105-.444.182-.95.23-1.516-.355.498-.72.852-1.095 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.553 3.553 0 0 1-1.031-1.357 4.457 4.457 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.768-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.296 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.586-.865-.678-.577-1.069-1.351-1.175-2.325Zm3.856-7.006c-.497 0-.917.204-1.261.61-.344.413-.516.937-.516 1.572 0 .513.124.968.373 1.365.338.545.815.817 1.428.817.556 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.161-1.124-.484-1.547a1.611 1.611 0 0 0-1.34-.66Zm6.763 3.689c-.54-.185-.955-.489-1.246-.912-.285-.423-.428-.936-.428-1.54 0-1.004.412-1.806 1.237-2.403.73-.53 1.598-.794 2.603-.794 1.143 0 2.076.315 2.8.944.689.593 1.032 1.325 1.032 2.198 0 .609-.145 1.13-.436 1.563-.29.434-.703.749-1.238.944.418.122.76.278 1.024.469.735.534 1.103 1.314 1.103 2.34 0 1.18-.453 2.119-1.357 2.817-.762.587-1.74.88-2.936.88-1.296 0-2.327-.322-3.094-.967-.8-.672-1.199-1.553-1.199-2.642 0-1.021.355-1.812 1.064-2.373.322-.248.68-.423 1.07-.524Zm2.134-3.848c-.508 0-.918.143-1.23.429-.312.28-.468.65-.468 1.11 0 .482.156.868.468 1.159.318.286.741.428 1.27.428s.947-.142 1.254-.428c.306-.286.46-.674.46-1.166 0-.482-.156-.857-.468-1.127-.307-.27-.736-.405-1.286-.405Zm-.016 4.721c-.57 0-1.039.178-1.404.532-.36.349-.54.799-.54 1.349 0 .566.183 1.026.548 1.38.37.355.849.532 1.436.532.667 0 1.167-.196 1.5-.587.322-.376.484-.81.484-1.301 0-.561-.185-1.021-.556-1.381-.354-.35-.843-.524-1.468-.524Z"
    />
  </svg>
);
export default SvgEv98Negative;
