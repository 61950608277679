import type { SVGProps } from "react";
const SvgBarrierConstructionMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.75 5.25a1 1 0 1 1 2 0v2.5h-2v-2.5Zm-1 2.5v-2.5a2 2 0 1 1 4 0v2.5h12.5v-2.5a2 2 0 1 1 4 0v2.5h4.5v7h-4.5v5.002h4.5v7h-4.5v5.998h-4v-5.998h-12.5v5.998h-4v-5.998h-4.5v-7h4.5V14.75h-4.5v-7h4.5Zm19.5-2.5v2.5h-2v-2.5a1 1 0 1 1 2 0Zm0 14.502V14.75h-2v5.002h2Zm-15.5-5.002h12.5v5.002h-12.5V14.75Zm-3 5.002V14.75h2v5.002h-2ZM4.25 8.75v5h3.793l5-5H4.25Zm10.207 0-5 5h12.086l5-5H14.457Zm17.293 5h-8.793l5-5h3.793v5Zm-4.5 13.002v4.998h-2v-4.998h2Zm-18.5 0v4.998h2v-4.998h-2Zm-4.5-6v4.29l4.291-4.29H4.25Zm9.791 5H4.955l5-5h9.086l-5 5Zm6.415-5-5 5h9.085l5-5h-9.085Zm10.5 0-5 5h5.794v-5h-.794Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBarrierConstructionMedium;
