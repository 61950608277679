import type { SVGProps } from "react";
const SvgCityLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 8.5v-.61l-.597.118-10 1.982-.403.08V34H5v7h1v-2h15v2h1v-7h-2V18h3v23h1v-3h12v3h1v-2h5v2h1V20h-2v-3h-4v-5h-1.64l-.886-2.658L34.36 9h-8.72l-.114.342L24.64 12H23v5h-4v17h-1V8.5ZM17 34V9.109l-9 1.784V34h1v-1h1v1h2v-1h1v1h2v-1h1v1h1Zm4 4v-3H6v3h15ZM9 13v2h1v-2H9Zm28 5v2h3v-2h-3Zm5 3v17h-5V21h5Zm-16.306-9h8.612l-.666-2h-7.28l-.666 2ZM24 37h12V13H24v24ZM12 15v-2h1v2h-1Zm3-2v2h1v-2h-1Zm-6 6v-2h1v2H9Zm3-2v2h1v-2h-1Zm3 2v-2h1v2h-1Zm-6 2v2h1v-2H9Zm3 2v-2h1v2h-1Zm3-2v2h1v-2h-1Zm-6 6v-2h1v2H9Zm3-2v2h1v-2h-1Zm3 2v-2h1v2h-1Zm-6 2v2h1v-2H9Zm3 2v-2h1v2h-1Zm3-2v2h1v-2h-1Zm10.001-13v-2h1v2h-1Zm3-2v2h1v-2h-1Zm3 2v-2h1v2h-1Zm-5 2h-1v2h1v-2Zm2 0h1v2h-1v-2Zm4 0h-1v2h1v-2Zm-7 4h1v2h-1v-2Zm4 0h-1v2h1v-2Zm2 0h1v2h-1v-2Zm-5 4h-1v2h1v-2Zm2 0h1v2h-1v-2Zm4 0h-1v2h1v-2Zm-7 4h1v2h-1v-2Zm4 0h-1v2h1v-2Zm2 0h1v2h-1v-2Zm3.001-16v2h1v-2h-1Zm0 4h1v2h-1v-2Zm1 4h-1v2h1v-2Zm-1 4h1v2h-1v-2Zm1 4h-1v2h1v-2Zm-10.001 6v-2h1v2h-1Zm3-2v2h1v-2h-1Zm3 2v-2h1v2h-1Zm3.001-2v2h1v-2h-1Zm5-12h-1v2h1v-2Zm-1 4h1v2h-1v-2Zm1 4h-1v2h1v-2Zm1-8h1v2h-1v-2Zm1 4h-1v2h1v-2Zm-1 4h1v2h-1v-2Zm-2 4v2h1v-2h-1Zm2 2v-2h1v2h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCityLarge;
