import type { SVGProps } from "react";
const SvgTwoFoldersSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.5 3h-.309l-.138.277L16.19 5H6v6H3v10.01h.5l15-.01h.5v-6h3V3h-4.5ZM19 14h2V4h-3.191l-.862 1.724L16.81 6H7v5h6.191l.862-1.723L14.19 9H19v5Zm-5.053-2.276L14.81 10H18v10.001l-14 .008V12h9.809l.138-.276Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTwoFoldersSmall;
