import type { SVGProps } from "react";
const SvgArrowChangeHorizontalSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16.354 2.647 3.5 3.5.353.353-.353.354-3.5 3.5-.707-.707L18.293 7H7.5a2.5 2.5 0 1 0 0 5h9.001a3.5 3.5 0 0 1 0 7H5.708l2.646 2.646-.707.707-3.5-3.5-.354-.353.354-.354 3.5-3.5.707.707L5.707 18H16.5a2.5 2.5 0 0 0 0-5h-9a3.5 3.5 0 0 1 0-7h10.792l-2.646-2.646.707-.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowChangeHorizontalSmall;
