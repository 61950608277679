import type { SVGProps } from "react";
const SvgRailwayShipSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 2H7v3h2v2H2.182l.373.728 2.127 4.156-.763.261c-.408.14-.849.14-1.257 0l-.324.946a2.935 2.935 0 0 0 1.905 0l1.42-.486a1.93 1.93 0 0 1 1.256 0l1.419.486.324-.946-1.419-.486a2.934 2.934 0 0 0-1.597-.087L3.818 8H14V7h-1V5h3V4H8V3h9V2H7.5ZM12 7h-2V5h2v2ZM3.646 19.646l15-15 .708.708-15 15-.708-.708ZM16 12h2.824a3.573 3.573 0 0 0-1.83-1.726l.386-.922a4.576 4.576 0 0 1 2.564 2.745l1.875 5.246A1.854 1.854 0 0 1 20.156 20H17.95c-.075.37-.232.71-.45 1H22v1H10v-1h3.5a2.488 2.488 0 0 1-.45-1H10v-1h10.156a.854.854 0 0 0 .751-1.245l-.012-.025-.01-.026-.25-.704H16v-5Zm.915 8a1.502 1.502 0 0 1-2.83 0h2.83Zm3.362-4-1.072-3H17v3h3.277ZM15 12v5h-5v-1h4v-4h1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRailwayShipSmall;
