import type { SVGProps } from "react";
const SvgEv39 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-39_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.45V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.674c.026-.953.262-1.751.706-2.397.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.85.735.613 1.103 1.406 1.103 2.38 0 .581-.145 1.084-.436 1.507-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.548 1.087.548 1.786 0 1.147-.424 2.086-1.27 2.816-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.57-.195-1.023-.587-1.356-.322-.275-.82-.413-1.491-.413-.191 0-.379.01-.564.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.047-.291-.27-.674-.405-1.15-.405-.545 0-.966.17-1.262.508-.296.338-.455.833-.476 1.484h-2.198Zm8.754 5.014h2.19c.18.9.7 1.349 1.563 1.349.487 0 .88-.164 1.182-.492.307-.333.542-.849.706-1.547.106-.444.183-.95.23-1.516-.354.498-.719.852-1.094 1.064-.37.206-.823.309-1.357.309-.852 0-1.592-.249-2.222-.746a3.552 3.552 0 0 1-1.031-1.357 4.457 4.457 0 0 1-.365-1.809c0-1.306.447-2.364 1.34-3.174.767-.698 1.688-1.047 2.762-1.047.661 0 1.272.148 1.833.444.56.297 1.031.717 1.412 1.262.672.968 1.008 2.328 1.008 4.078 0 1.619-.297 3-.889 4.142-.772 1.487-1.941 2.23-3.507 2.23-1.042 0-1.904-.288-2.587-.865-.677-.577-1.068-1.351-1.174-2.325Zm3.856-7.006c-.497 0-.918.204-1.261.61-.344.413-.516.937-.516 1.572 0 .513.124.968.373 1.365.338.545.814.817 1.428.817.555 0 1-.222 1.333-.667.312-.417.468-.915.468-1.491 0-.609-.161-1.124-.484-1.547a1.611 1.611 0 0 0-1.34-.66Z"
    />
  </svg>
);
export default SvgEv39;
