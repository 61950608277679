import type { SVGProps } from "react";
const SvgGunMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.75 7.813c.704.18 1.258.734 1.439 1.437h1.561v2.5h-1.5v1.118l-.053.106-1.5 3-.138.276h-8.944c-.246 1.683-.765 2.929-1.44 3.843a5.378 5.378 0 0 1-2.505 1.89 6.26 6.26 0 0 1-2.738.339l-.682 5.463a2 2 0 0 1-2 1.966h-3a2 2 0 0 1-2-2v-.03l.004-.032 1.43-11.438H6.75a2 2 0 0 1-2-2V10.25H3v-1h1.813a2.002 2.002 0 0 1 1.937-1.5h22V6h1v1.813Zm2 2.937h-.499v-.5h.499v.5Zm-12.147 5.5h-4.912l-.03.25h.09c0 .967.784 1.75 1.75 1.75v1a2.744 2.744 0 0 1-2.067-.937l-.377 3.016a5.257 5.257 0 0 0 2.269-.285 4.379 4.379 0 0 0 2.044-1.545c.542-.733 1-1.773 1.233-3.249ZM6.75 8.75a1 1 0 0 0-1 1v2.5h24.5l.002-2.5a1 1 0 0 0-1-1H6.75Zm-1 5.502V13.25h24.191l-1 2H13.809l-.055.438-1.5 12.001-.004.031v.032a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-.973l1.496-11.967.07-.563-.567.001-1.499.002a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGunMedium;
