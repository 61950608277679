import type { SVGProps } from "react";
const SvgIr80 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-80_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-80_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.389 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915ZM38.35 9.59c-.539-.186-.953-.489-1.243-.91-.284-.422-.427-.934-.427-1.535 0-1.002.412-1.801 1.234-2.397.728-.527 1.593-.791 2.595-.791 1.139 0 2.07.314 2.792.941.686.591 1.028 1.321 1.028 2.191 0 .607-.145 1.126-.435 1.559-.29.432-.701.746-1.234.941.417.121.757.277 1.02.467.734.532 1.1 1.31 1.1 2.333 0 1.176-.45 2.112-1.352 2.808-.76.586-1.735.878-2.927.878-1.292 0-2.32-.321-3.085-.965-.796-.67-1.195-1.547-1.195-2.634 0-1.018.354-1.806 1.06-2.365a2.924 2.924 0 0 1 1.068-.522Zm2.127-3.837c-.506 0-.915.142-1.226.427-.311.28-.467.648-.467 1.107 0 .48.156.865.467 1.155.316.285.738.427 1.266.427.527 0 .944-.142 1.25-.427.305-.285.458-.672.458-1.163 0-.48-.155-.854-.467-1.123-.305-.269-.733-.403-1.28-.403Zm-.016 4.706c-.57 0-1.036.177-1.4.53-.358.348-.538.796-.538 1.345 0 .564.182 1.023.546 1.376.37.354.846.53 1.432.53.664 0 1.163-.195 1.495-.585.322-.374.482-.807.482-1.297 0-.56-.184-1.018-.553-1.377-.354-.348-.841-.522-1.464-.522Zm9.113-6.502c.88 0 1.656.269 2.325.807.665.538 1.147 1.366 1.448 2.484.227.854.34 1.782.34 2.784 0 1.661-.295 3.027-.886 4.098-.348.622-.804 1.102-1.368 1.44a3.556 3.556 0 0 1-1.875.505c-.944 0-1.75-.284-2.42-.854-1.192-1.012-1.788-2.742-1.788-5.189 0-1.74.31-3.138.933-4.192a3.77 3.77 0 0 1 1.384-1.393 3.788 3.788 0 0 1 1.907-.49Zm-.087 1.954c-.76 0-1.276.438-1.55 1.313-.217.696-.325 1.672-.325 2.927 0 1.107.11 1.996.332 2.665.285.87.812 1.306 1.582 1.306.638 0 1.113-.338 1.424-1.013.311-.675.467-1.706.467-3.093 0-1.181-.11-2.112-.332-2.792-.285-.875-.818-1.313-1.598-1.313Z"
      />
    </g>
    <defs>
      <clipPath id="ir-80_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr80;
