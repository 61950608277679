import type { SVGProps } from "react";
const SvgChevronLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.354 6.353 12.707 18l11.647 11.647-.707.707-12-12-.354-.354.354-.353 12-12 .707.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronLeftMedium;
