import type { SVGProps } from "react";
const SvgClapperboardSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.531 3A.531.531 0 0 0 3 3.531V6h4.691l1.5-3h-5.66Zm10.16 3H8.809l1.5-3h4.882l-1.5 3Zm2.618-3-1.5 3H21V3.531A.531.531 0 0 0 20.469 3h-4.16Zm-13.86-.552A1.531 1.531 0 0 1 3.53 2h16.94A1.531 1.531 0 0 1 22 3.531V7H2V3.531c0-.406.161-.795.448-1.083ZM3 21.027v-13H2v14h20v-14h-1v13H3ZM9.74 10.56 9 10.158v7.684l.74-.403 5.5-3 .804-.439-.805-.439-5.5-3ZM13.955 14 10 16.158v-4.316L13.956 14Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClapperboardSmall;
