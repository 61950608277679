import type { SVGProps } from "react";
const SvgMicrophoneDisabledSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 5.5C9 4.126 10.126 3 11.5 3S14 4.126 14 5.5V12h1V5.5C15 3.574 13.426 2 11.5 2A3.509 3.509 0 0 0 8 5.5V6h1v-.5Zm7.114 11.32 4.532 4.534.708-.708-18-18-.708.708L8 8.707V13.5c0 1.926 1.574 3.5 3.5 3.5 1.295 0 2.428-.71 3.034-1.76l.86.86C14.635 17.277 13.21 18 11.5 18 8.942 18 7 16.418 7 14H6c0 2.929 2.255 4.781 5 4.982V21H9v1h5v-1h-2v-2.018c1.703-.125 3.208-.887 4.114-2.161ZM9 13.5V9.707l4.79 4.79A2.51 2.51 0 0 1 11.5 16 2.509 2.509 0 0 1 9 13.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMicrophoneDisabledSmall;
