import type { SVGProps } from "react";
const SvgTgvNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="tgv-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M23.193 6.66a7.168 7.168 0 0 0-.654-.349 6.826 6.826 0 0 0-.902-.307 5.88 5.88 0 0 0-1.573-.174c-1.37 0-2.49.454-3.361 1.362-.872.908-1.308 2.065-1.308 3.472 0 1.096.309 1.955.927 2.575.618.62 1.479.93 2.583.93.302.014.605-.026.894-.116l.645-3.074h-2.45l.38-1.827h4.686l-1.324 6.33c-.221.077-.42.14-.596.19-.177.05-.475.117-.894.2a8.964 8.964 0 0 1-1.672.124c-1.822 0-3.21-.445-4.165-1.337-.954-.891-1.432-2.179-1.432-3.862 0-2.082.632-3.735 1.896-4.96 1.264-1.223 2.967-1.835 5.108-1.835.655-.003 1.308.052 1.953.166a12.87 12.87 0 0 1 1.838.482l-.58 2.01ZM4.583 4.201h8.924l-.38 1.828H9.864l-2.103 9.768H5.345L7.447 6.03H4.186l.397-1.83Zm23.13 0h-2.318l1.523 11.596h2.765L36.14 4.201h-2.517l-4.834 9.021-1.076-9.02Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTgvNegative;
