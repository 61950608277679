import type { SVGProps } from "react";
const SvgTunnelFreightWagonShiftedMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#tunnel-freight-wagon-shifted-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.25 17.38c0-7.488 5.82-13.13 13-13.13s13 5.642 13 13.13V33h1V17.38c0-8.043-6.27-14.13-14-14.13s-14 6.087-14 14.13V33h1V17.38Zm18.941 14.37h-8.882l1-2h6.882l1 2Zm1.118 0H27v1H10.5v-1h2.691l1-2H10.75v-3h-3v-14.5h16v13.5h3v4h-3.441l1 2Zm-12.559-5h14v2h-14v-2Zm-3-13.5v12.5h3.5v-12.5h-3.5Zm4.5 0h5v12.5h-5v-12.5Zm6 0h3.5v12.5h-3.5v-12.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="tunnel-freight-wagon-shifted-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTunnelFreightWagonShiftedMedium;
