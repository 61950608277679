import type { SVGProps } from "react";
const SvgPizzaSliceSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.619 3.057c.294-.826 1.228-1.294 2.069-.937 5.593 2.372 10.027 6.967 12.211 12.705.323.847-.155 1.776-.988 2.052l-2.645.877a1.584 1.584 0 0 1-1.482-.258L3.507 21.94c-.917.307-1.753-.587-1.44-1.478l.002-.006L6.916 7.237a1.622 1.622 0 0 1-.24-1.534l.943-2.646Zm.14 4.786L7.064 9.74c.58.428.955 1.12.955 1.899 0 1.293-1.038 2.352-2.33 2.352a2.71 2.71 0 0 1-.181-.007L3.01 20.796a.149.149 0 0 0 .18.196l1.803-.604a2.109 2.109 0 0 1 2.095-2.385 2.095 2.095 0 0 1 1.816 1.076l7.285-2.438a16.5 16.5 0 0 0-8.43-8.798Zm.163 11.565a1.092 1.092 0 0 0-.84-.405 1.108 1.108 0 0 0-1.104 1.055l1.944-.65Zm-2.045-6.432.816-2.225c.203.237.326.547.326.887 0 .686-.5 1.245-1.142 1.338Zm3.42-9.935c-.273-.116-.62.028-.736.352l-.943 2.645a.651.651 0 0 0 .37.812 17.498 17.498 0 0 1 9.185 9.556c.136.344.479.498.778.398l2.645-.876a.583.583 0 0 0 .369-.747C18.877 9.697 14.639 5.306 9.297 3.04ZM11 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPizzaSliceSmall;
