import type { SVGProps } from "react";
const SvgArrowCircleEyeMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 3.25C9.854 3.25 3.25 9.854 3.25 18h1c0-7.594 6.156-13.75 13.75-13.75S31.75 10.406 31.75 18 25.594 31.75 18 31.75c-5.547 0-10.544-3.287-12.74-8H9v-1H3.25v5.75h1v-4.555c2.305 5.19 7.73 8.805 13.75 8.805 8.146 0 14.75-6.604 14.75-14.75S26.146 3.25 18 3.25Zm.022 10c1.848-.083 6.162.695 8.877 4.763-1.417 2.288-4.922 4.82-8.889 4.737H18c-3.71 0-6.975-1.886-8.908-4.75 1.933-2.864 5.197-4.75 8.908-4.75h.022Zm9.888 4.483c-2.946-4.676-7.793-5.574-9.921-5.483-4.18.004-7.837 2.197-9.916 5.483l-.17.267.17.267c2.08 3.288 5.74 5.481 9.922 5.483 4.524.091 8.464-2.87 9.928-5.507l.144-.259-.157-.25ZM16.25 18a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0ZM18 15.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowCircleEyeMedium;
