import type { SVGProps } from "react";
const SvgNineSquaresMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.25 6.25h5.5v5.5h-5.5v-5.5Zm1 1v3.5h3.5v-3.5h-3.5Zm8-1h5.5v5.5h-5.5v-5.5Zm1 1v3.5h3.5v-3.5h-3.5Zm8.5-1h-.5v5.5h5.5v-5.5h-5Zm.5 4.5v-3.5h3.5v3.5h-3.5Zm-19 4.5h5.5v5.5h-5.5v-5.5Zm1 1v3.5h3.5v-3.5h-3.5Zm8.5-1h-.5v5.5h5.5v-5.5h-5Zm.5 4.5v-3.5h3.5v3.5h-3.5Zm8-4.5h5.5v5.5h-5.5v-5.5Zm1 1v3.5h3.5v-3.5h-3.5Zm-18.5 8h-.5v5.5h5.5v-5.5h-5Zm.5 4.5v-3.5h3.5v3.5h-3.5Zm8-4.5h5.5v5.5h-5.5v-5.5Zm1 1v3.5h3.5v-3.5h-3.5Zm8.5-1h-.5v5.5h5.5v-5.5h-5Zm.5 4.5v-3.5h3.5v3.5h-3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNineSquaresMedium;
