import type { SVGProps } from "react";
const SvgWeatherUnknownSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.311 9.934c.416.099.767.314 1.039.61.391.424.598.99.641 1.556.086 1.121-.468 2.43-1.815 2.875l-.313-.95c.803-.265 1.191-1.062 1.131-1.848-.03-.387-.168-.726-.38-.956-.201-.219-.49-.363-.895-.347l-.696.028.197-.669c.473-1.604-.33-2.707-1.373-3.088-1.05-.383-2.368-.047-3.006 1.41l-.266.607-.51-.421c-.965-.796-2.578-.055-2.396 1.474l.066.559h-.562c-.59 0-1.083.538-1.163 1.355-.077.78.27 1.57 1.126 1.862l-.323.946c-1.401-.478-1.909-1.785-1.798-2.906.092-.935.65-1.934 1.643-2.193.092-1.862 2.01-2.923 3.532-2.196.948-1.552 2.632-1.936 4-1.436 1.375.502 2.41 1.884 2.121 3.728Zm-5.285 7.065h.88v-.838h-.88V17Zm-1.093-5.238c-.179.274-.278.661-.297 1.161h.701c.057-.88.448-1.322 1.173-1.322.351 0 .616.112.795.335.15.182.224.408.224.676 0 .145-.026.288-.077.428-.063.164-.195.348-.395.551a9.746 9.746 0 0 0-.606.65c-.091.114-.16.244-.209.39-.069.198-.105.504-.111.915h.693c0-.232.014-.419.041-.565.025-.144.067-.266.128-.365.062-.097.17-.22.324-.368.351-.339.572-.57.664-.696.207-.278.31-.608.31-.992 0-.464-.152-.839-.456-1.124-.326-.309-.765-.463-1.316-.463-.343 0-.651.068-.926.205a1.671 1.671 0 0 0-.66.584Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWeatherUnknownSmall;
