import type { SVGProps } from "react";
const SvgAddStop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className="add-stop_svg__color-immutable"
    {...props}
  >
    <rect width={16} height={16} fill="#EB0000" rx={2} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.559 10.646a2.724 2.724 0 0 1-.709.104 2.69 2.69 0 0 1-.791-.13A2.744 2.744 0 0 1 5.1 8c0-1.239.83-2.278 1.959-2.62.252-.076.514-.13.791-.13.247 0 .481.043.709.105A2.745 2.745 0 0 1 10.6 8c0 1.27-.869 2.331-2.041 2.646Zm0-6.824V0h-1.5v3.83A4.253 4.253 0 0 0 3.6 8a4.252 4.252 0 0 0 3.459 4.17V16h1.5v-3.821C10.565 11.839 12.1 10.101 12.1 8c0-2.1-1.535-3.838-3.541-4.178Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAddStop;
