import type { SVGProps } from "react";
const SvgUnicornMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m31.314 8.432-5.51 5.72.152.31c.289.581.633 1.23.988 1.9l.49.927c.513.979 1.008 1.958 1.321 2.748.155.393.277.765.327 1.082.024.159.036.327.011.49a.854.854 0 0 1-.252.506c-.1.093-.193.184-.285.274l-.007.007c-.093.09-.187.181-.278.266-.18.166-.38.333-.607.45a1.39 1.39 0 0 1-.942.132 1.381 1.381 0 0 1-.547.799 1.12 1.12 0 0 1-.937.151c-.32-.087-.608-.301-.857-.604a14.928 14.928 0 0 0-4.332-2.882l.4-.916c1.72.75 3.292 1.8 4.646 3.097l.024.023.02.026c.169.21.298.274.362.292.053.014.084.005.114-.016.086-.058.219-.278.146-.606l-.26-1.182 1.018.653c.215.138.356.182.448.193.08.01.15-.004.24-.05.103-.053.222-.144.386-.295.08-.074.164-.156.259-.248l.009-.009c.076-.074.158-.154.246-.237a.85.85 0 0 0-.013-.158c-.033-.208-.122-.5-.269-.87-.29-.734-.762-1.67-1.277-2.652-.157-.3-.32-.605-.48-.908a87.562 87.562 0 0 1-1.007-1.937 17.16 17.16 0 0 1-.543-1.181c-.132-.33-.24-.655-.266-.923a2.534 2.534 0 0 0-1.251-1.87l-.41-.235.211-.423 1.082-2.163c-.07.016-.142.034-.216.055-.603.173-1.189.49-1.471 1.056l-.19.377-.403-.123a10.566 10.566 0 0 0-10.026 2.149l-.008.007c-2.216 1.853-3.613 4.575-4.487 7.516-.82 2.763-1.167 5.68-1.31 8.162 5.37.68 9.583 1.377 14.852 2.647-1.213-1.841-2.138-3.34-2.774-5.028-.76-2.016-1.101-4.273-1.101-7.681h1c0 3.342.334 5.466 1.036 7.328.707 1.873 1.795 3.51 3.375 5.888l.005.007.714 1.075-1.252-.313c-5.975-1.494-10.455-2.24-16.44-2.989l-.46-.057.022-.463c.128-2.617.47-5.816 1.375-8.86.902-3.04 2.377-5.969 4.8-7.996A11.566 11.566 0 0 1 21.52 8.415c.5-.69 1.262-1.034 1.872-1.208A5.6 5.6 0 0 1 24.713 7h.037v.5V7h.81l-.362.724-1.303 2.606c.127.098.248.204.36.319l6.488-3.017 2.43-1.13-1.858 1.93Zm-2.58 1.236-3.363 3.492a1.996 1.996 0 0 1-.123-.451l-.001-.011-.002-.01a3.533 3.533 0 0 0-.386-1.218l3.875-1.802ZM20.75 13.5a.25.25 0 1 1 .5 0 .25.25 0 0 1-.5 0Zm.25-1.25a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUnicornMedium;
