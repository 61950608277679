import type { SVGProps } from "react";
const SvgSignXMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.25 3.376.448.901 13.05 26.25.359.723H3.393l.36-.723 13.05-26.25.447-.9ZM29.493 30.25 17.25 5.623 5.007 30.25h24.486Zm-12.95-7-3.396-3.396.707-.707 3.396 3.396 3.397-3.396.707.707-3.397 3.396 3.397 3.396-.707.708-3.397-3.397-3.396 3.397-.707-.707 3.396-3.397Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSignXMedium;
