import type { SVGProps } from "react";
const SvgBackpackLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M34.18 6a.5.5 0 0 0-.466.315L32.32 9.803a9.5 9.5 0 0 1 1.667.943l2.005-4.337A.5.5 0 0 0 35.5 6h-1.32Zm1.038 5.783a9.527 9.527 0 0 0-.424-.4L36 8.774v3.896a9.48 9.48 0 0 0-.782-.886Zm-2.433-5.838-1.4 3.503A9.498 9.498 0 0 0 30 9.12V8.5A3.5 3.5 0 0 0 26.5 5h-4A3.5 3.5 0 0 0 19 8.5v.62c-.47.074-.933.185-1.384.328l-1.401-3.503A1.5 1.5 0 0 0 14.82 5H13.5A1.5 1.5 0 0 0 12 6.5v7.757a9.5 9.5 0 0 0-1 4.243V44h27V18.5a9.5 9.5 0 0 0-1-4.243V6.5A1.5 1.5 0 0 0 35.5 5h-1.32v.5V5a1.5 1.5 0 0 0-1.394.945Zm-17.5.37 1.395 3.487a9.493 9.493 0 0 0-1.667.943l-2.005-4.337A.5.5 0 0 1 13.5 6h1.32a.5.5 0 0 1 .466.315Zm-1.079 5.069L13 8.774v3.895a9.48 9.48 0 0 1 1.206-1.285ZM29 8.5v.513A9.515 9.515 0 0 0 28.5 9H28v-.5A1.5 1.5 0 0 0 26.5 7h-4A1.5 1.5 0 0 0 21 8.5V9h-.5c-.167 0-.334.004-.5.013V8.5A2.5 2.5 0 0 1 22.5 6h4A2.5 2.5 0 0 1 29 8.5ZM22 9v-.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5V9h-5Zm-1.5 1a8.5 8.5 0 0 0-8.5 8.5V43h25V18.5a8.5 8.5 0 0 0-8.5-8.5h-8Zm-2.99 18a2.5 2.5 0 0 0-2.5 2.5v.5h19v-.5a2.5 2.5 0 0 0-2.5-2.5h-14ZM29 32H15.01v8h19v-8H32v3.5a1.5 1.5 0 1 1-3 0V32Zm1 0v3.5a.5.5 0 0 0 1 0V32h-1Zm-14.965-3.975A3.5 3.5 0 0 1 17.51 27h14a3.5 3.5 0 0 1 3.5 3.5V41h-21V30.5a3.5 3.5 0 0 1 1.025-2.475ZM20 14a1 1 0 0 0-1 1v1h11v-1a1 1 0 0 0-1-1h-9Zm10 3v4H19v-4h8v2h1v-2h2Zm-11.414-3.414A2 2 0 0 1 20 13h9a2 2 0 0 1 2 2v7H18v-7a2 2 0 0 1 .586-1.414Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBackpackLarge;
