import type { SVGProps } from "react";
const SvgFingerprintSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.78 3.1a15.382 15.382 0 0 1 9.588 2.058l.51-.86a16.381 16.381 0 0 0-8.379-2.299c-.622 0-1.232.04-1.832.108l.113.993ZM4.07 5.06A14.445 14.445 0 0 1 9.5 4c4.257 0 8.073 1.846 10.724 4.765l-.74.672C17.013 6.717 13.461 5 9.5 5c-1.788 0-3.49.355-5.054.987L4.07 5.06ZM9.5 7c-2.173 0-4.198.613-5.933 1.663l-.518-.856A12.426 12.426 0 0 1 9.5 6c4.64 0 8.678 2.533 10.834 6.281l-.866.499C17.48 9.327 13.765 7 9.5 7Zm-5.855 6.82A7.48 7.48 0 0 1 9.5 11a7.498 7.498 0 0 1 7.497 7.444l1-.008A8.498 8.498 0 0 0 9.5 10a8.48 8.48 0 0 0-6.636 3.195l.78.625Zm-.966-3.294A10.452 10.452 0 0 1 9.499 8c4.778 0 8.802 3.193 10.075 7.557l-.96.28C17.462 11.887 13.82 9 9.5 9a9.452 9.452 0 0 0-6.17 2.286l-.651-.76Zm3.327 7.924A3.497 3.497 0 0 1 13 18.5v2.696h1V18.5a4.497 4.497 0 0 0-8.994-.065l1 .015Zm-2.424-2.63A6.497 6.497 0 0 1 16 18.501v1.763h-1V18.5a5.497 5.497 0 0 0-10.507-2.266l-.911-.413ZM9.5 16A2.5 2.5 0 0 0 7 18.5v1.763h1V18.5a1.5 1.5 0 1 1 3 0v3h1v-3A2.5 2.5 0 0 0 9.5 16ZM9 21v-3h1v3H9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFingerprintSmall;
