import type { SVGProps } from "react";
const SvgWheelThermometerLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 3C12.402 3 3 12.402 3 24s9.402 21 21 21c.758 0 1.479-.033 2.21-.11l.106.994A21.69 21.69 0 0 1 24 46C11.85 46 2 36.15 2 24S11.85 2 24 2c2.421 0 4.777.44 6.947 1.157l-.315.95C28.551 3.416 26.302 3 24 3Zm7.5 6c0-2.792 2.208-5 5-5s5 2.208 5 5v21.744c2.069 1.533 3.5 3.958 3.5 6.756 0 4.68-3.821 8.5-8.5 8.5S28 42.18 28 37.5c0-2.798 1.431-5.223 3.5-6.756V9Zm5-4c-2.24 0-4 1.76-4 4v22.261l-.214.15C30.336 32.768 29 34.972 29 37.5c0 4.127 3.373 7.5 7.5 7.5s7.5-3.373 7.5-7.5c0-2.527-1.336-4.73-3.286-6.09l-.214-.149V9c0-2.24-1.76-4-4-4ZM21 24a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm9 17.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm3-4.472a4.5 4.5 0 1 0 1 0V12h-1v21.028ZM7 24c0-9.382 7.618-17 17-17 1.58 0 3.093.17 4.526.565l.266-.964C27.256 6.177 25.65 6 24 6 14.066 6 6 14.066 6 24s8.066 18 18 18l.345.002c.27.003.491.005.844-.015l-.058-.998c-.317.018-.495.016-.751.014A32.107 32.107 0 0 0 24 41c-9.382 0-17-7.618-17-17Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWheelThermometerLarge;
