import type { SVGProps } from "react";
const SvgFolderSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.191 6h4.804v.5l.005 11v.5H4V8h10.191l.862-1.724L15.19 6Zm.618 1-.862 1.724L14.81 9H5v8h14l-.004-10h-3.187Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFolderSmall;
