import type { SVGProps } from "react";
const SvgCloudRainSnowMoonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.428 4.026.525-1.038-1.114.333c-.61.183-1.175.473-1.67 1.02-.4.442-.732 1.03-1.016 1.812-1.355-.471-3.009-.085-3.945 1.437-1.516-.719-3.43.326-3.522 2.177-.481.128-.87.43-1.144.814-.36.504-.535 1.154-.507 1.791.028.64.262 1.303.764 1.809.508.511 1.247.82 2.201.82h7c.977 0 1.74-.305 2.272-.808a2.657 2.657 0 0 0 .819-2.084c.656.013 1.415-.129 2.134-.416 1.082-.432 2.169-1.233 2.731-2.488l.52-1.16-1.17.495c-1.89.799-3.4.258-4.294-.744-.92-1.031-1.198-2.553-.584-3.77Zm.408 7.065c.512.062 1.258-.023 2.018-.326a4.455 4.455 0 0 0 1.423-.896c-1.668.25-3.082-.366-4.011-1.408-.882-.989-1.322-2.361-1.117-3.68a2.433 2.433 0 0 0-.238.23c-.307.34-.598.84-.864 1.612.384.282.71.65.943 1.094.318.607.447 1.334.323 2.139a2.17 2.17 0 0 1 1.206.739c.126.152.232.319.317.496Zm-6.978-2.589c.635-1.44 1.948-1.774 2.997-1.394.53.192.983.566 1.249 1.073.262.5.358 1.165.117 1.977l-.2.67.7-.028c.466-.019.803.162 1.029.434.235.282.363.684.342 1.104-.02.416-.187.825-.508 1.129-.317.3-.822.533-1.584.533H8c-.73 0-1.2-.23-1.492-.524-.298-.3-.455-.713-.474-1.147-.019-.438.104-.863.321-1.166.21-.294.497-.463.845-.463h.563l-.066-.559c-.18-1.502 1.419-2.246 2.386-1.454l.51.417.265-.602Zm-4.805 7.274-1 2 .894.448 1-2-.894-.448Zm3 2 1-2 .894.448-1 2-.894-.448Zm-3 2 2-4 .894.448-2 4-.894-.448ZM13 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-1 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudRainSnowMoonSmall;
