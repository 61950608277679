import type { SVGProps } from "react";
const SvgPreviousMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.752 8.25v-.921l-.773.502-7.5 4.875-7.5 4.875-.645.419.645.42 7.5 4.874 7.5 4.875.773.502V8.25Zm-7.728 5.294 6.727-4.373V26.83l-6.727-4.373L15.169 18l6.855-4.456ZM6.75 7.75h-.5v20.5h5.5V7.75h-5Zm.5 19.5V8.75h3.5v18.5h-3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPreviousMedium;
