import type { SVGProps } from "react";
const SvgEv6Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-6-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm8.104 2.896h-2.206c-.206-.894-.714-1.341-1.524-1.341-.857 0-1.465.526-1.825 1.579-.148.428-.267 1.076-.357 1.944.35-.471.72-.81 1.111-1.016.397-.206.873-.31 1.428-.31 1.09 0 1.965.387 2.627 1.16.63.73.944 1.644.944 2.744 0 1.307-.423 2.357-1.27 3.15-.761.715-1.676 1.072-2.745 1.072-1.37 0-2.436-.516-3.198-1.547-.761-1.037-1.142-2.481-1.142-4.333 0-1.978.407-3.528 1.222-4.65.788-1.084 1.864-1.626 3.23-1.626 1.285 0 2.268.431 2.95 1.293.392.498.644 1.124.755 1.88Zm-3.928 2.658c-.555 0-.997.233-1.325.698-.296.413-.444.905-.444 1.476 0 .566.15 1.06.452 1.484.328.47.777.706 1.349.706.555 0 1.002-.225 1.34-.674.318-.424.477-.924.477-1.5 0-.566-.143-1.05-.429-1.452-.349-.492-.822-.738-1.42-.738Z"
    />
  </svg>
);
export default SvgEv6Negative;
