import type { SVGProps } from "react";
const SvgBulbOffPercentageSignalMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#bulb-off-percentage-signal-medium_svg__a)">
      <path
        stroke="currentColor"
        d="M8.851 25.5c-2.1-1.2-3.6-3.45-3.6-6 0-3.75 3-6.75 6.75-6.75a6.718 6.718 0 0 1 6.577 5.212M28.5 19.64 18 31.453M20.25 3c0 7.042 5.708 12.75 12.75 12.75M23.25 3A9.75 9.75 0 0 0 33 12.75M26.25 3A6.75 6.75 0 0 0 33 9.75M29.25 3A3.75 3.75 0 0 0 33 6.75M15.75 25.5v3.15l-3 1.05v1.05h-1.5V29.7l-3-1.05V25.5h7.5Zm4.5-5.203a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm6 7.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      />
    </g>
    <defs>
      <clipPath id="bulb-off-percentage-signal-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBulbOffPercentageSignalMedium;
