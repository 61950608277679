import type { SVGProps } from "react";
const SvgBusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.464 4.464C5.184 4.744 5 5.215 5 6v5h14V6c0-.785-.183-1.256-.464-1.536C18.256 4.184 17.785 4 17 4H7c-.785 0-1.256.183-1.536.464ZM5 18v-6h14v6H5ZM4.757 3.757C5.3 3.214 6.079 3 7 3h10c.921 0 1.7.214 2.243.757C19.786 4.3 20 5.079 20 6v3h1v2h-1v10h-3.999v-2H8v2H4V11H3V9h1V6c0-.921.214-1.7.757-2.243ZM17 19h2v1h-1.999v-1ZM5 20v-1h2v1H5ZM17 5H7v1h10V5ZM7 15a1 1 0 1 0 .002 2A1 1 0 0 0 7 15Zm9 1a1 1 0 1 1 2.002.001A1 1 0 0 1 16 16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBusSmall;
