import type { SVGProps } from "react";
const SvgIc59Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ic-59-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ic-59-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.22 4.2h4.057L8.259 15.8H4.186L9.22 4.2Zm6.728 0h14.395l-1.49 3.445h-10.28l-2.042 4.71h10.285l-1.49 3.445H10.93l5.017-11.6Zm28.144.034V6.29h-4.659l-.498 2.602c.559-.59 1.273-.886 2.143-.886.855 0 1.598.23 2.231.688.939.691 1.408 1.74 1.408 3.149 0 1.381-.501 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.224 0-2.207-.328-2.95-.982-.744-.659-1.137-1.547-1.18-2.665h2.271c.037.406.169.751.395 1.036.354.448.844.672 1.472.672.606 0 1.086-.218 1.44-.656.342-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.359-.401-.836-.602-1.432-.602-.728 0-1.295.327-1.7.981l-2.065-.031 1.1-6.447h6.351Zm1.464 8.662h2.183c.179.896.698 1.344 1.558 1.344.485 0 .878-.163 1.179-.49.305-.332.54-.847.704-1.543.105-.443.181-.946.229-1.51-.353.495-.717.849-1.092 1.06-.369.205-.82.308-1.352.308-.85 0-1.588-.248-2.215-.744a3.542 3.542 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.445-2.357 1.337-3.164.764-.696 1.682-1.044 2.752-1.044.66 0 1.268.148 1.828.443a3.961 3.961 0 0 1 1.407 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.295 2.99-.886 4.129-.77 1.482-1.935 2.222-3.496 2.222-1.039 0-1.899-.287-2.579-.862-.675-.575-1.065-1.347-1.17-2.317ZM49.4 5.91c-.496 0-.915.203-1.258.609-.343.411-.514.933-.514 1.566 0 .512.124.965.372 1.36.337.544.812.815 1.423.815a1.58 1.58 0 0 0 1.33-.664c.31-.417.466-.912.466-1.487 0-.607-.16-1.12-.482-1.543A1.606 1.606 0 0 0 49.4 5.91Z"
      />
    </g>
    <defs>
      <clipPath id="ic-59-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIc59Negative;
