import type { SVGProps } from "react";
const SvgQrcodeDisabledMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m30.95 31.507.197.197.196-.197h.157v-.157l.354-.354-.354-.353v-.023l-1.5-1.5v.023l-.64-.64h.022l-1.5-1.5h-.023l-1.5-1.5h.023l-.882-.883v.023l-.637-.637h.022L24 23.12v.023l-1.5-1.5v-.023l-1.5-1.5v.023l-1.5-1.5v-.022l-2.122-2.12h-.02l-1.501-1.502h.022l-1.5-1.5h-.022l-2.996-2.995h.023L8.378 7.5h-.021L6.859 6.002h.02L5.372 4.493H5.35l-.346-.347-.347.347H4.5v.159l-.202.202.202.202v2.449H6v-.95l1.5 1.5V9l.946.002.554.554v2.448h1.5V13.5h-3v-3H6V9H4.5v3H6v1.5h1.5V15h3v-1.498H12v-.947l1.501 1.502V22.5h8.443l1.503 1.503-3.946.003v1.5H18v3h1.5v-1.5H21v-1.503h1.5v3h3v-1.5H24v-2.446l3 3v.945h.945l1.5 1.5H28.5v1.5H30v.005h.95ZM20.443 20.999l-1.5-1.499H16.5v-2.443l-1.5-1.5V21h5.443Zm-8.999-8.997-.945-.947v.947h.945ZM10.5 5.378v-.887h4.502v1.502h-3.887l-.615-.615ZM21 15.879V15h-.879l-1.5-1.5h3.88v3.88l-1.5-1.5Zm-7.5-8.377v.003h-.873l1.497 1.497H16.5v-1.5H18v.003h1.5v3H21v-1.5l4.5-.003v-1.5H27v-1.5h-1.5V4.505H24v2.997h-1.5V6.005H21v1.5h-1.5v-3H18v1.497h-1.5v1.5h-3.001ZM30 10.505h1.5V9.002H30v1.502Zm-3 7.5v1.497h-2.377L24 18.879v-.877h3v-1.497h1.5v1.5H27Zm1.5 3v1.5H30v2.373l-1.5-1.5v-.873h-.873L27 21.878v-2.373h4.5v3H30v-1.5h-1.5Zm-3-9.003H27v1.5h-1.5v.003H24v-3h1.5v1.497Zm0 19.505H27v-3h-1.5v3ZM30 7.506h1.5v-3H30v-.003h-3v1.5h3v1.503Zm1.5 18.873-.877-.876h.877v.876ZM30 18.006h1.5v-3.002H30v-1.5l1.5-.002v-1.5h-3v3.003H30v3.001Zm-1.5-7.505h-3v-1.5h3v1.5Zm-12 21.002v-1.502h3v1.502h-3ZM18 12.005v-3h-1.5v1.497h-.878l1.503 1.503h.874Zm-4.5 16.498H15v-1.496h1.5v3H15v-.004h-1.5v-1.5Zm9 .004H21v3h1.5v-.005H24v-1.5h-1.5v-1.495ZM9 24.005v-1.5l3-.002v1.5h1.5v1.5h-3v-1.497H9Zm6-.001v1.5h3v-1.5h-3Zm12-7.502h-3v-1.5h3v1.5Zm-22.5 10.5H6v-1.5H4.5v1.5Zm4.5-7.5H7.5v-1.5H9v-1.495h3v1.5H9v1.495Zm-3-1.5h1.5v-1.5H6v-3H4.5v3H6v1.5Zm0 0H4.5v3.002H6v1.5l-1.5.003v1.5h3v-3.003H6V18Zm16.5-5.999H21v-1.502h1.5v1.502Zm-12 15.002h-3v-1.5h3v1.5Zm-.001 1.5v-1.499h3v1.5h-1.5v2.997h-1.5v-1.496h-1.5v-1.5h1.5v-.002Zm-4.5 1.502h3v1.5h-3v-.005h-1.5v-3h1.5v1.505Zm6-9.001h-3v-1.5h3v1.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQrcodeDisabledMedium;
