import type { SVGProps } from "react";
const SvgChairliftProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M33.248 4.226 11.9 8.496V10a2 2 0 0 1-1.6 1.994h-.002L5.2 13.016h-.001a1 1 0 0 0-.8 1l.001.01v13.71a1 1 0 0 0 1 1h1v-7.5h4v4.5h3.25v1H9.4v-4.5h-2v6.5h5.783l.146.243 2.25 3.75-.858.515-2.104-3.508H5.4a2 2 0 0 1-2-2V14.03A2 2 0 0 1 5 12.035h.002l5.098-1.02a1 1 0 0 0 .8-1v-1.32l-7.652 1.53-.196-.98 30-6 .196.98ZM7.4 17.236a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChairliftProfileMedium;
