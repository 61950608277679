import type { SVGProps } from "react";
const SvgBexNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="bex-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#EB0000"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m4.186 15.8 2.42-11.6h3.952c.6-.013 1.2.013 1.798.08.508.058.998.22 1.443.474.383.225.694.554.899.95.207.406.313.857.307 1.313a2.693 2.693 0 0 1-.536 1.673 3.268 3.268 0 0 1-1.656 1.073 2.6 2.6 0 0 1 1.392.874c.327.4.504.903.5 1.42a3.655 3.655 0 0 1-.59 1.982 3.327 3.327 0 0 1-1.633 1.35c-.694.274-1.643.411-2.847.411h-5.45Zm3.817-6.916h1.813a6.287 6.287 0 0 0 1.759-.174 1.472 1.472 0 0 0 1.073-1.416c.01-.266-.08-.526-.253-.728a1.262 1.262 0 0 0-.717-.38 9.593 9.593 0 0 0-1.254-.047H8.578l-.575 2.745Zm1.246 5.049H6.954l.63-3.03h2.84c.662 0 1.135.124 1.42.375.277.237.434.587.425.953-.002.325-.106.64-.296.902a1.64 1.64 0 0 1-.788.614c-.329.124-.974.186-1.936.186ZM15.34 15.8l2.413-11.6h8.572l-.402 1.939h-6.19l-.553 2.635h5.986l-.402 1.938h-5.986l-.725 3.15h6.726l-.41 1.938H15.34Zm9.096 0h3.076l1.593-1.804c.56-.616 1.09-1.26 1.585-1.93.147.4.446 1.062.899 1.985l.867 1.749h2.681l-2.847-5.491L37.582 4.2h-2.989l-1.822 2.065a81.311 81.311 0 0 0-1.616 1.93 1.64 1.64 0 0 0-.103-.213l-.773-1.606L29.152 4.2h-2.484l2.87 5.729-5.102 5.871Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBexNegative;
