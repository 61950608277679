import type { SVGProps } from "react";
const SvgChevronSmallEndLeftSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16.323 7.74-3.95 3.904-.36.356.36.355 3.948 3.904.703-.71L13.436 12l3.59-3.549-.703-.71ZM9 9v6H8V9z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronSmallEndLeftSmall;
