import type { SVGProps } from "react";
const SvgWarningLightSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 5V2h-1v3h1Zm-3.646.646-2-2-.707.707 2 2 .707-.707Zm9-1.293-2 2-.707-.707 2-2 .707.707ZM9 11.5a3.5 3.5 0 0 1 7 0V17H9v-5.5ZM8 17v-5.5a4.5 4.5 0 0 1 9 0V17h1.86l.115.342 1 3 .22.658H4.806l.219-.658 1-3L6.14 17H8Zm3-6v5h-1v-5h1Zm-4.806 9 .667-2H18.14l.667 2H6.194Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWarningLightSmall;
