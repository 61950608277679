import type { SVGProps } from "react";
const SvgSaVc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.782 3.133c1.5 0 2.56.369 3.2.795l.7-2.268c-1.22-.485-2.7-.66-4.1-.66-4.942 0-8.002 3.22-8.002 7.582 0 3.665 2.02 6.418 6.241 6.418 1.06 0 2.14-.096 3.161-.368l.44-2.598c-.8.465-1.76.833-2.94.833-2.462 0-3.98-2.017-3.98-4.285 0-3.18 2.178-5.449 5.28-5.449ZM3.8 1.233H1l1.84 13.534h3.341l7.802-13.534h-3.041l-5.801 10.53h-.04L3.8 1.232Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVc;
