import type { SVGProps } from "react";
const SvgComputerChipMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      d="M14.25 4.5v5.25m4.5-5.25v5.25m4.5-5.25v5.25m-9 18V33m4.5-5.25V33m4.5-5.25V33M4.5 23.25h5.25m-5.25-4.5h5.25m-5.25-4.5h5.25m18 9H33m-5.25-4.5H33m-5.25-4.5H33m-5.25 13.5h-18v-18h18v18Zm-4.5-13.5h-9v9h9v-9Z"
    />
  </svg>
);
export default SvgComputerChipMedium;
