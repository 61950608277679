import type { SVGProps } from "react";
const SvgWeightWheelSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm2.732 1a2 2 0 1 0-3.465 0H6.11l-.095.379-2 8L3.86 14h16.28l-.155-.621-2-8L17.89 5h-4.158ZM12 6H6.89l-1.75 7h13.72l-1.75-7H12Zm2.182 1c.545 0 1 .2 1.364.6.272.3.454.7.454 1.1h-.91c-.09-.3-.18-.5-.272-.6-.182-.1-.364-.2-.636-.2-.364 0-.636.2-.91.5-.181.2-.272.6-.272 1.1 0 .5.09.9.273 1.1.181.3.545.5.909.5a.98.98 0 0 0 .727-.3c.182-.2.273-.5.273-.8h-1v-.8H16v2.6h-.636l-.091-.5c-.364.4-.727.7-1.273.7a1.88 1.88 0 0 1-1.545-.8c-.273-.5-.455-1-.455-1.7 0-.8.182-1.4.636-1.8.364-.5.91-.7 1.546-.7ZM8.93 7v2.083L10.698 7h1.209l-1.768 1.98L12 12h-1.21L9.489 9.812l-.558.626V12H8V7h.93Zm8.079 7.908C16.575 17.222 14.5 19 12 19c-2.508 0-4.58-1.771-5.008-4.09l-.984.18C6.524 17.89 9.015 20 12 20c2.98 0 5.469-2.12 5.991-4.908l-.982-.184Zm-12.013.025C5.456 18.35 8.414 21 12 21c3.59 0 6.548-2.646 7.004-6.066l.992.132C19.473 18.984 16.09 22 12 22c-4.087 0-7.469-3.02-7.996-6.933l.992-.134ZM12 15c-.191 0-.438-.126-.631-.338l-.738.676c.305.333.8.662 1.369.662.582 0 1.056-.281 1.386-.682l-.772-.636c-.175.213-.382.318-.614.318Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWeightWheelSmall;
