import type { SVGProps } from "react";
const SvgUserHatLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24 2.977.146.045 11.5 3.5 1.575.48-1.576.476L31 8.89v5.747h-.002A9.835 9.835 0 0 1 32 19c0 2.451-.88 4.7-2.32 6.337C35.753 28.289 40 35.794 40 44.5v.5H8v-.5c0-8.706 4.237-16.211 10.32-19.163C16.88 23.7 16 21.45 16 19c0-1.58.362-3.067 1.002-4.362L17 14.637V8.892a5461.8 5461.8 0 0 0-4-1.218v10.19l1.18 2.002h.001l1.25 2.13.442.754H9.127l.442-.753 1.25-2.13v-.001L12 17.863V7.371L10.78 7l1.574-.48 11.5-3.5.146-.044ZM19.07 26.09a13.794 13.794 0 0 0-2.948 1.827c1.764 2.914 5.107 6.533 7.878 8.478 2.77-1.945 6.112-5.563 7.876-8.476a13.799 13.799 0 0 0-2.947-1.829C27.58 27.283 25.873 28 24 28c-1.872 0-3.578-.716-4.93-1.91Zm13.574 2.49c-1.92 3.108-5.464 6.892-8.366 8.836l-.278.187-.278-.187c-2.903-1.944-6.448-5.73-8.368-8.839a16.933 16.933 0 0 0-1.917 2.09c2.947 3.997 7.066 8.23 10.563 10.649 3.494-2.427 7.61-6.656 10.557-10.65a16.967 16.967 0 0 0-1.913-2.086Zm2.531 2.93c-3.06 4.093-7.298 8.404-10.896 10.826l-.278.187-.28-.187c-3.6-2.413-7.84-6.729-10.902-10.823-2.288 3.32-3.721 7.673-3.814 12.488h29.99c-.093-4.816-1.53-9.17-3.82-12.49ZM24 16c2.238 0 4.331-.36 6.068-.985A8.827 8.827 0 0 1 31 19c0 4.474-3.193 8-7 8s-7-3.526-7-8c0-1.455.34-2.813.932-3.986 1.737.625 3.83.986 6.068.986Zm.146-5.02L30 9.193v4.78a5.902 5.902 0 0 1-.146.054l-.008.003c-1.638.608-3.658.97-5.846.97s-4.208-.362-5.846-.969l-.008-.003a5.902 5.902 0 0 1-.146-.053V9.197c2.258.688 4.537 1.382 5.855 1.782l.146.044.145-.045Zm-6.25-2.86.005.002h.001c2.3.702 4.678 1.426 6.098 1.856C26.213 9.302 30.85 7.89 33.78 7L24 4.023 14.219 7l3.677 1.12ZM11.68 20.375v-.001l.819-1.389.819 1.389.808 1.377h-3.254l.808-1.376ZM21.6 22.8A2.99 2.99 0 0 0 24 24c.984 0 1.85-.47 2.4-1.2l-.8-.601a1.99 1.99 0 0 1-1.6.8 1.99 1.99 0 0 1-1.6-.8l-.8.602Zm4.9-4.8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-4 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserHatLarge;
