import type { SVGProps } from "react";
const SvgPlaySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7 4.13.752.438 6 3.5 6 3.5.74.432-.74.432-6 3.5-6 3.5L7 19.87V4.13Zm1 1.74v12.26l5.248-3.062L18.508 12l-5.26-3.068L8 5.87Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPlaySmall;
