import type { SVGProps } from "react";
const SvgNetworkLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 8c0-1.424 1.076-2.5 2.5-2.5s2.5 1.076 2.5 2.5-1.076 2.5-2.5 2.5S9.5 9.424 9.5 8ZM12 4.5c-1.976 0-3.5 1.524-3.5 3.5s1.524 3.5 3.5 3.5c.68 0 1.308-.18 1.837-.498l5.293 7.27C17.515 19.64 16.5 21.685 16.5 24c0 1.928.704 3.672 1.873 4.992l-4.566 4.454A4.54 4.54 0 0 0 11 32.5c-2.534 0-4.5 1.961-4.5 4.496S8.465 41.5 11 41.5c2.534 0 4.512-1.967 4.512-4.504a4.495 4.495 0 0 0-.99-2.85l4.563-4.451A7.489 7.489 0 0 0 24 31.5c2.864 0 5.322-1.553 6.588-3.875l4.14 1.916A4.701 4.701 0 0 0 34.5 31c0 2.547 1.953 4.5 4.5 4.5 2.547 0 4.5-1.953 4.5-4.5 0-2.547-1.953-4.5-4.5-4.5-1.668 0-3.081.837-3.858 2.13l-4.136-1.914c.32-.84.496-1.755.496-2.716a7.507 7.507 0 0 0-1.165-4.047l5.097-3.55A3.485 3.485 0 0 0 38 17.5c1.96 0 3.519-1.533 3.519-3.5a3.486 3.486 0 0 0-3.52-3.51c-1.965 0-3.5 1.549-3.5 3.51 0 .573.131 1.109.365 1.582l-5.119 3.565c-1.37-1.62-3.424-2.638-5.745-2.638a7.534 7.534 0 0 0-4.064 1.17l-5.325-7.314A3.5 3.5 0 0 0 15.5 8c0-1.976-1.524-3.5-3.5-3.5Zm26 6.99c-1.41 0-2.5 1.097-2.5 2.51 0 1.41 1.089 2.5 2.5 2.5 1.415 0 2.519-1.093 2.519-2.5a2.486 2.486 0 0 0-2.52-2.51Zm-14 6.019c-3.633 0-6.5 2.86-6.5 6.491 0 3.632 2.868 6.5 6.5 6.5s6.502-2.868 6.502-6.5c0-3.63-2.87-6.491-6.502-6.491ZM35.5 31c0-1.995 1.505-3.5 3.5-3.5s3.5 1.505 3.5 3.5-1.505 3.5-3.5 3.5-3.5-1.505-3.5-3.5ZM11 33.5c-1.983 0-3.5 1.515-3.5 3.496C7.5 38.98 9.018 40.5 11 40.5c1.984 0 3.512-1.522 3.512-3.504 0-1.98-1.527-3.496-3.512-3.496Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNetworkLarge;
