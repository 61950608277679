import type { SVGProps } from "react";
const SvgSn2Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="sn-2-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#sn-2-negative_svg__a)">
      <path
        stroke="#FFDE15"
        d="M1.792.5h55.416c.71 0 1.292.58 1.292 1.3v16.4c0 .72-.58 1.3-1.292 1.3H1.792C1.082 19.5.5 18.92.5 18.2V1.8c0-.72.581-1.3 1.292-1.3Z"
      />
      <path
        fill="#FFDE15"
        d="M12.798 7.659h-2.405c-.047-.564-.258-.994-.633-1.29-.369-.3-.888-.45-1.558-.45-.596 0-1.06.118-1.392.356-.327.232-.49.559-.49.98 0 .38.158.654.474.823.306.169 1.081.406 2.326.712 1.313.327 2.223.657 2.729.989.933.612 1.4 1.463 1.4 2.555 0 1.197-.47 2.14-1.408 2.832-.828.606-1.949.91-3.362.91-1.571 0-2.792-.36-3.662-1.076-.87-.718-1.321-1.738-1.353-3.062h2.515c.037.644.203 1.121.499 1.432.464.496 1.163.744 2.096.744.633 0 1.134-.124 1.503-.372.416-.285.625-.673.625-1.163s-.293-.849-.878-1.076c-.322-.126-1.081-.356-2.278-.688-.75-.205-1.335-.4-1.756-.585-.422-.185-.76-.39-1.013-.617-.59-.528-.886-1.255-.886-2.183 0-1.113.462-1.994 1.384-2.642.786-.554 1.807-.831 3.062-.831 1.102 0 2.025.219 2.768.657 1.07.627 1.635 1.642 1.693 3.045Zm11.715-3.425v11.565h-2.54l-4.84-7.879.031 7.879h-2.428V4.234h2.57l4.81 7.863-.032-7.863h2.429Zm9.785 9.508v2.056h-8.33c0-.627.1-1.226.301-1.795.206-.57.493-1.06.862-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.554-.385.941-.744 1.163-1.076.221-.332.332-.717.332-1.155 0-.506-.15-.915-.451-1.226-.295-.316-.685-.474-1.17-.474-1.192 0-1.807.857-1.844 2.57h-2.223V8.34c0-1.402.393-2.489 1.18-3.259.764-.749 1.753-1.123 2.965-1.123 1.076 0 1.975.306 2.698.918.785.664 1.178 1.558 1.178 2.681 0 .981-.313 1.825-.94 2.531-.207.232-.399.42-.578.562-.18.137-.652.472-1.416 1.005-.728.506-1.25.893-1.567 1.162a6.353 6.353 0 0 0-.87.926h5.427Z"
      />
    </g>
    <defs>
      <clipPath id="sn-2-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSn2Negative;
