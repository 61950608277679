import type { SVGProps } from "react";
const SvgUserHatSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12 2.973.158.053 4.5 1.5L18.083 5l-1.425.473L15 6.025v1.921c.319.605.5 1.31.5 2.053 0 .946-.3 1.849-.813 2.556C17.37 13.927 19 17.085 19 20.5v.5h-.5l-13-.006H5v-.5c0-3.707 1.711-6.659 4.308-7.937A4.297 4.297 0 0 1 8.613 11H5.4l.413-.743L7 8.12V5.36L5.916 5l1.426-.474 4.5-1.5.158-.053ZM10.04 13.32c-2.231.948-3.877 3.37-4.028 6.674l11.976.004c-.156-3.05-1.73-5.66-4.031-6.684a3.15 3.15 0 0 1-3.917.007ZM9.79 8.6c1.26.793 2.94.948 4.418.003.186.418.293.894.293 1.397 0 1.71-1.186 3-2.51 3-1.319 0-2.49-1.286-2.49-3 0-.505.105-.982.29-1.4ZM9 7.749v.188c-.197.375-.34.787-.422 1.224L8 8.12V5.692l1 .332V7.75Zm1-1.392v1.165c1.077.874 2.674 1.072 4-.002V6.357l-1.842.612-.158.053-.158-.053L10 6.357ZM9.084 5 12 5.97 14.916 5 12 4.027l-2.916.972ZM7.5 9.28l.4.721h-.8l.4-.72Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserHatSmall;
