import type { SVGProps } from "react";
const SvgArrowLongUpSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.144 4.156-3.14 3.142.706.707 2.288-2.288V20h1V5.715l2.296 2.29.706-.708-3.15-3.142-.353-.352-.353.353Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongUpSmall;
