import type { SVGProps } from "react";
const SvgBluetoothLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24 2.293.854.853 10 10 .353.354-.353.354-9.647 9.646 9.647 9.646.353.354-.353.354-10 10-.854.853v-20l-9.146 9.147-.707-.708 9.646-9.646-9.646-9.646.707-.708L24 22.293v-20Zm1 22.414 8.793 8.793L25 42.293V24.707Zm0-2.414V4.707l8.793 8.793L25 22.293Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBluetoothLarge;
