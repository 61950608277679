import type { SVGProps } from "react";
const SvgDocumentSoundMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m18.944 6.246.146.147 7.514 7.509.146.146v15.707H9.25v-23.5h.5l8.986-.009h.208ZM10.25 7.255v21.5h15.5v-14h-7.5v-.498l-.013-7.01-7.987.008Zm8.989.7 5.803 5.8H19.25l-.01-5.8Zm.585 8.795h-.207l-.146.146-2.854 2.854h-2.873v4h2.873l2.853 2.862.148.148.209-.001 1.92-.01.497-.002V16.75h-2.42Zm-2.646 3.853 2.854-2.853h1.212v8.002l-1.213.006-2.853-2.861-.146-.147h-2.287v-2h2.287l.147-.147Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDocumentSoundMedium;
