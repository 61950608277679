import type { SVGProps } from "react";
const SvgThreeAdultsLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 12.5a1.5 1.5 0 1 1 3.001.001A1.5 1.5 0 0 1 11 12.5Zm1.5-2.5a2.5 2.5 0 1 0 .002 5.002A2.5 2.5 0 0 0 12.5 10Zm11 1a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 23.5 11ZM21 12.5a2.5 2.5 0 1 1 5.002.002A2.5 2.5 0 0 1 21 12.5ZM35.5 11a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 35.5 11ZM33 12.5a2.5 2.5 0 1 1 5.002.002A2.5 2.5 0 0 1 33 12.5ZM33 38v1.999h1V38h3v1.999h1V38h2.053l-.055-.55-1-9.999-.045-.45h-6.906l-.044.45-1 10-.055.549H33Zm-.924-21h6.848l.07.418 2 12-.987.164L38.077 18h-5.153l-1.93 11.582-.987-.164 2-12 .07-.418Zm-.023 20 .9-8.999h5.094l.9 8.999h-6.895ZM22 39.999V28.001h3v11.998h1V28.001h1V29.5h1V17h-9v12.5h1v-1.499h1v11.998h1ZM27 18v9.001h-7V18h7ZM11 28.001v11.998h-1V28.001H9V29.5H8V17h9v12.5h-1v-1.499h-1v11.998h-1V28.001h-3Zm5-1V18H9v9.001h7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgThreeAdultsLarge;
