import type { SVGProps } from "react";
const SvgAppleBagMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.982 4.75h12.692l.07.417 3 18 .052.32-.269.179-4.5 3-.126.084H19.5v-1h7.785L28.616 7.1l-1.95 2.926-.148.223H16.15l-1.415 5.622-.97-.244 1.354-5.379h-1.803l.518-.777 3-4.5.148-.222Zm14.722 18.263-3.383 2.255L29.45 9.484l2.255 13.529ZM28.316 5.75H17.518l-2.334 3.5h10.798l2.334-3.5ZM8.629 18.387c-.294-.086-.65-.137-1.123-.137-.669-.015-1.772-.029-2.692.67-.946.72-1.564 2.078-1.564 4.473 0 2.225.37 4.149 1.067 5.537.698 1.39 1.775 2.32 3.183 2.32h.01a4.994 4.994 0 0 0 1.972-.412l.232-.1c.32-.138.516-.223.786-.238.27.015.466.1.786.238l.232.1a4.994 4.994 0 0 0 1.973.412h.009c1.408 0 2.485-.93 3.183-2.32.697-1.388 1.067-3.312 1.067-5.537 0-2.395-.618-3.752-1.564-4.472-.92-.7-2.023-.686-2.692-.671-.473 0-.829.05-1.123.137a3.05 3.05 0 0 0-.681.303l-.014.008a2.213 2.213 0 0 1-.436.209 1.746 1.746 0 0 1-.27.061c-.157-1.392-.92-2.306-1.67-2.868a5.332 5.332 0 0 0-1.63-.822 2.068 2.068 0 0 0-.033-.009l-.01-.002-.003-.001h-.002l-.122.484-.122.485h.002l.017.006.079.023A4.333 4.333 0 0 1 8.7 16.9c.562.422 1.11 1.067 1.26 2.057a1.631 1.631 0 0 1-.2-.05 2.214 2.214 0 0 1-.436-.21l-.014-.007a3.051 3.051 0 0 0-.68-.303ZM4.25 23.393c0-2.248.58-3.227 1.17-3.676.614-.467 1.382-.483 2.069-.468H7.5c.405 0 .664.044.847.098.182.053.31.122.47.212l.03.018c.154.087.35.198.607.281.278.09.607.142 1.046.142.439 0 .768-.052 1.046-.142.258-.083.453-.194.607-.281l.03-.018c.16-.09.288-.16.47-.213.183-.053.442-.096.847-.096h.011c.687-.016 1.455 0 2.07.467.59.449 1.169 1.428 1.169 3.677 0 2.131-.357 3.885-.96 5.087-.602 1.197-1.398 1.767-2.285 1.768a3.994 3.994 0 0 1-1.6-.333 11.16 11.16 0 0 1-.178-.077c-.334-.148-.724-.32-1.207-.339h-.04c-.483.02-.873.191-1.207.339-.061.027-.12.053-.178.077a3.994 3.994 0 0 1-1.6.334c-.887-.002-1.683-.572-2.284-1.77-.604-1.2-.961-2.955-.961-5.087Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAppleBagMedium;
