import type { SVGProps } from "react";
const SvgDriverlessBusProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.75 7.749a2 2 0 0 0-2 2v15.5h3.3c.454.89 1.38 1.5 2.45 1.5 1.07 0 1.996-.61 2.45-1.5h10.1a2.75 2.75 0 0 0 4.9 0h3.3v-15.5a2 2 0 0 0-2-2H6.75Zm21.489 16.5h2.011v-14.5a1 1 0 0 0-1-1H6.75a1 1 0 0 0-1 1v14.5h2.011a2.75 2.75 0 1 1 5.478 0h9.522a2.75 2.75 0 1 1 5.478 0ZM8.25 10.749h-.5v8.5h5.5v-8.5h-5Zm.5 7.5v-6.5h3.5v6.5h-3.5Zm1.75 4a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Zm15 0a1.75 1.75 0 1 0 .002 3.502 1.75 1.75 0 0 0-.002-3.502Zm-10.25-11.5h5.5v8.5h-5.5v-8.5Zm1 1v6.5h3.5v-6.5h-3.5Zm7-1h-.5v8.5h5.5v-8.5h-5Zm.5 7.5v-6.5h3.5v6.5h-3.5ZM9 29.75c-1.52 0-2.75-1.23-2.75-2.75h1c0 .967.783 1.75 1.75 1.75v1ZM3.25 27A5.749 5.749 0 0 0 9 32.749v-1a4.749 4.749 0 0 1-4.75-4.75h-1ZM27 28.749a1.75 1.75 0 0 0 1.75-1.75h1a2.75 2.75 0 0 1-2.75 2.75v-1Zm4.75-1.75a4.749 4.749 0 0 1-4.75 4.75v1a5.749 5.749 0 0 0 5.75-5.75h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDriverlessBusProfileMedium;
