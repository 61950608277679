import type { SVGProps } from "react";
const SvgSaVp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.02 7.478h-1l.9-4.272h.88c1.2 0 2.341.3 2.341 1.725 0 1.745-1.58 2.547-3.12 2.547ZM12.62 15h2.8l1.12-5.315h1.62c3.122 0 5.901-1.666 5.901-5.054 0-3.49-3.54-3.631-6.12-3.631h-2.36L12.62 15ZM3.8 1H1l1.84 14h3.341l7.801-14h-3.04L5.141 11.89h-.04L3.801 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaVp;
