import type { SVGProps } from "react";
const SvgUserGroupSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 7c.438 0 .837.17 1.171.471l.02.019c.039.035.076.073.113.112.419.447.696 1.115.696 1.9a1.406 1.406 0 0 1-.015.206l-.007.064-.006.055v.001c-.133 1.314-.992 2.173-1.972 2.173-.963 0-1.81-.829-1.964-2.104l-.008-.07-.011-.105c-.01-.089-.017-.15-.017-.22 0-.853.328-1.569.809-2.012l.032-.03c.332-.294.726-.46 1.16-.46Zm-2.408 4.6a3.75 3.75 0 0 1-.4-.882 2.706 2.706 0 0 1-2.947-.353c-1.931 1.23-3.053 3.147-3.223 5.632h3.335c.526-1.837 1.624-3.335 3.235-4.397Zm-3.46 5.397c-.079.48-.123.979-.131 1.495l-.009.509h12.045L18 18.465A12.639 12.639 0 0 0 17.812 17h4.225L22 16.465c-.204-2.871-1.25-5.274-3.596-6.843.382-.6.597-1.343.597-2.121 0-1.85-1.213-3.502-3-3.502-1.377 0-2.408.991-2.808 2.284a2.638 2.638 0 0 0-2.384 0C10.408 4.99 9.378 3.998 8 3.998 6.214 4 5 5.651 5 7.501c0 .77.21 1.505.584 2.1C3.298 11.114 2.05 13.5 2 16.49l-.008.508h4.141ZM16 11.001c.67 0 1.26-.232 1.735-.62 1.965 1.263 2.94 3.18 3.217 5.62h-3.381c-.528-1.786-1.534-3.288-3.176-4.382.174-.275.313-.579.413-.902.358.18.76.284 1.192.284Zm-1.94-4.056C14.65 7.59 15 8.5 15 9.5c0 .054-.003.109-.006.162.297.217.638.338 1.006.338 1.063 0 2-1.015 2-2.5 0-1.486-.937-2.502-2-2.502-.922 0-1.737.762-1.941 1.946ZM12 13c-.678 0-1.272-.24-1.748-.637-1.936 1.23-3.06 3.149-3.23 5.637h9.93c-.278-2.442-1.254-4.36-3.222-5.622A2.686 2.686 0 0 1 12 13ZM8 5C6.938 5 6 6.015 6 7.501c0 1.485.937 2.5 2 2.5.369 0 .709-.12 1.006-.337A2.23 2.23 0 0 1 9 9.5c0-1.001.35-1.91.941-2.556C9.738 5.76 8.923 4.999 8 4.999Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserGroupSmall;
