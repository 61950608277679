import type { SVGProps } from "react";
const SvgHandshakeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 6v7.994l-.257.143-8.1 4.5-.01.006-.232-.443.232.443h-.001l-.002.001-.003.002-.01.004a1.464 1.464 0 0 1-.124.057 3.996 3.996 0 0 1-2.65.12 3.432 3.432 0 0 1-.46-.177l-.01-.004-.003-.002h-.001v-.001h-.001l.232-.443-.232.443-.011-.006-8.1-4.5L2 13.994V6l.743.413 2.513 1.395 3.696-1.162.014-.005.015-.003c1.15-.287 2.18-.053 3.073.594.889-.608 1.856-.945 2.897-.609l.003.001 3.79 1.185 2.513-1.396L22 6ZM11.229 7.884c-.604-.36-1.257-.46-1.992-.28L5.35 8.828l-.205.064-.188-.104L3 7.699v5.707l7.833 4.352.008.003.056.025a2.995 2.995 0 0 0 1.969.085 2.489 2.489 0 0 0 .294-.11l.007-.003 4.976-2.765-3.5-3.633-4.218 2.087h-.001c-.814.408-1.66.187-2.136-.382-.488-.58-.533-1.454.078-2.186l.007-.009.008-.008c.2-.217.397-.435.593-.652.77-.85 1.52-1.677 2.255-2.326Zm7.818 6.607L21 13.405V7.7l-1.957 1.087-.188.104-.204-.064-4-1.25-.005-.001c-.743-.24-1.515.038-2.462.808-.772.627-1.564 1.5-2.446 2.473l-.611.672c-.305.37-.236.7-.073.894.174.208.516.334.923.13l.002-.001 4.55-2.25.327-.162.254.263 3.937 4.088Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandshakeSmall;
