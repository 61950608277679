import type { SVGProps } from "react";
const SvgRe9Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="re-9-negative_svg__color-immutable"
    {...props}
  >
    <g fill="#fff" clipPath="url(#re-9-negative_svg__a)">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.982 1.982 0 0 1-.197-.625c-.037-.253-.087-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.708-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.17-.949-.514-1.202-.342-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm1.25 8.662h2.183c.18.896.699 1.344 1.559 1.344.485 0 .878-.163 1.178-.49.306-.332.54-.847.704-1.543.106-.443.182-.946.23-1.51-.354.495-.718.849-1.092 1.06-.37.205-.82.308-1.353.308-.849 0-1.587-.248-2.215-.744a3.543 3.543 0 0 1-1.028-1.352 4.444 4.444 0 0 1-.364-1.804c0-1.302.446-2.357 1.337-3.164.765-.696 1.682-1.044 2.753-1.044.66 0 1.268.148 1.827.443a3.961 3.961 0 0 1 1.408 1.258c.67.965 1.005 2.32 1.005 4.066 0 1.613-.296 2.99-.886 4.129-.77 1.482-1.936 2.222-3.497 2.222-1.038 0-1.898-.287-2.578-.862-.675-.575-1.065-1.347-1.17-2.317Zm3.844-6.985c-.495 0-.915.203-1.257.609-.343.411-.515.933-.515 1.566 0 .512.124.965.372 1.36.338.544.812.815 1.424.815a1.58 1.58 0 0 0 1.33-.664c.31-.417.466-.912.466-1.487 0-.607-.161-1.12-.483-1.543a1.606 1.606 0 0 0-1.337-.656Z" />
    </g>
    <defs>
      <clipPath id="re-9-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe9Negative;
