import type { SVGProps } from "react";
const SvgFogSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 5h5V4h-5v1ZM8 7h9V6H8v1Zm9 2H7V8h10v1ZM6 11h3v-1H6v1Zm13 0h-9v-1h9v1ZM7 13.01 19 13v-1l-12 .01v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFogSmall;
