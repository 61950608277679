import type { SVGProps } from "react";
const SvgFreightWagonSwitzerlandSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.517 4.116-.388-.323-.32.39-.802.98-3.026.78-.493-.252-.268-.137-.247.173L5.79 7.26l-.82.576.953.31.232.074-2.05 2.15-.084.088-.034.118-.966 3.346-.239.826.836-.201 1.914-.46.066-.016.06-.033.532-.294 1.737 1.573.166.15.223-.023 2.824-.3.179-.02.126-.128 1.224-1.253 1.576 2.06.487.638.357-.718 1.229-2.466 2.89.714.703.174-.087-.72-.137-1.12.802.019.539.013-.028-.539-.14-2.766-.013-.24-.194-.14-1.202-.867-.455-.33-.274.491-.198.354-.695-.199.415-1.17.165-.466-.464-.17L14.7 5.104l-1.183-.988ZM7.219 7.515l-.035-.012L8.3 6.72l.393.2.17.087.183-.047 3.368-.868.158-.041.104-.127.586-.716.863.72.066.056.082.03 2.861 1.05-.428 1.206-.178.501.512.147 1.588.453.381.109.193-.346.117-.209.552.398.101 1.989-.841-.02-.579-.014.07.574.12.975-2.575-.636-.39-.096-.178.358-1.05 2.107-1.439-1.882-.35-.459-.404.413-1.503 1.537-2.423.258-1.835-1.663-.265-.24-.313.173-.786.434-1.013.244.694-2.403 2.513-2.636.549-.575-.756-.245ZM3 19v1H2v-3h1v1h1v-1h16v1h1v-1h1v3h-1v-1h-1v1h-1a2 2 0 0 1-4 0H9a2 2 0 0 1-4 0H4v-1H3Zm4 2a1 1 0 0 1-1-1h2a1 1 0 0 1-1 1Zm9-1a1 1 0 0 0 2 0h-2ZM5 18.5V18h14v1H5v-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFreightWagonSwitzerlandSmall;
