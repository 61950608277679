import type { SVGProps } from "react";
const SvgSaNv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.542 1H4.001L1 15h2.681l2.22-10.73h.04L8.862 15h3.6l3.021-14h-2.64l-2.281 10.63h-.04L7.542 1Zm12.34 0h-2.8l1.84 14h3.34l7.801-14h-3.04l-5.8 10.892h-.04L19.882 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaNv;
