import type { SVGProps } from "react";
const SvgLocationPinPulseSurroundingAreaMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 12.023a8.75 8.75 0 0 1 17.5 0v.004c-.013 1.458-.473 2.755-1.167 3.97l-.005.01-.007.01-7.125 11.249-.422.667-.422-.667-7.125-11.248-.007-.01c-.711-1.185-1.16-2.455-1.22-3.966v-.02Zm1.678 2.728a8.5 8.5 0 0 0 .397.737l6.699 10.576 6.696-10.572a9.36 9.36 0 0 0 .39-.757h-3.669l-.138-.275-.987-1.963-1.848 4.93-.564 1.503-.39-1.558-1.86-7.442-1.686 4.496-.122.325h-2.917Zm-.388-1h2.613l2.128-5.676.564-1.504.39 1.558 1.86 7.443 1.686-4.497.402-1.07.513 1.021 1.362 2.71h3.43a6.193 6.193 0 0 0 .262-1.716 7.75 7.75 0 0 0-15.5-.007c.025.62.125 1.193.29 1.738ZM6.203 24.68c1.735-.767 4.152-1.35 6.944-1.666l.112.994c-2.733.31-5.042.875-6.652 1.586-.807.357-1.413.738-1.81 1.122-.395.382-.547.729-.547 1.033 0 .386.25.845.915 1.343.656.491 1.638.956 2.898 1.355 2.514.797 6.023 1.302 9.937 1.302 3.915 0 7.424-.505 9.938-1.302 1.26-.4 2.241-.864 2.897-1.355.666-.498.915-.957.915-1.343 0-.302-.15-.645-.539-1.025-.39-.38-.987-.759-1.782-1.113-1.587-.708-3.865-1.274-6.564-1.588l.116-.994c2.758.322 5.143.905 6.855 1.668.855.381 1.567.819 2.073 1.311.507.494.841 1.08.841 1.74 0 .848-.544 1.568-1.316 2.145-.78.584-1.88 1.09-3.194 1.507-2.636.836-6.251 1.348-10.24 1.348-3.988 0-7.604-.512-10.24-1.348-1.314-.417-2.413-.923-3.194-1.507-.772-.577-1.316-1.297-1.316-2.144 0-.667.34-1.256.853-1.753.512-.495 1.234-.934 2.1-1.316Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationPinPulseSurroundingAreaMedium;
