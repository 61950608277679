import type { SVGProps } from "react";
const SvgEnvelopeOpenSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.328 3.622 12 3.338l-.328.284-7.5 6.5-.172.15V22h16V10.27l-.172-.15-7.5-6.5ZM5 11.706v8.589l4.304-4.293L5 11.706ZM5.71 21h12.585l-6.276-6.293L5.71 21ZM19 20.29v-8.583l-4.286 4.286L19 20.29Zm-.233-9.764L12 4.66l-6.766 5.865 4.778 4.77 1.655-1.65.354-.353.353.354 1.634 1.638 4.759-4.76Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEnvelopeOpenSmall;
