import type { SVGProps } from "react";
const SvgGondolaProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.25 9.447 19.894-5.968-.288-.958-30 9 .288.958 9.106-2.732v5.503h-4.5a1.5 1.5 0 0 0-1.5 1.5v10.838c0 .16.026.321.077.474l1.22 3.662a1.5 1.5 0 0 0 1.424 1.026h7.558a1.5 1.5 0 0 0 1.423-1.026l1.221-3.662a1.5 1.5 0 0 0 .077-.474V16.75a1.5 1.5 0 0 0-1.5-1.5h-4.5V9.447Zm-4 8.803h7v7h-7v-7Zm1 1v5h5v-5h-5Zm-3-2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10.838a.5.5 0 0 1-.026.158l-1.22 3.662a.5.5 0 0 1-.475.342H8.971a.5.5 0 0 1-.475-.342l-1.22-3.662a.5.5 0 0 1-.026-.158V16.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGondolaProfileMedium;
