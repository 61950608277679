import type { SVGProps } from "react";
const SvgSignParkingSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 3.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-7ZM8.5 2A1.5 1.5 0 0 0 7 3.5v7A1.5 1.5 0 0 0 8.5 12H11v10h1V12h2.5a1.5 1.5 0 0 0 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5h-6ZM10 4h2c.57 0 1.094.144 1.471.519.379.375.529.899.529 1.481 0 .578-.145 1.102-.523 1.478-.376.376-.9.52-1.476.522H11v2h-1V4Zm1 3h1c.421 0 .647-.106.771-.23.123-.123.229-.348.229-.77 0-.421-.107-.647-.233-.771C12.641 5.104 12.414 5 12 5h-1v2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSignParkingSmall;
