import type { SVGProps } from "react";
const SvgBuildingTreeLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.5 5H9v3h2v9.619h1V8h30v33H12v-3.428a5.83 5.83 0 0 0 2.83-2.46 3.054 3.054 0 0 0 4.806-3.032 4.748 4.748 0 0 0-3.685-8.608 5.266 5.266 0 1 0-9.24 3.806A5.808 5.808 0 0 0 11 37.877V41H3.999v1h40v-1h-1V8h2V5H9.5ZM12 36.472v-4.236l3.774-2.823-.6-.801L12 30.987V27.43h-1v5.795l-1.007-1.008-.707.707L11 34.638v2.213a4.808 4.808 0 0 1-3.257-9.002l.68-.323-.562-.502a4.266 4.266 0 1 1 7.093-2.9l-.054.8.742-.305a3.748 3.748 0 0 1 3.18 6.779l-.373.2.134.4a2.054 2.054 0 0 1-3.52 1.974l-.52-.614-.32.74A4.828 4.828 0 0 1 12 36.471ZM10 7V6h34v1H10Zm22 24h7.002l-.002.502-.018 6-.002.498H32v-7Zm1 1v5h4.983L38 32h-5Zm-.5-11H32v7h6.98l.002-.498.018-6 .002-.502H32.5Zm.5 6v-5h4.998l-.014 5H33Zm-5.003-5H17v-1h12.003l-.003.503-.036 6-.003.497h-5.97v-1h4.976l.03-5ZM21.95 32h6.047l-.03 5H15v1h13.961l.003-.497.036-6 .003-.503H21.95v1ZM32.5 11H32v7h6.98l.002-.498.018-6 .002-.502H32.5Zm.5 6v-5h4.998l-.014 5H33Zm-18-6h14.003l-.003.503-.036 6-.003.497H15v-7Zm1 1v5h11.967l.03-5H16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBuildingTreeLarge;
