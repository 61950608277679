import type { SVGProps } from "react";
const SvgSaTf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.261 1H1.479L1 3.206h3.94L2.4 15h2.92L7.861 3.206h3.94L12.261 1Zm9.44 0H14l-2.94 14h2.8l1.281-6.137h4.68l.442-2.207H15.6l.74-3.45h4.881L21.701 1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaTf;
