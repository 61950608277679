import type { SVGProps } from "react";
const SvgTicketStarMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.25 6.25h29.5v7h-.5c-2.274 0-4 1.726-4 4 0 2.273 1.726 4 4 4h.5v7H3.25v-22Zm1 1v20h27.5v-5.023c-2.578-.24-4.5-2.32-4.5-4.977 0-2.658 1.922-4.738 4.5-4.977V7.25H4.25Zm20 .737V7.5h1v.487h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.95v-.975h1v.975h-1Zm0 1.463v-.488h1V27h-1ZM13.97 10.327l-.47-1.28-.47 1.28L11.5 14.5H6.1l1.083.887 3.023 2.474-1.674 4.464-.544 1.451 1.29-.86L13.5 20.1l4.223 2.816 1.268.845-.521-1.432-1.675-4.607 3.01-2.327 1.16-.896H15.5l-1.53-4.172Zm-1.65 4.845 1.18-3.22 1.18 3.22.12.327h3.235l-2.14 1.655-.29.223.125.343 1.28 3.518-3.233-2.154-.277-.185-.277.184-3.21 2.14 1.255-3.348.126-.335-.277-.227L8.9 15.5h3.3l.12-.328Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketStarMedium;
