import type { SVGProps } from "react";
const SvgIr2Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ir-2-negative_svg__color-immutable"
    {...props}
  >
    <g clipPath="url(#ir-2-negative_svg__a)">
      <path
        fill="#EB0000"
        fillRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M9.388 4.2h4.042l-5 11.6H4.37L9.388 4.2Zm16.704 8.166a.922.922 0 0 0 .843-.553L30.216 4.2H16.13l-5.001 11.6h4.025l3.786-8.69h5.973l-1.197 2.775-5.209-.019L22.71 15.8h4.582l-3.114-3.434h1.915Zm18.578 1.376v2.056h-8.33c0-.627.1-1.226.3-1.795.206-.57.494-1.06.863-1.471.285-.317.617-.625.997-.926.385-.3 1.147-.851 2.286-1.653.553-.385.941-.744 1.163-1.076.22-.332.332-.717.332-1.155 0-.506-.15-.915-.451-1.226-.295-.316-.686-.474-1.17-.474-1.193 0-1.807.857-1.844 2.57h-2.223V8.34c0-1.402.393-2.489 1.179-3.259.764-.749 1.753-1.123 2.966-1.123 1.076 0 1.975.306 2.698.918.785.664 1.178 1.558 1.178 2.681 0 .981-.314 1.825-.941 2.531a4.65 4.65 0 0 1-.578.562c-.179.137-.65.472-1.416 1.005-.727.506-1.25.893-1.566 1.162a6.348 6.348 0 0 0-.87.926h5.426Z"
      />
    </g>
    <defs>
      <clipPath id="ir-2-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIr2Negative;
