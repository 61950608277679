import type { SVGProps } from "react";
const SvgUploadMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.75 9.237h22V13.5h-1v-3.263h-20v3.25h-1v-4.25Zm11 3.118.355.357 5.862 5.904-.71.705-5.007-5.044v11.226h-1V14.279l-5 5.042-.71-.705 5.855-5.904.355-.357Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUploadMedium;
