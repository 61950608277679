import type { SVGProps } from "react";
const SvgPenSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.353 4.356-.354-.353-.353.353-2.29 2.29-.353.354.353.353 2.29 2.29.355.355.353-.355 2.29-2.3.353-.353-.354-.353-2.29-2.281ZM15.417 7l1.584-1.584 1.582 1.576-1.584 1.59L15.417 7Zm-2.063 1.357L13 8.003l-.354.354-7.5 7.5-.146.146V19h2.997l.147-.146 7.5-7.501.353-.354-.354-.353-2.29-2.29ZM6 16.417l7-7L14.583 11l-7 7.001H6v-1.583Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPenSmall;
