import type { SVGProps } from "react";
const SvgSaTk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.261 1H1.479L1 3.206h3.94L2.4 15h2.92L7.861 3.206h3.94L12.261 1Zm3.419 5.496L16.86 1h-2.8l-3.001 14h2.8l1.522-7.12L19.26 15h3.34l-4.5-7.622L25.163 1H21.26l-5.54 5.496h-.04Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaTk;
