import type { SVGProps } from "react";
const SvgPlatformSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 3h-1v15h1v1H11v3h-1v-3H9v-1h11V3h-3v1h1v4h-8V4h1V3H9V2h13v1ZM12 3v1h4V3h-4ZM5 15a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3-2h5V9.5c0-.55-.2-.894-.506-1.118C6.166 8.142 5.66 8 5 8H2V7h.576l.334-2H2V4h3v1H3.924L3.59 7H5c.78 0 1.524.164 2.086.576C7.67 8.004 8 8.66 8 9.5V19H6.258l.429 1H8v1h-.885l.345.803-.92.394L6.027 21H2v-1h3.599l-.429-1H2v-1h5v-4H2v-1Zm9-6V5h6v2h-6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPlatformSmall;
