import type { SVGProps } from "react";
const SvgControlsLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.5 7a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-4.473 3a4.5 4.5 0 0 1 8.946 0H45v1H17.973a4.5 4.5 0 0 1-8.946 0H3v-1h6.027ZM36.5 20a4.5 4.5 0 0 0-4.472 4H3v1h29.028a4.5 4.5 0 0 0 8.944 0H45v-1h-4.028a4.5 4.5 0 0 0-4.472-4ZM17.973 39H45v-1H17.973a4.5 4.5 0 0 0-8.946 0H3v1h6.027a4.5 4.5 0 0 0 8.946 0ZM36.5 21a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7ZM10 38.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgControlsLarge;
