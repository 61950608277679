import type { SVGProps } from "react";
const SvgSaAf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 15 9.463 1h3.101l2.539 14h-2.8l-.48-3.21H5.882L4.102 15H1Zm9.563-11.513h-.08L7.122 9.584h4.32l-.88-6.097ZM16.902 15l2.94-14h7.702l-.48 2.207h-4.881l-.74 3.449h4.66l-.44 2.207h-4.68L19.703 15h-2.801Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSaAf;
