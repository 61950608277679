import type { SVGProps } from "react";
const SvgAvatarPoliceLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m24.5 1.982.129.035 15 4 .524.14-.182.51-2.115 5.931-.004.073a118.895 118.895 0 0 1-.116 1.9 148.272 148.272 0 0 1-.386 4.661c-.174 1.744-.396 3.566-.67 5.093a21.01 21.01 0 0 1-.457 2.043c-.164.57-.356 1.073-.591 1.415v.001A21.72 21.72 0 0 1 33 30.944v3.242l8.317 4.003.012.006.012.007A7.06 7.06 0 0 1 45 44.379V46h-1v-1.618a6.06 6.06 0 0 0-3.13-5.297l-8.072-3.886A8.83 8.83 0 0 1 25 40.994V46h-1v-5.006a8.83 8.83 0 0 1-7.798-5.796L8.13 39.084A6.06 6.06 0 0 0 5 44.38V46H4v-1.62a7.06 7.06 0 0 1 3.66-6.178l.011-.007.012-.006L16 34.186v-3.204a21.514 21.514 0 0 1-2.66-3.197c-.239-.338-.43-.84-.594-1.405a20.511 20.511 0 0 1-.455-2.039c-.272-1.526-.49-3.349-.66-5.095a147.263 147.263 0 0 1-.483-6.576l-.004-.071L9.03 6.668l-.182-.511.524-.14 15-4 .129-.035ZM17 34.428a7.83 7.83 0 0 0 15 0v-2.572C29.032 34.36 26.2 35 24.48 35c-1.712 0-4.53-.634-7.48-3.11v2.538ZM12.166 13l.002.03a150.34 150.34 0 0 0 .459 6.119c.168 1.736.383 3.529.649 5.017.132.744.276 1.403.43 1.936.158.546.314.914.453 1.11l.003.005C18.072 32.907 22.402 34 24.48 34c2.078 0 6.398-1.093 10.329-6.784.14-.203.295-.577.453-1.125.155-.536.3-1.198.433-1.943.268-1.49.487-3.282.66-5.015a147.396 147.396 0 0 0 .476-6.107l.001-.026h-1.92a10.58 10.58 0 0 1-.55.605A13.5 13.5 0 0 1 24.504 18h-.01a13.71 13.71 0 0 1-10.413-5h-1.915Zm3.246 0H33.56a12.5 12.5 0 0 1-9.059 4 12.71 12.71 0 0 1-9.089-4Zm-5.259-6.157L11.993 12h25.014l1.84-5.157L24.5 3.017 10.153 6.843Zm19.52 19.379A7.34 7.34 0 0 1 24.552 28h-.041a7.5 7.5 0 0 1-4.923-1.593l-.617.786A8.5 8.5 0 0 0 24.53 29a8.34 8.34 0 0 0 5.798-2.022l-.654-.756ZM22 5h5v3.854a1.501 1.501 0 0 1-.851 1.385l-1.427.709-.223.11-.223-.11-1.34-.67-.003-.003A1.71 1.71 0 0 1 22 8.763V5Zm1 1v2.758a.71.71 0 0 0 .386.626l1.115.557 1.207-.599.007-.004A.5.5 0 0 0 26 8.873V6h-3Zm5.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-.278 1.084a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83Zm-8.555-.831a1.5 1.5 0 1 1 1.667 2.495 1.5 1.5 0 0 1-1.667-2.495ZM20.5 20a.5.5 0 1 0 0 .999.5.5 0 0 0 0-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAvatarPoliceLarge;
