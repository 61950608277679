import type { SVGProps } from "react";
const SvgUserHeadsetDisplayMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.56 9.25H21c.369 0 .702.12.938.38.23.25.312.571.312.87v3c0 .352-.117.679-.364.916a1.26 1.26 0 0 1-.886.334h-3.101a5.82 5.82 0 0 1-.731 1.176 11.513 11.513 0 0 1 2.406 1.705l-.675.738a10.437 10.437 0 0 0-2.466-1.695A4.55 4.55 0 0 1 13.5 17.75a4.55 4.55 0 0 1-2.936-1.078C6.81 18.471 4.417 22.538 4.258 27.25H16.5v1H3.25v-.5c0-5.108 2.467-9.7 6.579-11.829a5.995 5.995 0 0 1-1.079-2.113v.942H6a1.26 1.26 0 0 1-.857-.359 1.24 1.24 0 0 1-.393-.891v-2.991c0-.361.166-.674.386-.89.217-.213.525-.37.864-.37h.308c1.916-8.08 12.7-7.812 14.253 0Zm-1.022 0H18.25v.94c-.644-2.26-2.468-3.941-4.75-3.941S9.394 7.93 8.75 10.19v-.94H7.34c1.807-6.773 10.73-6.453 12.198 0ZM9.5 12c0-2.712 1.835-4.75 4-4.75s4 2.038 4 4.75a5.6 5.6 0 0 1-.274 1.75H13.5v1h3.273c-.732 1.227-1.94 2-3.273 2-2.165 0-4-2.038-4-4.75Zm-3.75-1.491a.26.26 0 0 1 .087-.176A.261.261 0 0 1 6 10.25h1.75v3.5H6a.268.268 0 0 1-.166-.082.242.242 0 0 1-.084-.168v-2.991Zm13.5 3.24H21c.119 0 .172-.034.193-.054.018-.017.057-.066.057-.195v-3c0-.124-.034-.179-.05-.197-.01-.01-.052-.053-.2-.053h-1.75v3.5Zm-1 6h14.5v10h-6v2H30v1h-9v-1h3.25v-2h-6v-10Zm7 12h.5v-2h-.5v2Zm-6-11v8h12.5v-8h-12.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUserHeadsetDisplayMedium;
