import type { SVGProps } from "react";
const SvgRe5Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="re-5-negative_svg__color-immutable"
    {...props}
  >
    <g fill="#fff" clipPath="url(#re-5-negative_svg__a)">
      <path d="M57.208 1c.439 0 .795.358.795.8v16.4c0 .442-.356.8-.795.8H1.792a.798.798 0 0 1-.795-.8V1.8c0-.442.356-.8.795-.8h55.416Zm0-1H1.792C.803 0 0 .806 0 1.8v16.4c0 .994.803 1.8 1.792 1.8h55.416c.99 0 1.792-.806 1.792-1.8V1.8c0-.994-.803-1.8-1.792-1.8Z" />
      <path d="M4.16 4.234h5.6c1.108 0 1.957.174 2.547.522.49.285.865.664 1.124 1.139.258.475.387 1.01.387 1.606 0 .675-.163 1.263-.49 1.764-.322.5-.773.86-1.353 1.075a1.95 1.95 0 0 1 1.203.855c.184.28.308.572.371.878.064.306.145 1.004.245 2.096.074.802.188 1.3.34 1.495l.111.135h-2.634a1.982 1.982 0 0 1-.197-.625c-.037-.253-.087-.839-.15-1.756-.064-.818-.264-1.382-.602-1.693-.332-.317-.902-.475-1.708-.475h-2.27v4.55H4.16V4.234Zm2.523 1.985V9.4h2.413c.775 0 1.32-.11 1.637-.332.401-.28.601-.701.601-1.266 0-.548-.17-.949-.514-1.202-.342-.253-.883-.38-1.621-.38H6.683Zm17.964-1.985V6.37h-6.154v2.468h5.64v1.985h-5.64v2.84h6.265v2.136H15.97V4.234h8.677Zm8.804 0V6.29h-4.659l-.498 2.602c.559-.59 1.273-.886 2.143-.886.855 0 1.598.23 2.231.688.939.691 1.408 1.74 1.408 3.149 0 1.381-.5 2.475-1.503 3.282-.78.633-1.714.95-2.8.95-1.223 0-2.207-.328-2.95-.982-.744-.659-1.137-1.547-1.179-2.665h2.27c.037.406.169.751.396 1.036.353.448.843.672 1.471.672.606 0 1.086-.218 1.44-.656.342-.427.514-.947.514-1.558 0-.665-.18-1.195-.538-1.59-.359-.401-.836-.602-1.432-.602-.728 0-1.294.327-1.7.981L26 10.681l1.1-6.447h6.351Z" />
    </g>
    <defs>
      <clipPath id="re-5-negative_svg__a">
        <path fill="#fff" d="M0 0h59v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRe5Negative;
