import type { SVGProps } from "react";
const SvgTicketParkingMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.25 6.25h29.5v7h-.5c-2.274 0-4 1.726-4 4 0 2.273 1.726 4 4 4h.5v7H3.25v-22Zm1 1v3.5h8.032l.148.224 2.839 4.288H18A2.749 2.749 0 0 1 20.75 18v4.25h-3.3a2.749 2.749 0 0 1-2.45 1.5c-1.07 0-1.996-.61-2.45-1.5h-5.6a2.749 2.749 0 0 1-2.7 1.487v3.513h27.5v-5.023c-2.578-.24-4.5-2.32-4.5-4.977 0-2.658 1.922-4.738 4.5-4.977V7.25H4.25Zm0 11.013V11.75h7.495l2.838 4.288.148.224H18c.967 0 1.75.783 1.75 1.75v3.238h-2.011a2.75 2.75 0 1 0-5.478 0H7.24a2.75 2.75 0 0 0-2.989-2.987Zm0 4.466V19.27c.083-.014.166-.021.25-.021a1.75 1.75 0 1 1 0 3.5c-.084 0-.167-.007-.25-.021Zm9-1.73a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0Zm-3.013-6.256H9v-1h1.775l.148.224 1.494 2.257-.834.552-1.346-2.033ZM24.25 7.5v.487h1V7.5h-1Zm0 1.462v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95v.975h1v-.975h-1Zm0 1.95V27h1v-.488h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTicketParkingMedium;
