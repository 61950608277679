import type { SVGProps } from "react";
const SvgFlashlightOnMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m13.916 7.223-3-4.5-.832.555 3 4.5.832-.555Zm10.5-3.945-3 4.5-.832-.555 3-4.5.832.555ZM16.75 3v4.5h1V3h-1Zm-5.5 6.25h-.5v4.347l.036.089 1.468 3.672V32.75h9.996V17.347l1.464-3.661.036-.09V9.25h-12.5Zm2.004 8.5h7.996v14h-7.996v-14Zm8.158-1 1.1-2.75H11.988l1.1 2.75h8.324ZM11.75 13h11v-2.75h-11V13Zm3.5 6.75h4v10h-4v-10Zm1 1v8h2v-8h-2Zm.5 1.75V24h1v-1.5h-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFlashlightOnMedium;
