import type { SVGProps } from "react";
const SvgCloudLightningSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.494 10.606a2.062 2.062 0 0 0-1.182-.75c.125-.805-.004-1.532-.322-2.14a3.276 3.276 0 0 0-1.794-1.549c-1.363-.494-3.042-.116-3.987 1.423-1.516-.72-3.431.325-3.523 2.176-.481.128-.87.431-1.144.815-.36.503-.535 1.153-.507 1.79.028.641.262 1.304.764 1.81C6.307 14.69 7.046 15 8 15v-1c-.73 0-1.2-.23-1.492-.524-.298-.301-.455-.714-.474-1.148-.019-.438.104-.862.321-1.165.21-.295.497-.463.845-.463h.563l-.066-.56c-.18-1.501 1.42-2.246 2.386-1.454l.509.418.266-.602c.635-1.44 1.948-1.775 2.997-1.395.53.193.983.566 1.249 1.073.262.501.358 1.165.117 1.977l-.2.67.7-.028c.464-.018.78.161.984.423.215.275.328.674.295 1.1a1.8 1.8 0 0 1-.526 1.15c-.31.302-.785.528-1.474.528v1c.925 0 1.658-.312 2.172-.812a2.799 2.799 0 0 0 .825-1.786c.05-.636-.111-1.293-.503-1.796ZM11.5 13h-.207l-.147.146-3 3-.853.854H10l-1.06 1.415-2.453 3.271 3.169-2.584 3.33-2.715 1.088-.887h-2.367l1.646-1.646.854-.854H11.5Zm-1.793 3h.086l.353-.354L11.793 14h-.086l-2 2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudLightningSmall;
