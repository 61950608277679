import type { SVGProps } from "react";
const SvgEv32Negative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={20}
    fill="none"
    className="ev-32-negative_svg__color-immutable"
    {...props}
  >
    <path
      fill="#F27E00"
      fillRule="evenodd"
      d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h55a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M14.153 4.17v2.142H7.98v2.476h5.657v1.991H7.98v2.849h6.284v2.142H5.449V4.17h8.704Zm11.158 0-3.856 11.6h-2.88l-3.8-11.6h2.65l2.61 8.688 2.65-8.688h2.626Zm.193 3.673c.026-.952.262-1.75.706-2.396.72-1.037 1.801-1.555 3.245-1.555 1.111 0 2.008.283 2.69.849.735.614 1.103 1.407 1.103 2.38 0 .582-.145 1.085-.436 1.508-.286.418-.675.7-1.167.849.624.143 1.116.46 1.476.952.365.492.547 1.087.547 1.785 0 1.148-.423 2.087-1.269 2.817-.783.677-1.772 1.016-2.968 1.016-1.296 0-2.324-.373-3.086-1.119-.756-.746-1.135-1.754-1.135-3.023v-.15h2.23c0 .713.17 1.266.508 1.658.338.386.817.579 1.436.579s1.095-.183 1.428-.548c.307-.344.46-.754.46-1.23 0-.571-.195-1.023-.587-1.357-.323-.274-.82-.412-1.492-.412-.19 0-.378.01-.563.032V8.772c.138.01.27.016.397.016 1.333 0 2-.495 2-1.484 0-.434-.146-.783-.437-1.048-.291-.27-.674-.404-1.15-.404-.546 0-.966.17-1.262.508-.296.338-.455.833-.476 1.483h-2.198Zm16.911 5.864v2.063H34.06c0-.63.1-1.23.301-1.801a4.438 4.438 0 0 1 .865-1.476 8.124 8.124 0 0 1 1-.928c.386-.302 1.15-.855 2.293-1.659.555-.386.944-.745 1.166-1.079a2.04 2.04 0 0 0 .333-1.158c0-.508-.15-.918-.452-1.23-.296-.317-.688-.476-1.174-.476-1.196 0-1.812.86-1.849 2.579h-2.23v-.254c0-1.407.395-2.497 1.183-3.27.767-.75 1.758-1.126 2.975-1.126 1.08 0 1.981.307 2.706.92.788.667 1.182 1.563 1.182 2.69 0 .984-.315 1.83-.944 2.54-.206.232-.4.42-.58.563-.18.137-.653.473-1.42 1.007-.73.508-1.253.897-1.57 1.167a6.37 6.37 0 0 0-.873.928h5.443Z"
    />
  </svg>
);
export default SvgEv32Negative;
