import type { SVGProps } from "react";
const SvgScannerSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 4A1.5 1.5 0 0 0 4 5.5V6h16v-.5A1.5 1.5 0 0 0 18.5 4h-13Zm.309 6-1.5-3h15.382l-1.5 3H5.809Zm.051 1-1.666 5h15.612l-1.666-5H5.86ZM4 18.5V17h16v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 4 18.5Zm-1-13A2.5 2.5 0 0 1 5.5 3h13A2.5 2.5 0 0 1 21 5.5v1.118l-.053.106-1.907 3.814 1.934 5.804.026.077V18.5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 3 18.5v-2.081l.026-.077 1.934-5.804-1.907-3.814L3 6.618V5.5ZM16 19h2v-1h-2v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgScannerSmall;
