import type { SVGProps } from "react";
const SvgRewindSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 4.5v15l-.8-.6L14 15v4.478l-.793-.573-9-6.5L3.646 12l.561-.405 9-6.5.793-.573V9l5.2-3.9.8-.6ZM5.354 12 13 17.522V13l.8.6 5.2 3.9v-11l-5.2 3.9-.8.6V6.478L5.354 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRewindSmall;
