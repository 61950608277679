import type { SVGProps } from "react";
const SvgWagonCouplingDraggingEquipmentMedium = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <g clipPath="url(#wagon-coupling-dragging-equipment-medium_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.25 5.75H3v-1h19.25v13.5h5V15h1v7.5h-1v-3.25h-2v4.272l3.578 3.1-.655.756-3.75-3.25-.173-.15V19.25h-2v4.5h-6v1.375c0 2.128-1.027 4.025-2.598 5.125h3.413l5.11-4.38.325-.279.325.28 5.11 4.379H33v1H3v-1h4.348c-1.571-1.1-2.598-2.997-2.598-5.125V23.75H3v-1h18.25v-17Zm1.25 21.159 3.898 3.341h-7.796l3.898-3.341Zm-12 3.341c2.591 0 4.75-2.262 4.75-5.125V23.75h-9.5v1.375c0 2.863 2.159 5.125 4.75 5.125Zm19.75-7.75V15h1v3.25H33v1h-1.75v3.25h-1Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="wagon-coupling-dragging-equipment-medium_svg__a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWagonCouplingDraggingEquipmentMedium;
