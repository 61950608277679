import type { SVGProps } from "react";
const SvgWeatherUnknownMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.178 15.099c.678.106 1.24.427 1.663.886.542.589.835 1.38.896 2.185.123 1.597-.666 3.433-2.551 4.055l-.313-.95c1.34-.442 1.964-1.766 1.867-3.029-.047-.625-.272-1.19-.635-1.583-.352-.383-.855-.628-1.536-.6l-.697.027.198-.67c.749-2.538-.531-4.322-2.213-4.937-1.69-.616-3.81-.066-4.825 2.25l-.266.606-.51-.42c-1.627-1.342-4.299-.076-4 2.433l.066.559h-.563c-1.066 0-1.866.965-1.993 2.258-.123 1.256.436 2.569 1.857 3.054l-.323.946c-1.966-.671-2.686-2.501-2.529-4.098.138-1.401.992-2.833 2.461-3.112.006-2.822 2.948-4.403 5.15-3.163 1.33-2.39 3.813-2.984 5.818-2.252 2.011.735 3.52 2.791 2.978 5.555Zm-7.639 10.4h1.32v-1.257h-1.32v1.257Zm-1.64-7.857c-.268.411-.416.992-.445 1.742h1.051c.086-1.322.672-1.983 1.76-1.983.526 0 .924.168 1.192.502.226.273.337.612.337 1.014 0 .217-.04.432-.116.642-.095.246-.293.522-.593.826-.469.477-.772.8-.909.974-.136.172-.24.367-.313.586-.104.297-.157.756-.166 1.373h1.039c0-.348.021-.629.061-.848.038-.215.101-.398.192-.547.093-.145.255-.33.486-.552.527-.508.858-.855.996-1.044.311-.417.465-.912.465-1.488 0-.696-.227-1.258-.683-1.686-.49-.463-1.148-.694-1.974-.694a3.08 3.08 0 0 0-1.39.307 2.507 2.507 0 0 0-.99.876Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWeatherUnknownMedium;
