import type { SVGProps } from "react";
const SvgLighthouseLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 2a7 7 0 0 0-7 7v1h-3v1h3v9h-3v1h2.936l-2.88 24H6v1h36v-1h-8.056l-2.88-24H34v-1h-3v-9h3v-1h-3V9a7 7 0 0 0-7-7Zm6 8V9a6 6 0 0 0-12 0v1h12Zm-12 1v9h3v-6h6v6h3v-9H18Zm8 9v-5h-4v5h4Zm-8.056 1-.6 5h13.312l-.6-5H17.944Zm-2.04 17 .6-5h14.992l.6 5H15.904Zm14.872-11 .6 5H16.624l.6-5h13.552ZM15.784 39l-.72 6h17.872l-.72-6H15.784ZM42 16h-5v-1h5v1Zm-4.755-5.064 8-4.5-.49-.872-8 4.5.49.872Zm7.51 14.5-8-4.5.49-.872 8 4.5-.49.872ZM44 16h2v-1h-2v1ZM6 15h5v1H6v-1Zm5.245-4.936-8-4.5-.49.872 8 4.5.49-.872Zm-8.49 14.5 8-4.5.49.872-8 4.5-.49-.872ZM4 15H2v1h2v-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLighthouseLarge;
