import type { SVGProps } from "react";
const SvgHandFingersSnapSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 7V2h-1v5h1Zm6.808-1.229a1.501 1.501 0 0 0-2.12-.002L11 11.313l.001-1.81v-.001A1.5 1.5 0 0 0 9.501 8c-.417 0-.762.16-1.018.439-.24.26-.378.596-.466.931l-2 6.992-.078.274.196.207 4.955 5.252.205.217.289-.077-.13-.483.13.483h.001l.003-.002.007-.001.021-.007a4.74 4.74 0 0 0 .324-.114c.214-.083.515-.215.882-.414.734-.398 1.728-1.063 2.803-2.137a2140.48 2140.48 0 0 1 2.38-2.377l.073-.073.016-.016.004-.004h.001l.002-.002a1.501 1.501 0 0 0-.466-2.44 1.504 1.504 0 0 0-.24-1.808l-.002-.001a1.502 1.502 0 0 0-.463-.315 1.504 1.504 0 0 0-.244-1.802v-.001a1.501 1.501 0 0 0-.414-.293l2.535-2.533a1.501 1.501 0 0 0 0-2.124Zm-6.462 15.047a6.956 6.956 0 0 1-.75.355l-4.538-4.81L8.981 9.64l.003-.011c.068-.26.152-.422.236-.513A.344.344 0 0 1 9.5 9a.5.5 0 0 1 .501.502L10 12.5v1.187l.85-.83 6.537-6.374.005-.005a.501.501 0 0 1 .709.71l-3.483 3.48a1.606 1.606 0 0 0-.055.053l-1.771 1.773.707.707 1.803-1.802a.503.503 0 0 1 .678.738l-.637.635a1.56 1.56 0 0 0-.072.067l.002.002-1.063 1.06.705.708 1.105-1.1a.503.503 0 0 1 .668.747l-1.062 1.06-.001.001.706.708.354-.353.001-.001a.501.501 0 0 1 .71.709l-.005.004-.016.016-.074.074-.365.363-2.017 2.015c-1.002 1.002-1.917 1.61-2.573 1.966Zm4.07-17.54-2 3-.832-.555 2-3 .832.554Zm-7 2.445-2-3-.832.554 2 3 .832-.554Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHandFingersSnapSmall;
