import type { SVGProps } from "react";
const SvgSpeechBubbleEmptySmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 6h16v12H9.957l-2.103 2.104-.854.853V18H4V6Zm1 1v10h3v1.543l1.396-1.397.147-.146H19V7H5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSpeechBubbleEmptySmall;
