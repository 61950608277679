import type { SVGProps } from "react";
const SvgMergeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.578 8.528c2.49 1.363 5.98 2.339 11.702 2.46l-3.024-3.024.707-.708 3.89 3.89.354.354-.354.354-3.89 3.89-.707-.708 3.024-3.023c-5.722.12-9.213 1.096-11.702 2.46-2.165 1.184-3.593 2.666-5.114 4.243-.361.375-.728.755-1.11 1.138l-.708-.707c.359-.359.71-.723 1.06-1.088 1.53-1.588 3.081-3.199 5.391-4.464 1.669-.913 3.722-1.645 6.446-2.095-2.724-.45-4.777-1.182-6.446-2.095-2.31-1.265-3.861-2.876-5.39-4.464a80.33 80.33 0 0 0-1.06-1.087l.707-.708c.382.383.749.763 1.11 1.138 1.52 1.577 2.95 3.059 5.114 4.244Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMergeSmall;
