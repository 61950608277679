import type { SVGProps } from "react";
const SvgArrowLongLeftMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.123 13.673 6.41 18.397l-.353.353.353.353 4.712 4.712.707-.707-3.857-3.858H30v-1H7.97l3.86-3.87-.707-.707Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowLongLeftMedium;
