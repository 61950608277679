import type { SVGProps } from "react";
const SvgRackRailawayProfileMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m16.715 3.284.233.089 6.27 2.385.971.37-.895.528-3.768 2.225 8.232-.858.052.497-.052-.498a2 2 0 0 1 2.2 1.8v.002l1.23 13.498v.002a2 2 0 0 1-1.78 2.178l-5.567.6a3.5 3.5 0 0 1-.732 2.232l9.916-.991.1.995-11.648 1.165a3.5 3.5 0 0 1-1.595.159l-7.216.722a3.504 3.504 0 0 1-1.953.195l-7.588.759-.1-.995 5.992-.6-.025-.022a3.503 3.503 0 0 1-1.072-1.9l-4.791.516-.108-.994 26.28-2.835h.001a1 1 0 0 0 .89-1.09v-.003l-1.23-13.498v-.001a1 1 0 0 0-1.1-.899l-10.404 1.085-.08.047-.084-.03-14.167 1.478-.104-.995L15.09 9.345l-4.156-1.462-.976-.344.876-.55 5.67-3.57.21-.134ZM8.919 27.712a2.503 2.503 0 0 0 1.85 1.856l1.643-.164a2.503 2.503 0 0 0 1.446-2.224l-4.939.532Zm5.938-.64a3.503 3.503 0 0 1-.693 2.157l3.887-.389a3.502 3.502 0 0 1-1.15-1.989l-2.044.22Zm3.043-.329a2.501 2.501 0 0 0 2.008 1.911l1.344-.134a2.502 2.502 0 0 0 1.59-2.31l-4.942.533ZM16.825 4.396l-4.583 2.886 5.03 1.77 4.619-2.729-5.066-1.927Zm6.762 7.304-.498.05.05.497.738 7.464.049.497.497-.049 2.986-.295.497-.05-.049-.497-.738-7.464-.05-.497-.497.049-2.985.295Zm1.236 7.415-.64-6.469 1.99-.197.64 6.469-1.99.197Zm-13.189-6.227-.497.049.049.497.738 7.464.05.497.497-.049 7.464-.738.497-.05-.05-.497-.738-7.463-.049-.498-.497.05-7.464.737Zm1.236 7.414-.64-6.468 6.469-.64.64 6.468-6.469.64Zm-3.72-7.166.049.495.735 7.455.049.5-.5.047-6.36.6-.094-.995 5.86-.553-.637-6.46-5.13.478-.092-.996 5.625-.525.495-.046Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRackRailawayProfileMedium;
