import type { SVGProps } from "react";
const SvgGlobeLocomotiveSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.999 10.5A7.49 7.49 0 0 1 6.07 4.446l.752.47.121.076h1.863l.097-.375.393-1.521A7.554 7.554 0 0 1 10.498 3a7.47 7.47 0 0 1 3.337.781l-.672.488-.078.057-.051.082-.621.99-1.997-.354-.265-.047-.186.195-1.609 1.695-2.232.941-.141.06-.084.128-.396.607-.36.553.63.197 2.315.727.12.037.123-.023 3.354-.636.292.147.552 1.543.942-.336-.62-1.731-.066-.188-.178-.09-.622-.315-.152-.076-.167.031-3.398.645-1.494-.469 2.067-.871.096-.041.072-.076 1.496-1.576 2.065.367.332.06.18-.287.748-1.194.942-.685a7.505 7.505 0 0 1 3.046 4.522l.976-.218a8.5 8.5 0 1 0-7.754 10.343l-.063-.998a7.501 7.501 0 0 1-7.289-4.34l1.117-1.492 1.362-.332 2.323.801.634 1.055.207.344.381-.128 1.546-.52.587.192.31-.95-.744-.244-.158-.052-.157.053-1.322.445-.52-.866-.094-.156-.172-.059-2.633-.908-.139-.048-.142.035-1.68.409-.175.042-.108.144-.835 1.117a7.506 7.506 0 0 1-.277-2.027ZM7.23 3.992l-.216-.135c.376-.198.77-.365 1.18-.497l-.162.632H7.23ZM20 10h-5v1h2v1h-1.961A2.039 2.039 0 0 0 13 14.039V20h1.261l-.725 1.814.928.372.874-2.186h4.324l.874 2.186.928-.372L20.738 20H22v-5.961A2.038 2.038 0 0 0 19.962 12H18v-1h2v-1Zm-6 9v-3h7v3h-7Zm7-4.961V15h-7v-.961c0-.574.465-1.039 1.039-1.039h4.923c.573 0 1.038.465 1.038 1.039ZM16 16.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm2 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGlobeLocomotiveSmall;
