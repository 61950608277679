import type { SVGProps } from "react";
const SvgPrinterMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.75 4.749h-.5v6h-6v14.5h6v7.5h17.5v-7.5h6v-14.5h-6v-6h-17Zm16 6v-5h-15.5v5h15.5Zm6 13.5h-5v-4.5H9.25v4.5h-5v-12.5h27.5v12.5Zm-21.5 7.5v-11h15.5v11h-15.5Zm13.75-8H12v-1h12v1Zm-12 3h12v-1H12v1Zm0 3h12v-1H12v1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPrinterMedium;
